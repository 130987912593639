/*
 * @Author: zbq
 * @description: 文件描述
 * @Date: 2022-05-09 17:07:42
 * @LastEditors: zbq
 * @LastEditTime: 2022-05-28 16:51:08
 */
import { defineComponent, ref, watch } from 'vue'

import { getNoPermOption } from '../../router/permission'
import styles from './LayoutContainer.module.scss'
import useAuthStore from '../../store/auth'

const LayoutContainer = defineComponent({
  name: 'LayoutContainer',
  setup() {
    return () => {
      const activedKey = ref('workbench')
      const auth = useAuthStore()
      const menuData = ref([
        {
          title: '工作区',
          key: 'workbench',
          icon: 'icongongzuokongjian',
          index: '/workbench',
        },
      ])

      return (
        <>
          <div class={styles.container}>
            <div class={styles.sidebar}>
              <ssu-sidebar
                isRouter
                active={activedKey.value}
                menu-data={menuData.value}
                v-slots={{
                  header: () => (
                    <div class={[styles.header, 'header']}>
                      <div class={[styles.img, 'img']}></div>
                    </div>
                  ),
                  user: () => {
                    return (
                      <div class={styles.user}>
                        <span class={styles.name}>
                          <syc-icon type="designer/chengyuan"></syc-icon> {auth.userInfo?.account}
                        </span>
                        <div class={styles.welcome}>欢迎使用PaaS</div>
                        <el-button type="text" onClick={() => {
                          getNoPermOption()
                        }}>退出</el-button>
                      </div>
                    )
                  },
                }}
              />
            </div>
            <RouterView></RouterView>
          </div>
        </>
      )
    }
  },
})

export default LayoutContainer
