<!--
 * @Author: pzf
 * @description: 组件
 * @Date: 2022-01-27 14:19:31
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-02 16:58:29
-->
<template>
  <div ref="model" class="file-preview-item">
    <component
      :is="componentName"
      :url="previewUrl"
      :name="previewName"
      :draggable="draggable"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue'
import packageComponents from './previewExport' // 导入组件
import { getComponentName } from './hooks/preview-type'

export default defineComponent({
  name: 'SlmPreview',
  components: { ...packageComponents },
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    previewItem: {
      type: Object,
      default: () => ({}),
    },
    dictLabel: {
      type: String,
      default: 'name',
    },
    dictValue: {
      type: String,
      default: 'url',
    },
    // 预览组件默认是禁止滚动，我们需要允许
    allowScroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: null,
  },
  setup(props) {
    const previewUrl = computed(() => {
      return props.previewItem?.[props.dictValue]
    })
    const previewName = computed(() => {
      return props.previewItem?.[props.dictLabel]
    })
    const model = ref(null)
    onMounted(() => {
      bindDisabledEvent()
    })
    onBeforeUnmount(() => {
      unbindDisabledEvent()
    })
    const disabledScroll = (event) => {
      if (props.allowScroll) {
        return
      }
      // 当deltaY大于0，说明当前正在向下滚动， 此时如果元素的内容到达底部，则阻止鼠标滚动事件
      // 当deltaY小于0，寿命当前正在向上滚动，此时如果元素的内容到达顶部，则阻止鼠标滚动事件
      let scrollTop = Number(model.value?.scrollTop)
      let scrollHeight = Number(model.value?.scrollHeight)
      let height = Number(model.value?.clientHeight)
      let delta = event.deltaY

      if (
        (delta < 0 && scrollTop <= -1 * delta) ||
        (delta > 0 && scrollHeight - height - scrollTop <= delta)
      ) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
    const bindDisabledEvent = () => {
      window.addEventListener('wheel', disabledScroll, {
        passive: false,
      })
    }
    const unbindDisabledEvent = () => {
      window.removeEventListener('wheel', disabledScroll)
    }
    const componentName = computed(() => {
      return getComponentName(previewUrl.value, previewName.value)
    })
    return { previewUrl, previewName, componentName, model }
  },
})
</script>
<style lang="scss">
.file-preview-item {
  .audio-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .frame-container,
  .frame-loading {
    width: 100%;
    height: 100%;

    .el-loading-mask {
      background: none;
    }
  }
  .image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      // max-height: 100vh;
      // max-width: 100%;
      user-select: none;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }
  .pdf-container {
    width: 100%;
    height: 100%;

    .el-loading-mask {
      background: none;
    }
  }
  .video-container {
    height: 100%;

    video {
      width: 100%;
      height: 100%;

      &.original {
        width: auto;
      }
    }
  }
}
</style>
