<!--
 * @Author: pzf
 * @description: 图片
 * @Date: 2022-03-14 15:26:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:50:17
-->
<template>
    <div class="image-wrapper">
        <img
            v-if="draggable"
            ref="image"
            v-myDrag="false"
            v-stretch="{
                addCount: 10,
                reduceCount: 10,
                minPercent: 2,
                maxPercent: 5
            }"
            class="image"
            :src="url"
        />
        <img v-else class="image" :src="url" alt="" />
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, nextTick } from 'vue'
import { commonPreviewItemProps } from '../hooks/common'
import { myDrag, stretch } from '../directives/index'

export default defineComponent({
    directives: {
        myDrag: myDrag,
        stretch: stretch
    },
    props: { ...commonPreviewItemProps },
    setup() {
        return {}
    }
})
</script>
