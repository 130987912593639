export const logoName = 'Syc PaaS'
export const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN || '.sycdev.com'
export const COOKIE_TOKEN_KEY = process.env.VUE_APP_COOKIE_TOKEN_KEY || 'token'
export const COOKIE_TENANT_ID_KEY =
  process.env.VUE_APP_COOKIE_TENANT_ID_KEY || 'tenantId'
export const ADMIN_HOST =
  process.env.VUE_APP_ADMIN_HOST || 'https://admin.sycdev.com/'
export const GATEWAY_HOST =
  process.env.VUE_APP_GATEWAY_HOST || 'https://sycapi.sycdev.com'
// export const SYSTEM_TYPE = 'low_code'
export const redirectLoginParam = 'redirectUrl'
// 平台预览页面
export const SLM_PREVIEW_PAGE = process.env.VUE_APP_SLM_PREVIEW_PAGE
export const BI_HOST = process.env.VUE_APP_BI_HOST
export const __DEV__ = process.env.NODE_ENV === 'development'
