import { createRouter, createWebHashHistory } from 'vue-router'

import AppDesignLayout from '../layouts/AppDesignLayout/AppDesignLayout'
import AppLayout from '../layouts/AppLayout/AppLayout'
import AppPageLayout from '../layouts/AppPageLayout/AppPageLayout'
import AutomationLayout from '../layouts/AutomationLayout/AutomationLayout'
import BlankLayout from '../layouts/BlankLayout/BlankLayout'
import LayoutContainer from '../components/LayoutContainer/LayoutContainer'
import NotFound from '../views/NotFound/NotFound'
import NotPermission from '../views/NotPermission/NotPermission'
import PlatformLayout from '../layouts/PlatformLayout/PlatformLayout'
import Preview from '../views/AppDesign/AppFormDesign/Preview'
import PublicFormShare from '../views/PublicFormShare/PublicFormShare'
import SignWrap from '../views/SignMobile'
import SignReport from '../views/SignReport'
import SycPaaSComponents from '@syc-paas/components'
import permission from './permission'

const router = createRouter({
  routes: [
    {
      path: '/login',
      component: () => import('@/views/Login/Login'),
    },
    {
      path: '/render/designer',
      component: SycPaaSComponents.CanvasInIframe,
    },
    {
      // h5签名路由
      path: '/h5/sign',
      name: 'Sign',
      component: SignWrap,
    },
    {
      // 确认签名的页面
      path: '/sign/report',
      name: 'SignReport',
      component: SignReport
    },
    {
      path: '/render/preview',
      // component: Preview,
      component: SycPaaSComponents.FormPreviewRender,
    },
    {
      path: '/render/form-style-config-preview',
      component: SycPaaSComponents.FormPublicReleasePreview,
    },
    {
      path: '/workbench',
      component: LayoutContainer,
      children: [
        {
          path: '',
          component: () => import('@/views/Workbench/Workbench'),
        },
      ],
    },
    {
      path: '/platform/:platformId',
      component: PlatformLayout,
      children: [
        {
          path: 'app',
          component: () => import('@/views/Platform/AppManagePage'),
        },
        {
          path: 'navigation',
          component: () => import('@/views/Platform/NavigationSettingPage'),
        },
        {
          path: 'config',
          component: () =>
            import(
              '@/views/Platform/PlatformConfig/PlatformConfigIndex/PlatformConfigIndex'
            ),
          children: [
            {
              path: 'baseInfo',
              component: () =>
                import(
                  '@/views/Platform/PlatformConfig/PlatformConfigBaseInfo/PlatformConfigBaseInfo'
                ),
            },
            {
              path: 'admin',
              component: () =>
                import(
                  '@/views/Platform/PlatformConfig/PlatformConfigAdmin/PlatformConfigAdmin'
                ),
            },
            {
              path: 'runtimeEnv',
              component: () =>
                import('@/views/Platform/PlatformConfig/RuntimeEnv/RuntimeEnv'),
            },
          ],
        },
        {
          path: 'agency',
          component: () => import('@/views/Platform/AgencyCenter/AgencyCenter'),
          children: [
            {
              path: 'backlog',
              component: () =>
                import(`@/views/Platform/AgencyCenter/backlog/backlog`),
            },
            {
              path: 'done',
              component: () =>
                import(`@/views/Platform/AgencyCenter/done/done`),
            },
            {
              path: 'launch',
              component: () =>
                import(`@/views/Platform/AgencyCenter/launch/launch`),
            },
            {
              path: 'carbon-copy',
              component: () =>
                import(`@/views/Platform/AgencyCenter/carbon-copy/carbon-copy`),
            },
            {
              path: 'drafts',
              component: () =>
                import(`@/views/Platform/AgencyCenter/drafts/drafts`),
            },
          ],
        },
        {
          path: 'siot',
          component: () => import('@/views/Platform/SIot/SIot'),
        },
        {
          path: 'configuration',
          component: () =>
            import('@/views/Platform/Configuration/Configuration'),
        },
      ],
    },
    {
      path: '/platform/:platformId/app/:appId',
      component: AppLayout,
      children: [
        {
          path: 'page',
          component: AppPageLayout,
          redirect: (to) => {
            return {
              name: 'PageManagePage',
              params: { ...to.params, pageCode: 'default' },
            }
          },
          children: [
            {
              name: 'PageManagePage',
              path: ':pageCode',
              component: () => import('@/views/App/PageManagePage'),
            },
          ],
        },
        {
          path: 'automation',
          component: () =>
            import('@/views/App/AutomationManage/AutomationManage'),
        },
        {
          path: 'settings',
          component: () =>
            import('@/views/App/AppSettings/AppSettingsIndexPage'),
          children: [
            {
              path: 'baseInfo',
              component: () =>
                import('@/views/App/AppSettings/AppSettingsBaseInfoPage'),
            },
          ],
        },
        {
          path: 'publish',
          component: () =>
            import('@/views/App/AppPublishManage/AppPublishManage'),
        },
      ],
    },
    {
      path: '/platform/:platformId/app/:appId/automation/:automationId/code/:automationCode',
      component: AutomationLayout,
      children: [
        {
          path: 'orchestration',
          name: 'orchestration',
          component: () =>
            import(
              /* automation-Orchestration */ '@/views/Automation/AutomationDesigner/AutomationDesigner'
            ),
        },
        {
          path: 'records',
          component: () =>
            import(
              /* automation-Records */ '@/views/Automation/ExecutionRecords/ExecutionRecords'
            ),
        },
      ],
    },
    {
      path: '/platform/:platformId/app/:appId/design/:pageCode',
      component: AppDesignLayout,
      children: [
        {
          path: 'dashboard',
          component: () =>
            import('@/views/AppDesign/AppDashboardDesign/AppDashboardDesign'),
        },
        {
          path: 'form',
          component: () =>
            import('@/views/AppDesign/AppFormDesign/AppFormDesign'),
        },
        {
          path: 'form-setting',
          component: () =>
            import('@/views/AppDesign/AppFormSetting/AppFormSetting'),
          children: [
            {
              path: 'business-rules',
              component: () =>
                import(
                  `@/views/AppDesign/AppFormSetting/BusinessRules/BusinessRules`
                ),
            },
            {
              path: 'function-button',
              component: () =>
                import(
                  `@/views/AppDesign/AppFormSetting/FunctionButton/FunctionButton`
                ),
            },
            {
              path: 'data-title',
              component: () =>
                import(`@/views/AppDesign/AppFormSetting/DataTitle/DataTitle`),
            },
            {
              path: 'public-release',
              component: () =>
                import(
                  `@/views/AppDesign/AppFormSetting/PublicRelease/PublicRelease`
                ),
            },
            {
              path: 'print-template',
              component: () =>
                import(
                  `@/views/AppDesign/AppFormSetting/PrintTemplate/PrintTemplate`
                ),
            },
            {
              path: 'data-log',
              component: () =>
                import(
                  `@/views/AppDesign/AppFormSetting/DataLog/DataLog`
                ),
            },
          ],
        },
        {
          path: 'process',
          component: () =>
            import('@/views/AppDesign/AppProcessDesign/AppProcessDesign'),
        },
        // {
        //   path: 'process/bak',
        //   component: () =>
        //     import('@/views/AppDesign/AppProcessDesignBak/AppProcessDesign'),
        // },
      ],
    },
    {
      path: '/playground',
      component: BlankLayout,
      children: [
        {
          path: '/playground/h5render/:platformId/:appId/:pageCode',
          component: () => import('@/views/Playground/H5Render/index'),
        },
        {
          path: '/playground/h5DetailRender/:platformId/:appId/:pageCode/:dataId',
          component: () =>
            import(
              /*h5DetailRender*/ '@/views/Playground/h5DetailRender/index'
            ),
        },
      ],
    },
    {
      path: '/publicFormShare',
      component: PublicFormShare,
      meta: {
        isCuStomTitle: true,
      },
    },
    {
      path: '/publicFormShare/questionnaire',
      component: () =>
        import(
          /*QuestionnaireStatistical*/ '@/views/PublicFormShare/QuestionnaireStatistical/QuestionnaireStatistical'
        ),
    },
    {
      path: '/publicFormShare/ShareQuestionnaireStatistical',
      component: () =>
        import(
          /*ShareQuestionnaireStatistical*/ '@/views/PublicFormShare/QuestionnaireStatistical/ShareQuestionnaireStatistical'
        ),
      meta: {
        isCuStomTitle: true,
      },
    },
    {
      path: '/403',
      component: NotPermission,
    },
    {
      path: '/404',
      component: NotFound,
    },
    {
      path: '/',
      redirect: '/workbench',
      // component: () => import('@/layouts/AdminLayout/AdminLayout'),
      // children: [
      //   {
      //     path: '',
      //     name: 'Home',
      //     component: () => import('@/views/Home/Home'),
      //   },import H5Preview from '../views/AppDesign/AppFormDesign/H5Preview';

      // ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
    },
  ],
  history: createWebHashHistory(),
})

router.beforeEach((to, from, next) => {
  if (to.meta?.isCuStomTitle) {
    document.title = to.query?.pageName || ''
  } else if (to.meta?.title) {
    document.title = to.meta?.title || ''
  } else {
    document.title = 'Syc PaaS 低代码开发平台'
  }

  next()
})
permission(router)

export default router
