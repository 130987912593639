import { RouterView } from 'vue-router'
import { defineComponent } from 'vue'

const BlankLayout = defineComponent({
  name: 'BlankLayout',
  setup() {
    return () => <RouterView />
  },
})

export default BlankLayout
