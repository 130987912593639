import { PageAuth, PageType, createLiteStore } from '@syc-paas/core'
import { computed, ref } from 'vue'

import _ from 'lodash'
import paasClient from '@/lib/paasClient'
import { recursionTree } from '@syc-paas/utils'
import { useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

export const AppLayoutStore = createLiteStore(() => {
  const app = ref({})
  const route = useRoute()
  const platformId = computed(() => route.params.platformId)
  const appId = computed(() => route.params.appId)
  const appName = computed(() => app.value.name)
  const getAppDetail = async () => {
    app.value = await paasClient.admin.getAppDetail(appId.value)
  }

  const useData = () => {
    const menuList = ref([])
    const pageList = computed(() => {
      const res = []
      recursionTree(menuList.value, {
        visit: (data) => {
          if (data.pageType !== PageType.GROUP) {
            res.push(data)
          }
        },
      })
      return res
    })
    const { isLoading, refetch } = useQuery(
      ['appMenu', platformId.value, appId.value],
      () => {
        return paasClient.admin.getPageList({
          appId: appId.value,
          preview: true,
        })
      },
      {
        select: (res) => {
          if (!res) return []
          return res.map((item) => {
            if (item.type === PageAuth.GROUP) {
              item.expand = true
              _.isNil(item.children) && (item.children = [])
            }
            return item
          })
        },
        onSuccess(res) {
          menuList.value = res
        },
      }
    )

    return {
      pageList,
      isLoading,
      menuList,
      platformId,
      refetch: refetch.value,
    }
  }

  const data = useData()

  return {
    app,
    appId,
    appName,
    getAppDetail,
    ...data,
  }
}, 'appLayout')

export const useAppLayoutStore = () => AppLayoutStore.useInject()
