import dayjs from 'dayjs'
import { download } from '@syc-paas/utils'
import { isAndroid, isWeChat } from '../../utils/device'

export const downloadReportPdf = (url, previewUrl, title) => {
  if (!url) {
    return
  }
  if (isAndroid() && isWeChat()) {
    window.open(url)
  } else {
    download(
      url,
      title + dayjs().format('YYYYMMDDHHmmss'),
      true,
      false,
      'application/pdf;charset=UTF-8'
    )
  }
}
