import { defineComponent } from 'vue'
import { safeCallFunction } from '@syc-paas/utils'
import styles from './BaseHeader.module.scss'

const BaseHeader = defineComponent({
  name: 'BaseHeader',
  setup(_, { slots }) {
    return () => {
      return (
        <div class={styles.container}>
          <div class={styles.inner}>
            <div class={styles.left}>{safeCallFunction(slots.left, null)}</div>
            <div class={styles.middle}>
              {safeCallFunction(slots.middle, null)}
            </div>
            <div class={styles.right}>
              {safeCallFunction(slots.right, null)}
            </div>
          </div>
        </div>
      )
    }
  },
})
export default BaseHeader
