import { MemberVariable, dynamicDataContext } from '@syc-paas/core'
/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-06-23 11:31:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-06-24 10:28:10
 */
import _ from 'lodash'
import authHelper, { tokenKey } from '../lib/auth.helper'

import { defineStore } from 'pinia'
import { getNoPermOption } from '../router/permission'
import paasClient from '../lib/paasClient'
import permission from '../lib/permission'
import router from '../router'

const useAuthStore = defineStore('auth', {
  state: () => ({
    whiteList: [
      '/404',
      '/403',
      '/login',
      '/render/designer',
      '/h5/sign',
      '/sign/report',
      '/publicFormShare',
      '/publicFormShare/questionnaire',
      '/publicFormShare/ShareQuestionnaireStatistical',
    ], // 链接白名单
    userInfo: {},
    permissionCodeMap: {}, // 权限Map
    permissionCode: [], // 权限List
  }),
  actions: {
    async setUserInfo(newInfo = {}) {
      // authHelper.setUserInfo({
      //   ...newInfo,
      // })
      const userInfo = {
        ...authHelper.getUserInfo(),
        ...this.userInfo,
        ...newInfo,
      }

      dynamicDataContext.setCurrentUser(userInfo)
      this.userInfo = userInfo
      this.permissionCode = newInfo.permissionCode ?? []
      this.permissionCodeMap = newInfo.permissionCodeMap ?? {}
      if (!this.permissionCodeMap[permission.ENTER]) {
        router.push(getNoPermOption())
      }
    },
    async getUserInfo() {
      if (_.isEmpty(this.userInfo)) {
        const res = await paasClient.admin.getUserInfo()
        this.setUserInfo(res ?? {})
      }
      return this.userInfo
    },
    async getPermission() {
      const res = await paasClient.admin.getPermission()
      this.setUserInfo(res ?? {})
      return res
    },
    async getTokenFromCode(code) {
      const res = await paasClient.admin.getTokenFromCode(code)
      authHelper.setUserInfo({
        token: res.accessToken,
        tenantId: res.tenantId,
      })

      return res
    },
    async clearUserInfo() {
      authHelper.clearUserInfo()
      this.userInfo = {}
      this.permissionCodeMap = {}
      this.permissionCode = []
      dynamicDataContext.emptyData()
    },
    async login(data) {
      const res = await paasClient.iam.login(data)
      const userInfo = {
        username: _.get(res, ['userInfo', 'username']),
        userId: _.get(res, ['userInfo', 'userId']),
      }
      authHelper.setUserInfo({
        token: res.access_token,
        ...userInfo,
      })
      this.userInfo = authHelper.getUserInfo()
      return res
    },
    async exit() {},
  },
})

export default useAuthStore
