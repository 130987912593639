import '@/assets/scss/global.scss'
import '@/assets/scss/element-ui.scss'
import '@yzfe/svgicon/lib/svgicon.css'
import 'nprogress/nprogress.css'
import 'element-plus/dist/index.css'
import '@syc-saas/ui/dist/lib/style.css'
import 'vant/lib/index.css'

import App from './App.js'
import Components from '@syc-paas/components'
import ElementPlus from 'element-plus'
import OfficialLibrary from '@syc-paas/official-library'
import { Plugin } from '@syc-paas/icons'
import SvgIconPlugin from './plugins/svgIcon'
import SycSassUi from '@syc-saas/ui'
import Vant from 'vant'
import { VueQueryPlugin } from 'vue-query'
import { createApp } from 'vue'
import i18n from './locale/index.js'
import router from './router'
import store from './store/index'
import vClickOutside from 'click-outside-vue3'

const app = createApp(App)
app.use(Vant)
app.use(VueQueryPlugin)
app.use(Plugin)
app.use(vClickOutside)
app.use(ElementPlus)
app._context.components.ElDialog.props.closeOnClickModal.default = false
app._context.components.ElDrawer.props.closeOnClickModal.default = false
app.use(SycSassUi)
app.use(Components)
app.use(OfficialLibrary)
app.use(app)
app.use(i18n)
app.use(SvgIconPlugin)
app.use(router)
app.use(store)

app.mount('#app')
