import { defineComponent } from 'vue'
import styles from './NotFound.module.scss'

const NotFound = defineComponent({
  name: 'NotFound',
  setup() {
    return () => {
      return (
        <div class={styles.container}>
          <div class={styles.content}>
            <img
              class={styles.img}
              src={require('@/assets/images/no-page.png')}
            />
            <p class={styles.describe}>抱歉，您访问的页面出现错误了</p>
            <div>
              <ssu-button type="primary" onClick={() => history.go(-1)}>
                返回上一页
              </ssu-button>
            </div>
          </div>
        </div>
      )
    }
  },
})

export default NotFound
