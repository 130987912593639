/*
 * @Author: 莫靓仔
 * @description: 自定义拖拽指令
 * @Date: 2020-12-04 18:16:46
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-02 17:15:31
 */
import { getStyle, addClass, isType } from './domHandle' 
export const bind = function (el , binding ) {
    let dragNode = el //当前元素
    dragNode.onmousedown = function (e) {
        let parent = el.parentNode 
        // 给el父级元素添加position属性 (offsetLeft等属性是基于父级定位元素计算的)
        let position = getStyle(parent, 'position') || ''
        if (position !== 'fixed' && position !== 'absolute') {
            addClass(parent, 'public-relative')
        }
        // 给el添加position属性
        let el_position = getStyle(el, 'position') || ''
        if (el_position !== 'fixed' && el_position !== 'absolute') {
            addClass(el, 'public-absolute')
        }
        e.preventDefault()

        let bw, bh, disX, disY, ofX, ofY
        let targetNode

        // 边界为body
        if (binding.value === true) {
            bw = document.body.clientWidth
            bh = document.body.clientHeight
            //鼠标按下，计算当前元素距离可视区的距离
            disX = e.clientX - dragNode.offsetLeft
            disY = e.clientY - dragNode.offsetTop
            ofX = e.offsetX
            ofY = e.offsetY
        }
        // 无边界
        else if (binding.value === false) {
            //鼠标按下，计算当前元素距离可视区的距离
            bw = document.body.clientWidth
            bh = document.body.clientHeight
            //鼠标按下，计算当前元素距离可视区的距离
            disX = e.clientX - dragNode.offsetLeft
            disY = e.clientY - dragNode.offsetTop
        }
        // 边界为目标元素
        else if (binding.value && isType(binding.value, 'String')) {
            targetNode =
                document.querySelectorAll(binding.value) &&
                document.querySelectorAll(binding.value)[0]
            bw = targetNode && targetNode.clientWidth + targetNode.getBoundingClientRect().left
            bh = targetNode && targetNode.clientHeight + targetNode.getBoundingClientRect().top
            //鼠标按下，保存当前元素距离可视区的距离
            disX = e.clientX - dragNode.offsetLeft
            disY = e.clientY - dragNode.offsetTop
            ofX = e.offsetX
            ofY = e.offsetY
        }
        // 边界为父元素
        else {
            //鼠标按下，保存当前元素距离可视区的距离
            disX = e.clientX - dragNode.offsetLeft
            disY = e.clientY - dragNode.offsetTop
        }
        // 计算两边坐标
        document.onmousemove = function (e) {
            let left_p = 0,
                top_p = 0
            // body为边界
            if (binding.value === true) {
                if (e.clientX - ofX >= bw - dragNode.clientWidth) {
                    left_p = bw - dragNode.clientWidth - parent.getBoundingClientRect().left
                } else if (e.clientX - ofX <= 0) {
                    left_p = -parent.getBoundingClientRect().left
                } else {
                    left_p = e.clientX - disX
                }
                if (e.clientY - ofY >= bh - dragNode.clientHeight) {
                    top_p = bh - dragNode.clientHeight - parent.getBoundingClientRect().top
                } else if (e.clientY - ofY <= 0) {
                    top_p = -parent.getBoundingClientRect().top
                } else {
                    top_p = e.clientY - disY
                }
                dragNode.style.left = left_p + 'px'
                dragNode.style.top = top_p + 'px'
            }
            // 无边界
            else if (binding.value === false) {
                // 拖动边界
                if (e.clientX >= bw) {
                    left_p = bw - disX
                } else if (e.clientX <= 0) {
                    left_p = 0 - disX
                } else {
                    left_p = e.clientX - disX
                }
                if (e.clientY >= bh) {
                    top_p = bh - disY
                } else if (e.clientY <= 0) {
                    top_p = 0 - disY
                } else {
                    top_p = e.clientY - disY
                }
                dragNode.style.left = left_p + 'px'
                dragNode.style.top = top_p + 'px'
            }
            // 边界为目标元素
            else if (binding.value && isType(binding.value, 'String')) {
                if (e.clientX - ofX >= bw - dragNode.clientWidth) {
                    left_p = bw - parent.getBoundingClientRect().left - dragNode.clientWidth
                } else if (e.clientX - ofX <= targetNode.getBoundingClientRect().left) {
                    left_p = targetNode.getBoundingClientRect().left - parent.getBoundingClientRect().left
                } else {
                    left_p = e.clientX - disX
                }
                if (e.clientY - ofY >= bh - dragNode.clientHeight) {
                    top_p = bh - parent.getBoundingClientRect().top - dragNode.clientHeight
                } else if (e.clientY - ofY <= targetNode.getBoundingClientRect().top) {
                    top_p = targetNode.getBoundingClientRect().top - parent.getBoundingClientRect().top
                } else {
                    top_p = e.clientY - disY
                }
                dragNode.style.left = left_p + 'px'
                dragNode.style.top = top_p + 'px'
            }
            // 边界为父元素
            else {
                //移动当前元素
                left_p = e.clientX - disX
                top_p = e.clientY - disY
                // 左边界
                if (left_p <= 0) {
                    left_p = 0
                }
                // 上边界
                if (top_p <= 0) {
                    top_p = 0
                }
                // 最大top值
                let top_l = parent.clientHeight - dragNode.clientHeight
                // 最打left值
                let left_l = parent.clientWidth - dragNode.clientWidth
                // 下边界
                if (left_p >= left_l) {
                    left_p = left_l
                }
                // 右边界
                if (top_p >= top_l) {
                    top_p = top_l
                }
                dragNode.style.left = left_p + 'px'
                dragNode.style.top = top_p + 'px'
            }
            // 鼠标停止移动时，事件移除
            document.onmouseup = function () {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
        document.onmouseup = function () {
            document.onmousemove = null
            document.onmouseup = null
        }
    }
}

export default {
    mounted: bind
}
