<!--
 * @Author: pzf
 * @description: 不可预览文件
 * @Date: 2022-03-14 15:26:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:50:00
-->
<template>
    <div class="empty-container">
        <img class="doc-img" :src="documentUrl" alt="附件" />
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { commonPreviewItemProps } from '../hooks/common'
import useShow from '../hooks/useShow'

export default defineComponent({
    props: { ...commonPreviewItemProps },
    setup(props) {
        const { showFileImage  } = useShow()
        const documentUrl = showFileImage({url: props.url, name: props.name})
        return {
            documentUrl
        }
    }
})
</script>
