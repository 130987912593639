import { GATEWAY_HOST, __DEV__ } from './constants'

/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-05-20 19:07:34
 * @LastEditors: pzf
 * @LastEditTime: 2022-05-26 15:42:05
 */
import { ElMessage } from 'element-plus'
import authHelper from './auth.helper'
import createClient from '@syc-paas/sdk'
// todo: 使用permission报错，暂时原因不明
import { getNoPermOption } from '../router/permission_bak'
import router from '../router'
import { valueStrategy } from '@syc-paas/utils'
import { RuntimePlatformEnv } from '@syc-paas/core'

const getStatusErrorText = valueStrategy(
  {
    400: '请求错误',
    401: '认证已失效',
    403: '暂无权限访问',
    404: '请求不存在',
    500: '服务器错误',
    502: '网关错误',
    504: '请求超时',
  },
  '未知错误'
)

const paasClient = createClient({
  baseURL: GATEWAY_HOST,
  getters: {
    token() {
      const { token } = authHelper.getUserInfo()
      return token
    },
  },
  onRequest(config) {
    const configAuthorization = config.headers['Authorization']
    const configTenantId = config.headers['TENANT-ID']
    const configEnv = config.headers['env']
    const { token, tenantId } = authHelper.getUserInfo()

    config.headers.env = configEnv || RuntimePlatformEnv.DESIGN
    config.headers.Authorization = configAuthorization || `Bearer ${token}`
    config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    config.headers['TENANT-ID'] = configTenantId || tenantId
    if (__DEV__) {
      config.headers['VERSION'] = 'GL'
    }
  },
  onResponse(res) {
    console.log(res)
  },
  onError(res) {
    if (res) {
      const errorText = getStatusErrorText(res.status)
      if (['401', '403'].includes(String(res.status))) {
        getNoPermOption()
      } else {
        let resultMsg = errorText
        const { message, errorMsg, msg } = res.data
        if (message || errorMsg || msg) {
          resultMsg = message || errorMsg || msg
        } else if (res.data?.data?.length) {
          // 为了更好的适配错误信息返回的情况（自动化执行错误时）
          resultMsg = res.data.data?.[0] || res.data.data
        }
        ElMessage.error(resultMsg)
      }
    }
  },
})

export default paasClient
