<!--
 * @Author: pzf
 * @description: 视频
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:50:35
-->
<template>
  <div ref="modal" class="video-container">
    <video controls @click.stop>
      <source :src="url" type="video/mp4" />
      <source :src="url" type="video/ogg" />
      您的浏览器不支持 HTML5 video 标签。
    </video>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { commonPreviewItemProps } from '../hooks/common'

export default defineComponent({
  props: { ...commonPreviewItemProps },
  setup() {
    return {}
  }
})
</script>
