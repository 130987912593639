import { defineComponent } from 'vue'
import styles from './PublicFormShare.module.scss'
import { usePublicFormShare } from './store'

const FormDetailRender = defineComponent({
  name: 'FormDetailRender',

  setup() {
    const {
      platformId,
      appId,
      pageCode,
      formRef,
      widgets,
      shareCode,
      isMobile,
      renderLoading,
      variables,
    } = usePublicFormShare()

    return () => {
      return (
        <div v-loading={renderLoading.value} class={styles.main}>
          {!!widgets.value.length && (
            <PageRender
              ref={formRef}
              widgets={widgets.value}
              isPublicRelease={true}
              publicReleaseShareCode={shareCode.value}
              isMobile={isMobile.value}
              variables={variables.value}
              platformId={platformId.value}
              appId={appId.value}
              pageCode={pageCode.value}
            />
          )}
        </div>
      )
    }
  },
})

export default FormDetailRender
