<!--
 * @Author: pzf
 * @description: pdf
 * @Date: 2022-03-14 15:26:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:50:26
-->
<template>
    <div class="pdf-container">
        <embed class="pdf" type="application/pdf" height="100%" width="100%" :src="url" />
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { commonPreviewItemProps } from '../hooks/common'

export default defineComponent({
    props: { ...commonPreviewItemProps },
    setup() {
        return {}
    }
})
</script>
