<!--
 * @Author: pzf
 * @description: 预览文件
 * @Date: 2022-01-19 13:29:48
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-13 16:02:03
-->
<template>
  <div class="slm-preview-demo">
    <div class="preview-box">
      <div v-if="previewVisible" class="preview-mask">
        <component
          :is="currentPreview.component"
          v-if="currentPreview.component"
          v-bind="currentPreview.attrs"
          v-on="currentPreview.on"
        />
        <slm-preview
          v-else
          :draggable="true"
          class="preview-content"
          :dict-label="dictLabel"
          :dict-value="dictValue"
          :preview-item="currentPreview"
        />
      </div>
      <div class="close-btn">
        <div class="icon-btn pointer" @click="closePreview">
          <i class="iconfont iconguanbi-nor"></i>
        </div>
      </div>
      <div
        v-show="currentPreview.url && previewArr?.length > 1"
        class="top-page"
      >
        {{ currentIdx + 1 }}/{{ previewArr?.length }}
      </div>
      <div class="layer" @click.stop>
        <div
          v-if="showLeft"
          class="toggle-left icon-btn pointer"
          @click="updatePreview(updateFlag.left)"
        >
          <i class="iconfont iconshangyiye"></i>
        </div>
        <div
          v-if="showRight"
          class="toggle-right icon-btn pointer"
          @click="updatePreview(updateFlag.right)"
        >
          <i class="iconfont iconxiayiye"></i>
        </div>
      </div>
      <div v-show="currentPreview.url" class="bottom-box">
        <!-- <span class="view-origin" @click="$emit('destroy')">{{ t('T_COMMON.close') }}</span> -->
        <span
          v-if="showReset"
          class="view-origin"
          @click="updatePreview(updateFlag.reset)"
        >
          复位
        </span>
        <ssu-button
          v-if="!currentPreview[dictLabel] && download"
          class="view-origin"
          @click="() => handleDownload(currentPreview[dictValue])"
          >下载</ssu-button
        >
        <span class="view-origin" v-else-if="download">
          <ssu-download
            :file-url="currentPreview[dictValue]"
            :file-name="currentPreview[dictLabel]"
          >
            下载
          </ssu-download>
        </span>

        <!-- <a
          v-if="!currentPreview[dictLabel] && download"
          class="view-origin"
          target="_blank"
          :href="currentPreview[dictValue]"
          @click.stop
        >
          下载
        </a> -->
        <!-- <span class="view-origin" v-else-if="download">
          <ssu-download
            :file-url="currentPreview[dictValue]"
            :file-name="currentPreview[dictLabel]"
          >
            下载
          </ssu-download>
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, nextTick } from 'vue'
import slmPreview from './components/previewItem/index.vue'
import { getComponentName } from './components/previewItem/hooks/preview-type'
import { SsuDownload } from '@syc-saas/ui'

export default defineComponent({
  name: 'SlmPreviewBox',
  components: { slmPreview, SsuDownload },
  props: {
    previewArr: {
      type: Array,
      default: () => {
        return []
      },
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    dictLabel: {
      type: String,
      default: 'name',
    },
    dictValue: {
      type: String,
      default: 'url',
    },
    download: {
      type: Boolean,
      default: true,
    },
    isApp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['destroy'],
  setup(props, context) {
    const currentIdx = ref(props.currentIndex)
    const previewVisible = ref(true)
    const updateFlag = {
      left: -1,
      right: 1,
      reset: 0,
    }
    const currentPreview = computed(() => {
      if (props.previewArr) {
        return { ...props.previewArr[currentIdx.value] }
      } else {
        return {}
      }
    })
    // 打开预览
    // const changeVisible = () => {
    //   visible.value = !visible.value
    // }
    // 按钮的显示标识
    const showRight = computed(() => {
      return !!(currentIdx.value + 1 < props.previewArr.length)
    })
    const showLeft = computed(() => {
      return !!(currentIdx.value > 0)
    })
    const showReset = computed(() => {
      const url = currentPreview.value?.[props.dictValue]
      return !!(getComponentName(url) === 'displayImg')
    })
    // 切换预览视图显示
    const updatePreview = (status) => {
      previewVisible.value = false
      if (
        currentIdx.value + status + 1 > props.previewArr.length ||
        currentIdx.value + status < 0
      ) {
        currentIdx.value = 0
      }
      currentIdx.value = currentIdx.value + status

      nextTick(() => {
        previewVisible.value = true
      })
    }

    const closePreview = () => {
      context.emit('destroy')
    }

    const handleDownload = (url) => {
      if (!url) {
        return
      }

      if (props.isApp) {
        window.open(
          `slmspro://webDownloadPDF?url=${url}&fileType=pdf`,
          '_blank'
        )
        return
      }

      window.open(url, '_blank')
    }
    return {
      currentIdx,
      currentPreview,
      previewVisible,
      showRight,
      showLeft,
      showReset,
      updateFlag,
      updatePreview,
      // changeVisible,
      closePreview,
      handleDownload,
    }
  },
})
</script>
<style lang="scss">
@import '../../../../apps/app/src/assets/scss/include/index.scss';

.slm-preview-demo {
  height: 800px;
}

.preview-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  height: 100%;

  .preview-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(4, 5, 13, 0.6);

    .preview-content {
      position: absolute;
      top: 40px;
      left: 50%;
      z-index: 10001;
      box-sizing: border-box;
      flex: auto;
      width: calc(100% - 208px);
      height: calc(100% - 40px - 106px);
      overflow: hidden;
      border: 2px solid #90939a;
      border-radius: 20px;
      transform: translateX(-50%);
      @include mobile-style {
        width: calc(100% - px2vw(208));
        height: calc(100% - px2vw(40) - px2vw(106));
      }
    }
  }
}
// 关闭弹窗按钮
.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10001;
}
// 上方页码
.top-page {
  position: absolute;
  top: 10px;
  z-index: 10001;
  width: 100%;
  font-size: 16px;
  color: rgba(192, 196, 206, 1);
  text-align: center;
}

.icon-btn {
  width: 44px;
  height: 44px;
  color: #c0c4ce;
  background-color: rgba(144, 147, 154, 0.5);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(144, 147, 154, 0.8);
  }
}

.layer {
  .toggle-left,
  .toggle-right {
    position: absolute;
    top: 50%;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }

  .toggle-left {
    left: 40px;
  }

  .toggle-right {
    right: 40px;
  }

  i {
    font-size: 20px;
  }
}

.bottom-box {
  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: 10010;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  font-size: 16px;
  background-color: rgba(144, 147, 154, 0.5);
  border: none;
  border-radius: 22px;
  transform: translateX(-50%);

  .view-origin {
    padding: 0 16px;
    padding: 0 20px;
    color: rgba(192, 196, 206, 1);
    text-decoration: none;
    cursor: pointer;
    border-right: 1px solid rgba(192, 196, 206, 0.3);

    &:hover {
      color: rgba(236, 238, 244, 1);
    }
  }

  .view-origin:last-child {
    border: none;
  }
}
</style>
