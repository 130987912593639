/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-06-08 16:58:26
 * @LastEditors: pzf
 * @LastEditTime: 2022-06-20 17:52:59
 */
import { computed, defineComponent, ref, unref } from 'vue'
import { useCacheProvider, useSdkProvider } from '@syc-paas/core'
import { useLibraryManagerProvide, usePaaSConfigProvider } from '@syc-paas/core'

import { Language } from './locale/index'
import componentLibraries from './lib/componentLibrary'
import en from 'element-plus/lib/locale/lang/en'
import paasClient from './lib/paasClient'
import { useI18n } from 'vue-i18n'
import { useQueryProvider } from 'vue-query'
import { useRouter } from 'vue-router'
import { useRuntimeVariables } from './hooks/useRuntimeVariables'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
const elementLocalMap = {
  [Language.ZH_CN]: zhCn,
  [Language.EN]: en,
}

export const basicGlobalZIndex = 2000

const App = defineComponent({
  setup() {
    const { locale } = useI18n()
    const elLocale = computed(() => {
      return elementLocalMap[locale.value]
    })

    const router = useRouter()

    useLibraryManagerProvide(componentLibraries)
    useQueryProvider({
      defaultOptions: {
        queries: {
          retry: 2,
          refetchOnWindowFocus: false,
          cacheTime: 0,
        },
        mutations: {
          retry: false,
        },
      },
    })
    useCacheProvider({
      expire: 5 * 60 * 1000,
      initialGroups: ['member', 'department'],
    })
    useSdkProvider(paasClient)

    usePaaSConfigProvider({
      handleSignClick() {
        router.push('/h5/sign')
      },
      getGlobalVariables() {
        const variables = useRuntimeVariables()

        return variables
      },
    })
    return () => {
      return (
        <el-config-provider locale={elLocale.value} zIndex={basicGlobalZIndex}>
          {/* 平台直接默认zh（中文）即可 */}
          <ssu-config-provider locale={elLocale.value}  allowJsTransmissionBox={true} allowJsDialog={true}>
            <router-view></router-view>
          </ssu-config-provider>
        </el-config-provider>
      )
    }
  },
})
export default App
