/*
 * @Author: 莫靓仔
 * @description:
 * @Date: 2020-12-07 14:45:37
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-06 09:28:08
 */
import { addClass, removeClass } from './domHandle'
 

let stretchDataMap = {} // 缩放元素的数据集合，可能有很多个元素都用了这个指令，每个元素使用独立的数据
let stretchNode // 缩放的元素
let stretchData // 目标元素的各种数据

export const init = function (el, binding) {
    // 保存dom标识
    let obj = {
        key: getDataKey(el.dataset), // 唯一标志，如果是同一个标签则无效（如img标签，url是动态的，这种情况获取到的el事件对象只能是同一个img）
        variation: 0, // 滚轮变化量
        originHeight: null, // 图片的原始高度
        originWidth: null, // 图片的原始高度
        stretchNode: null, // 缩放的元素
        noScrollList: [], // 禁止滚动的选择器列表
        reduceCount: 5, // 缩小系数
        addCount: 5, // 放大系数
        minMultiples: 3, // 缩小倍数
        maxMultiples: 20, // 放大倍数
        minPx: 20, // 最小高度，单位px 默认20px
        maxPx: 999999 // 最大高度，单位px，默认不限制
    }

    // 保存数据
    let key = el.dataset
    stretchDataMap[getDataKey(key)] = obj
    stretchData = stretchDataMap[getDataKey(key)] || {}

    // 禁止传入的元素滚动
    if (
        binding.value &&
        binding.value.noScrollList &&
        binding.value.noScrollList instanceof Array &&
        binding.value.noScrollList.length
    ) {
        stretchData.noScrollList = binding.value.noScrollList
    }
    // 放大系数
    stretchData.addCount = (binding.value && binding.value.addCount) || 5
    // 缩小系数
    stretchData.reduceCount = (binding.value && binding.value.reduceCount) || 5
    // 最小缩小倍数
    stretchData.minMultiples = (binding.value && binding.value.minMultiples) || 3
    // 最大放大倍数
    stretchData.maxMultiples = (binding.value && binding.value.maxMultiples) || 20
    // 当前元素
    stretchNode = el

    // 鼠标移入图片
    stretchNode.onmouseover = function (e) {
        let thisKey = getDataKey((e.target).dataset)
        stretchDataMap[getDataKey(key)] = obj
        stretchData = stretchDataMap[thisKey]
        // 给传入的父级元素添加overflow:hidden
        stretchData.noScrollList.length &&
            stretchData.noScrollList.forEach(item => {
                let node = document.querySelector(item)
                node && addClass(node, 'public-overflow-hidden')
            })
        if (!stretchData.originHeight) {
            stretchData.originHeight = (e.target).clientHeight
        }
        if (!stretchData.originWidth) {
            stretchData.originWidth = (e.target).clientWidth
        }
        //兼容火狐
        if (/(?:Firefox)/.test(navigator.userAgent)) {
            stretchNode.addEventListener('DOMMouseScroll', wheel, false)
        } else {
            // stretchNode.addEventListener('mousewheel', wheel, false)
            stretchNode.onmousewheel = wheel
        }
    }
    // 移除监听器
    stretchNode.onmouseout = function () {
        //兼容火狐
        if (/(?:Firefox)/.test(navigator.userAgent)) {
            stretchNode.removeEventListener('DOMMouseScroll', wheel, false)
        } else {
            stretchNode.onmousewheel = null
            ;(document).onmousewheel = null
            // stretchNode.removeEventListener('mousewheel', wheel, false)
        }
        // 移除overflow:hidden
        stretchData.noScrollList.length &&
            stretchData.noScrollList.forEach(item => {
                let node = document.querySelector(item)
                node && removeClass(node, 'public-overflow-hidden')
            })
    }
}

const wheel = function (e) {
    let delta = 0
    e = e || window.event
    if (e.wheelDelta) {
        delta = e.wheelDelta / 120 // IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
        if ((window).opera) {
            delta = -delta // 因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
        }
    } else if (e.detail) {
        delta = -e.detail / 3 //FF浏览器使用的是detail,其值为“正负3”
    }
    if (delta) {
        let compensation = stretchData.reduceCount / 100 // 滚一次setHeight的变化是reduceCount，补差为reduceCount/100
        if (delta < 0) {
            //向下滚动，缩小
            if (
                1 - compensation + stretchData.variation / 100 >= 0.5 ** stretchData.minMultiples &&
                (1 + stretchData.variation / 100) * stretchData.originHeight >= stretchData.minPx
            ) {
                stretchData.variation = stretchData.variation - stretchData.reduceCount
            }
        } else {
            //向上滚动，放大
            if (
                1 + compensation + stretchData.variation / 100 <= stretchData.maxMultiples &&
                (1 + stretchData.variation / 100) * stretchData.originHeight <= stretchData.maxPx
            ) {
                stretchData.variation = stretchData.variation + stretchData.addCount
            }
        }
        // 设置高度
        let setHeight = (1 + stretchData.variation / 100) * stretchData.originHeight
        let setWidth = (1 + stretchData.variation / 100) * stretchData.originWidth
        stretchNode.style.height = setHeight + 'px'
        stretchNode.style.width = setWidth + 'px'
    }
}

// 获取el事件对象的唯一标志(针对同一个元素无效)
const getDataKey = function (dataset) {
    let keysList = Object.keys(dataset)
    let id = keysList.find(item => item === 'id')
    if (id) {
        return dataset[id]
    } else {
        let resKey = keysList.find(item => item.indexOf('v-') > -1)
        if (resKey) {
            return resKey
        } else {
            return `v-${new Date().getTime()}`
        }
    }
}

export default {
    mounted: init,
    beforeUpdate: init // 不能去掉，因为这个指令多数情况都是用于同一个img标签切换图片，必须用update监听binding.value的变化
}
