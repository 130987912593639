import { computed } from 'vue'
import useAuthStore from '../store/auth'

export const useRuntimeVariables = (data = {}) => {
  const { userInfo } = useAuthStore()
  const variables = computed(() => {
    return {
      currentUserId: userInfo.id,
      currentTenantId: userInfo.tenantId,
      ...data,
    }
  })
  return variables
}
