/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-07-28 17:38:35
 * @LastEditors: pzf
 * @LastEditTime: 2022-08-09 17:45:28
 */
import { computed, ref } from 'vue'

import { createLiteStore } from '@syc-paas/core'
import paasClient from '@/lib/paasClient'
import { useRoute } from 'vue-router'

export const PlatformLayoutStore = createLiteStore(() => {
  const platform = ref({})
  const route = useRoute()
  const platformName = computed(() => {
    return platform.value.name
  })
  const platformType = computed(() => {
    return platform.value.type
  })

  const platformCode = computed(() => {
    return platform.value.code
  })

  const platformId = computed(() => route.params.platformId)
  const getPlatformDetail = async () => {
    platform.value = await paasClient.admin.getPlatformDetail(platformId.value)
  }
  return {
    platform,
    platformName,
    platformType,
    platformCode,
    getPlatformDetail,
  }
}, 'platformLayout')

export const usePlatformLayoutStore = () => PlatformLayoutStore.useInject()
