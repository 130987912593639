import { AppPageLayoutStore } from './AppPageLayoutStore'
import { RouterView } from 'vue-router'
// import Sidebar from './Sidebar/Sidebar'
import { defineComponent } from 'vue'
import styles from './AppPageLayout.module.scss'

const AppPageLayout = defineComponent({
  name: 'AppPageLayout',
  setup() {
    AppPageLayoutStore.useProvide()
    return () => {
      return (
        <div class={styles.container}>
          {/* <Sidebar class={styles.sidebar} /> */}
          {/* <div class={styles.content}> */}
          <RouterView />
          {/* </div> */}
        </div>
      )
    }
  },
})

export default AppPageLayout
