<!--
 * @Author: pzf
 * @description: 可预览的文件类型
 * @Date: 2022-03-14 15:26:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:50:49
-->
<template>
    <div class="frame-container">
        <div v-loading="frameLoading" class="frame-loading">
            <iframe
                :src="getFilePreviewUrl(url)"
                width="100%"
                height="100%"
                @onreadystatechange="frameLoad"
                @load="frameLoad"
            />
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { vLoading } from 'element-plus/es/components/loading/src/directive'
import { commonPreviewItemProps } from '../hooks/common'
import { getFilePreviewUrl } from '@syc-paas/utils'

export default defineComponent({
    directives: {
        loading: vLoading
    },
    props: { ...commonPreviewItemProps },
    setup() {
        const frameLoading = ref(true)
        const frameLoad = () => {
            frameLoading.value = false
        }
        return { frameLoad, frameLoading, getFilePreviewUrl }
    }
})
</script>
