/*
 * @Author: pzf
 * @description: 显示对应文件类型的图标
 * @Date: 2022-06-09 17:03:59
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:48:05
 */
import config from './config'
export default () => {
  const getFileFormat = (file) => {
    let index = file.name.lastIndexOf('.') // 获取文件最后一个点的位置
    return file.name.substring(index + 1) // 获取文件后缀 例如:jpg、 mp4
  }
  // 获取前面
  const getFileName = (file) => {
    let index = file.name.lastIndexOf('.') // 获取文件最后一个点的位置
    return file.name.substring(0, index) // 获取文件后缀 例如:jpg、 mp4
  }

  // 获取前面
  const getFileSize = (file = {}) => {
    if (!file.size) {
      return ''
    }

    const mUnit = 'M'
    const kUnit = 'K'
    const mUnitSize = 1024 * 1024
    const kUnitSize = 1024

    if (file.size / mUnitSize > 1) {
      return `(${(file.size / mUnitSize).toFixed(2)}${mUnit})`
    }

    return `(${(file.size / kUnitSize).toFixed(2)}${kUnit})`
  }

  // 根据后缀显示图片
  const showFileImage = (file) => {
    if (!file.url) {
      return
    }

    let fileFormat = getFileFormat(file)

    // 根据文件名后缀显示不一样的图片
    let mixTypeObj = config.mixTypeObj
    let imgArr = config.imgArr

    if (
      imgArr.includes(fileFormat) ||
      imgArr.includes(fileFormat.toLowerCase())
    ) {
      return file.url
    }
    // 获取显示的对象
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let showObj =
      mixTypeObj.find((item) => {
        return item.typeArr.includes(fileFormat)
      }) || {}
    let imgSrc =
      showObj.icon ||
      'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_documentation.png'
    return imgSrc
  }
  return { showFileImage, getFileFormat, getFileName, getFileSize }
}
