import { RuntimePlatformEnv, WidgetTree, createLiteStore } from '@syc-paas/core'
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { ElMessage } from 'element-plus'
import { PUBLIC_RELEASE_WIDGETS_FILTER_LIST } from '@/lib/enum'
import { isNotEmptyArray } from '@syc-paas/utils'
import paasClient from '../../lib/paasClient'
import { useRuntimeVariables } from '../../hooks/useRuntimeVariables'
import wx from 'weixin-js-sdk'
import openPreviewBox from '@syc-paas/components/src/FilePreview/create-preview-box'
import { isApp } from '../../utils/device'

const PublicFormShareStore = createLiteStore(({ props, ctx }) => {
  const variables = useRuntimeVariables()

  const route = useRoute()

  const shareCode = computed(() => route.query.shareCode)

  const env = computed(() => route.query.env)

  const modelCode = computed(() => route.query.modelCode)

  const processInstanceId = computed(() => route.query.processInstanceId)

  const nodeId = computed(() => route.query.nodeId)

  const appId = computed(() => route.query.appId)

  const pageCode = computed(() => route.query.pageCode)

  const platformId = computed(() => route.query.platformId)

  const widgets = ref([])

  const formRef = ref(null)

  const insertForm = ref()

  const isSubmitSuccess = ref(false)

  const formName = ref('')

  const formStyleConfig = ref({})

  const publicConfig = ref({})

  const wechatConfig = ref({})

  const questionnaireInfos = ref({})

  const loading = ref(false)

  const renderLoading = ref(false)

  const appraiseOpenDetails = ref({})

  let publicReleaseShareCodeMap = {}

  const isFailure = ref(false)

  const isMobile = computed(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  })

  const pageBgColor = computed(() => {
    return formStyleConfig.value.backgroundColor
      ? formStyleConfig.value.backgroundColor
      : '#ECEEF4'
  })

  const pageBgImage = computed(() => {
    if (
      formStyleConfig.value.type === 'custom' &&
      formStyleConfig.value.backgroundImage
    ) {
      return formStyleConfig.value.backgroundImage
    }
    return require('@/assets/images/form-style-config-bg-pc.png')
  })

  const handleClickSubmit = () => {
    const formInstance = formRef.value.getInnerRef()
    formInstance?.validate().then(() => {
      const data = formInstance.getFormValue()
      let _dataList = Object.keys(data)
      //  对元数据特定排序
      _dataList.length &&
        _dataList.forEach((v) => {
          if (_.isArray(data[v])) {
            data[v] = _.sortBy(data[v])
          }
        })
      if (modelCode.value && processInstanceId.value) {
        saveEvaluation(data)
        return
      }
      if (
        publicConfig.value.times === 'one' &&
        publicReleaseShareCodeMap[shareCode.value] === shareCode.value
      ) {
        ElMessage.warning('已提交过数据，无法再次提交')
        return
      }
      submitPublicReleaseInfo(data)
    })
  }

  const fetchAppraiseInfo = () => {
    paasClient.process
      .getAppraiseOpenDetails(
        {
          shareCode: shareCode.value,
          processInstanceId: processInstanceId.value,
          nodeId: nodeId.value,
        },
        {
          headers: {
            env: RuntimePlatformEnv.RUNTIME,
          },
        }
      )
      .then((res) => {
        appraiseOpenDetails.value = res
      })
  }

  const getData = () => {
    fetchAppraiseInfo()
    getPublicReleaseInfoByShareCode()
  }

  /* 接口 */
  // 获取业务规则列表
  // const getOpenFormRuleByShareCode = () => {
  //   return paasClient.metaData.getOpenFormRuleByShareCode({
  //     shareCode: shareCode.value,
  //   })
  // }

  // 容器组件递归删除(容器内也可容器，[[[...]]])
  const delDeepWidgets = (widgets, delId) => {
    if (!isNotEmptyArray(widgets)) {
      return widgets
    }
    const walkFilter = (walkItem, index, arr) => {
      if (!isNotEmptyArray(walkItem)) {
        return walkItem.id !== delId
      }
      arr[index] = walkItem.filter(walkFilter)
      return !_.isEmpty(arr[index])
    }
    return widgets.filter(walkFilter)
  }

  // 过滤掉不显示的widgets
  const filterWidgets = (widgets) => {
    const widgetTree = new WidgetTree(widgets)
    widgetTree.each((item, index, parentWidget) => {
      const isFilterWidgets = PUBLIC_RELEASE_WIDGETS_FILTER_LIST.includes(
        item.code
      )

      // 如果有则过滤掉对应的children
      if (isNotEmptyArray(parentWidget?.children) && isFilterWidgets) {
        delDeepWidgets(parentWidget.children, item.id)
        parentWidget.children = parentWidget.children.filter((i) => !_.isEmpty(i))
        return
      }

      // 如果没有parentWidget说明是根widgets，就直接过滤根widgets
      if (!parentWidget && isFilterWidgets) {
        widgetTree.list = widgetTree.list.filter((i) => i.id !== item.id)
        return
      }
    })
    return widgetTree.list
  }

  // 获取元数据配置
  const getOpenMetaDataByShareCode = async () => {
    renderLoading.value = true
    paasClient.metaData
      .getOpenMetaDataByShareCode({
        body: {
          shareCode: shareCode.value,
        },
        headers: { env: env.value },
      })
      .then((res) => {
        if (!res) {
          isFailure.value = true
          return
        }
        const { content } = res
        isNotEmptyArray(content.widgets) &&
          (widgets.value = filterWidgets(content.widgets))
        isFailure.value = false
      })
      .finally(() => {
        renderLoading.value = false
      })
  }

  // 获取公开表单配置
  const getPublicReleaseInfoByShareCode = () => {
    loading.value = true
    paasClient.metaData
      .getPublicReleaseInfoByShareCode({
        body: {
          shareCode: shareCode.value,
        },
        headers: { env: env.value },
      })
      .then((res = {}) => {
        if (!res) {
          isFailure.value = true
          return
        }
        questionnaireInfos.value = res
        formName.value = res.formName ?? ''
        formStyleConfig.value = res.formStyleConfig ?? {}
        publicConfig.value = res.publicConfig ?? {}
        getOpenMetaDataByShareCode()
        wechatConfig.value = res?.wechatConfig ?? {}
        // 配置微信自定义分享卡片
        configWechatShareCardStyle()
        isFailure.value = false
      })
      .finally(() => {
        loading.value = false
      })
  }

  // 保存表单数据
  const submitPublicReleaseInfo = (formValue) => {
    const params = {
      body: { dto: { shareCode: shareCode.value }, insertForm: formValue },
      headers: { env: env.value },
    }
    paasClient.form.submitPublicReleaseInfo(params).then((res) => {
      isSubmitSuccess.value = true
      publicReleaseShareCodeMap[shareCode.value] = shareCode.value
      localStorage.setItem(
        'publicReleaseShareCodeMap',
        JSON.stringify(publicReleaseShareCodeMap)
      )
    })
  }

  //保存评论
  const saveEvaluation = (formContent) => {
    // 调用保存评论
    const params = {
      body: {
        formContent: formContent,
        modelCode: modelCode.value,
        processInstanceId: processInstanceId.value,
        appId: appId.value,
        nodeId: nodeId.value,
        shareCode: shareCode.value,
      },
      headers: { env: env.value },
    }
    paasClient.process.postAppraise(params).then((res) => {
      isSubmitSuccess.value = true
    })

    // 同时也掉用openApi的接口，但不知道有啥后果，这个为了解决问卷统计问题，用saveEvaluationInfo无法统计
    // submitPublicReleaseInfo(formContent)
  }

  // 微信分享配置
  const configWechatShareCardStyle = () => {
    let url = encodeURIComponent(window.location.href.split('#')[0])
    // 获取签名
    paasClient.admin
      .getWechatShareSign({ url })
      .then((res) => {
        const { appId, nonceStr, signAture, ticket, timesTamp } = res
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: appId, // 必填，公众号的唯一标识
          timestamp: timesTamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signAture, // 必填，签名（这里用上面第三步得到的签名）
          jsApiList: [
            'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
            'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容
          ], // 必填，需要使用的 JS 接口列表
        })

        //分享给朋友
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: wechatConfig?.value?.title ?? '', // 分享标题
            desc: '', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: wechatConfig?.value?.logoUrl ?? '', // 分享图标
            success: function () {},
          })
          // 分享朋友圈
          wx.updateTimelineShareData({
            title: wechatConfig?.value?.title ?? '', // 分享标题
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: wechatConfig?.value?.logoUrl ?? '', // 分享图标
            success: function () {},
          })
        })
        wx.error(function (error) {
          console.log(error)
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onClickPrintTemplate = () => {
    if (!appraiseOpenDetails.value?.printTemplatePdfUrl) {
      return
    }
    openPreviewBox({
      previewArr: [{ url: appraiseOpenDetails.value?.printTemplatePdfUrl }],
      isApp: isApp(),
    })
  }

  onMounted(() => {
    publicReleaseShareCodeMap = localStorage.getItem(
      'publicReleaseShareCodeMap'
    )
      ? JSON.parse(localStorage.getItem('publicReleaseShareCodeMap'))
      : {}
    getData()
  })

  return {
    isMobile,
    shareCode,
    widgets,
    formRef,
    formName,
    pageBgColor,
    pageBgImage,
    insertForm,
    isSubmitSuccess,
    loading,
    renderLoading,
    variables,
    pageCode,
    appId,
    platformId,
    appraiseOpenDetails,
    isFailure,
    handleClickSubmit,
    getData,
    onClickPrintTemplate,
  }
})

export const usePublicFormShareProvider = (state) => {
  return PublicFormShareStore.useProvide(state)
}

export const usePublicFormShare = () => PublicFormShareStore.useInject()
