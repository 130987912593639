/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-06-07 10:59:31
 * @LastEditors: pzf
 * @LastEditTime: 2022-06-07 19:59:29
 */
import { RouterView, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue'

import { AutomationLayoutStore } from './AutomationLayoutStore'
import BaseHeader from '../../components/BaseHeader/BaseHeader'
import BaseHeaderBack from '../../components/BaseHeaderBack/BaseHeaderBack'
import { safeCallFunction } from '@syc-paas/utils'
import styles from './AutomationLayout.module.scss'
import { useLayoutActiveRoute } from '../../hooks/useLayoutActiveRoute'

const AutomationLayout = defineComponent({
  name: 'AutomationLayout',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isRefresh = ref(false)
    const { platformId, appId, automationId, automationCode } = route.params
    const { renderButton, pageData, isLoading, fetchFlowDetail } =
      AutomationLayoutStore.useProvide()

    const menus = computed(() => {
      return [
        { label: '自动化编排', name: 'orchestration', key: 'orchestration' },
        { label: '执行记录', name: 'records', key: 'records' },
      ].filter((item) => !!item)
    })

    const layoutPrefix = computed(() => {
      return `/platform/${platformId}/app/${appId}/automation/${automationId}/code/${automationCode}`
    })
    const activeTab = useLayoutActiveRoute(layoutPrefix.value)

    const handelClickTab = (tab) => {
      router.push(`${layoutPrefix.value}/${tab.props.name}`)
    }

    const back = () => {
      router.push(`/platform/${platformId}/app/${appId}/automation`)
    }
    onMounted(() => {
      watch(
        () => route.path,
        async (v) => {
          if (v.startsWith(layoutPrefix.value)) {
            isRefresh.value = true
            await fetchFlowDetail()
            isRefresh.value = false
          }
        }
      )

      provide('automationActiveTab', activeTab)
    })

    return () => {
      return (
        <div
          class={styles.container}
          v-loading={isLoading.value && !isRefresh.value}
        >
          <BaseHeader
            vSlots={{
              left: () => {
                return (
                  <BaseHeaderBack
                    onClick={back}
                    v-model={pageData.value.name}
                    modelValue={pageData.value?.name}
                  />
                )
              },
              middle: () => {
                return (
                  <ssu-tab-list
                    v-model={activeTab.value}
                    plain
                    class={styles.tabs}
                    margin={{ bottom: 0 }}
                    onTabClick={(tab) => {
                      handelClickTab(tab)
                    }}
                    list={menus.value}
                    disabledMore={true}
                  ></ssu-tab-list>
                )
              },
              right: () => {
                return (
                  <el-space>{safeCallFunction(renderButton.value)}</el-space>
                )
              },
            }}
          />
          <div class={styles.main}>
            {isRefresh.value ? (
              <RouterView />
            ) : !isLoading.value ? (
              <RouterView />
            ) : null}
          </div>
        </div>
      )
    }
  },
})

export default AutomationLayout
