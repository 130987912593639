<!--
 * @Author: pzf
 * @description: 音频
 * @Date: 2022-03-14 15:26:36
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:49:50
-->
<template>
    <div class="audio-container">
        <audio controls>
            <source :src="url" type="audio/ogg" />
            <source :src="url" type="audio/mpeg" />
            您的浏览器不支持 audio 元素。
        </audio>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { commonPreviewItemProps } from '../hooks/common'

export default defineComponent({
    props: { ...commonPreviewItemProps },
    setup() {
        return {}
    }
})
</script>
