import { defineComponent as e, resolveDirective as a, openBlock as t, createElementBlock as l, withDirectives as o, ref as i, getCurrentInstance as n, inject as r, computed as s, reactive as u, createApp as d, toRefs as p, h as m, Transition as c, withCtx as v, createVNode as f, vShow as g, nextTick as b, isRef as h, createElementVNode as y, createTextVNode as D, withModifiers as C, onMounted as w, onBeforeUnmount as S, createBlock as L, resolveDynamicComponent as T, resolveComponent as V, mergeProps as E, toHandlers as I, createCommentVNode as F, toDisplayString as O, isVNode as N, render as M, Fragment as R, renderList as x, normalizeClass as A, provide as k, watch as P, unref as _, onUpdated as U, onUnmounted as B, renderSlot as W, watchEffect as q, normalizeStyle as j, createSlots as $ } from "vue";

import { mixins as z, statusConfigOptions as G, utils as H, RuntimeEnv as Q, tableEmptyText as Y, WidgetCode as K, WidgetGroup as X, WidgetType as J, DataType as Z, Operator as ee, baseOperatorCalc as ae, OptionSetCode as te, useSdk as le, SELECT_DATA_SOURCE_TYPE as oe, tableTextSeparator as ie, useCacheGroup as ne, RADIO_CHECKBOX_DATA_SOURCE_OPTIONS as re, dateFormatOptions as se, DATETIME_SELECT_RANGE_FIELD_NAME_MAP as ue, dateRange as de, departmentModeOptions as pe, commonModeOptions as me, imageAcceptModeOptions as ce, dynamicDataContext as ve, RuntimePlatform as fe, WidgetTree as ge, useLibraryManager as be, SELECT_DATA_SOURCE_OPTIONS as he, DEFAULT_DEFAULT_ROW_NUM as ye, numberAppendTypeOptions as De, numberFormatOptions as Ce, numberAppendTypeMap as we, radioFormatTypeOptions as Se, rateConfig as Le, createEntityStore as Te, createLiteStore as Ve, useCache as Ee, JsonRuleCondition as Ie, DefaultValueEngine as Fe, PageEngine as Oe, BusinessRuleEngine as Ne, StatusPriorityMap as Me, sourceTypeList as Re, connect as xe, isRelationFormCode as Ae, JsonRuleExecution as ke, BaseCache as Pe, isDateTimeCode as _e, isMemberCode as Ue, Mode as Be, RelationFormShowType as We, RELATION_FORM_ORIGIN_FLAG as qe, RELATION_FORM_SELECT_FLAG as je, RELATION_FORM_CONDITION_KEY as $e, RELATION_FORM_CONDITION_OTHER_KEY as ze, basicSortComponentsOptions as Ge, SYSTEM_TYPE as He, PageType as Qe, isSubFormCode as Ye, sequenceTypes as Ke, RuntimePlatformEnv as Xe, ValidatorHelper as Je, VARIABLE_FIELDS as Ze, backendJsonRuleType as ea, mergeJsonRule as aa, isStringTypeCode as ta, designerEventNameMap as la, apiKey as oa, DynamicDefaultValueType as ia, TabDisplayEnv as na, useVariableResolver as ra, inputFormatRegular as sa, SystemVariable as ua, FormulaEngine as da, formulaReturnTypeToDataType as pa, formulaReturnTypeOptions as ma } from "@syc-paas/core";

import { useElMessage as ca, useUpload as va, useElForm as fa, useOptions as ga, useWidgetPropCompat as ba, useDatetimeRangeSelect as ha, useDisplayStyleRules as ya, useWidgetProp as Da, usePersonRange as Ca, useConditionData as wa, useMitt as Sa, useFormExtraValue as La, useRelationFormQueryData as Ta, useRelationFormCache as Va, useRelationFormField as Ea, useDialog as Ia, useTableColumnWidth as Fa } from "@syc-paas/hooks";

import { isClient as Oa, useVModel as Na } from "@vueuse/core";

import { getFilePreviewUrl as Ma, toArray as Ra, optionsWrap as xa, isNotEmptyArray as Aa, recursionTree as ka, wrapStyles as Pa, safeCallFunction as _a, hasContentArray as Ua, getRawValue as Ba, formDataAdaptor as Wa, nanoid as qa, isAvailableData as ja, copyToClipboard as $a, objArrayToObj as za, listToMapWith as Ga, numberAmend as Ha } from "@syc-paas/utils";

import { SsuDownload as Qa, SsuUploadV2 as Ya, SsuMessage as Ka, SsuSelectField as Xa } from "@syc-saas/ui";

import Ja from "lodash";

import { Uploader as Za, Field as et, Popup as at, Picker as tt, ActionSheet as lt, Tabs as ot, Tab as it, Toast as nt, CheckboxGroup as rt, Checkbox as st, Rate as ut } from "vant";

import dt from "dayjs";

import pt from "sortablejs";

import mt from "axios";

import { useQuery as ct } from "vue-query";

import vt from "js-cookie";

import ft from "qrcode";

import gt from "signature_pad";

import { BaiduMap as bt, BmMarker as ht, BmLabel as yt } from "vue-baidu-map-3x";

import { ElMessageBox as Dt } from "element-plus";

const Ct = {
    url: {
        type: String,
        default: ""
    },
    name: {
        type: String,
        default: ""
    },
    draggable: {
        type: Boolean,
        default: !1
    }
};

function wt(e, a) {
    if (!e || !a) return !1;
    if (-1 !== a.indexOf(" ")) throw new Error("className should not contain space.");
    return e.classList ? e.classList.contains(a) : (" " + e.className + " ").indexOf(" " + a + " ") > -1;
}

function St(e, a) {
    if (e) {
        for (var t = e.className, l = (a || "").split(" "), o = 0, i = l.length; o < i; o++) {
            var n = l[o];
            n && (e.classList ? e.classList.add(n) : wt(e, n) || (t += " " + n));
        }
        e.classList || (e.className = t);
    }
}

const Lt = function(e, a) {
    if (!e || !a) return null;
    "float" === (a = Tt(a)) && (a = "cssFloat");
    try {
        var t = document.defaultView.getComputedStyle(e, "") || "";
        return e.style[a] || t ? t[a] : null;
    } catch (t) {
        return e.style[a];
    }
}, Tt = function(e) {
    return e.replace(/([:\-_]+(.))/g, (function(e, a, t, l) {
        return l ? t.toUpperCase() : t;
    })).replace(/^moz([A-Z])/, "Moz$1");
};

function Vt(e, a) {
    let t = Object.prototype.toString.call(e).replace(/^\[object (.*)\]$/, "$1");
    return a ? a === t : t;
}

let Et, It, Ft = {};

const Ot = function(e, a) {
    let t = {
        key: Mt(e.dataset),
        variation: 0,
        originHeight: null,
        originWidth: null,
        stretchNode: null,
        noScrollList: [],
        reduceCount: 5,
        addCount: 5,
        minMultiples: 3,
        maxMultiples: 20,
        minPx: 20,
        maxPx: 999999
    }, l = e.dataset;
    Ft[Mt(l)] = t, It = Ft[Mt(l)] || {}, a.value && a.value.noScrollList && a.value.noScrollList instanceof Array && a.value.noScrollList.length && (It.noScrollList = a.value.noScrollList), 
    It.addCount = a.value && a.value.addCount || 5, It.reduceCount = a.value && a.value.reduceCount || 5, 
    It.minMultiples = a.value && a.value.minMultiples || 3, It.maxMultiples = a.value && a.value.maxMultiples || 20, 
    Et = e, Et.onmouseover = function(e) {
        let a = Mt(e.target.dataset);
        Ft[Mt(l)] = t, It = Ft[a], It.noScrollList.length && It.noScrollList.forEach((e => {
            let a = document.querySelector(e);
            a && St(a, "public-overflow-hidden");
        })), It.originHeight || (It.originHeight = e.target.clientHeight), It.originWidth || (It.originWidth = e.target.clientWidth), 
        /(?:Firefox)/.test(navigator.userAgent) ? Et.addEventListener("DOMMouseScroll", Nt, !1) : Et.onmousewheel = Nt;
    }, Et.onmouseout = function() {
        /(?:Firefox)/.test(navigator.userAgent) ? Et.removeEventListener("DOMMouseScroll", Nt, !1) : (Et.onmousewheel = null, 
        document.onmousewheel = null), It.noScrollList.length && It.noScrollList.forEach((e => {
            let a = document.querySelector(e);
            a && function(e, a) {
                if (e && a) {
                    for (var t = a.split(" "), l = " " + e.className + " ", o = 0, i = t.length; o < i; o++) {
                        var n = t[o];
                        n && (e.classList ? e.classList.remove(n) : wt(e, n) && (l = l.replace(" " + n + " ", " ")));
                    }
                    if (!e.classList) try {
                        e.className = l.trim();
                    } catch (e) {}
                }
            }(a, "public-overflow-hidden");
        }));
    };
}, Nt = function(e) {
    let a = 0;
    if ((e = e || window.event).wheelDelta ? (a = e.wheelDelta / 120, window.opera && (a = -a)) : e.detail && (a = -e.detail / 3), 
    a) {
        let e = It.reduceCount / 100;
        a < 0 ? 1 - e + It.variation / 100 >= .5 ** It.minMultiples && (1 + It.variation / 100) * It.originHeight >= It.minPx && (It.variation = It.variation - It.reduceCount) : 1 + e + It.variation / 100 <= It.maxMultiples && (1 + It.variation / 100) * It.originHeight <= It.maxPx && (It.variation = It.variation + It.addCount);
        let t = (1 + It.variation / 100) * It.originHeight, l = (1 + It.variation / 100) * It.originWidth;
        Et.style.height = t + "px", Et.style.width = l + "px";
    }
}, Mt = function(e) {
    let a = Object.keys(e), t = a.find((e => "id" === e));
    if (t) return e[t];
    {
        let e = a.find((e => e.indexOf("v-") > -1));
        return e || `v-${(new Date).getTime()}`;
    }
};

var Rt = e({
    directives: {
        myDrag: {
            mounted: function(e, a) {
                let t = e;
                t.onmousedown = function(l) {
                    let o = e.parentNode, i = Lt(o, "position") || "";
                    "fixed" !== i && "absolute" !== i && St(o, "public-relative");
                    let n, r, s, u, d, p, m, c = Lt(e, "position") || "";
                    "fixed" !== c && "absolute" !== c && St(e, "public-absolute"), l.preventDefault(), 
                    !0 === a.value ? (n = document.body.clientWidth, r = document.body.clientHeight, 
                    s = l.clientX - t.offsetLeft, u = l.clientY - t.offsetTop, d = l.offsetX, p = l.offsetY) : !1 === a.value ? (n = document.body.clientWidth, 
                    r = document.body.clientHeight, s = l.clientX - t.offsetLeft, u = l.clientY - t.offsetTop) : a.value && Vt(a.value, "String") ? (m = document.querySelectorAll(a.value) && document.querySelectorAll(a.value)[0], 
                    n = m && m.clientWidth + m.getBoundingClientRect().left, r = m && m.clientHeight + m.getBoundingClientRect().top, 
                    s = l.clientX - t.offsetLeft, u = l.clientY - t.offsetTop, d = l.offsetX, p = l.offsetY) : (s = l.clientX - t.offsetLeft, 
                    u = l.clientY - t.offsetTop), document.onmousemove = function(e) {
                        let l = 0, i = 0;
                        if (!0 === a.value) l = e.clientX - d >= n - t.clientWidth ? n - t.clientWidth - o.getBoundingClientRect().left : e.clientX - d <= 0 ? -o.getBoundingClientRect().left : e.clientX - s, 
                        i = e.clientY - p >= r - t.clientHeight ? r - t.clientHeight - o.getBoundingClientRect().top : e.clientY - p <= 0 ? -o.getBoundingClientRect().top : e.clientY - u, 
                        t.style.left = l + "px", t.style.top = i + "px"; else if (!1 === a.value) l = e.clientX >= n ? n - s : e.clientX <= 0 ? 0 - s : e.clientX - s, 
                        i = e.clientY >= r ? r - u : e.clientY <= 0 ? 0 - u : e.clientY - u, t.style.left = l + "px", 
                        t.style.top = i + "px"; else if (a.value && Vt(a.value, "String")) l = e.clientX - d >= n - t.clientWidth ? n - o.getBoundingClientRect().left - t.clientWidth : e.clientX - d <= m.getBoundingClientRect().left ? m.getBoundingClientRect().left - o.getBoundingClientRect().left : e.clientX - s, 
                        i = e.clientY - p >= r - t.clientHeight ? r - o.getBoundingClientRect().top - t.clientHeight : e.clientY - p <= m.getBoundingClientRect().top ? m.getBoundingClientRect().top - o.getBoundingClientRect().top : e.clientY - u, 
                        t.style.left = l + "px", t.style.top = i + "px"; else {
                            l = e.clientX - s, i = e.clientY - u, l <= 0 && (l = 0), i <= 0 && (i = 0);
                            let a = o.clientHeight - t.clientHeight, n = o.clientWidth - t.clientWidth;
                            l >= n && (l = n), i >= a && (i = a), t.style.left = l + "px", t.style.top = i + "px";
                        }
                        document.onmouseup = function() {
                            document.onmousemove = null, document.onmouseup = null;
                        };
                    }, document.onmouseup = function() {
                        document.onmousemove = null, document.onmouseup = null;
                    };
                };
            }
        },
        stretch: {
            mounted: Ot,
            beforeUpdate: Ot
        }
    },
    props: {
        ...Ct
    },
    setup: () => ({})
});

const xt = {
    class: "image-wrapper"
}, At = [ "src" ], kt = [ "src" ];

Rt.render = function(e, i, n, r, s, u) {
    const d = a("myDrag"), p = a("stretch");
    return t(), l("div", xt, [ e.draggable ? o((t(), l("img", {
        key: 0,
        ref: "image",
        class: "image",
        src: e.url
    }, null, 8, At)), [ [ d, !1 ], [ p, {
        addCount: 10,
        reduceCount: 10,
        minPercent: 2,
        maxPercent: 5
    } ] ]) : (t(), l("img", {
        key: 1,
        class: "image",
        src: e.url,
        alt: ""
    }, null, 8, kt)) ]);
}, "production" === process.env.NODE_ENV || Object.freeze({}), "production" === process.env.NODE_ENV || Object.freeze([]);

const Pt = e => "string" == typeof e, _t = e => null !== e && "object" == typeof e, Ut = e => {
    const a = Object.create(null);
    return t => a[t] || (a[t] = e(t));
}, Bt = /-(\w)/g, Wt = Ut((e => e.replace(Bt, ((e, a) => a ? a.toUpperCase() : "")))), qt = /\B([A-Z])/g, jt = Ut((e => e.replace(qt, "-$1").toLowerCase())), $t = (e = "") => e.split(" ").filter((e => !!e.trim())), zt = (e, a) => {
    e && a.trim() && e.classList.add(...$t(a));
}, Gt = (e, a) => {
    e && a.trim() && e.classList.remove(...$t(a));
}, Ht = (e, a) => {
    var t;
    if (!Oa || !e || !a) return "";
    Wt(a);
    try {
        const l = e.style[a];
        if (l) return l;
        const o = null == (t = document.defaultView) ? void 0 : t.getComputedStyle(e, "");
        return o ? o[a] : "";
    } catch (t) {
        return e.style[a];
    }
}, Qt = Symbol(), Yt = i();

const Kt = i(0), Xt = () => {
    const e = function(e, a) {
        const t = n() ? r(Qt, Yt) : Yt;
        return e ? s((() => {
            var l, o;
            return null != (o = null == (l = t.value) ? void 0 : l[e]) ? o : a;
        })) : t;
    }("zIndex", 2e3), a = s((() => e.value + Kt.value));
    return {
        initialZIndex: e,
        currentZIndex: a,
        nextZIndex: () => (Kt.value++, a.value)
    };
};

let Jt;

const Zt = function(e = {}) {
    if (!Oa) return;
    const a = el(e);
    a.fullscreen && Jt && (Jt.remvoeElLoadingChild(), Jt.close());
    const t = function(e) {
        let a;
        const t = i(!1), l = u({
            ...e,
            originalPosition: "",
            originalOverflow: "",
            visible: !1
        });
        function n() {
            const e = l.parent;
            if (!e.vLoadingAddClassList) {
                let a = e.getAttribute("loading-number");
                a = Number.parseInt(a) - 1, a ? e.setAttribute("loading-number", a.toString()) : (Gt(e, "el-loading-parent--relative"), 
                e.removeAttribute("loading-number")), Gt(e, "el-loading-parent--hidden");
            }
            r();
        }
        function r() {
            var e, a;
            null == (a = null == (e = b.$el) ? void 0 : e.parentNode) || a.removeChild(b.$el);
        }
        function s() {
            t.value && (t.value = !1, n());
        }
        const b = d({
            name: "ElLoading",
            setup: () => () => {
                const e = l.spinner || l.svg, a = m("svg", {
                    class: "circular",
                    viewBox: l.svgViewBox ? l.svgViewBox : "25 25 50 50",
                    ...e ? {
                        innerHTML: e
                    } : {}
                }, [ m("circle", {
                    class: "path",
                    cx: "50",
                    cy: "50",
                    r: "20",
                    fill: "none"
                }) ]), t = l.text ? m("p", {
                    class: "el-loading-text"
                }, [ l.text ]) : void 0;
                return m(c, {
                    name: "el-loading-fade",
                    onAfterLeave: s
                }, {
                    default: v((() => [ o(f("div", {
                        style: {
                            backgroundColor: l.background || ""
                        },
                        class: [ "el-loading-mask", l.customClass, l.fullscreen ? "is-fullscreen" : "" ]
                    }, [ m("div", {
                        class: "el-loading-spinner"
                    }, [ a, t ]) ]), [ [ g, l.visible ] ]) ]))
                });
            }
        }).mount(document.createElement("div"));
        return {
            ...p(l),
            setText: function(e) {
                l.text = e;
            },
            remvoeElLoadingChild: r,
            close: function() {
                var o;
                e.beforeClose && !e.beforeClose() || (l.parent.vLoadingAddClassList = void 0, t.value = !0, 
                clearTimeout(a), a = window.setTimeout((() => {
                    t.value && (t.value = !1, n());
                }), 400), l.visible = !1, null == (o = e.closed) || o.call(e));
            },
            handleAfterLeave: s,
            vm: b,
            get $el() {
                return b.$el;
            }
        };
    }({
        ...a,
        closed: () => {
            var e;
            null == (e = a.closed) || e.call(a), a.fullscreen && (Jt = void 0);
        }
    });
    al(a, a.parent, t), tl(a, a.parent, t), a.parent.vLoadingAddClassList = () => tl(a, a.parent, t);
    let l = a.parent.getAttribute("loading-number");
    return l = l ? `${Number.parseInt(l) + 1}` : "1", a.parent.setAttribute("loading-number", l), 
    a.parent.appendChild(t.$el), b((() => t.visible.value = a.visible)), a.fullscreen && (Jt = t), 
    t;
}, el = e => {
    var a, t, l, o;
    let i;
    return i = Pt(e.target) ? null != (a = document.querySelector(e.target)) ? a : document.body : e.target || document.body, 
    {
        parent: i === document.body || e.body ? document.body : i,
        background: e.background || "",
        svg: e.svg || "",
        svgViewBox: e.svgViewBox || "",
        spinner: e.spinner || !1,
        text: e.text || "",
        fullscreen: i === document.body && (null == (t = e.fullscreen) || t),
        lock: null != (l = e.lock) && l,
        customClass: e.customClass || "",
        visible: null == (o = e.visible) || o,
        target: i
    };
}, al = async (e, a, t) => {
    const {nextZIndex: l} = Xt(), o = {};
    if (e.fullscreen) t.originalPosition.value = Ht(document.body, "position"), t.originalOverflow.value = Ht(document.body, "overflow"), 
    o.zIndex = l(); else if (e.parent === document.body) {
        t.originalPosition.value = Ht(document.body, "position"), await b();
        for (const a of [ "top", "left" ]) {
            const t = "top" === a ? "scrollTop" : "scrollLeft";
            o[a] = e.target.getBoundingClientRect()[a] + document.body[t] + document.documentElement[t] - parseInt(Ht(document.body, `margin-${a}`), 10) + "px";
        }
        for (const a of [ "height", "width" ]) o[a] = `${e.target.getBoundingClientRect()[a]}px`;
    } else t.originalPosition.value = Ht(a, "position");
    for (const [e, a] of Object.entries(o)) t.$el.style[e] = a;
}, tl = (e, a, t) => {
    "absolute" !== t.originalPosition.value && "fixed" !== t.originalPosition.value ? zt(a, "el-loading-parent--relative") : Gt(a, "el-loading-parent--relative"), 
    e.fullscreen && e.lock ? zt(a, "el-loading-parent--hidden") : Gt(a, "el-loading-parent--hidden");
}, ll = Symbol("ElLoading"), ol = (e, a) => {
    var t, l, o, n;
    const r = a.instance, s = e => _t(a.value) ? a.value[e] : void 0, u = a => (e => {
        const a = Pt(e) && (null == r ? void 0 : r[e]) || e;
        return a ? i(a) : a;
    })(s(a) || e.getAttribute(`element-loading-${jt(a)}`)), d = null != (t = s("fullscreen")) ? t : a.modifiers.fullscreen, p = {
        text: u("text"),
        svg: u("svg"),
        svgViewBox: u("svgViewBox"),
        spinner: u("spinner"),
        background: u("background"),
        customClass: u("customClass"),
        fullscreen: d,
        target: null != (l = s("target")) ? l : d ? void 0 : e,
        body: null != (o = s("body")) ? o : a.modifiers.body,
        lock: null != (n = s("lock")) ? n : a.modifiers.lock
    };
    e[ll] = {
        options: p,
        instance: Zt(p)
    };
}, il = {
    mounted(e, a) {
        a.value && ol(e, a);
    },
    updated(e, a) {
        const t = e[ll];
        a.oldValue !== a.value && (a.value && !a.oldValue ? ol(e, a) : a.value && a.oldValue ? _t(a.value) && ((e, a) => {
            for (const t of Object.keys(a)) h(a[t]) && (a[t].value = e[t]);
        })(a.value, t.options) : null == t || t.instance.close());
    },
    unmounted(e) {
        var a;
        null == (a = e[ll]) || a.instance.close();
    }
};

var nl = e({
    directives: {
        loading: il
    },
    props: {
        ...Ct
    },
    setup() {
        const e = i(!0);
        return {
            frameLoad: () => {
                e.value = !1;
            },
            frameLoading: e,
            getFilePreviewUrl: Ma
        };
    }
});

const rl = {
    class: "frame-container"
}, sl = {
    class: "frame-loading"
}, ul = [ "src" ];

nl.render = function(e, i, n, r, s, u) {
    const d = a("loading");
    return t(), l("div", rl, [ o((t(), l("div", sl, [ y("iframe", {
        src: e.getFilePreviewUrl(e.url),
        width: "100%",
        height: "100%",
        onOnreadystatechange: i[0] || (i[0] = (...a) => e.frameLoad && e.frameLoad(...a)),
        onLoad: i[1] || (i[1] = (...a) => e.frameLoad && e.frameLoad(...a))
    }, null, 40, ul) ])), [ [ d, e.frameLoading ] ]) ]);
};

var dl = e({
    props: {
        ...Ct
    },
    setup: () => ({})
});

const pl = {
    class: "audio-container"
}, ml = {
    controls: ""
}, cl = [ "src" ], vl = [ "src" ];

dl.render = function(e, a, o, i, n, r) {
    return t(), l("div", pl, [ y("audio", ml, [ y("source", {
        src: e.url,
        type: "audio/ogg"
    }, null, 8, cl), y("source", {
        src: e.url,
        type: "audio/mpeg"
    }, null, 8, vl), D(" 您的浏览器不支持 audio 元素。 ") ]) ]);
};

var fl = e({
    props: {
        ...Ct
    },
    setup: () => ({})
});

const gl = {
    ref: "modal",
    class: "video-container"
}, bl = [ "src" ], hl = [ "src" ];

fl.render = function(e, a, o, i, n, r) {
    return t(), l("div", gl, [ y("video", {
        controls: "",
        onClick: a[0] || (a[0] = C((() => {}), [ "stop" ]))
    }, [ y("source", {
        src: e.url,
        type: "video/mp4"
    }, null, 8, bl), y("source", {
        src: e.url,
        type: "video/ogg"
    }, null, 8, hl), D(" 您的浏览器不支持 HTML5 video 标签。 ") ]) ], 512);
};

var yl = e({
    props: {
        ...Ct
    },
    setup: () => ({})
});

const Dl = {
    class: "pdf-container"
}, Cl = [ "src" ];

yl.render = function(e, a, o, i, n, r) {
    return t(), l("div", Dl, [ y("embed", {
        class: "pdf",
        type: "application/pdf",
        height: "100%",
        width: "100%",
        src: e.url
    }, null, 8, Cl) ]);
};

const wl = [ {
    typeArr: [ "mp4", "wmv", "flv", "avi", "RM", "mpeg", "3gp", "asf", "rmvb", "mov", "rm", "mkv", "mp4", "avi", "swf", "wmv", "rmvb", "mov", "mpg" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_video.png"
}, {
    typeArr: [ "pdf" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_pdf.png"
}, {
    typeArr: [ "psd" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_psd.png"
}, {
    typeArr: [ "txt" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_txt.png"
}, {
    typeArr: [ "doc", "docx", "wps", "rtf", "hlp" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_word.png"
}, {
    typeArr: [ "xls", "xlsx", "xlsm", "xlsb", "cvs" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_elsx.png"
}, {
    typeArr: [ "ppt", "pptx", "pptm" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_ppt.png"
}, {
    typeArr: [ "rar", "zip", "7z", "war", "rar", "zip", "7z", "gz", "arj", "z" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_zip.png"
}, {
    typeArr: [ "xml" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_xml.png"
}, {
    typeArr: [ "mpeg", "wma", "flac", "aac", "mp3", "flac", "ape", "wma", "wav", "aac", "m4a", "au", "ram", "mmf", "aif", "cda", "ogg" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_audio.png"
}, {
    typeArr: [ "htm", "html" ],
    icon: "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_html.png"
} ], Sl = [ "jpg", "png", "gif", "svg", "bmp", "webp", "apng", "jpeg", "heic" ];

var Ll = () => {
    const e = e => {
        let a = e.name.lastIndexOf(".");
        return e.name.substring(a + 1);
    };
    return {
        showFileImage: a => {
            if (!a.url) return;
            let t = e(a), l = wl, o = Sl;
            return o.includes(t) || o.includes(t.toLowerCase()) ? a.url : (l.find((e => e.typeArr.includes(t))) || {}).icon || "https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_documentation.png";
        },
        getFileFormat: e,
        getFileName: e => {
            let a = e.name.lastIndexOf(".");
            return e.name.substring(0, a);
        },
        getFileSize: (e = {}) => {
            if (!e.size) return "";
            const a = 1048576;
            return e.size / a > 1 ? `(${(e.size / a).toFixed(2)}M)` : `(${(e.size / 1024).toFixed(2)}K)`;
        }
    };
}, Tl = e({
    props: {
        ...Ct
    },
    setup(e) {
        const {showFileImage: a} = Ll();
        return {
            documentUrl: a({
                url: e.url,
                name: e.name
            })
        };
    }
});

const Vl = {
    class: "empty-container"
}, El = [ "src" ];

function Il(e) {
    if (!e) return "";
    let a = e.split("?")[0], t = a.lastIndexOf(".");
    return a.substring(t + 1).toLowerCase();
}

function Fl(e, a) {
    var t, l;
    if (!e) return;
    let o = {
        displayImg: [ "png", "bmp", "webp", "gif", "jpg", "jpeg", "heic" ],
        displayAudio: [ "mp3", "wav", "ogg", "flac", "cda" ],
        displayVideo: [ "mp4", "mpeg", "mov", "3gp", "3gpp" ],
        displayDoc: [ "pdf", "xlsx", "TXT", "XLS", "PDF", "DOC", "XLSX", "DOCX", "PPT", "pptx", "zip", "xml", "png", "gif", "jpg", "jpeg", "mp3", "wav", "mp4", "flv" ].map((e => e.toLowerCase()))
    }, i = null === (t = Il(e)) || void 0 === t ? void 0 : t.toLowerCase(), n = null === (l = Il(a)) || void 0 === l ? void 0 : l.toLowerCase();
    for (let e in o) if (o[e].includes(i) || o[e].includes(n)) return e;
    return "displayDefault";
}

Tl.render = function(e, a, o, i, n, r) {
    return t(), l("div", Vl, [ y("img", {
        class: "doc-img",
        src: e.documentUrl,
        alt: "附件"
    }, null, 8, El) ]);
};

var Ol = e({
    name: "SlmPreview",
    components: {
        ...{
            displayImg: Rt,
            displayDoc: nl,
            displayAudio: dl,
            displayVideo: fl,
            displayPdf: yl,
            displayDefault: Tl
        }
    },
    props: {
        draggable: {
            type: Boolean,
            default: !1
        },
        previewItem: {
            type: Object,
            default: () => ({})
        },
        dictLabel: {
            type: String,
            default: "name"
        },
        dictValue: {
            type: String,
            default: "url"
        },
        allowScroll: {
            type: Boolean,
            default: !1
        }
    },
    emits: {
        click: null
    },
    setup(e) {
        const a = s((() => e.previewItem?.[e.dictValue])), t = s((() => e.previewItem?.[e.dictLabel])), l = i(null);
        w((() => {
            n();
        })), S((() => {
            r();
        }));
        const o = a => {
            if (e.allowScroll) return;
            let t = Number(l.value?.scrollTop), o = Number(l.value?.scrollHeight), i = Number(l.value?.clientHeight), n = a.deltaY;
            (n < 0 && t <= -1 * n || n > 0 && o - i - t <= n) && (a.preventDefault(), a.stopPropagation());
        }, n = () => {
            window.addEventListener("wheel", o, {
                passive: !1
            });
        }, r = () => {
            window.removeEventListener("wheel", o);
        }, u = s((() => Fl(a.value, t.value)));
        return {
            previewUrl: a,
            previewName: t,
            componentName: u,
            model: l
        };
    }
});

const Nl = {
    ref: "model",
    class: "file-preview-item"
};

Ol.render = function(e, a, o, i, n, r) {
    return t(), l("div", Nl, [ (t(), L(T(e.componentName), {
        url: e.previewUrl,
        name: e.previewName,
        draggable: e.draggable
    }, null, 8, [ "url", "name", "draggable" ])) ], 512);
};

var Ml = e({
    name: "SlmPreviewBox",
    components: {
        slmPreview: Ol,
        SsuDownload: Qa
    },
    props: {
        previewArr: {
            type: Array,
            default: () => []
        },
        currentIndex: {
            type: Number,
            default: 0
        },
        dictLabel: {
            type: String,
            default: "name"
        },
        dictValue: {
            type: String,
            default: "url"
        },
        download: {
            type: Boolean,
            default: !0
        },
        isApp: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "destroy" ],
    setup(e, a) {
        const t = i(e.currentIndex), l = i(!0), o = s((() => e.previewArr ? {
            ...e.previewArr[t.value]
        } : {})), n = s((() => !!(t.value + 1 < e.previewArr.length))), r = s((() => !!(t.value > 0))), u = s((() => {
            const a = o.value?.[e.dictValue];
            return !("displayImg" !== Fl(a));
        }));
        return {
            currentIdx: t,
            currentPreview: o,
            previewVisible: l,
            showRight: n,
            showLeft: r,
            showReset: u,
            updateFlag: {
                left: -1,
                right: 1,
                reset: 0
            },
            updatePreview: a => {
                l.value = !1, (t.value + a + 1 > e.previewArr.length || t.value + a < 0) && (t.value = 0), 
                t.value = t.value + a, b((() => {
                    l.value = !0;
                }));
            },
            closePreview: () => {
                a.emit("destroy");
            },
            handleDownload: a => {
                a && (e.isApp ? window.open(`slmspro://webDownloadPDF?url=${a}&fileType=pdf`, "_blank") : window.open(a, "_blank"));
            }
        };
    }
});

const Rl = {
    class: "slm-preview-demo"
}, xl = {
    class: "preview-box"
}, Al = {
    key: 0,
    class: "preview-mask"
}, kl = {
    class: "close-btn"
}, Pl = [ y("i", {
    class: "iconfont iconguanbi-nor"
}, null, -1) ], _l = [ y("i", {
    class: "iconfont iconshangyiye"
}, null, -1) ], Ul = [ y("i", {
    class: "iconfont iconxiayiye"
}, null, -1) ], Bl = {
    class: "bottom-box"
}, Wl = {
    key: 2,
    class: "view-origin"
};

Ml.render = function(e, a, i, n, r, s) {
    const u = V("slm-preview"), d = V("ssu-button"), p = V("ssu-download");
    return t(), l("div", Rl, [ y("div", xl, [ e.previewVisible ? (t(), l("div", Al, [ e.currentPreview.component ? (t(), 
    L(T(e.currentPreview.component), E({
        key: 0
    }, e.currentPreview.attrs, I(e.currentPreview.on)), null, 16)) : (t(), L(u, {
        key: 1,
        draggable: !0,
        class: "preview-content",
        "dict-label": e.dictLabel,
        "dict-value": e.dictValue,
        "preview-item": e.currentPreview
    }, null, 8, [ "dict-label", "dict-value", "preview-item" ])) ])) : F("", !0), y("div", kl, [ y("div", {
        class: "icon-btn pointer",
        onClick: a[0] || (a[0] = (...a) => e.closePreview && e.closePreview(...a))
    }, Pl) ]), o(y("div", {
        class: "top-page"
    }, O(e.currentIdx + 1) + "/" + O(e.previewArr?.length), 513), [ [ g, e.currentPreview.url && e.previewArr?.length > 1 ] ]), y("div", {
        class: "layer",
        onClick: a[3] || (a[3] = C((() => {}), [ "stop" ]))
    }, [ e.showLeft ? (t(), l("div", {
        key: 0,
        class: "toggle-left icon-btn pointer",
        onClick: a[1] || (a[1] = a => e.updatePreview(e.updateFlag.left))
    }, _l)) : F("", !0), e.showRight ? (t(), l("div", {
        key: 1,
        class: "toggle-right icon-btn pointer",
        onClick: a[2] || (a[2] = a => e.updatePreview(e.updateFlag.right))
    }, Ul)) : F("", !0) ]), o(y("div", Bl, [ e.showReset ? (t(), l("span", {
        key: 0,
        class: "view-origin",
        onClick: a[4] || (a[4] = a => e.updatePreview(e.updateFlag.reset))
    }, " 复位 ")) : F("", !0), !e.currentPreview[e.dictLabel] && e.download ? (t(), L(d, {
        key: 1,
        class: "view-origin",
        onClick: a[5] || (a[5] = () => e.handleDownload(e.currentPreview[e.dictValue]))
    }, {
        default: v((() => [ D("下载") ])),
        _: 1
    })) : e.download ? (t(), l("span", Wl, [ f(p, {
        "file-url": e.currentPreview[e.dictValue],
        "file-name": e.currentPreview[e.dictLabel]
    }, {
        default: v((() => [ D(" 下载 ") ])),
        _: 1
    }, 8, [ "file-url", "file-name" ]) ])) : F("", !0) ], 512), [ [ g, e.currentPreview.url ] ]) ]) ]);
};

const ql = function(e) {
    let a = e;
    a = {
        ...a
    };
    const t = document.createElement("div"), l = f(Ml, a, N(a) ? {
        default: () => a
    } : null);
    l.props && (l.props.onDestroy = () => {
        M(null, t);
    }), M(l, t), t.firstElementChild && document.body.appendChild(t.firstElementChild);
}, {formComponentMixin: jl} = z;

var $l = e({
    name: "OlAttachment",
    components: {
        SsuUploadV2: Ya
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        basicFileInteraction: {
            type: String,
            default: ""
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        label: {
            type: String,
            default: ""
        },
        limit: {
            type: [ String, Number ]
        },
        mode: {
            type: String,
            default: "single"
        },
        accept: {
            type: String,
            default: ""
        },
        acceptType: {
            type: String
        },
        bulk: {
            type: [ String, Number ]
        },
        pageCode: {
            type: String,
            default: ""
        },
        fileTypeName: {
            type: String,
            default: "文件"
        },
        isShowSize: {
            type: Boolean,
            default: !0
        },
        isAllowAdd: {
            type: Boolean,
            default: !0
        }
    },
    mixins: [ jl ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const {warning: l} = ca(), {uploadHWCloud: o} = va(e.pageCode), i = s((() => e.label)), n = s((() => e.formRules.find((e => "required" === e.key || e.required)))), r = s({
            get() {
                var a, t;
                return (null === (a = e.modelValue) || void 0 === a || null === (t = a.map) || void 0 === t ? void 0 : t.call(a, (e => (e => {
                    if ("string" == typeof e) try {
                        let a = JSON.parse(e);
                        return Boolean(a && "object" == typeof a);
                    } catch (e) {
                        return !1;
                    }
                })(e) ? JSON.parse(e) : e))) || e.metaData.defaultValue || [];
            },
            set(e) {
                var a;
                const l = null == e || null === (a = e.map) || void 0 === a ? void 0 : a.call(e, (e => ({
                    name: null == e ? void 0 : e.name,
                    size: null == e ? void 0 : e.size,
                    url: null == e ? void 0 : e.url
                })));
                t("update:modelValue", l);
            }
        }), u = s((() => (r.value || []).length ? `请将${e.fileTypeName}拖到此处，或点击上传` : `至少上传一份${e.fileTypeName}，请将${e.fileTypeName}拖到此处，或点击上传`)), d = s((() => e.bulk ? Number(e.bulk) : 20)), p = s((() => e.inSubForm ? "drag" : e.basicFileInteraction)), m = s((() => e.inSubForm ? "small" : "default")), c = s((() => "free" === e.acceptType ? "" : e.accept.split(",").map((e => "." === e[0] || [ "image/*", "video/*", "audio/*" ].includes(e) ? e : `.${e}`)).join(","))), v = s((() => {
            if ("single" === e.mode) return 1;
            return e.limit ? Number(e.limit) : 20;
        })), g = a => {
            l({
                message: `请上传${d.value}M以内的${e.fileTypeName}`
            });
        }, b = (a, t) => {
            l({
                message: `当前限制最多可上传 ${v.value} 个${e.fileTypeName}，您共选择了 ${t.length} 个${e.fileTypeName}`
            });
        }, h = (e, a) => {
            e.url && ql({
                previewArr: [ {
                    url: e.url
                } ]
            });
        }, y = async (a, {updateProcess: t}) => {
            try {
                t(100, await o(a.raw, void 0, e.isPublicRelease));
            } catch (e) {
                l({
                    message: `${e}，上传失败，请重试`
                });
            }
        };
        return () => {
            var a;
            return f("div", {
                class: "olAttachment"
            }, [ !e.inSubForm && f("div", null, [ f("label", {
                class: [ "el-form-item__label", n.value && "required-label" ]
            }, [ f("span", null, [ i.value, D("（"), (null === (a = r.value) || void 0 === a ? void 0 : a.length) || 0, D("/"), v.value, D("）") ]) ]) ]), f(Ya, {
                disabled: e.disabled,
                fileList: r.value,
                "onUpdate:fileList": e => r.value = e,
                size: m.value,
                isShowSize: e.isShowSize,
                isAllowAdd: e.isAllowAdd,
                showType: p.value,
                limit: v.value,
                accept: c.value,
                bulk: d.value,
                addDragText: u.value,
                onBulkExceed: g,
                onExceed: b,
                onPreview: h,
                remoteUpload: y
            }, null) ]);
        };
    }
});

const {formComponentMixin: zl} = z;

var Gl = e({
    name: "OlAttachmentMobile",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ zl ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const {uploadHWCloud: l} = va(e.pageCode), n = ca(), r = u({}), d = s((() => e.metaData.props.mode)), p = s((() => e.metaData.props.limit)), m = i(0), {showFileImage: c, getFileFormat: v} = Ll(), b = s({
            get: () => {
                var a;
                return (null == e || null === (a = e.modelValue) || void 0 === a ? void 0 : a.map((e => (e => {
                    if ("string" == typeof e) try {
                        let a = JSON.parse(e);
                        return Boolean(a && "object" == typeof a);
                    } catch (e) {
                        return !1;
                    }
                })(e) ? JSON.parse(e) : e))) || e.metaData.defaultValue || [];
            },
            set: e => {
                t("update:modelValue", e);
            }
        }), h = a => {
            (a => {
                let t = a.file;
                l(t, void 0, e.isPublicRelease).then((e => {
                    let a = [ ...b.value || [] ];
                    a.push({
                        url: e,
                        name: t.name
                    }), b.value = a;
                })).catch((e => {
                    Ka({
                        type: "warning",
                        message: `${e}，上传失败，请重试`
                    });
                }));
            })(a);
        }, y = ({size: a = 0, name: t}) => {
            let l = Ja.nth(t.split("."), 1), o = a, {bulk: i = 0, accept: r = "", mode: s, limit: u, acceptType: d} = e.metaData.props, p = r.split(",").filter((e => "" != e)) || [], m = Number(1024 * i * 1024);
            return "multiple" == s && b.value.length > u ? (n.warning({
                message: `请多上传${u}个附件`
            }), !1) : "limit" != d || p.includes(l) ? i && o > m ? (n.warning({
                message: `请上传${i}M以内的文件`
            }), !1) : void 0 : (n.warning({
                message: "不支持此附件类型！"
            }), !1);
        }, C = (e, a) => {
            n.warning({
                message: `当前限制最多可上传 ${p.value} 个文件，您共选择了 ${e.length + a.length} 个文件`
            });
        }, w = (e, a) => {
            m.value = a;
        }, S = s((() => ({
            ...a,
            ...e
        }))), L = e => {
            e.file.isEdit = !0;
        };
        return () => f("div", {
            class: "attachment"
        }, [ f(Za, E({
            action: "#"
        }, S.value, r, {
            modelValue: b.value,
            disabled: e.disabled,
            "file-list": b.value,
            multiple: d.value,
            limit: p.value,
            "before-upload": y,
            onChange: w,
            onExceed: C,
            "http-request": h
        }), {
            default: () => [ f("div", {
                class: "cardUpload",
                style: e.disabled ? {
                    color: "#DCDFE6",
                    background: "#F6F7FB",
                    border: "1px solid #DDDFE5"
                } : ""
            }, [ f("div", {
                class: "cardUploadIcon"
            }, [ f(V("syc-icon"), {
                type: "designer/shangchuan",
                width: 46,
                height: 37
            }, null) ]), f("span", null, [ D("点击上传") ]) ]) ],
            file: a => {
                var l, i;
                return Ja.nth(((null == a || null === (l = a.file) || void 0 === l ? void 0 : l.url) || (null == a || null === (i = a.file) || void 0 === i ? void 0 : i.name)).split("."), 1), 
                f("div", {
                    class: "image-item"
                }, [ f("i", {
                    class: [ "iconfont", "icon-guanbi-hov", "close-img", e.disabled && "disabled-close-img" ],
                    onClick: () => {
                        e.disabled || (e => {
                            var a;
                            let l = e.file.url, o = 0;
                            null === (a = b.value) || void 0 === a || a.map(((e, a) => {
                                l == e.url && (o = a);
                            })), b.value.splice(o, 1), t("update:modelValue", b.value);
                        })(a);
                    }
                }, null), f("img", {
                    src: c(a.file)
                }, null), o(f("div", {
                    class: "rename-img",
                    onClick: L.bind(null, a)
                }, [ D("重命名"), a.file.isEdit && f(V("RenameFile"), {
                    modelValue: a.file.isEdit,
                    "onUpdate:modelValue": e => a.file.isEdit = e,
                    onUpdateName: e => {
                        a.file.name = e, (e => {
                            var a;
                            let t = e.file.url;
                            b.value = null === (a = b.value) || void 0 === a ? void 0 : a.map((a => (t == a.url && (a.name = e.file.name), 
                            a)));
                        })(a);
                    }
                }, null) ]), [ [ g, !e.isMobile ] ]), f("div", null, [ a.file.name.substr(0, 6) + "..." + v(a.file) ]) ]);
            }
        }) ]);
    }
});

const {propsFormMixin: Hl} = z;

var Ql = e({
    name: "OlAttachmentPropsForm",
    mixins: [ Hl ],
    setup(e, {expose: a}) {
        var t, l, o;
        null !== (t = e.pageData) && void 0 !== t && t.code && null !== (l = e.metaData.props) && void 0 !== l && l.pageCode && (e.metaData.props.pageCode.rawValue = null === (o = e.pageData) || void 0 === o ? void 0 : o.code);
        const n = s((() => e.metaData.validateRuleConfig)), {data: r, formRef: u} = fa(e, a), {convertOptionListToObj: d, convertObjToOptionList: p} = H, m = s((() => i(p({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: u,
            model: r,
            "label-position": "top",
            class: "ol-Attachment-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("attachment-setter"), {
                metaData: e.metaData,
                inSubForm: e.inSubForm
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: n.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: m.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = d(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
}), Yl = e({
    name: "SlmPreviewWrap",
    components: {
        slmPreview: Ol
    },
    props: {
        previewArr: {
            type: Array,
            default: () => []
        },
        dictLabel: {
            type: String,
            default: "name"
        },
        dictValue: {
            type: String,
            default: "url"
        },
        allowScroll: {
            type: Boolean,
            default: !1
        }
    },
    setup(e, {attrs: a}) {
        const {showFileImage: t, getFileFormat: l, getFileName: o, getFileSize: n} = Ll(), r = s((() => ({
            ...e,
            ...a,
            previewItem: v.value,
            currentIndex: d.value
        }))), d = i(0), p = u({
            left: -1,
            right: 1,
            reset: 0
        }), m = e => {
            if (!e) return "";
            const a = e.split(".");
            if (a.length < 2) return {
                name: e,
                suffix: ""
            };
            const t = a.pop().toLocaleLowerCase();
            return {
                name: a.join("."),
                suffix: t
            };
        }, c = s((() => {
            const a = e.dictValue, t = e.dictLabel, l = m(v.value[a]), o = m(v.value[t]);
            return o.suffix && o.suffix === l.suffix ? `${o.name}` : v.value[t];
        })), v = s((() => e.previewArr[d.value])), f = i(1), g = s((() => Math.ceil(e.previewArr.length / 3)));
        return {
            openPreview: () => {
                ql({
                    ...e
                });
            },
            bindData: r,
            updateFlag: p,
            currentDisplayValue: s((() => e.previewArr.slice(3 * (f.value - 1), 3 * f.value))),
            updateDisplayValue: e => {
                const a = f.value + e;
                a > g.value || a < 1 || (f.value = a, d.value = 3 * (f.value - 1));
            },
            setDisplay: e => {
                d.value = 3 * (f.value - 1) + e;
            },
            getFileFormat: l,
            getFileName: o,
            getFileSize: n,
            showFileImage: t,
            Display_LEN: 3,
            previewItem: v,
            currentDisplayPage: f,
            totalPage: g,
            currentIndex: d,
            downFileName: c
        };
    }
});

const Kl = {
    class: "file-preview-wrap"
}, Xl = {
    class: "preview-info"
}, Jl = {
    class: "preview-info-header"
}, Zl = {
    class: "preview-info-header-name"
}, eo = {
    class: "preview-info-header-icons"
}, ao = y("i", {
    class: "iconfont iconxiazai1"
}, null, -1), to = {
    class: "preview-info-content"
}, lo = [ "onClick" ], oo = [ "src" ], io = {
    class: "file-name"
}, no = {
    class: "operate-page"
};

Yl.render = function(e, a, o, i, n, r) {
    const s = V("ssu-download"), u = V("slm-preview"), d = V("ssu-icon");
    return t(), l("div", Kl, [ y("div", Xl, [ y("div", Jl, [ y("div", Zl, O(e.previewItem[e.dictLabel]), 1), y("div", eo, [ f(s, {
        "file-url": e.previewItem[e.dictValue],
        "file-name": e.downFileName
    }, {
        default: v((() => [ ao ])),
        _: 1
    }, 8, [ "file-url", "file-name" ]), y("i", {
        class: "iconfont iconquanping",
        onClick: a[0] || (a[0] = (...a) => e.openPreview && e.openPreview(...a))
    }) ]) ]), y("div", to, [ f(u, E({
        draggable: !1,
        allowScroll: e.allowScroll,
        class: "preview-content"
    }, e.bindData), null, 16, [ "allowScroll" ]) ]) ]), (t(!0), l(R, null, x(e.currentDisplayValue, ((a, o) => (t(), 
    l("div", {
        key: o,
        class: A([ "img-drop-list", e.currentIndex === (e.currentDisplayPage - 1) * e.Display_LEN + o && "img-active-drop-list" ]),
        onClick: a => e.setDisplay(o)
    }, [ y("img", {
        src: e.showFileImage(a),
        class: "attachment-table-show"
    }, null, 8, oo), y("div", io, O(e.getFileName(a)), 1), y("span", null, " ." + O(e.getFileFormat(a)) + O(e.getFileSize(a)), 1) ], 10, lo)))), 128)), y("div", no, [ f(d, {
        "icon-name": "icon-shangyiye",
        class: A(e.currentDisplayPage < 2 && "disabled-icon"),
        onClick: a[1] || (a[1] = a => e.updateDisplayValue(e.updateFlag.left))
    }, null, 8, [ "class" ]), y("span", null, O(e.currentDisplayPage) + " / " + O(e.totalPage), 1), f(d, {
        "icon-name": "icon-xiayiye",
        class: A(e.currentDisplayPage + 1 > e.totalPage && "disabled-icon"),
        onClick: a[2] || (a[2] = a => e.updateDisplayValue(e.updateFlag.right))
    }, null, 8, [ "class" ]) ]) ]);
};

const {formEntityMixin: ro, metaDataMixin: so} = z;

var uo = e({
    name: "OlAttachmentFormatter",
    mixins: [ ro, so ],
    props: {
        runtimeEnv: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        basicFileInteraction: {
            type: String,
            default: ""
        },
        inSubForm: {
            type: Boolean,
            default: !1
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        limit: {
            type: [ String, Number ]
        },
        mode: {
            type: String,
            default: "single"
        },
        accept: {
            type: String,
            default: ""
        },
        acceptType: {
            type: String
        },
        bulk: {
            type: [ String, Number ]
        },
        pageCode: {
            type: String,
            default: ""
        },
        fileTypeName: {
            type: String,
            default: "文件"
        }
    },
    components: {
        previewWrap: Yl
    },
    setup(e, {attrs: a}) {
        const t = s((() => e.label)), l = s((() => {
            var e;
            return null === (e = a.formRules) || void 0 === e ? void 0 : e.find((e => "required" === e.key || e.required));
        })), i = s((() => null == e ? void 0 : e.runtimeEnv)), n = s((() => {
            let a = p(e.fieldValue) ? JSON.parse(e.fieldValue) : e.fieldValue, t = Ra(a).map((e => p(e) ? JSON.parse(e) : e));
            return i.value === Q.TABLE && (t = t.slice(0, 3)), t;
        })), {showFileImage: r, getFileFormat: u, getFileName: d} = Ll(), p = e => {
            if ("string" == typeof e) try {
                let a = JSON.parse(e);
                return Boolean(a && "object" == typeof a);
            } catch (e) {
                return !1;
            }
        };
        return () => {
            return e.inSubForm ? (() => {
                var t;
                const l = {
                    ...a,
                    ...e
                };
                return null !== (t = n.value) && void 0 !== t && t.length ? f($l, E(l, {
                    modelValue: e.fieldValue,
                    class: "ol-attachment-table-formatter",
                    disabled: !0,
                    isAllowAdd: !1
                }), null) : f("div", {
                    class: "ol-attachment-table-formatter"
                }, [ Y ]);
            })() : f("label", {
                class: "ol-attachment-table-formatter"
            }, [ o(f("div", null, [ f("label", {
                class: [ "el-form-item__label", l.value && "required-label" ]
            }, [ f("span", null, [ t.value ]) ]) ]), [ [ g, "table" !== i.value ] ]), "drag" === e.basicFileInteraction && "table" !== i.value && null !== (s = n.value) && void 0 !== s && s.length ? f("div", null, [ f(Yl, {
                class: "preview-wrap",
                "dict-label": "name",
                "dict-value": "url",
                previewArr: n.value,
                allowScroll: !0
            }, null) ]) : f("div", null, [ n.value && n.value.map(((e, a) => f("div", {
                class: "img-list",
                onClick: e => {
                    e.stopPropagation(), ql({
                        currentIndex: a,
                        previewArr: n.value
                    });
                }
            }, [ f(V("el-tooltip"), {
                content: e.name,
                placement: "top"
            }, {
                default: () => [ f("img", {
                    src: r(e),
                    class: "table" === i.value ? "attachment-table-show" : "attachment-detail-show"
                }, null) ]
            }), o(f("div", null, [ f("div", {
                class: "file-name"
            }, [ f("span", {
                class: "file-name-left"
            }, [ d(e) ]), D("."), u(e) ]) ]), [ [ g, "table" !== i.value ] ]) ]))) ]), "table" === i.value && (null === (p = e.fieldValue) || void 0 === p ? void 0 : p.length) > 3 && f(V("ssu-icon"), {
                class: "icon-more",
                "icon-name": "icongengduo-copy"
            }, null), !(null !== (m = n.value) && void 0 !== m && m.length) && Y ]);
            var s, p, m;
        };
    }
}), po = {
    code: K.ATTACHMENT,
    name: "附件",
    group: X.NORMAL,
    icon: "fujian",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlAttachment",
    mobileWidget: "OlAttachment",
    propsForm: "OlAttachmentPropsForm",
    tableFormatter: "OlAttachmentFormatter",
    props: {
        mode: "single",
        limit: 1,
        acceptType: "free",
        accept: "",
        bulk: void 0,
        basicFileInteraction: "card",
        pageCode: ""
    },
    defaultValue: null,
    requiredProps: [],
    supportBindVariableProps: [ "basicAttachment" ],
    supportI18NProps: [ "basicAttachment" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IS_NOT_NULL,
        supportWidgetCodeConfig: [],
        supportOperator: {
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL],
            [ee.IS_NULL]: ae[ee.IS_NULL]
        }
    },
    renderConfig: {
        showLabel: !1
    }
};

$l.meta = po, $l.PropsForm = Ql, $l.Mobile = Gl, $l.install = e => {
    e.component($l.name, $l), e.component(Ql.name, Ql), e.component(Gl.name, Gl), e.component(uo.name, uo);
}, $l.widgetMap = {
    [Gl.name]: Gl,
    [$l.name]: $l,
    [Ql.name]: Ql,
    [uo.name]: uo
};

const mo = {
    appId: {
        type: String,
        default: ""
    },
    modelValue: {
        type: Boolean,
        default: !1
    },
    selectedIdList: {
        type: Array,
        default: () => []
    },
    isMultiple: {
        type: Boolean,
        default: !1
    },
    isToLastLevel: {
        type: Boolean,
        default: !1
    },
    isShowLevel: {
        type: Boolean,
        default: !1
    },
    isShowOptionCode: {
        type: Boolean,
        default: !1
    },
    level: {
        type: Number,
        default: 1
    },
    optionType: {
        type: String,
        default: "normal"
    },
    optionValue: {
        type: String,
        default: ""
    },
    customOptionList: {
        type: Object,
        default: () => ({})
    },
    filterSelectedIdList: {
        type: Array,
        default: () => []
    },
    handleClose: {
        type: Function,
        default: () => null
    },
    limit: {
        type: Number,
        default: 1 / 0
    },
    limitTip: {
        type: String,
        default: "单次最多复用20个型号，选择失败"
    },
    currentModelId: {
        type: String,
        default: ""
    },
    selectedValue: {
        type: [ String, Array ],
        default: () => []
    }
}, co = [ "confirm", "limit", "closed" ], vo = xa([ {
    optionSetName: "产线",
    optionSetCode: te.PRODUCTION_LINE,
    optionSetTip: '选项内容可在"设备中心_产线管理"配置；'
}, {
    optionSetName: "型号编号",
    optionSetCode: te.MODEL,
    optionSetTip: '选项内容可在"设备中心_设备型号"配置；'
} ]);

Object.keys(te).map((e => te[e])), te.MODEL, te.PRODUCTION_LINE;

const fo = "CASCADE", go = (e, a) => {
    const t = ((e, a) => {
        const t = s((() => e)), l = s((() => a)), o = a => {
            var t;
            let l = null == e || null === (t = e.metaData) || void 0 === t ? void 0 : t.props[a];
            return Ja.isObject(l) && Ja.has(l, "rawValue") ? l.rawValue : l;
        }, n = s((() => e.metaData.id)), r = s((() => o("appId"))), u = le(), d = vo.options.map((({optionSetCode: e}) => e)), p = s((() => {
            let e = "normalSet";
            switch (o("optionType")) {
              case "normal":
                d.includes(o("optionValue")) || (e = "customSet");
                break;

              case "custom":
                e = "customValue";
            }
            return e;
        })), m = s((() => [ ...vo.options ])), c = s((() => o("isShowOptionCode"))), v = i(null), f = i(!1), g = i(!1), h = i(""), y = i([]), D = i([]), C = () => {
            var e;
            return null === (e = v.value) || void 0 === e ? void 0 : e.getElTreeRef();
        }, w = e => f.value = e, S = e => g.value = e, L = e => h.value = e, T = e => y.value = e, V = t => {
            t.length > e.limit ? (e.limitTip && Ka({
                type: "danger",
                message: e.limitTip
            }), b((() => {
                var t;
                null === (t = C()) || void 0 === t || t.setCheckedKeys(D.value.slice(0, e.limit + 1).map((e => e.id))), 
                a.emit("limit", D.value);
            }))) : D.value = t;
        };
        return {
            appId: r,
            sdk: u,
            cascadeCode: n,
            ssuTreeRef: v,
            isGlobalSearch: g,
            isLoading: f,
            searchValue: h,
            treeDataList: y,
            selectedList: D,
            optionSetType: p,
            normalTypes: d,
            isShowCode: c,
            allNormalSetList: m,
            getElTreeRef: C,
            setIsLoading: w,
            setIsGlobalSearch: S,
            setSearchValue: L,
            setTreeDataList: T,
            setSelectedList: V,
            clear: () => {
                w(!1), L(""), T([]), S(!1), V([]);
            },
            getRawValue: o,
            rootCtx: l,
            rootProps: t,
            getLabel: a => {
                let t = "";
                var l, o;
                return e.optionType === oe.NORMAL && e.optionValue === te.MODEL ? (t = `${null !== (l = a.optionCode) && void 0 !== l ? l : ""}`, 
                c.value && (t += null != a && a.optionName ? `(${null == a ? void 0 : a.optionName})` : "")) : (t = `${null !== (o = a.optionName) && void 0 !== o ? o : ""}`, 
                c.value && (t += null != a && a.optionCode ? `(${null == a ? void 0 : a.optionCode})` : "")), 
                t;
            }
        };
    })(e, a);
    return k(fo, t), t;
}, bo = () => r(fo), ho = (e, a, t) => {
    const {optionSetType: l, sdk: o, cascadeCode: i, appId: n, searchValue: r, isShowCode: s, getRawValue: u, rootProps: d} = t, p = (e = {}) => (t.setIsLoading(!0), 
    new Promise(((e, a) => {
        var t;
        let s = [];
        switch (l.value) {
          case "normalSet":
          case "customSet":
            o.metaData.getOptionSetByCode(n.value, i.value, r.value).then((a => {
                s = a, c(e, s);
            }));
            break;

          case "customValue":
            let a = u("customOptionList");
            s = null !== (t = null == a ? void 0 : a.optionList) && void 0 !== t ? t : [], s = s.map((e => (e.id || (e.id = Ja.uniqueId("custom_")), 
            e))), s = m(s), c(e, s);
        }
    }))), m = e => {
        let a = t.searchValue.value;
        if (!a) return e;
        let l = [];
        return e.forEach((e => {
            var t;
            if (e.optionName.indexOf(a) > -1 || s.value && e.optionCode.indexOf(a) > -1) l.push(e); else if ((null === (t = e.children) || void 0 === t ? void 0 : t.length) > 0) {
                let a = m(e.children);
                (null == a ? void 0 : a.length) > 0 && l.push({
                    ...e,
                    children: a
                });
            }
        })), l;
    }, c = (e, a) => {
        let l = v(a);
        t.setTreeDataList(null != l ? l : []), t.setIsLoading(!1), e(l);
    }, v = (e, a = 1) => {
        const t = [ te.MODEL, te.PRODUCTION_LINE ];
        return e.map((e => {
            var l;
            return t.includes(null === (l = d.value) || void 0 === l ? void 0 : l.optionValue) || null != e && e.enable ? (a < u("level") && e.children ? e.children = v(e.children, a + 1) : e.children = [], 
            u("isToLastLevel") && (e.disabled = e.children.length > 0), e) : null;
        })).filter(Boolean);
    }, f = e => {
        const a = t.getElTreeRef();
        if (a && "blur" === e && a.setCurrentKey(""), a && "firstFocus" === e && Aa(t.treeDataList.value)) {
            const e = t.treeDataList.value[0];
            a.setCurrentKey(e.id);
        }
    }, g = a => {
        t.setIsGlobalSearch(!1);
        const l = t.getElTreeRef();
        if (!l) return;
        let o = [];
        if (e.isMultiple) {
            const e = t.selectedList.value;
            o = !!e.find((e => e.id === a.id)) ? e.filter((e => e.id !== a.id)) : e.concat(a);
        } else o = Array.of(a), t.setSelectedList(o);
        t.setSelectedList(o), l.setCheckedKeys(o.map((e => e.id)));
    }, h = () => {
        const a = t.getElTreeRef();
        if (!a) return;
        let l = e.selectedValue.map((e => {
            var a;
            return null !== (a = e.id) && void 0 !== a ? a : e;
        }));
        a.setCheckedKeys(l), b((() => {
            t.setSelectedList(e.selectedValue), l.length > 0 ? a.setCurrentKey(l[0]) : f("firstFocus");
        }));
    };
    return P((() => t.isGlobalSearch.value), (() => {
        t.isGlobalSearch.value && f("blur");
    })), P((() => e.selectedIdList), (() => {
        h();
    })), {
        treeProps: {
            label: "name"
        },
        treeNodeClick: e => {
            null != e && e.disabled || (t.setIsGlobalSearch(!1), g(e));
        },
        getTreeData: p,
        treeNodeCheck: g,
        init: () => {
            p().then((() => {
                b((() => h()));
            }));
        }
    };
}, yo = e({
    name: "CascadeSearchInput",
    props: mo,
    emits: co,
    setup(e, a) {
        const t = bo(), {inputSearchInput: l, inputSearchClick: o} = ((e, a, t) => {
            const {getTreeData: l} = ho(e, 0, t), o = () => {
                const e = t.getElTreeRef();
                if (!e) return;
                const a = {
                    keyword: t.searchValue.value
                };
                l(null != a ? a : {}).then((a => {
                    null == e || e.setCheckedKeys(t.selectedList.value.map((e => e.id)));
                }));
            };
            return {
                search: o,
                inputSearchInput: e => {
                    t.setSearchValue(e);
                },
                inputSearchClick: () => {
                    b((() => {
                        t.setIsGlobalSearch(!0), o();
                    }));
                }
            };
        })(e, 0, t), {getRawValue: i} = t, n = s((() => "请输入名称" + (i("isShowOptionCode") ? "、编号" : "")));
        return () => f("div", {
            class: "cascade-input"
        }, [ f(V("ssu-input-text"), {
            placeholder: n.value,
            modelValue: t.searchValue.value,
            search: !0,
            onInput: l,
            onSearch: o,
            onKeyEnter: o
        }, null) ]);
    }
}), Do = e({
    name: "SelectList",
    props: {
        selectList: {
            type: Array,
            default: () => []
        },
        selectKey: {
            type: String,
            default: "name"
        },
        title: {
            type: String,
            default: "已选择项"
        }
    },
    emits: [ "handleItemDelete", "handleAllDelete" ],
    setup(e, a) {
        const t = s((() => Ja.cloneDeep(e.selectList)));
        s((() => e.selectKey));
        const l = () => {
            a.emit("handleAllDelete", t.value);
        };
        return () => {
            var o;
            return f("div", {
                class: "select-list common-select-list"
            }, [ f("div", {
                class: "select-list-header"
            }, [ f("div", null, [ f("span", null, [ e.title ]), f("span", {
                class: "select-list-header-tip"
            }, [ D("("), t.value.length, D(")") ]) ]), f("div", {
                onClick: l,
                class: "select-list-close"
            }, [ f(V("syc-icon"), {
                type: "delete-garbage",
                width: 18,
                height: 18
            }, null) ]) ]), f("div", {
                class: "select-list-overflow"
            }, [ !(null === (o = t.value) || void 0 === o || !o.length) && t.value.map(((l, o) => {
                var i;
                return f("div", {
                    key: l.id,
                    class: "select-list-item"
                }, [ f("span", null, [ null !== (i = l[e.selectKey]) && void 0 !== i ? i : "" ]), f("div", {
                    onClick: () => ((e, l) => {
                        t.value.splice(e, 1), a.emit("handleItemDelete", t.value, l);
                    })(o, l)
                }, [ f(V("syc-icon"), {
                    type: "close-simple",
                    width: 18,
                    height: 18
                }, null) ]) ]);
            })) ]) ]);
        };
    }
}), Co = e({
    name: "CascadeSelectedList",
    props: mo,
    emits: co,
    components: {
        SelectList: Do
    },
    setup(e, a) {
        const t = bo(), {selectedList: l, getLabel: o} = t, {deleteItem: i, deleteAllItem: n} = ((e, a, t) => {
            const {treeNodeCheck: l} = ho(e, 0, t);
            return {
                deleteAllItem: () => {
                    const e = t.getElTreeRef();
                    e && (t.setSelectedList([]), e.setCheckedKeys([]));
                },
                deleteItem: (e, a) => {
                    t.getElTreeRef() && l(a);
                }
            };
        })(e, 0, t), r = s((() => l.value.map((e => (null != e && e.optionCode && (null == e || e.optionCode), 
        {
            ...e,
            name: o(e)
        })))));
        return () => f(V("select-list"), {
            class: "cascade-selected-list",
            title: "已选择",
            selectList: r.value,
            onHandleItemDelete: i,
            onHandleAllDelete: n
        }, null);
    }
}), wo = e({
    name: "CascadeTree",
    props: {
        ...mo,
        filter: {
            type: Boolean,
            default: !1
        }
    },
    emits: co,
    setup(e, a) {
        const t = bo(), {treeDataList: l, ssuTreeRef: o, isShowCode: i, rootProps: n, getLabel: r} = t, {treeProps: s, filterMethod: u, treeNodeClick: d, treeNodeCheck: p, init: m} = ho(e, 0, t);
        return w(m), e => f(V("ssu-tree"), {
            ref: e => o.value = e,
            class: "cascade-tree",
            "is-show-folder": !0,
            border: !0,
            props: s,
            data: l.value,
            type: e.isMultiple ? "checkbox" : "radio",
            defaultDisabledKeys: e.filterSelectedIdList,
            "default-expand-all": !0,
            "node-key": "id",
            "check-strictly": !0,
            onNodeClick: d,
            onCheck: p,
            filterNodeMethod: u
        }, {
            label: ({data: a}) => {
                var t, l;
                let o = r(a);
                return f("div", {
                    class: "tree-flex"
                }, [ f("div", {
                    class: [ "tree-flex-item", (null == a || null === (t = a.entity) || void 0 === t ? void 0 : t.id) === e.currentModelId ? "min-item" : "normal-item" ]
                }, [ f(V("el-tooltip"), {
                    effect: "dark",
                    content: o,
                    placement: "top"
                }, {
                    default: () => [ f("div", null, [ o ]) ]
                }) ]), (null == a || null === (l = a.entity) || void 0 === l ? void 0 : l.id) === e.currentModelId && f(V("ssu-tag"), {
                    size: "small",
                    type: "primary"
                }, {
                    default: () => [ D("本机型号") ]
                }) ]);
            }
        });
    }
}), {setterMixin: So} = z, Lo = e({
    name: "SelectCascade",
    mixins: [ So ],
    props: mo,
    emits: co,
    components: {
        SearchInput: yo,
        SelectedList: Co,
        Tree: wo
    },
    setup(e, t) {
        const l = go(e, t), {isLoading: i, allNormalSetList: n, getRawValue: r} = l, {confirmDialog: u, closeDialog: d} = ((e, a, t) => ({
            closeDialog: () => {
                a.emit("closed");
            },
            confirmDialog: () => {
                a.emit("confirm", t.selectedList.value), a.emit("closed");
            }
        }))(0, t, l), p = s((() => "cascader-popover " + (e.isMultiple ? "is-multiple" : ""))), m = s((() => {
            let e = "";
            switch (r("optionType")) {
              case "custom":
                e = "自定义选项值";
                break;

              case "normal":
                const a = n.value.find((({optionSetCode: e}) => e === r("optionValue")));
                e = null == a ? void 0 : a.optionSetName;
            }
            return e;
        }));
        return () => f(V("BaseDialog"), E(t.attrs, {
            modelValue: e.modelValue,
            "onUpdate:modelValue": a => e.modelValue = a,
            title: m.value,
            "disabled-limit-height": !0,
            "destroy-on-close": !0,
            "custom-class": p.value,
            loading: i.value,
            width: 600,
            onClosed: d,
            "append-to-body": !0
        }), {
            default: () => [ f("div", {
                class: "cascade-select-content"
            }, [ f("div", {
                class: "head"
            }, [ f("div", {
                class: "search"
            }, [ f(V("search-input"), E({
                class: "search-input"
            }, e, Ja.omit(t.attrs, [ "class", "style" ])), null) ]) ]), o(f("div", {
                class: "cascade-body"
            }, [ f(wo, E({
                class: "tree"
            }, e, Ja.omit(t.attrs, [ "class", "style" ])), null) ]), [ [ a("loading"), i.value ] ]), e.isMultiple && f("div", {
                class: "right"
            }, [ f(Co, E({
                class: "selectedList"
            }, e, Ja.omit(t.attrs, [ "class", "style" ])), null) ]) ]) ],
            footer: () => f("div", {
                class: "footer"
            }, [ f(V("ssu-button"), {
                onClick: d
            }, {
                default: () => [ D("取消") ]
            }), f(V("ssu-button"), {
                type: "primary",
                onClick: u
            }, {
                default: () => [ D("确认") ]
            }) ])
        });
    }
}), To = {
    label: "label",
    value: "value"
};

var Vo = e({
    name: "",
    components: {
        SsuSelectField: Xa
    },
    props: {
        multiple: {
            type: Boolean,
            default: !1
        },
        placeholder: {
            type: String,
            default: "请选择"
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        modelValue: {
            type: [ String, Number, Boolean, Array ],
            default: ""
        },
        optionData: {
            type: Array,
            default: () => []
        },
        handleSelect: {
            type: Function,
            default: () => {}
        },
        propsKey: {
            type: Object,
            default: () => To
        },
        tagExtractText: {
            type: [ String, Function ],
            default: ""
        }
    },
    emits: {
        "update:modelValue": e => !0,
        removeTag: e => !0
    },
    setup(e, a) {
        const t = s({
            set(e) {
                a.emit("update:modelValue", e);
            },
            get: () => Ja.isArray(e.modelValue) ? e.modelValue : Array.of(e.modelValue)
        }), l = s((() => (...a) => Ja.isString(e.tagExtractText) ? e.tagExtractText : Ja.isFunction(e.tagExtractText) ? e.tagExtractText(...a) : ""));
        return {
            innerModelValue: t,
            innerOptionData: s((() => {
                const a = e.propsKey.value ?? To.value, t = e.propsKey.label ?? To.label, l = [];
                return e.optionData?.forEach((e => {
                    e.label = e[t], e.value = e[a], l.push(e);
                })), l;
            })),
            innerTagExtractText: l,
            handleRemoveTag: e => {
                a.emit("removeTag", e);
            }
        };
    }
});

const Eo = {
    class: "input-select-wrap"
}, Io = {
    key: 0,
    class: "input-select-tag"
};

Vo.render = function(e, a, o, i, n, r) {
    const s = V("ssu-select-field");
    return t(), l("div", Eo, [ f(s, {
        disabled: e.disabled,
        "select-props": {
            modelValue: e.innerModelValue,
            onChange: a => e.innerModelValue = a,
            optionData: e.innerOptionData,
            disabledOptionsPanel: !0,
            onRemoveTag: e.handleRemoveTag,
            placeholder: e.placeholder ?? "请选择"
        },
        "button-props": {
            onClick: e.handleSelect
        }
    }, {
        tag: v((a => [ y("span", null, O(a.currentLabel), 1), e.innerTagExtractText(a) ? (t(), 
        l("span", Io, O(e.innerTagExtractText(a)), 1)) : F("", !0) ])),
        _: 1
    }, 8, [ "disabled", "select-props", "button-props" ]) ]);
};

const Fo = (e = [], a = []) => e.map((e => {
    var t;
    return e.path = [ ...a, e.optionName ], e.name = e.optionName, (null === (t = e.children) || void 0 === t ? void 0 : t.length) > 0 && Fo(e.children, e.path), 
    e;
})), Oo = (e, a) => {
    const t = le(), l = s((() => {
        var e, t;
        return (null === (e = a.value) || void 0 === e || null === (t = e.toList) || void 0 === t ? void 0 : t.call(e)) || [];
    })), o = s((() => Ra(e.fieldValue))), i = s((() => g("optionType"))), n = s((() => {
        var a, t;
        return null == e || null === (a = e.metaData) || void 0 === a || null === (t = a.props) || void 0 === t ? void 0 : t.customOptionList;
    })), r = s((() => {
        var e, a, t;
        return Ja.cloneDeep((null === (e = n.value) || void 0 === e || null === (a = e.rawValue) || void 0 === a ? void 0 : a.optionList) || (null === (t = n.value) || void 0 === t ? void 0 : t.optionList) || []);
    })), u = s((() => g("isShowLevel"))), d = s((() => g("optionValue"))), p = s((() => i.value === oe.NORMAL && d.value === te.MODEL)), m = s((() => {
        var e;
        const a = [];
        let t = [];
        if (i.value === oe.CUSTOM) t = r.value; else {
            var n;
            const e = null === (n = l.value) || void 0 === n ? void 0 : n.find((e => e.dictCode === d.value));
            t = (null == e ? void 0 : e.items) || [];
        }
        return ka(t || [], {
            visit: e => {
                a.push(e);
            }
        }), null !== (e = o.value) && void 0 !== e && e.length ? a.filter((e => o.value.includes(e.id))) : [];
    })), c = (a = {}) => {
        var t, l, o, n;
        const s = p.value ? null !== (t = a.value) && void 0 !== t ? t : a.optionCode : null !== (l = null !== (o = null !== (n = a.name) && void 0 !== n ? n : a.label) && void 0 !== o ? o : a.optionName) && void 0 !== l ? l : a.value, d = (() => {
            var t, l;
            if ([ Q.TABLE, Q.DETAIL, Q.CARD ].includes(e.runtimeEnv) && p.value) return null !== (l = a.value) && void 0 !== l ? l : a.optionCode;
            if (i.value === oe.CUSTOM) {
                let e = Fo(r.value);
                a = ((e, a) => {
                    let t = [ ...e ];
                    for (;t && 0 !== t.length; ) {
                        let e = t.pop();
                        if (e.id === a.id) return e;
                        e.children && e.children.length && Array.prototype.push.apply(t, e.children);
                    }
                    return a;
                })(e, a);
            }
            return null === (t = a.path) || void 0 === t ? void 0 : t.join("/");
        })();
        return u.value ? d : s;
    }, v = s((() => m.value.map(c))), f = s((() => {
        var e;
        return null !== (e = v.value) && void 0 !== e && e.length ? v.value.join(ie) : Y;
    })), g = a => {
        var t;
        let l = null == e || null === (t = e.metaData) || void 0 === t ? void 0 : t.props[a];
        return Ja.isObject(l) ? l.rawValue : l;
    };
    return {
        dictionaryList: l,
        displayList: v,
        displayText: f,
        dictionaryCode: d,
        optionType: i,
        customOptionList: n,
        optionList: r,
        getLevelText: c,
        getRawValue: g,
        fetchDictionaryListByCode: e => t.metaData.getDictionaryByCode(e).then((t => {
            var l, o;
            if (Array.isArray(t)) return null === (l = a.value) || void 0 === l || null === (o = l.addCache) || void 0 === o || o.call(l, {
                cacheKey: e,
                dictCode: e,
                items: t
            }), t;
        })),
        getDictionaryByCode: e => l.value.find((a => a.dictCode === e))
    };
}, {formComponentMixin: No} = z;

var Mo = e({
    name: "OlCascader",
    components: {
        SelectCascade: Lo,
        InputSelect: Vo
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        isMultiple: {
            type: Boolean,
            default: !1
        },
        optionValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ No ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = le(), o = s((() => {
            const a = e.metaData?.props?.appId;
            return Ja.isObject(a) ? a?.rawValue : a;
        })), n = Na(e), r = ne("dictionary"), u = s((() => Ja.get(e, "metaData.props.level")), 1), d = s((() => ({
            ...e,
            ...a
        }))), {getLevelText: p, dictionaryCode: m, optionType: c, optionList: v, fetchDictionaryListByCode: f, getDictionaryByCode: g} = Oo(e, r), b = s((() => c.value === oe.CUSTOM));
        s((() => e.isMultiple));
        const h = s((() => e.runtimeEnv === Q.DESIGNER)), y = i(!1), D = i([]), C = i(1), S = i(20), L = i(""), T = i(!1), V = i([]), E = i(!1), I = s((() => !g(m.value))), F = i(0), O = async () => {
            f(m.value);
        }, N = s({
            get: () => {
                let a = [];
                if (c.value === oe.CUSTOM) a = v.value; else {
                    if (I.value) return void O();
                    a = g(m.value).items;
                }
                const t = [];
                ka(a, {
                    visit: e => {
                        t.push(e);
                    }
                });
                return ((n.value || e.metaData.defaultValue)?.filter(Boolean) ?? []).map((e => {
                    const a = t?.find?.((a => a.id === e));
                    return !a && F.value < 3 && (F.value = F.value + 1, O()), {
                        ...a,
                        value: a?.id,
                        name: p(a) ?? "已删除选项",
                        optionName: p(a) ?? "已删除选项"
                    };
                }));
            },
            set: e => {
                Ja.isEmpty(e) ? t("update:modelValue", null) : Array.isArray(e) ? t("update:modelValue", e.map((e => e.id))) : t("update:modelValue", [ e.id ]);
            }
        });
        P((() => N.value), (() => {
            I.value && O();
        }), {
            deep: !0,
            immediate: !0
        }), P((() => e.metaData.defaultValue), (e => {
            n.value && (N.value && N.value.length || (N.value = e));
        }));
        const M = () => l.metaData.getOptionListByCode(o.value, e.metaData?.id, {
            pageNum: C.value,
            keyword: L.value,
            pageSize: S.value
        }), R = (e = []) => {
            V.value = V.value.filter((a => !e.find((e => e.id === a.id))));
        }, x = async e => {
            L.value = e, T.value = !0, D.value = [], C.value = 1;
            const a = await M();
            y.value = a.more, R(a.result ?? []), D.value = a.result, T.value = !1;
        };
        return w((() => {
            const e = ((e, a) => {
                const t = [];
                function l(e, o) {
                    if (o <= a && t.push(e), e.children && o <= a) for (const a of e.children) l(a, o + 1);
                }
                for (const a of e) l(a, 0);
                return t;
            })(v.value, u.value - 1);
            b.value && (D.value = [ ...e ]);
        })), {
            dataValue: N,
            bindData: d,
            dialogVisible: E,
            handleOpenDialog: () => {
                !Ja.has(e.metaData, "saved") || e.metaData.saved ? E.value = !0 : Ka({
                    type: "warning",
                    message: "表单未保存时暂无法预览该组件"
                });
            },
            handleClosed: () => {
                E.value = !1;
            },
            handleConfirm: e => {
                const a = Array.isArray(e) ? e : [ e ];
                b.value || (V.value = [ ...a ]), N.value = a.map((e => ({
                    ...e,
                    value: e.id,
                    name: e.optionName,
                    label: e.optionName
                })));
            },
            filterMethod: x,
            loading: T,
            handleLoadMore: async e => {
                if (b.value || T.value || !y.value) return;
                T.value = !0, C.value++;
                const a = await M();
                y.value = a.more, R(a.result ?? []), D.value = D.value.concat(a.result ?? []), T.value = !1;
            },
            optionData: D,
            visibleChange: e => {
                e && !b.value && x("");
            },
            isDesigner: h,
            dynamicOptions: V
        };
    }
});

const Ro = {
    class: "ol-cascade"
}, xo = {
    class: "ol-cascade-input"
};

Mo.render = function(e, a, o, i, n, r) {
    const s = V("input-select"), u = V("select-cascade");
    return t(), l("div", Ro, [ y("div", xo, [ f(s, E(e.bindData, {
        modelValue: e.dataValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.dataValue = a),
        "option-data": e.dataValue,
        "props-key": {
            label: "optionName",
            value: "value"
        },
        multiple: e.isMultiple,
        "handle-select": e.handleOpenDialog,
        clearable: !0
    }), null, 16, [ "modelValue", "option-data", "multiple", "handle-select" ]) ]), e.dialogVisible ? (t(), 
    L(u, E({
        key: 0
    }, e.bindData, {
        modelValue: e.dialogVisible,
        "onUpdate:modelValue": a[1] || (a[1] = a => e.dialogVisible = a),
        optionValue: e.optionValue,
        selectedValue: e.dataValue,
        metaData: e.metaData,
        "is-multiple": e.isMultiple,
        onClosed: e.handleClosed,
        onConfirm: e.handleConfirm
    }), null, 16, [ "modelValue", "optionValue", "selectedValue", "metaData", "is-multiple", "onClosed", "onConfirm" ])) : F("", !0) ]);
};

const {formComponentMixin: Ao} = z;

var ko = e({
    name: "OlCascaderMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ Ao ],
    setup: e => () => f(et, null, null)
});

const Po = xa([ {
    label: "支持多选",
    key: "isMultiple"
}, {
    label: "必须选择到最后一级",
    key: "isToLastLevel"
}, {
    label: "选择结果显示层级路径",
    key: "isShowLevel"
}, {
    label: "显示选项编码",
    key: "isShowOptionCode"
} ]), _o = xa([ {
    label: "通用选项集",
    value: "normal"
}, {
    label: "自定义选项值",
    value: "custom"
} ]), {setterMixin: Uo} = z, Bo = e({
    name: "OlCascadeConfigSetter",
    props: {
        configList: {
            type: Array,
            default: () => []
        }
    },
    mixins: [ Uo ],
    emits: [ "update:configList" ],
    setup(e, {emit: a}) {
        const {convertOptionListToObj: t, convertObjToOptionList: l} = H, o = i(Po.options), n = s((() => i(l({
            isMultiple: e.metaData.props.isMultiple.rawValue,
            isToLastLevel: e.metaData.props.isToLastLevel.rawValue,
            isShowLevel: e.metaData.props.isShowLevel.rawValue,
            isShowOptionCode: e.metaData.props.isShowOptionCode.rawValue
        }, !1)).value));
        return () => f(V("CustomFormItem"), {
            label: "设置",
            setter: !0
        }, {
            default: () => [ f(V("CheckBoxGroupWrapper"), {
                optionList: o.value,
                enableList: n.value,
                "onUpdate:enableList": (a = []) => {
                    const l = t(a, Po.options, !1);
                    let {isMultiple: o, isToLastLevel: i, isShowLevel: n, isShowOptionCode: r} = l;
                    e.metaData.props.isMultiple.rawValue = o, e.metaData.props.isToLastLevel.rawValue = i, 
                    e.metaData.props.isShowLevel.rawValue = n, e.metaData.props.isShowOptionCode.rawValue = r;
                }
            }, null) ]
        });
    }
}), {setterMixin: Wo} = z;

var qo = e({
    name: "OlCascadeLevel",
    mixins: [ Wo ],
    setup(e) {
        const a = s((() => {
            var a;
            let t = null === (a = e.metaData.props.level) || void 0 === a ? void 0 : a.rawValue;
            return t ? Number(t) : 1;
        })), t = s((() => [ 1, 2, 3, 4 ].map((e => ({
            label: e,
            value: e
        })))));
        return () => f(V("CustomFormItem"), {
            label: "选择层级",
            setter: !0,
            questionText: "根据所配置的选项集值层级确定，可选项为小于等于该层级数值。"
        }, {
            default: () => [ f(V("ssu-select"), {
                modelValue: a.value,
                "onUpdate:modelValue": a => {
                    e.metaData.props.level.rawValue = a;
                },
                "option-data": t.value,
                placeholder: "请选择"
            }, null) ]
        });
    }
});

const {setterMixin: jo} = z;

var $o = e({
    name: "OlCascadeOption",
    props: {
        runtimePlatform: {
            type: String,
            default: ""
        }
    },
    mixins: [ jo ],
    setup(e) {
        const a = s((() => {
            var a;
            return null === (a = e.metaData.props.optionType) || void 0 === a ? void 0 : a.rawValue;
        })), t = i(_o.options);
        P(a, (e => {
            l.value = "custom" === e;
        }));
        const l = i(!1), o = (a, t, l) => {
            e.metaData.props.customOptionList.rawValue = a, l();
        };
        return () => {
            var i;
            return f(V("CustomFormItem"), {
                label: "选项内容",
                setter: !0
            }, {
                default: () => [ f(V("ssu-select"), {
                    modelValue: a.value,
                    "onUpdate:modelValue": a => {
                        e.metaData.props.optionType.rawValue = a;
                    },
                    "option-data": t.value,
                    placeholder: "请选择选项集类型",
                    class: "cascade-option__type"
                }, null), "normal" === a.value ? f(V("option-set-setter"), {
                    metaData: e.metaData,
                    appData: e.appData,
                    optionSetValue: null === (i = e.metaData.props.optionValue) || void 0 === i ? void 0 : i.rawValue,
                    runtimePlatform: e.runtimePlatform,
                    "onUpdate:optionSetValue": a => e.metaData.props.optionValue.rawValue = a
                }, null) : f(V("ssu-input-text"), {
                    readonly: !0,
                    "css-suffix-icon": "iconbianji",
                    placeholder: "自定义选项值",
                    onClick: () => {
                        l.value = !0;
                    }
                }, null), f(V("option-set-operation"), {
                    modelValue: l.value,
                    "onUpdate:modelValue": e => l.value = e,
                    optionData: e.metaData.props.customOptionList.rawValue,
                    dialogType: "optionConfig",
                    onSave: o
                }, null) ]
            });
        };
    }
});

const {propsFormMixin: zo} = z;

var Go = e({
    name: "OlCascaderPropsForm",
    mixins: [ zo ],
    components: {
        CascadeConfigSetter: Bo,
        CascadeLevelSetter: qo,
        CascadeOption: $o
    },
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return w((() => {
            e.metaData.props.appId && (e.metaData.props.appId.rawValue = e.appData.id);
        })), () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-Cascade-select-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("cascade-option"), {
                metaData: e.metaData,
                appData: e.appData,
                runtimePlatform: e.runtimePlatform
            }, null), f(V("Cascade-level-setter"), {
                metaData: e.metaData
            }, null), f(V("Cascade-config-setter"), {
                metaData: e.metaData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (a = []) => {
                    e.metaData.validateRuleConfigList = a;
                }
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Ho, metaDataMixin: Qo} = z;

var Yo = e({
    name: "OlCascaderTableFormatter",
    mixins: [ Ho, Qo ],
    setup(e) {
        const a = ne("dictionary"), {displayText: t, dictionaryCode: l, fetchDictionaryListByCode: o, getDictionaryByCode: i} = Oo(e, a);
        return P((() => e.fieldValue), Ja.debounce((e => {
            null != e && e.length && requestIdleCallback((() => {
                i(l.value) || o(l.value);
            }));
        }), 500), {
            immediate: !0
        }), () => f("label", {
            class: "cascade-table-formatter"
        }, [ t.value ]);
    }
});

var Ko = {
    code: K.CASCADER,
    name: "级联选择",
    group: X.HIGH_LEVEL,
    icon: "folder",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlCascader",
    mobileWidget: "OlCascaderMobile",
    propsForm: "OlCascaderPropsForm",
    tableFormatter: "OlCascaderTableFormatter",
    props: {
        placeholder: "请选择",
        level: 1,
        optionType: "normal",
        optionValue: te.PRODUCTION_LINE,
        customOptionList: {},
        isMultiple: !1,
        isToLastLevel: !1,
        isShowLevel: !1,
        isShowOptionCode: !1,
        appId: ""
    },
    defaultValue: [],
    prependAction: {
        type: "action",
        action: ({widget: e, appData: a = {}}) => {
            var t;
            null != e && null !== (t = e.props) && void 0 !== t && t.appId && (e.props.appId.rawValue = a.id);
        }
    },
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.CASCADER ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Mo.meta = Ko, Mo.PropsForm = Go, Mo.Mobile = ko, Mo.install = e => {
    e.component(Mo.name, Mo), e.component(Go.name, Go), e.component(ko.name, ko), e.component(Yo.name, Yo);
}, Mo.widgetMap = {
    [ko.name]: ko,
    [Mo.name]: Mo,
    [Go.name]: Go,
    [Yo.name]: Yo
};

const Xo = require("color"), {formComponentMixin: Jo} = z;

var Zo = e({
    name: "OlCheckbox",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        direction: {
            type: String,
            default: "horizontal"
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        optionsType: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        publicReleaseShareCode: {
            type: String,
            default: ""
        }
    },
    mixins: [ Jo ],
    emits: [ "update:modelValue", "*" ],
    setup(e, {attrs: a, emit: t}) {
        const {curOptions: l} = ga(e), o = s((() => ({
            ...e,
            ...a
        }))), i = s({
            get: () => e.modelValue || [],
            set: e => {
                if (!Array.isArray(e)) return void d(null);
                const a = e.filter((e => !!l.value.find((a => a.value === e))));
                d(a);
            }
        }), n = s((() => "vertical" === e.direction)), r = s((() => o.value.isMobile)), u = s((() => e.enableColor)), d = e => {
            t("update:modelValue", e);
        };
        return () => {
            let e;
            return f(V("ssu-checkbox-group"), E(o.value, {
                modelValue: i.value,
                "onUpdate:modelValue": e => i.value = e,
                class: "ol-checkbox ol-checkbox-mobile",
                style: {
                    display: n.value ? "flex" : "block",
                    "flex-direction": n.value ? "column" : "row"
                }
            }), "function" == typeof (a = e = l.value.map((e => f(V("ssu-checkbox"), {
                label: e.value,
                class: "checkbox-mobile"
            }, {
                default: () => [ f("div", {
                    class: [ u.value && !r.value ? "color-tag" : "color-tag-mobile", "checkbox-label-mobile" ],
                    style: u.value && e.color && {
                        color: e.color,
                        border: `1px solid ${e.color}`,
                        "background-color": r.value ? `${Xo(e.color).alpha("0.1").toString()}` : ""
                    } || void 0
                }, [ e.label ]) ]
            })))) || "[object Object]" === Object.prototype.toString.call(a) && !N(a) ? e : {
                default: () => [ e ]
            });
            var a;
        };
    }
});

const {formComponentMixin: ei} = z;

var ai = e({
    name: "OlCheckboxMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ ei ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: ti} = z;

var li = e({
    name: "OlCheckboxPropsForm",
    mixins: [ ti ],
    setup(e, {expose: a}) {
        const t = s({
            get: () => e.metaData.props.options.rawValue,
            set(a) {
                e.metaData.props.options.rawValue = a;
            }
        }), l = s((() => e.metaData.validateRuleConfig)), {data: o, formRef: n} = fa(e, a), {convertOptionListToObj: r, convertObjToOptionList: u} = H, d = s((() => i(u({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), p = ba(e.metaData, "optionsType", oe.CUSTOM), m = ba(e.metaData, "optionSetValue", te.PRODUCTION_LINE);
        return () => f(V("el-form"), {
            ref: n,
            model: o,
            "label-position": "top",
            class: "ol-checkbox-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("options-type-setter"), {
                modelValue: p.value,
                "onUpdate:modelValue": e => p.value = e,
                metaData: e.metaData,
                optionList: re.options
            }, null), p.value === oe.CUSTOM && f(V("option-setter"), {
                metaData: e.metaData,
                optionList: t.value,
                "onUpdate:optionList": e => t.value = e,
                type: "multiple"
            }, null), p.value === oe.OPTION_SET && f(V("option-set-setter"), {
                metaData: e.metaData,
                appData: e.appData,
                runtimePlatform: e.runtimePlatform,
                optionSetValue: m.value,
                "onUpdate:optionSetValue": e => m.value = e
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: l.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("option-layout-setter"), {
                metaData: e.metaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: d.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = r(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const oi = require("color"), {formEntityMixin: ii, metaDataMixin: ni} = z;

var ri = e({
    props: {
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        optionsType: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        }
    },
    name: "OlCheckboxTableFormatter",
    mixins: [ ii, ni ],
    setup(e) {
        const {curOptions: a} = ga(e), t = s((() => {
            if (!Array.isArray(e.fieldValue) || !e.fieldValue.length) return [];
            return e.fieldValue.map((e => {
                const t = a.value.find((a => a.value === e));
                return t || {
                    label: e
                };
            })).map((a => e.enableColor && f(V("ssu-tag"), {
                class: "color-tag",
                style: a.color && {
                    border: `1px solid ${a.color}`,
                    color: a.color,
                    "background-color": `${oi(a.color).alpha("0.1").toString()}`
                } || void 0
            }, {
                default: () => [ a.label ]
            }) || a.label));
        }));
        return () => f("label", {
            class: "ol-checkbox-table-formatter"
        }, [ t.value.length > 0 ? e.enableColor && t.value.map((e => e)) || t.value.join(ie) : Y ]);
    }
});

const si = e => a => (e => Ja.get(e, "props.returnType.rawValue"))(a) === e, ui = si("text"), di = si("number"), pi = si("date"), mi = {
    code: K.FORMULA,
    when: ({targetWidget: e, widget: a}) => ui(e)
}, ci = {
    code: K.FORMULA,
    when: ({targetWidget: e, widget: a}) => pi(e)
}, vi = {
    code: K.FORMULA,
    when: ({targetWidget: e, widget: a}) => di(e)
};

K.FORMULA;

var fi = {
    code: K.CHECKBOX,
    name: "多选",
    group: X.NORMAL,
    icon: "duoxuan",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlCheckbox",
    propsForm: "OlCheckboxPropsForm",
    tableFormatter: "OlCheckboxTableFormatter",
    props: {
        options: [ {
            label: "选项一",
            value: "选项一",
            score: "",
            code: "1"
        }, {
            label: "选项二",
            value: "选项二",
            score: "",
            code: "2"
        }, {
            label: "选项三",
            value: "选项三",
            score: "",
            code: "3"
        } ],
        enableColor: !1,
        enableScore: !1,
        direction: "horizontal",
        optionsType: "custom",
        optionSetValue: te.PRODUCTION_LINE
    },
    extraValues: [],
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.SINGLE_SELECT, K.MULTIPLE_SELECT, K.RADIO, K.CHECKBOX ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Zo.meta = fi, Zo.PropsForm = li, Zo.Mobile = ai, Zo.install = e => {
    e.component(Zo.name, Zo), e.component(li.name, li), e.component(ai.name, ai), e.component(ri.name, ri);
}, Zo.widgetMap = {
    [ai.name]: ai,
    [Zo.name]: Zo,
    [li.name]: li,
    [ri.name]: ri
};

var gi = {
    code: K.DATETIME,
    name: "日期",
    group: X.NORMAL,
    icon: "shijianriqi",
    widgetType: J.FORM,
    dataType: Z.DATE,
    widget: "OlDatetime",
    mobileWidget: "OlDatetimeMobile",
    propsForm: "OlDatetimePropsForm",
    tableFormatter: "OlDatetimeTableFormatter",
    props: {
        placeholder: "请选择",
        format: se.options[0].value,
        datetimeSelectRange: {
            startTime: {
                isEnable: !1,
                valueType: ue.fixedTime,
                isCustomValueType: !0,
                value: Date.now()
            },
            endTime: {
                isEnable: !1,
                valueType: ue.fixedTime,
                isCustomValueType: !0,
                value: Date.now()
            }
        },
        displayStyleRules: []
    },
    defaultValue: void 0,
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.BETWEEN,
        supportWidgetCodeConfig: [ K.DATETIME, ci ],
        supportOperator: {
            [ee.BETWEEN]: ae[ee.BETWEEN],
            [ee.NOT_BETWEEN]: ae[ee.NOT_BETWEEN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.LESS]: ae[ee.LESS],
            [ee.LESS_OR_EQUAL]: ae[ee.LESS_OR_EQUAL],
            [ee.GREATER]: ae[ee.GREATER],
            [ee.GREATER_OR_EQUAL]: ae[ee.GREATER_OR_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

const {formComponentMixin: bi} = z;

var hi = e({
    name: "OlDatetime",
    props: {
        modelValue: {
            type: [ Number, String ]
        },
        format: {
            type: String
        },
        datetimeSelectRange: {
            type: Object,
            default: () => gi.props.datetimeSelectRange
        },
        formData: {
            type: Object,
            default: () => ({})
        }
    },
    emits: [ "update:modelValue" ],
    mixins: [ bi ],
    setup(e, {attrs: a, emit: t}) {
        const l = s((() => e.formData ?? {})), o = s((() => e.mainFormData)), i = s((() => e.inSubForm)), n = s((() => e.datetimeSelectRange ?? {})), {selectTimeList: r} = ha(i, n, l, o), u = s({
            get: () => Number(e.modelValue),
            set(e) {
                (e => {
                    const a = e ? !isNaN(+e) && +e : null;
                    t("update:modelValue", a);
                })(e);
            }
        }), d = s((() => ({
            ...e,
            ...a,
            format: "timestamp"
        }))), p = s((() => se.getOption(e.format)?.type)), m = s((() => e => {
            const a = e.getTime(), [t, l] = _(r);
            return !(a >= t && a <= l);
        })), c = () => {
            const [a, t] = _(r), s = e.formRules?.find((e => "dateRange" === e?.key)), u = de({
                params: {
                    startTime: a,
                    endTime: t
                },
                inSubForm: _(i),
                mainFormData: _(o),
                formData: _(l),
                datetimeSelectRange: n.value
            });
            if (s) return Ja.isArray(s) ? void e.formRules.push({
                key: "dateRange",
                validator: u
            }) : void (s.validator = u);
            e.formRules.push({
                key: "dateRange",
                validator: u
            });
        };
        return P((() => [ u.value, r.value, l.value, i.value, o.value, n.value ]), Ja.debounce((() => {
            c();
        }), 100), {
            deep: !0
        }), w((() => {
            c();
        })), U((() => {
            c();
        })), {
            data: u,
            disabledDate: m,
            bindData: d,
            formateType: p
        };
    }
});

hi.render = function(e, a, l, o, i, n) {
    const r = V("ssu-date-picker-v2");
    return t(), L(r, E({
        modelValue: e.data,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.data = a)
    }, e.bindData, {
        type: e.formateType,
        "disabled-date": e.disabledDate,
        format: e.format,
        class: "ol-date"
    }), null, 16, [ "modelValue", "type", "disabled-date", "format" ]);
};

const yi = e({
    name: "SelectMobile",
    props: {
        modelValue: {
            type: [ String, Array ],
            default: ""
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        isPlaceholder: {
            type: Boolean,
            default: !1
        }
    },
    setup(e) {
        const a = s((() => e.disabled)), t = s((() => e.isPlaceholder)), l = s((() => Array.isArray(e.modelValue) ? e.modelValue.length ? `已选择${e.modelValue[0]}等${e.modelValue.length}项` : "" : e.modelValue || ""));
        return () => f("div", {
            class: [ "commonSelectMobile", a.value && "disabled" ]
        }, [ f("div", {
            class: "content"
        }, [ t.value && f("span", {
            class: "placeholder"
        }, [ D("请选择") ]), !t.value && f("span", null, [ l.value ]) ]), f(V("syc-icon"), {
            class: "icon",
            width: 24,
            height: 14,
            type: "xiala",
            color: "#919399"
        }, null) ]);
    }
}), Di = require("dayjs"), {formComponentMixin: Ci} = z;

var wi = e({
    name: "OlDatetimeMobile",
    props: {
        modelValue: {
            type: [ Number, String ]
        },
        format: {
            type: String
        }
    },
    mixins: [ Ci ],
    components: {
        SelectMobile: yi
    },
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a
        }))), n = l || i(e.metaData.defaultValue);
        let r = i([]);
        const u = e.format, d = [ "YYYY", "MM", "DD", "HH", "mm", "ss" ], p = i(!1), m = i([]), c = i(), v = s((() => e.disabled)), g = s((() => !c.value)), b = (e, a) => {
            const t = [];
            let l = e || (new Date).getFullYear(), o = a || (new Date).getMonth() + 1;
            o = parseInt(o, 10);
            const i = new Date(l, o, 0).getDate();
            for (let e = 1; e <= i; e++) e < 10 ? t.push("0" + e) : t.push(e + "");
            return t;
        }, h = () => y(60), y = (e, a) => {
            let t = [];
            for (let l = 0; l < e; l++) "month" === a ? l < 9 ? t.push("0" + (l + 1)) : t.push(l + 1 + "") : l < 10 ? t.push("0" + l) : t.push(l + "");
            return t;
        }, D = {
            YYYY: {
                method: (e = 10, a = 10) => {
                    for (var t = new Date, l = t.getFullYear() - e, o = t.getFullYear() + a, i = [], n = l; n <= o; n++) i.push(n + "");
                    return i;
                }
            },
            MM: {
                method: () => y(12, "month")
            },
            DD: {
                method: b
            },
            HH: {
                method: () => y(24)
            },
            mm: {
                method: h
            },
            ss: {
                method: h
            }
        }, C = {
            YYYY: "YYYY",
            "YYYY-MM": "YYYY-MM",
            "YYYY-MM-DD": "YYYY-MM-DD",
            "YYYY-MM-DD HH": "YYYY-MM-DD-HH",
            "YYYY-MM-DD HH:mm": "YYYY-MM-DD-HH-mm",
            "YYYY-MM-DD HH:mm:ss": "YYYY-MM-DD-HH-mm-ss"
        };
        P((() => n.value), (e => {
            if (e) {
                const a = Di(e).format(C[u]), l = a.split("-");
                m.value = l, c.value = a, t("update:modelValue", e);
            }
        }), {
            immediate: !0
        });
        P((() => p.value), (e => {
            if (e) {
                let e = [];
                return d.forEach((a => {
                    if (u.indexOf(a) >= 0) {
                        let t = 0;
                        t = (e => {
                            const [a, t, l, o, i, n] = m.value;
                            let r = 0, s = a || "" + (new Date).getFullYear(), u = t || ((new Date).getMonth() + 1 < 10 ? "0" + ((new Date).getMonth() + 1) : (new Date).getMonth() + 1), d = l || ((new Date).getDate() < 10 ? "0" + (new Date).getDate() : (new Date).getDate()), p = o || "00", c = i || "00", v = n || "00";
                            switch (e) {
                              case "YYYY":
                                r = D[e].method().indexOf(s) >= 0 ? D[e].method().indexOf(s) : 0;
                                break;

                              case "MM":
                                r = D[e].method().indexOf("" + u) >= 0 ? D[e].method().indexOf("" + u) : 0;
                                break;

                              case "DD":
                                r = D[e].method().indexOf("" + d) >= 0 ? D[e].method().indexOf("" + d) : 0;
                                break;

                              case "HH":
                                r = D[e].method().indexOf("" + p) >= 0 ? D[e].method().indexOf("" + p) : 0;
                                break;

                              case "mm":
                                r = D[e].method().indexOf("" + c) >= 0 ? D[e].method().indexOf("" + c) : 0;
                                break;

                              default:
                                r = D[e].method().indexOf("" + v) >= 0 ? D[e].method().indexOf("" + v) : 0;
                            }
                            return r;
                        })(a), e.push({
                            values: D[a].method(),
                            defaultIndex: t
                        });
                    }
                })), void (r.value = e);
            }
            r.value = [];
        }));
        const w = () => {
            p.value = !1;
        }, S = e => {
            let a = "";
            e.forEach(((e, t) => {
                switch (t) {
                  case 0:
                    a += `${e}`;
                    break;

                  case 1:
                  case 2:
                    a += `-${e}`;
                    break;

                  case 3:
                    a += ` ${e}`;
                    break;

                  default:
                    a += `:${e}`;
                }
            })), c.value = a, 13 === a.length && (a = `${a}:00:00`), n.value = new Date(a).getTime(), 
            w();
        }, L = () => {
            w();
        }, T = e => {
            m.value = e;
        };
        return P((() => m.value[1]), (e => {
            if (m.value.length > 2) {
                let a = {
                    values: b(m.value[0], e),
                    defaultIndex: 0
                };
                r.value.splice(2, 1, a);
            }
        })), () => f("div", {
            className: "ol-date-mobile"
        }, [ f(yi, {
            modelValue: c.value,
            "onUpdate:modelValue": e => c.value = e,
            onClick: () => {
                !o.value.disabled && (p.value = !0);
            },
            disabled: v.value,
            isPlaceholder: g.value
        }, null), f(at, {
            show: p.value,
            "onUpdate:show": e => p.value = e,
            round: !0,
            position: "bottom",
            style: {
                height: "50%"
            }
        }, {
            default: () => [ f(tt, {
                class: "ol-date-mobile-picker",
                title: "请选择时间",
                columns: r.value,
                onChange: T,
                onCancel: L,
                onConfirm: S
            }, null) ]
        }) ]);
    }
});

const {propsFormMixin: Si} = z;

var Li = e({
    name: "OlDatetimePropsForm",
    mixins: [ Si ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), d = ba(e.metaData, "datetimeSelectRange", gi.props.datetimeSelectRange);
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-date-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                appData: e.appData,
                pageData: e.pageData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (a = []) => {
                    e.metaData.props.validateRuleConfigList = a;
                }
            }, null), f(V("date-format-setter"), {
                metaData: e.metaData
            }, null), f(V("datetime-select-range-setter"), {
                datetimeSelectRange: d.value,
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (a = []) => {
                    e.metaData.props.validateRuleConfigList = a;
                },
                inSubForm: e.inSubForm
            }, null), f(V("display-style-rule-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                inSubForm: e.inSubForm,
                pageData: e.pageData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Ti, metaDataMixin: Vi} = z;

var Ei = e({
    props: {
        format: {
            type: String,
            default: se.options[0].value
        },
        displayStyleRules: {
            type: Array,
            default: () => []
        }
    },
    name: "OlDatetimeTableFormatter",
    mixins: [ Ti, Vi ],
    setup(e) {
        const {displayStyleRulesColorResult: a} = ya(s((() => e.displayStyleRules)), s((() => e.pageMetaData)), s((() => e.formData))), t = s((() => e.fieldValue ? dt(e.fieldValue).format(e.format) : Y));
        return () => f("label", {
            class: "ol-date-table-formatter",
            style: {
                color: a.value
            }
        }, [ t.value ]);
    }
});

hi.meta = gi, hi.PropsForm = Li, hi.Mobile = wi, hi.install = e => {
    e.component(hi.name, hi), e.component(Li.name, Li), e.component(wi.name, wi), e.component(Ei.name, Ei);
}, hi.widgetMap = {
    [wi.name]: wi,
    [hi.name]: hi,
    [Li.name]: Li,
    [Ei.name]: Ei
};

const Ii = {
    multiple: {
        type: Boolean,
        default: !1
    },
    selectedValue: {
        type: Array,
        default: () => []
    },
    filterSelectedValue: {
        type: Array,
        default: () => []
    },
    selectMobileVisible: {
        type: Boolean,
        default: !1
    }
}, Fi = {
    "update:selected-value": e => Array.isArray(e),
    closed: null,
    opended: null,
    confirm: e => Array.isArray(e)
};

const Oi = Pa({
    "head-height": "58px",
    content: "index-module_content__RJydg",
    body: "index-module_body__MPazh",
    typeIcon: "index-module_typeIcon__nff9s",
    title: "index-module_title__Lscvz",
    iconLinear: "index-module_iconLinear__PI4xZ",
    footer: "index-module_footer__M2Dps"
}), Ni = e({
    name: "Dialog",
    props: {
        type: {
            type: String,
            default: ""
        },
        iconLinear: {
            type: Boolean,
            default: !1
        },
        customClass: {
            type: String,
            default: ""
        },
        disabledLimitHeight: {
            type: Boolean,
            default: !1
        },
        closeOnClickModal: {
            type: Boolean,
            default: !1
        },
        appendToBody: {
            type: Boolean,
            default: !0
        },
        loading: {
            type: Boolean,
            default: !1
        },
        zIndex: {
            type: [ Number, String ]
        }
    },
    setup(e, t) {
        const l = i(), n = Ja.omit(t.attrs, [ "class", "style" ]), r = Ja.omit(e, "disabledLimitHeight", "loading", "type", "iconLinear"), u = s((() => `typeIcons/${e.type}`)), d = s((() => `commonDialog ${e.customClass} ${e.disabledLimitHeight ? "" : "isLimitHeight"} ${t.slots.footer ? "isFooterShow" : ""} ${u.value ? `${e.type}` : ""}`));
        return t.expose({
            getElDialogRef: () => l.value
        }), () => {
            var l;
            return f(V("ssu-dialog"), E(n, r, {
                ref: "elDialogRef",
                "custom-class": d.value,
                "close-on-click-modal": e.closeOnClickModal,
                "append-to-body": e.appendToBody,
                "z-index": e.zIndex
            }), {
                default: () => [ o(f("div", {
                    class: Oi.content
                }, [ f("div", {
                    class: [ Oi.body, e.iconLinear && Oi.iconLinear ]
                }, [ o(f("div", {
                    class: Oi.typeIcon
                }, [ u.value && f(V("syc-icon"), {
                    name: u.value
                }, null) ]), [ [ g, null === (l = u.value) || void 0 === l ? void 0 : l.name ] ]), _a(t.slots.default, null) ]), f("div", {
                    class: Oi.footer
                }, [ _a(t.slots.footer, null) ]) ]), [ [ a("loading"), e.loading ] ]) ],
                ...Ja.omit(t.slots, [ "default", "footer" ])
            });
        };
    }
});

function Mi(e) {
    return Mi = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(e) {
        return typeof e;
    } : function(e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, Mi(e);
}

function Ri(e) {
    var a = function(e, a) {
        if ("object" !== Mi(e) || null === e) return e;
        var t = e[Symbol.toPrimitive];
        if (void 0 !== t) {
            var l = t.call(e, a || "default");
            if ("object" !== Mi(l)) return l;
            throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === a ? String : Number)(e);
    }(e, "string");
    return "symbol" === Mi(a) ? a : String(a);
}

function xi(e, a, t) {
    return (a = Ri(a)) in e ? Object.defineProperty(e, a, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
    }) : e[a] = t, e;
}

let Ai = "SELECT_PERSON_KEY";

class ki {
    constructor(e, a) {
        xi(this, "props", void 0), xi(this, "ctx", void 0), xi(this, "ssuTreeRef", i(null)), 
        xi(this, "isLoading", i(!1)), xi(this, "isGlobalSearch", i(!1)), xi(this, "searchValue", i("")), 
        xi(this, "treeDataList", i([])), xi(this, "selectedDepartmentList", i([])), xi(this, "selectingDepartmentList", i([])), 
        xi(this, "filterSelectedDepartmentList", i([])), this.props = e, this.ctx = a, this.filterSelectedDepartmentList = s((() => e.filterSelectedValue || [])), 
        this.setSearchValue = this.setSearchValue.bind(this), this.setTreeDataList = this.setTreeDataList.bind(this), 
        this.setSelectedDepartmentList = this.setSelectedDepartmentList.bind(this), this.setSelectingDepartmentList = this.setSelectingDepartmentList.bind(this), 
        this.setIsLoading = this.setIsLoading.bind(this), this.getElTreeRef = this.getElTreeRef.bind(this);
    }
    clear() {
        this.setIsLoading(!1), this.setIsGlobalSearch(!1), this.setSearchValue(""), this.setTreeDataList([]), 
        this.setSelectingDepartmentList([]), this.setSelectedDepartmentList([]), this.setFilterSelectedDepartmentList([]);
    }
    getElTreeRef() {
        var e;
        return null === (e = this.ssuTreeRef.value) || void 0 === e ? void 0 : e.getElTreeRef();
    }
    setTreeDataList(e) {
        this.treeDataList.value = Array.isArray(e) ? e : [];
    }
    setSelectedDepartmentList(e, a = !1) {
        this.selectedDepartmentList.value = Array.isArray(e) ? e : [], a && this.ctx.emit("update:selected-value", this.selectedDepartmentList.value);
    }
    setSelectingDepartmentList(e) {
        let a = this.selectingDepartmentList.value;
        a = Array.isArray(e) ? e : [], a = a.filter((e => !this.filterSelectedDepartmentList.value.includes(e.id))), 
        this.selectingDepartmentList.value = a;
    }
    setFilterSelectedDepartmentList(e) {
        this.filterSelectedDepartmentList.value = null != e ? e : [];
    }
    setSearchValue(e) {
        this.searchValue.value = String(e);
    }
    setIsGlobalSearch(e) {
        this.isGlobalSearch.value = !!e;
    }
    setIsLoading(e) {
        this.isLoading.value = !!e;
    }
}

const Pi = (e, a) => {
    const t = {
        store: new ki(e, a),
        rootInstance: n()
    };
    return k(Ai, t), t;
}, _i = () => r(Ai), Ui = e({
    name: "SelectedList",
    props: Ii,
    emits: Fi,
    setup(e, a) {
        const {store: t} = _i(), l = () => {
            t.setSelectedDepartmentList([]);
        };
        return () => {
            var e;
            return f("div", {
                class: "select-department-search-list"
            }, [ f("div", {
                class: "select-department-search-list-header"
            }, [ f("div", {
                class: "title"
            }, [ D("已选择"), f("span", {
                class: "num"
            }, [ D("("), null !== (e = t.selectedDepartmentList.value.length) && void 0 !== e ? e : 0, D(")") ]) ]), f("div", {
                class: "deleteGarbage",
                onClick: l
            }, [ f(V("syc-icon"), {
                type: "delete-garbage"
            }, null) ]) ]), f("div", {
                class: "select-department-search-list-body"
            }, [ t.selectedDepartmentList.value.map((e => f("div", {
                class: "item"
            }, [ f("div", {
                class: "text",
                title: e.name
            }, [ e.name || "已删除部门" ]), f("div", {
                class: "close",
                onClick: () => (e => {
                    t.setSelectedDepartmentList(t.selectedDepartmentList.value.filter((a => a.id !== e.id)));
                })(e)
            }, [ f(V("syc-icon"), {
                type: "close-simple"
            }, null) ]) ]))) ]) ]);
        };
    }
}), Bi = (e, a, t) => {
    const l = le(), o = e => {
        const a = t.isGlobalSearch.value ? {
            keyword: t.searchValue.value,
            pageNum: 1,
            pageSize: 100
        } : {
            deptId: e,
            pageNum: 1,
            pageSize: 1e4
        };
        return t.setIsLoading(!0), l.admin.getMemberList(a).then((e => {
            t.setSelectingDepartmentList(e.result);
        })).finally((() => t.setIsLoading(!1)));
    }, i = e => {
        13 === e.keyCode && (t.setIsGlobalSearch(!0), o());
    }, n = () => {
        window.addEventListener("keydown", i);
    }, r = () => {
        window.removeEventListener("keydown", i);
    };
    return w((() => {
        n();
    })), B((() => {
        r();
    })), P((() => e.filterSelectedValue), (() => {
        const a = t.selectingDepartmentList.value;
        t.setFilterSelectedDepartmentList(e.filterSelectedValue), a && t.setSelectingDepartmentList(a);
    })), {
        addSearchKeyDownListener: n,
        removeSearchKeyDownLister: r,
        search: o,
        inputSearchInput: e => {
            t.setSearchValue(e);
        },
        inputSearchClick: () => {
            t.setIsGlobalSearch(!0), o();
        }
    };
}, Wi = (e, a, t) => {
    const l = le();
    Bi(e, 0, t);
    const o = e => {
        const a = t.getElTreeRef();
        if (a && "blur" === e && a.setCurrentKey(null), a && "firstFocus" === e && Aa(t.treeDataList.value)) {
            const e = t.treeDataList.value[0];
            a.setCurrentKey(e.id);
        }
    }, i = () => {
        var a;
        const l = t.getElTreeRef();
        l && (l.setCheckedKeys(null === (a = e.selectedValue) || void 0 === a ? void 0 : a.map((e => e.id))), 
        b((() => {
            t.setSelectedDepartmentList(e.selectedValue), e.selectedValue.length > 0 ? l.setCurrentKey(e.selectedValue[0]) : o("firstFocus");
        })));
    }, n = () => {
        (t.setIsLoading(!0), l.admin.getDepartmentTree().then((e => {
            t.setTreeDataList(null != e ? e : []);
        })).finally((() => {
            t.setIsLoading(!1);
        }))).then((() => {
            t.treeDataList.value[0], i();
        }));
    };
    return P((() => t.isGlobalSearch.value), (() => {
        t.isGlobalSearch.value && o("blur");
    })), P((() => e.selectedValue), (() => {
        i();
    })), P((() => {
        var e;
        return null === (e = t.selectedDepartmentList.value) || void 0 === e ? void 0 : e.length;
    }), ((e, a) => {
        b((() => {
            if (e < a) {
                var l;
                const e = t.getElTreeRef();
                if (!e) return;
                e.setCheckedKeys(null === (l = t.selectedDepartmentList.value) || void 0 === l ? void 0 : l.map((e => e.id)));
            }
        }));
    })), w((() => {
        n();
    })), {
        treeProps: {
            label: "name"
        },
        treeNodeClick: a => {
            if (!a) return;
            const l = t.getElTreeRef();
            t.setIsGlobalSearch(!1);
            let o = [];
            if (e.multiple) {
                const e = t.selectedDepartmentList.value;
                o = !!e.find((e => e.id === a.id)) ? e.filter((e => e.id !== a.id)) : e.concat(a);
            } else o = Array.of(a), t.setSelectedDepartmentList(o);
            t.setSelectedDepartmentList(o), l.setCheckedKeys(o.map((e => e.id)));
        }
    };
}, qi = e({
    name: "Tree",
    props: {
        ...Ii,
        filter: {
            type: Boolean,
            default: !1
        }
    },
    emits: Fi,
    setup(e, a) {
        const {store: t} = _i(), {treeProps: l, treeNodeClick: o} = Wi(e, 0, t);
        t.selectedDepartmentList.value;
        const i = s((() => e.filter ? t.selectedDepartmentList.value.map((e => ({
            ...e,
            children: []
        }))) : t.treeDataList.value));
        return e => f(V("ssu-tree"), {
            ref: t.ssuTreeRef,
            props: l,
            class: "select-department-tree",
            type: null != e && e.multiple ? "checkbox" : "radio",
            data: i.value,
            "default-expand-all": !0,
            "node-key": "id",
            "check-strictly": !0,
            onNodeClick: o,
            onCheck: o
        }, null);
    }
}), ji = (e, a, t) => {
    const {addSearchKeyDownListener: l, removeSearchKeyDownLister: o} = Bi(e, 0, t), i = () => {
        a.emit("closed"), o(), t.clear();
    };
    return {
        closedDialog: i,
        openedDialog: () => {
            a.emit("opended"), l();
        },
        confirmDialog: () => {
            Ja.isEmpty(t.selectedDepartmentList.value) ? Ka({
                type: "warning",
                message: "没有选择对应的部门，如需关闭弹框，请直接点击关闭"
            }) : (a.emit("confirm", t.selectedDepartmentList.value), i());
        }
    };
}, $i = e({
    name: "SelectDepartmentDialog",
    props: Ii,
    emits: Fi,
    setup(e, a) {
        const {store: t} = Pi(e, a), l = s((() => "selectDepartmentDialog " + (e.multiple ? "selectDepartmentDialogMultiple" : ""))), {closedDialog: o, openedDialog: i, confirmDialog: n} = ji(e, a, t);
        return () => f(Ni, E(a.attrs, {
            title: "选择部门",
            "disabled-limit-height": !0,
            "destroy-on-close": !0,
            "custom-class": l.value,
            loading: t.isLoading.value,
            width: 600,
            onClosed: o,
            onOpened: i
        }), {
            default: () => [ f("div", {
                class: "selectDepartmentContainer"
            }, [ f("div", {
                class: "body"
            }, [ f(qi, E({
                class: "tree"
            }, e, Ja.omit(a.attrs, [ "class", "style" ])), null) ]), e.multiple && f("div", {
                class: "right"
            }, [ f(Ui, E({
                class: "selectedList"
            }, e, Ja.omit(a.attrs, [ "class", "style" ])), null) ]) ]) ],
            footer: () => f("div", {
                class: "footer"
            }, [ f(V("ssu-button"), {
                onClick: o
            }, {
                default: () => [ D("取消") ]
            }), f(V("ssu-button"), {
                type: "primary",
                onClick: n
            }, {
                default: () => [ D("确认") ]
            }) ])
        });
    }
}), {formComponentMixin: zi} = z;

var Gi = e({
    name: "OlDepartment",
    components: {
        SelectDepartmentDialog: $i,
        InputSelect: Vo
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: "single"
        }
    },
    mixins: [ zi ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = le(), o = Na(e), n = ne("department"), r = s((() => ({
            ...e,
            ...a
        }))), u = i(!1), d = i([]), p = s((() => n.value.toList())), m = i([]), c = s((() => e.runtimeEnv === Q.DESIGNER)), v = i(!1), f = i([]), g = i(1), b = i(20), h = i(""), y = i(!1), D = s({
            get: () => ((o.value || e.metaData.defaultValue)?.filter(Boolean) ?? []).map((e => {
                const a = p.value.find((a => a.id === e));
                return a || d.value.includes(e) || d.value.push(e), {
                    ...a,
                    value: a?.id || e,
                    label: a?.name || "已删除部门",
                    name: a?.name || "已删除部门"
                };
            })),
            set: e => {
                Ja.isEmpty(e) ? t("update:modelValue", null) : Array.isArray(e) ? t("update:modelValue", e.map((e => e.id))) : t("update:modelValue", [ e.id ]);
            }
        }), C = s((() => "multiple" === e.mode)), w = (e = []) => {
            m.value = m.value.filter((a => !e.find((e => e.id === a.id))));
        };
        P((() => e.metaData.defaultValue), (e => {
            o.value && (D.value = e);
        })), P((() => d.value), Ja.debounce((e => {
            if (!e?.length) return;
            const a = e.filter((e => e && !Ja.isEmpty(e)));
            a.length && l.admin.getBatchDept({
                deptIds: a
            }).then((e => {
                (e.deptInfo || []).forEach((e => {
                    n.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), d.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        });
        const S = (e = []) => {
            e.forEach((e => {
                n.value.addCache({
                    cacheKey: e.id,
                    ...e
                });
            }));
        }, L = () => l.admin.getDeptList({
            pageNum: g.value,
            keyword: h.value,
            pageSize: b.value
        }), T = async e => {
            h.value = e, y.value = !0, f.value = [], g.value = 1;
            const a = await L();
            v.value = a.more, w(a.result ?? []), f.value = a.result, S(a.result ?? []), y.value = !1;
        };
        return {
            dataValue: D,
            isMultiple: C,
            bindData: r,
            departmentSelectDialogVisible: u,
            handleOpenDialog: () => {
                u.value = !0;
            },
            handleClosed: () => {
                u.value = !1;
            },
            handleConfirm: e => {
                const a = Array.isArray(e) ? e : [ e ];
                m.value = [ ...a ], D.value = a.map((e => (n.value.addCache({
                    cacheKey: e.id,
                    ...e
                }), {
                    ...e,
                    value: e.id,
                    label: e.name
                })));
            },
            filterMethod: T,
            loading: y,
            handleLoadMore: async e => {
                if (y.value || !v.value) return;
                y.value = !0, g.value++;
                const a = await L();
                v.value = a.more, w(a.result ?? []), f.value = f.value.concat(a.result ?? []), S(a.result), 
                y.value = !1;
            },
            optionData: f,
            visibleChange: e => {
                e && T("");
            },
            isDesigner: c
        };
    }
});

const Hi = {
    class: "ol-department"
}, Qi = {
    class: "ol-department-input"
};

Gi.render = function(e, a, o, i, n, r) {
    const s = V("input-select"), u = V("SelectDepartmentDialog");
    return t(), l("div", Hi, [ y("div", Qi, [ f(s, E(e.bindData, {
        modelValue: e.dataValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.dataValue = a),
        multiple: e.isMultiple,
        "handle-select": e.handleOpenDialog
    }), null, 16, [ "modelValue", "multiple", "handle-select" ]) ]), e.departmentSelectDialogVisible ? (t(), 
    L(u, {
        key: 0,
        modelValue: e.departmentSelectDialogVisible,
        "onUpdate:modelValue": a[1] || (a[1] = a => e.departmentSelectDialogVisible = a),
        selectedValue: e.dataValue,
        multiple: e.isMultiple,
        onClosed: e.handleClosed,
        onConfirm: e.handleConfirm
    }, null, 8, [ "modelValue", "selectedValue", "multiple", "onClosed", "onConfirm" ])) : F("", !0) ]);
};

const Yi = e({
    name: "PopupHeaderMobile",
    props: {
        cancelText: {
            type: String,
            default: "取消"
        },
        title: {
            type: String,
            default: "标题"
        },
        confirmText: {
            type: String,
            default: "确认"
        },
        onConfirm: {
            type: Function,
            default: () => {}
        },
        onCancel: {
            type: Function,
            default: () => {}
        }
    },
    setup: (e, {slots: a}) => () => f("div", {
        class: "popup-header-mobile"
    }, [ f("span", {
        class: "popup-header-mobile-side left",
        onClick: e.onCancel
    }, [ e.cancelText ]), f("div", {
        class: "popup-header-mobile-middle"
    }, [ e.title || "" ]), f("span", {
        class: "popup-header-mobile-side right",
        onClick: e.onConfirm
    }, [ e.confirmText ]) ])
}), Ki = e({
    name: "SelectDepartmentMobile",
    props: Ii,
    emits: Fi,
    setup(e, a) {
        const {store: t} = Pi(e, a), {closedDialog: l, openedDialog: o, confirmDialog: n} = ji(e, a, t), r = s((() => e.selectMobileVisible)), u = i("all"), d = i(!1), p = e => {
            d.value = "checked" === e;
        };
        return P((() => r.value), (() => {
            u.value = "all", d.value = !1;
        })), () => f("div", {
            class: "select-department-mobile-container"
        }, [ f(lt, {
            show: r.value,
            "onUpdate:show": e => r.value = e,
            "close-on-click-action": !0
        }, {
            default: () => [ r.value && f("div", null, [ f(Yi, {
                title: "请选择部门",
                onCancel: l,
                onConfirm: n
            }, null), f(ot, {
                active: u.value,
                "onUpdate:active": e => u.value = e,
                onChange: p,
                "title-active-color": "#167DFA",
                color: "white"
            }, {
                default: () => [ f(it, {
                    title: "可选",
                    name: "all"
                }, null), f(it, {
                    title: "已选",
                    name: "checked"
                }, null) ]
            }), f("div", {
                class: [ "body", d.value ? "un-icon-tree" : "" ]
            }, [ f(qi, E({
                class: "tree"
            }, e, Ja.omit(a.attrs, [ "class", "style" ]), {
                filter: d.value
            }), null) ]) ]) ]
        }) ]);
    }
}), {formComponentMixin: Xi} = z;

var Ji = e({
    name: "OlDepartmentMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        mode: {
            type: String,
            default: "single"
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "update:modelValue" ],
    mixins: [ Xi ],
    setup(e, {emit: a}) {
        const t = le(), l = Na(e), o = ne("department"), n = i([]), r = s((() => "multiple" === e.mode)), u = i(!1), d = () => {
            e.disabled || (u.value = !0);
        }, p = () => {
            u.value = !1;
        }, m = e => {
            const a = Array.isArray(e) ? e : [ e ];
            v.value = a.map((e => (o.value.addCache({
                cacheKey: e.id,
                ...e
            }), {
                ...e,
                value: e.id,
                label: e.name
            })));
        }, c = s((() => o.value.toList())), v = s({
            get: () => {
                let a = l.value || e.metaData.defaultValue;
                return null == a ? void 0 : a.map((e => {
                    const a = c.value.find((a => a.id === e));
                    return a || n.value.includes(e) || n.value.push(e), {
                        ...a,
                        value: (null == a ? void 0 : a.id) || e,
                        label: (null == a ? void 0 : a.name) || "已删除部门",
                        name: (null == a ? void 0 : a.name) || "已删除部门"
                    };
                }));
            },
            set: e => {
                a("update:modelValue", e.map((e => e.id)));
            }
        });
        P((() => n.value), Ja.debounce((e => {
            if (null == e || !e.length) return;
            const a = e.filter((e => e && !Ja.isEmpty(e)));
            a.length && t.admin.getBatchDept({
                deptIds: a
            }).then((e => {
                (e.deptInfo || []).forEach((e => {
                    o.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), n.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        });
        const g = s((() => {
            var e;
            let a = null === (e = v.value) || void 0 === e ? void 0 : e.length;
            var t, l, o, i;
            return 1 === a ? null === (t = v.value) || void 0 === t || null === (l = t[0]) || void 0 === l ? void 0 : l.name : a > 1 ? `已选择${null === (o = v.value) || void 0 === o || null === (i = o[0]) || void 0 === i ? void 0 : i.name}等${a}项` : "";
        }));
        return i(!1), () => f("div", {
            class: "ol-department-mobile"
        }, [ f("div", {
            class: "departent-select",
            onClick: d
        }, [ f(V("ssu-select"), {
            modelValue: g.value,
            disabled: null == e ? void 0 : e.disabled
        }, null), f("div", {
            class: "select-hot-zone"
        }, null) ]), f(Ki, {
            selectedValue: v.value,
            multiple: r.value,
            selectMobileVisible: u.value,
            onClosed: p,
            onConfirm: m
        }, null) ]);
    }
});

const {propsFormMixin: Zi} = z;

var en = e({
    name: "OlDepartmentPropsForm",
    mixins: [ Zi ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-department-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("mode-setter"), {
                metaData: e.metaData,
                title: "可选数量",
                optionList: pe.options
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: an, metaDataMixin: tn} = z;

var ln = e({
    name: "OlDepartmentTableFormatter",
    mixins: [ an, tn ],
    setup(e) {
        const a = le(), t = ne("department"), l = i([]), o = s((() => t.value.toList())), n = s((() => {
            const a = Ra(e.fieldValue);
            return a.length ? a.map((e => {
                const a = o.value.find((a => a.id === e));
                return l.value.includes(e) || a || l.value.push(e), null == a ? void 0 : a.name;
            })).join(ie) : Y;
        }));
        return P((() => o.value), (() => {
            const a = Ra(e.fieldValue);
            a.length && (l.value = Ja.uniq(l.value.concat(Ja.cloneDeep(a))));
        }), {
            immediate: !0
        }), P((() => l.value), Ja.debounce((e => {
            if (null == e || !e.length) return;
            let i = [];
            const n = o.value.map((({id: e}) => e));
            null == e || e.forEach((e => {
                if (!n.includes(e)) {
                    i.push(e);
                    const a = {
                        id: e
                    };
                    t.value.addCache({
                        cacheKey: e,
                        ...a
                    });
                }
            })), i.length && a.admin.getBatchDept({
                deptIds: i
            }).then((e => {
                (e.deptInfo || []).forEach((e => {
                    t.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), l.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        }), () => f("label", {
            class: "ol-department-table-formatter"
        }, [ n.value ]);
    }
}), on = {
    code: K.DEPARTMENT,
    name: "部门",
    group: X.HIGH_LEVEL,
    icon: "bumen",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlDepartment",
    mobileWidget: "OlDepartmentMobile",
    propsForm: "OlDepartmentPropsForm",
    tableFormatter: "OlDepartmentTableFormatter",
    props: {
        placeholder: "请选择",
        mode: "single"
    },
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.DEPARTMENT ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Gi.meta = on, Gi.PropsForm = en, Gi.Mobile = Ji, Gi.install = e => {
    e.component(Gi.name, Gi), e.component(en.name, en), e.component(Ji.name, Ji), e.component(ln.name, ln);
}, Gi.widgetMap = {
    [Ji.name]: Ji,
    [Gi.name]: Gi,
    [en.name]: en,
    [ln.name]: ln
};

const {metaDataMixin: nn} = z;

var rn = e({
    name: "OlGrid",
    props: {
        layoutType: {
            type: String
        },
        cols: {
            type: Array,
            default: () => [ 6, 6, 6, 6 ]
        }
    },
    mixins: [ nn ]
});

rn.render = function(e, a, l, o, i, n) {
    const r = V("el-row");
    return t(), L(r, {
        class: A([ "ol-grid", {
            "in-container": e.inContainer
        } ]),
        gutter: e.isMobile ? 0 : 32
    }, {
        default: v((() => [ W(e.$slots, "default") ])),
        _: 3
    }, 8, [ "class", "gutter" ]);
};

var sn = e({
    name: "OlGridChild",
    props: {
        parentMetaData: {
            type: Object,
            default: () => ({})
        },
        parentProps: {
            type: Object,
            default: () => ({})
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        index: {
            type: Number
        }
    },
    setup(e) {
        const a = s((() => e.parentProps.cols));
        return {
            span: s((() => e.isMobile ? 24 : a.value[e.index]))
        };
    }
});

sn.render = function(e, a, l, o, i, n) {
    const r = V("el-col");
    return t(), L(r, {
        class: "ol-grid-child",
        span: e.span
    }, {
        default: v((() => [ W(e.$slots, "default") ])),
        _: 3
    }, 8, [ "span" ]);
};

var un = e({
    name: "LayoutTypeInput",
    props: {
        modelValue: {
            type: String
        }
    },
    setup(e) {
        const a = Na(e), t = u([ {
            key: "24",
            icon: "ershisi"
        }, {
            key: "12:12",
            icon: "shierbishier"
        }, {
            key: "8:8:8",
            icon: "babibabiba"
        }, {
            key: "6:6:6:6",
            icon: "liubiliubiliubiliu"
        }, {
            key: "6:18",
            icon: "liubishiba"
        }, {
            key: "18:6",
            icon: "shibabiliu"
        }, {
            key: "16:8",
            icon: "shiliubiba"
        }, {
            key: "8:16",
            icon: "babishiliu"
        }, {
            key: "6:12:6",
            icon: "liubishierbiliu"
        } ]);
        return {
            data: a,
            layoutTypes: t,
            selectLayoutType: e => {
                a.value = e.key;
            }
        };
    }
});

const dn = {
    class: "layout-type-list"
}, pn = [ "onClick" ];

un.render = function(e, a, o, i, n, r) {
    const s = V("syc-icon");
    return t(), l("div", dn, [ (t(!0), l(R, null, x(e.layoutTypes, (a => (t(), l("div", {
        class: A([ "layout-type-item", {
            active: a.key === e.data
        } ]),
        key: a.key,
        onClick: C((t => e.selectLayoutType(a)), [ "stop" ])
    }, [ f(s, {
        type: `designer/${a.icon}`,
        width: 67,
        height: 36
    }, null, 8, [ "type" ]) ], 10, pn)))), 128)) ]);
};

const {propsFormMixin: mn} = z;

var cn = e({
    name: "OlGridPropsForm",
    mixins: [ mn ],
    components: {
        LayoutTypeInput: un
    },
    setup(e) {
        const a = Da(e.metaData, "layoutType"), t = Da(e.metaData, "cols");
        return P((() => a.value), (a => {
            const l = a.split(":").filter(Boolean).map((e => Ja.toNumber(e)));
            t.value = l, (a => {
                const t = e.metaData.children, l = a.length - t.length;
                0 !== l && (l > 0 ? Ja.times(l, (() => {
                    t.push([]);
                })) : l < 0 && Ja.times(Math.abs(l), (() => {
                    t.pop();
                })));
            })(l);
        })), {
            layoutType: a
        };
    }
});

cn.render = function(e, a, l, o, i, n) {
    const r = V("LayoutTypeInput"), s = V("CustomFormItem"), u = V("el-form");
    return t(), L(u, {
        class: "ol-grid-props-form",
        "label-position": "top",
        ref: "form"
    }, {
        default: v((() => [ f(s, {
            label: "布局",
            setter: ""
        }, {
            default: v((() => [ f(r, {
                modelValue: e.layoutType,
                "onUpdate:modelValue": a[0] || (a[0] = a => e.layoutType = a)
            }, null, 8, [ "modelValue" ]) ])),
            _: 1
        }) ])),
        _: 1
    }, 512);
};

var vn = {
    code: "Grid",
    name: "布局容器",
    icon: "bujurongqi",
    group: X.HIGH_LEVEL,
    widgetType: J.CONTAINER,
    widget: "OlGrid",
    child: "OlGridChild",
    mobileWidget: "OlGridMobile",
    propsForm: "OlGridPropsForm",
    children: [ [], [], [], [] ],
    props: {
        layoutType: "6:6:6:6",
        cols: [ 6, 6, 6, 6 ]
    }
};

rn.install = e => {
    e.component(rn.name, rn), e.component(sn.name, sn), e.component(cn.name, cn);
}, rn.meta = vn, rn.PropsForm = cn, rn.Child = sn, rn.widgetMap = {
    [sn.name]: sn,
    [rn.name]: rn,
    [cn.name]: cn
};

const {metaDataMixin: fn} = z;

var gn = e({
    name: "OlGroup",
    props: {
        title: {
            type: String
        },
        canCollapse: String,
        defaultCollapse: String
    },
    mixins: [ fn ],
    setup(e) {
        const a = i(!1), t = s((() => ({
            collapsed: a.value
        })));
        return P((() => [ e.canCollapse, e.defaultCollapse ]), (e => {
            const [t, l] = e;
            a.value = "N" !== t && "N" !== l;
        }), {
            immediate: !0
        }), {
            clazz: t,
            collapsed: a
        };
    }
});

gn.render = function(e, a, o, i, n, r) {
    const s = V("Pane");
    return t(), l("div", {
        class: A([ "ol-group", e.clazz ])
    }, [ f(s, {
        title: e.title,
        modelValue: e.collapsed,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.collapsed = a),
        "show-collapsed": "Y" === e.canCollapse
    }, {
        default: v((() => [ W(e.$slots, "default") ])),
        _: 3
    }, 8, [ "title", "modelValue", "show-collapsed" ]) ], 2);
};

var bn = e({
    name: "OlGroupChild",
    props: {
        parentMetaData: {
            type: Object,
            default: () => ({})
        },
        parentProps: {
            type: Object,
            default: () => ({})
        },
        index: {
            type: Number
        }
    },
    setup() {}
});

const hn = {
    class: "ol-group-child"
};

bn.render = function(e, a, o, i, n, r) {
    return t(), l("div", hn, [ W(e.$slots, "default") ]);
};

const {propsFormMixin: yn} = z;

var Dn = e({
    name: "OlGroupPropsForm",
    mixins: [ yn ],
    setup(e) {
        const a = e.metaData.props.title?.rawValue, t = u([ {
            label: "可以折叠",
            value: "Y"
        }, {
            label: "不可折叠",
            value: "N"
        } ]), l = u([ {
            label: "默认折叠",
            value: "Y"
        }, {
            label: "默认展开",
            value: "N"
        } ]);
        P((() => e.metaData.props.canCollapse.rawValue), (() => {
            e.metaData.props.defaultCollapse.rawValue = "N";
        })), P((() => e.metaData.props.title.rawValue), (a => {
            e.metaData.name.rawValue = a;
        }));
        return {
            options: t,
            defaultCollapseOptions: l,
            handleTitleInputBlur: t => {
                t && t.trim() || (e.metaData.props.title.rawValue = a);
            }
        };
    }
});

const Cn = {
    key: 0,
    class: "default-collapse-setting"
}, wn = y("span", null, "默认展示方式", -1);

Dn.render = function(e, a, o, i, n, r) {
    const s = V("I18NInput"), u = V("CustomFormItem"), d = V("el-radio"), p = V("el-radio-group"), m = V("ssu-select"), c = V("el-form");
    return t(), L(c, {
        class: "ol-group-props-form",
        "label-position": "top",
        ref: "form"
    }, {
        default: v((() => [ f(u, {
            label: "标题",
            setter: ""
        }, {
            default: v((() => [ f(s, {
                modelValue: e.metaData.props.title,
                "onUpdate:modelValue": a[0] || (a[0] = a => e.metaData.props.title = a),
                maxlength: 50,
                onBlur: e.handleTitleInputBlur
            }, null, 8, [ "modelValue", "onBlur" ]) ])),
            _: 1
        }), f(u, {
            label: "设置",
            setter: ""
        }, {
            default: v((() => [ f(p, {
                modelValue: e.metaData.props.canCollapse.rawValue,
                "onUpdate:modelValue": a[1] || (a[1] = a => e.metaData.props.canCollapse.rawValue = a)
            }, {
                default: v((() => [ (t(!0), l(R, null, x(e.options, (e => (t(), L(d, {
                    key: e.value,
                    label: e.value
                }, {
                    default: v((() => [ D(O(e.label), 1) ])),
                    _: 2
                }, 1032, [ "label" ])))), 128)) ])),
                _: 1
            }, 8, [ "modelValue" ]), "Y" === e.metaData.props.canCollapse.rawValue ? (t(), l("div", Cn, [ wn, f(m, {
                "option-data": e.defaultCollapseOptions,
                modelValue: e.metaData.props.defaultCollapse.rawValue,
                "onUpdate:modelValue": a[2] || (a[2] = a => e.metaData.props.defaultCollapse.rawValue = a)
            }, null, 8, [ "option-data", "modelValue" ]) ])) : F("", !0) ])),
            _: 1
        }) ])),
        _: 1
    }, 512);
};

var Sn = {
    code: "Group",
    name: "分组",
    icon: "fenzu",
    group: X.HIGH_LEVEL,
    widgetType: J.CONTAINER,
    widget: "OlGroup",
    child: "OlGroupChild",
    propsForm: "OlGroupPropsForm",
    children: [ [] ],
    props: {
        title: "分组",
        canCollapse: "Y",
        defaultCollapse: "N"
    }
};

gn.install = e => {
    e.component(gn.name, gn), e.component(bn.name, bn), e.component(Dn.name, Dn);
}, gn.meta = Sn, gn.PropsForm = Dn, gn.Child = bn, gn.widgetMap = {
    [bn.name]: bn,
    [gn.name]: gn,
    [Dn.name]: Dn
};

const {formComponentMixin: Ln} = z;

var Tn = e({
    name: "OlImage",
    components: {
        Attachment: $l
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        mode: {
            type: String,
            default: "single"
        },
        accept: {
            type: String,
            default: ""
        },
        acceptType: {
            type: String
        },
        bulk: {
            type: [ String, Number ]
        },
        limit: {
            type: [ String, Number ]
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        label: {
            type: String,
            default: ""
        },
        pageCode: {
            type: String,
            default: ""
        }
    },
    mixins: [ Ln ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = s({
            get: () => e.modelValue?.map?.((e => ({
                name: e?.fileName ?? "",
                size: 0,
                url: e?.url ?? ""
            }))) ?? [],
            set(a) {
                const l = a?.map?.((a => ({
                    name: e?.label ?? "",
                    fileName: a?.name ?? "",
                    url: a.url ?? ""
                })));
                t("update:modelValue", l);
            }
        });
        return {
            imgAccept: s((() => "free" !== e.acceptType && e.accept ? e.accept.split(",").map((e => "." === e[0] ? e : `.${e}`)).join(",") : "image/*")),
            maxSizeLimit: 50,
            innerModelValue: l
        };
    }
});

const Vn = {
    class: "ol-image ol-image-mobile"
};

Tn.render = function(e, a, o, i, n, r) {
    const s = V("attachment");
    return t(), l("div", Vn, [ f(s, E({
        ...e.$attrs,
        ...e.$props
    }, {
        modelValue: e.innerModelValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.innerModelValue = a),
        basicFileInteraction: "card",
        disabled: e.disabled,
        isShowSize: !1,
        bulk: e.bulk ?? e.maxSizeLimit,
        accept: e.imgAccept,
        acceptType: "limit",
        fileTypeName: "图片"
    }), null, 16, [ "modelValue", "disabled", "bulk", "accept" ]) ]);
};

const {formComponentMixin: En} = z;

var In = e({
    name: "OlImageMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ En ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: Fn} = z;

var On = e({
    name: "OlImagePropsForm",
    mixins: [ Fn ],
    setup(e, {expose: a}) {
        var t, l, n;
        null !== (t = e.pageData) && void 0 !== t && t.code && null !== (l = e.metaData.props) && void 0 !== l && l.pageCode && (e.metaData.props.pageCode.rawValue = null === (n = e.pageData) || void 0 === n ? void 0 : n.code);
        const r = s((() => e.metaData.validateRuleConfig)), {data: u, formRef: d} = fa(e, a), {convertOptionListToObj: p, convertObjToOptionList: m} = H, c = s((() => i(m({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return P((() => e.metaData.props.mode.rawValue), (a => {
            e.metaData.props.limit.rawValue = "single" === a ? 1 : void 0;
        })), () => f(V("el-form"), {
            ref: d,
            model: u,
            "label-position": "top",
            class: "ol-image-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("mode-setter"), {
                metaData: e.metaData,
                title: "图片数量",
                optionList: me.options
            }, {
                default: () => [ o(f("div", {
                    class: "info-input-tip"
                }, [ f("span", null, [ D("最多可上传") ]), f(V("ssu-input-text"), {
                    style: {
                        width: "84px",
                        margin: "0 10px"
                    },
                    modelValue: e.metaData.props.limit.rawValue,
                    "onUpdate:modelValue": a => {
                        +a > 20 || (a = Number(a.replace(/^(0+)|[^\d]+/g, "")), e.metaData.props.limit.rawValue = !isNaN(a) && a ? a : void 0);
                    }
                }, null), f("span", null, [ D("个图片") ]) ]), [ [ g, "multiple" === e.metaData.props.mode.rawValue ] ]) ]
            }), f(V("mode-setter"), {
                metaData: e.metaData,
                title: "图片格式",
                modeKey: "acceptType",
                optionList: ce.options
            }, {
                default: () => [ o(f("div", null, [ f(V("ssu-input-text"), {
                    modelValue: e.metaData.props.accept.rawValue,
                    "onUpdate:modelValue": a => {
                        e.metaData.props.accept.rawValue = a;
                    }
                }, null), f("div", {
                    class: "info-tip"
                }, [ D("1.请输入自定义的图片格式扩展名，多个请用逗号隔开，如:jpg,jpeg,png,gif,bmp,psd,tif,jfif等。2.设置完毕后，填表者仅能上传你指定的图片格式。") ]) ]), [ [ g, "limit" === e.metaData.props.acceptType.rawValue ] ]) ]
            }), f(V("CustomFormItem"), {
                label: "图片大小",
                setter: !0
            }, {
                default: () => [ f("div", {
                    class: "info-input-tip"
                }, [ f("span", null, [ D("最大可上传") ]), f(V("ssu-input-text"), {
                    style: {
                        width: "84px",
                        margin: "0 10px"
                    },
                    modelValue: e.metaData.props.bulk.rawValue,
                    "onUpdate:modelValue": a => {
                        +a > 50 || (a = Number(a.replace(/^(0+)|[^\d]+/g, "")), e.metaData.props.bulk.rawValue = !isNaN(a) && a ? a : void 0);
                    }
                }, null), f("span", null, [ D("MB图片") ]) ]) ]
            }), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: r.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: c.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = p(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Nn, metaDataMixin: Mn} = z;

var Rn = e({
    name: "OlImageTableFormatter",
    mixins: [ Nn, Mn ],
    props: {
        label: {
            type: String,
            default: ""
        },
        inSubForm: {
            type: Boolean,
            default: !1
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        mode: {
            type: String,
            default: "single"
        },
        accept: {
            type: String,
            default: ""
        },
        acceptType: {
            type: String
        },
        bulk: {
            type: [ String, Number ]
        },
        limit: {
            type: [ String, Number ]
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        pageCode: {
            type: String,
            default: ""
        }
    },
    setup(e, {attrs: a}) {
        const t = s((() => e.label)), l = s((() => {
            var e;
            return null === (e = a.formRules) || void 0 === e ? void 0 : e.find((e => "required" === e.key || e.required));
        })), i = s((() => null == e ? void 0 : e.runtimeEnv)), n = s((() => {
            let a = r(e.fieldValue) ? JSON.parse(e.fieldValue) : e.fieldValue, t = Ra(a).map((e => r(e) ? JSON.parse(e) : e)).filter((e => e.url));
            return "table" === i.value && (t = t.slice(0, 3)), t;
        })), r = e => {
            if ("string" == typeof e) try {
                let a = JSON.parse(e);
                return Boolean(a && "object" == typeof a);
            } catch (e) {
                return !1;
            }
        };
        return () => {
            return e.inSubForm ? (() => {
                var t;
                const l = {
                    ...a,
                    ...e
                };
                return null !== (t = n.value) && void 0 !== t && t.length ? f(Tn, E(l, {
                    modelValue: e.fieldValue,
                    class: "ol-image-table-formatter",
                    disabled: !0,
                    isAllowAdd: !1
                }), null) : f("div", {
                    class: "ol-image-table-formatter"
                }, [ Y ]);
            })() : f("label", {
                class: "ol-image-table-formatter"
            }, [ o(f("div", null, [ f("label", {
                class: [ "el-form-item__label", l.value && "required-label" ]
            }, [ f("span", null, [ t.value ]) ]) ]), [ [ g, "table" !== i.value ] ]), n.value.map(((e, a) => f("img", {
                class: "table" === i.value ? "img-table-show" : "img-detail-show",
                src: e.url,
                onClick: e => {
                    e.stopPropagation(), ql({
                        currentIndex: a,
                        previewArr: n.value
                    });
                }
            }, null))), "table" === i.value && (null === (r = e.fieldValue) || void 0 === r ? void 0 : r.length) > 3 && f(V("ssu-icon"), {
                class: "icon-more",
                "icon-name": "icongengduo-copy"
            }, null), 0 === n.value.length && Y ]);
            var r;
        };
    }
}), xn = {
    code: K.IMAGE,
    name: "图片",
    group: X.NORMAL,
    icon: "tupian",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlImage",
    propsForm: "OlImagePropsForm",
    tableFormatter: "OlImageTableFormatter",
    props: {
        mode: "single",
        limit: 1,
        acceptType: "free",
        accept: "",
        bulk: void 0,
        pageCode: ""
    },
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    renderConfig: {
        showLabel: !1
    },
    conditionConfig: {
        defaultOperator: ee.IS_NOT_NULL,
        supportWidgetCodeConfig: [],
        supportOperator: {
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL],
            [ee.IS_NULL]: ae[ee.IS_NULL]
        }
    }
};

Tn.meta = xn, Tn.PropsForm = On, Tn.Mobile = In, Tn.install = e => {
    e.component(Tn.name, Tn), e.component(On.name, On), e.component(In.name, In), e.component(Rn.name, Rn);
}, Tn.widgetMap = {
    [In.name]: In,
    [Tn.name]: Tn,
    [On.name]: On,
    [Rn.name]: Rn
};

const An = {
    multiple: {
        type: Boolean,
        default: !1
    },
    selectedValue: {
        type: Array,
        default: () => []
    },
    filterSelectedValue: {
        type: Array,
        default: () => []
    },
    deptFilterParams: {
        type: Array,
        default: () => []
    },
    version: {
        type: String,
        default: "v1"
    },
    defaultDeptTreeData: {
        type: Array,
        default: () => []
    },
    selectPersionList: {
        type: Array,
        default: () => []
    }
}, kn = {
    "update:selected-value": e => Array.isArray(e),
    closed: null,
    opended: null,
    confirm: e => Array.isArray(e)
};

var Pn = "SearchInput-module_content__u3QMn";

let _n = "SELECT_PERSON_KEY";

class Un {
    constructor(e, a) {
        xi(this, "props", void 0), xi(this, "ctx", void 0), xi(this, "ssuTreeRef", i(null)), 
        xi(this, "isLoading", i(!1)), xi(this, "isGlobalSearch", i(!1)), xi(this, "searchValue", i("")), 
        xi(this, "treeDataList", i([])), xi(this, "selectedPersonList", i([])), xi(this, "selectingPersonList", i([])), 
        xi(this, "selectingPersonListCopy", []), xi(this, "filterSelectedPersonList", i([])), 
        xi(this, "deptList", new Set), xi(this, "userList", new Set), xi(this, "optionalUsersId", new Set), 
        xi(this, "deptMap", {}), this.props = e, this.ctx = a, e.deptFilterParams.forEach((e => {
            switch (e.type) {
              case "personal":
                e.id && this.userList.add(e.id);
                break;

              case "dept":
                e.id && this.deptList.add(e.id);
            }
        })), this.filterSelectedPersonList = s((() => e.filterSelectedValue || [])), this.setSearchValue = this.setSearchValue.bind(this), 
        this.setTreeDataList = this.setTreeDataList.bind(this), this.setSelectedPersonList = this.setSelectedPersonList.bind(this), 
        this.setSelectingPersonList = this.setSelectingPersonList.bind(this), this.setIsLoading = this.setIsLoading.bind(this), 
        this.getElTreeRef = this.getElTreeRef.bind(this);
    }
    clear() {
        this.setIsLoading(!1), this.setIsGlobalSearch(!1), this.setSearchValue(""), this.setTreeDataList([]), 
        this.setSelectingPersonList([]), this.setSelectedPersonList([]), this.setFilterSelectedPersonList([]);
    }
    getElTreeRef() {
        var e;
        return null === (e = this.ssuTreeRef.value) || void 0 === e ? void 0 : e.getElTreeRef();
    }
    setTreeDataList(e) {
        this.treeDataList.value = Array.isArray(e) ? e : [];
    }
    setSelectedPersonList(e, a = !1) {
        this.selectedPersonList.value = Array.isArray(e) ? e : [], a && this.ctx.emit("update:selected-value", this.selectedPersonList.value);
    }
    setSelectingPersonListCopy(e) {
        this.selectingPersonListCopy = Array.isArray(e) ? [ ...e ] : [];
    }
    setSelectingPersonList(e) {
        let a = this.selectingPersonList.value;
        a = Array.isArray(e) ? e : [], a = a.filter((e => !this.filterSelectedPersonList.value.includes(e.id))), 
        this.selectingPersonList.value = a;
    }
    setFilterSelectedPersonList(e) {
        this.filterSelectedPersonList.value = null != e ? e : [];
    }
    setSearchValue(e) {
        this.searchValue.value = String(e);
    }
    setIsGlobalSearch(e) {
        this.isGlobalSearch.value = !!e;
    }
    setIsLoading(e) {
        this.isLoading.value = !!e;
    }
}

const Bn = () => r(_n), Wn = (e, a, t) => {
    const l = le(), o = e => (t.setSelectingPersonList(e), t.setSelectingPersonListCopy(e), 
    Promise.resolve(e)), i = () => {
        const e = t.searchValue.value;
        if (!e) return void t.setSelectingPersonList([ ...t.selectingPersonListCopy ]);
        const a = t.selectingPersonListCopy.filter((a => {
            var t;
            return null === (t = a.name) || void 0 === t ? void 0 : t.includes(e);
        }));
        t.setSelectingPersonList(a);
    };
    return P((() => e.filterSelectedValue), (() => {
        const a = t.selectingPersonList.value;
        t.setFilterSelectedPersonList(e.filterSelectedValue), a && t.setSelectingPersonList(a);
    })), {
        search: a => {
            if (Ua(e.selectPersionList)) return new Promise((a => {
                t.setSelectingPersonList(e.selectPersionList), t.setSelectingPersonListCopy(e.selectPersionList), 
                a();
            }));
            if ("v2" === e.version) {
                var i, n;
                let l = null !== (i = null === (n = t.deptMap[a]) || void 0 === n ? void 0 : n.member) && void 0 !== i ? i : [];
                if (!e.deptFilterParams.length) return o(l);
                const r = l.filter((e => t.optionalUsersId.has(e.id)));
                return o(r);
            }
            const r = t.isGlobalSearch.value ? {
                keyword: t.searchValue.value,
                pageNum: 1,
                pageSize: 100
            } : {
                deptId: a,
                pageNum: 1,
                pageSize: 1e4
            };
            return t.setIsLoading(!0), l.admin.getMemberList(r).then((e => {
                t.setSelectingPersonList(e.result), t.setSelectingPersonListCopy(e.result);
            })).finally((() => t.setIsLoading(!1)));
        },
        inputSearchInput: e => {
            t.setSearchValue(e);
        },
        inputSearchClick: () => {
            i();
        },
        listenerCallback: e => {
            13 === e.keyCode && i();
        }
    };
}, qn = e({
    name: "SearchInput",
    props: An,
    emits: kn,
    setup(e, a) {
        const {store: t} = Bn(), {inputSearchInput: l, inputSearchClick: o, listenerCallback: i} = Wn(e, 0, t);
        return () => f("div", {
            class: Pn
        }, [ f(V("ssu-input-text"), {
            placeholder: "请输入搜索内容",
            modelValue: t.searchValue.value,
            search: !0,
            onInput: l,
            onSearch: o,
            onKeyup: i
        }, null) ]);
    }
});

var jn = "SelectedList-module_container__oIHnl", $n = "SelectedList-module_header__9JaSA", zn = "SelectedList-module_title__h0rQg", Gn = "SelectedList-module_num__-Wj6X", Hn = "SelectedList-module_deleteGarbage__dUevQ", Qn = "SelectedList-module_body__L5Vqh", Yn = "SelectedList-module_item__cNwsc", Kn = "SelectedList-module_text__cTwHb", Xn = "SelectedList-module_close__t0DmX";

const Jn = e({
    name: "SelectedList",
    props: An,
    emits: kn,
    setup(e, a) {
        const {store: t} = Bn(), l = () => {
            t.setSelectedPersonList([]);
        };
        return () => {
            var e;
            return f("div", {
                class: jn
            }, [ f("div", {
                class: $n
            }, [ f("div", {
                class: zn
            }, [ D("已选择"), f("span", {
                class: Gn
            }, [ D("("), null !== (e = t.selectedPersonList.value.length) && void 0 !== e ? e : 0, D(")") ]) ]), f("div", {
                class: Hn,
                onClick: l
            }, [ f(V("syc-icon"), {
                type: "delete-garbage"
            }, null) ]) ]), f("div", {
                class: Qn
            }, [ t.selectedPersonList.value.map((e => f("div", {
                class: Yn
            }, [ f("div", {
                class: Kn,
                title: e.name
            }, [ e.name || "已删除成员" ]), f("div", {
                class: Xn,
                onClick: () => (e => {
                    t.setSelectedPersonList(t.selectedPersonList.value.filter((a => a.id !== e.id)));
                })(e)
            }, [ f(V("syc-icon"), {
                type: "close-simple"
            }, null) ]) ]))) ]) ]);
        };
    }
});

var Zn = {
    selectPersonContent: "SelectingList-module_selectPersonContent__bg-Av",
    allCheckbox: "SelectingList-module_allCheckbox__AAIUl"
};

const er = e({
    name: "SelectingList",
    props: An,
    emits: kn,
    setup(e, a) {
        const {store: t} = Bn(), {radioClick: l, radioValue: n, checkboxCheck: r, checkboxValue: s, isAllCheck: u, allCheckboxCheck: d} = ((e, a, t) => {
            const l = i(""), o = i([]), n = i(!1);
            return P((() => [ t.selectedPersonList.value, t.selectingPersonList.value ]), (() => {
                const a = t.selectedPersonList.value, i = t.selectingPersonList.value;
                e.multiple ? (o.value = Aa(a) ? a.map((e => e.id)) : [], n.value = !Ja.isEmpty(i) && i.every((e => a.find((a => e.id === a.id))))) : l.value = Aa(a) ? a[0].id : " ";
            })), P((() => e.selectedValue), (() => {})), w((() => {
                var a;
                null !== (a = e.selectedValue) && void 0 !== a && a.length && t.setSelectedPersonList(e.selectedValue);
            })), {
                checkboxValue: o,
                radioValue: l,
                radioClick: (e, a) => {
                    e && "INPUT" === e.target.tagName || null != a && a.id && (l.value === a.id ? t.setSelectedPersonList([], !0) : t.setSelectedPersonList(Array.of(a), !0));
                },
                checkboxCheck: e => {
                    if (null == e || !e.id) return;
                    const a = t.selectedPersonList.value;
                    a.findIndex((a => a.id === e.id)) >= 0 ? t.setSelectedPersonList(a.filter((a => a.id !== e.id))) : t.setSelectedPersonList([ ...a, e ]);
                },
                allCheckboxCheck: e => {
                    const a = t.selectedPersonList.value, l = t.selectingPersonList.value, o = a.filter((e => !l.find((a => a.id === e.id)))), i = e ? [ ...o, ...l ] : o;
                    t.setSelectedPersonList(i);
                },
                isAllCheck: n
            };
        })(e, 0, t);
        return () => f("div", {
            class: Zn.selectPersonContent
        }, [ o(f(V("ssu-checkbox"), {
            "model-value": u.value,
            class: Zn.allCheckbox,
            label: "全选",
            onChange: e => d(e)
        }, null), [ [ g, e.multiple ] ]), t.selectingPersonList.value.map((a => e.multiple ? f(V("ssu-checkbox"), {
            "model-value": !!s.value.find((e => e === a.id)),
            title: a.name,
            label: a.name,
            onChange: () => r(a)
        }, null) : f("div", {
            class: Zn.ssuRadioWrap,
            onClick: e => l(e, a)
        }, [ f(V("ssu-radio"), {
            "model-value": n.value,
            label: a.id,
            title: a.name
        }, {
            default: () => [ a.name ]
        }) ]))) ]);
    }
});

var ar = "Tree-module_content__rzUng";

const tr = (e, a, t) => {
    const l = le(), {search: o} = Wn(e, 0, t), i = e => e.filter((e => {
        var a, l, o, n;
        if (t.deptMap[e.id] = e, null !== (a = e.children) && void 0 !== a && a.length && (e.children = i(e.children)), 
        t.deptList.has(e.id)) return null === (n = e.member) || void 0 === n || n.forEach((e => {
            t.optionalUsersId.add(e.id);
        })), !0;
        return !!(null === (l = e.member) || void 0 === l ? void 0 : l.filter((e => !!t.userList.has(e.id) && (t.optionalUsersId.add(e.id), 
        !0)))).length || !(null === (o = e.children) || void 0 === o || !o.length);
    })), n = e => {
        e.forEach((e => {
            var a;
            t.deptMap[e.id] = e, null !== (a = e.children) && void 0 !== a && a.length && n(e.children);
        }));
    }, r = e => {
        const a = t.getElTreeRef();
        if (a && "blur" === e && a.setCurrentKey(null), a && "firstFocus" === e && Aa(t.treeDataList.value)) {
            const e = t.treeDataList.value[0];
            a.setCurrentKey(e.id);
        }
    }, s = () => {
        const e = t.treeDataList.value[0];
        r("firstFocus"), d(e);
    }, u = () => {
        if (Ua(e.defaultDeptTreeData)) return t.setTreeDataList(e.defaultDeptTreeData), 
        void s();
        (t.setIsLoading(!0), l.admin.getDepartmentMemberTree().then((a => {
            let l = null != a ? a : [];
            t.deptMap = {}, t.optionalUsersId.clear(), e.deptFilterParams.length ? l = i(l) : n(l), 
            t.setTreeDataList(l);
        })).finally((() => {
            t.setIsLoading(!1);
        }))).then((() => {
            s();
        }));
    }, d = e => {
        e && (t.setIsGlobalSearch(!1), o(e.id));
    };
    return P((() => t.isGlobalSearch.value), (() => {
        t.isGlobalSearch.value && r("blur");
    })), w((() => {
        u();
    })), {
        treeProps: {
            label: "name"
        },
        treeNodeClick: d
    };
}, lr = e({
    name: "Tree",
    props: An,
    emits: kn,
    setup(e, a) {
        const {store: t} = Bn(), {treeProps: l, treeNodeClick: o} = tr(e, 0, t);
        return () => f(V("ssu-tree"), {
            border: !0,
            ref: t.ssuTreeRef,
            props: l,
            class: ar,
            data: t.treeDataList.value,
            onNodeClick: o
        }, null);
    }
});

var or = {
    selectPersonContainer: "index-module_selectPersonContainer__InTyN",
    search: "index-module_search__Xi0jd",
    body: "index-module_body__jTvmK",
    selectingList: "index-module_selectingList__Q082p",
    searchInput: "index-module_searchInput__UGS86",
    selectedList: "index-module_selectedList__-cjCv",
    right: "index-module_right__BCcG0"
};

const ir = e({
    name: "SelectPersonDialog",
    props: An,
    emits: kn,
    setup(e, a) {
        const {store: t} = ((e, a) => {
            const t = {
                store: new Un(e, a),
                rootInstance: n()
            };
            return k(_n, t), t;
        })(e, a), l = s((() => "selectPersonDialog " + (e.multiple ? "isMultiple" : ""))), {closedDialog: o, openedDialog: i, confirmDialog: r} = ((e, a, t) => {
            Wn(e, 0, t);
            const l = () => {
                a.emit("closed"), t.clear();
            };
            return {
                closedDialog: l,
                openedDialog: () => {
                    a.emit("opended");
                },
                confirmDialog: () => {
                    Ja.isEmpty(t.selectedPersonList.value) ? Ka({
                        type: "warning",
                        message: "没有选择对应的人员，如需关闭弹框，请直接点击关闭"
                    }) : (a.emit("confirm", t.selectedPersonList.value), l());
                }
            };
        })(e, a, t);
        return () => f(Ni, E(a.attrs, {
            title: "选择成员",
            "disabled-limit-height": !0,
            "destroy-on-close": !0,
            "custom-class": l.value,
            loading: t.isLoading.value,
            width: 600,
            onClosed: o,
            onOpened: i
        }), {
            default: () => [ f("div", {
                class: or.selectPersonContainer
            }, [ f("div", {
                class: or.body
            }, [ f(lr, E({
                class: or.tree
            }, e, Ja.omit(a.attrs, [ "class", "style" ]), {
                "default-expand-all": !0
            }), null), f("div", {
                class: or.selectingList
            }, [ f("div", {
                class: or.searchInput
            }, [ f(qn, E(e, Ja.omit(a.attrs, [ "class", "style" ])), null) ]), f("div", {
                class: or.selectedList
            }, [ f(er, E(e, Ja.omit(a.attrs, [ "class", "style" ])), null) ]) ]) ]), e.multiple && f("div", {
                class: or.right
            }, [ f(Jn, E({
                class: or.selectedList
            }, e, Ja.omit(a.attrs, [ "class", "style" ])), null) ]) ]) ],
            footer: () => f("div", {
                class: or.footer
            }, [ f(V("ssu-button"), {
                onClick: o
            }, {
                default: () => [ D("取消") ]
            }), f(V("ssu-button"), {
                type: "primary",
                onClick: r
            }, {
                default: () => [ D("确认") ]
            }) ])
        });
    }
}), {formComponentMixin: nr} = z;

var rr = e({
    name: "OlMember",
    components: {
        SelectPersonDialog: ir,
        InputSelect: Vo
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: "single"
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    mixins: [ nr ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = le(), {getRange: o} = Ca(), n = Na(e), r = s((() => e.runtimeEnv === Q.DESIGNER)), u = i([]), d = i(!1), p = i([]), m = i(1), c = i(20), v = i(""), f = i(!1), g = ne("member"), b = s((() => ({
            ...e,
            ...a
        }))), h = i(!1), y = i([]), D = s((() => g.value.toList())), C = i([]), w = s({
            get: () => ((n.value || e.metaData.defaultValue)?.filter(Boolean) ?? []).map((e => {
                const a = D.value.find((a => a.id === e));
                return a || y.value.includes(e) || y.value.push(e), {
                    ...a,
                    value: a?.id,
                    label: a?.name || "已删除成员",
                    name: a?.name || "已删除成员"
                };
            })),
            set: e => {
                Ja.isEmpty(e) ? t("update:modelValue", null) : Array.isArray(e) ? t("update:modelValue", e.map((e => e.id))) : t("update:modelValue", [ e.id ]);
            }
        }), S = s((() => "multiple" === e.mode)), L = (e = []) => {
            u.value = u.value.filter((a => !e.find((e => e.id === a.id))));
        }, T = (e = []) => {
            e.forEach((e => {
                g.value.addCache({
                    cacheKey: e.id,
                    ...e
                });
            }));
        }, V = () => l.admin.getMemberListV3(C.value, {
            pageNum: m.value,
            keyword: v.value,
            pageSize: c.value
        }), E = async e => {
            v.value = e, f.value = !0, p.value = [], m.value = 1;
            const a = await V();
            d.value = a.more, L(a.result ?? []), p.value = a.result, T(a.result ?? []), f.value = !1;
        };
        P((() => e.formData), (() => {
            const a = Ba(e.metaData.props, "range") ?? [];
            C.value = o({
                range: a,
                formValue: e.formData,
                userData: {
                    ...ve.data
                }
            });
        }), {
            immediate: !0,
            deep: !0
        }), P((() => e.metaData.defaultValue), (e => {
            n.value && (w.value = e);
        })), P((() => y.value), Ja.debounce((e => {
            if (!e?.length) return;
            const a = e.filter((e => e && !Ja.isEmpty(e)));
            a.length && l.admin.getBatchUser({
                userIds: a
            }).then((e => {
                (e || []).forEach((e => {
                    g.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), y.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        });
        return {
            dataValue: w,
            isMultiple: S,
            bindData: b,
            memberSelectDialogVisible: h,
            handleOpenDialog: () => {
                h.value = !0;
            },
            handleClosed: () => {
                h.value = !1;
            },
            handleConfirm: e => {
                const a = Array.isArray(e) ? e : [ e ];
                u.value = [ ...a ], w.value = a.map((e => (g.value.addCache({
                    cacheKey: e.id,
                    ...e
                }), {
                    ...e,
                    value: e.id,
                    label: e.name
                })));
            },
            deptFilterParams: C,
            filterMethod: E,
            loading: f,
            handleLoadMore: async e => {
                if (f.value || !d.value) return;
                f.value = !0, m.value++;
                const a = await V();
                d.value = a.more, L(a.result ?? []), p.value = p.value.concat(a.result ?? []), T(a.result), 
                f.value = !1;
            },
            optionData: p,
            visibleChange: e => {
                e && E("");
            },
            isDesigner: r
        };
    }
});

const sr = {
    class: "ol-member"
}, ur = {
    class: "ol-member-input"
};

rr.render = function(e, a, o, i, n, r) {
    const s = V("input-select"), u = V("SelectPersonDialog");
    return t(), l("div", sr, [ y("div", ur, [ f(s, E(e.bindData, {
        modelValue: e.dataValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.dataValue = a),
        multiple: e.isMultiple,
        "handle-select": e.handleOpenDialog
    }), null, 16, [ "modelValue", "multiple", "handle-select" ]) ]), e.memberSelectDialogVisible ? (t(), 
    L(u, {
        key: 0,
        modelValue: e.memberSelectDialogVisible,
        "onUpdate:modelValue": a[1] || (a[1] = a => e.memberSelectDialogVisible = a),
        selectedValue: e.dataValue,
        multiple: e.isMultiple,
        deptFilterParams: e.deptFilterParams,
        version: "v2",
        onClosed: e.handleClosed,
        onConfirm: e.handleConfirm
    }, null, 8, [ "modelValue", "selectedValue", "multiple", "deptFilterParams", "onClosed", "onConfirm" ])) : F("", !0) ]);
};

const dr = {
    multiple: {
        type: Boolean,
        default: !1
    },
    selectedValue: {
        type: Array,
        default: () => []
    },
    filterSelectedValue: {
        type: Array,
        default: () => []
    },
    selectMobileVisible: {
        type: Boolean,
        default: !1
    }
}, pr = {
    "update:selected-value": e => Array.isArray(e),
    closed: null,
    opended: null,
    confirm: e => Array.isArray(e)
};

let mr = "SELECT_PERSON_KEY";

class cr {
    constructor(e, a) {
        xi(this, "props", void 0), xi(this, "ctx", void 0), xi(this, "ssuTreeRef", i(null)), 
        xi(this, "isLoading", i(!1)), xi(this, "isGlobalSearch", i(!1)), xi(this, "searchValue", i("")), 
        xi(this, "treeDataList", i([])), xi(this, "selectedDepartmentList", i([])), xi(this, "selectingDepartmentList", i([])), 
        xi(this, "filterSelectedDepartmentList", i([])), this.props = e, this.ctx = a, this.filterSelectedDepartmentList = s((() => e.filterSelectedValue || [])), 
        this.setSearchValue = this.setSearchValue.bind(this), this.setTreeDataList = this.setTreeDataList.bind(this), 
        this.setSelectedDepartmentList = this.setSelectedDepartmentList.bind(this), this.setSelectingDepartmentList = this.setSelectingDepartmentList.bind(this), 
        this.setIsLoading = this.setIsLoading.bind(this), this.getElTreeRef = this.getElTreeRef.bind(this);
    }
    clear() {
        this.setIsLoading(!1), this.setIsGlobalSearch(!1), this.setSearchValue(""), this.setTreeDataList([]), 
        this.setSelectingDepartmentList([]), this.setSelectedDepartmentList([]), this.setFilterSelectedDepartmentList([]);
    }
    getElTreeRef() {
        var e;
        return null === (e = this.ssuTreeRef.value) || void 0 === e ? void 0 : e.getElTreeRef();
    }
    setTreeDataList(e) {
        this.treeDataList.value = Array.isArray(e) ? e : [];
    }
    setSelectedDepartmentList(e, a = !1) {
        this.selectedDepartmentList.value = Array.isArray(e) ? e : [], a && this.ctx.emit("update:selected-value", this.selectedDepartmentList.value);
    }
    setSelectingDepartmentList(e) {
        let a = this.selectingDepartmentList.value;
        a = Array.isArray(e) ? e : [], a = a.filter((e => !this.filterSelectedDepartmentList.value.includes(e.id))), 
        this.selectingDepartmentList.value = a;
    }
    setFilterSelectedDepartmentList(e) {
        this.filterSelectedDepartmentList.value = null != e ? e : [];
    }
    setSearchValue(e) {
        this.searchValue.value = String(e);
    }
    setIsGlobalSearch(e) {
        this.isGlobalSearch.value = !!e;
    }
    setIsLoading(e) {
        this.isLoading.value = !!e;
    }
}

const vr = (e, a, t) => {
    const l = le(), o = e => {
        const a = t.isGlobalSearch.value ? {
            keyword: t.searchValue.value,
            pageNum: 1,
            pageSize: 100
        } : {
            deptId: e,
            pageNum: 1,
            pageSize: 1e4
        };
        return t.setIsLoading(!0), l.admin.getMemberList(a).then((e => {
            t.setSelectingDepartmentList(e.result);
        })).finally((() => t.setIsLoading(!1)));
    }, i = e => {
        13 === e.keyCode && (t.setIsGlobalSearch(!0), o());
    }, n = () => {
        window.addEventListener("keydown", i);
    }, r = () => {
        window.removeEventListener("keydown", i);
    };
    return w((() => {
        n();
    })), B((() => {
        r();
    })), P((() => e.filterSelectedValue), (() => {
        const a = t.selectingDepartmentList.value;
        t.setFilterSelectedDepartmentList(e.filterSelectedValue), a && t.setSelectingDepartmentList(a);
    })), {
        addSearchKeyDownListener: n,
        removeSearchKeyDownLister: r,
        search: o,
        inputSearchInput: e => {
            t.setSearchValue(e);
        },
        inputSearchClick: () => {
            t.setIsGlobalSearch(!0), o();
        }
    };
}, fr = (e, a, t) => {
    const l = le();
    vr(e, 0, t);
    const o = e => {
        let a = e;
        const t = a.id;
        Array.isArray(a.children) && a.children.forEach((e => {
            e.uuid = `${t}/${e.id}`, e.hiddenSuffix = !0, Array.isArray(e.children) && o(e);
        })), Aa(a.member) && a.member.forEach((e => {
            e.uuid = `${t}/${e.id}`, e.hiddenSuffix = !1;
        })), a.children = [ ...a.children, ...a.member ];
    }, i = e => {
        const a = t.getElTreeRef();
        if (a && "blur" === e && a.setCurrentKey(null), a && "firstFocus" === e && Aa(t.treeDataList.value)) {
            const e = t.treeDataList.value[0];
            a.setCurrentKey(e.uuid);
        }
    }, n = () => {
        var a;
        const l = t.getElTreeRef();
        l && (l.setCheckedKeys(null === (a = e.selectedValue) || void 0 === a ? void 0 : a.map((e => e.uuid))), 
        b((() => {
            t.setSelectedDepartmentList(l.getCheckedNodes()), e.selectedValue.length > 0 ? l.setCurrentKey(e.selectedValue[0]) : i("firstFocus");
        })));
    }, r = () => {
        (t.setIsLoading(!0), l.admin.getDepartmentMemberTree().then((e => {
            e.forEach((e => {
                e.uuid = e.id, e.hiddenSuffix = !0, o(e);
            })), t.setTreeDataList(null != e ? e : []);
        })).finally((() => {
            t.setIsLoading(!1);
        }))).then((() => {
            t.treeDataList.value[0], n();
        }));
    };
    return P((() => t.isGlobalSearch.value), (() => {
        t.isGlobalSearch.value && i("blur");
    })), P((() => e.selectedValue), (() => {
        n();
    })), P((() => {
        var e;
        return null === (e = t.selectedDepartmentList.value) || void 0 === e ? void 0 : e.length;
    }), ((e, a) => {
        b((() => {
            if (e < a) {
                var l;
                const e = t.getElTreeRef();
                if (!e) return;
                e.setCheckedKeys(null === (l = t.selectedDepartmentList.value) || void 0 === l ? void 0 : l.map((e => e.uuid)));
            }
        }));
    })), w((() => {
        r();
    })), {
        treeProps: {
            label: "name"
        },
        treeNodeClick: a => {
            if (!a) return;
            const l = t.getElTreeRef();
            t.setIsGlobalSearch(!1);
            let o = [];
            if (e.multiple) {
                const e = t.selectedDepartmentList.value;
                o = !!e.find((e => e.uuid === a.uuid)) ? e.filter((e => e.uuid !== a.uuid)) : e.concat(a);
            } else o = Array.of(a), t.setSelectedDepartmentList(o);
            t.setSelectedDepartmentList(o), l.setCheckedKeys(o.map((e => e.uuid)));
        }
    };
}, gr = e({
    name: "Tree",
    props: {
        ...dr,
        filter: {
            type: Boolean,
            default: !1
        }
    },
    emits: pr,
    setup(e, a) {
        const {store: t} = r(mr), {treeProps: l, treeNodeClick: o} = fr(e, 0, t);
        t.selectedDepartmentList.value;
        const i = s((() => e.filter ? t.selectedDepartmentList.value.map((e => ({
            ...e,
            children: []
        }))) : t.treeDataList.value));
        return e => f(V("ssu-tree"), {
            ref: t.ssuTreeRef,
            props: l,
            class: "ol-member-mobile-tree",
            type: null != e && e.multiple ? "checkbox" : "radio",
            data: i.value,
            "default-expand-all": !0,
            "node-key": "uuid",
            "check-strictly": !0,
            onCheck: o
        }, null);
    }
}), br = e({
    name: "SelectDepartmentMobile",
    props: dr,
    emits: pr,
    setup(e, a) {
        const {store: t} = ((e, a) => {
            const t = {
                store: new cr(e, a),
                rootInstance: n()
            };
            return k(mr, t), t;
        })(e, a), {closedDialog: l, openedDialog: o, confirmDialog: r} = ((e, a, t) => {
            const {addSearchKeyDownListener: l, removeSearchKeyDownLister: o} = vr(e, 0, t), i = () => {
                a.emit("closed"), o(), t.clear();
            };
            return {
                closedDialog: i,
                openedDialog: () => {
                    a.emit("opended"), l();
                },
                confirmDialog: () => {
                    Ja.isEmpty(t.selectedDepartmentList.value) ? nt("没有选择对应的部门，如需关闭弹框，请直接点击关闭") : (a.emit("confirm", t.selectedDepartmentList.value), 
                    i());
                }
            };
        })(e, a, t), u = s((() => e.selectMobileVisible)), d = i("all"), p = i(!1), m = e => {
            p.value = "checked" === e;
        };
        return P((() => u.value), (() => {
            d.value = "all", p.value = !1;
        })), () => f("div", {
            class: "selectDepartmentMobileContainer"
        }, [ f(lt, {
            show: u.value,
            "onUpdate:show": e => u.value = e,
            "close-on-click-action": !0
        }, {
            default: () => [ u.value && f("div", null, [ f(Yi, {
                title: "请选择",
                onCancel: l,
                onConfirm: r
            }, null), f(ot, {
                active: d.value,
                "onUpdate:active": e => d.value = e,
                onChange: m,
                "title-active-color": "#167DFA",
                color: "white"
            }, {
                default: () => [ f(it, {
                    title: "可选",
                    name: "all"
                }, null), f(it, {
                    title: "已选",
                    name: "checked"
                }, null) ]
            }), f("div", {
                class: [ "tree", p.value ? "unIconTree" : "" ]
            }, [ f(gr, E({
                class: "tree"
            }, e, Ja.omit(a.attrs, [ "class", "style" ]), {
                filter: p.value
            }), null) ]) ]) ]
        }) ]);
    }
}), {formComponentMixin: hr} = z;

var yr = e({
    name: "OlMemberMobile",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: "single"
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ hr ],
    setup(e, {emit: a}) {
        const t = le(), l = Na(e), o = ne("member"), n = i([]), r = s((() => "multiple" === e.mode)), u = i(!1), d = () => {
            e.disabled || (u.value = !0);
        }, p = () => {
            u.value = !1;
        }, m = e => {
            const a = Array.isArray(e) ? e : [ e ];
            v.value = a.map((e => (o.value.addCache({
                cacheKey: e.id,
                ...e
            }), {
                ...e,
                value: e.id,
                label: e.name
            })));
        }, c = s((() => o.value.toList())), v = s({
            get: () => {
                var a, t;
                return null === (a = l.value || e.metaData.defaultValue) || void 0 === a || null === (t = a.map) || void 0 === t ? void 0 : t.call(a, (e => {
                    const a = c.value.find((a => a.id === e));
                    return a || n.value.includes(e) || n.value.push(e), {
                        ...a,
                        value: null == a ? void 0 : a.id,
                        label: (null == a ? void 0 : a.name) || "已删除成员",
                        name: (null == a ? void 0 : a.name) || "已删除成员"
                    };
                }));
            },
            set: e => {
                a("update:modelValue", e.map((e => e.id)));
            }
        }), g = s((() => {
            var e;
            let a = null === (e = v.value) || void 0 === e ? void 0 : e.length;
            var t, l, o, i;
            return 1 === a ? null === (t = v.value) || void 0 === t || null === (l = t[0]) || void 0 === l ? void 0 : l.name : a > 1 ? `已选择${null === (o = v.value) || void 0 === o || null === (i = o[0]) || void 0 === i ? void 0 : i.name}等${a}项` : "";
        }));
        return P((() => e.metaData.defaultValue), (e => {
            l.value && (v.value = e);
        })), P((() => n.value), Ja.debounce((e => {
            if (null == e || !e.length) return;
            const a = e.filter((e => e && !Ja.isEmpty(e)));
            a.length && t.admin.getBatchUser({
                userIds: a
            }).then((e => {
                (e || []).forEach((e => {
                    o.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), n.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        }), () => f("div", {
            class: "ol-member-mobile"
        }, [ f("div", {
            class: "member-select",
            onClick: d
        }, [ f(V("ssu-select"), {
            modelValue: g.value,
            disabled: null == e ? void 0 : e.disabled
        }, null), f("div", {
            class: "select-hot-zone"
        }, null) ]), f(br, {
            selectedValue: v.value,
            multiple: r.value,
            selectMobileVisible: u.value,
            onClosed: p,
            onConfirm: m
        }, null) ]);
    }
});

const Dr = "OPTTIONAL_SETTING_STORE", Cr = {
    PERSONAL: "personal",
    DEPT: "dept",
    MODEL_FIELD: "modelField",
    ROLE: "role",
    JOB: "job"
}, wr = {
    [Cr.DEPT]: "#FD5849",
    [Cr.JOB]: "#FF942B",
    [Cr.ROLE]: "#1B7DFA",
    [Cr.PERSONAL]: "#3AC93C",
    [Cr.MODEL_FIELD]: "#6AD1FF"
}, Sr = ({key: e, label: a, componentType: t, showSearch: l}) => ({
    label: a,
    name: e,
    color: wr[e],
    dataSource: null,
    componentType: null != t ? t : "Tree",
    showSearch: l
}), Lr = {
    [Cr.DEPT]: {
        key: Cr.DEPT,
        label: "部门",
        componentType: "Tree",
        showSearch: !0
    },
    [Cr.JOB]: {
        key: Cr.JOB,
        label: "职位",
        componentType: "List",
        showSearch: !0
    },
    [Cr.ROLE]: {
        key: Cr.ROLE,
        label: "角色",
        componentType: "List",
        showSearch: !0
    },
    [Cr.PERSONAL]: {
        key: Cr.PERSONAL,
        label: "人员",
        componentType: "Personal",
        showSearch: !1
    },
    [Cr.MODEL_FIELD]: {
        key: Cr.MODEL_FIELD,
        label: "动态人员",
        componentType: "Tree",
        showSearch: !0
    }
}, Tr = e => e + "直属上级主管", Vr = e => `${e}.leader`, Er = e => e + "所在部门负责人", Ir = e => `${e}.hold`, Fr = [ Sr(Lr[Cr.DEPT]), Sr(Lr[Cr.JOB]), Sr(Lr[Cr.ROLE]), Sr(Lr[Cr.PERSONAL]) ], Or = (e, a) => {
    const t = le(), l = s((() => e.isRunTime)), o = i(""), n = s((() => {
        var e;
        return null !== (e = O.value.find((e => e.name === o.value))) && void 0 !== e ? e : {};
    })), r = i(!1), u = i(""), d = i([]), p = i([]), m = i(), c = i(""), v = s((() => {
        var a;
        return null !== (a = e.deptList) && void 0 !== a ? a : [];
    })), f = s((() => {
        var a;
        return null !== (a = e.memberList) && void 0 !== a ? a : [];
    })), g = i([]), b = s((() => e.dialogTitle)), h = i([]), y = i([]), D = i(null), C = i(null), w = i([]), S = i(), L = new Set, T = s((() => {
        var a, t;
        return null !== (a = null === (t = e.systemWidgets) || void 0 === t ? void 0 : t.filter((e => e.code === K.MEMBER))) && void 0 !== a ? a : [];
    })), V = s((() => e.showLeader)), E = s((() => e.showCurrentUser)), I = s((() => e.showCurrentUserDept)), F = s((() => e.otherWidgets.map((e => {
        var a;
        if (e.code === K.SINGLE_TEXT && null !== (a = e.validateRuleConfig) && void 0 !== a && a.length) {
            const a = e.validateRuleConfig[0], t = Ja.cloneDeep(e);
            return t.name.rawValue = `${t.name.rawValue}(${a.params.label})`, t;
        }
        return e;
    })))), O = s((() => {
        var a;
        let t = [];
        return null !== (a = e.customList) && void 0 !== a && a.length ? e.customList.forEach((e => {
            if ("string" == typeof e) {
                const a = Lr[e];
                a && t.push(Sr(a));
            } else t.push(e);
        })) : (l.value && (t = [ ...Fr ]), e.showDynamic && t.push(Sr(Lr.modelField))), 
        e.customTabListFun ? e.customTabListFun(t) : t;
    })), N = async (e = []) => {
        const a = {};
        e.forEach((e => {
            Array.isArray(a[e.type]) ? a[e.type].push(e.id) : a[e.type] = [ e.id ];
        }));
        const l = new Set;
        O.value.forEach((e => {
            if (e.dataSource) L.add(e.name); else switch (e.name) {
              case Cr.PERSONAL:
              case Cr.DEPT:
                l.add((async () => {
                    const {deptInfo: e = [], userInfo: l = []} = await t.admin.getBatchDept({
                        deptIds: a.dept,
                        userIds: a.personal
                    });
                    S.value = {
                        deptInfo: e,
                        userInfo: l
                    };
                })());
                break;

              case Cr.ROLE:
                l.add((async () => {
                    const e = await t.admin.getRoleList({
                        pageSize: 999,
                        pageNum: 1
                    });
                    C.value = e;
                })());
                break;

              case Cr.JOB:
                l.add((async () => {
                    const e = await t.admin.getPositionList({
                        pageSize: 999,
                        pageNum: 1
                    });
                    D.value = e;
                })());
            }
        })), await Promise.all([ ...l ]), M(e);
    }, M = a => {
        for (let b = 0; b < a.length; b++) {
            var t;
            let w = null !== (t = a[b]) && void 0 !== t ? t : {};
            if (L.has(w.type)) break;
            if ("modelField" === w.type) switch (w.id) {
              case "{{currentUser}}":
                w.name = "当前用户";
                break;

              case "{{currentUserDept}}":
                w.name = "当前用户所在部门";
                break;

              default:
                {
                    var l;
                    const a = null === (l = w.id) || void 0 === l ? void 0 : l.split("."), t = [ ...f.value, ...v.value, ...e.systemWidgets, ...F.value ].find((e => e.fieldName === a[0]));
                    if (t) {
                        var o;
                        const e = null === (o = t.name) || void 0 === o ? void 0 : o.rawValue, l = {
                            leader: Tr(e),
                            hold: Er(e)
                        };
                        w.name = l[a[1]] ? l[a[1]] : e;
                    } else w.name = "已删除";
                    break;
                }
            } else {
                var i, n, r, s, u, d, p, m, c, g;
                const e = (null !== (g = {
                    [Cr.ROLE]: null !== (i = null === (n = C.value) || void 0 === n ? void 0 : n.result) && void 0 !== i ? i : [],
                    [Cr.JOB]: null !== (r = null === (s = D.value) || void 0 === s ? void 0 : s.result) && void 0 !== r ? r : [],
                    [Cr.DEPT]: null !== (u = y.value.concat(null !== (d = null === (p = S.value) || void 0 === p ? void 0 : p.deptInfo) && void 0 !== d ? d : [])) && void 0 !== u ? u : [],
                    [Cr.PERSONAL]: null !== (m = h.value.concat((null === (c = S.value) || void 0 === c ? void 0 : c.userInfo) || [])) && void 0 !== m ? m : []
                }[w.type]) && void 0 !== g ? g : []).find((e => e.id === w.id));
                w.name = e ? e.name : "已删除";
            }
        }
    }, R = a => {
        const t = Ja.cloneDeep(a || e.modelValue);
        return null == t ? void 0 : t.map((e => ({
            bgColor: wr[e.type],
            ...e
        })));
    };
    P((() => r.value), (a => {
        if (!a) return;
        const t = R(e.modelValue);
        d.value = t, M(d.value);
    })), q((() => {
        var e, a;
        o.value = null !== (e = null === (a = O.value[0]) || void 0 === a ? void 0 : a.name) && void 0 !== e ? e : "";
    }));
    const x = e => {
        d.value.find((a => a.id === e.id)) || d.value.push(e);
    }, A = e => {
        const a = d.value.findIndex((a => a.id === e.id));
        a > -1 && d.value.splice(a, 1);
        const t = p.value.findIndex((a => a === e.id));
        t > -1 && p.value.splice(t, 1);
    }, k = P((() => e.modelValue), (async e => {
        if (null != e && e.length) {
            const a = R(e);
            await N(a), _(a), k();
        }
    }), {
        immediate: !0
    }), _ = e => {
        a.emit("update:modelValue", e);
    };
    return {
        visibleDialog: r,
        tabList: O,
        tabActive: o,
        tabActiveItem: n,
        filterParams: u,
        deptId: c,
        showSelectedList: d,
        deptTreeRef: m,
        handleAddCheckedList: x,
        handleDelCheckedList: A,
        personelListSource: p,
        handleChangeDeptTree: (e, a = !1) => {
            var t;
            null === (t = m.value) || void 0 === t || t.getElTreeRef().setChecked(e, a, !0);
        },
        deptList: v,
        memberList: f,
        deptSourceData: w,
        jobSourceData: D,
        roleSourceData: C,
        handleChangeData: (e, a) => {
            const t = {
                id: e.id,
                name: e.name,
                bgColor: n.value.color,
                type: o.value
            };
            a ? x(t) : A(t);
        },
        isRunTime: l,
        dialogTitle: b,
        handleChange: _,
        systemWidgets: T,
        otherWidgets: F,
        showLeader: V,
        showCurrentUser: E,
        showCurrentUserDept: I,
        searchPersonelList: g,
        cachePersonelList: h,
        cacheDeptList: y
    };
}, Nr = () => r(Dr);

var Mr = e({
    name: "Detp",
    props: {
        dataSource: {
            type: Array
        },
        treeTypes: {
            type: String,
            default: "checkbox"
        }
    },
    setup(e) {
        const a = le(), {tabActive: t, deptId: l, filterParams: o, tabActiveItem: n, deptTreeRef: r, showSelectedList: s, handleChangeData: u, deptSourceData: d, deptList: p, memberList: m, systemWidgets: c, showLeader: v, showCurrentUser: g, showCurrentUserDept: h, cacheDeptList: y, otherWidgets: D} = Nr(), C = i([]), S = (e, a = []) => (e.forEach((e => {
            var t;
            null !== (t = e.children) && void 0 !== t && t.length && S(e.children, a), a.push(e);
        })), a);
        P((() => C.value), (e => {
            y.value.push(...S(e));
        }), {
            immediate: !0,
            deep: !0
        });
        const L = async () => {
            n.value.dataSource ? C.value = n.value.dataSource : await (async e => {
                if (d.value.length || (d.value = await a.admin.getDepartmentTree()), C.value = [ ...d.value ], 
                t.value === Cr.PERSONAL) {
                    var o, i, n;
                    l.value = null === (o = C.value[0]) || void 0 === o ? void 0 : o.id;
                    const a = null != e && e.id ? e : null === (i = C.value) || void 0 === i ? void 0 : i[0], t = null === (n = r.value) || void 0 === n ? void 0 : n.getElTreeRef();
                    b((() => {
                        null == t || t.setCurrentKey(null == a ? void 0 : a.id);
                    }));
                }
            })();
        }, T = e => {
            null != e && e.length && e.forEach((e => {
                const a = e.name.rawValue, t = e.fieldName;
                C.value.push({
                    name: a,
                    id: t,
                    type: Cr.MODEL_FIELD,
                    children: [ {
                        name: Tr(a),
                        id: Vr(t),
                        type: Cr.MODEL_FIELD
                    }, {
                        name: Er(a),
                        id: Ir(t),
                        type: Cr.MODEL_FIELD
                    } ]
                });
            }));
        }, E = (e, a) => {
            if (null == a || !a.length) return;
            const t = a.map((e => {
                var a;
                return {
                    name: (null === (a = e.name) || void 0 === a ? void 0 : a.rawValue) || "",
                    id: e.fieldName,
                    type: Cr.MODEL_FIELD
                };
            }));
            C.value.push({
                name: e,
                hiddenSuffix: !0,
                children: t
            });
        }, I = async () => {
            C.value = [], g.value && C.value.push({
                name: "当前用户",
                type: Cr.MODEL_FIELD,
                id: "{{currentUser}}"
            }), h.value && C.value.push({
                name: "当前用户所在部门",
                type: Cr.MODEL_FIELD,
                id: "{{currentUserDept}}"
            }), v.value ? (T(m.value), T(p.value), T(c.value)) : (E("人员字段", m.value), E("部门字段", p.value), 
            E("系统字段", c.value)), D.value.length && D.value.forEach((e => {
                var a;
                C.value.push({
                    name: (null === (a = e.name) || void 0 === a ? void 0 : a.rawValue) || "",
                    type: Cr.MODEL_FIELD,
                    id: e.fieldName
                });
            }));
        };
        P((() => o.value), (e => {
            var a;
            null === (a = r.value) || void 0 === a || a.getElTreeRef().filter(e);
        })), w((async () => {
            (async () => {
                t.value === Cr.MODEL_FIELD ? I() : await L();
                const e = s.value.filter((e => e.type === t.value));
                b((() => {
                    e.forEach((e => {
                        var a;
                        null === (a = r.value) || void 0 === a || a.getElTreeRef().setChecked(e.id, !0, !0);
                    }));
                }));
            })();
        }));
        const F = (e, a) => !e || a.name.includes(e), O = e => {
            var a;
            t.value === Cr.PERSONAL && (l.value = null !== (a = e.id) && void 0 !== a ? a : "");
        }, N = e => ({
            2: {
                icon: "iconwenjianjia",
                size: 20
            },
            3: {
                icon: "iconwenjianjia",
                size: 16
            },
            4: {
                icon: "iconwenjianjia2"
            }
        }[e.data.level]);
        return () => f("div", {
            class: "tree-container"
        }, [ f(V("ssu-tree"), {
            ref: r,
            class: "department-tree-nodes",
            data: C.value,
            "default-expand-all": !0,
            "node-key": "id",
            "check-strictly": !0,
            border: !0,
            type: e.treeTypes,
            "filter-node-method": F,
            onNodeClick: O,
            onCheckChange: u
        }, {
            label: ({data: e}) => null == e ? void 0 : e.name,
            icon: e => N(e) && f(V("ssu-icon"), {
                "icon-name": N(e).icon,
                size: N(e).size,
                "class-name": "pre-icon"
            }, null)
        }) ]);
    }
}), Rr = e({
    name: "List",
    setup(e) {
        const a = le(), {tabActive: t, tabActiveItem: l, filterParams: o, showSelectedList: n, personelListSource: r, handleChangeData: u, jobSourceData: d, roleSourceData: p} = Nr(), m = {
            role: async () => (p.value || (p.value = await a.admin.getRoleList({
                pageSize: 999,
                pageNum: 1
            })), p.value),
            job: async () => (d.value || (d.value = await a.admin.getPositionList({
                pageSize: 999,
                pageNum: 1
            })), d.value)
        }, c = i([]), v = s((() => {
            let e = [];
            return e = o.value ? c.value.filter((e => e.name.includes(o.value))) : c.value, 
            e;
        }));
        return w((async () => {
            (async () => {
                if (l.value.dataSource) c.value = l.value.dataSource; else {
                    const e = await m[t.value]();
                    c.value = e.result;
                }
                r.value = n.value.filter((e => e.type === t.value)).map((e => e.id));
            })();
        })), () => {
            var e;
            return f("div", {
                class: "tree-list-container"
            }, [ f("div", {
                class: "checkList"
            }, [ f(V("ssu-checkbox-group"), {
                modelValue: r.value,
                "onUpdate:modelValue": e => r.value = e,
                class: "check-group"
            }, {
                default: () => [ null === (e = v.value) || void 0 === e ? void 0 : e.map((e => f(V("ssu-checkbox"), {
                    key: e.id,
                    label: e.id,
                    class: "check-item",
                    onChange: a => {
                        u(e, a);
                    }
                }, {
                    default: () => [ e.name ]
                }))) ]
            }) ]) ]);
        };
    }
}), xr = e({
    name: "Personal",
    components: {
        Dept: Mr
    },
    setup(e) {
        const a = le(), {tabActive: t, personelListSource: l, deptId: n, tabActiveItem: r, showSelectedList: s, handleAddCheckedList: d, handleDelCheckedList: p, cachePersonelList: m} = Nr();
        let c = u({
            deptId: n,
            keyword: "",
            pageNum: 1,
            pageSize: 10,
            status: 0,
            topId: "",
            loading: !1,
            finished: !1,
            error: !1
        }), v = u({
            deptId: n,
            keyword: "",
            pageNum: 1,
            pageSize: 10,
            status: 0,
            topId: "",
            loading: !1,
            finished: !1,
            error: !1
        }), b = i("");
        const h = i(), y = i(), C = i([]), S = i([]);
        P((() => b.value), (() => {
            "" === b.value && (c.keyword = "", c.loading = !1, c.finished = !1, S.value = []);
        }));
        const L = (e, a) => {
            const l = {
                id: e.id,
                name: e.name,
                bgColor: r.value.color,
                type: t.value
            };
            a ? d(l) : p(l);
        }, T = () => {
            c.pageNum = 1, c.finished = !1, E("search");
        }, E = e => {
            let t = "search" === e ? c : v;
            const {loading: l, finished: o, error: i} = t;
            if (l || o || i) return;
            "search" === e && (t.keyword = b.value), t.loading = !0;
            const n = Ja.pickBy(Ja.omit(t, [ "loading", "finished", "error" ]));
            a.admin.getMemberList(n).then((({result: a, pageNum: l, more: o}) => {
                a = a || [], m.value.push(...a);
                let i = "search" === e ? S : C;
                i.value = l > 1 ? i.value.concat(a) : a, t.error = !1, o ? t.pageNum = l + 1 : t.finished = !0;
            })).catch((() => {
                t.error = !0;
            })).finally((() => {
                t.loading = !1;
            }));
        }, I = ({scrollTop: e, type: a}) => {
            const t = "search" === a ? h : y;
            e + t.value.scrollbar$.clientHeight + 50 >= t.value.resize$.clientHeight && E(a);
        };
        return w((async () => {
            await (l.value = s.value.filter((e => e.type === Cr.PERSONAL)).map((e => e.id)));
        })), P((() => n.value), (async () => {
            v.pageNum = 1, v.loading = !1, v.error = !1, v.finished = !1, C.value = [], E("list");
        }), {
            immediate: !0
        }), () => f("div", {
            class: "tree-list-container personal"
        }, [ f(Mr, {
            "tree-types": "none"
        }, null), f("div", {
            class: "checkList"
        }, [ f("div", null, [ f(V("ssu-input-text"), {
            modelValue: b.value,
            "onUpdate:modelValue": e => b.value = e,
            "search-icon": "icon-zhinengsousuo",
            search: !0,
            clearable: !0,
            class: "personal-search",
            placeholder: "搜索用户名称",
            onSearch: T,
            onKeyEnter: T
        }, null), o(f("div", null, [ f(V("ssu-checkbox-group"), {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e,
            class: "check-group"
        }, {
            default: () => [ f(V("el-scrollbar"), {
                ref: h,
                onScroll: e => I({
                    ...e,
                    type: "search"
                })
            }, {
                default: () => [ S.value.map((e => f(V("ssu-checkbox"), {
                    key: e.id,
                    label: e.id,
                    class: "check-item",
                    onChange: a => L(e, a)
                }, {
                    default: () => [ e.name ]
                }))), c.finished && f("p", {
                    class: "no-data-serarch"
                }, [ D("暂无更多数据") ]), c.loading && f("p", {
                    class: "no-data-serarch"
                }, null) ]
            }) ]
        }) ]), [ [ g, c.keyword ] ]) ]), o(f("div", null, [ f(V("ssu-checkbox-group"), {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e,
            class: "check-group"
        }, {
            default: () => [ f(V("el-scrollbar"), {
                ref: y,
                onScroll: e => I({
                    ...e,
                    type: "list"
                })
            }, {
                default: () => [ C.value.map((e => f(V("ssu-checkbox"), {
                    key: e.id,
                    label: e.id,
                    class: "check-item",
                    onChange: a => L(e, a)
                }, {
                    default: () => [ e.name ]
                }))), v.finished && f("p", {
                    class: "no-data-serarch"
                }, [ D("暂无更多数据") ]), v.loading && f("p", {
                    class: "no-data-serarch"
                }, [ D("加载中....") ]) ]
            }) ]
        }) ]), [ [ g, !c.keyword ] ]) ]) ]);
    }
});

const Ar = {
    Tree: Mr,
    Personal: xr,
    List: Rr
};

var kr = e({
    name: "ChooseLeft",
    setup(e) {
        const {tabList: a, tabActive: t, tabActiveItem: l, filterParams: o} = Nr(), n = i(""), r = () => {
            o.value = n.value;
        }, u = s((() => {
            const e = Ar[l.value.componentType];
            return f(e, {
                key: t.value
            }, null);
        }));
        return () => f("div", {
            class: "choose-left-content"
        }, [ f(V("ssu-tab"), {
            active: t.value,
            "onUpdate:active": e => t.value = e,
            grade: "first",
            plain: !0,
            list: a.value,
            margin: {
                bottom: 0
            }
        }, null), l.value && f(R, null, [ f("div", {
            class: "seach"
        }, [ f("div", {
            class: "title"
        }, [ f("div", {
            class: "markColor",
            style: {
                background: l.value.color
            }
        }, null), l.value.label ]), l.value.showSearch && f(V("ssu-input-text"), {
            modelValue: n.value,
            "onUpdate:modelValue": e => n.value = e,
            "search-icon": "icon-zhinengsousuo",
            search: !0,
            placeholder: "请输入搜索内容",
            onSearch: r,
            onKeyEnter: r
        }, null) ]), l.value.componentType && u.value ]) ]);
    }
});

var Pr = e({
    props: {
        selectList: {
            type: Array,
            default: () => []
        },
        selectKey: {
            type: String,
            default: "name"
        },
        title: {
            type: String,
            default: "已选择项"
        },
        bgColor: {
            type: String,
            default: ""
        },
        limit: {
            type: Number
        },
        isDrag: {
            type: Boolean,
            default: !1
        }
    },
    emits: {
        handleItemDelete: null,
        handleAllDelete: null,
        handleDrag: null
    },
    setup(e, a) {
        const t = i(null), l = s((() => Ja.cloneDeep(e.selectList))), o = s((() => e.selectKey)), n = s((() => isNaN(Number(e.limit)) ? "" : "/" + Number(e.limit)));
        return P((() => l.value), (() => {
            b((() => {
                l.value.length > 0 && (() => {
                    const e = t.value?.querySelector(".ol-member-select-list-selected");
                    if (!t.value || !e) return;
                    const o = {
                        onEnd: e => {
                            if ("number" != typeof e.oldIndex || "number" != typeof e.newIndex) return;
                            const t = l.value[e.oldIndex], o = l.value[e.newIndex], i = Ja.cloneDeep(l.value), n = l.value, r = n.splice(e.oldIndex, 1)[0];
                            n.splice(e.newIndex, 0, r), a.emit("handleDrag", e, {
                                newRow: o,
                                oldRow: t,
                                oldData: i,
                                newData: n
                            });
                        }
                    };
                    pt.create(e, o);
                })();
            }));
        }), {
            deep: !0
        }), {
            selectListRef: t,
            computedLimit: n,
            tempSelectList: l,
            selectKey: o,
            handleItemDelete: (e, t) => {
                l.value.splice(e, 1), a.emit("handleItemDelete", l.value, t);
            },
            handleAllDelete: () => {
                a.emit("handleAllDelete", l.value);
            }
        };
    }
});

const _r = {
    ref: "selectListRef",
    class: "ol-member-select-list"
}, Ur = {
    class: "ol-member-select-list-header"
}, Br = {
    class: "ol-member-select-list-header-tip"
}, Wr = {
    class: "ol-member-select-list-overflow"
}, qr = {
    key: 0,
    class: "ol-member-select-list-selected"
}, jr = [ "title" ], $r = {
    key: 1,
    class: "ol-member-select-list-item-drag"
}, zr = {
    key: 2,
    class: "ol-member-select-list-item-icon"
}, Gr = [ "onClick" ];

Pr.render = function(e, a, o, i, n, r) {
    const s = V("ssu-icon");
    return t(), l("div", _r, [ y("div", Ur, [ y("div", null, [ W(e.$slots, "label", {}, (() => [ D(O(e.title), 1) ])), y("span", Br, "（" + O(e.tempSelectList.length) + O(e.computedLimit) + "）", 1) ]), y("i", {
        class: "iconfont iconpiliangshanchu ol-member-select-list-header-delete-icon",
        onClick: a[0] || (a[0] = (...a) => e.handleAllDelete && e.handleAllDelete(...a))
    }) ]), y("div", Wr, [ e.tempSelectList && e.tempSelectList.length ? (t(), l("div", qr, [ (t(!0), 
    l(R, null, x(e.tempSelectList, ((a, o) => (t(), l("div", {
        key: a.id,
        class: "ol-member-select-list-item",
        title: String(a[e.selectKey] ?? "")
    }, [ a.bgColor ? (t(), l("div", {
        key: 0,
        class: "colorBlock",
        style: j({
            backgroundColor: a.bgColor
        })
    }, null, 4)) : F("", !0), e.isDrag ? (t(), l("div", $r, [ f(s, {
        "icon-name": "icon-yidongshu",
        color: "#1B7DFA"
    }) ])) : F("", !0), e.$slots?.icon ? (t(), l("div", zr, [ W(e.$slots, "icon", {
        data: a
    }) ])) : F("", !0), y("span", null, O(a[e.selectKey] ?? ""), 1), y("i", {
        class: "iconfont iconguanbi-nor ol-member-select-list-close-icon",
        onClick: t => e.handleItemDelete(o, a)
    }, null, 8, Gr) ], 8, jr)))), 128)) ])) : F("", !0) ]) ], 512);
};

var Hr = e({
    name: "ChooseRight",
    components: {
        SelectList: Pr
    },
    setup(e) {
        const {showSelectedList: a, handleChangeDeptTree: t, handleDelCheckedList: l} = Nr(), o = (e, a) => {
            [ Cr.DEPT, Cr.MODEL_FIELD ].includes(a.type) && t(a.id), l(a);
        }, i = e => {
            e.forEach((e => {
                o(0, e);
            }));
        }, n = s((() => a.value));
        return () => f(V("select-list"), {
            title: "已选择",
            "select-list": n.value,
            onHandleItemDelete: o,
            onHandleAllDelete: i
        }, null);
    }
}), Qr = e({
    name: "OptionalDialog",
    components: {
        ChooseLeft: kr,
        ChooseRight: Hr
    },
    props: {
        visible: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "change" ],
    setup(e, {emit: a}) {
        const {showSelectedList: t, visibleDialog: l, tabList: o, dialogTitle: i} = Nr(), n = () => {
            l.value = !1;
        }, r = () => {
            const e = t.value.map((e => (delete e.bgColor, e)));
            a("change", e), l.value = !1;
        };
        return () => f(V("ssu-dialog"), {
            "custom-class": "ol-member-optional-dialog",
            modelValue: e.visible,
            "show-close": !1,
            width: "900px",
            appendToBody: !0
        }, {
            default: () => [ f("div", {
                class: "dialog-wrap"
            }, [ f("div", {
                class: "dialog-header"
            }, [ f("span", {
                class: "dialog-header-title"
            }, [ i.value ]), f("i", {
                class: "iconfont iconguanbi-nor dialog-header-close-icon close-icon",
                onClick: n
            }, null) ]) ]), f("div", {
                class: "ol-member-optional-dialog-content"
            }, [ f("div", {
                class: "choose-left"
            }, [ o.value.length ? f(kr, null, null) : "" ]), f("div", {
                class: "choose-right"
            }, [ f(Hr, null, null) ]) ]), f("slot", {
                name: "footer"
            }, [ f("div", {
                class: "dialog-footer"
            }, [ f(V("ssu-button"), {
                type: "plain",
                onClick: n
            }, {
                default: () => [ D("取消") ]
            }), f(V("ssu-button"), {
                type: "primary",
                onClick: r
            }, {
                default: () => [ D("确定") ]
            }) ]) ]) ]
        });
    }
}), Yr = e({
    name: "OptionalSetting",
    components: {
        OptionalDialog: Qr
    },
    props: {
        dialogTitle: {
            type: String,
            default: "人员范围"
        },
        multiple: {
            type: Boolean,
            default: !0
        },
        isRunTime: {
            type: Boolean,
            default: !1
        },
        modelValue: {
            type: [ String, Array ],
            default: () => []
        },
        customList: {
            type: Array,
            default: null
        },
        customTabListFun: {
            type: Function,
            default: null
        },
        deptList: {
            type: Array,
            default: () => []
        },
        memberList: {
            type: Array,
            default: () => []
        },
        showDynamic: {
            type: Boolean,
            default: !0
        },
        showCurrentUser: {
            type: Boolean,
            default: !0
        },
        showCurrentUserDept: {
            type: Boolean,
            default: !0
        },
        systemWidgets: {
            type: Array,
            default: () => []
        },
        showLeader: {
            type: Boolean,
            default: !1
        },
        otherWidgets: {
            type: Array,
            default: () => []
        }
    },
    emits: [ "update:modelValue" ],
    setup(e, a) {
        const {visibleDialog: t, showSelectedList: l, handleChange: o} = ((e, a) => {
            const t = Or(e, a);
            return k(Dr, t), t;
        })(e, a), {attrs: i, emit: n} = a, r = () => {
            l.value = [], n("update:modelValue", []);
        }, u = s((() => Array.isArray(e.modelValue) && e.modelValue.length && e.multiple));
        return () => {
            var a, l;
            return f(R, null, [ f("div", {
                class: "ol-member-optional-setting"
            }, [ u.value ? f("div", {
                class: "ol-member-optional-setting-item"
            }, [ (null === (a = e.modelValue[0]) || void 0 === a ? void 0 : a.name) && f(V("ssu-tag"), {
                size: "small"
            }, {
                default: () => [ f("div", {
                    class: "model-wrap"
                }, [ f("div", {
                    class: "model-text"
                }, [ (null === (l = e.modelValue[0]) || void 0 === l ? void 0 : l.name) || "" ]) ]) ]
            }), e.modelValue.length > 1 && f("span", {
                class: "icon-add"
            }, [ D("+"), e.modelValue.length - 1 ]), e.modelValue.length && f(V("ssu-icon"), {
                "icon-name": "iconx",
                size: "14",
                onClick: r,
                class: "clear-icon"
            }, null) ]) : f(V("ssu-input-text"), E({
                "model-value": e.modelValue.name || ""
            }, i, {
                readonly: !0,
                placeholder: ""
            }), {
                suffix: () => e.modelValue.name ? f(V("ssu-icon"), {
                    "icon-name": "iconx",
                    size: "14",
                    onClick: r,
                    class: "clear-icon"
                }, null) : ""
            }), f(V("ssu-button"), {
                type: "primary",
                plain: !0,
                "suffix-css-icon": "iconjiant1",
                onClick: () => t.value = !0
            }, {
                default: () => [ D("选择") ]
            }) ]), t.value && f(Qr, {
                visible: t.value,
                onChange: o
            }, null) ]);
        };
    }
});

const {propsFormMixin: Kr} = z;

var Xr = e({
    name: "OlMemberPropsForm",
    mixins: [ Kr ],
    components: {
        MemberOptional: Yr
    },
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), l = s((() => e.runtimePlatform === fe.SLM)), {data: o, formRef: n} = fa(e, a), {convertOptionListToObj: r, convertObjToOptionList: u} = H, d = s((() => i(u({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), p = s({
            get: () => Ja.get(e, "metaData.props.range.rawValue", []),
            set(a) {
                Ja.set(e, "metaData.props.range", {
                    key: "range",
                    rawValue: a,
                    type: "raw"
                });
            }
        }), m = s((() => {
            const a = [];
            return new ge(e.pageMetaData.widgets).each(((e, t, l, o) => {
                e.code === K.DEPARTMENT && a.push(e);
            })), a;
        }));
        return () => f(V("el-form"), {
            ref: n,
            model: o,
            "label-position": "top",
            class: "ol-member-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                appData: e.appData
            }, null), f(V("mode-setter"), {
                metaData: e.metaData,
                title: "可选数量",
                optionList: me.options
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("CustomFormItem"), {
                label: "可选人员范围",
                setter: !0,
                questionText: "为空时默认全部成员可选"
            }, {
                default: () => [ f(Yr, {
                    modelValue: p.value,
                    "onUpdate:modelValue": e => p.value = e,
                    deptList: m.value,
                    isRunTime: l.value
                }, null) ]
            }), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: d.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = r(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Jr, metaDataMixin: Zr} = z;

var es = e({
    name: "OlMemberTableFormatter",
    mixins: [ Jr, Zr ],
    setup(e) {
        const a = le(), t = ne("member"), l = i([]), o = s((() => t.value.toList())), n = s((() => {
            const a = Ra(e.fieldValue);
            return a.length ? a.map((e => {
                const a = o.value.find((a => a.id === e));
                return l.value.includes(e) || a || l.value.push(e), null == a ? void 0 : a.name;
            })).join(ie) : Y;
        }));
        return P((() => o.value), (() => {
            const a = Ra(e.fieldValue);
            a.length && (l.value = Ja.uniq(l.value.concat(Ja.cloneDeep(a))));
        }), {
            immediate: !0
        }), P((() => l.value), Ja.debounce((e => {
            if (null == e || !e.length) return;
            let i = [];
            const n = o.value.map((({id: e}) => e));
            null == e || e.forEach((e => {
                if (!n.includes(e)) {
                    i.push(e);
                    const a = {
                        id: e
                    };
                    t.value.addCache({
                        cacheKey: e,
                        ...a
                    });
                }
            })), i.length && a.admin.getBatchUser({
                userIds: i
            }).then((e => {
                (e || []).forEach((e => {
                    t.value.addCache({
                        cacheKey: e.id,
                        ...e
                    });
                })), l.value = [];
            }));
        }), 200), {
            immediate: !0,
            deep: !0
        }), () => f("label", {
            class: "ol-member-table-formatter"
        }, [ n.value ]);
    }
}), as = {
    code: K.MEMBER,
    name: "成员",
    group: X.NORMAL,
    icon: "chengyuan",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlMember",
    mobileWidget: "OlMemberMobile",
    propsForm: "OlMemberPropsForm",
    tableFormatter: "OlMemberTableFormatter",
    props: {
        placeholder: "请选择",
        mode: "single",
        range: []
    },
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.MEMBER ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

rr.meta = as, rr.PropsForm = Xr, rr.Mobile = yr, rr.install = e => {
    e.component(rr.name, rr), e.component(Xr.name, Xr), e.component(yr.name, yr), e.component(es.name, es);
}, rr.widgetMap = {
    [yr.name]: yr,
    [rr.name]: rr,
    [Xr.name]: Xr,
    [es.name]: es
};

const {formComponentMixin: ts} = z;

var ls = e({
    name: "OlMultipleSelect",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        placeholder: {
            type: String,
            default: ""
        },
        optionsType: {
            type: String,
            default: ""
        },
        filterable: {
            type: Boolean,
            default: !0
        },
        remoteUrl: {
            type: String,
            default: ""
        },
        remoteUrlRequestParams: {
            type: Array,
            default: () => []
        },
        allowParam: {
            type: Boolean,
            default: !1
        },
        optionSetValue: {
            type: String,
            default: ""
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        publicReleaseShareCode: {
            type: String,
            default: ""
        }
    },
    mixins: [ ts ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), {curOptions: o, loading: i} = ga(e), n = s((() => ({
            ...e,
            ...a
        }))), r = s({
            get: () => l.value || e.metaData.defaultValue,
            set: e => {
                if (!Array.isArray(e)) return void d(null);
                const a = e.filter((e => !!o.value.find((a => a.value === e))));
                d(a);
            }
        }), u = s((() => e.enableColor)), d = e => {
            t("update:modelValue", e);
        };
        return {
            dataValue: r,
            curOptions: o,
            bindData: n,
            isEnableColor: u,
            loading: i
        };
    }
});

ls.render = function(e, i, n, r, s, u) {
    const d = V("ssu-option"), p = V("ssu-select"), m = a("loading");
    return o((t(), l("div", null, [ f(p, E({
        class: "ol-multiple-select"
    }, e.bindData, {
        modelValue: e.dataValue,
        "onUpdate:modelValue": i[0] || (i[0] = a => e.dataValue = a),
        multiple: !0,
        placeholder: e.placeholder || " ",
        "option-data": e.curOptions,
        clearable: ""
    }), {
        default: v((() => [ (t(!0), l(R, null, x(e.curOptions, (a => (t(), L(d, {
            key: a.value,
            value: a.value
        }, {
            default: v((() => [ y("label", {
                style: j({
                    color: e.isEnableColor && a.color || void 0
                })
            }, O(a.label), 5) ])),
            _: 2
        }, 1032, [ "value" ])))), 128)) ])),
        _: 1
    }, 16, [ "modelValue", "placeholder", "option-data" ]) ])), [ [ m, e.loading ] ]);
};

const os = e({
    name: "CheckboxPickerMobile",
    props: {
        checked: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "update:checked" ],
    setup(e, {emit: a}) {
        const t = i(), l = s((() => e.options || [])), o = (a = "") => e.enableColor ? a : "";
        return P((() => e.checked), (e => {
            t.value = e;
        }), {
            immediate: !0
        }), P((() => t.value), (e => {
            a("update:checked", e);
        })), () => f(rt, {
            modelValue: t.value,
            "onUpdate:modelValue": e => t.value = e
        }, {
            default: () => [ !!l.value.length && l.value.map((e => f("div", {
                class: "multiple-select-checkbox-mobile"
            }, [ f("div", {
                class: "checkbox-text",
                style: {
                    color: o(e.color)
                }
            }, [ e.label ]), f(st, {
                name: e.value,
                "checked-color": "#167DFA"
            }, null) ]))) ]
        });
    }
}), {formComponentMixin: is} = z;

var ns = e({
    name: "OlMultipleSelectMobile",
    props: {
        modelValue: {
            type: Array,
            default: ""
        },
        options: {
            type: Array,
            default: []
        },
        enableColor: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ is ],
    components: {
        SelectMobile: yi,
        PopupHeaderMobile: Yi
    },
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a
        }))), n = i(l.value || e.metaData.defaultValue), r = i(l.value || e.metaData.defaultValue), u = i(!1), d = i(0), p = s((() => e.options || [])), m = s((() => e.options.filter((e => n.value.includes(e.value))) || [])), c = s((() => e.enableColor)), v = () => {
            r.value = n.value, t("update:modelValue", r.value), g();
        }, g = () => {
            u.value = !1, b();
        }, b = () => {
            d.value = 0, n.value = l.value || e.metaData.defaultValue;
        }, h = s((() => e.disabled)), y = s((() => e.disabled && !e.modelValue));
        return P((() => e.metaData.defaultValue), (e => {
            n.value = l.value || e;
        })), () => f("div", null, [ f(yi, {
            modelValue: r.value,
            "onUpdate:modelValue": e => r.value = e,
            onClick: () => {
                !o.value.disabled && (u.value = !0);
            },
            disabled: h.value,
            isPlaceholder: y.value
        }, null), f(at, {
            show: u.value,
            "onUpdate:show": e => u.value = e,
            position: "bottom",
            round: !0,
            style: {
                height: "80%"
            },
            onClose: b
        }, {
            default: () => [ f(Yi, {
                onCancel: g,
                onConfirm: v
            }, null), f(ot, {
                active: d.value,
                "onUpdate:active": e => d.value = e,
                "line-width": "0",
                "line-height": "0",
                "title-active-color": "#167DFA"
            }, {
                default: () => [ f(it, {
                    title: "可选"
                }, {
                    default: () => [ f(os, {
                        checked: n.value,
                        "onUpdate:checked": e => n.value = e,
                        options: p.value,
                        enableColor: c.value,
                        key: "value1"
                    }, null) ]
                }), f(it, {
                    title: "已选"
                }, {
                    default: () => [ f(os, {
                        checked: n.value,
                        "onUpdate:checked": e => n.value = e,
                        options: m.value,
                        enableColor: c.value,
                        key: "value2"
                    }, null) ]
                }) ]
            }) ]
        }) ]);
    }
});

const {propsFormMixin: rs} = z;

var ss = e({
    name: "OlMultipleSelectPropsForm",
    mixins: [ rs ],
    setup(e, {expose: a}) {
        if (!e.metaData.props.optionsType) {
            const a = be().getMeta("official", e.metaData.code), t = e => {
                let t = {};
                return e.forEach((e => {
                    t[e] = {
                        type: "raw",
                        key: e,
                        rawValue: a.props[e]
                    };
                })), t;
            };
            e.metaData.props = {
                ...e.metaData.props,
                ...t([ "optionsType", "remoteUrl", "allowParam", "remoteUrlRequestParams" ])
            };
        }
        const t = s({
            get: () => e.metaData.props.options.rawValue,
            set(a) {
                e.metaData.props.options.rawValue = a;
            }
        }), l = s((() => e.metaData.validateRuleConfig)), {data: o, formRef: n} = fa(e, a), {convertOptionListToObj: r, convertObjToOptionList: u} = H, d = s((() => i(u({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), p = Da(e.metaData, "optionsType"), m = Da(e.metaData, "remoteUrl"), c = Da(e.metaData, "allowParam"), v = Da(e.metaData, "remoteUrlRequestParams"), g = ba(e.metaData, "optionSetValue", te.PRODUCTION_LINE);
        return P((() => v.value), (e => {}), {
            deep: !0
        }), () => f(V("el-form"), {
            ref: n,
            model: o,
            "label-position": "top",
            class: "ol-multiple-select-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("options-type-setter"), {
                modelValue: p.value,
                "onUpdate:modelValue": e => p.value = e,
                optionList: he.options,
                metaData: e.metaData
            }, null), p.value === oe.REMOTE && f("div", null, [ f(V("DataSourceUrlSetter"), {
                modelValue: m.value,
                "onUpdate:modelValue": e => m.value = e
            }, null), f(V("ssu-checkbox"), {
                modelValue: c.value,
                "onUpdate:modelValue": e => c.value = e
            }, {
                default: () => [ D("对链接目标传参") ]
            }), c.value && f(V("ParamsSetter"), {
                modelValue: v.value,
                "onUpdate:modelValue": e => v.value = e,
                pageMetaData: e.pageMetaData
            }, null) ]), p.value === oe.CUSTOM && f(V("option-setter"), {
                metaData: e.metaData,
                optionList: t.value,
                "onUpdate:optionList": e => t.value = e,
                type: "multiple",
                defaultValue: 1
            }, null), p.value === oe.OPTION_SET && f(V("option-set-setter"), {
                metaData: e.metaData,
                appData: e.appData,
                runtimePlatform: e.runtimePlatform,
                optionSetValue: g.value,
                "onUpdate:optionSetValue": e => g.value = e
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: l.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: d.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = r(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const us = require("color"), {formEntityMixin: ds, metaDataMixin: ps} = z;

var ms = e({
    props: {
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        optionsType: {
            type: String,
            default: ""
        },
        remoteUrl: {
            type: String,
            default: ""
        },
        formatParams: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        }
    },
    name: "OlMultipleSelectTableFormatter",
    mixins: [ ds, ps ],
    setup(e) {
        const {curOptions: a} = ga(e), t = s((() => {
            if (!Array.isArray(e.fieldValue) || !e.fieldValue.length) return [];
            return e.fieldValue.map((e => {
                const t = a.value.find((a => a.value === e));
                return t || {
                    label: e
                };
            })).map((a => e.enableColor && f(V("ssu-tag"), {
                class: "color-tag",
                style: a.color && {
                    border: `1px solid ${a.color}`,
                    color: a.color,
                    "background-color": `${us(a.color).alpha("0.1").toString()}`
                } || void 0
            }, {
                default: () => [ a.label ]
            }) || a.label));
        }));
        return () => f("label", {
            class: "ol-multiple-select-table-formatter"
        }, [ t.value.length > 0 ? e.enableColor && t.value.map((e => e)) || t.value.join(ie) : Y ]);
    }
}), cs = {
    code: "MultipleSelect",
    name: "下拉多选",
    group: X.NORMAL,
    icon: "xialadanxuan",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlMultipleSelect",
    mobileWidget: "OlMultipleSelectMobile",
    propsForm: "OlMultipleSelectPropsForm",
    tableFormatter: "OlMultipleSelectTableFormatter",
    props: {
        options: [ {
            label: "选项一",
            value: "选项一",
            score: "",
            code: "1"
        }, {
            label: "选项二",
            value: "选项二",
            score: "",
            code: "2"
        }, {
            label: "选项三",
            value: "选项三",
            score: "",
            code: "3"
        } ],
        enableColor: !1,
        enableScore: !1,
        placeholder: "请选择",
        optionsType: "custom",
        remoteUrl: "",
        remoteUrlRequestParams: [],
        optionSetValue: te.PRODUCTION_LINE,
        allowParam: !1
    },
    extraValues: [],
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.SINGLE_SELECT, K.MULTIPLE_SELECT, K.RADIO, K.CHECKBOX ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

ls.meta = cs, ls.PropsForm = ss, ls.Mobile = ns, ls.install = e => {
    e.component(ls.name, ls), e.component(ss.name, ss), e.component(ns.name, ns), e.component(ms.name, ms);
}, ls.widgetMap = {
    [ns.name]: ns,
    [ls.name]: ls,
    [ss.name]: ss,
    [ms.name]: ms
};

const {formComponentMixin: vs} = z;

var fs = e({
    name: "OlMultipleText",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ vs ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = i(l.value || e.metaData.defaultValue), n = s((() => e.metaData.validateRuleConfig.find((e => "length" === e.key))?.params?.max)), r = s((() => {
            const a = Ba(e.metaData.props, "defaultRowNum");
            return Number(a || ye);
        })), u = s((() => ({
            ...e,
            ...a,
            maxlength: n.value || 255,
            "show-word-limit": !!n.value
        })));
        return P((() => o.value), (e => {
            t("update:modelValue", e);
        })), P((() => e.metaData.defaultValue), (e => {
            o.value = l.value || e;
        })), {
            data: l,
            defaultRowNum: r,
            bindData: u,
            modelValue: o
        };
    }
});

fs.render = function(e, a, l, o, i, n) {
    const r = V("ssu-input-text");
    return t(), L(r, E({
        modelValue: e.modelValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.modelValue = a)
    }, e.bindData, {
        type: "textarea",
        rows: e.defaultRowNum,
        class: "ol-multiple-text ol-multiple-text-mobile"
    }), null, 16, [ "modelValue", "rows" ]);
};

const {formComponentMixin: gs} = z;

var bs = e({
    name: "OlMultipleTextMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ gs ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: hs} = z;

var ys = e({
    name: "OlMultipleTextPropsForm",
    mixins: [ hs ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-multiple-text-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("default-row-num-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateType: "text",
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Ds, metaDataMixin: Cs} = z;

var ws = e({
    name: "OlMultipleTextTableFormatter",
    mixins: [ Ds, Cs ],
    setup(e) {
        const a = s((() => e.fieldValue || Y));
        return () => e.runtimeEnv === Q.TABLE ? f("label", {
            class: "ol-multiple-text-table-formatter"
        }, [ a.value ]) : f("pre", {
            class: "ol-multiple-text-table-formatter",
            style: {
                "white-space": "break-spaces"
            }
        }, [ a.value ]);
    }
}), Ss = {
    code: K.MULTIPLE_TEXT,
    name: "多行文本",
    group: X.NORMAL,
    icon: "duohangwenben",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlMultipleText",
    propsForm: "OlMultipleTextPropsForm",
    tableFormatter: "OlMultipleTextTableFormatter",
    props: {
        placeholder: "请输入",
        defaultRowNum: ye
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.CONTAINS,
        supportWidgetCodeConfig: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT, mi ],
        supportOperator: {
            [ee.CONTAINS]: ae[ee.CONTAINS],
            [ee.NOT_CONTAINS]: ae[ee.NOT_CONTAINS],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

fs.meta = Ss, fs.PropsForm = ys, fs.Mobile = bs, fs.install = e => {
    e.component(fs.name, fs), e.component(ys.name, ys), e.component(bs.name, bs), e.component(ws.name, ws);
}, fs.widgetMap = {
    [bs.name]: bs,
    [fs.name]: fs,
    [ys.name]: ys,
    [ws.name]: ws
};

const {formComponentMixin: Ls} = z;

var Ts = e({
    name: "OlNumber",
    props: {
        modelValue: {
            type: [ Number, String ]
        }
    },
    emits: [ "update:modelValue" ],
    mixins: [ Ls ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a,
            step: 1 / Math.pow(10, n.value)
        }))), i = s((() => a.showDecimal)), n = s((() => i.value ? a.decimalCount : 0)), r = s({
            get: () => Ja.isNil(l.value) ? e.metaData.defaultValue : l.value,
            set: e => {
                let a = e.replace(/[^\d.-]/g, "");
                if (Ja.isNil(a) || "" === a) return void t("update:modelValue", null);
                if (a = String(a), a.length > 16) {
                    const e = a.split("");
                    e.length = 16, a = e.join("");
                }
                const l = a.includes("."), o = l && a.lastIndexOf(".") === a.length - 1;
                if (!l || !o) return void t("update:modelValue", isNaN(+a) ? null : Ja.floor(+a, n.value));
                const i = a.split("."), r = i[1].split("");
                r.length > n.value && (r.length = n.value, i[1] = r.join("")), a = i.join("."), 
                t("update:modelValue", `${a}${!a.includes(".") && n.value > 0 ? "." : ""}`);
            }
        }), u = s((() => De.options.reduce(((e, t) => {
            let l = "";
            switch (t.value) {
              case "percentUnit":
                l = "%";
                break;

              case "priceUnit":
                l = a.priceUnit;
                break;

              case "customUnit":
                l = a.customUnit;
            }
            return {
                ...e,
                [t.value]: l
            };
        }))[a.formatType]));
        return {
            inputValue: r,
            bindData: o,
            unit: u,
            handleInputBlur: () => {
                t("update:modelValue", isNaN(+r.value) ? null : Ja.floor(+r.value, n.value));
            }
        };
    }
});

const Vs = {
    class: "ol-number ol-number-mobile"
};

Ts.render = function(e, a, o, i, n, r) {
    const s = V("ssu-input-text");
    return t(), l("div", Vs, [ f(s, E(e.bindData, {
        modelValue: e.inputValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.inputValue = a),
        class: [ "priceUnit" === e.bindData.formatType && "padding-unit-left", (e.bindData.customUnit || "percentUnit" === e.bindData.formatType) && "padding-unit-right" ],
        onBlur: e.handleInputBlur
    }), null, 16, [ "modelValue", "class", "onBlur" ]), e.unit ? (t(), l("span", {
        key: 0,
        class: A([ "ol-number-unit", "priceUnit" === e.bindData.formatType && "left-unit", (e.bindData.customUnit || "percentUnit" === e.bindData.formatType) && "right-unit" ])
    }, O(e.unit), 3)) : F("", !0) ]);
};

const {formComponentMixin: Es} = z;

var Is = e({
    name: "OlNumberMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ Es ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: Fs} = z;

var Os = e({
    name: "OlNumberPropsForm",
    mixins: [ Fs ],
    setup(e, {expose: a}) {
        const {convertOptionListToObj: t, convertObjToOptionList: l} = H, o = s((() => e.metaData.validateRuleConfig)), n = s((() => {
            const a = i(l({
                showThousands: e.metaData.props.showThousands.rawValue,
                showDecimal: e.metaData.props.showDecimal.rawValue
            }));
            return i(a.value.map((a => i({
                ...a,
                params: "showDecimal" === a.key && {
                    ...a.params,
                    decimalCount: e.metaData.props.decimalCount.rawValue
                } || void 0
            }).value))).value;
        })), r = s((() => i(Ce.options.map((a => ({
            ...a,
            params: {
                ...a.params,
                decimalCount: "showDecimal" === a.key && e.metaData.props.decimalCount.rawValue || void 0
            }
        })))).value)), {data: u, formRef: d} = fa(e, a), p = s((() => i(l({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: d,
            model: u,
            "label-position": "top",
            class: "ol-number-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                appData: e.appData,
                pageData: e.pageData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateType: "number",
                validateRuleConfigList: o.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("number-format-setter"), {
                metaData: e.metaData,
                numberFormatOptions: r.value,
                numberFormatConfigList: n.value,
                "onUpdate:numberFormatConfigList": (a = []) => {
                    const l = t(a), {showDecimal: o, showThousands: i} = l;
                    let r;
                    var s, u;
                    o && (r = null === (s = n.value.find((e => "showDecimal" === e.key))) || void 0 === s || null === (u = s.params) || void 0 === u ? void 0 : u.decimalCount);
                    e.metaData.props.showDecimal.rawValue = o, e.metaData.props.showThousands.rawValue = i, 
                    e.metaData.props.decimalCount.rawValue = r;
                }
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: p.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const l = t(a, G.options, !0), {editable: o, visible: i, visibleWhenCreate: n} = l;
                    e.metaData.editable = o, e.metaData.visible = i, e.metaData.visibleWhenCreate = n;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Ns, metaDataMixin: Ms} = z;

var Rs = e({
    name: "OlNumberTableFormatter",
    mixins: [ Ns, Ms ],
    props: {
        formatType: {
            type: String,
            default: ""
        },
        priceUnit: {
            type: String,
            default: ""
        },
        customUnit: {
            type: String,
            default: ""
        },
        showThousands: {
            type: Boolean,
            default: !1
        },
        decimalCount: {
            type: Number,
            default: 0
        }
    },
    setup(e) {
        const a = s((() => ({
            [we.DEFAULT.value]: e => e,
            [we.PERCENT_UNIT.value]: e => e + "%",
            [we.PRICE_UNIT.value]: a => e.priceUnit + a,
            [we.CUSTOM_UNIT.value]: a => a + e.customUnit
        }))), t = s((() => {
            let t = "";
            var l, o;
            return Ja.isNil(e.fieldValue) ? (t = Y, t) : (t = e.fieldValue, t = Ja.floor(t, e.decimalCount).toLocaleString("zh-CN", {
                useGrouping: e.showThousands,
                minimumFractionDigits: e.decimalCount
            }), t = (null === (l = (o = a.value)[e.formatType]) || void 0 === l ? void 0 : l.call(o, t)) || t, 
            t);
        }));
        return () => f("label", {
            class: "ol-number-table-formatter"
        }, [ t.value ]);
    }
}), xs = {
    code: K.NUMBER,
    name: "数字",
    group: X.NORMAL,
    icon: "shuzi",
    widgetType: J.FORM,
    dataType: Z.NUMBER,
    widget: "OlNumber",
    propsForm: "OlNumberPropsForm",
    tableFormatter: "OlNumberTableFormatter",
    questionText: "数值最大值为 9007199254740991 (16 位)，如有超出的需求请使用“单行文本”组件替代，否则可能会丢失精度",
    props: {
        placeholder: "请输入数字",
        formatType: we.DEFAULT.value,
        priceUnit: "￥",
        customUnit: "",
        showThousands: !1,
        showDecimal: !1,
        decimalCount: void 0
    },
    defaultValue: void 0,
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.BETWEEN,
        supportWidgetCodeConfig: [ K.NUMBER, K.RATE, K.SUMMARY, vi ],
        supportOperator: {
            [ee.BETWEEN]: ae[ee.BETWEEN],
            [ee.NOT_BETWEEN]: ae[ee.NOT_BETWEEN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.LESS]: ae[ee.LESS],
            [ee.LESS_OR_EQUAL]: ae[ee.LESS_OR_EQUAL],
            [ee.GREATER]: ae[ee.GREATER],
            [ee.GREATER_OR_EQUAL]: ae[ee.GREATER_OR_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Ts.meta = xs, Ts.PropsForm = Os, Ts.Mobile = Is, Ts.install = e => {
    e.component(Ts.name, Ts), e.component(Os.name, Os), e.component(Is.name, Is), e.component(Rs.name, Rs);
}, Ts.widgetMap = {
    [Is.name]: Is,
    [Ts.name]: Ts,
    [Os.name]: Os,
    [Rs.name]: Rs
};

var As = e({
    name: "",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default: () => [ {} ]
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "update:modelValue" ],
    setup(e, a) {
        const t = s({
            get: () => e.modelValue || "",
            set(e) {
                a.emit("update:modelValue", e);
            }
        });
        return {
            modelValueIndex: s((() => e?.options.findIndex((e => e.value === t.value)))),
            changeModelValue: a => {
                e.disabled || (t.value = a.value);
            }
        };
    }
});

const ks = {
    class: "base-steps-container"
}, Ps = [ "onClick" ], _s = {
    key: 1,
    class: "active-icon normal-icon"
}, Us = {
    key: 2,
    class: "unpass-icon normal-icon"
};

As.render = function(e, a, o, i, n, r) {
    const s = V("ssu-icon"), u = V("el-step"), d = V("el-steps");
    return t(), l("div", ks, [ f(d, {
        active: e.modelValueIndex,
        "align-center": "center"
    }, {
        default: v((() => [ (t(!0), l(R, null, x(e.options, ((a, o) => (t(), L(u, {
            class: A(e.disabled ? "disabled-steps-icon" : "active-steps-icon")
        }, {
            icon: v((() => [ y("div", {
                class: "custom-icon",
                onClick: t => e.changeModelValue(a)
            }, [ e.modelValueIndex > o ? (t(), L(s, {
                key: 0,
                "icon-name": "icon-buzhou1",
                size: 16,
                color: "#1B7DFA"
            })) : e.modelValueIndex === o ? (t(), l("div", _s)) : (t(), l("div", Us)) ], 8, Ps) ])),
            title: v((() => [ y("span", {
                class: A([ "step-title", {
                    "step-title-active": e.modelValueIndex === o
                } ])
            }, O(a?.label), 3) ])),
            _: 2
        }, 1032, [ "class" ])))), 256)) ])),
        _: 1
    }, 8, [ "active" ]) ]);
};

const Bs = require("color"), {formComponentMixin: Ws} = z;

var qs = e({
    name: "OlRadio",
    components: {
        BaseSteps: As
    },
    props: {
        modelValue: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        direction: {
            type: String,
            default: "horizontal"
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        selectEnable: {
            type: Boolean,
            default: !0
        },
        formatType: {
            type: String,
            default: "tile"
        },
        optionsType: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        publicReleaseShareCode: {
            type: String,
            default: ""
        }
    },
    mixins: [ Ws ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const {curOptions: l} = ga(e), o = Na(e), i = s((() => e.metaData.fieldName)), n = s((() => ({
            ...e,
            ...a
        }))), r = s({
            get: () => o.value ?? e.metaData.defaultValue,
            set: e => {
                t("update:modelValue", e);
            }
        }), u = s({
            get: () => e.formData[`${i.value}_other`],
            set: e => {
                t(`update:${i.value}_other`, e);
            }
        }), d = s((() => "vertical" === e.direction)), p = s((() => n.value.isMobile)), m = s((() => "tile" === e.formatType));
        return {
            dataValue: r,
            isVerticalLayout: d,
            isTile: m,
            curOptions: l,
            bindData: n,
            isMobile: p,
            Color: Bs,
            otherValue: u,
            onRadioClick: (a, t) => {
                n.value?.disabled || "INPUT" !== a.target.tagName && (a.preventDefault(), r.value === t && e.selectEnable ? (r.value = null, 
                e.metaData.defaultValue = null) : r.value = t);
            }
        };
    }
});

const js = {
    class: "ol-radio ol-radio-mobile singleChoice"
};

qs.render = function(e, a, o, i, n, r) {
    const s = V("ssu-radio"), u = V("ssu-input-text"), d = V("BaseSteps");
    return t(), l("div", js, [ e.isTile ? (t(), l("div", E({
        key: 0
    }, e.bindData, {
        style: {
            display: e.isVerticalLayout ? "flex" : "block",
            "flex-direction": e.isVerticalLayout ? "column" : "row"
        }
    }), [ (t(!0), l(R, null, x(e.curOptions, ((l, o) => (t(), L(s, E(e.bindData, {
        modelValue: e.dataValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.dataValue = a),
        label: l.value,
        key: l.value + o,
        onClick: a => e.onRadioClick(a, l.value)
    }), {
        default: v((() => [ y("div", {
            class: A({
                "color-tag": e.enableColor
            }),
            style: j(e.enableColor && l.color && {
                color: l.color,
                border: `1px solid ${l.color}`,
                "background-color": e.isMobile ? `${e.Color(l.color).alpha("0.1").toString()}` : ""
            } || void 0)
        }, O(l.label), 7) ])),
        _: 2
    }, 1040, [ "modelValue", "label", "onClick" ])))), 128)), "other" === e.dataValue ? (t(), 
    L(u, {
        key: 0,
        modelValue: e.otherValue,
        "onUpdate:modelValue": a[1] || (a[1] = a => e.otherValue = a),
        maxlength: "255",
        type: "text"
    }, null, 8, [ "modelValue" ])) : F("", !0) ], 16)) : (t(), L(d, E({
        key: 1
    }, e.bindData, {
        options: e.curOptions,
        modelValue: e.dataValue,
        "onUpdate:modelValue": a[2] || (a[2] = a => e.dataValue = a)
    }), null, 16, [ "options", "modelValue" ])) ]);
};

const {formComponentMixin: $s} = z;

var zs = e({
    name: "OlRadioMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ $s ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: Gs} = z;

var Hs = e({
    name: "OlRadioPropsForm",
    mixins: [ Gs ],
    setup(e, {expose: a}) {
        var t, l;
        null !== (t = e.metaData.props) && void 0 !== t && t.selectEnable ? null === (l = e.metaData.props) || void 0 === l || l.selectEnable : e.metaData.props.selectEnable = {
            type: "raw",
            key: "selectEnable",
            rawValue: !0
        };
        const n = "tile", r = s((() => e.metaData.validateRuleConfig)), {data: u, formRef: d} = fa(e, a), {convertOptionListToObj: p, convertObjToOptionList: m} = H, c = s({
            get: () => e.metaData.props.options.rawValue,
            set(a) {
                e.metaData.props.options.rawValue = a;
            }
        }), v = s((() => i(m({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), b = ba(e.metaData, "optionsType", oe.CUSTOM), h = ba(e.metaData, "optionSetValue", te.PRODUCTION_LINE);
        return () => {
            var a, t, l, i;
            return f(V("el-form"), {
                ref: d,
                model: u,
                "label-position": "top",
                class: "ol-radio-props-form"
            }, {
                default: () => [ f(V("label-setter"), {
                    metaData: e.metaData
                }, null), f(V("field-name-setter"), {
                    metaData: e.metaData,
                    pageMetaData: e.pageMetaData
                }, null), f(V("options-type-setter"), {
                    modelValue: b.value,
                    "onUpdate:modelValue": e => b.value = e,
                    metaData: e.metaData,
                    optionList: re.options
                }, null), b.value === oe.CUSTOM && f(V("option-setter"), {
                    metaData: e.metaData,
                    optionList: c.value,
                    "onUpdate:optionList": e => c.value = e,
                    type: "single"
                }, null), b.value === oe.OPTION_SET && f(V("option-set-setter"), {
                    metaData: e.metaData,
                    appData: e.appData,
                    runtimePlatform: e.runtimePlatform,
                    optionSetValue: h.value,
                    "onUpdate:optionSetValue": e => h.value = e
                }, null), f(V("validate-rule-setter"), {
                    metaData: e.metaData,
                    validateRuleConfigList: r.value,
                    "onUpdate:validateRuleConfigList": (e = []) => {}
                }, null), f(V("mode-setter"), {
                    metaData: e.metaData,
                    title: "显示方式",
                    modeKey: "formatType",
                    optionList: Se.options
                }, null), o(f(V("option-layout-setter"), {
                    metaData: e.metaData
                }, null), [ [ g, (null === (a = e.metaData) || void 0 === a || null === (t = a.props.formatType) || void 0 === t ? void 0 : t.rawValue) === n ] ]), o(f(V("support-deselection-setter"), {
                    metaData: e.metaData
                }, null), [ [ g, (null === (l = e.metaData) || void 0 === l || null === (i = l.props.formatType) || void 0 === i ? void 0 : i.rawValue) === n ] ]), f(V("field-status-setter"), {
                    metaData: e.metaData,
                    statusConfigList: v.value,
                    "onUpdate:statusConfigList": (a = []) => {
                        const t = p(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                        e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                    }
                }, null) ]
            });
        };
    }
});

const Qs = require("color"), {formEntityMixin: Ys, metaDataMixin: Ks} = z;

var Xs = e({
    props: {
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        formatType: {
            type: String,
            default: "tile"
        },
        optionsType: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        }
    },
    name: "OlRadioTableFormatter",
    mixins: [ Ys, Ks ],
    setup(e) {
        const {curOptions: a} = ga(e), t = s((() => null == e ? void 0 : e.runtimeEnv)), l = s((() => {
            var a, t, l;
            return null !== (a = null !== (t = null === (l = e.metaData) || void 0 === l ? void 0 : l.metaData) && void 0 !== t ? t : e.metaData) && void 0 !== a ? a : {};
        })), o = s((() => "tile" === e.formatType)), i = s((() => {
            if (!e.fieldValue) return [];
            let t = [ e.fieldValue ];
            return t = t.map((e => {
                const t = a.value.find((a => a.value === e));
                return t || {
                    label: e
                };
            })), t.map((a => e.enableColor && f(V("ssu-tag"), {
                class: "color-tag",
                style: a.color && {
                    border: `1px solid ${a.color}`,
                    color: a.color,
                    "background-color": `${Qs(a.color).alpha("0.1").toString()}`
                } || void 0
            }, {
                default: () => [ a.label ]
            }) || a.label));
        }));
        return () => f("label", {
            class: "ol-radio-table-formatter"
        }, [ i.value.length > 0 ? "table" === t.value || o.value ? e.enableColor && i.value.map((e => e)) || i.value.join(ie) : f(As, {
            options: a.value,
            modelValue: e.fieldValue
        }, null) || i.value.join(ie) : Y, o.value && "other" === e.formData[l.value.fieldName] && e.formData[l.value.fieldName + "_other"] && f("p", {
            class: "remark-length"
        }, [ f("span", {
            class: "remark-sign"
        }, [ D("[") ]), f("span", null, [ e.formData[l.value.fieldName + "_other"] ]), f("span", {
            class: "remark-sign"
        }, [ D("]") ]) ]) ]);
    }
}), Js = {
    code: K.RADIO,
    name: "单选",
    group: X.NORMAL,
    icon: "danxuan",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlRadio",
    propsForm: "OlRadioPropsForm",
    tableFormatter: "OlRadioTableFormatter",
    props: {
        options: [ {
            label: "选项一",
            value: "选项一",
            score: "",
            code: "1"
        }, {
            label: "选项二",
            value: "选项二",
            score: "",
            code: "2"
        }, {
            label: "选项三",
            value: "选项三",
            score: "",
            code: "3"
        } ],
        formatType: "tile",
        enableColor: !1,
        enableScore: !1,
        selectEnable: !0,
        direction: "horizontal",
        optionsType: "custom",
        optionSetValue: te.PRODUCTION_LINE
    },
    defaultValue: void 0,
    extraValues: [ {
        fieldName: "_other",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "其他输入框值"
        },
        fieldLevel: 1
    } ],
    requiredProps: [],
    supportBindVariableProps: [ "options" ],
    supportI18NProps: [ "options" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ {
            when: [ ee.EQUAL, ee.NOT_EQUAL ],
            code: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT, mi ]
        }, {
            when: [ ee.IN, ee.NOT_IN ],
            code: [ K.MULTIPLE_SELECT, K.SINGLE_SELECT, K.RADIO, K.CHECKBOX ]
        } ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

qs.meta = Js, qs.PropsForm = Hs, qs.Mobile = zs, qs.install = e => {
    e.component(qs.name, qs), e.component(Hs.name, Hs), e.component(zs.name, zs), e.component(Xs.name, Xs);
}, qs.widgetMap = {
    [zs.name]: zs,
    [qs.name]: qs,
    [Hs.name]: Hs,
    [Xs.name]: Xs
};

const {formComponentMixin: Zs} = z;

var eu = e({
    name: "OlRate",
    props: {
        modelValue: {
            type: Number,
            default: null
        },
        basicRate: {
            type: Number,
            default: null
        },
        formatType: {
            type: String,
            default: null
        },
        rateLabels: {
            type: Array,
            default: () => []
        },
        rateTags: {
            type: Array,
            default: () => []
        },
        rateTips: {
            type: Array,
            default: () => []
        },
        showRateLabels: {
            type: Boolean,
            default: !1
        },
        showRateTags: {
            type: Boolean,
            default: !1
        },
        showRateComment: {
            type: Boolean,
            default: !1
        },
        showRateTips: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Zs ],
    emits: [ "update:modelValue", "*" ],
    setup(e, {attrs: a, emit: t}) {
        const l = i(null), n = i(""), r = i([]), u = be(), d = i(!1), p = u.getMeta("official", e.metaData.code);
        e.formatType || (e.formatType = p.props.formatType, e.metaData = p);
        const m = s({
            get: () => {
                let a = e.modelValue || e.metaData.defaultValue;
                return "default" === e.formatType ? a / (Number(e.basicRate) / 5) : a;
            },
            set: a => {
                e.disabled || ("default" !== e.formatType ? t("update:modelValue", null != a ? a : null) : t("update:modelValue", a ? a * (Number(e.basicRate) / 5) : null));
            }
        }), c = s((() => e.metaData.fieldName)), v = s((() => 10 === Number(e.basicRate))), b = s((() => ({
            ...e,
            ...a
        }))), h = i(!1), y = (a, t) => {
            e.disabled || "INPUT" !== t.target.tagName && (t.preventDefault(), m.value !== a ? m.value = a : m.value = "");
        }, C = () => {
            let a;
            return a = 10 === Number(e.basicRate) && "default" === e.formatType ? 2 * m.value : m.value, 
            a;
        }, w = e => {
            r.value.includes(e) ? ((e = [], a) => {
                if (Array.isArray(e)) {
                    var t = e.indexOf(a);
                    t > -1 && e.splice(t, 1);
                }
            })(r.value, e) : r.value.push(e);
        }, S = () => {
            d.value = !d.value, n.value = d.value ? n.value : "";
        };
        return P((() => n.value), ((a, l) => {
            if (Ja.isEqual(a, l)) return;
            const o = setTimeout((() => {
                const a = e.formData, t = e.metaData.fieldName + "_comment", l = e.metaData.fieldName + "_tags";
                e.rateTags.map((e => {
                    a[l] && a[l].includes(e.label) && (e.select = !0);
                })), r.value = a[l] || [], n.value = a[t], d.value = !!n.value, clearTimeout(o);
            }), 200);
            t(`update:${c.value}_comment`, a);
        }), {}), P((() => m.value), (a => {
            var t;
            r.value = [];
            let o = a || e.metaData.defaultValue;
            l.value = null !== (t = o * (Number(e.basicRate) / 5)) && void 0 !== t ? t : 0;
        }), {}), P((() => r.value), ((e, a) => {
            Ja.isEqual(e, a) || t(`update:${c.value}_tags`, r.value);
        }), {}), () => f("div", {
            class: "ol-rate"
        }, [ "default" === e.formatType ? f("div", null, [ f(V("van-rate"), E(b.value, Le, {
            modelValue: m.value,
            "onUpdate:modelValue": e => m.value = e,
            "allow-half": v.value,
            onChange: () => {
                "default" === e.formatType && (h.value = !0);
            },
            onClick: () => {
                e.disabled || "default" === e.formatType && (h.value || (m.value = null), h.value && (h.value = !1));
            }
        }), null), o(f("div", {
            class: "show-rate-text"
        }, [ `${l.value}${Le.unit}` ]), [ [ g, l.value ] ]) ]) : f("div", {
            class: "gauge"
        }, {
            default: () => (() => {
                let a = [];
                for (let t = 0; t < e.basicRate; t++) a.push(t + 1);
                return a.map((a => f(V("el-row"), {
                    id: "gauge-content",
                    style: {
                        width: 10 === Number(e.basicRate) ? "10%" : "20%"
                    }
                }, {
                    default: () => {
                        return [ f(V("ssu-radio"), E(b.value, Le, {
                            modelValue: m.value,
                            "onUpdate:modelValue": e => m.value = e,
                            label: a,
                            class: {
                                "gauge-radio": !0,
                                "is-checked": a <= m.value
                            },
                            onClick: y.bind(event, a)
                        }), (e = a, "function" == typeof e || "[object Object]" === Object.prototype.toString.call(e) && !N(e) ? a : {
                            default: () => [ a ]
                        })) ];
                        var e;
                    }
                })));
            })()
        }), e.showRateTips && f("div", {
            class: "rate-tips",
            style: {
                width: "default" === e.formatType ? "200px" : "99%"
            }
        }, {
            default: () => e.rateTips && e.rateTips.map((e => f("div", {
                class: "rate-tips-label"
            }, [ e.label ])))
        }), e.showRateLabels && f("div", {
            class: "rate-labels"
        }, {
            default: () => e.rateLabels && e.rateLabels.filter((e => e.value === C())).map((e => e.label ? f("div", {
                class: "rate-labels-label"
            }, [ D("「 "), e.label, D(" 」") ]) : ""))
        }), e.showRateTags && f("div", {
            class: "rate-tags"
        }, {
            default: () => (e.metaData.extraValues || (e.metaData.extraValues = p.extraValues), 
            e.rateTags && e.rateTags.map((e => {
                var a;
                if (C() && C() > e.conditions.rules[0].value[0] - 1 && C() < e.conditions.rules[0].value[1] + 1) return f(V("ssu-tag"), {
                    class: {
                        "rate-tags-label": !0,
                        active: (null === (a = r.value) || void 0 === a ? void 0 : a.length) > 0 && r.value.includes(e.label)
                    },
                    onClick: () => w(e.label)
                }, {
                    default: () => [ e.label ]
                });
            })))
        }), e.showRateComment && f("div", {
            class: "rate-comment"
        }, [ !isNaN(m.value) && m.value > 0 && f(V("ssu-tag"), {
            type: "primary",
            onClick: S,
            style: {
                background: d.value ? "" : "none"
            }
        }, {
            default: () => [ f(V("ssu-icon"), {
                class: "font-style",
                "icon-name": "icon-bianji"
            }, null), D("写评价") ]
        }), d.value && f(V("ssu-input-text"), E(b.value, Le, {
            modelValue: n.value,
            "onUpdate:modelValue": e => n.value = e,
            type: "textarea",
            placeholder: "请输入",
            maxlength: "300"
        }), null) ]) ]);
    }
});

const {formComponentMixin: au} = z, tu = {
    voidColor: "#C1C4CD",
    disabledColor: "#C1C4CD",
    color: "#427CF2",
    textColor: "#90939A",
    unit: "分",
    size: "default"
};

var lu = e({
    name: "OlRateMobile",
    props: {
        modelValue: {
            type: Number,
            default: null
        },
        basicRate: {
            type: Number,
            default: null
        }
    },
    mixins: [ au ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), n = i(null), r = s({
            get: () => {
                let a = l.value || e.metaData.defaultValue;
                return n.value = null != a ? a : 0, a / (e.basicRate / 5);
            },
            set: a => {
                t("update:modelValue", (a || 0) * (e.basicRate / 5));
            }
        }), u = s((() => 10 === e.basicRate)), d = s((() => ({
            ...e,
            ...a
        })));
        return () => f("div", {
            class: "ol-rate-mobile"
        }, [ f(ut, E(d.value, tu, {
            modelValue: r.value,
            "onUpdate:modelValue": e => r.value = e,
            "allow-half": u.value
        }), null), o(f("span", {
            class: "show-rate-text"
        }, [ `${n.value}${tu.unit}` ]), [ [ g, n.value ] ]) ]);
    }
});

const {propsFormMixin: ou} = z;

var iu = e({
    name: "OlRatePropsForm",
    mixins: [ ou ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-rate-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("rate-setter"), {
                metaData: e.metaData
            }, null), f(V("style-setter"), {
                metaData: e.metaData
            }, null), f(V("score-setter"), {
                metaData: e.metaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

function nu(e) {
    return "function" == typeof e || "[object Object]" === Object.prototype.toString.call(e) && !N(e);
}

const {formEntityMixin: ru, metaDataMixin: su} = z;

var uu = e({
    name: "OlRateFormatter",
    mixins: [ ru, su ],
    props: {
        modelValue: {
            type: Number,
            default: null
        },
        basicRate: {
            type: Number,
            default: null
        },
        formatType: {
            type: String,
            default: null
        },
        rateLabels: {
            type: Array,
            default: () => []
        },
        rateTags: {
            type: Array,
            default: () => []
        },
        rateTips: {
            type: Array,
            default: () => []
        },
        showRateLabels: {
            type: Boolean,
            default: !1
        },
        showRateTags: {
            type: Boolean,
            default: !1
        },
        showRateComment: {
            type: Boolean,
            default: !1
        },
        showRateTips: {
            type: Boolean,
            default: !1
        }
    },
    setup(e) {
        const a = s((() => i.value ? Number(e.fieldValue / (Number(e.basicRate) / 5)) : e.fieldValue)), t = s((() => {
            var a, t, l;
            return null !== (a = null !== (t = null === (l = e.metaData) || void 0 === l ? void 0 : l.metaData) && void 0 !== t ? t : e.metaData) && void 0 !== a ? a : {};
        })), l = s((() => Number(e.fieldValue) || Y)), o = s((() => e.runtimeEnv)), i = s((() => "default" === e.formatType)), n = () => e.rateLabels && e.rateLabels.filter((t => t.value === (() => {
            let t;
            return t = 10 === Number(e.basicRate) && i.value ? 2 * a.value : a.value, t;
        })())).map((e => e.label ? f("div", {
            class: "rate-labels-label"
        }, [ D("「 "), e.label, D(" 」") ]) : ""));
        return () => o.value === Q.TABLE ? f("label", {
            class: [ "ol-rate-table-formatter", o.value === Q.TABLE && "ol-rate-table-formatter-list" ]
        }, [ i.value ? f("div", {
            class: "ol-rate-show"
        }, [ f(ut, E(Le, {
            modelValue: a.value,
            count: o.value === Q.TABLE ? Math.ceil(a.value) : 5,
            "allow-half": !0,
            readonly: !0
        }), null), a.value ? f("div", {
            class: "show-rate-text"
        }, [ `${l.value}${Le.unit}` ]) : Y ]) : f("div", {
            class: "show-rate-text"
        }, [ a.value ? `${l.value}${Le.unit} ` : Y ]) ]) : f("label", {
            class: [ "ol-rate-table-formatter" ]
        }, [ i.value ? f("div", {
            class: "ol-rate-show"
        }, [ f(ut, E(Le, {
            modelValue: a.value,
            count: o.value === Q.TABLE ? Math.ceil(a.value) : 5,
            "allow-half": !0,
            readonly: !0
        }), null), a.value ? f("div", {
            class: "show-rate-text"
        }, [ `${l.value}${Le.unit}` ]) : Y ]) : f("div", {
            class: "gauge"
        }, {
            default: () => (() => {
                let t = [];
                for (let a = 0; a < Number(e.basicRate) + 1; a++) t.push(a);
                return t.map((t => f(V("el-row"), {
                    id: "gauge-content",
                    style: {
                        width: 10 === Number(e.basicRate) ? "9%" : "16.66%"
                    }
                }, {
                    default: () => [ f(V("ssu-radio"), {
                        modelValue: a.value,
                        "onUpdate:modelValue": e => a.value = e,
                        class: {
                            "gauge-radio": !0,
                            "is-checked": t <= a.value
                        },
                        label: t
                    }, nu(t) ? t : {
                        default: () => [ t ]
                    }) ]
                })));
            })()
        }), e.showRateTips && f("div", {
            class: "rate-tips",
            style: {
                width: i.value ? "200px" : "99%"
            }
        }, {
            default: () => e.rateTips && e.rateTips.map((e => f("div", {
                class: "rate-tips-label"
            }, [ e.label ])))
        }), e.showRateLabels && f("div", {
            class: "rate-labels"
        }, {
            default: () => n()
        }), e.showRateTags && f("div", {
            class: "rate-tags"
        }, {
            default: () => {
                return null === (a = e.formData[t.value.fieldName + "_tags"]) || void 0 === a || null === (l = a.map) || void 0 === l ? void 0 : l.call(a, (e => f(V("ssu-tag"), {
                    class: "rate-tags-label",
                    type: "primary"
                }, nu(e) ? e : {
                    default: () => [ e ]
                })));
                var a, l;
            }
        }), e.showRateComment && f("div", {
            class: "rate-comment"
        }, [ f("span", null, [ e.formData[t.value.fieldName + "_comment"] ]) ]) ]);
    }
}), du = {
    code: K.RATE,
    name: "评分",
    group: X.NORMAL,
    icon: "pingfen",
    widgetType: J.FORM,
    dataType: Z.NUMBER,
    widget: "OlRate",
    propsForm: "OlRatePropsForm",
    tableFormatter: "OlRateFormatter",
    props: {
        basicRate: 5,
        formatType: "default",
        rateLabels: [],
        showRateLabels: !1,
        rateTags: [],
        showRateTags: !1,
        showRateComment: !1,
        rateTips: [ {
            value: "left",
            label: ""
        }, {
            value: "center",
            label: ""
        }, {
            value: "right",
            label: ""
        } ],
        showRateTips: !1
    },
    defaultValue: null,
    extraValues: [ {
        fieldName: "_tags",
        dataType: "array",
        name: {
            type: "raw",
            key: "name",
            rawValue: "评分标签列表"
        },
        fieldLevel: 1
    }, {
        fieldName: "_comment",
        dataType: "string",
        name: {
            type: "raw",
            key: "name",
            rawValue: "评分评论"
        },
        fieldLevel: 1
    } ],
    requiredProps: [],
    supportBindVariableProps: [ "basicRate" ],
    supportI18NProps: [ "basicRate" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.BETWEEN,
        supportWidgetCodeConfig: [ K.NUMBER, K.RATE, K.SUMMARY, vi ],
        supportOperator: {
            [ee.BETWEEN]: ae[ee.BETWEEN],
            [ee.NOT_BETWEEN]: ae[ee.NOT_BETWEEN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.LESS]: ae[ee.LESS],
            [ee.LESS_OR_EQUAL]: ae[ee.LESS_OR_EQUAL],
            [ee.GREATER]: ae[ee.GREATER],
            [ee.GREATER_OR_EQUAL]: ae[ee.GREATER_OR_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

eu.meta = du, eu.PropsForm = iu, eu.Mobile = lu, eu.install = e => {
    e.component(eu.name, eu), e.component(iu.name, iu), e.component(lu.name, lu), e.component(uu.name, uu);
}, eu.widgetMap = {
    [lu.name]: lu,
    [eu.name]: eu,
    [iu.name]: iu,
    [uu.name]: uu
};

const pu = "http://localhost:9999/messages", mu = mt.create();

mu.interceptors.response.use((e => e.data));

const cu = Te({
    getList(e) {
        const a = {};
        return e && (a.value_like = e), mu.get(pu, {
            params: a
        });
    },
    getDetail: e => mu.get(`${pu}/${e}`),
    destroy: e => mu.delete(`${pu}/${e}`),
    update: (e, a) => mu.put(`${pu}/${e}`, a),
    create: e => mu.post(`${pu}`, e)
}, "id", "i18nManager");

window.requestIdleCallback = window.requestIdleCallback || function(e) {
    var a = Date.now();
    return setTimeout((function() {
        e({
            didTimeout: !1,
            timeRemaining: function() {
                return Math.max(0, 50 - (Date.now() - a));
            }
        });
    }), 1);
}, window.cancelIdleCallback = window.cancelIdleCallback || function(e) {
    clearTimeout(e);
};

let vu = [], fu = [];

const gu = [ K.GROUP, K.TAB, K.GRID, K.RELATION_FORM, K.RELATION_FORM_QUERY, K.SUB_FORM, K.REMARK, K.WEB_VIEW ], bu = Ve((({expose: e, props: a}) => {
    const t = i(), l = i({});
    let o = {}, n = {}, r = {};
    const d = i({}), p = i({}), m = i(), c = i(), v = be(), {conditionRulesAdaptor: f, checkConditionRulesFieldType: g} = wa(), h = s((() => a.widgets)), y = s((() => Ua(a.allWidgets) ? a.allWidgets : h.value)), D = s((() => [ Q.CREATE ].includes(a.runtimeEnv))), C = s((() => [ Q.DETAIL ].includes(a.runtimeEnv))), S = s((() => a.readOnly)), L = Ee(), T = Sa();
    T.on("fetch-form-data", ((e = {}) => {
        requestIdleCallback((() => {
            var a;
            const {appId: t, formId: l, condition: o, cacheGroup: i, formValue: n} = e;
            if (!t || !l) return;
            const r = g(null == o ? void 0 : o.rules) ? {
                condition: Ie.AND,
                ...o,
                rules: f(null == o ? void 0 : o.rules, Wa(n))
            } : {
                condition: Ie.AND,
                ...o
            };
            null == x || null === (a = x.form) || void 0 === a || a.getListWithoutScene({
                appId: t,
                modelCode: l,
                pageSize: 100,
                pageNum: 1,
                filter: null != o && o.rules ? r : void 0
            }).then((e => {
                var a, t, l;
                if (null == e || null === (a = e.result) || void 0 === a || null === (t = a.forEach) || void 0 === t || t.call(a, (e => {
                    var a, t;
                    null == i || null === (a = i.value) || void 0 === a || null === (t = a.addCache) || void 0 === t || t.call(a, {
                        ...e
                    });
                })), null != e && null !== (l = e.result) && void 0 !== l && l.length) {
                    if (C.value) return;
                    requestIdleCallback((() => {
                        Y();
                    }));
                }
            }));
        }));
    }));
    const V = s((() => new Fe({
        widgets: y.value,
        libraryManager: v,
        eventBus: T,
        cacheManager: L
    }))), E = i(qa()), I = s((() => new Oe({
        messages: [],
        language: "zh-cn",
        widgets: a.widgets,
        libManager: v
    }))), F = i({}), O = s((() => a.widgets)), N = s((() => ({
        ...a.variables,
        currentRecordId: l.value.id,
        field: l.value
    }))), M = s((() => {
        var e, a;
        return null === (e = V.value) || void 0 === e || null === (a = e.calcFormValue) || void 0 === a ? void 0 : a.call(e, l.value);
    })), R = i({}), x = le();
    let A = new Ne({
        sdk: x,
        widgets: y.value,
        libraryManager: v,
        isPublicRelease: a.isPublicRelease,
        processNode: a.processNode,
        runtimeEnv: a.runtimeEnv
    });
    const k = i([]), U = e => m.value && m.value.clearValidate(e), W = (e, a = {}) => {
        Object.keys(l.value).forEach((t => {
            null != a && a.exceptWithoutData && (Ja.isNil(l.value[t]) || (Ja.isArray(l.value[t]) || Ja.isString(l.value[t])) && 0 === l.value[t].length) || (r[t] = e);
        }));
    }, q = () => {
        var e, a;
        l.value = null === (e = V.value) || void 0 === e || null === (a = e.calcFormValue) || void 0 === a ? void 0 : a.call(e);
    };
    P((() => a.disabled), (e => {
        Object.keys(p.value).forEach((a => {
            p[a] && (p[a].editable = !e);
        }));
    })), e({
        clearValidate: U,
        validate: () => {
            const e = Object.values(_(F)).reduce(((e, a) => {
                if (a && a.getRef && Ja.isFunction(a.getRef)) {
                    const t = a.getRef();
                    t.value && t.value.validate && Ja.isFunction(t.value.validate) && e.push(t.value.validate());
                }
                return e;
            }), []);
            return Promise.all([ ...e, m.value.validate() ]);
        },
        setFormValue: (e = {}, t = {}) => {
            const o = I.value.getFormInitialValue();
            let i;
            i = !D.value || null != t && t.force ? Ja.merge(o, e) : Ja.merge(o, M.value, e), 
            l.value = i, [ Q.EDIT, Q.PROCESS_AUDIT, Q.DETAIL ].includes(a.runtimeEnv) ? W(!0) : D.value && W(!0, {
                exceptWithoutData: !0
            });
        },
        getFormValue: (e = !0) => e ? l.value : Wa(l.value),
        resetFormValue: q,
        refreshRender: () => {
            var e, a;
            null === (e = c.value) || void 0 === e || null === (a = e.refreshRender) || void 0 === a || a.call(e);
        }
    });
    const j = P((() => y.value), (e => {
        const t = setTimeout((() => {
            var e, o;
            const i = I.value.getWidgetStatus(a.runtimeEnv);
            p.value = Ja.cloneDeep(i), d.value = u(Object.assign(I.value.getFormRules(l.value), d.value)), 
            null === (e = A) || void 0 === e || null === (o = e.init) || void 0 === o || o.call(e, {
                statusRef: p,
                rulesRef: d,
                statusPriority: a.statusPriority
            }), fu.push(t);
        }), 200);
    }), {
        deep: !0,
        immediate: !0
    });
    vu.push(j);
    const $ = () => {
        setTimeout((() => {
            const e = P((() => l.value), Ja.debounce(((e, a) => {
                var t, l, o;
                null === (t = A) || void 0 === t || null === (l = t.dispatch(e)) || void 0 === l || null === (o = l.then) || void 0 === o || o.call(l, (() => {
                    requestIdleCallback(Ja.debounce((() => {
                        var e;
                        null !== (e = d.value) && void 0 !== e && e.length && (d.value = Ja.cloneDeep(d.value), 
                        b((() => {
                            U();
                        })));
                    }), 20));
                }));
            }), 0), {
                deep: !0,
                immediate: !0
            });
            vu.push(e);
        }), 600);
    }, z = () => {
        var e;
        let t = null;
        const o = {
            appId: a.appId,
            modelCode: a.pageCode
        };
        if (a.runtimeEnv === Q.CREATE) t = x.process.getStartFormSubPermission(o); else if (a.runtimeEnv === Q.PROCESS_AUDIT) {
            var i;
            if (Re.includes(null === (i = a.variables) || void 0 === i ? void 0 : i.sourceType)) {
                var n, r;
                t = x.process.getBacklogSubPermission({
                    ...o,
                    actId: null === (n = a.variables) || void 0 === n ? void 0 : n.actId,
                    taskInstanceId: null === (r = a.variables) || void 0 === r ? void 0 : r.taskInstanceId
                });
            } else {
                var s;
                if (null === (s = l.value) || void 0 === s || !s.id) return;
                t = x.process.getListSubPermission({
                    ...o,
                    businessId: l.value.id
                });
            }
        }
        null === (e = t) || void 0 === e || e.then((e => {
            e.visitAll || (R.value = e.childPropertiesMap);
        }));
    };
    P((() => d.value), Ja.debounce((e => {
        Ja.isEqual(e, n) || (n = Ja.cloneDeep(e), d.value = Ja.cloneDeep(e));
    }), 500), {
        deep: !0
    }), w((async () => {
        q(), d.value = u(I.value.getFormRules(l.value)), p.value = Ja.cloneDeep(I.value.getWidgetStatus(a.runtimeEnv)), 
        b((() => {
            U();
        })), a.isProcessPage && ![ Me.BUTTON_FORM, Me.PROCESS_BUTTON_FORM ].includes(a.statusPriority) && z(), 
        await ve.fetchDeptData(x), (a.isPublicRelease ? x.metaData.getOpenFormRuleByShareCode({
            shareCode: a.publicReleaseShareCode
        }) : x.metaData.getBusinessRules(a.appId, {
            params: {
                pageCode: a.pageCode
            }
        })).then((e => {
            k.value = e, (() => {
                if (a.isProcessPage && a.runtimeEnv !== Q.EDIT) {
                    const e = P((() => a.processNode), (e => {
                        var t, l;
                        e && !Ja.isEmpty(e) && (null === (t = A) || void 0 === t || null === (l = t.init) || void 0 === l || l.call(t, {
                            businessRuleList: k.value,
                            processNode: a.processNode,
                            statusPriority: a.statusPriority
                        }), $());
                    }), {
                        immediate: !0,
                        deep: !0
                    });
                    vu.push(e);
                } else {
                    var e, t;
                    null === (e = A) || void 0 === e || null === (t = e.init) || void 0 === t || t.call(e, {
                        businessRuleList: k.value,
                        statusPriority: a.statusPriority
                    }), $();
                }
            })();
        })), [ Q.CREATE, Q.EDIT, Q.PROCESS_AUDIT ].includes(a.runtimeEnv) || W(!0), setTimeout((() => {
            C.value || Y();
        }), 300);
    })), B((() => {
        F.value = null, d.value = null, vu.forEach((e => e())), fu.forEach((e => clearTimeout(e))), 
        vu = [], fu = [], l.value = null, p.value = null, A = null, r = {}, o = {}, n = {};
    }));
    const G = (e = {}, a, t) => {
        Ja.isObject(e) && b((() => {
            e[a] = t;
        }));
    }, H = i(!0), Y = Ja.debounce((() => {
        requestIdleCallback((() => {
            H.value ? (Object.keys(M.value).forEach((e => {
                r[e] || G(l.value, e, M.value[e]);
            })), H.value = !1) : Ja.isEqual(M.value, t.value) || Object.keys(M.value).forEach((e => {
                Ja.isEqual(Ja.get(M, `value[${e}]`), Ja.get(l.value, `value[${e}]`)) || r[e] || G(l.value, e, M.value[e]);
            })), t.value = M.value;
        }));
    }), 10), K = P((() => l.value), Ja.debounce((e => {
        Ja.isEqual(e, o) || (b((() => {
            U();
        })), o = Ja.cloneDeep(e), d.value = u(Object.assign(I.value.getFormRules(l.value), d.value)), 
        setTimeout((() => {
            C.value || Y();
        }), 100));
    }), 100), {
        deep: !0
    });
    vu.push(K);
    const X = P((() => l.value.id), Ja.debounce((e => {
        e && a.isProcessPage && ![ Me.BUTTON_FORM, Me.PROCESS_BUTTON_FORM ].includes(a.statusPriority) && z();
    }), 50));
    return vu.push(X), {
        refreshKey: E,
        readOnly: S,
        isMobile: s((() => a.isMobile)),
        runtimeEnv: s((() => a.runtimeEnv)),
        isPublicRelease: s((() => a.isPublicRelease)),
        publicReleaseShareCode: s((() => a.publicReleaseShareCode)),
        widgetStatus: p,
        formRef: m,
        nestedRenderRef: c,
        pageEngine: I,
        renderList: O,
        formRules: d,
        formValue: l,
        calcShowLabel: e => {
            const a = v.getMeta(e.libInfoCode, e.code);
            return Ja.get(a, [ "renderConfig", "showLabel" ], !0);
        },
        getComponentCode: e => v.getComponentCode(e.libInfoCode, e.code, a.isMobile ? "mobile" : "pc"),
        calcWidgetWidth: (e, t) => {
            let l = "100%";
            return a.isMobile || gu.includes(e.code) || 0 !== t || (l = "50%"), l;
        },
        props: a,
        widgetRefs: F,
        runtimeVariables: N,
        subFormPermissionMap: R,
        calcFormValue: Y,
        onFormValueInput: (e, a) => {
            var t, o, i, n, s;
            l.value[a] = e, r[a] = !0;
            const u = null === (t = V.value) || void 0 === t || null === (o = t.dependenceGraph) || void 0 === o || null === (i = o.reverseMap) || void 0 === i || null === (n = i.get) || void 0 === n ? void 0 : n.call(i, a);
            null == u || null === (s = u.forEach) || void 0 === s || s.call(u, (e => {
                var a, t;
                const l = V.value.formWidgets.find((a => a.fieldName === e));
                r[e] = !1, null == l || null === (a = l.extraValues) || void 0 === a || null === (t = a.forEach) || void 0 === t || t.call(a, (a => {
                    r[e + a.fieldName] = !1;
                }));
            }));
        }
    };
}));

var hu = e({
    name: "CustomFormItem",
    props: {
        showLabel: {
            type: Boolean,
            default: !0
        },
        setter: {
            type: Boolean,
            default: !1
        },
        helpText: String,
        label: String,
        questionText: String,
        isMobile: {
            type: Boolean,
            default: !1
        },
        inContainer: {
            type: Boolean,
            default: !1
        },
        labelTextAlign: {
            type: String,
            default: "left"
        }
    },
    setup: (e, {attrs: a}) => ({
        elFormItemProps: s((() => ({
            helpText: e.helpText,
            questionText: e.questionText,
            ...a
        })))
    })
});

const yu = {
    key: 0,
    class: "custom-form-item-label"
}, Du = {
    style: {
        cursor: "pointer"
    }
}, Cu = {
    key: 2,
    class: "custom-form-item-label-right"
}, wu = {
    class: "custom-form-item-ctrl"
}, Su = {
    key: 0,
    class: "custom-form-item-help-text"
};

hu.render = function(e, a, o, i, n, r) {
    const s = V("syc-icon"), u = V("el-tooltip"), d = V("el-form-item");
    return t(), L(d, E({
        class: [ "custom-form-item", [ `label-text-align-${e.labelTextAlign}`, {
            "in-container": e.inContainer,
            "hidden-label": !e.showLabel
        } ] ]
    }, e.elFormItemProps), {
        label: v((() => [ e.showLabel ? (t(), l("span", yu, [ y("span", {
            style: j({
                fontWeight: e.setter ? "bold" : "400",
                color: e.setter ? "#303133" : "#616266"
            })
        }, O(e.label), 5) ])) : F("", !0), e.questionText ? (t(), L(u, {
            key: 1,
            content: e.questionText,
            placement: "top",
            "popper-class": "opacity"
        }, {
            default: v((() => [ y("label", Du, [ f(s, {
                type: "designer/bangzhuzhongxin",
                width: 16,
                height: 16,
                style: {
                    marginLeft: "6px",
                    display: "block"
                },
                color: "#1B7DFA"
            }) ]) ])),
            _: 1
        }, 8, [ "content" ])) : F("", !0), e.$slots.labelRight ? (t(), l("div", Cu, [ W(e.$slots, "labelRight") ])) : F("", !0) ])),
        default: v((() => [ y("div", wu, [ W(e.$slots, "default") ]), e.helpText ? (t(), 
        l("p", Su, O(e.helpText), 1)) : F("", !0) ])),
        _: 3
    }, 16, [ "class" ]);
};

const Lu = e({
    name: "RenderComponent",
    props: {
        widgetMap: {
            type: Object,
            default: () => ({})
        },
        code: {
            type: String
        }
    },
    setup(e, {attrs: a, slots: t, expose: l}) {
        const o = i(), n = s((() => ({
            ...a,
            ...Ja.omit(e, "widgetMap", "code"),
            ref: o
        }))), r = s((() => e.code in e.widgetMap ? e.widgetMap[e.code] : e.code));
        return l({
            getRef: () => o
        }), () => {
            const e = r.value;
            try {
                return m(e, n.value, t);
            } catch (e) {
                return f(V("el-alert"), null, {
                    default: () => [ e.message ]
                });
            }
        };
    }
});

function Tu(e) {
    return "function" == typeof e || "[object Object]" === Object.prototype.toString.call(e) && !N(e);
}

const Vu = (e = [], a = {}) => e.filter((e => {
    var t;
    if (e.widgetType === J.CONTAINER) {
        if (e.code === K.GRID) {
            const t = e.children.reduce(((e, a) => (e.concat(a), e.concat(a))), []);
            return t.filter((e => {
                var t;
                return e.widgetType === J.CONTAINER ? Vu(e.children[0], a) : null === (t = a[e.id]) || void 0 === t ? void 0 : t.visible;
            })).length > 0;
        }
        return Vu(e.children[0], a);
    }
    return null === (t = a[e.id]) || void 0 === t ? void 0 : t.visible;
})).length > 0, Eu = e({
    name: "NestedRender",
    props: {
        widgets: {
            type: Array,
            default: () => []
        },
        depth: {
            type: Number,
            default: 0
        }
    },
    emits: [ "render-complete" ],
    setup(e, {expose: t, emit: l}) {
        const {getExtraValueUpdateWatcher: n} = La(), r = s((() => 0 === e.depth)), {refreshKey: u, formValue: d, formRules: p, formRef: m, widgetStatus: c, runtimeEnv: v, isPublicRelease: b, publicReleaseShareCode: h, readOnly: y, isMobile: D, getComponentCode: C, calcShowLabel: S, calcWidgetWidth: L, widgetRefs: T, runtimeVariables: I, props: F, subFormPermissionMap: O, onFormValueInput: N, pageEngine: M} = bu.useInject(), x = i(!1), A = i({}), k = i({}), _ = i(!1), U = i([]), W = (e = []) => {
            if (!Array.isArray(e)) return;
            r.value && (x.value = !1, A.value = {}, k.value = {}, _.value = !0);
            const a = Ja.cloneDeep(e);
            U.value = [];
            const t = Ja.chunk(a, 20);
            for (let e = 0; e < t.length; e++) {
                const a = t[e];
                for (let e = 0; e < a.length; e++) {
                    var l;
                    const t = a[e];
                    null != t && null !== (l = t.children) && void 0 !== l && l.length && (A.value[t.id] = !0);
                }
                requestIdleCallback((() => {
                    U.value.push(...a), e === t.length - 1 && (x.value = !0);
                }));
            }
        };
        w((() => {
            W(e.widgets), P((() => [ x.value, A.value, k.value ]), Ja.debounce((() => {
                if (!x.value) return;
                let e = !0;
                Object.keys(A.value).forEach((a => {
                    k.value[a] || (e = !1);
                })), e && (setTimeout((() => {
                    r.value && e && (_.value = !1);
                }), 200), l("render-complete", e));
            }), 10), {
                deep: !0
            });
        })), B((() => {
            x.value = !1, A.value = {}, k.value = {}, _.value = !1, U.value = [];
        })), t({
            refreshRender: () => {
                setTimeout((() => {
                    W(e.widgets);
                }), 100);
            }
        });
        const q = () => U.value.map((a => {
            const t = M.value.toRenderItem(a), {libWidgetMap: l, defineMetaData: o = {}} = t, {child: i} = o, s = c.value[t.id];
            if (null == s || !s.visible) return k.value[t.id] = !0, null;
            if (t.widgetType === J.CONTAINER) {
                if (t.code === K.GROUP) {
                    if (!Vu(t.children[0], c.value)) return k.value[t.id] = !0, null;
                }
                return (() => {
                    let a;
                    return f(Lu, E({
                        widgetMap: l,
                        code: C(t),
                        metaData: t,
                        runtimeEnv: v.value,
                        runtimePlatform: F.runtimePlatform,
                        isMobile: D.value,
                        isPublicRelease: b.value,
                        publicReleaseShareCode: h.value,
                        inContainer: !r.value
                    }, t.props, {
                        ref: e => {
                            T.value[t.id] = e;
                        },
                        variables: I.value,
                        key: t.id,
                        pageMetaData: F.allWidgets
                    }), Tu(a = t.children.map(((a, o) => (null != a && a.length || (k.value[t.id] = !0), 
                    f(Lu, {
                        widgetMap: l,
                        code: i,
                        runtimeEnv: v.value,
                        runtimePlatform: F.runtimePlatform,
                        index: o,
                        parentMetaData: t,
                        isMobile: D.value,
                        isPublicRelease: b.value,
                        publicReleaseShareCode: h.value,
                        parentProps: t.props,
                        formData: d.value,
                        pageMetaData: F.allWidgets
                    }, {
                        default: () => [ f(Eu, {
                            widgets: a,
                            depth: e.depth + 1,
                            onRenderComplete: e => {
                                k.value[t.id] = e;
                            }
                        }, null) ]
                    }))))) ? a : {
                        default: () => [ a ]
                    });
                })();
            }
            return f(hu, {
                label: [ J.CONTAINER, J.LAYOUT ].includes(t.widgetType) ? void 0 : t.name,
                prop: t.fieldName,
                inContainer: !r.value,
                isMobile: D.value,
                style: {
                    width: L(t, e.depth)
                },
                showLabel: S(t)
            }, {
                default: () => [ y.value ? f(Lu, E({
                    variables: I.value,
                    key: t.id,
                    runtimePlatform: F.runtimePlatform,
                    runtimeEnv: v.value,
                    formEntityId: null === (u = d.value) || void 0 === u ? void 0 : u.id,
                    formData: d.value,
                    fieldValue: d.value[t.fieldName],
                    widgetMap: l,
                    isMobile: D.value,
                    isPublicRelease: b.value,
                    publicReleaseShareCode: h.value,
                    readOnly: y.value,
                    code: (null == o ? void 0 : o.tableFormatter) || (null == o ? void 0 : o.widget),
                    defineMetaData: o,
                    metaData: t,
                    modelValue: d.value[t.fieldName],
                    "onUpdate:modelValue": e => d.value[t.fieldName] = e,
                    label: t.name,
                    appId: F.appId,
                    pageCode: F.pageCode
                }, t.props, {
                    ref: e => {
                        T.value[t.id] = e;
                    },
                    formRules: p.value[t.fieldName],
                    pageMetaData: F.allWidgets,
                    subFieldPermission: null === (m = O.value) || void 0 === m ? void 0 : m[t.fieldName]
                }), null) : f(Lu, E({
                    key: t.id,
                    variables: I.value,
                    readOnly: y.value,
                    formEntityId: null === (g = d.value) || void 0 === g ? void 0 : g.id,
                    disabled: !(null != s && s.editable),
                    widgetMap: l,
                    code: C(t),
                    isMobile: D.value,
                    isPublicRelease: b.value,
                    publicReleaseShareCode: h.value,
                    runtimePlatform: F.runtimePlatform,
                    runtimeEnv: v.value,
                    metaData: t,
                    label: t.name,
                    appId: F.appId,
                    pageCode: F.pageCode,
                    modelValue: d.value[t.fieldName],
                    "onUpdate:modelValue": e => {
                        N(e, t.fieldName);
                    },
                    formData: d.value,
                    formRules: p.value[t.fieldName]
                }, n(t, d.value), t.props, {
                    pageMetaData: F.allWidgets,
                    subFieldPermission: null === (w = O.value) || void 0 === w ? void 0 : w[t.fieldName],
                    ref: e => {
                        T.value[t.id] = e;
                    }
                }), null) ]
            });
            var u, m, g, w;
        }));
        return () => {
            if (r.value) {
                let e;
                return f(R, null, [ _.value && o(f("div", {
                    style: {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: "100"
                    }
                }, null), [ [ a("loading"), _.value ] ]), o(f(V("ElForm"), {
                    ref: m,
                    labelPosition: "top",
                    rules: p.value,
                    model: d.value,
                    key: u.value,
                    "validate-on-rule-change": !1
                }, Tu(e = q()) ? e : {
                    default: () => [ e ]
                }), [ [ g, !_.value ] ]) ]);
            }
            return f(R, null, [ q() ]);
        };
    }
});

var Iu = e({
    components: {
        NestedRender: Eu
    },
    props: {
        widgets: {
            type: Array,
            default: () => []
        },
        allWidgets: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        platformId: {
            type: String
        },
        appId: {
            type: String
        },
        pageCode: {
            type: String
        },
        isProcessPage: {
            type: Boolean,
            default: !1
        },
        variables: {
            type: Object,
            default: () => ({})
        },
        processNode: {
            type: Object
        },
        readOnly: {
            type: Boolean,
            default: !1
        },
        runtimeEnv: {
            type: String,
            default: Q.CREATE
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        publicReleaseShareCode: {
            type: String,
            default: ""
        },
        runtimePlatform: {
            type: String,
            default: fe.PAAS
        },
        statusPriority: {
            type: Number,
            default: Me.DEFAULT
        }
    },
    setup(e, {expose: a}) {
        const {renderList: t, nestedRenderRef: l} = bu.useProvide({
            props: e,
            expose: a
        });
        return {
            renderList: t,
            nestedRenderRef: l
        };
    }
});

const Fu = {
    class: "page-render"
};

Iu.render = function(e, a, o, i, n, r) {
    const s = V("NestedRender");
    return t(), l("div", Fu, [ f(s, {
        widgets: e.renderList,
        ref: "nestedRenderRef"
    }, null, 8, [ "widgets" ]) ]);
};

var Ou = xe(cu, Iu, {
    name: "PageRender"
}), Nu = e({
    name: "BaseFormDialog",
    components: {
        PageRender: Ou
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        visible: {
            type: Boolean,
            default: !1
        },
        footerVisible: {
            type: Boolean,
            default: !0
        },
        formReadOnly: {
            type: Boolean,
            default: !1
        },
        formAppId: {
            type: String,
            default: ""
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        pageCode: {
            type: String,
            default: ""
        },
        platformId: {
            type: String,
            default: ""
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        formRuntimeEnv: {
            type: String,
            default: Q.CREATE
        },
        isRemote: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        closeCb: {
            type: Function
        },
        saveCb: {
            type: Function
        }
    },
    emits: [ "update:visible", "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = le(), o = i(null), n = s({
            get: () => e.visible,
            set: e => {
                t("update:visible", e);
            }
        });
        return P((() => [ e.formData, e.visible ]), (async () => {
            if (!e.visible) return;
            let a = e.formData;
            const t = new ge(e.formWidgets), i = [];
            t.each(((a, t, l, o) => {
                Ae(a.code) && Ja.isNil(e.formData[a.filterName]) && i.push(a);
            })), i.length && e.formData.id && (a = await l.form.getDetailWithoutScene({
                appId: e.formAppId,
                modelCode: e.pageCode,
                id: e.formData.id
            })), b((() => {
                (e => {
                    const a = o.value?.getInnerRef();
                    a?.setFormValue(e);
                })(a);
            }));
        }), {
            immediate: !0
        }), {
            formRef: o,
            dialogVisible: n,
            handleClose: () => {
                n.value = !1, e.closeCb?.();
            },
            handleSave: () => {
                const a = o.value?.getInnerRef();
                a.validate().then((() => {
                    const t = a.getFormValue();
                    e.saveCb?.(t), b((() => {
                        n.value = !1;
                    }));
                }));
            }
        };
    }
});

const Mu = {
    key: 0,
    class: "table-form"
};

Nu.render = function(e, a, o, i, n, r) {
    const s = V("PageRender"), u = V("OlWebView"), d = V("ssu-button"), p = V("el-space"), m = V("BaseDialog");
    return t(), L(m, {
        modelValue: e.dialogVisible,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.dialogVisible = a),
        size: "large",
        title: e.title,
        onClose: e.handleClose
    }, $({
        default: v((() => [ e.isRemote ? (t(), L(u, {
            key: 1,
            url: e.remoteDetailUrl,
            autoHeight: !0,
            allowParam: e.allowRequestParams,
            params: e.remoteDetailUrlRequestParams
        }, null, 8, [ "url", "allowParam", "params" ])) : (t(), l("div", Mu, [ f(s, {
            ref: "formRef",
            readOnly: e.formReadOnly,
            widgets: e.formWidgets,
            appId: e.formAppId,
            pageCode: e.pageCode,
            platformId: e.platformId,
            runtimeEnv: e.formRuntimeEnv
        }, null, 8, [ "readOnly", "widgets", "appId", "pageCode", "platformId", "runtimeEnv" ]) ])) ])),
        _: 2
    }, [ e.footerVisible ? {
        name: "footer",
        fn: v((() => [ f(p, null, {
            default: v((() => [ f(d, {
                onClick: e.handleClose
            }, {
                default: v((() => [ D("取消") ])),
                _: 1
            }, 8, [ "onClick" ]), f(d, {
                type: "primary",
                loading: e.saving,
                onClick: e.handleSave
            }, {
                default: v((() => [ D("保存") ])),
                _: 1
            }, 8, [ "loading", "onClick" ]) ])),
            _: 1
        }) ])),
        key: "0"
    } : void 0 ]), 1032, [ "modelValue", "title", "onClose" ]);
};

const Ru = e => ({
    tableHeight: s((() => `calc(100vh - ${286 - (e.value ? 40 : 0)}px)`))
});

let xu = {};

const Au = e({
    name: "SearchRelation",
    emits: [ "filterData", "clear" ],
    props: {
        displayRelationDataList: {
            type: Array,
            default: () => []
        },
        relationFormFieldList: {
            type: Array,
            default: () => []
        },
        formId: {
            type: String,
            default: ""
        },
        appId: {
            type: String,
            default: ""
        },
        systemsWidgets: {
            type: Array,
            default: () => []
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        filterConfig: {
            type: Array,
            default: () => []
        }
    },
    setup(e, {emit: a, attrs: t}) {
        const l = i(!1), o = i({}), n = be(), r = le(), u = i([]), d = i(!1), p = s((() => {
            const a = xu[e.formId];
            return new ke({
                widgets: a,
                libraryManager: n
            });
        })), m = i(""), c = a => {
            const t = [], l = [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SINGLE_SELECT, K.SEQUENCE, K.RADIO, K.ADDRESS, K.RELATION_FORM ];
            (xu[e.formId] || []).filter((e => l.includes(null == e ? void 0 : e.code))).forEach((l => {
                if (l.code === K.ADDRESS) return t.push({
                    operator: "contains",
                    field: `${l.fieldName}_address`,
                    value: a,
                    type: "string"
                }), void t.push({
                    operator: "contains",
                    field: `${l.fieldName}_desc`,
                    value: a,
                    type: "string"
                });
                if (l.code !== K.RELATION_FORM) t.push({
                    operator: "contains",
                    field: l.fieldName,
                    value: a,
                    type: "string"
                }); else {
                    const o = e.displayRelationDataList.filter((e => (e[l.fieldName] || []).find((e => {
                        var t, o, i;
                        const n = null == e ? void 0 : e[null == l || null === (t = l.props) || void 0 === t || null === (o = t.showFields) || void 0 === o || null === (i = o.rawValue) || void 0 === i ? void 0 : i[0]];
                        return !Ja.isNil(n) && (Ja.isArray(n) ? n.find((e => Ja.toString(e).includes(a))) : Ja.toString(n).includes(a));
                    })))).map((e => Ra(e[l.fieldName]).map((e => null == e ? void 0 : e.id))));
                    t.push({
                        operator: "in",
                        field: l.fieldName,
                        value: Ja.flatMapDeep(o),
                        type: "string"
                    });
                }
            }));
            b({
                condition: "OR",
                rules: t
            }), a || v();
        }, v = () => {
            a("clear");
        }, g = () => {
            l.value = !0;
        }, b = t => {
            u.value = [], e.displayRelationDataList.forEach(((e, a) => {
                (null == p ? void 0 : p.value.execute({
                    jsonRule: t,
                    data: Wa(e)
                })) && u.value.push(e);
            })), d.value = !0, a("filterData", u.value);
        }, h = e => {
            if (b(e), e.rules) {
                (e.rules || []).every((e => !e.value)) && v();
            }
        }, y = s((() => {
            var a;
            const t = new ge(e.formWidgets.concat(e.systemsWidgets)), l = [];
            t.each(((e, a, t, o) => {
                e.widgetType !== J.CONTAINER && l.push(e);
            }));
            return ((null === (a = e.filterConfig) || void 0 === a ? void 0 : a.fields) || []).map((e => l.find((a => a.fieldName === e)))).filter((e => !!e));
        }));
        w((() => {
            r.metaData.getPageMetaData({
                appId: e.appId,
                pageCode: e.formId
            }).then((a => {
                const {widgets: t = [], systemAttributes: l = []} = a.content || {}, o = new ge(t.concat(l)), i = [];
                o.each(((e, a, t, l) => {
                    e.widgetType === J.FORM && i.push(e);
                })), xu[e.formId] = i;
            }));
        })), B((() => {
            xu = {};
        }));
        const C = s((() => d.value ? u.value.length : e.displayRelationDataList.length));
        return () => f("div", {
            class: "relation-table-filter-wrapper"
        }, [ f("span", {
            class: "filter-form-trigger",
            onClick: g
        }, [ f(V("syc-icon"), {
            type: "shaixuan"
        }, null) ]), f(V("ssu-input-text"), {
            modelValue: m.value,
            "onUpdate:modelValue": e => m.value = e,
            class: "relation-table-filter-wrapper-keyword-input",
            search: !0,
            placeholder: "请输入",
            onSearch: c,
            onKeyEnter: c
        }, null), f("span", null, [ D(" 共 "), C.value, D(" 行  ") ]), f(V("FilterDialog"), {
            modelValue: o.value,
            fields: y.value,
            appId: e.appId,
            modelCode: e.formId,
            visible: l.value,
            "onUpdate:visible": e => l.value = e,
            title: "筛选",
            onFilter: h
        }, null) ]);
    }
}), {formComponentMixin: ku} = z, {temporarySortTableData: Pu} = H;

var _u = e({
    name: "RelationFormTableFormat",
    components: {
        BaseFormDialog: Nu,
        SearchRelation: Au
    },
    props: {
        selectedList: {
            type: Array,
            default: () => []
        },
        platformId: {
            type: String
        },
        appId: {
            type: String
        },
        formId: {
            type: String
        },
        isSingleMode: {
            type: Boolean,
            default: !1
        },
        isAllowCreate: {
            type: Boolean,
            default: !1
        },
        data: {
            type: Array,
            default: () => []
        },
        dataTotal: {
            type: Number
        },
        showFieldList: {
            type: Array,
            default: () => []
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        systemsWidgets: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: !1
        },
        hasDelete: {
            type: Boolean,
            default: !0
        },
        hasChooseButton: {
            type: Boolean,
            default: !0
        },
        formName: {
            type: String,
            default: ""
        },
        hasViewDetail: {
            type: Boolean,
            default: !0
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        isSortable: {
            type: Boolean,
            default: !1
        },
        isMainSortable: {
            type: Boolean,
            default: !1
        },
        isAllowMainTemporarySortable: {
            type: Boolean,
            default: !1
        },
        hasFilter: {
            type: Boolean,
            default: !1
        },
        filterConfig: {
            type: Object,
            default: () => ({})
        },
        mainFilterConfig: {
            type: Object,
            default: () => ({})
        },
        conditions: {
            type: Object,
            default: () => ({})
        },
        query: {
            type: Object,
            default: () => ({})
        },
        allowCancelRelation: {
            type: Boolean,
            default: !0
        },
        allowHasSelect: {
            type: Boolean,
            default: !0
        },
        onRowClick: Function,
        allowFilter: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ ku ],
    emits: [ "update:selectedList", "show-create-dialog", "page-current-change", "page-size-change", "main-page-current-change", "main-page-size-change", "keyword-filter", "filter", "selected-list", "main-refresh" ],
    setup(e, a) {
        const {warning: t} = ca(), l = le(), o = i(!1), n = i([]), r = i(null), u = i(!1), d = i({}), p = i(), m = i([]), c = i(!1), v = s((() => e.runtimeEnv === Q.DETAIL && e.allowFilter)), f = s((() => e.data)), g = s((() => !E.value && e.allowHasSelect && e.hasChooseButton)), h = s((() => e.query?.pageNum ?? 1)), {tableHeight: y} = Ru(s((() => e.hasFilter))), D = s((() => e.appId)), C = s((() => e.formId)), w = s((() => e.conditions)), {initRelationFormQueryData: S} = Ta(D, C, w, e, a), {relationFormListCacheGroup: L} = Va(C), T = s({
            get: () => e.selectedList || [],
            set(e) {
                a.emit("update:selectedList", e);
            }
        }), V = s((() => D.value && C.value)), E = s((() => !!e.disabled || !V.value)), I = s((() => {
            const e = T.value;
            if (!e) return [];
            return (Ja.isArray(e) ? e : Ja.isObject(e) ? e.id ? [ e ] : [] : e?.split(",")).map((e => e?.id || e));
        })), F = s((() => L.value?.toList() || [])), O = s((() => {
            if (e.hasChooseButton) {
                return I.value.map((e => F.value.find((a => a.id === e)))).filter(Boolean);
            }
            return f.value;
        })), N = s((() => c.value ? m.value : O.value)), M = s((() => {
            const a = new ge(e.formWidgets.concat(e.systemsWidgets)), t = [];
            a.each(((e, a, l, o) => {
                e.widgetType !== J.CONTAINER && t.push(e);
            }));
            return (e.filterConfig?.fields || []).map((e => t.find((a => a.fieldName === e)))).filter((e => !!e));
        })), R = s((() => e.formName || "查看详情")), x = s((() => {
            if (e.data.length) return Ja.isString(e.data[0]);
        }));
        return P((() => o.value), (e => {
            e || (n.value = I.value);
        })), P((() => e.selectedList), (e => {
            e?.length && e?.forEach((e => {
                F.value.find((a => Ja.isEqual(a, e))) || L?.value.addCache({
                    cheKey: e.id,
                    ...e
                });
            }));
        }), {
            immediate: !0
        }), P((() => x.value), (a => {
            if (!a) return;
            const t = e.data.filter((e => !F.value.find((a => a.id === e))));
            t.length > 0 && S({
                condition: "AND",
                rules: [ {
                    field: "id",
                    operator: "in",
                    value: t,
                    type: "string",
                    valueType: "fixed"
                } ]
            }, "push");
        }), {
            immediate: !0,
            deep: !0
        }), {
            selectDialogVisible: o,
            pageNum: h,
            relationFormQueryData: f,
            filterData: d,
            tableHeight: y,
            relationFormAppId: D,
            relationFormId: C,
            displayRelationDataList: O,
            idDisabled: E,
            filterFields: M,
            baseFormDialogFormData: r,
            baseFormDialogTitle: R,
            baseFormDialogVisible: u,
            RuntimeEnv: Q,
            filterKeyword: p,
            isShowSelectData: g,
            filterDisplayDataList: N,
            isShowFilter: v,
            handleSelectionListChange: a => {
                if (e.isSingleMode) return void (n.value = Array.isArray(a) ? a.map((e => e.id)) : a.id);
                const t = a.map((e => e.id)), l = t.filter((e => !n.value.includes(e))), o = Ja.difference(n.value, t);
                n.value = [ ...n.value, ...l ].filter((e => !o.includes(e)));
            },
            handleDeleteDataItem: t => {
                const l = [];
                e.selectedList.filter((({id: e}) => {
                    e !== t.id && l.push(e);
                })), a.emit("update:selectedList", l);
            },
            handleShowFormDialog: () => {
                a.emit("show-create-dialog");
            },
            handleSelectConfirm: () => {
                T.value = n.value;
            },
            handlePageChange: e => {
                a.emit("page-current-change", e);
            },
            handlePageSizeChange: e => {
                a.emit("page-size-change", e);
            },
            handleMainPageChange: e => {
                a.emit("main-page-current-change", e);
            },
            handleMainPageSizeChange: e => {
                a.emit("main-page-size-change", e);
            },
            hanTableRefresh: () => {
                a.emit("main-refresh");
            },
            handleRowClick: (a, o, i) => {
                l.form.getDataSummary({
                    appId: D.value,
                    modelCode: C.value,
                    id: a.id
                }).then((l => {
                    if (l) {
                        if (Ja.isFunction(e.onRowClick) && e.onRowClick(a, o, i), !e.hasViewDetail) return;
                        r.value = a, b((() => {
                            u.value = !0;
                        }));
                    } else t("暂无数据权限");
                }));
            },
            handleBaseFormDialogVisibleChange: e => {
                u.value = e;
            },
            handleSortChange: ({column: e, prop: t, order: l}) => {
                a.emit("sort-change", {
                    column: e,
                    prop: t,
                    order: l
                });
            },
            handleRefresh: () => {
                a.emit("refresh");
            },
            handleMainSortChange: ({column: t, prop: l, order: o}) => {
                e.isAllowMainTemporarySortable ? T.value = Pu(O.value, {
                    column: t,
                    prop: l,
                    order: o
                }).map((e => e.id)) : a.emit("main-sort-change", {
                    column: t,
                    prop: l,
                    order: o
                });
            },
            handleKeywordFilter: e => {
                a.emit("keyword-filter", e);
            },
            handleFilter: (e = {}) => {
                a.emit("filter", e);
            },
            handleShowSelectDialog: () => {
                a.emit("page-current-change", 1), o.value = !0;
            },
            onFilterData: e => {
                c.value = !0, m.value = e;
            },
            onClear: () => {
                c.value = !1;
            }
        };
    }
});

const Uu = {
    class: "relation-form-table-format"
};

_u.render = function(e, a, o, i, n, r) {
    const s = V("syc-icon"), u = V("ssu-button"), d = V("SearchRelation"), p = V("BaseTable"), m = V("BaseFormDialog"), c = V("SelectTableDataDialog"), g = V("FilterDialog");
    return t(), l("div", Uu, [ y("div", {
        class: A([ "buttons-wrapper", e.inSubForm && "is-in-sub-form" ])
    }, [ e.isShowSelectData ? (t(), L(u, {
        key: 0,
        type: "primary",
        onClick: e.handleShowSelectDialog,
        disabled: e.idDisabled
    }, {
        default: v((() => [ f(s, {
            type: "xuanze",
            style: {
                marginRight: "6px"
            }
        }), D("选择数据 ") ])),
        _: 1
    }, 8, [ "onClick", "disabled" ])) : F("", !0), !e.idDisabled && e.isAllowCreate ? (t(), 
    L(u, {
        key: 1,
        type: "primary",
        onClick: e.handleShowFormDialog,
        disabled: e.idDisabled,
        style: {
            marginLeft: "8px"
        }
    }, {
        default: v((() => [ f(s, {
            type: "mxqd_15",
            style: {
                marginRight: "6px"
            }
        }), D(" 新增 ") ])),
        _: 1
    }, 8, [ "onClick", "disabled" ])) : F("", !0), e.isShowFilter ? (t(), L(d, {
        key: 2,
        formId: e.formId,
        appId: e.appId,
        systemsWidgets: e.systemsWidgets,
        showFieldList: e.showFieldList,
        formWidgets: e.formWidgets,
        filterConfig: e.filterConfig,
        displayRelationDataList: e.displayRelationDataList,
        onFilterData: e.onFilterData,
        onClear: e.onClear
    }, null, 8, [ "formId", "appId", "systemsWidgets", "showFieldList", "formWidgets", "filterConfig", "displayRelationDataList", "onFilterData", "onClear" ])) : F("", !0) ], 2), f(p, {
        appId: e.relationFormAppId,
        pageCode: e.pageCode,
        metaData: e.metaData,
        data: e.filterDisplayDataList,
        columnList: e.showFieldList,
        hasDelete: !!e.allowCancelRelation && (!e.idDisabled && e.hasDelete),
        showPagination: e.showPagination,
        total: e.dataTotal,
        isSortable: e.isMainSortable,
        class: "relation-form-table-data-list is-pre",
        onDelete: e.handleDeleteDataItem,
        onPageCurrentChange: e.handleMainPageChange,
        onPageSizeChange: e.handleMainPageSizeChange,
        onRowClick: e.handleRowClick,
        onSortChange: e.handleMainSortChange,
        onRefresh: e.hanTableRefresh
    }, null, 8, [ "appId", "pageCode", "metaData", "data", "columnList", "hasDelete", "showPagination", "total", "isSortable", "onDelete", "onPageCurrentChange", "onPageSizeChange", "onRowClick", "onSortChange", "onRefresh" ]), e.baseFormDialogVisible ? (t(), 
    L(m, {
        key: 0,
        title: e.baseFormDialogTitle,
        visible: e.baseFormDialogVisible,
        "onUpdate:visible": e.handleBaseFormDialogVisibleChange,
        formWidgets: e.formWidgets,
        formAppId: e.relationFormAppId,
        formData: e.baseFormDialogFormData,
        pageCode: e.relationFormId,
        platformId: e.platformId,
        formReadOnly: !0,
        formRuntimeEnv: e.RuntimeEnv.DETAIL,
        footerVisible: !1,
        isRemote: e.allowRemoteDetail,
        remoteDetailUrl: e.remoteDetailUrl,
        allowRequestParams: e.allowRequestParams,
        remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams
    }, null, 8, [ "title", "visible", "onUpdate:visible", "formWidgets", "formAppId", "formData", "pageCode", "platformId", "formRuntimeEnv", "isRemote", "remoteDetailUrl", "allowRequestParams", "remoteDetailUrlRequestParams" ])) : F("", !0), !e.idDisabled && e.hasChooseButton && e.selectDialogVisible ? (t(), 
    L(c, {
        key: 1,
        modelValue: e.selectDialogVisible,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.selectDialogVisible = a),
        filterData: e.filterData,
        "onUpdate:filterData": a[1] || (a[1] = a => e.filterData = a),
        appId: e.relationFormAppId,
        modelCode: e.relationFormId,
        hasFilter: e.hasFilter,
        filterFields: e.filterFields,
        tableData: e.relationFormQueryData,
        tableColumnList: e.showFieldList,
        isSingleMode: e.isSingleMode,
        isSortable: e.isSortable,
        tableSelectionList: e.displayRelationDataList,
        tableTotal: e.dataTotal,
        tableHeight: e.tableHeight,
        tableCurrentPage: e.pageNum,
        title: "请选择数据",
        onConfirm: e.handleSelectConfirm,
        onFilter: e.handleFilter,
        onFilterSearch: e.handleKeywordFilter,
        "onUpdate:tableSelectionList": e.handleSelectionListChange,
        onPageCurrentChange: e.handlePageChange,
        onPageSizeChange: e.handlePageSizeChange,
        onSortChange: e.handleSortChange,
        onRefresh: e.handleRefresh
    }, null, 8, [ "modelValue", "filterData", "appId", "modelCode", "hasFilter", "filterFields", "tableData", "tableColumnList", "isSingleMode", "isSortable", "tableSelectionList", "tableTotal", "tableHeight", "tableCurrentPage", "onConfirm", "onFilter", "onFilterSearch", "onUpdate:tableSelectionList", "onPageCurrentChange", "onPageSizeChange", "onSortChange", "onRefresh" ])) : F("", !0), e.hasFilter ? (t(), 
    L(g, {
        key: 2,
        modelValue: e.filterData,
        fields: e.filterFields,
        appId: e.relationFormAppId,
        modelCode: e.relationFormId,
        title: "筛选",
        onFilter: e.handleFilter
    }, null, 8, [ "modelValue", "fields", "appId", "modelCode", "onFilter" ])) : F("", !0) ]);
};

const {formComponentMixin: Bu} = z;

var Wu = e({
    name: "RelationFormSelectFormat",
    components: {
        RenderComponent: Lu
    },
    props: {
        selectedList: {
            type: Array,
            default: () => []
        },
        filterMethod: {
            type: Function,
            default: () => []
        },
        loadMoreData: {
            type: Function,
            default: () => []
        },
        optionPanelLoading: {
            type: Boolean,
            default: !1
        },
        appId: {
            type: String
        },
        formId: {
            type: String
        },
        isSingleMode: {
            type: Boolean,
            default: !1
        },
        isAllowCreate: {
            type: Boolean,
            default: !1
        },
        data: {
            type: Array,
            default: () => []
        },
        filterData: {
            type: Array,
            default: () => []
        },
        dataTotal: {
            type: Number
        },
        showFieldList: {
            type: Array,
            default: () => []
        },
        query: {
            type: Object,
            default: () => ({})
        },
        dataLoading: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Bu ],
    emits: [ "update:selectedList", "show-create-dialog", "selected-list" ],
    setup(e, {emit: a}) {
        const t = le(), l = i(), o = i([]), n = i(!1), r = s((() => e.appId)), u = s((() => e.formId)), {relationFormListCacheGroup: d, clearRelationFormListCacheGroup: p} = Va(u), m = s((() => d.value?.toList() || [])), c = s((() => e.filterData)), v = s({
            get: () => e.selectedList || [],
            set(e) {
                a("update:selectedList", e);
            }
        }), f = s((() => r.value && u.value)), g = s((() => !!e.disabled || !f.value)), b = s((() => v.value.map((e => e?.id || e)))), h = i([]), y = s((() => e.showFieldList[0])), D = s((() => {
            const a = Ja.cloneDeep(e.showFieldList);
            return a.splice(0, 1), a.length > 3 && (a.length = 3), a;
        })), C = s((() => !e.isAllowCreate && m.value.length > 0 && 0 === c.value.length)), S = () => {
            const e = b.value;
            h.value = m.value.filter((a => !!e.find((e => a.id === e))));
        }, L = new Pe("getListWithoutScene", 0), T = (e = []) => {
            var l;
            o.value = e, m.value.some((a => e.includes(a.id))) ? a("selected-list", m.value) : (l = e, 
            (e => {
                if (!r.value || !u.value) return;
                if (0 === l.length) return;
                const o = {
                    appId: r.value,
                    modelCode: u.value,
                    pageSize: l.length,
                    pageNum: 1,
                    filter: {
                        condition: "AND",
                        rules: [ {
                            field: "id",
                            operator: "in",
                            value: l,
                            type: "string",
                            valueType: "fixed"
                        } ]
                    }
                }, i = t => {
                    const {result: l = []} = t;
                    l?.forEach((e => {
                        d.value.addCache({
                            cheKey: e.id,
                            ...e
                        });
                    })), a("selected-list", l || []), e && e();
                };
                if (L.has(o)) {
                    const e = L.get(o);
                    return i(e);
                }
                t.form.getListWithoutScene(o).then((e => {
                    L.set(o, e), i(e);
                }));
            })());
        };
        return P((() => m.value), (() => {
            h.value.forEach((e => {
                m.value.find((a => a.id === e.id)) || d.value.addCache({
                    cheKey: e.id,
                    ...e
                });
            })), !n.value && S();
        }), {
            deep: !0
        }), P((() => b.value), (() => {
            !n.value && S();
        }), {
            deep: !0
        }), P((() => b.value), (e => {
            T(e);
        }), {
            immediate: !0
        }), P((() => e.data), (e => {
            const a = setTimeout((() => {
                p(), T(b.value), e?.forEach((e => {
                    d.value.addCache({
                        cheKey: e.id,
                        ...e
                    });
                })), clearTimeout(a);
            }), 200);
        }), {
            deep: !0,
            immediate: !0
        }), P((() => e.selectedList), (e => {
            e?.length && e?.forEach((e => {
                m.value.find((a => a.id === e.id)) || d?.value.addCache({
                    cheKey: e.id,
                    ...e
                });
            }));
        }), {
            immediate: !0
        }), w((() => {
            S();
        })), {
            isOptionShow: a => {
                const t = !!e.query?.keyword, l = c.value.find((e => e.id === a.id));
                return !t || l;
            },
            triggerSelectRef: l,
            relationFormList: m,
            relationFormAppId: r,
            itemTitleField: y,
            displayRelationDataList: h,
            showFields: D,
            idDisabled: g,
            handleSelectionListChange: a => {
                if (a) {
                    if (e.isSingleMode) o.value = [ a.id ]; else {
                        const e = a.map((e => e.id)), t = e.filter((e => !o.value.includes(e))), l = Ja.difference(o.value, e);
                        o.value = [ ...o.value, ...t ].filter((e => !l.includes(e)));
                    }
                    v.value = o.value;
                } else v.value = [];
            },
            handleShowFormDialog: () => {
                l.value?.getElSelectRef()?.blur?.(), a("show-create-dialog");
            },
            getFieldText: (e = {}, a = {}) => {
                const t = a.metaData, l = e[t?.fieldName];
                if (!t || !l) return Y;
                const o = t.code;
                if (_e(o)) {
                    const e = Ba(t.props, "format") || "YYYY-MM-DD HH:mm:ss";
                    return dt(l).format(e);
                }
                if (Ue(o)) {
                    const e = ne("member"), a = (e.value?.toList() || []).filter((e => l?.includes?.(e.id)));
                    return a.length ? a.map((e => e?.name || Y)).join(ie) : Y;
                }
                return l?.toString() || Y;
            },
            isAllOptionEmpty: C,
            visibleChange: a => {
                n.value = a, a && Ja.isFunction(e.filterMethod) && e.filterMethod("");
            }
        };
    }
});

const qu = {
    class: "relation-form-select-format"
}, ju = {
    class: "select-item"
}, $u = {
    class: "select-item-title"
}, zu = {
    class: "select-item-content"
}, Gu = {
    class: "select-item-content-item-label"
}, Hu = {
    class: "select-item-content-item-value"
}, Qu = {
    "disabled-show-checkbox": "",
    disabled: "",
    class: "el-select-dropdown__empty"
};

Wu.render = function(e, a, i, n, r, s) {
    const u = V("syc-icon"), d = V("ssu-button"), p = V("RenderComponent"), m = V("ssu-option"), c = V("ssu-select");
    return t(), l("div", qu, [ f(c, {
        ref: "triggerSelectRef",
        modelValue: e.isSingleMode ? e.displayRelationDataList[0] : e.displayRelationDataList,
        loading: !e.relationFormList?.length && e.dataLoading,
        disabled: e.idDisabled,
        multiple: !e.isSingleMode,
        filterable: "",
        placeholder: "请选择",
        style: {
            width: "100%"
        },
        placement: "bottom",
        class: "relation-form-select",
        "popper-class": !e.isAllOptionEmpty || e.relationFormList?.length ? "relation-popper-card" : "",
        "value-key": "id",
        "filter-method": e.filterMethod,
        "load-more-data": e.loadMoreData,
        "option-panel-loading": e.optionPanelLoading,
        "fit-input-width": !0,
        "popper-append-to-body": "",
        clearable: "",
        onChange: e.handleSelectionListChange,
        onVisibleChange: e.visibleChange
    }, $({
        default: v((() => [ (t(!0), l(R, null, x(e.relationFormList, (a => o((t(), L(m, {
            key: a.id,
            value: a,
            label: e.getFieldText(a, e.itemTitleField),
            class: A([ e.isSingleMode ? "relation-form-select-option" : "relation-form-select-option-multiple", !e.showFields?.length && "relation-form-select-option-only-label" ])
        }, {
            default: v((() => [ y("div", ju, [ y("div", $u, [ f(p, E({
                formEntityId: a.id,
                formData: a,
                fieldValue: a[e.itemTitleField?.metaData?.fieldName],
                runtimeEnv: "table",
                defineMetaData: e.itemTitleField?.defineMetaData,
                widgetMap: e.itemTitleField?.widgetMap,
                code: e.itemTitleField?.formatterCode,
                metaData: e.itemTitleField?.metaData
            }, e.itemTitleField?.props), null, 16, [ "formEntityId", "formData", "fieldValue", "defineMetaData", "widgetMap", "code", "metaData" ]) ]), y("div", zu, [ (t(!0), 
            l(R, null, x(e.showFields, (e => (t(), l("span", {
                key: e.id,
                class: "select-item-content-item"
            }, [ y("div", Gu, O(e.label), 1), y("div", Hu, [ f(p, E({
                formEntityId: a.id,
                formData: a,
                fieldValue: a[e?.metaData?.fieldName],
                runtimeEnv: "table",
                defineMetaData: e.defineMetaData,
                widgetMap: e.widgetMap,
                code: e.formatterCode,
                metaData: e.metaData
            }, e.props), null, 16, [ "formEntityId", "formData", "fieldValue", "defineMetaData", "widgetMap", "code", "metaData" ]) ]) ])))), 128)) ]) ]) ])),
            _: 2
        }, 1032, [ "value", "label", "class" ])), [ [ g, e.isOptionShow(a) ] ]))), 128)), !e.isAllOptionEmpty || e.relationFormList?.length || e.dataLoading ? F("", !0) : (t(), 
        L(m, {
            key: 0,
            class: "ssu-select-nomatchoption",
            "disabled-push-el-option-ref-list": "",
            "disabled-show-checkbox": "",
            disabled: ""
        }, {
            default: v((() => [ D("无数据") ])),
            _: 1
        })), o(y("p", Qu, " 加载中 ", 512), [ [ g, e.relationFormList?.length && e.dataLoading ] ]) ])),
        _: 2
    }, [ e.isAllowCreate ? {
        name: "headoption",
        fn: v((() => [ f(d, {
            class: "relation-form-select-option-btn",
            type: "primary",
            onClick: e.handleShowFormDialog,
            disabled: e.idDisabled,
            style: {
                width: "100%",
                margin: "16px 0",
                "z-index": 99
            }
        }, {
            default: v((() => [ f(u, {
                type: "mxqd_15",
                style: {
                    marginRight: "6px",
                    marginTop: "-3px"
                }
            }), D(" 新增 ") ])),
            _: 1
        }, 8, [ "onClick", "disabled" ]) ])),
        key: "0"
    } : void 0 ]), 1032, [ "modelValue", "loading", "disabled", "multiple", "popper-class", "filter-method", "load-more-data", "option-panel-loading", "onChange", "onVisibleChange" ]) ]);
};

const {formComponentMixin: Yu} = z, Ku = {
    customer_id: "customer_name",
    contact_id: "customer_contact",
    phone: "phone",
    address: "address",
    address_desc: "address_desc",
    address_address: "address_address",
    address_lng: "address_lng",
    address_lat: "address_lat",
    address_province: "address_province",
    address_city: "address_city",
    address_district: "address_district",
    address_street: "address_street"
}, Xu = {
    devece_id: [ {
        value: "job_details",
        subFormField: "device_name"
    }, {
        value: "job_details_1",
        subFormField: "device_name_1"
    } ]
};

var Ju = e({
    name: "OlRelationForm",
    components: {
        BaseFormDialog: Nu,
        RelationFormTableFormat: _u,
        RelationFormSelectFormat: Wu
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        formId: {
            type: String,
            default: ""
        },
        showType: {
            type: String,
            default: "list"
        },
        mode: {
            type: String,
            default: Be.SINGLE
        },
        allowCreate: {
            type: Boolean,
            default: !1
        },
        showFields: {
            type: Array,
            default: () => []
        },
        formName: {
            type: String,
            default: ""
        },
        conditions: {
            type: Object,
            default: () => ({})
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        allowFilter: {
            type: Boolean,
            default: !1
        },
        filterConfig: {
            type: Object,
            default: () => ({})
        },
        allowCancelRelation: {
            type: Boolean,
            default: !0
        },
        allowHasSelect: {
            type: Boolean,
            default: !0
        }
    },
    mixins: [ Yu ],
    emits: [ "update:modelValue", "dataSourceUpdate" ],
    setup(e, a) {
        const t = s((() => e.metaData.fieldName)), l = le(), o = s((() => {
            const a = e.metaData?.props?.appId;
            return Ja.isObject(a) ? a.rawValue : a;
        })), n = s((() => e.formId)), r = s((() => e.conditions)), u = s((() => e.showFields)), {conditionRulesAdaptor: d, checkConditionRulesFieldType: p} = wa(), {relationFormFieldList: m, relationFormSystemFieldList: c, relationTableColumnList: v} = Ea(o, n, u), {relationFormQueryData: f, relationFormSelectedData: g, relationFormDataLoading: b, relationFormQueryDataTotal: h, relationFormQuery: y, relationFormCondition: D, handlePageChange: C, handleSortChange: S, handlePageSizeChange: L, handleRefresh: T, handleKeyWordFilter: V, handleFilter: E, handleGetSelectedList: I, initRelationFormQueryData: F, filterRelationFormQueryData: O, relationFormListCacheGroup: N} = Ta(o, n, r, e, a), M = s((() => N.value?.toList() || []));
        let R = !0;
        const x = i(!1), A = s((() => Wa(e.formData))), k = s((() => e.mode === Be.SINGLE)), _ = s((() => e.inSubForm ? We.SELECT : e.showType)), U = s((() => _.value === We.LIST)), B = s((() => _.value === We.SELECT)), W = s((() => e.allowCreate)), q = i(!0), j = s({
            get() {
                if (q.value) return (e.formData[`${t.value}${qe}`] || e.modelValue) ?? [];
                return Ja.uniqBy(g.value?.concat(M.value)?.concat(e.formData[`${t.value}${qe}`] || []) || [], "id").filter((a => e.modelValue?.includes(a.id)));
            },
            set(l) {
                const o = Ja.uniqBy(g.value?.concat(f.value || []) || [], "id"), i = o.find((e => l.includes(e.id)));
                z(e.formData, Wa(i)), q.value = !1, l = l.filter((e => !!e)), a.emit(`update:${t.value}${je}`, o.filter((e => l.includes(e.id))).map((e => Wa(e, "id", {
                    withoutOriginData: !0
                })))), a.emit("update:modelValue", l.length > 0 ? l : null);
            }
        }), $ = s((() => o.value && n.value)), z = (a = {}, l, o) => {
            if ("service_demand" === t.value && !e.disabled && (!o || l)) return Object.keys(Ku).forEach((e => {
                a[Ku[e]] = l?.[e];
            })), Object.keys(Xu).forEach((e => {
                Array.isArray(Xu[e]) ? Xu[e].forEach((t => {
                    a[t?.value] = l?.[e]?.map?.((e => ({
                        [t.subFormField]: [ e ]
                    }))) || [];
                })) : a[Xu[e]] = l?.[e];
            })), a;
        }, G = Ja.debounce((a => {
            if (!p(D.value?.rules)) return;
            const t = d(D.value.rules, a, {
                inSubForm: e.inSubForm,
                mainFormData: e.mainFormData
            });
            F({
                rules: t
            });
        }), 200);
        e.inSubForm && P((() => e.mainFormData), (() => G(A.value)), {
            deep: !0
        }), P((() => A.value), G, {
            deep: !0
        }), P((() => _.value), (e => {
            B.value && (y.value.pageSize = 30);
        }), {
            immediate: !0
        });
        const H = s((() => {
            if (e.modelValue?.length) return Ja.isString(e.modelValue[0]);
        }));
        return P((() => H.value), (l => {
            if (!l) return;
            const o = Ja.uniqBy(g.value?.concat(f.value || [])?.concat(e.formData[`${t.value}${qe}`] || []) || [], "id"), i = e.modelValue.filter((e => !o.find((a => a.id === e))));
            i.length ? F({
                condition: "AND",
                rules: [ {
                    field: "id",
                    operator: "in",
                    value: i,
                    type: "string",
                    valueType: "fixed"
                } ]
            }, "push").then((() => {
                const l = Ja.uniqBy(g.value?.concat(f.value || [])?.concat(e.formData[`${t.value}${qe}`] || []) || [], "id");
                a.emit(`update:${t.value}${je}`, l.filter((a => e.modelValue?.includes(a.id))).map((e => Wa(e, "id", {
                    withoutOriginData: !0
                })))), q.value = !1;
            })) : a.emit(`update:${t.value}${je}`, o.filter((a => e.modelValue?.includes(a.id))).map((e => Wa(e, "id", {
                withoutOriginData: !0
            }))));
        }), {
            immediate: !0,
            deep: !0
        }), w((() => {
            if (!$.value) return;
            p(D.value?.rules) && (D.value = {
                ...D.value,
                rules: d(D.value?.rules, A.value, {
                    inSubForm: e.inSubForm,
                    mainFormData: e.mainFormData
                })
            });
            const a = setTimeout((() => {
                const l = Ja.uniqBy(g.value?.concat(f.value || [])?.concat(e.formData[`${t.value}${qe}`] || []) || [], "id").find((e => j.value?.includes(e.id)));
                z(e.formData, Wa(l), !0), clearTimeout(a);
            }), 2e3);
        })), {
            selectedList: j,
            relationFormQueryData: f,
            relationFormFieldList: m,
            relationFormSystemFieldList: c,
            baseFormDialogVisible: x,
            relationFormAppId: o,
            relationFormId: n,
            relationConditions: r,
            isSingleMode: k,
            relationShowType: _,
            isAllowCreate: W,
            relationTableColumnList: v,
            isListComponent: U,
            isSelectComponent: B,
            relationFormQuery: y,
            filterRelationFormQueryData: O,
            handleSelectListUpdate: e => {
                j.value = e;
            },
            handleSelectLoadMoreData: () => {
                if (b.value) return;
                if (!R) return;
                const e = y.value.pageNum;
                y.value.pageNum = e + 1, F(D.value, "push", {
                    pageSize: 20,
                    cache: !1
                }).then((a => {
                    y.value.keyword && (O.value = O.value.concat(a.result || [])), R = a?.more, R || (y.value.pageNum = e);
                }));
            },
            handleShowFormDialog: () => {
                x.value = !0;
            },
            handleCreateFormDialogVisibleChange: e => {
                x.value = e;
            },
            handleCreateFormData: e => {
                ((e, a, t) => l.form.createWithoutScene({
                    appId: e,
                    modelCode: a,
                    ...t
                }))(o.value, n.value, e).then((a => {
                    const t = a;
                    F(D.value)?.then((() => {
                        let a = j.value;
                        a = k.value ? [ t ] : j.value.concat([ {
                            ...e,
                            id: t
                        } ]).map((e => e.id)), j.value = a;
                    }));
                }));
            },
            handlePageChange: C,
            handlePageSizeChange: L,
            handleRefresh: T,
            handleSortChange: S,
            handleKeyWordFilter: V,
            handleSelectFilterMethod: async e => {
                y.value.keyword = e, y.value.pageNum = 1, !e && (O.value = []);
                const a = await F(D.value, "replace", {
                    pageSize: 20,
                    cache: !1
                });
                R = a?.more, O.value = Ja.cloneDeep(a.result || []);
            },
            handleFilter: E,
            handleGetSelectedList: I,
            relationFormDataLoading: b,
            relationFormQueryDataTotal: h,
            RelationFormShowType: We
        };
    }
});

const Zu = {
    class: "ol-relation-form"
};

Ju.render = function(e, a, o, i, n, r) {
    const s = V("RelationFormTableFormat"), u = V("RelationFormSelectFormat"), d = V("BaseFormDialog");
    return t(), l("div", Zu, [ e.isListComponent ? (t(), L(s, {
        key: 0,
        isAllowMainTemporarySortable: !0,
        disabled: e.disabled,
        selectedList: e.selectedList,
        appId: e.relationFormAppId,
        pageCode: e.pageCode,
        metaData: e.metaData,
        formId: e.relationFormId,
        conditions: e.relationConditions,
        isSingleMode: e.isSingleMode,
        isAllowCreate: e.isAllowCreate,
        data: e.relationFormQueryData,
        dataTotal: e.relationFormQueryDataTotal,
        dataLoading: e.relationFormDataLoading,
        showFieldList: e.relationTableColumnList,
        formWidgets: e.relationFormFieldList,
        systemsWidgets: e.relationFormSystemFieldList,
        formName: e.formName,
        query: e.relationFormQuery,
        allowRemoteDetail: e.allowRemoteDetail,
        remoteDetailUrl: e.remoteDetailUrl,
        allowRequestParams: e.allowRequestParams,
        remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams,
        isSortable: !0,
        isMainSortable: !0,
        hasFilter: e.allowFilter,
        allowFilter: e.allowFilter,
        filterConfig: e.filterConfig,
        allowHasSelect: e.allowHasSelect,
        allowCancelRelation: e.allowCancelRelation,
        "onUpdate:selectedList": e.handleSelectListUpdate,
        onShowCreateDialog: e.handleShowFormDialog,
        onPageCurrentChange: e.handlePageChange,
        onPageSizeChange: e.handlePageSizeChange,
        onSortChange: e.handleSortChange,
        onKeywordFilter: e.handleKeyWordFilter,
        onFilter: e.handleFilter,
        onSelectedList: e.handleGetSelectedList,
        onRefresh: e.handleRefresh
    }, null, 8, [ "disabled", "selectedList", "appId", "pageCode", "metaData", "formId", "conditions", "isSingleMode", "isAllowCreate", "data", "dataTotal", "dataLoading", "showFieldList", "formWidgets", "systemsWidgets", "formName", "query", "allowRemoteDetail", "remoteDetailUrl", "allowRequestParams", "remoteDetailUrlRequestParams", "hasFilter", "allowFilter", "filterConfig", "allowHasSelect", "allowCancelRelation", "onUpdate:selectedList", "onShowCreateDialog", "onPageCurrentChange", "onPageSizeChange", "onSortChange", "onKeywordFilter", "onFilter", "onSelectedList", "onRefresh" ])) : F("", !0), e.isSelectComponent ? (t(), 
    L(u, {
        key: 1,
        disabled: e.disabled,
        selectedList: e.selectedList,
        appId: e.relationFormAppId,
        formId: e.relationFormId,
        isSingleMode: e.isSingleMode,
        isAllowCreate: e.isAllowCreate,
        data: e.relationFormQueryData,
        query: e.relationFormQuery,
        filterData: e.filterRelationFormQueryData,
        dataTotal: e.relationFormQueryDataTotal,
        dataLoading: e.relationFormDataLoading,
        showFieldList: e.relationTableColumnList,
        filterMethod: e.handleSelectFilterMethod,
        loadMoreData: e.handleSelectLoadMoreData,
        "onUpdate:selectedList": e.handleSelectListUpdate,
        onShowCreateDialog: e.handleShowFormDialog
    }, null, 8, [ "disabled", "selectedList", "appId", "formId", "isSingleMode", "isAllowCreate", "data", "query", "filterData", "dataTotal", "dataLoading", "showFieldList", "filterMethod", "loadMoreData", "onUpdate:selectedList", "onShowCreateDialog" ])) : F("", !0), e.isAllowCreate && e.baseFormDialogVisible ? (t(), 
    L(d, {
        key: 2,
        title: "新增数据",
        visible: e.baseFormDialogVisible,
        "onUpdate:visible": e.handleCreateFormDialogVisibleChange,
        formWidgets: e.relationFormFieldList,
        formAppId: e.relationFormAppId,
        pageCode: e.relationFormId,
        platformId: e.platformId,
        saveCb: e.handleCreateFormData
    }, null, 8, [ "visible", "onUpdate:visible", "formWidgets", "formAppId", "pageCode", "platformId", "saveCb" ])) : F("", !0) ]);
};

const ed = e({
    name: "MobilePopup",
    props: {
        modelValue: {
            type: Boolean,
            default: !1
        },
        active: {
            type: String,
            default: "option"
        }
    },
    emits: [ "update:modelValue", "update:active", "select-confirm", "cancel" ],
    setup(e, {emit: a, slots: t}) {
        const l = s({
            get: () => e.modelValue,
            set: e => {
                a("update:modelValue", e);
            }
        }), o = s({
            get: () => e.active,
            set: e => {
                a("update:active", e);
            }
        });
        return () => f(at, {
            show: l.value,
            "onUpdate:show": e => l.value = e,
            position: "bottom",
            round: !0,
            style: {
                height: "80%"
            }
        }, {
            default: () => [ f(Yi, {
                title: "选择关联表单",
                onConfirm: () => a("select-confirm"),
                onCancel: () => a("cancel")
            }, null), f(ot, {
                active: o.value,
                "onUpdate:active": e => o.value = e,
                "line-width": "0",
                "line-height": "0",
                "title-active-color": "#167DFA"
            }, {
                default: () => [ f(it, {
                    title: "可选",
                    name: "option"
                }, null), f(it, {
                    title: "已选",
                    name: "select"
                }, null) ]
            }), f("div", {
                class: "relatime-form-mobile-popup-main"
            }, [ _a(t.default, null) ]) ]
        });
    }
}), ad = e({
    name: "MobilePopupSelectFormCardItem",
    props: {
        modelValue: {
            type: Boolean,
            default: !1
        },
        cardData: {
            type: Object,
            default: () => ({})
        },
        showFields: {
            type: Array,
            default: () => []
        },
        isMultiple: {
            type: Boolean,
            default: !1
        }
    },
    setup(e) {
        const a = s((() => e.cardData)), t = s((() => e.showFields));
        return () => f("div", {
            class: "select-form-card-item-content"
        }, [ t.value.length && t.value.map(((e, t) => {
            var l;
            return t > 2 ? null : f("div", {
                class: "select-form-card-item-content-item"
            }, [ f("div", {
                class: "select-form-card-item-content-item-label"
            }, [ e.label ]), f("div", {
                class: "select-form-card-item-content-item-detail"
            }, [ f(Lu, E({
                formEntityId: a.value.id,
                formData: a.value,
                fieldValue: a.value[null == e || null === (l = e.metaData) || void 0 === l ? void 0 : l.fieldName],
                runtimeEnv: "table",
                defineMetaData: e.defineMetaData,
                widgetMap: e.widgetMap,
                code: e.formatterCode,
                metaData: e.metaData
            }, e.props), null) ]) ]);
        })) ]);
    }
}), td = e({
    name: "MobilePopupSelectFormCard",
    props: {
        data: {
            type: Array,
            default: () => []
        },
        columnList: {
            type: Object,
            default: () => ({})
        },
        selectionList: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: !1
        },
        hasSelection: {
            type: Boolean,
            default: !1
        },
        hasDelete: {
            type: Boolean,
            default: !1
        },
        isMultiple: {
            type: Boolean,
            default: !1
        },
        height: Number,
        showFields: {
            type: Array,
            default: () => []
        }
    },
    emits: [ "update:selectionList", "page-current-change", "page-size-change", "row-click", "delete" ],
    setup(e, {emit: a}) {
        var t;
        const l = i(null), o = s((() => e.data)), n = s((() => e.columnList)), r = s((() => e.showFields)), u = s((() => e.isMultiple)), d = i(u.value ? null !== (t = e.selectionList) && void 0 !== t ? t : [] : e.selectionList[0] ? e.selectionList[0] : "");
        return P((() => d.value), (e => {
            a("update:selectionList", u.value ? e : [ e ]);
        })), () => f("div", {
            class: "mobile-popup-select-form-card"
        }, [ u.value ? f(V("van-checkbox-group"), {
            modelValue: d.value,
            "onUpdate:modelValue": e => d.value = e,
            ref: l
        }, {
            default: () => [ o.value.length && o.value.map((e => f("div", {
                class: "mobile-popup-select-form-card-item"
            }, [ f("div", {
                class: "select-form-card-item-title"
            }, [ f("div", {
                class: "select-form-card-item-title-label"
            }, [ e[n.value[0].prop] ]), f(V("van-checkbox"), {
                iconSize: "14",
                name: e.id
            }, null) ]), f(ad, {
                cardData: e,
                showFields: r.value
            }, null) ]))) ]
        }) : f(V("van-radio-group"), {
            modelValue: d.value,
            "onUpdate:modelValue": e => d.value = e
        }, {
            default: () => [ o.value.length && o.value.map((e => f("div", {
                class: "mobile-popup-select-form-card-item"
            }, [ f("div", {
                class: "select-form-card-item-title"
            }, [ f("div", {
                class: "select-form-card-item-title-label"
            }, [ e[n.value[0].prop] ]), f(V("van-radio"), {
                iconSize: "14",
                name: e.id
            }, null) ]), f(ad, {
                cardData: e,
                showFields: r.value
            }, null) ]))) ]
        }) ]);
    }
}), ld = e({
    name: "MobilePopupAddFormDetail",
    props: {
        modelValue: {
            type: Boolean,
            default: !1
        },
        title: {
            type: String,
            default: ""
        },
        visible: {
            type: Boolean,
            default: !1
        },
        footerVisible: {
            type: Boolean,
            default: !0
        },
        formReadOnly: {
            type: Boolean,
            default: !1
        },
        formAppId: {
            type: String,
            default: ""
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        pageCode: {
            type: String,
            default: ""
        },
        platformId: {
            type: String,
            default: ""
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        formRuntimeEnv: {
            type: String,
            default: Q.CREATE
        },
        isRemote: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: []
        },
        closeCb: {
            type: Function
        },
        saveCb: {
            type: Function
        }
    },
    emits: [ "update:visible", "update:modelValue" ],
    setup(e, {emit: t}) {
        const l = i(null), n = s({
            get: () => e.visible,
            set: e => {
                t("update:visible", e);
            }
        }), r = () => {
            var a;
            t("update:visible", !1), null === (a = e.closeCb) || void 0 === a || a.call(e);
        }, u = () => {
            var a;
            t("update:visible", !1);
            const o = null === (a = l.value) || void 0 === a ? void 0 : a.getInnerRef();
            o.validate().then((() => {
                var a;
                const t = o.getFormValue();
                null === (a = e.saveCb) || void 0 === a || a.call(e, t);
            }));
        };
        return P((() => e.formData), (e => {
            setTimeout((() => {
                (e => {
                    var a;
                    const t = null === (a = l.value) || void 0 === a ? void 0 : a.getInnerRef();
                    null == t || t.setFormValue(e);
                })(e);
            }), 200);
        }), {
            immediate: !0
        }), () => f(V("van-popup"), {
            show: n.value,
            "onUpdate:show": e => n.value = e,
            position: "bottom",
            round: !0,
            style: {
                height: "80%"
            }
        }, {
            default: () => [ f(Yi, {
                title: "新增数据",
                onCancel: r,
                onConfirm: u
            }, null), !e.isRemote && f("div", {
                class: "table-form"
            }, [ f(Ou, {
                ref: l,
                readOnly: e.formReadOnly,
                widgets: e.formWidgets,
                appId: e.formAppId,
                pageCode: e.pageCode,
                platformId: e.platformId,
                runtimeEnv: e.formRuntimeEnv,
                isMobile: !0
            }, null) ]), e.isRemote && o(f(V("OlWebView"), {
                url: e.remoteDetailUrl,
                autoHeight: !0,
                allowParam: e.allowRequestParams,
                params: e.remoteDetailUrlRequestParams
            }, null), [ [ a("else") ] ]) ]
        });
    }
}), od = e({
    name: "MobileRelationFormTableCard",
    props: {
        displayRelationDataList: {
            type: Array,
            default: () => []
        },
        showFields: {
            type: Array,
            default: () => []
        },
        idDisabled: {
            type: Boolean,
            default: !1
        },
        relationTableColumnList: {
            type: Array,
            default: () => []
        }
    },
    emits: [ "delete-data-item", "row-click" ],
    setup(e, {emit: a}) {
        const t = s((() => e.idDisabled)), l = s((() => {
            var a;
            return null !== (a = null == e ? void 0 : e.displayRelationDataList) && void 0 !== a ? a : [];
        })), o = s((() => e.showFields));
        return () => f("div", null, [ l.value.map((l => f("div", {
            class: "mobile-relation-form-table-card"
        }, [ f("div", {
            class: "mobile-relation-form-table-card-title"
        }, [ f("span", null, [ l[e.relationTableColumnList[0].prop] ]), t.value ? "" : f(V("syc-icon"), {
            type: "delete",
            color: "#C1C4CD",
            onClick: () => {
                var e;
                !t.value && (e = l, !t.value && a("delete-data-item", e));
            }
        }, null) ]), f("div", {
            class: "mobile-relation-form-table-card-main",
            onClick: () => {
                a("row-click", l);
            }
        }, [ f("div", {
            class: "table-card-main-item"
        }, [ o.value.length && o.value.map((e => {
            var a;
            return f("div", {
                class: "select-form-card-item-content-item"
            }, [ f("div", {
                class: "select-form-card-item-content-item-label"
            }, [ e.label ]), f("div", {
                class: "select-form-card-item-content-item-detail"
            }, [ f(Lu, E({
                formEntityId: l.id,
                formData: l,
                fieldValue: l[null == e || null === (a = e.metaData) || void 0 === a ? void 0 : a.fieldName],
                runtimeEnv: "table",
                defineMetaData: e.defineMetaData,
                widgetMap: e.widgetMap,
                code: e.formatterCode,
                metaData: e.metaData
            }, e.props), null) ]) ]);
        })) ]) ]) ]))) ]);
    }
}), {formComponentMixin: id} = z;

var nd = e({
    name: "RelationFormTableFormatMobile",
    props: {
        selectedList: {
            type: Array,
            default: () => []
        },
        platformId: {
            type: String
        },
        appId: {
            type: String
        },
        formId: {
            type: String
        },
        isSingleMode: {
            type: Boolean,
            default: !1
        },
        isAllowCreate: {
            type: Boolean,
            default: !1
        },
        data: {
            type: Array,
            default: () => []
        },
        dataTotal: {
            type: Number
        },
        showFieldList: {
            type: Array,
            default: () => []
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: !1
        },
        hasDelete: {
            type: Boolean,
            default: !0
        },
        hasChooseButton: {
            type: Boolean,
            default: !0
        },
        formName: {
            type: String,
            default: ""
        },
        hasViewDetail: {
            type: Boolean,
            default: !0
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        allowCancelRelation: {
            type: Boolean,
            default: !0
        },
        allowHasSelect: {
            type: Boolean,
            default: !0
        }
    },
    mixins: [ id ],
    emits: [ "update:selectedList", "show-create-dialog", "page-current-change", "page-size-change", "row-click", "main-page-current-change", "main-page-size-change" ],
    setup(e, {emit: t}) {
        const l = i(!1), n = s((() => !e.isSingleMode)), r = i([]), u = i("option"), d = s((() => e.data)), p = i(e.data), m = () => {
            "select" === u.value ? p.value = d.value.filter((e => r.value.includes(e.id))) : p.value = d.value;
        }, c = i(!1), v = s((() => e.appId)), g = s((() => e.formId)), h = s({
            get: () => e.selectedList || [],
            set(e) {
                t("update:selectedList", e);
            }
        }), y = s((() => v.value && g.value)), C = s((() => !!e.disabled || !y.value)), w = s((() => {
            const e = h.value;
            if (!e) return [];
            return (Ja.isArray(e) ? e : Ja.isObject(e) ? e.id ? [ e ] : [] : null == e ? void 0 : e.split(",")).map((e => (null == e ? void 0 : e.id) || e));
        })), S = s((() => {
            const e = w.value;
            return d.value.filter((a => !!e.find((e => a.id === e))));
        })), L = s((() => e.showFieldList)), T = s((() => {
            const a = Ja.cloneDeep(e.showFieldList);
            return a.splice(0, 1), a;
        })), V = i(null), E = i(!1);
        s((() => e.formName || "查看详情"));
        const I = a => {
            if (e.isSingleMode) return void (r.value = Array.isArray(a) ? a : a.id);
            const t = a, l = t.filter((e => !r.value.includes(e))), o = Ja.difference(r.value, t);
            r.value = [ ...r.value, ...l ].filter((e => !o.includes(e))), m();
        }, F = e => {
            const a = r.value, t = a.findIndex((a => a === e.id));
            a.splice(t, 1), h.value = a;
        }, O = () => {
            l.value = !0;
        }, N = () => {
            t("show-create-dialog");
        }, M = () => {
            h.value = r.value, l.value = !1;
        }, R = () => {
            l.value = !1;
        }, x = e => {
            t("page-current-change", e);
        }, A = e => {
            t("page-size-change", e);
        }, k = (a, t, l) => {
            e.hasViewDetail && (V.value = a, b((() => {
                E.value = !0;
            })));
        };
        P((() => w.value), (e => {
            r.value = e;
        })), P((() => l.value), (e => {
            e || (r.value = w.value, m());
        })), P((() => d.value), (e => {
            p.value = e;
        })), P((() => u.value), (e => {
            m();
        }));
        return () => f("div", {
            class: "relation-form-table-format-mobile"
        }, [ f("div", null, [ S.value.length ? f(od, {
            displayRelationDataList: S.value,
            showFields: T.value,
            idDisabled: !e.allowCancelRelation || C.value,
            relationTableColumnList: L.value,
            onDeleteDataItem: F,
            onRowClick: k
        }, null) : "暂无数据", !C.value && f("div", {
            class: "buttons"
        }, [ e.hasChooseButton && e.allowHasSelect && f("div", {
            class: "select-button button-item",
            type: "primary",
            onClick: O
        }, [ D("选择数据") ]), e.isAllowCreate && f("div", {
            class: "add-button button-item",
            type: "primary",
            onClick: N
        }, [ D("新增") ]) ]) ]), !C.value && e.hasChooseButton && f(ed, {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e,
            active: u.value,
            "onUpdate:active": e => u.value = e,
            onSelectConfirm: M,
            onCancel: R
        }, {
            default: () => [ f("div", {
                class: "relation-form-table-format-mobile-main"
            }, [ p.value.length ? o(f(td, {
                data: p.value,
                columnList: L.value,
                hasSelection: !0,
                isMultiple: n.value,
                showPagination: !0,
                selectionList: S.value,
                total: e.dataTotal,
                showFields: T.value,
                "onUpdate:selectionList": I,
                onPageCurrentChange: x,
                onPageSizeChange: A
            }, null), [ [ a("loading"), c.value ] ]) : f("div", {
                class: "no-data"
            }, [ D("暂无数据") ]) ]) ]
        }), f(ld, {
            visible: E.value,
            "onUpdate:visible": e => E.value = e,
            formWidgets: e.formWidgets,
            formAppId: v.value,
            formData: V.value,
            pageCode: g.value,
            platformId: e.platformId,
            formReadOnly: !0,
            formRuntimeEnv: Q.DETAIL,
            isRemote: e.allowRemoteDetail,
            remoteDetailUrl: e.remoteDetailUrl,
            allowRequestParams: e.allowRequestParams,
            remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams
        }, null) ]);
    }
});

const rd = e({
    name: "MobileTableSelect",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    setup(e) {
        const a = s((() => e.modelValue)), t = s((() => e.disabled));
        return () => f("div", {
            class: "mobile-relation-form-table-select"
        }, [ f(V("ssu-select"), {
            modelValue: a.value,
            disabled: t.value
        }, null), f("div", {
            class: "select-hot-zone"
        }, null) ]);
    }
}), {formComponentMixin: sd} = z;

var ud = e({
    name: "RelationFormSelectFormatMobile",
    props: {
        selectedList: {
            type: Array,
            default: () => []
        },
        platformId: {
            type: String
        },
        appId: {
            type: String
        },
        formId: {
            type: String
        },
        isSingleMode: {
            type: Boolean,
            default: !1
        },
        isAllowCreate: {
            type: Boolean,
            default: !1
        },
        data: {
            type: Array,
            default: () => []
        },
        dataTotal: {
            type: Number
        },
        showFieldList: {
            type: Array,
            default: () => []
        },
        formWidgets: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: !1
        },
        hasDelete: {
            type: Boolean,
            default: !0
        },
        hasChooseButton: {
            type: Boolean,
            default: !0
        },
        formName: {
            type: String,
            default: ""
        },
        hasViewDetail: {
            type: Boolean,
            default: !0
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: []
        }
    },
    mixins: [ sd ],
    emits: [ "update:selectedList", "show-create-dialog", "page-current-change", "page-size-change", "row-click", "main-page-current-change", "main-page-size-change" ],
    setup(e, {emit: t}) {
        const l = i(!1), n = s((() => !e.isSingleMode)), r = i([]), u = i("option"), d = s((() => e.data)), p = i(e.data), m = () => {
            "select" === u.value ? p.value = d.value.filter((e => r.value.includes(e.id))) : p.value = d.value;
        }, c = i(!1), v = s((() => e.appId)), g = s((() => e.formId)), b = s({
            get: () => e.selectedList || [],
            set(e) {
                t("update:selectedList", e);
            }
        }), h = s((() => v.value && g.value)), y = s((() => !!e.disabled || !h.value)), C = s((() => {
            const e = b.value;
            if (!e) return [];
            return (Ja.isArray(e) ? e : Ja.isObject(e) ? e.id ? [ e ] : [] : null == e ? void 0 : e.split(",")).map((e => (null == e ? void 0 : e.id) || e));
        })), w = s((() => {
            const e = C.value;
            return d.value.filter((a => !!e.find((e => a.id === e))));
        })), S = s((() => e.showFieldList)), L = s((() => {
            let e = w.value, a = "";
            return Ja.isEmpty(e) ? "请选择" : (Ja.isArray(e) ? a = e[0][T.value[0].prop] : Ja.isObject(e) && (a = e[T.value[0].prop]), 
            n.value ? `已选择${a}等${e.length}个字段` : a);
        })), T = s((() => {
            const a = Ja.cloneDeep(e.showFieldList);
            return a.splice(0, 1), a;
        }));
        i(null), i(!1), s((() => e.formName || "查看详情"));
        const E = a => {
            if (e.isSingleMode) return void (r.value = Array.isArray(a) ? a : a.id);
            const t = a, l = t.filter((e => !r.value.includes(e))), o = Ja.difference(r.value, t);
            r.value = [ ...r.value, ...l ].filter((e => !o.includes(e))), m();
        }, I = () => {
            !y.value && (l.value = !0);
        }, F = () => {
            t("show-create-dialog");
        }, O = () => {
            b.value = r.value, l.value = !1;
        }, N = () => {
            l.value = !1;
        }, M = e => {
            t("page-current-change", e);
        }, R = e => {
            t("page-size-change", e);
        };
        P((() => C.value), (e => {
            r.value = e;
        })), P((() => l.value), (e => {
            e || (r.value = C.value);
        })), P((() => d.value), (e => {
            p.value = e;
        })), P((() => u.value), (e => {
            m();
        }));
        return () => f("div", {
            class: "relation-form-select-format-mobile"
        }, [ f(rd, {
            disabled: y.value,
            onClick: I,
            modelValue: L.value
        }, null), f(ed, {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e,
            active: u.value,
            "onUpdate:active": e => u.value = e,
            onSelectConfirm: O,
            onCancel: N
        }, {
            default: () => [ f("div", {
                class: "relation-form-select-format-mobile-main"
            }, [ p.value.length ? o(f(td, {
                data: p.value,
                columnList: S.value,
                hasSelection: !0,
                isMultiple: n.value,
                showPagination: !0,
                selectionList: w.value,
                total: e.dataTotal,
                showFields: T.value,
                "onUpdate:selectionList": E,
                onPageCurrentChange: M,
                onPageSizeChange: R
            }, null), [ [ a("loading"), c.value ] ]) : f("div", {
                class: "no-data"
            }, [ D("暂无数据") ]), !y.value && "select" !== u.value && f("div", {
                class: "relation-form-select-format-mobile-main-button",
                onClick: F
            }, [ f(V("syc-icon"), {
                type: "buttonIcons/button_35",
                color: "#fff"
            }, null), f("span", null, [ D("新增") ]) ]) ]) ]
        }) ]);
    }
});

const {formComponentMixin: dd} = z;

var pd = e({
    name: "OlRelationFormMobile",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        formId: {
            type: String,
            default: ""
        },
        showType: {
            type: String,
            default: "list"
        },
        mode: {
            type: String,
            default: Be.SINGLE
        },
        allowCreate: {
            type: Boolean,
            default: !1
        },
        showFields: {
            type: Array,
            default: () => []
        },
        conditions: {
            type: Object,
            default: () => ({})
        },
        formName: {
            type: String,
            default: ""
        },
        allowCancelRelation: {
            type: Boolean,
            default: !0
        },
        allowHasSelect: {
            type: Boolean,
            default: !0
        }
    },
    mixins: [ dd ],
    emits: [ "update:modelValue", "dataSourceUpdate" ],
    setup(e, {emit: a}) {
        const t = le(), l = be(), {conditionRulesAdaptor: o, checkConditionRulesFieldType: n} = wa(), r = i(null), u = i([]), d = i(!1), p = i([]), m = i(!1), c = i(0), v = i({
            pageNum: 1,
            pageSize: 10
        }), g = s((() => JSON.parse(JSON.stringify(e.conditions).replaceAll(`${$e}.`, "").replaceAll(`${ze}.`, "")))), b = s((() => {
            var a, t;
            const l = null === (a = e.metaData) || void 0 === a || null === (t = a.props) || void 0 === t ? void 0 : t.appId;
            return Ja.isObject(l) ? l.rawValue : l;
        })), h = s((() => e.formId)), y = s((() => e.conditions)), D = s((() => e.mode === Be.SINGLE)), C = s((() => e.inSubForm ? We.SELECT : e.showType)), S = s((() => e.allowCreate)), L = s({
            get: () => e.modelValue,
            set(e) {
                a("update:modelValue", e);
            }
        }), T = s((() => b.value && h.value)), V = s((() => {
            var a;
            const t = (null === (a = r.value) || void 0 === a ? void 0 : a.toColumnList()) || [];
            return (e.showFields || []).map((e => t.find((a => {
                var t;
                return (null == a || null === (t = a.metaData) || void 0 === t ? void 0 : t.fieldName) === e;
            })))).filter((e => !!e));
        })), E = e => {
            L.value = e;
        }, I = e => {
            if (!T.value || d.value) return;
            let l;
            return Ja.isEmpty(e) || (l = e.condition ? e : {
                condition: "AND",
                ...e
            }), d.value = !0, t.form.getListWithoutScene({
                appId: b.value,
                modelCode: h.value,
                ...v.value,
                filter: l
            }).then((e => {
                const {result: t = [], total: l} = e;
                a("dataSourceUpdate", t || []), u.value = t || [], c.value = l;
            })).finally((() => {
                d.value = !1;
            }));
        }, F = () => {
            m.value = !0;
        }, O = e => {
            ((e, a, l) => t.form.createWithoutScene({
                appId: e,
                modelCode: a,
                ...l
            }))(b.value, h.value, e).then((e => {
                var a;
                const t = e;
                null === (a = I(g.value)) || void 0 === a || a.then((() => {
                    let e = L.value;
                    D.value ? e = [ t ] : e.push(t), L.value = e;
                }));
            }));
        };
        return P((() => e.formData), (e => {
            var a;
            n(null === (a = g.value) || void 0 === a ? void 0 : a.rules) && (g.value = o(g.value.rules, e), 
            I(g.value));
        }), {
            deep: !0
        }), P((() => C.value), (e => {
            e === We.SELECT && (v.value.pageSize = "65535");
        }), {
            immediate: !0
        }), w((() => {
            var a, i, s;
            if (!T.value) return;
            i = b.value, s = h.value, i && s && t.metaData.getPageMetaData({
                appId: i,
                pageCode: s
            }).then((e => {
                const {widgets: a, systemAttributes: t} = e.content || {};
                r.value = new Oe({
                    libManager: l,
                    widgets: a || [],
                    messages: [],
                    systemWidgets: t || [],
                    language: "zh-cn"
                }), p.value = a;
            }));
            var u;
            n(null === (a = g.value) || void 0 === a ? void 0 : a.rules) && (g.value = {
                ...g.value,
                rules: o(null === (u = g.value) || void 0 === u ? void 0 : u.rules, e.formData)
            });
            I(g.value);
        })), () => f("div", {
            class: "ol-relation-form-mobile"
        }, [ C.value === We.LIST && f(nd, {
            isAllowMainTemporarySortable: !0,
            disabled: e.disabled,
            selectedList: L.value,
            appId: b.value,
            formId: h.value,
            conditions: y.value,
            isSingleMode: D.value,
            isAllowCreate: S.value,
            data: u.value,
            dataTotal: c.value,
            showFieldList: V.value,
            formWidgets: p.value,
            formName: e.formName,
            allowCancelRelation: e.allowCancelRelation,
            allowHasSelect: e.allowHasSelect,
            onShowCreateDialog: F,
            "onUpdate:selectedList": E
        }, null), C.value === We.SELECT && f(ud, {
            disabled: e.disabled,
            selectedList: L.value,
            appId: b.value,
            formId: h.value,
            isSingleMode: D.value,
            isAllowCreate: S.value,
            data: u.value,
            dataTotal: c.value,
            showFieldList: V.value,
            formWidgets: p.value,
            formName: e.formName,
            onShowCreateDialog: F,
            "onUpdate:selectedList": E
        }, null), S.value && f(ld, {
            visible: m.value,
            "onUpdate:visible": e => m.value = e,
            formWidgets: p.value,
            formAppId: b.value,
            pageCode: h.value,
            platformId: e.platformId,
            saveCb: O
        }, null) ]);
    }
});

const {propsFormMixin: md} = z;

var cd = e({
    name: "OlRelationFormPropsForm",
    mixins: [ md ],
    emits: [ "to-form-design" ],
    setup(e, {emit: a, expose: t}) {
        const l = s((() => e.metaData.props.formName.rawValue)), o = s((() => e.metaData.props.appId.rawValue)), n = s((() => {
            var a;
            return null === (a = e.metaData.props.formId) || void 0 === a ? void 0 : a.rawValue;
        })), r = s({
            get: () => e.metaData.props.showFields.rawValue,
            set(a) {
                e.metaData.props.showFields.rawValue = a;
            }
        }), u = s((() => e.metaData.validateRuleConfig)), {data: d, formRef: p} = fa(e, t), {convertOptionListToObj: m, convertObjToOptionList: c} = H, v = s((() => i(c({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), g = () => {
            a("to-form-design", {
                platformId: e.platformId,
                appId: o.value,
                pageCode: n.value,
                title: l.value
            });
        };
        return () => f(V("el-form"), {
            ref: p,
            model: d,
            "label-position": "top",
            class: "ol-relation-form-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f("div", {
                class: "relation-form-wrapper link",
                onClick: g
            }, [ f(V("syc-icon"), {
                width: 16,
                height: 16,
                type: "designer/guanlianbiaodan",
                class: "icon-relation-form active"
            }, null), f("label", {
                class: "relation-form-name",
                style: {
                    marginLeft: "8px"
                }
            }, [ l.value ]) ]), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("RelationFormDefault"), {
                pageData: e.pageData,
                appData: e.appData,
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("mode-setter"), {
                metaData: e.metaData,
                title: "关联记录数量",
                disabled: e.metaData.saved
            }, null), f(V("relation-form-setter"), {
                pageData: e.pageData,
                metaData: e.metaData,
                showFieldsList: r.value,
                "onUpdate:showFieldsList": e => r.value = e,
                pageMetaData: e.pageMetaData,
                inSubForm: e.inSubForm
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: u.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: v.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = m(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: vd, metaDataMixin: fd, formComponentMixin: gd} = z;

var bd = e({
    props: {
        platformId: {
            type: String,
            default: ""
        },
        formId: {
            type: String,
            default: ""
        },
        showFields: {
            type: Array,
            default: () => []
        },
        showType: {
            type: String,
            default: ""
        },
        formName: {
            type: String,
            default: ""
        },
        mode: {
            type: String,
            default: ""
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        filterConfig: {
            type: Object,
            default: () => ({})
        }
    },
    name: "OlRelationFormFormatter",
    mixins: [ vd, fd, gd ],
    components: {
        RenderComponent: Lu,
        RelationFormTableFormat: _u,
        MobileRelationFormTableCard: od
    },
    setup(e, a) {
        const {warning: t} = ca(), l = le(), o = s((() => {
            var a, t;
            const l = null === (a = e.metaData) || void 0 === a || null === (t = a.props) || void 0 === t ? void 0 : t.appId;
            return Ja.isObject(l) ? l.rawValue : l;
        })), n = s((() => e.formId)), r = s((() => e.conditions)), {pageEngine: u, relationFormFieldList: d, relationTitleField: p} = Ea(o, n), {widgetsCacheGroup: m, systemAttributesCacheGroup: c, titleFieldCacheGroup: v} = Va(n), g = be(), b = s((() => {
            var e;
            return (null === (e = u.value) || void 0 === e ? void 0 : e.toColumnList()) || [];
        })), h = i(!1), y = i(!1), D = i(null), C = s((() => e.mode === Be.SINGLE)), S = s((() => {
            const a = e.showFields.map((e => b.value.find((a => a.metaData.fieldName === e)))).filter((e => !!e));
            if (a.length) {
                var t, l;
                const e = null != p && p.value ? p.value : null === (t = a[0]) || void 0 === t || null === (l = t.metaData) || void 0 === l ? void 0 : l.titleField, o = a.findIndex((a => a.prop === e));
                a.unshift(a[o]), a.splice(o + 1, 1);
            }
            return a.map((e => {
                var a;
                let t = Ge.includes(null == e || null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.code);
                return {
                    ...e,
                    sortable: t
                };
            }));
        })), L = s((() => e.showType)), T = s((() => e.showFields[0])), V = i(e.fieldValue || []), I = i(V.value), F = s((() => {
            const a = e.fieldValue;
            let t = "";
            return Ja.isNil(a) ? [ Y ] : Array.isArray(a) ? (t = a.filter((e => !Ja.isNil(e))).map((a => {
                var t, l, i, n;
                const r = Ja.get(a, [ T.value ]), s = b.value.find((e => {
                    var a;
                    return (null == e || null === (a = e.metaData) || void 0 === a ? void 0 : a.fieldName) === T.value;
                }));
                var u;
                return null != s && null !== (t = s.defineMetaData) && void 0 !== t && t.code ? f("label", {
                    onClick: () => O(a)
                }, [ f(Lu, E({
                    runtimeEnv: e.runtimeEnv,
                    formEntityId: null == a ? void 0 : a.id,
                    formData: a,
                    fieldValue: a[null === (l = s.metaData) || void 0 === l ? void 0 : l.fieldName],
                    widgetMap: s.widgetMap,
                    isMobile: e.isMobile,
                    code: (null === (i = s.defineMetaData) || void 0 === i ? void 0 : i.tableFormatter) || (null === (n = s.defineMetaData) || void 0 === n ? void 0 : n.widget),
                    appId: o.value,
                    defineMetaData: s.defineMetaData,
                    metaData: s.metaData,
                    label: s.name
                }, s.props, {
                    readOnly: !0,
                    pageMetaData: b.value,
                    style: {
                        cursor: "pointer"
                    }
                }), null) ]) : f("label", {
                    style: {
                        cursor: "pointer"
                    },
                    onClick: () => O(a)
                }, [ Ja.isNil(r) ? Y : null === (u = r.toString) || void 0 === u ? void 0 : u.call(r) ]);
            })), t) : [ Y ];
        })), O = async a => {
            if (e.runtimeEnv === Q.TABLE) return;
            await l.form.getDataSummary({
                appId: o.value,
                modelCode: n.value,
                id: a.id
            }) ? (y.value = !0, D.value = a) : t("暂无数据权限");
        }, N = ({column: e, prop: a, order: t}) => {
            V.value = V.value.map(((e, a) => ({
                index: a,
                ...e
            }))), V.value.sort(((e, l) => {
                if (!t) return e.index > l.index ? -1 : 1;
                const o = "descending" === t;
                return e[a] > l[a] ? o ? -1 : 1 : o ? 1 : -1;
            }));
        }, M = (e, a) => {
            var t, o, i;
            if (!h.value && e && a) if (null !== (t = c.value) && void 0 !== t && null !== (o = t.toList) && void 0 !== o && null !== (i = o.call(t)) && void 0 !== i && i.length) {
                var n, r;
                const e = d.value || [], a = (null === (n = c.value) || void 0 === n || null === (r = n.toList) || void 0 === r ? void 0 : r.call(n)) || [];
                u.value = new Oe({
                    libManager: g,
                    widgets: e.concat(a),
                    messages: [],
                    language: "zh-cn"
                });
            } else h.value = !0, l.metaData.getPageMetaData({
                appId: e,
                pageCode: a
            }).then(((e = {}) => {
                var t;
                const {widgets: l = [], systemAttributes: o = [], titleField: i} = (null == e ? void 0 : e.content) || {};
                l.forEach((e => {
                    var t, l;
                    null === (t = m.value) || void 0 === t || null === (l = t.addCache) || void 0 === l || l.call(t, {
                        cacheKey: a,
                        ...e
                    });
                })), o.forEach((e => {
                    var t, l;
                    null === (t = c.value) || void 0 === t || null === (l = t.addCache) || void 0 === l || l.call(t, {
                        cacheKey: a,
                        ...e
                    });
                })), null === (t = v.value) || void 0 === t || t.addCache({
                    cacheKey: "titleField",
                    titleField: i,
                    id: "titleField"
                }), u.value = new Oe({
                    libManager: g,
                    widgets: l.concat(o),
                    messages: [],
                    language: "zh-cn"
                });
            })).finally((() => {
                h.value = !1;
            }));
        };
        return P((() => o.value), (e => {
            n.value && M(e, n.value);
        })), P((() => n.value), (e => {
            o.value && M(o.value, e);
        })), P((() => e.fieldValue), (e => {
            V.value = e || [];
        }), {
            deep: !0
        }), P((() => V.value), (() => {
            I.value = V.value;
        })), w((() => {})), () => f(R, null, [ f("label", {
            class: "ol-relation-form-formatter"
        }, [ e.runtimeEnv === Q.TABLE || L.value !== We.LIST || e.inSubForm ? F.value.map(((e, a) => a > 0 ? f(R, null, [ f("label", null, [ ie ]), e ]) : e)) : e.isMobile ? f(od, {
            showFields: S.value,
            displayRelationDataList: V.value,
            idDisabled: !0,
            relationTableColumnList: S.value
        }, null) : f(_u, {
            allowFilter: a.attrs.allowFilter,
            runtimeEnv: e.runtimeEnv,
            isAllowMainTemporarySortable: !0,
            filterConfig: e.filterConfig,
            data: V.value,
            disabled: !0,
            isAllowCreate: !1,
            selectedList: I.value,
            appId: o.value,
            pageCode: e.pageCode,
            metaData: e.metaData,
            formId: n.value,
            conditions: r.value,
            isSingleMode: C.value,
            isMainSortable: !0,
            showFieldList: S.value,
            formWidgets: d.value,
            formName: e.formName,
            allowRemoteDetail: e.allowRemoteDetail,
            remoteDetailUrl: e.remoteDetailUrl,
            allowRequestParams: e.allowRequestParams,
            remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams,
            onMainSortChange: N
        }, null) ]), y.value && f(Nu, {
            title: e.formName,
            visible: y.value,
            "onUpdate:visible": e => y.value = e,
            formWidgets: d.value,
            formAppId: o.value,
            formData: D.value,
            pageCode: n.value,
            platformId: e.platformId,
            formReadOnly: !0,
            formRuntimeEnv: Q.DETAIL,
            footerVisible: !1,
            isRemote: e.allowRemoteDetail,
            remoteDetailUrl: e.remoteDetailUrl,
            allowRequestParams: e.allowRequestParams,
            remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams
        }, null) ]);
    }
}), hd = e({
    name: "OlRelationFormPrependForm",
    emits: [ "update:modelValue" ],
    props: {
        platformId: {
            type: String,
            required: !0,
            default: ""
        },
        pageData: {
            type: Object,
            default: () => ({})
        },
        appData: {
            type: Object,
            default: () => ({})
        },
        runtimePlatform: {
            type: String,
            default: fe.PAAS
        }
    },
    setup(e, {expose: t}) {
        const l = le(), n = i([]), r = i([]), d = i(!1), p = i(!1), m = s((() => e.pageData.code)), c = u({
            appId: "",
            form: null
        }), v = i(null);
        return P((() => e.platformId), (a => {
            a && (d.value || (d.value = !0, l.admin.getAppPage({
                platformId: e.platformId,
                keyword: "",
                pageNum: 1,
                pageSize: 500
            }).then((e => {
                n.value = (e.result || []).filter((e => e.type === He)).map((e => ({
                    ...e,
                    label: e.name,
                    value: e.id
                })));
            })).finally((() => d.value = !1))));
        }), {
            immediate: !0
        }), P((() => c.appId), (a => {
            var t;
            a && (t = a, p.value || (p.value = !0, l.admin.getPageList({
                appId: t,
                preview: e.runtimePlatform === fe.PAAS
            }).then((e => {
                const a = [];
                ka(e, {
                    visit: e => {
                        e.pageType !== Qe.NORMAL_FORM && e.pageType !== Qe.FLOW_FORM || a.push(e);
                    }
                }), r.value = a.filter((e => e.code === m.value)).map((e => ({
                    ...e,
                    label: e.name + "(本表单)",
                    value: e
                }))).concat(a.filter((e => e.code !== m.value)).map((e => ({
                    ...e,
                    label: e.name,
                    value: e
                }))));
            })).finally((() => p.value = !1))));
        }), {
            immediate: !0
        }), P((() => e.appData.id), (e => {
            c.appId = e;
        }), {
            immediate: !0
        }), t({
            getValue: () => {
                var e, a, t;
                return {
                    ...c,
                    formId: null === (e = c.form) || void 0 === e ? void 0 : e.code,
                    formName: null === (a = c.form) || void 0 === a ? void 0 : a.name,
                    pageType: null === (t = c.form) || void 0 === t ? void 0 : t.pageType
                };
            },
            validate: () => v.value.validate(),
            getInnerRef: () => v.value
        }), () => f(V("el-form"), {
            ref: v,
            model: c,
            "label-position": "top",
            class: "form-relation-subform",
            rules: {
                appId: [ {
                    required: !0,
                    message: "请选择应用",
                    trigger: [ "blur" ]
                } ],
                form: [ {
                    required: !0,
                    message: "请选择表单",
                    trigger: [ "blur" ]
                } ]
            }
        }, {
            default: () => [ f(V("el-form-item"), {
                prop: "appId",
                label: "应用"
            }, {
                default: () => [ o(f(V("ssu-select"), {
                    modelValue: c.appId,
                    "onUpdate:modelValue": e => c.appId = e,
                    class: "select-style",
                    filterable: !0,
                    "option-data": n.value
                }, null), [ [ a("loading"), d.value ] ]) ]
            }), f(V("el-form-item"), {
                prop: "form",
                label: "表单"
            }, {
                default: () => [ f(V("ssu-select"), {
                    class: "select-style",
                    modelValue: c.form,
                    "onUpdate:modelValue": e => c.form = e,
                    "option-data": r.value,
                    "value-key": "id"
                }, null) ]
            }) ]
        });
    }
}), yd = {
    code: K.RELATION_FORM,
    name: "关联表单",
    group: X.HIGH_LEVEL,
    icon: "guanlianbiaodan",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlRelationForm",
    mobileWidget: "OlRelationFormMobile",
    propsForm: "OlRelationFormPropsForm",
    tableFormatter: "OlRelationFormFormatter",
    props: {
        placeholder: "请选择",
        appId: void 0,
        formId: void 0,
        mode: "single",
        showType: "list",
        appShowType: "list",
        showFields: [],
        conditions: {},
        allowCreate: !1,
        allowHasSelect: !0,
        allowCancelRelation: !0,
        allowRemoteDetail: !1,
        remoteDetailUrl: "",
        allowRequestParams: !1,
        remoteDetailUrlRequestParams: [],
        twoWay: !1,
        twoWayObject: {},
        allowFilter: !1,
        filterConfig: {},
        pageType: "",
        formName: ""
    },
    extraValues: [ {
        fieldName: je,
        dataType: Z.ARRAY,
        name: {
            type: "raw",
            key: "name",
            rawValue: "选中数据值"
        },
        fieldLevel: 1,
        isTransparent: !0
    } ],
    defaultValue: [],
    prependAction: {
        type: "props",
        form: "OlRelationFormPrependForm",
        dialogTitle: "关联表单",
        width: "450px",
        height: "188px"
    },
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.RELATION_FORM ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Ju.meta = yd, Ju.PropsForm = cd, Ju.Mobile = pd, Ju.install = e => {
    e.component(Ju.name, Ju), e.component(cd.name, cd), e.component(pd.name, pd), e.component(bd.name, bd), 
    e.component(hd.name, hd);
}, Ju.widgetMap = {
    [pd.name]: pd,
    [Ju.name]: Ju,
    [cd.name]: cd,
    [bd.name]: bd,
    [hd.name]: hd
};

const {formComponentMixin: Dd} = z;

var Cd = e({
    name: "OlRelationFormField",
    props: {
        foreignFieldName: {
            type: String,
            default: ""
        },
        foreignFormFieldName: {
            type: String,
            default: ""
        },
        foreignFormAppId: {
            type: String,
            default: ""
        },
        foreignFormId: {
            type: String,
            default: ""
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        isMobile: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Dd ],
    emits: [ "update:modelValue" ],
    components: {
        RenderComponent: Lu
    },
    setup(e, a) {
        const {pageEngine: t} = Ea(i(e.foreignFormAppId), i(e.foreignFormId)), l = s((() => t.value?.toColumnList() || [])), o = s((() => l.value.find((a => a.fieldName === e.foreignFormFieldName || a.metaData?.fieldName === e.foreignFormFieldName)))), n = s((() => o.value?.props?.appId)), r = s((() => o.value?.props?.formId)), u = s((() => o.value?.props?.conditions)), {relationFormListCacheGroup: d, initRelationFormQueryData: p} = Ta(n, r, u, e, a), m = s((() => e.formData ?? {})), c = s((() => e.metaData.fieldName)), v = s((() => {
            if ([ Q.CREATE, Q.EDIT, Q.PROCESS_AUDIT ].includes(e.runtimeEnv)) return m.value[`${e.foreignFieldName}${je}`]?.[0] || m.value[`${e.foreignFieldName}`]?.[0] || {};
            const a = JSON.parse(JSON.stringify(m.value).replaceAll(c.value, o.value?.metaData?.fieldName || o.value?.fieldName || ""));
            return m.value[`${e.foreignFieldName}${je}`]?.[0] || m.value[`${e.foreignFieldName}`]?.[0] || a;
        })), f = s((() => d.value?.toList() || [])), g = s((() => [ Q.CREATE, Q.EDIT, Q.PROCESS_AUDIT ].includes(e.runtimeEnv) ? v.value[e.foreignFormFieldName] : v.value[e.foreignFormFieldName] ?? m.value[c.value])), b = s((() => {
            const a = v.value[e.foreignFormFieldName];
            return Ae(o.value?.code || o.value?.defineMetaData?.code) && Ja.isString(v.value[e.foreignFormFieldName]?.[0]) ? f.value.filter((e => a.includes(e.id))) : g.value;
        })), h = s((() => Ja.isNil(v.value[e.foreignFormFieldName]) ? Ja.isNil(m.value[c.value]) ? Y : m.value[c.value] : v.value[e.foreignFormFieldName])), y = s((() => {
            const a = o.value?.defineMetaData?.code;
            return Ye(a) ? e.runtimeEnv : Q.TABLE;
        }));
        return P((() => g.value), ((e, t) => {
            Ja.isEqual(e, t) || (a.emit("update:modelValue", e), setTimeout((() => {
                if (!Ae(o.value?.code || o.value?.defineMetaData?.code) || !Ja.isString(g.value?.[0])) return;
                const e = g.value.filter((e => !f.value.find((a => a.id === e))));
                e.length && p({
                    condition: "AND",
                    rules: [ {
                        field: "id",
                        operator: "in",
                        value: e,
                        type: "string",
                        valueType: "fixed"
                    } ]
                }, "push").then((e => {
                    e?.result?.forEach?.((e => {
                        d?.value?.addCache?.({
                            ...e
                        });
                    }));
                }));
            }), 200));
        }), {
            immediate: !0,
            deep: !0
        }), {
            recordData: v,
            relationFormField: o,
            displayValue: b,
            fieldTextValue: h,
            renderRuntimeEnv: y,
            fieldName: c
        };
    }
});

const wd = {
    class: "ol-relation-form-field"
}, Sd = {
    key: 1
};

Cd.render = function(e, a, o, i, n, r) {
    const s = V("RenderComponent");
    return t(), l("div", wd, [ e.relationFormField?.code || e.relationFormField?.formatterCode ? (t(), 
    L(s, E({
        key: 0
    }, e.relationFormField?.props, {
        appId: e.foreignFormAppId,
        runtimeEnv: e.renderRuntimeEnv,
        formEntityId: e.recordData.id,
        formData: e.recordData,
        fieldValue: e.displayValue,
        widgetMap: e.relationFormField?.libWidgetMap || e.relationFormField?.widgetMap,
        isMobile: e.isMobile,
        modelValue: e.recordData[e.foreignFormFieldName],
        "onUpdate:modelValue": a[0] || (a[0] = a => e.recordData[e.foreignFormFieldName] = a),
        code: e.relationFormField?.defineMetaData?.tableFormatter || e.relationFormField?.defineMetaData?.widget,
        defineMetaData: e.relationFormField?.defineMetaData,
        metaData: e.relationFormField,
        label: e.relationFormField?.name || e.relationFormField?.label,
        readOnly: !0
    }), null, 16, [ "appId", "runtimeEnv", "formEntityId", "formData", "fieldValue", "widgetMap", "isMobile", "modelValue", "code", "defineMetaData", "metaData", "label" ])) : (t(), 
    l("label", Sd, O(e.fieldTextValue), 1)) ]);
};

const {propsFormMixin: Ld} = z;

var Td = e({
    name: "OlRelationFormFieldPropsForm",
    mixins: [ Ld ],
    setup(e, {expose: a}) {
        const {data: t, formRef: l} = fa(e, a), {convertOptionListToObj: o, convertObjToOptionList: n} = H, r = s((() => i(n({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: l,
            model: t,
            "label-position": "top",
            class: "ol-relation-form-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("relation-form-field-setter"), {
                pageData: e.pageData,
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: r.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = o(a, G.options, !0), {editable: l, visible: i, visibleWhenCreate: n} = t;
                    e.metaData.editable = l, e.metaData.visible = i, e.metaData.visibleWhenCreate = n;
                }
            }, null) ]
        });
    }
}), Vd = {
    code: "RelationFormField",
    name: "关联表字段",
    group: X.HIGH_LEVEL,
    icon: "guanlianbiaoziduan",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlRelationFormField",
    propsForm: "OlRelationFormFieldPropsForm",
    props: {
        foreignFieldName: "",
        foreignFormFieldName: "",
        foreignFormAppId: "",
        foreignFormId: ""
    },
    defaultValue: void 0,
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ]
};

Cd.meta = Vd, Cd.PropsForm = Td, Cd.install = e => {
    e.component(Cd.name, Cd), e.component(Td.name, Td);
}, Cd.widgetMap = {
    [Cd.name]: Cd,
    [Td.name]: Td
};

const {formComponentMixin: Ed} = z;

var Id = e({
    name: "OlRelationFormQuery",
    components: {
        BaseFormDialog: Nu,
        RelationFormTableFormat: _u
    },
    props: {
        formData: {
            type: Object,
            default: () => ({})
        },
        formId: {
            type: String,
            default: ""
        },
        allowCreate: {
            type: Boolean,
            default: !1
        },
        showFields: {
            type: Array,
            default: () => []
        },
        formName: {
            type: String,
            default: ""
        },
        conditions: {
            type: Object,
            default: () => ({})
        },
        allowRemoteDetail: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrl: {
            type: String,
            default: ""
        },
        allowRequestParams: {
            type: Boolean,
            default: !1
        },
        remoteDetailUrlRequestParams: {
            type: Array,
            default: () => []
        },
        allowFilter: {
            type: Boolean,
            default: !1
        },
        filterConfig: {
            type: Object,
            default: () => ({})
        }
    },
    mixins: [ Ed ],
    setup(e, {emit: a}) {
        const t = le(), l = be(), {conditionRulesAdaptor: o, checkConditionRulesFieldType: n} = wa(), r = i(null), u = i([]), d = i(!1), p = i([]), m = i([]), c = i(!1), v = i(0), f = i({
            pageNum: 1,
            pageSize: 10
        }), g = s((() => Wa(e.formData))), b = s((() => JSON.parse(JSON.stringify(e.conditions).replaceAll(`${$e}.`, "").replaceAll(`${ze}.`, "")))), h = s((() => {
            const a = e.metaData?.props?.appId;
            return Ja.isObject(a) ? a.rawValue : a;
        })), y = s((() => e.formId)), D = s((() => e.allowCreate)), C = s((() => h.value && y.value)), S = s((() => {
            const a = r.value?.toColumnList() || [];
            return (e.showFields || []).map((e => a.find((a => a?.metaData?.fieldName === e)))).filter((e => !!e)).map((e => {
                let a = Ge.includes(e?.defineMetaData?.code);
                return {
                    ...e,
                    sortable: a
                };
            }));
        })), L = e => {
            if (!e?.rules?.length) return void (u.value = []);
            if (!C.value) return;
            let l;
            Ja.isEmpty(e) || (l = e.condition ? e : {
                condition: "AND",
                ...e
            });
            return n(l.rules) && (l = {
                ...l,
                rules: o(l.rules, g.value)
            }), d.value = !0, t.form.getListWithoutScene({
                appId: h.value,
                modelCode: y.value,
                ...f.value,
                dataPermission: !1,
                filter: l,
                cache: !1
            }).then((e => {
                const {result: t = [], total: l} = e;
                return a("dataSourceUpdate", t || []), u.value = t || [], v.value = l, e;
            })).finally((() => {
                d.value = !1;
            }));
        };
        return P((() => [ g.value, b.value ]), Ja.debounce(((e, a) => {
            Ja.isEqual(e, a) || L(b.value);
        }), 100), {
            deep: !0
        }), w((() => {
            if (!C.value) return;
            var e, a;
            e = h.value, a = y.value, e && a && ct([ "pageMetaData", e, a ], (() => t.metaData.getPageMetaData({
                appId: e,
                pageCode: a
            })), {
                onSuccess(e) {
                    const {widgets: a, systemAttributes: t} = e.content || {};
                    r.value = new Oe({
                        libManager: l,
                        widgets: a || [],
                        messages: [],
                        systemWidgets: t || [],
                        language: "zh-cn"
                    }), p.value = a, m.value = t;
                }
            });
            n(b.value?.rules) && (b.value = {
                ...b.value,
                rules: o(b.value?.rules, g.value)
            }), L(b.value);
        })), {
            relationFormQueryData: u,
            relationFormFieldList: p,
            relationFormSystemFieldList: m,
            createFormDialogVisible: c,
            relationFormAppId: h,
            relationFormId: y,
            isAllowCreate: D,
            relationTableColumnList: S,
            handleShowFormDialog: () => {
                c.value = !0;
            },
            handleCreateFormDialogVisibleChange: e => {
                c.value = e;
            },
            handleCreateFormData: e => {
                ((e, a, l) => t.form.createWithoutScene({
                    appId: e,
                    modelCode: a,
                    ...l
                }))(h.value, y.value, e).then((e => {
                    L(b.value);
                }));
            },
            handlePageChange: e => {
                f.value.pageNum = e, L(b.value);
            },
            handlePageSizeChange: e => {
                f.value.pageSize = e, f.value.pageNum = 1, L(b.value);
            },
            handleSortChange: ({prop: e, order: a}) => {
                f.value.sort = a ? `${e}${"descending" == a ? "-" : "+"}` : void 0, L(b.value);
            },
            handleKeyWordFilter: e => {
                f.value.keyword = e, L(b.value);
            },
            handleFilter: (e = {}) => {
                const a = {
                    ...b.value,
                    rules: (b.value.rules || []).concat(e.rules || [])
                };
                L(a);
            },
            handleRefresh: () => {
                L(b.value);
            },
            relationFormQueryDataTotal: v
        };
    }
});

const Fd = {
    class: "ol-relation-form-query"
};

Id.render = function(e, a, o, i, n, r) {
    const s = V("RelationFormTableFormat"), u = V("BaseFormDialog");
    return t(), l("div", Fd, [ f(s, {
        disabled: e.disabled,
        selectedList: e.relationFormQueryData,
        appId: e.relationFormAppId,
        pageCode: e.pageCode,
        metaData: e.metaData,
        formId: e.relationFormId,
        conditions: e.conditions,
        isAllowCreate: e.isAllowCreate,
        data: e.relationFormQueryData,
        dataTotal: e.relationFormQueryDataTotal,
        showFieldList: e.relationTableColumnList,
        formWidgets: e.relationFormFieldList,
        systemsWidgets: e.relationFormSystemFieldList,
        formName: e.formName,
        hasChooseButton: !1,
        hasDelete: !1,
        showPagination: !0,
        isMainSortable: !0,
        allowRemoteDetail: e.allowRemoteDetail,
        remoteDetailUrl: e.remoteDetailUrl,
        allowRequestParams: e.allowRequestParams,
        remoteDetailUrlRequestParams: e.remoteDetailUrlRequestParams,
        allowFilter: e.allowFilter,
        runtimeEnv: e.runtimeEnv,
        filterConfig: {
            ...e.filterConfig
        },
        onShowCreateDialog: e.handleShowFormDialog,
        onMainPageCurrentChange: e.handlePageChange,
        onMainPageSizeChange: e.handlePageSizeChange,
        onMainSortChange: e.handleSortChange,
        onKeywordFilter: e.handleKeyWordFilter,
        onFilter: e.handleFilter,
        onMainRefresh: e.handleRefresh
    }, null, 8, [ "disabled", "selectedList", "appId", "pageCode", "metaData", "formId", "conditions", "isAllowCreate", "data", "dataTotal", "showFieldList", "formWidgets", "systemsWidgets", "formName", "allowRemoteDetail", "remoteDetailUrl", "allowRequestParams", "remoteDetailUrlRequestParams", "allowFilter", "runtimeEnv", "filterConfig", "onShowCreateDialog", "onMainPageCurrentChange", "onMainPageSizeChange", "onMainSortChange", "onKeywordFilter", "onFilter", "onMainRefresh" ]), e.isAllowCreate && e.createFormDialogVisible ? (t(), 
    L(u, {
        key: 0,
        title: "新增数据",
        visible: e.createFormDialogVisible,
        "onUpdate:visible": e.handleCreateFormDialogVisibleChange,
        formWidgets: e.relationFormFieldList,
        formAppId: e.relationFormAppId,
        pageCode: e.relationFormId,
        platformId: e.platformId,
        saveCb: e.handleCreateFormData
    }, null, 8, [ "visible", "onUpdate:visible", "formWidgets", "formAppId", "pageCode", "platformId", "saveCb" ])) : F("", !0) ]);
};

const {propsFormMixin: Od} = z;

var Nd = e({
    name: "OlRelationFormQueryPropsForm",
    mixins: [ Od ],
    emits: [ "to-form-design" ],
    setup(e, {emit: a, expose: t}) {
        const l = s((() => {
            var a;
            return null === (a = e.metaData.props.formName) || void 0 === a ? void 0 : a.rawValue;
        })), o = s((() => e.metaData.props.appId.rawValue)), n = s((() => {
            var a;
            return null === (a = e.metaData.props.formId) || void 0 === a ? void 0 : a.rawValue;
        })), r = s({
            get: () => e.metaData.props.showFields.rawValue,
            set(a) {
                e.metaData.props.showFields.rawValue = a;
            }
        });
        s((() => e.metaData.validateRuleConfig));
        const {data: u, formRef: d} = fa(e, t), {convertOptionListToObj: p, convertObjToOptionList: m} = H, c = s((() => i(m({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), v = () => {
            a("to-form-design", {
                platformId: e.platformId,
                appId: o.value,
                pageCode: n.value,
                title: l.value
            });
        };
        return () => f(V("el-form"), {
            ref: d,
            model: u,
            "label-position": "top",
            class: "ol-relation-form-query-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f("div", {
                class: "relation-form-wrapper link",
                onClick: v
            }, [ f(V("syc-icon"), {
                width: 16,
                height: 16,
                type: "designer/guanlianbiaodan",
                class: "icon-relation-form active"
            }, null), f("label", {
                class: "relation-form-name",
                style: {
                    marginLeft: "8px"
                }
            }, [ l.value ]) ]), f(V("relation-form-setter"), {
                hasShowType: !1,
                hasTwoObject: !1,
                hasAllowHasSelect: !1,
                hasAllowCancelRelation: !1,
                pageData: e.pageData,
                metaData: e.metaData,
                showFieldsList: r.value,
                "onUpdate:showFieldsList": e => r.value = e,
                pageMetaData: e.pageMetaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: c.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = p(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
}), Md = e({
    name: "OlRelationFormQueryPrependForm",
    emits: [ "update:modelValue" ],
    props: {
        platformId: {
            type: String,
            required: !0,
            default: ""
        },
        pageData: {
            type: Object,
            default: () => ({})
        },
        appData: {
            type: Object,
            default: () => ({})
        },
        runtimePlatform: {
            type: String,
            default: fe.PAAS
        }
    },
    setup(e, {expose: t}) {
        const l = le(), n = i([]), r = i([]), d = i(!1), p = i(!1), m = s((() => e.pageData.code)), c = u({
            appId: "",
            form: null
        }), v = i(null);
        return P((() => e.platformId), (a => {
            a && (d.value || (d.value = !0, l.admin.getAppPage({
                platformId: e.platformId,
                keyword: "",
                pageNum: 1,
                pageSize: 500
            }).then((e => {
                n.value = (e.result || []).filter((e => e.type === He)).map((e => ({
                    ...e,
                    label: e.name,
                    value: e.id
                })));
            })).finally((() => d.value = !1))));
        }), {
            immediate: !0
        }), P((() => c.appId), (a => {
            var t;
            a && (t = a, p.value || (p.value = !0, l.admin.getPageList({
                appId: t,
                preview: e.runtimePlatform === fe.PAAS
            }).then((e => {
                const a = [];
                ka(e, {
                    visit: e => {
                        e.pageType !== Qe.NORMAL_FORM && e.pageType !== Qe.FLOW_FORM || a.push(e);
                    }
                }), r.value = a.filter((e => e.code !== m.value)).map((e => ({
                    ...e,
                    label: e.name,
                    value: e
                })));
            })).finally((() => p.value = !1))));
        }), {
            immediate: !0
        }), P((() => e.appData.id), (e => {
            c.appId = e;
        }), {
            immediate: !0
        }), t({
            getValue: () => {
                var e, a, t;
                return {
                    ...c,
                    formId: null === (e = c.form) || void 0 === e ? void 0 : e.code,
                    formName: null === (a = c.form) || void 0 === a ? void 0 : a.name,
                    pageType: null === (t = c.form) || void 0 === t ? void 0 : t.pageType
                };
            },
            validate: () => v.value.validate(),
            getInnerRef: () => v.value
        }), () => f(V("el-form"), {
            ref: v,
            model: c,
            "label-position": "top",
            class: "query-form-relation",
            rules: {
                appId: [ {
                    required: !0,
                    message: "请选择应用",
                    trigger: [ "blur" ]
                } ],
                form: [ {
                    required: !0,
                    message: "请选择表单",
                    trigger: [ "blur" ]
                } ]
            }
        }, {
            default: () => [ f(V("el-form-item"), {
                prop: "appId",
                label: "应用"
            }, {
                default: () => [ o(f(V("ssu-select"), {
                    modelValue: c.appId,
                    "onUpdate:modelValue": e => c.appId = e,
                    class: "select-style",
                    "option-data": n.value
                }, null), [ [ a("loading"), d.value ] ]) ]
            }), f(V("el-form-item"), {
                prop: "form",
                label: "表单"
            }, {
                default: () => [ f(V("ssu-select"), {
                    width: "100%",
                    class: "select-style",
                    modelValue: c.form,
                    "onUpdate:modelValue": e => c.form = e,
                    "option-data": r.value,
                    "value-key": "id"
                }, null) ]
            }) ]
        });
    }
}), Rd = {
    code: "RelationFormQuery",
    name: "关联查询",
    group: X.HIGH_LEVEL,
    icon: "guanlianchaxun",
    widgetType: J.DISPLAY,
    dataType: Z.ARRAY,
    widget: "OlRelationFormQuery",
    propsForm: "OlRelationFormQueryPropsForm",
    props: {
        placeholder: "请选择",
        appId: void 0,
        formId: void 0,
        showFields: [],
        conditions: {},
        allowCreate: !1,
        pageType: "",
        allowRemoteDetail: !1,
        remoteDetailUrl: "",
        allowRequestParams: !1,
        remoteDetailUrlRequestParams: [],
        allowFilter: !1,
        filterConfig: {},
        formName: ""
    },
    defaultValue: [],
    prependAction: {
        type: "props",
        form: "OlRelationFormQueryPrependForm",
        dialogTitle: "关联查询",
        width: "450px",
        height: "188px"
    },
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ K.RELATION_FORM ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Id.meta = Rd, Id.PropsForm = Nd, Id.install = e => {
    e.component(Id.name, Id), e.component(Nd.name, Nd), e.component(Md.name, Md);
}, Id.widgetMap = {
    [Id.name]: Id,
    [Nd.name]: Nd,
    [Md.name]: Md
};

const {formComponentMixin: xd} = z;

var Ad = e({
    name: "OlRemark",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ xd ],
    emits: [ "update:modelValue" ],
    setup: (e, {attrs: a, emit: t}) => ({
        modelValue: s((() => a.content || ""))
    })
});

const kd = {
    class: "ol-remark-text"
};

Ad.render = function(e, a, o, i, n, r) {
    return t(), l("div", kd, O(e.modelValue), 1);
};

const {formComponentMixin: Pd} = z;

var _d = e({
    name: "OlRemarkMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ Pd ],
    setup: e => () => f("div", null, [ D("测试移动端") ])
});

const {propsFormMixin: Ud} = z;

var Bd = e({
    name: "OlRemarkPropsForm",
    mixins: [ Ud ],
    setup(e, {expose: a}) {
        const {data: t, formRef: l} = fa(e, a), {convertOptionListToObj: o, convertObjToOptionList: n} = H, r = s((() => i(n({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: l,
            model: t,
            "label-position": "top",
            class: "ol-remark-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("remark-content"), {
                metaData: e.metaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: r.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = o(a, G.options.filter((e => "editable" !== e.key)), !0), {editable: l, visible: i, visibleWhenCreate: n} = t;
                    e.metaData.editable = l, e.metaData.visible = i, e.metaData.visibleWhenCreate = n;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Wd, metaDataMixin: qd} = z;

var jd = e({
    name: "OlRemarkTableFormatter",
    mixins: [ Wd, qd ],
    props: {
        content: {
            type: String,
            default: ""
        }
    },
    setup(e) {
        const a = s((() => e.content || ""));
        return () => f("div", {
            class: "ol-remark-table-formatter ol-remark-text "
        }, [ a.value ]);
    }
}), $d = {
    code: "Remark",
    name: "备注",
    group: X.NORMAL,
    icon: "beizhu",
    events: [],
    libInfoCode: "official",
    widget: "OlRemark",
    propsForm: "OlRemarkPropsForm",
    tableFormatter: "OlRemarkTableFormatter",
    props: {
        content: ""
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "content" ],
    supportI18NProps: [ "content" ],
    widgetType: J.LAYOUT
};

Ad.meta = $d, Ad.PropsForm = Bd, Ad.Mobile = _d, Ad.install = e => {
    e.component(Ad.name, Ad), e.component(Bd.name, Bd), e.component(_d.name, _d), e.component(jd.name, jd);
}, Ad.widgetMap = {
    [_d.name]: _d,
    [Ad.name]: Ad,
    [Bd.name]: Bd,
    [jd.name]: jd
};

const {formComponentMixin: zd} = z;

var Gd = e({
    name: "OlSequence",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ zd ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = i(l.value || e.metaData.defaultValue), n = s((() => ({
            ...e,
            ...a
        })));
        return P((() => o.value), (e => {
            t("update:modelValue", e);
        })), P((() => e.metaData.defaultValue), (e => {
            o.value = l.value || e;
        })), {
            data: l,
            bindData: n,
            modelValue: o
        };
    }
});

Gd.render = function(e, a, l, o, i, n) {
    const r = V("ssu-input-text");
    return t(), L(r, E({
        modelValue: e.modelValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.modelValue = a),
        modelModifiers: {
            trim: !0
        }
    }, e.bindData, {
        disabled: "",
        readonly: "",
        class: "ol-auto-number"
    }), null, 16, [ "modelValue" ]);
};

const {formComponentMixin: Hd} = z;

var Qd = e({
    name: "OlSequenceMobile",
    props: {
        modelValue: {
            type: String,
            default: "update:modelValue"
        }
    },
    emits: [ "" ],
    mixins: [ Hd ],
    setup(e, {emit: a}) {
        const t = Na(e), l = i(t.value || e.metaData.defaultValue);
        return P((() => l.value), (e => {
            a("update:modelValue", e);
        })), () => f(et, {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e
        }, null);
    }
});

const {propsFormMixin: Yd, setterMixin: Kd} = z;

var Xd = e({
    name: "OlSequencePropsForm",
    mixins: [ Yd, Kd ],
    setup(e, {expose: a}) {
        const t = s((() => new ge(Ba(e.pageMetaData, "widgets"), []).getFormWidgets().filter((a => a.fieldName !== e.metaData.fieldName)))), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-auto-number-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("sequence-setter"), {
                metaData: e.metaData,
                widgets: t.value
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options.filter((e => "editable" !== e.key)), !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Jd, metaDataMixin: Zd} = z;

var ep = e({
    name: "OlSequenceTableFormatter",
    mixins: [ Jd, Zd ],
    setup(e) {
        const a = s((() => e.fieldValue || Y));
        return () => f("label", {
            class: "ol-auto-number-table-formatter"
        }, [ a.value ]);
    }
}), ap = {
    code: K.SEQUENCE,
    name: "自动编号",
    group: X.NORMAL,
    icon: "bianhao1",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlSequence",
    propsForm: "OlSequencePropsForm",
    tableFormatter: "OlSequenceTableFormatter",
    props: {
        placeholder: "系统自动生成,无需填写",
        configType: "0",
        sequenceItems: [ {
            type: "count",
            title: Ke.count,
            digit: 3,
            overwriteType: !1,
            resetCycle: "0",
            initialValue: "1"
        } ]
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.CONTAINS,
        supportWidgetCodeConfig: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT, mi ],
        supportOperator: {
            [ee.CONTAINS]: ae[ee.CONTAINS],
            [ee.NOT_CONTAINS]: ae[ee.NOT_CONTAINS],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Gd.meta = ap, Gd.PropsForm = Xd, Gd.Mobile = Qd, Gd.install = e => {
    e.component(Gd.name, Gd), e.component(Xd.name, Xd), e.component(Qd.name, Qd), e.component(ep.name, ep);
}, Gd.widgetMap = {
    [Qd.name]: Qd,
    [Gd.name]: Gd,
    [Xd.name]: Xd,
    [ep.name]: ep
};

var tp = {
    margin: 0,
    qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q"
    },
    imageOptions: {
        hideBackgroundDots: !0,
        imageSize: .4,
        margin: 0
    },
    dotsOptions: {
        type: "square",
        color: "#000000"
    },
    backgroundOptions: {
        color: "#ffffff"
    },
    image: null,
    dotsOptionsHelper: {
        colorType: {
            single: !0,
            gradient: !1
        },
        gradient: {
            linear: !0,
            radial: !1,
            color1: "#6a1a4c",
            color2: "#6a1a4c",
            rotation: "0"
        }
    },
    cornersSquareOptions: {
        type: "square",
        color: "#000000"
    },
    cornersSquareOptionsHelper: {
        colorType: {
            single: !0,
            gradient: !1
        },
        gradient: {
            linear: !0,
            radial: !1,
            color1: "#000000",
            color2: "#000000",
            rotation: "0"
        }
    },
    cornersDotOptions: {
        type: "",
        color: "#000000"
    },
    cornersDotOptionsHelper: {
        colorType: {
            single: !0,
            gradient: !1
        },
        gradient: {
            linear: !0,
            radial: !1,
            color1: "#000000",
            color2: "#000000",
            rotation: "0"
        }
    },
    backgroundOptionsHelper: {
        colorType: {
            single: !0,
            gradient: !1
        },
        gradient: {
            linear: !0,
            radial: !1,
            color1: "#ffffff",
            color2: "#ffffff",
            rotation: "0"
        }
    }
}, lp = (e, a, t) => {
    const l = i({
        ...tp,
        width: 130,
        height: 130,
        value: "",
        originValue: ""
    }), o = le(), n = i(!1), r = s((() => JSON.parse(JSON.stringify({
        headers: {
            Authorization: e.token ? `Bearer ${e.token}` : void 0,
            "TENANT-ID": e.tenantId || void 0
        }
    })))), u = a => Ja.isFunction(e.postQrCodeValue) ? e.postQrCodeValue(a) : a, d = i(), p = () => {
        c(JSON.stringify({
            action: "reg",
            content: {}
        }));
    }, m = i => {
        const s = JSON.parse(i.data);
        s.sessionId && ((a = {}, t) => {
            n.value = !0, (0, o.form.getSignatureQrCode)({
                appId: e.appId,
                ...a
            }, t).then((a => {
                n.value = !1, e.tempH5SignCode && (a = a.replace(/code=.*/, `code=${e.tempH5SignCode}`)), 
                e.pageCode && (a = a.indexOf("?") ? `${a}&pageCode=${e.pageCode}` : `${a}?pageCode=${e.pageCode}`), 
                l.value.value = u(a), l.value.originValue = a;
            }));
        })({
            sessionId: s.sessionId,
            taskId: null == t ? void 0 : t.taskId,
            formDataId: null == t ? void 0 : t.recordId
        }, r.value), s.url && (a.value = s.url);
    }, c = e => {
        d.value.send(e);
    }, v = e => {}, f = () => {};
    return P((() => [ e.postQrCodeValue, l.value.originValues ]), (() => {
        l.value.originValue && (l.value.value = u(l.value.originValue));
    })), {
        initWebsocket: () => {
            if (window.WebSocket || (window.WebSocket = window.MozWebSocket), !window.WebSocket) return void alert("当前浏览器不支持websocketet");
            const e = vt.get(process.env.VUE_APP_COOKIE_TOKEN_KEY);
            let a = `${process.env.VUE_APP_SIGN_WEBSOCKET_URL}?token=${e}`;
            d.value = new WebSocket(a), d.value.onopen = p, d.value.onclose = v, d.value.onmessage = m, 
            d.value.onerror = f;
        },
        qrConfig: l,
        qrLoading: n
    };
};

const op = {
    type: "png",
    width: 100,
    margin: 0
}, ip = () => f(V("syc-icon"), {
    type: "user",
    height: 30,
    width: 30
}, null);

var np = e({
    name: "OlQRCodeCom",
    props: {
        text: {
            type: String,
            default: ""
        },
        options: {
            type: Object,
            default: () => ({})
        },
        centerImage: {
            type: [ Object, Function ],
            default: () => ip
        }
    },
    setup(e, a) {
        const t = i(""), l = s((() => Ja.isEmpty(e.text) ? "" : e.text)), o = s((() => {
            const {width: a, height: t} = e.options || {}, {width: l} = op;
            return {
                ...op,
                ...e.options,
                width: Ja.isNumber(a) ? `${a}px` : Ja.isString(a) ? a : `${l}px`,
                height: Ja.isNumber(t) ? `${t}px` : Ja.isString(t) ? t : `${l}px`
            };
        })), n = () => {
            e.text && ft.toDataURL(l.value, o.value).then((e => {
                t.value = null != e ? e : "";
            }));
        };
        return a.expose({
            initQRCode: n,
            downloadImage: (e, a = op.width) => {
                const t = {
                    ...o.value,
                    width: a
                };
                ft.toDataURL(l.value, t).then((a => {
                    ((e, a) => {
                        let t = document.createElement("a"), l = (e => {
                            let a = e.split(";base64,"), t = a[0].split(":")[1], l = window.atob(a[1]), o = l.length, i = new Uint8Array(o);
                            for (let e = 0; e < o; ++e) i[e] = l.charCodeAt(e);
                            return new Blob([ i ], {
                                type: t
                            });
                        })(e);
                        document.createEvent("HTMLEvents").initEvent("click", !0, !0), t.download = a, t.href = URL.createObjectURL(l), 
                        t.click();
                    })(a, e = Ja.isEmpty(e) ? "" : e);
                }));
            }
        }), P((() => [ l.value, o.value ]), (async () => {
            await b(), n();
        }), {
            deep: !0,
            immediate: !0
        }), () => f("div", {
            class: "base-qr-code-component"
        }, [ f("img", {
            style: {
                width: o.value.width,
                height: o.value.width
            },
            src: t.value
        }, null) ]);
    }
});

const {formComponentMixin: rp} = z;

var sp = e({
    name: "OlSign",
    components: {
        QRCode: np
    },
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        appId: {
            type: String,
            default: ""
        },
        pageCode: {
            type: String,
            default: ""
        },
        qrCodePopoverPlacement: {
            type: String,
            default: "right-start"
        },
        disabledEditClose: {
            type: Boolean,
            default: !1
        },
        tempH5SignCode: {
            type: String,
            default: ""
        },
        postQrCodeValue: {
            type: Function,
            default: void 0
        },
        token: {
            type: String
        },
        tenantId: {
            type: String
        }
    },
    mixins: [ rp ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t, expose: l}) {
        const o = Na(e), n = s((() => ({
            ...a,
            ...e
        }))), r = s({
            get: () => o.value || e.metaData.defaultValue,
            set: e => {
                t("update:modelValue", e);
            }
        }), {initWebsocket: u, qrConfig: d, qrLoading: p} = lp(e, r, a), m = i(!1);
        return l({
            getQrConfig: () => _(d),
            initWebsocket: u
        }), {
            data: o,
            bindData: n,
            modelValue: r,
            switchSignVisible: e => {
                m.value = e, e && u();
            },
            showSignPopper: m,
            clearValue: () => {
                !e.disabled && (r.value = "");
            },
            qrConfig: d,
            qrLoading: p,
            clickPreview: e => {
                ql({
                    previewArr: [ {
                        url: e
                    } ],
                    download: !1
                });
            }
        };
    }
});

const up = {
    class: "ol-sign"
}, dp = {
    key: 0,
    class: "ol-sign-edit"
}, pp = [ "src" ], mp = {
    class: "ol-sign-edit-close"
}, cp = {
    class: "sign-wrap"
}, vp = {
    class: "sign-wrap-close"
}, fp = y("div", {
    class: "sign-wrap-header"
}, "扫码签名", -1), gp = {
    class: "sign-wrap-content"
}, bp = {
    class: "sign-wrap-content-qr"
}, hp = y("div", {
    class: "sign-wrap-content-tip"
}, "扫描二维码，添加签名", -1);

sp.render = function(e, i, n, r, s, u) {
    const d = V("syc-icon"), p = V("QRCode"), m = V("ssu-button"), c = V("ssu-popover"), g = a("loading");
    return t(), l("div", up, [ e.modelValue ? (t(), l("div", dp, [ y("img", {
        class: "ol-sign-edit-img",
        src: e.modelValue,
        onClick: i[0] || (i[0] = a => e.clickPreview(e.modelValue))
    }, null, 8, pp), y("div", mp, [ e.disabledEditClose ? F("", !0) : (t(), L(d, {
        key: 0,
        type: "close",
        width: 16,
        height: 16,
        onClick: e.clearValue,
        class: A(e.disabled && "disabled-close")
    }, null, 8, [ "onClick", "class" ])) ]) ])) : (t(), L(c, {
        key: 1,
        visible: e.showSignPopper,
        "onUpdate:visible": i[3] || (i[3] = a => e.showSignPopper = a),
        "popper-class": "ol-sign-popover",
        placement: e.qrCodePopoverPlacement,
        trigger: "manual",
        ref: "popover",
        width: "260"
    }, {
        reference: v((() => [ y("div", {
            class: "sign-reference",
            onClick: i[2] || (i[2] = a => e.switchSignVisible(!0))
        }, [ W(e.$slots, "reference", {}, (() => [ f(m, E({
            type: "primary",
            slot: "reference"
        }, e.bindData, {
            plain: !e.showSignPopper,
            "css-icon": "icon-bianji"
        }), {
            default: v((() => [ D("添加签名") ])),
            _: 1
        }, 16, [ "plain" ]) ])) ]) ])),
        default: v((() => [ y("div", cp, [ y("div", vp, [ f(d, {
            type: "guanbi",
            width: 10,
            height: 10,
            onClick: i[1] || (i[1] = a => e.switchSignVisible(!1))
        }) ]), fp, y("div", gp, [ o((t(), l("div", bp, [ f(p, {
            options: e.qrConfig,
            text: e.qrConfig.value
        }, null, 8, [ "options", "text" ]) ])), [ [ g, e.qrLoading ] ]), hp ]) ]) ])),
        _: 3
    }, 8, [ "visible", "placement" ])) ]);
};

var yp = (e, a, t) => {
    const l = i(), o = s((() => e.code)), n = le(), r = i(), u = i(!1), {imgRotate: d} = (e => {
        const a = (e, a, t, l) => {
            let o, i, n = t / l, r = [ 0, 0 ];
            return n > e / a ? (o = e, i = o / n, r[0] = 0, r[1] = (a - i) / 2) : (i = a, o = i * n, 
            r[0] = (e - o) / 2, r[1] = 0), o = Math.floor(o), i = Math.floor(i), {
                points: r,
                rw: o,
                rh: i
            };
        };
        return {
            imgRotate: ({src: t, angle: l}) => new Promise(((l, o) => {
                const i = e.value, n = i.getContext("2d");
                let r = new Image;
                r.src = t, r.onload = () => {
                    let e = r.width, t = r.height, o = "1080";
                    a(o, "1920", e, t);
                    const s = a(o, o, t, e);
                    n.translate(0, s.rh + s.points[1]), n.rotate(-Math.PI / 2), n.drawImage(r, s.points[1], s.points[0], s.rh, s.rw), 
                    n.translate(0, -(s.rh + s.points[1])), n.rotate(Math.PI / 2);
                    let u = n.getImageData(0, 0, s.rw, s.rh);
                    i.height = s.rh, n.clearRect(0, 0, s.rw, s.rh), n.putImageData(u, 0, 0), l(i.toDataURL("image/png"));
                };
            }))
        };
    })(r), {uploadHWCloud: p} = va(e.pageCode), m = i({
        token: "",
        tenantId: ""
    }), c = s((() => {
        const {token: a, tenantId: t} = m.value;
        return JSON.parse(JSON.stringify({
            headers: {
                Authorization: a ? `Bearer ${a}` : void 0,
                "TENANT-ID": t || void 0,
                env: e.isRuntime ? Xe.RUNTIME : Xe.DESIGN
            }
        }));
    })), v = (e = !0) => {
        try {
            setTimeout((() => {
                let e = document.querySelector("#ol-canvas-sign") || {};
                if (e && a.value) {
                    let t = e.offsetWidth, o = e.offsetHeight;
                    a.value.width = t - 4, a.value.height = o - 4, l.value.clear();
                }
            }), 100);
        } catch (e) {}
    }, f = i(!1), g = (e = !1) => {
        f.value = e, b((() => {
            v();
        }));
    }, h = e => {
        90 === window.orientation ? g(!0) : g();
    }, y = i(!1), D = e => {
        e.persisted && window.location.reload();
    }, C = i(!1);
    return B((() => {
        window.removeEventListener("pageshow", D);
        const e = "onorientationchange" in window ? "orientationchange" : "resize";
        window.removeEventListener(e, h, !1);
    })), {
        init: () => {
            if ("Sign" === e.name && o.value) {
                document.title = "手写签名", window.addEventListener("pageshow", D);
                (e.isTempH5SignCode ? n.process.getSignatureTokenByTempCode : n.form.getSignatureTokenByCode)(o.value).then((e => {
                    m.value.tenantId = e.tenantId, m.value.token = e.token;
                }));
            }
            try {
                let e = a.value;
                l.value = new gt(e, {
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    penColor: "#616266",
                    minWidth: 1,
                    maxWidth: 2
                }), v(!1);
                const t = "onorientationchange" in window ? "orientationchange" : "resize";
                window.addEventListener(t, h, !1);
            } catch (e) {}
        },
        isLandScreen: f,
        isSignSuccess: u,
        switchLandScreen: g,
        oriChange: h,
        isForce: y,
        forceScreen: e => {
            y.value = !y.value, b((() => {
                v();
            }));
        },
        saveHandle: () => {
            var a;
            new Promise(((e, t) => {
                let o = a || l.value.toDataURL("image/jpeg");
                y.value ? d({
                    src: o,
                    angle: 90
                }).then((a => {
                    e(a);
                })) : e(o);
            })).then((a => {
                let l = ((e, a = "file") => {
                    let t = e.split(","), l = t[0].match(/:(.*?);/)[1], o = l.split("/")[1], i = atob(t[1]), n = i.length, r = new Uint8Array(n);
                    for (;n--; ) r[n] = i.charCodeAt(n);
                    return new File([ r ], `${a}.${o}`, {
                        type: l
                    });
                })(a), i = null;
                p(l, void 0, e.isPublicRelease).then((a => {
                    if ("Sign" === e.name && !e.isTempH5SignCode && o.value) C.value = !0, i = n.form.saveSignatureUrl({
                        code: o.value,
                        url: a
                    }, c.value); else {
                        if (!e.isTempH5SignCode) return u.value = !0, void t("saved", a);
                        C.value = !0, i = n.process.postSignConfirmComplete({
                            taskId: e.taskId,
                            type: e.signType,
                            signPicUrl: a,
                            reportUrl: e.pdfUrl,
                            code: e.code
                        }, c.value);
                    }
                    i.then((e => {
                        u.value = !0, t("saved", a);
                    })).finally((() => {
                        C.value = !1;
                    }));
                })).catch((e => {
                    Ka({
                        type: "warning",
                        message: `${e}，上传失败，请重试`
                    });
                }));
            }));
        },
        clearHandle: () => {
            l.value.clear();
        },
        rotate: r,
        btnLoading: C,
        permissionInfoHeaders: c,
        permissionInfo: m
    };
}, Dp = e({
    name: "SignWrap",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        taskId: {
            type: String,
            default: ""
        },
        signType: {
            type: String,
            default: ""
        },
        pdfUrl: {
            type: String,
            default: ""
        },
        pageCode: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        isTempH5SignCode: {
            type: Boolean,
            default: !1
        },
        isRuntime: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "saved" ],
    setup(e, {emit: a}) {
        const t = i(), {init: l, isLandScreen: o, isForce: n, forceScreen: r, saveHandle: s, clearHandle: u, rotate: d, isSignSuccess: p, btnLoading: m} = yp(e, t, a);
        w((() => {
            l();
        }));
        return () => p.value ? f("div", {
            class: "sign-wrap-content is-success",
            id: "sign-wrap-content"
        }, [ f(V("ssu-icon"), {
            "icon-name": "iconchenggongtishi",
            size: 40,
            color: "#3AC93C"
        }, null), f("div", {
            class: "sign-wrap-content-text"
        }, [ D("签名成功") ]) ]) : f("div", {
            class: "sign-wrap-content is-failure",
            id: "sign-wrap-content"
        }, [ f("canvas", {
            class: "rotate",
            ref: d,
            width: "1080",
            height: "1920",
            style: "display: none"
        }, null), f("div", {
            class: [ o.value ? "land-sign-board" : n.value ? "isForce" : "vertical-sign-board" ]
        }, [ f("div", {
            id: "ol-canvas-sign"
        }, [ f("canvas", {
            ref: t
        }, null), f("div", {
            class: "ol-canvas-sign-tip"
        }, [ D("请在此区域内绘制签名") ]) ]), f("div", {
            class: "operate-wrap"
        }, [ f("div", {
            class: "switch-button",
            onClick: () => r(!o.value)
        }, [ f(V("syc-icon"), {
            type: "designer/quanping"
        }, null) ]), f("div", {
            class: "operate-btn"
        }, [ f(V("ssu-button"), {
            plain: !0,
            onClick: () => {
                u();
            }
        }, {
            default: () => [ D("重写") ]
        }), f(V("ssu-button"), {
            type: "primary",
            onClick: () => {
                s();
            },
            loading: m.value
        }, {
            default: () => [ D("确定") ]
        }) ]) ]) ]) ]);
    }
});

const {formComponentMixin: Cp} = z;

var wp = e({
    name: "OlSignMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Cp ],
    components: {
        SignWrap: Dp
    },
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a
        }))), n = s({
            get: () => l.value || e.metaData.defaultValue,
            set: e => {
                t("update:modelValue", e);
            }
        }), r = () => {
            !e.disabled && (n.value = "");
        }, u = i(!1), d = () => {
            u.value = !0;
        };
        return () => f("div", {
            class: "ol-sign-mobile"
        }, [ u.value && f(Dp, {
            onSaved: e => {
                n.value = e, u.value = !1;
            },
            isPublicRelease: e.isPublicRelease
        }, null), n.value ? f("div", {
            class: "ol-sign-mobile-edit"
        }, [ f("img", {
            class: "ol-sign-mobile-edit-img",
            src: n.value,
            alt: ""
        }, null), f("div", {
            class: "ol-sign-mobile-edit-close",
            onClick: r
        }, [ f(V("syc-icon"), {
            type: "designer/close2",
            width: 16,
            height: 16,
            onClick: r,
            class: e.disabled && "disabled-close"
        }, null) ]) ]) : f("div", {
            class: "ol-sign-mobile-button"
        }, [ f(V("ssu-button"), E({
            slot: "reference"
        }, o.value, {
            plain: !0,
            "css-icon": "icon-bianji",
            onClick: d
        }), {
            default: () => [ D("添加签名") ]
        }) ]) ]);
    }
});

const {propsFormMixin: Sp} = z;

var Lp = e({
    name: "OlSignPropsForm",
    mixins: [ Sp ],
    setup(e, {expose: a}) {
        e.metaData;
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-single-text-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Tp, metaDataMixin: Vp} = z;

var Ep = e({
    name: "OlSignTableFormatter",
    mixins: [ Tp, Vp ],
    setup(e) {
        const a = s((() => null == e ? void 0 : e.runtimeEnv)), t = s((() => e.fieldValue));
        return () => f("div", {
            class: "ol-sign-table-formatter"
        }, [ t.value ? f("img", {
            class: "table" !== a ? "detail-sign" : "table-sign",
            src: t.value,
            onClick: () => {
                "table" !== a.value && ql({
                    previewArr: [ {
                        url: t.value
                    } ],
                    download: !1
                });
            }
        }, null) : Y ]);
    }
});

var Ip = {
    code: K.SIGN,
    name: "手写签名",
    group: X.HIGH_LEVEL,
    icon: "sign",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlSign",
    mobileWidget: "OlSignMobile",
    propsForm: "OlSignPropsForm",
    tableFormatter: "OlSignTableFormatter",
    props: {
        placeholder: "请输入",
        appId: "",
        url: ""
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    prependAction: {
        type: "action",
        action: ({widget: e, appData: a = {}}) => {
            var t;
            null != e && null !== (t = e.props) && void 0 !== t && t.appId && (e.props.appId.rawValue = a.id);
        }
    },
    conditionConfig: {
        defaultOperator: ee.IS_NOT_NULL,
        supportWidgetCodeConfig: [],
        supportOperator: {
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

sp.meta = Ip, sp.PropsForm = Lp, sp.Mobile = wp, sp.install = e => {
    e.component(sp.name, sp), e.component(Lp.name, Lp), e.component(wp.name, wp), e.component(Ep.name, Ep);
}, sp.widgetMap = {
    [wp.name]: wp,
    [sp.name]: sp,
    [Lp.name]: Lp,
    [Ep.name]: Ep
};

const {formComponentMixin: Fp} = z;

var Op = e({
    name: "OlSingleSelect",
    props: {
        modelValue: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        placeholder: {
            type: String,
            default: ""
        },
        optionsType: {
            type: String,
            default: ""
        },
        filterable: {
            type: Boolean,
            default: !0
        },
        remoteUrl: {
            type: String,
            default: ""
        },
        remoteUrlRequestParams: {
            type: Array,
            default: () => []
        },
        allowParam: {
            type: Boolean,
            default: !1
        },
        optionSetValue: {
            type: String,
            default: ""
        },
        isPublicRelease: {
            type: Boolean,
            default: !1
        },
        publicReleaseShareCode: {
            type: String,
            default: ""
        }
    },
    mixins: [ Fp ],
    emits: [ "update:modelValue", "*" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => e.metaData.fieldName)), n = s((() => `${o.value}_other`)), r = i(e.formData[n.value]), {curOptions: u, loading: d} = ga(e), p = s((() => ({
            ...e,
            ...a
        })));
        return P((() => e.metaData.defaultValue), (a => {
            l.value || (l.value = e.metaData.defaultValue);
        })), P((() => e.formData[n.value]), (e => {
            r.value = e;
        })), P((() => r.value), ((a, l) => {
            Ja.isEqual(a, l) || Ja.isEqual(a, e.formData[n.value]) || t(`update:${o.value}_other`, a);
        })), w((() => {})), {
            data: l,
            curOptions: u,
            bindData: p,
            otherValue: r,
            loading: d
        };
    }
});

Op.render = function(e, i, n, r, s, u) {
    const d = V("ssu-option"), p = V("ssu-select"), m = V("ssu-input-text"), c = a("loading");
    return o((t(), l("div", null, [ f(p, E({
        class: "ol-single-select"
    }, e.bindData, {
        modelValue: e.data,
        "onUpdate:modelValue": i[0] || (i[0] = a => e.data = a),
        "option-data": e.curOptions,
        clearable: ""
    }), {
        default: v((() => [ (t(!0), l(R, null, x(e.curOptions, (a => (t(), L(d, {
            key: a.value,
            value: a.value
        }, {
            default: v((() => [ y("label", {
                style: j({
                    color: e.enableColor && a.color || void 0
                })
            }, O(a.label), 5) ])),
            _: 2
        }, 1032, [ "value" ])))), 128)) ])),
        _: 1
    }, 16, [ "modelValue", "option-data" ]), "other" === e.data ? (t(), L(m, {
        key: 0,
        modelValue: e.otherValue,
        "onUpdate:modelValue": i[1] || (i[1] = a => e.otherValue = a),
        maxlength: "255"
    }, null, 8, [ "modelValue" ])) : F("", !0) ])), [ [ c, e.loading ] ]);
};

const Np = e({
    name: "PopupMobile",
    props: {
        show: {
            type: Boolean,
            default: !1
        },
        position: {
            type: String,
            default: "bottom"
        },
        closeable: {
            type: Boolean,
            default: !0
        },
        closeIconPosition: {
            type: String,
            default: "top-right"
        },
        round: {
            type: Boolean,
            default: !0
        },
        overlay: {
            type: Boolean,
            default: !0
        },
        closeOnClickOverlay: {
            type: Boolean,
            default: !0
        },
        showConfirmButton: {
            type: Boolean,
            default: !1
        }
    },
    setup: (e, {slots: a}) => () => f(at, E({
        show: e.show,
        "onUpdate:show": a => e.show = a
    }, e), a)
}), Mp = e({
    name: "ActionSheetMobile",
    props: {
        show: {
            type: Boolean,
            default: !1
        },
        actions: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        cancelText: {
            type: String,
            default: ""
        },
        closeable: {
            type: Boolean,
            default: !0
        },
        round: {
            type: Boolean,
            default: !0
        },
        overlay: {
            type: Boolean,
            default: !0
        },
        closeOnClickOverlay: {
            type: Boolean,
            default: !0
        },
        onSelect: {
            type: Function,
            default: () => {}
        },
        clickOverlay: {
            type: Function,
            default: () => {}
        },
        onCancel: {
            type: Function,
            default: () => {}
        }
    },
    setup(e, {slots: a}) {
        const t = i(e.show);
        return () => f(lt, E({
            show: t.value,
            "onUpdate:show": e => t.value = e
        }, e), a);
    }
}), {formComponentMixin: Rp} = z;

var xp = e({
    name: "OlSingleSelectMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Rp ],
    components: {
        SelectMobile: yi,
        PopupMobile: Np,
        ActionSheetMobile: Mp
    },
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a
        }))), n = i(l.value || e.metaData.defaultValue), r = s((() => e.options.map((e => (!u.value && delete e.color, 
        {
            ...e,
            name: e.label
        }))) || [])), u = s((() => e.enableColor)), d = s((() => e.disabled)), p = s((() => e.disabled && !e.modelValue));
        P((() => n.value), (e => {
            t("update:modelValue", e);
        })), P((() => e.metaData.defaultValue), (e => {
            n.value = l.value || e;
        }));
        const m = i(!1), c = s((() => `请选择${e.metaData.name || ""}`)), v = e => {
            n.value = e.value, g();
        }, g = () => {
            m.value = !1;
        };
        return () => f("div", null, [ f(yi, {
            modelValue: n.value,
            "onUpdate:modelValue": e => n.value = e,
            onClick: () => {
                !o.value.disabled && (m.value = !0);
            },
            disabled: d.value,
            isPlaceholder: p.value
        }, null), f(Mp, {
            show: m.value,
            "onUpdate:show": e => m.value = e,
            title: c.value,
            "cancel-text": "取消",
            actions: r.value,
            onSelect: v,
            onClickOverlay: g,
            onCancel: g
        }, null) ]);
    }
});

const {propsFormMixin: Ap} = z;

var kp = e({
    name: "OlSingleSelectPropsForm",
    mixins: [ Ap ],
    setup(e, {expose: a}) {
        if (!e.metaData.props.optionsType) {
            const a = be().getMeta("official", e.metaData.code), t = e => {
                let t = {};
                return e.forEach((e => {
                    t[e] = {
                        type: "raw",
                        key: e,
                        rawValue: a.props[e]
                    };
                })), t;
            };
            e.metaData.props = {
                ...e.metaData.props,
                ...t([ "optionsType", "remoteUrl", "allowParam", "remoteUrlRequestParams" ])
            };
        }
        const t = s({
            get: () => e.metaData.props.options.rawValue,
            set(a) {
                e.metaData.props.options.rawValue = a;
            }
        }), l = s((() => e.metaData.validateRuleConfig)), {data: o, formRef: n} = fa(e, a), {convertOptionListToObj: r, convertObjToOptionList: u} = H, d = s((() => i(u({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value)), p = Da(e.metaData, "optionsType"), m = Da(e.metaData, "remoteUrl"), c = Da(e.metaData, "allowParam"), v = Da(e.metaData, "remoteUrlRequestParams"), g = ba(e.metaData, "optionSetValue", te.PRODUCTION_LINE);
        return () => f(V("el-form"), {
            ref: n,
            model: o,
            "label-position": "top",
            class: "ol-single-select-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("options-type-setter"), {
                modelValue: p.value,
                "onUpdate:modelValue": e => p.value = e,
                metaData: e.metaData,
                optionList: he.options
            }, null), p.value === oe.REMOTE && f("div", null, [ f(V("DataSourceUrlSetter"), {
                modelValue: m.value,
                "onUpdate:modelValue": e => m.value = e
            }, null), f(V("ssu-checkbox"), {
                modelValue: c.value,
                "onUpdate:modelValue": e => c.value = e
            }, {
                default: () => [ D("对链接目标传参") ]
            }), c.value && f(V("ParamsSetter"), {
                modelValue: v.value,
                "onUpdate:modelValue": e => v.value = e,
                pageMetaData: e.pageMetaData
            }, null) ]), p.value === oe.CUSTOM && f(V("option-setter"), {
                metaData: e.metaData,
                optionList: t.value,
                "onUpdate:optionList": e => t.value = e,
                type: "single",
                defaultValue: 1
            }, null), p.value === oe.OPTION_SET && f(V("option-set-setter"), {
                metaData: e.metaData,
                appData: e.appData,
                runtimePlatform: e.runtimePlatform,
                optionSetValue: g.value,
                "onUpdate:optionSetValue": e => g.value = e
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: l.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: d.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = r(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const Pp = require("color"), {formEntityMixin: _p, metaDataMixin: Up} = z;

var Bp = e({
    props: {
        options: {
            type: Array,
            default: () => []
        },
        enableColor: {
            type: Boolean,
            default: !1
        },
        optionsType: {
            type: String,
            default: ""
        },
        remoteUrl: {
            type: String,
            default: ""
        },
        formatParams: {
            type: String,
            default: ""
        },
        optionSetValue: {
            type: String,
            default: ""
        }
    },
    name: "OlSingleSelectTableFormatter",
    mixins: [ _p, Up ],
    setup(e, a) {
        const {curOptions: t} = ga(e), l = s((() => {
            var a, t, l;
            return null !== (a = null !== (t = null === (l = e.metaData) || void 0 === l ? void 0 : l.metaData) && void 0 !== t ? t : e.metaData) && void 0 !== a ? a : {};
        })), o = s((() => {
            if (!e.fieldValue) return [];
            let a = [ e.fieldValue ];
            return a = a.map((e => {
                const a = t.value.find((a => a.value === e));
                return a || {
                    label: e
                };
            })), a.map((a => e.enableColor && f(V("ssu-tag"), {
                class: "color-tag",
                style: a.color && {
                    border: `1px solid ${a.color}`,
                    color: a.color,
                    "background-color": `${Pp(a.color).alpha("0.1").toString()}`
                } || void 0
            }, {
                default: () => [ a.label ]
            }) || a.label));
        }));
        return () => f("label", {
            class: "ol-single-select-table-formatter single-select-label"
        }, [ f("p", {
            class: "remark-text"
        }, [ o.value.length > 0 ? e.enableColor && o.value.map((e => e)) || o.value.join(ie) : Y ]), "other" === e.formData[l.value.fieldName] && e.formData[l.value.fieldName + "_other"] && f("p", {
            class: "remark-length"
        }, [ f("span", {
            class: "remark-sign"
        }, [ D("[") ]), f("span", null, [ e.formData[l.value.fieldName + "_other"] ]), f("span", {
            class: "remark-sign"
        }, [ D("]") ]) ]) ]);
    }
}), Wp = {
    code: K.SINGLE_SELECT,
    name: "下拉单选",
    group: X.NORMAL,
    icon: "xialadanxuan",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlSingleSelect",
    mobileWidget: "OlSingleSelectMobile",
    propsForm: "OlSingleSelectPropsForm",
    tableFormatter: "OlSingleSelectTableFormatter",
    props: {
        options: [ {
            label: "选项一",
            value: "选项一",
            score: "",
            code: "1"
        }, {
            label: "选项二",
            value: "选项二",
            score: "",
            code: "2"
        }, {
            label: "选项三",
            value: "选项三",
            score: "",
            code: "3"
        } ],
        enableColor: !1,
        enableScore: !1,
        placeholder: "请选择",
        optionsType: "custom",
        optionSetValue: te.PRODUCTION_LINE,
        remoteUrl: "",
        remoteUrlRequestParams: [],
        allowParam: !1
    },
    defaultValue: void 0,
    extraValues: [ {
        fieldName: "_other",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "其他输入框值"
        },
        fieldLevel: 1
    } ],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.IN,
        supportWidgetCodeConfig: [ {
            when: [ ee.EQUAL, ee.NOT_EQUAL ],
            code: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT, mi ]
        }, {
            when: [ ee.IN, ee.NOT_IN ],
            code: [ K.MULTIPLE_SELECT, K.SINGLE_SELECT, K.RADIO, K.CHECKBOX ]
        } ],
        supportOperator: {
            [ee.IN]: ae[ee.IN],
            [ee.NOT_IN]: ae[ee.NOT_IN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

Op.meta = Wp, Op.PropsForm = kp, Op.Mobile = xp, Op.install = e => {
    e.component(Op.name, Op), e.component(kp.name, kp), e.component(xp.name, xp), e.component(Bp.name, Bp);
}, Op.widgetMap = {
    [xp.name]: xp,
    [Op.name]: Op,
    [kp.name]: kp,
    [Bp.name]: Bp
};

const {formComponentMixin: qp} = z;

var jp = e({
    name: "OlSingleText",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ qp ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => e.metaData.validateRuleConfig.find((e => "length" === e.key))?.params?.max)), i = s((() => ({
            ...e,
            ...a,
            maxlength: o.value || 255,
            "show-word-limit": !!o.value
        })));
        return P((() => e.metaData.defaultValue), (e => {
            l.value = l.value || e;
        })), {
            data: l,
            bindData: i,
            keydown: e => {
                13 === Number(e.keyCode) && (e.stopPropagation(), e.preventDefault());
            }
        };
    }
});

jp.render = function(e, a, l, o, i, n) {
    const r = V("ssu-input-text");
    return t(), L(r, E({
        modelValue: e.data,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.data = a),
        modelModifiers: {
            trim: !0
        }
    }, e.bindData, {
        class: "ol-single-text ol-single-text-mobile",
        onKeydown: e.keydown
    }), null, 16, [ "modelValue", "onKeydown" ]);
};

const {formComponentMixin: $p} = z;

var zp = e({
    name: "OlSingleTextMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ $p ],
    setup: e => () => f("div", null, [ D("测试移动端") ])
});

const {propsFormMixin: Gp} = z;

var Hp = e({
    name: "OlSingleTextPropsForm",
    mixins: [ Gp ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-single-text-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateType: "text",
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Qp, metaDataMixin: Yp} = z;

var Kp = e({
    name: "OlSingleTextTableFormatter",
    mixins: [ Qp, Yp ],
    setup(e) {
        const a = s((() => e.fieldValue || Y));
        return () => f("label", {
            class: "ol-single-text-table-formatter"
        }, [ a.value ]);
    }
}), Xp = {
    code: K.SINGLE_TEXT,
    name: "单行文本",
    group: X.NORMAL,
    icon: "danhangwenben",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlSingleText",
    propsForm: "OlSingleTextPropsForm",
    tableFormatter: "OlSingleTextTableFormatter",
    props: {
        placeholder: "请输入"
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.CONTAINS,
        supportWidgetCodeConfig: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT, mi ],
        supportOperator: {
            [ee.CONTAINS]: ae[ee.CONTAINS],
            [ee.NOT_CONTAINS]: ae[ee.NOT_CONTAINS],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

jp.meta = Xp, jp.PropsForm = Hp, jp.Mobile = zp, jp.install = e => {
    e.component(jp.name, jp), e.component(Hp.name, Hp), e.component(zp.name, zp), e.component(Kp.name, Kp);
}, jp.widgetMap = {
    [zp.name]: zp,
    [jp.name]: jp,
    [Hp.name]: Hp,
    [Kp.name]: Kp
};

const Jp = "list", Zp = "tile", em = xa([ {
    label: "列表",
    value: Jp
}, {
    label: "平铺",
    value: Zp
} ]), am = xa([ {
    label: "1列",
    value: 1
}, {
    label: "2列",
    value: 2
}, {
    label: "3列",
    value: 3
}, {
    label: "4列",
    value: 4
} ]), tm = {
    fields: {
        type: Array,
        default: () => []
    },
    modelValue: {
        type: Array,
        default: () => []
    },
    displayType: {
        type: String,
        default: Jp
    },
    showIndex: {
        type: Boolean,
        default: !1
    },
    indexName: {
        type: String
    },
    allowExport: {
        type: Boolean,
        default: !1
    },
    allowFilter: {
        type: Boolean,
        default: !1
    },
    filterFields: {
        type: Array,
        default: () => []
    },
    col: {
        type: Number,
        default: 1
    },
    modelCode: {
        type: String,
        default: ""
    },
    appId: {
        type: String,
        default: ""
    },
    pageCode: {
        type: String,
        default: ""
    },
    subFieldPermission: {
        type: Array,
        default: () => []
    },
    isLazyRender: {
        type: Boolean,
        default: !0
    }
}, {temporarySortTableData: lm} = H, om = ({props: e, ctx: a, pageEngine: t, evt: l, sdk: o}) => {
    const n = Ee(), {conditionRulesAdaptor: r, checkConditionRulesFieldType: u} = wa(), d = i(e.modelValue || []), p = i({}), m = i({});
    let c = {};
    const v = i(!1), f = i([]), g = i({}), h = i({}), y = s((() => Array.isArray(e.pageMetaData) ? e.pageMetaData : Ba(e.pageMetaData, "widgets") || [])), D = s((() => e.formData || {})), C = () => {
        const e = Object.keys(m.value).reduce(((e, a) => {
            const t = m.value[a];
            return t && e.push(t.validate()), e;
        }), []);
        return Promise.all(e);
    }, S = (e = []) => {
        Ja.isArray(e) && C().then((() => {
            const a = t.value.getFormInitialValue();
            e.forEach((({key: e, value: t}) => {
                e && (a[e] = t);
            }));
            const l = V.value.calcFormValue(a, {
                mainFormData: D.value
            }) || {};
            e.forEach((({key: e, value: a}) => {
                a && (l[e] = a);
            })), requestIdleCallback((() => {
                d.value = d.value.concat([ l ]);
            }));
        }));
    };
    l.on("dataSource.add", (({keyValueList: e = []}) => {
        Ja.isArray(e) && S(e);
    })), l.on("dataSource.batchAdd", (({batchKeyValueList: e = []}) => {
        Ja.isArray(e) && e.forEach((e => {
            Ja.isArray(e) && S(e);
        }));
    })), l.on("dataSource.replaceAll", (({key: a, value: t} = {}) => {
        e.disabled || e.readOnly || !a || d.value.forEach((e => {
            e[a] = t;
        }));
    })), l.on("dataSource.remove", (({index: a}) => {
        f.value.splice(a, 1), e.disabled && e.readOnly || (d.value.splice(a, 1), b((() => {
            Object.keys(m.value).forEach((e => {
                e.startsWith(a.toString()) && delete m.value[e];
            }));
        })));
    }));
    const L = be(), T = i(), V = s((() => new Fe({
        widgets: e.fields,
        mainFormWidgets: y.value,
        eventBus: l,
        cacheManager: n,
        libraryManager: L
    }))), E = s((() => d.value.map((e => V.value.calcFormValue(e, {
        [Ze.MAIN_FORM_FIELD]: D.value
    }))))), I = (e, a = {}) => {
        d.value.forEach(((t, l) => {
            for (let n in t) {
                var o, i;
                if (Ja.isObject(a)) a.exceptWithoutData = !!(null !== (o = null == a ? void 0 : a.exceptWithoutData) && void 0 !== o ? o : null === (i = t[n]) || void 0 === i || !i.id);
                null != a && a.exceptWithoutData && (Ja.isNil(t[n]) || (Ja.isArray(t[n]) || Ja.isString(t[n])) && 0 === t[n].length) || (f.value[l] || (f.value[l] = {}), 
                f.value[l][n] = e);
            }
        }));
    };
    l.on("fetch-form-data", ((e = {}) => {
        requestIdleCallback((() => {
            var a;
            const {appId: t, formId: l, condition: i, cacheGroup: n, formValue: s} = e;
            if (!t || !l) return;
            const d = u(null == i ? void 0 : i.rules) ? {
                condition: Ie.AND,
                ...i,
                rules: r(null == i ? void 0 : i.rules, Wa(s))
            } : {
                condition: Ie.AND,
                ...i
            };
            null == o || null === (a = o.form) || void 0 === a || a.getListWithoutScene({
                appId: t,
                modelCode: l,
                pageSize: 100,
                pageNum: 1,
                filter: null != i && i.rules ? d : void 0
            }).then((e => {
                var a, t, l;
                null == e || null === (a = e.result) || void 0 === a || null === (t = a.forEach) || void 0 === t || t.call(a, (e => {
                    var a, t;
                    null == n || null === (a = n.value) || void 0 === a || null === (t = a.addCache) || void 0 === t || t.call(a, {
                        ...e
                    });
                })), null != e && null !== (l = e.result) && void 0 !== l && l.length && requestIdleCallback((() => {
                    F();
                }));
            }));
        }));
    }));
    const F = Ja.debounce((() => {
        requestIdleCallback((() => {
            Ja.isEqual(E.value, T.value) || E.value.forEach(((e, a) => {
                Ja.isEqual(E.value[a], Ja.get(T, `value[${a}]`)) || ((e, a, t) => {
                    for (let o in a) {
                        var l;
                        Ja.isEqual(e[o], a[o]) || null !== (l = f.value[t]) && void 0 !== l && l[o] || requestIdleCallback((() => {
                            e[o] = Ja.cloneDeep(a[o]);
                        }));
                    }
                })(d.value[a], E.value[a], a);
            })), T.value = Ja.cloneDeep(E.value);
        }));
    }), 10);
    P((() => D.value), Ja.debounce((() => {
        F();
    }), 100), {
        deep: !0
    }), P((() => p.value), Ja.debounce((e => {
        Ja.isEqual(e, c) || (c = Ja.cloneDeep(e), p.value = Ja.cloneDeep(e));
    }), 500), {
        deep: !0
    }), w((() => {
        var a;
        if (I(!0), P((() => e.fields), (a => {
            b((() => {
                b((() => {
                    const e = t.value.getFormRules();
                    p.value = e;
                })), e.runtimeEnv === Q.DESIGNER && b((() => {
                    if (0 === d.value.length) {
                        const e = t.value.getFormInitialValue();
                        b((() => {
                            d.value = d.value.concat([ e ]);
                        }));
                    }
                }));
            }));
        }), {
            deep: !0,
            immediate: !0
        }), null === (a = e.modelValue) || void 0 === a || !a.length) {
            const a = Ja.get(e, [ "metaData", "dynamicDefaultValue", "expression" ], "");
            try {
                if (!a || "自定义" === a) return;
                const e = JSON.parse(a);
                d.value = e;
            } catch (e) {}
        }
        requestIdleCallback((() => {
            V.value.calcFormValue(e.modelValue, {
                mainFormData: D.value
            });
        }));
    })), P((() => e.formEntityId), (a => {
        !Ja.isNil(a) && [ Q.DETAIL, Q.EDIT, Q.PROCESS_AUDIT ].includes(e.runtimeEnv) && b((() => {
            O();
        }));
    }), {
        immediate: !0
    }), P((() => d.value), ((e, t) => {
        Ja.isEqual(e, t) || (F(), null != e && e.length ? a.emit("update:modelValue", e) : a.emit("update:modelValue", null));
    }), {
        deep: !0
    }), P((() => e.modelValue), (a => {
        d.value = a || [];
        const t = [ Q.CREATE ].includes(e.runtimeEnv);
        [ Q.EDIT, Q.PROCESS_AUDIT, Q.DETAIL ].includes(e.runtimeEnv) ? I(!0) : t && I(!0, {
            exceptWithoutData: !0
        });
    }), {
        deep: !0
    });
    const O = (a = {}) => {
        var t;
        v.value = !0;
        const l = {
            condition: Ie.AND,
            rules: [ {
                field: "master_id",
                operator: ee.EQUAL,
                value: e.formEntityId,
                type: ea.STRING
            } ]
        };
        let i, n = {
            appId: e.appId,
            modelCode: e.modelCode,
            masterId: e.formEntityId,
            pageSize: 999,
            pageNum: 1,
            filter: l,
            ...a,
            cache: !1
        };
        if (e.runtimeEnv === Q.DETAIL) {
            const e = Ja.cloneDeep(g.value), a = Ja.cloneDeep(h.value);
            aa(l, a, e);
        }
        var r, s, u;
        (e.runtimeEnv !== Q.DETAIL || null !== (t = e.variables) && void 0 !== t && t.isProcessPage) && e.runtimeEnv !== Q.EDIT ? (e.appId, 
        e.pageCode, e.formEntityId, i = Re.includes(null === (r = e.variables) || void 0 === r ? void 0 : r.sourceType) ? o.process.getSubListDetails({
            ...n,
            actId: null === (s = e.variables) || void 0 === s ? void 0 : s.actId,
            taskInstanceId: null === (u = e.variables) || void 0 === u ? void 0 : u.taskInstanceId
        }) : o.process.getSubTableDetails(n)) : i = o.form.getListWithoutScene(n);
        i.then((a => {
            const t = (null == a ? void 0 : a.result) || [], l = e.runtimeEnv === Q.EDIT || e.runtimeEnv === Q.PROCESS_AUDIT ? t.map((e => Wa(e))) : t;
            d.value = l;
            const o = [ Q.CREATE ].includes(e.runtimeEnv);
            [ Q.EDIT, Q.PROCESS_AUDIT, Q.DETAIL ].includes(e.runtimeEnv) ? I(!0) : o && I(!0, {
                exceptWithoutData: !0
            });
        })).finally((() => {
            v.value = !1;
        }));
    }, N = a => {
        var t, l;
        const o = null !== (t = null == e || null === (l = e.metaData) || void 0 === l ? void 0 : l.props) && void 0 !== t ? t : {};
        return Ba(o, a);
    }, M = s((() => e => !!N("disabledDeleteOldRecord") && (null == e ? void 0 : e.id))), R = s((() => e => !!N("disabledEditOldRecord") && (null == e ? void 0 : e.id)));
    return {
        search: () => {
            O();
        },
        loading: v,
        formRefs: m,
        formRules: p,
        dataSource: d,
        validate: C,
        normalSearchData: g,
        dialogSearchData: h,
        formValueIsInput: f,
        onFormValueInput: (a, t) => {
            var l, o, i, n, r;
            f.value[t] || (f.value[t] = {}), f.value[t][a] = !0;
            const s = null === (l = V.value) || void 0 === l || null === (o = l.dependenceGraph) || void 0 === o || null === (i = o.reverseMap) || void 0 === i || null === (n = i.get) || void 0 === n ? void 0 : n.call(i, a);
            null == s || null === (r = s.forEach) || void 0 === r || r.call(s, (e => {
                f.value[t][e] = !1;
            })), b((() => {
                ((a, t) => {
                    var l;
                    const o = e.displayType === Jp ? `${a}-${t}` : `tile-form-${a}`, i = m.value[o];
                    null == i || null === (l = i.validateField) || void 0 === l || l.call(i, [ t ]);
                })(t, a);
            }));
        },
        getPropsValue: N,
        isHiddenDeleteRow: M,
        isDisabledEditRow: R
    };
}, im = Ve((({ctx: e, props: a}) => {
    const t = Sa(), l = be(), o = le(), n = s((() => new Oe({
        widgets: a.fields,
        messages: [],
        language: "zh-cn",
        systemWidgets: [],
        libManager: l
    }))), r = i({}), u = s((() => {
        var e;
        return null === (e = a.formRules) || void 0 === e ? void 0 : e.find((e => "required" === e.key || e.required));
    })), d = om({
        props: a,
        ctx: e,
        pageEngine: n,
        sdk: o,
        evt: t
    }), {columns: p, isDetail: m} = (({pageEngine: e, evt: a, props: t}) => {
        const l = s((() => {
            const a = e.value.toColumnList(), l = e.value.getWidgetStatus(t.runtimeEnv) || {};
            return a.map((e => {
                var a, o;
                const i = l[null == e || null === (a = e.metaData) || void 0 === a ? void 0 : a.id], n = null === (o = t.subFieldPermission) || void 0 === o ? void 0 : o.find((a => {
                    var t;
                    return a.id === (null == e || null === (t = e.metaData) || void 0 === t ? void 0 : t.id);
                }));
                return {
                    ...e,
                    minWidth: 260,
                    ...i,
                    ...n
                };
            }));
        }));
        return {
            isDetail: s((() => t.runtimeEnv === Q.DETAIL)),
            columns: l
        };
    })({
        pageEngine: n,
        evt: t,
        props: a
    });
    return P((() => [ a.fields, a.subFieldPermission ]), (() => {
        requestIdleCallback((() => {
            const e = n.value.getWidgetStatus(a.runtimeEnv);
            Ja.keys(e).forEach((t => {
                var l;
                const o = null === (l = a.subFieldPermission) || void 0 === l ? void 0 : l.find((e => e.id === t));
                e[t] = {
                    ...e[t],
                    ...o
                };
                const i = d.formRules.value[null == o ? void 0 : o.fieldName];
                if (Array.isArray(i)) {
                    const e = i.findIndex((e => e.required));
                    i.splice(e, 1);
                }
                if (null != o && o.required) {
                    const e = Je.genRequiredRule();
                    Array.isArray(d.formRules.value[o.fieldName]) || (d.formRules.value[o.fieldName] = []), 
                    d.formRules.value[o.fieldName].push(e);
                }
            })), r.value = e;
        }));
    }), {
        deep: !0,
        immediate: !0
    }), e.expose({
        validate: () => d.validate()
    }), {
        libraryManager: l,
        evt: t,
        isRequired: u,
        isDetail: m,
        columns: p,
        ...d,
        props: a,
        widgetStatus: r,
        handleSortChange: ({column: e, prop: a, order: t}) => {
            d.dataSource.value = lm(d.dataSource.value, {
                column: e,
                prop: a,
                order: t
            });
        }
    };
}), "subForm"), nm = im.useProvide, rm = im.useInject, sm = e({
    name: "OlSubFormOperation",
    setup() {
        const {props: e, normalSearchData: a, search: t, dialogSearchData: l} = rm(), {showFilterButton: o, normalFields: n, dialogFields: r, firstInput: u} = (e => {
            const a = s((() => e.filterFields)), t = s((() => e.fields)), l = s((() => a.value.map((e => t.value.find((a => a.fieldName === e)))))), o = s((() => l.value.find((e => ta(e.code))))), i = s((() => l.value.length)), n = s((() => 0 === i.value ? [] : i.value <= 2 ? o.value ? [ ...l.value.filter((e => e.fieldName !== o.value.fieldName)), o.value ] : l.value : o.value ? [ o.value ] : []));
            return {
                dialogFields: s((() => l.value)),
                showFilterButton: s((() => !(i.value <= 2))),
                normalFields: n,
                firstInput: o
            };
        })(e), d = Ia(), p = i(), m = () => {
            p.value.reset();
        }, c = () => {
            d.close(), t();
        };
        return P((() => a.value), (() => {
            o.value || c();
        }), {
            deep: !0
        }), () => f("div", {
            class: "ol-sub-form-operation"
        }, [ e.allowFilter && f("div", {
            class: "ol-sub-form-operation__search"
        }, [ f(V("el-space"), null, {
            default: () => [ o.value && f(V("ssu-button"), {
                type: "primary",
                plain: !0,
                onClick: d.open
            }, {
                default: () => [ D("筛选") ]
            }), Ua(n.value) && f(V("WidgetSearchForm"), {
                appId: e.appId,
                modelCode: e.pageCode,
                showLabel: !1,
                inline: !0,
                modelValue: a.value,
                "onUpdate:modelValue": e => a.value = e,
                widgets: n.value
            }, null), u.value && f(V("ssu-button"), {
                type: "primary",
                onClick: t
            }, {
                default: () => [ f(V("syc-icon"), {
                    type: "search"
                }, null) ]
            }) ]
        }) ]), f(V("BaseDialog"), {
            onClose: d.close,
            modelValue: d.visible.value,
            "onUpdate:modelValue": e => d.visible.value = e,
            title: "筛选",
            size: "small",
            customClass: "ol-sub-form-operation__dialog"
        }, {
            default: () => [ d.visible.value && f(V("WidgetSearchForm"), {
                appId: e.appId,
                modelCode: e.pageCode,
                ref: p,
                modelValue: l.value,
                "onUpdate:modelValue": e => l.value = e,
                widgets: r.value
            }, null) ],
            footer: () => f("div", {
                class: "ol-sub-form-operation__dialog-footer"
            }, [ f("div", {
                class: "ol-sub-form-operation__dialog-footer-left",
                onClick: m
            }, [ f(V("el-space"), null, {
                default: () => [ f(V("syc-icon"), {
                    type: "refresh"
                }, null), f("span", null, [ D("重置") ]) ]
            }) ]), f("div", {
                class: "ol-sub-form-operation__dialog-footer-right"
            }, [ f(V("ssu-button"), {
                onClick: d.close
            }, {
                default: () => [ D("取消") ]
            }), f(V("ssu-button"), {
                onClick: c,
                type: "primary"
            }, {
                default: () => [ D("筛选") ]
            }) ]) ])
        }) ]);
    }
}), um = e({
    name: "SubFormSelectTableDataDialog",
    props: {
        appId: {
            type: String,
            default: ""
        },
        modelCode: {
            type: String,
            default: ""
        },
        inSubForm: {
            type: Boolean,
            default: !0
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        mainFormData: {
            type: Object,
            default: () => ({})
        }
    },
    emits: {
        batchAdd: null
    },
    setup(e, a) {
        const t = rm(), {props: l} = t, o = i([]), n = s((() => l.metaData.props)), r = s((() => {
            var e, a, t;
            return null === (e = n.value) || void 0 === e || null === (a = (t = e.fields).find) || void 0 === a ? void 0 : a.call(t, (e => e.fieldName === n.value.batchSelectAddField));
        })), u = s((() => {
            var e, a;
            return null !== (e = null === (a = r.value) || void 0 === a ? void 0 : a.props) && void 0 !== e ? e : null;
        })), d = s((() => {
            var e, a;
            return null === (e = u.value) || void 0 === e || null === (a = e.appId) || void 0 === a ? void 0 : a.rawValue;
        })), p = s((() => {
            var e, a;
            return null === (e = u.value) || void 0 === e || null === (a = e.formId) || void 0 === a ? void 0 : a.rawValue;
        })), m = s((() => {
            var e, a;
            return null === (e = u.value) || void 0 === e || null === (a = e.conditions) || void 0 === a ? void 0 : a.rawValue;
        })), c = s((() => {
            var e, a;
            return null === (e = u.value) || void 0 === e || null === (a = e.showFields) || void 0 === a ? void 0 : a.rawValue;
        })), v = s((() => {
            var e, a;
            return null === (e = u.value) || void 0 === e || null === (a = e.hasFilter) || void 0 === a ? void 0 : a.rawValue;
        })), {relationFormFieldList: g, relationFormSystemFieldList: b, relationTableColumnList: h} = Ea(d, p, c), {relationFormCondition: y, handleFilter: D, handleKeyWordFilter: C, handlePageChange: S, handlePageSizeChange: L, handleSortChange: T, handleRefresh: I, initRelationFormQueryData: F, relationFormQueryDataTotal: O, relationFormQueryData: N, relationFormQuery: M} = Ta(d, p, m, e, a), {tableHeight: R} = Ru(v), x = i({}), A = s((() => {
            var e, a;
            const t = new ge(g.value.concat(b.value)), l = [];
            t.each((e => {
                e.widgetType !== J.CONTAINER && l.push(e);
            }));
            return ((null === (e = u.value) || void 0 === e || null === (a = e.filterConfig) || void 0 === a ? void 0 : a.rawValue.fields) || []).map((e => l.find((a => a.fieldName === e)))).filter((e => !!e));
        })), k = s((() => ({
            ...e,
            ...a.attrs
        }))), P = () => {
            o.value = [];
        }, _ = () => {
            P();
        }, U = () => {
            var e;
            if (null === (e = o.value) || void 0 === e || !e.length || !n.value.batchSelectAddField) return;
            const t = o.value.map((e => (null == e ? void 0 : e.id) && {
                key: n.value.batchSelectAddField,
                value: Array.of(e.id)
            })).filter(Boolean);
            a.emit("batchAdd", t), P();
        };
        return w((() => {
            F(y.value);
        })), () => {
            var a, t;
            return f(V("SelectTableDataDialog"), E(k.value, {
                filterData: x.value,
                "onUpdate:filterData": e => x.value = e,
                tableSelectionList: o.value,
                "onUpdate:tableSelectionList": e => o.value = e,
                appId: e.appId,
                modelCode: e.modelCode,
                hasFilter: !(null === (a = u.value) || void 0 === a || null === (t = a.allowFilter) || void 0 === t || !t.rawValue),
                isSortable: !0,
                isSingleMode: !1,
                filterFields: A.value,
                tableData: N.value,
                tableColumnList: h.value,
                tableTotal: O.value,
                tableHeight: R.value,
                tableCurrentPage: M.value.pageNum,
                onFilter: D,
                onFilterSearch: C,
                onPageCurrentChange: S,
                onSizeChange: L,
                onSortChange: T,
                onRefresh: I,
                onConfirm: U,
                onCancel: _
            }), null);
        };
    }
}), {setterMixin: dm} = z, pm = 1 / 0;

var mm = e({
    name: "MaxRowNumSetter",
    mixins: [ dm ],
    setup(e) {
        const {warning: a} = ca(), t = s({
            get: () => {
                var a;
                return null === (a = e.metaData.props.maxRowNum) || void 0 === a ? void 0 : a.rawValue;
            },
            set: a => {
                e.metaData.props.maxRowNum ? e.metaData.props.maxRowNum.rawValue = String(a) : e.metaData.props.maxRowNum = {
                    rawValue: a,
                    key: "maxRowNum",
                    type: "raw"
                };
            }
        }), l = s({
            get: () => {
                var a;
                return null === (a = e.metaData.props.allowMaxRowNum) || void 0 === a ? void 0 : a.rawValue;
            },
            set: a => {
                e.metaData.props.allowMaxRowNum ? e.metaData.props.allowMaxRowNum.rawValue = !!a : e.metaData.props.allowMaxRowNum = {
                    rawValue: a,
                    key: "allowMaxRowNum",
                    type: "raw"
                };
            }
        }), i = e => {
            l.value = !!e, t.value = "";
        }, n = (e, t, l) => {
            "max" == e && a(`子表最大行数的输入值不能超过${l}`), "min" === e && a(`子表最大行数的输入值不能低于${l}`);
        };
        return w((() => {
            Number(t.value) || 0 === Number(t.value) || (t.value = "");
        })), () => f("div", {
            class: "max-row-num-setter"
        }, [ f("div", {
            class: "top"
        }, [ f(V("ssu-checkbox"), {
            modelValue: l.value,
            onChange: i
        }, {
            default: () => [ D("最大行数") ]
        }) ]), f("div", {
            class: "bottom"
        }, [ o(f(V("ssu-input-text"), {
            modelValue: t.value,
            "onUpdate:modelValue": e => t.value = e,
            "is-number": !0,
            "max-length": 6,
            "number-option": {
                decimal: 0,
                min: 1,
                max: pm
            },
            onNumberLimit: n
        }, null), [ [ g, l.value ] ]) ]) ]);
    }
});

const cm = e({
    name: "OlSubFormHeader",
    setup() {
        const {warning: e} = ca(), {evt: a, props: t, isRequired: l, dataSource: n} = rm(), r = i(!1), u = s((() => {
            const e = t.metaData.props;
            return (null == e ? void 0 : e.allowBatchSelectAdd) && (null == e ? void 0 : e.batchSelectAddField);
        })), d = s((() => {
            var e, a, l;
            const o = t.metaData.props;
            if (null == o || null === (e = o.fields) || void 0 === e || !e.length || !u.value) return "选择";
            const i = o.fields.find((e => e.fieldName === o.batchSelectAddField));
            return `选择${null !== (a = null == i || null === (l = i.name) || void 0 === l ? void 0 : l.rawValue) && void 0 !== a ? a : ""}`;
        })), p = s((() => {
            const {maxRowNum: e, allowMaxRowNum: a} = t.metaData.props;
            return [ "undefined", "" ].includes(String(e).trim()) || !a ? Number(pm) : Number(e);
        })), m = s((() => {
            var e, a;
            return p.value - (null !== (e = null === (a = n.value) || void 0 === a ? void 0 : a.length) && void 0 !== e ? e : 0);
        })), c = s((() => m.value <= 0)), v = () => {
            e(`当前子表最多只能添加${p.value}行`);
        }, b = s((() => {
            const {mergeTableColumnFields: e, allowMergeTableColumn: a, fields: l} = t.metaData.props;
            return (null == e ? void 0 : e.length) && a && (null == l ? void 0 : l.length);
        })), h = s((() => {
            var e;
            const {mergeTableColumnFields: a} = t.metaData.props;
            if (null !== (e = n.value) && void 0 !== e && e.length && b.value) {
                const e = n.value[0], t = Object.keys(e);
                return a.map((a => {
                    if (t.includes(a)) {
                        return {
                            key: a,
                            value: e[a]
                        };
                    }
                })).filter(Boolean);
            }
            return [];
        })), y = () => {
            a.emit("dataSource.add", {
                keyValueList: h.value
            });
        }, C = e => {
            const {mergeTableColumnFields: l} = t.metaData.props, o = e.map((e => {
                const a = Ja.cloneDeep(h.value);
                return l.includes(null == e ? void 0 : e.key) || a.push(e), a;
            }));
            a.emit("dataSource.batchAdd", {
                batchKeyValueList: o
            });
        };
        return () => f("div", {
            class: "ol-sub-form-header"
        }, [ f("div", {
            class: "ol-sub-form-header__title"
        }, [ l.value && f("label", {
            class: [ "required-label" ]
        }, null), t.label ]), f("div", {
            class: "ol-sub-form-header__right"
        }, [ t.runtimeEnv === Q.DETAIL && f(sm, null, null), [ Q.CREATE, Q.EDIT, Q.PROCESS_AUDIT ].includes(t.runtimeEnv) && f("div", {
            class: "ol-sub-form-header__button"
        }, [ o(f(V("ssu-button"), {
            disabled: t.disabled || c.value,
            onClick: () => r.value = !0
        }, {
            default: () => [ d.value ]
        }), [ [ g, u.value ] ]), f(V("ssu-button"), {
            disabled: t.disabled || c.value,
            type: "primary",
            onClick: y,
            cssIcon: "iconxinzeng"
        }, {
            default: () => [ D("添加一条") ]
        }) ]) ]), r.value && o(f(um, {
            modelValue: r.value,
            "onUpdate:modelValue": e => r.value = e,
            appId: t.appId,
            modelCode: t.pageCode,
            mainFormData: t.formData,
            title: d.value,
            limitNum: m.value,
            onLimit: v,
            onBatchAdd: C
        }, null), [ [ g, u.value ] ]) ]);
    }
}), vm = e({
    name: "SubFormField",
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        config: {
            type: Object,
            default: () => ({})
        },
        showLabel: {
            type: Boolean,
            default: !0
        },
        widgetStatus: {
            type: Object,
            default: () => ({})
        },
        updateCallback: {
            type: Function,
            default: () => null
        },
        index: {
            type: Number,
            required: !0
        }
    },
    setup(e, a) {
        const {props: t, onFormValueInput: l, isDisabledEditRow: o} = rm(), {getExtraValueUpdateWatcher: i} = La(), n = s((() => {
            var a, t;
            return null === (a = e.config) || void 0 === a || null === (t = a.metaData) || void 0 === t ? void 0 : t.id;
        })), r = s((() => {
            var a;
            return (null === (a = e.widgetStatus.value) || void 0 === a ? void 0 : a[n.value]) || {};
        }));
        return P((() => e.data[e.config.prop]), (() => {
            Ja.isFunction(e.updateCallback) && e.updateCallback(e.config.prop, e.data[e.config.prop]);
        })), () => {
            var n, s;
            const u = e.config, d = e.data, p = {
                prop: u.prop
            }, m = t.disabled || o.value(e.data);
            e.showLabel && (p.label = u.label);
            const c = Ja.get(u.defineMetaData, [ "renderConfig", "showLabel" ], !0);
            return null !== (n = r.value) && void 0 !== n && n.visible ? f(V("CustomFormItem"), E(p, {
                showLabel: c
            }), {
                default: () => [ t.readOnly || null === (s = r.value) || void 0 === s || !s.editable ? f(V("RenderComponent"), E({
                    appId: t.appId,
                    fieldValue: d[u.prop],
                    widgetMap: u.widgetMap,
                    formData: d,
                    label: u.label,
                    metaData: u.metaData,
                    code: u.formatterCode,
                    variables: t.variables,
                    disabled: m,
                    defineMetaData: u.defineMetaData,
                    readOnly: t.readOnly,
                    pageMetaData: {
                        widgets: t.fields
                    },
                    runtimeEnv: Q.TABLE,
                    inSubForm: !0,
                    mainFormData: t.formData
                }, a.attrs, u.props), null) : f(V("RenderComponent"), E({
                    appId: t.appId,
                    label: u.label,
                    inSubForm: !0,
                    mainFormData: t.formData,
                    modelValue: d[u.prop],
                    "onUpdate:modelValue": a => {
                        d[u.prop] = a, l(u.prop, e.index);
                    },
                    widgetMap: u.widgetMap,
                    formData: d,
                    variables: t.variables,
                    metaData: u.metaData,
                    code: u.defineMetaData.widget,
                    disabled: m,
                    defineMetaData: u.defineMetaData,
                    readOnly: t.readOnly,
                    pageMetaData: {
                        widgets: t.fields
                    },
                    runtimeEnv: t.runtimeEnv
                }, a.attrs, u.props, i(u, d)), null) ]
            }) : null;
        };
    }
}), fm = e({
    name: "OlSubFormTable",
    setup() {
        const {dataSource: e, props: a, columns: t, evt: l, formRules: o, formRefs: i, getPropsValue: n, isHiddenDeleteRow: r, isDisabledEditRow: u, handleSortChange: d, widgetStatus: p} = rm(), {getColumnWidth: m, setColumnWidth: c} = Fa(a.pageCode, a.metaData.fieldName), v = s((() => a.runtimeEnv === Q.DESIGNER)), g = s((() => a.runtimeEnv === Q.CREATE)), b = s((() => a.runtimeEnv === Q.EDIT)), h = s((() => a.runtimeEnv === Q.DETAIL)), y = s((() => !!n("allowMergeTableColumn"))), C = s((() => n("mergeTableColumnFields"))), w = s((() => e => a.disabled || h.value)), S = s((() => [ {
            label: "删除",
            isHidden: ({row: e}) => r.value(e),
            isDisabled: ({row: e}) => w.value(e),
            callback: ({row: e, column: a, $index: t}) => {
                l.emit("dataSource.remove", {
                    row: e,
                    index: t
                });
            }
        } ])), L = ({row: a, column: t, rowIndex: l, columnIndex: o}) => y.value && C.value.includes(t.property) ? 0 === l ? [ e.value.length, 1 ] : [ 0, 1 ] : [ 1, 1 ], T = (e, a) => {
            C.value.includes(e) && l.emit("dataSource.replaceAll", {
                key: e,
                value: a
            });
        }, E = e => {
            var a, t;
            const l = [ K.RADIO, K.CHECKBOX ].includes(null == e || null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.code) && (g.value || b.value || v.value), o = [ K.MULTIPLE_TEXT, K.SINGLE_TEXT ].includes(null == e || null === (t = e.defineMetaData) || void 0 === t ? void 0 : t.code) && h.value;
            return l || o;
        }, I = e => {
            var a;
            return [ K.ATTACHMENT, K.IMAGE ].includes(null == e || null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.code);
        }, F = (e, a) => {
            var t;
            let l = 14 * e.label + a ? 20 : 0;
            return l = (null !== (t = e.minWidth) && void 0 !== t ? t : l < 180) ? 180 : l, 
            I(e) ? 300 : (e => {
                var a;
                return [ K.CASCADER, K.MEMBER, K.DEPARTMENT ].includes(null == e || null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.code);
            })(e) ? 270 : E(e) ? 180 : l;
        };
        return () => f("div", {
            class: "ol-sub-form-table"
        }, [ f(V("ssu-table-v2"), {
            size: "small",
            "header-row-class-name": "ol-sub-form-table__header-row",
            "header-cell-class-name": "ol-sub-form-table__header-cell",
            "cell-class-name": "ol-sub-form-table__cell",
            stripe: !0,
            data: e.value,
            "show-pagination": !1,
            onSortChange: d,
            evenRowColor: "#fff",
            oddRowColor: "#fff",
            actions: S.value,
            actionsOptions: {
                isActionsEllipsis: !1
            },
            width: 85,
            spanMethod: L,
            onHeaderDragend: (e, a, t, l) => {
                c(null == t ? void 0 : t.property, e);
            }
        }, {
            default: () => [ a.showIndex && f(V("ssu-table-column-v2"), {
                type: "index",
                label: "序号",
                width: m(`${a.pageCode}index`) || 80,
                prop: `${a.pageCode}index`
            }, null), t.value.filter((e => e.visible)).map((e => (e => {
                var a, t, l, n, r, s;
                const d = Ge.includes(null == e || null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.code);
                e.minWidth = F(e, d);
                const c = (null == e || null === (t = e.defineMetaData) || void 0 === t ? void 0 : t.code) && (null == e || null === (l = e.defineMetaData) || void 0 === l ? void 0 : l.code.toLowerCase()), v = (a, t, l, n) => f(V("el-form"), {
                    model: a,
                    ref: a => {
                        const t = `${n}-${e.prop}`;
                        i.value[t] = a;
                    },
                    rules: o.value,
                    size: "small",
                    "hide-required-asterisk": !0,
                    "validate-on-rule-change": !1
                }, {
                    default: () => [ f(vm, {
                        data: a,
                        index: n,
                        config: e,
                        disabled: u.value(a),
                        showLabel: !1,
                        formRules: o.value[e.prop],
                        widgetStatus: p,
                        updateCallback: T
                    }, null) ]
                }), g = (null === (n = o.value) || void 0 === n || null === (r = n[e.prop]) || void 0 === r || null === (s = r.filter((e => "required" === (null == e ? void 0 : e.key) || (null == e ? void 0 : e.required)))) || void 0 === s ? void 0 : s.length) > 0, b = m(e.prop);
                return g ? f(V("ssu-table-column-v2"), {
                    prop: e.prop,
                    className: `is-required ${c}`,
                    minWidth: e.minWidth,
                    width: b,
                    showOverflowTooltip: !E(e) || !I(e),
                    formatter: v,
                    sortable: d
                }, {
                    header: () => f("div", {
                        title: e.label
                    }, [ f("label", {
                        style: {
                            color: "#f86c6c",
                            "margin-right": "4px"
                        }
                    }, [ D("*") ]), e.label ])
                }) : f(V("ssu-table-column-v2"), {
                    prop: e.prop,
                    className: c,
                    minWidth: e.minWidth,
                    width: b,
                    showOverflowTooltip: !E(e) || !I(e),
                    formatter: v,
                    sortable: d
                }, {
                    header: () => f("div", {
                        title: e.label
                    }, [ e.label ])
                });
            })(e))) ]
        }) ]);
    }
});

const gm = e({
    name: "OlSubFormTile",
    setup() {
        const {dataSource: e, isDetail: a, props: t, columns: l, evt: o, formRules: i, formRefs: n, isHiddenDeleteRow: r, widgetStatus: u} = rm(), d = s((() => {
            const e = [ "ol-sub-form-tile" ];
            return e.push(`grid-col-${t.col || 1}`), e;
        }));
        return () => {
            const s = e.value.map(((e, s) => {
                let d;
                return f("div", {
                    class: "ol-sub-form-tile__item"
                }, [ f("div", {
                    class: "ol-sub-form-tile__item-header"
                }, [ f("div", {
                    class: "ol-sub-form-tile__item-header-title"
                }, [ t.showIndex && `${t.indexName}${s + 1}` ]), !(t.disabled || a.value || r.value(e)) && f("div", {
                    class: "ol-sub-form-tile__item-header-action",
                    onClick: () => o.emit("dataSource.remove", {
                        index: s
                    })
                }, [ f(V("syc-icon"), {
                    type: "designer/shanchu"
                }, null) ]) ]), f(V("el-form"), {
                    labelPosition: "top",
                    class: "ol-sub-form-tile__item-grid",
                    rules: i.value,
                    model: e,
                    ref: e => {
                        const a = `tile-form-${s}`;
                        n.value[a] = e;
                    },
                    "validate-on-rule-change": !1
                }, (p = d = l.value.filter((e => e.visible)).map((a => f(vm, {
                    data: e,
                    config: a,
                    widgetStatus: u,
                    formRules: i.value[a.prop],
                    index: s
                }, null))), "function" == typeof p || "[object Object]" === Object.prototype.toString.call(p) && !N(p) ? d : {
                    default: () => [ d ]
                })) ]);
                var p;
            }));
            return f("div", {
                class: d.value
            }, [ e.value.length ? s : f("div", {
                class: "ol-sub-form-tile__empty"
            }, [ D("暂无数据") ]) ]);
        };
    }
}), {formComponentMixin: bm} = z, hm = e({
    name: "OlSubForm",
    props: {
        ...tm
    },
    mixins: [ bm ],
    setup(e, t) {
        const {loading: l} = nm({
            props: e,
            ctx: t
        }), n = i(!e.isLazyRender);
        return w((() => {
            requestIdleCallback((() => {
                n.value = !0;
            }));
        })), () => {
            const t = e.displayType === Jp ? fm : gm;
            return f(V("Pane"), {
                class: "ol-sub-form",
                showCollapsed: !1
            }, {
                default: () => [ n.value && o(f(t, {
                    "element-loading-text": `正在获取${e.label}数据`
                }, null), [ [ a("loading"), l.value ] ]) ],
                title: () => f(cm, null, null)
            });
        };
    }
}), {formComponentMixin: ym} = z, Dm = e({
    name: "OlSubFormMobile",
    mixins: [ ym ],
    setup: () => () => f("div", null, [ D("子表移动端") ])
});

const {setterMixin: Cm} = z, wm = e({
    name: "ColSetter",
    mixins: [ Cm ],
    setup(e) {
        const a = Da(e.metaData, "col");
        return () => {
            let e;
            return f(V("CustomFormItem"), {
                class: "col-setter",
                setter: !0,
                label: "平铺列数"
            }, {
                default: () => {
                    return [ f(V("el-radio-group"), {
                        modelValue: a.value,
                        "onUpdate:modelValue": e => a.value = e
                    }, (t = e = am.options.map((e => f(V("el-radio-button"), {
                        label: e.value
                    }, {
                        default: () => [ e.label ]
                    }))), "function" == typeof t || "[object Object]" === Object.prototype.toString.call(t) && !N(t) ? e : {
                        default: () => [ e ]
                    })) ];
                    var t;
                }
            });
        };
    }
});

const {setterMixin: Sm} = z, Lm = e({
    name: "DisplayTypeSetter",
    mixins: [ Sm ],
    emits: [ "edit" ],
    setup(e, {emit: a}) {
        const t = Da(e.metaData, "showIndex"), l = Da(e.metaData, "displayType"), o = ba(e.metaData, "appShowIndex", t.value), i = ba(e.metaData, "appDisplayType", l.value), n = Da(e.metaData, "disabledSetMergeTableColumn"), r = Da(e.metaData, "mergeTableColumnFields"), u = Da(e.metaData, "allowMergeTableColumn"), d = s((() => {
            var e;
            return u.value && (null === (e = r.value) || void 0 === e ? void 0 : e.length) && n;
        })), p = [ {
            title: "web端",
            displayType: l,
            showIndex: t,
            optionData: em.options
        }, {
            title: "app端",
            displayType: i,
            showIndex: o,
            optionData: em.options
        } ];
        return () => {
            let e;
            return f(V("CustomFormItem"), {
                class: "display-type-setter",
                setter: !0,
                label: "显示方式"
            }, "function" == typeof (a = e = p.map((e => f("div", {
                class: "display-type-setter-item"
            }, [ f("div", {
                class: "display-type-setter-item-top"
            }, [ f("span", null, [ e.title ]), f(V("ssu-checkbox"), {
                modelValue: e.showIndex.value,
                "onUpdate:modelValue": a => e.showIndex.value = a
            }, {
                default: () => [ D("显示序号") ]
            }) ]), f("div", {
                class: "display-type-setter-item-bottom"
            }, [ f(V("ssu-select"), {
                modelValue: e.displayType.value,
                "onUpdate:modelValue": a => e.displayType.value = a,
                optionData: e.optionData,
                disabled: d.value
            }, null) ]) ])))) || "[object Object]" === Object.prototype.toString.call(a) && !N(a) ? e : {
                default: () => [ e ]
            });
            var a;
        };
    }
}), {setterMixin: Tm} = z, Vm = e({
    name: "FieldsSetter",
    mixins: [ Tm ],
    props: {
        platformId: {
            type: String,
            default: ""
        }
    },
    emits: [ "edit" ],
    setup(e, {emit: a}) {
        const t = i(null), l = i(null), o = i(), n = Ia(), r = Da(e.metaData, "fields"), u = be(), d = new ge([]), p = s((() => u.getLibrary("official"))), m = s((() => _(p).metas.filter((e => ![ K.SUB_FORM ].includes(e.code) && e.widgetType === J.FORM && ![ K.SUMMARY ].includes(e.code))))), c = () => {
            t.value = null, l.value = null;
        }, v = () => {
            const e = r.value.findIndex((e => e.fieldName === t.value.fieldName));
            r.value.splice(e, 1), b((() => {
                n.close(), c();
            }));
        }, g = a => {
            var o;
            const i = u.getMeta("official", a), s = u.createWidget("official", a);
            r.value.push(s), d.batchAddFieldName(r.value);
            const p = Ja.get(i, "prependAction");
            if (p) switch (t.value = s, l.value = p, p.type) {
              case "props":
                n.open();
                break;

              case "action":
                null == p || null === (o = p.action) || void 0 === o || o.call(p, {
                    widget: s,
                    appData: e.appData
                });
            }
        }, h = () => {
            const e = o.value.getRef();
            e.value.validate().then((() => {
                const a = e.value.getValue(), l = Ja.omit(a, "form");
                Object.keys(l).forEach((e => {
                    const a = l[e];
                    Ja.set(t.value, [ "props", e, "rawValue" ], a);
                })), n.close(), b((() => {
                    c();
                }));
            }));
        };
        return () => {
            var i, s;
            return f(V("CustomFormItem"), {
                class: "fields-setter",
                setter: !0,
                label: "字段配置"
            }, {
                default: () => [ f(V("ProSortableList"), {
                    modelValue: r.value,
                    "onUpdate:modelValue": e => r.value = e,
                    options: m.value,
                    "not-border": !1,
                    optionConfig: {
                        labelPath: [ "name" ],
                        valuePath: [ "code" ]
                    },
                    createDataFn: g,
                    addConfig: {
                        text: "添加字段"
                    }
                }, {
                    itemLeft: e => Ja.get(e, [ "name", "rawValue" ]),
                    itemRight: (e, t) => f("div", {
                        class: "field-item-edit",
                        onClick: () => {
                            a("edit", {
                                data: e,
                                index: t
                            });
                        }
                    }, [ f(V("syc-icon"), {
                        type: "designer/bianji"
                    }, null) ])
                }), f(V("BaseDialog"), {
                    modelValue: n.visible.value,
                    "onUpdate:modelValue": e => n.visible.value = e,
                    title: null === (i = l.value) || void 0 === i ? void 0 : i.dialogTitle,
                    showClose: !1
                }, {
                    default: () => [ f(V("RenderComponent"), {
                        widgetMap: u.getWidgetMap("official"),
                        code: null === (s = l.value) || void 0 === s ? void 0 : s.form,
                        metaData: t.value,
                        appData: e.appData,
                        pageData: e.pageData,
                        platformId: e.platformId,
                        ref: o
                    }, null) ],
                    footer: () => f(R, null, [ f(V("ssu-button"), {
                        onClick: v
                    }, {
                        default: () => [ D("取消") ]
                    }), f(V("ssu-button"), {
                        onClick: h,
                        type: "primary"
                    }, {
                        default: () => [ D("确定") ]
                    }) ])
                }) ]
            });
        };
    }
}), {setterMixin: Em} = z, Im = e({
    name: "IndexNameSetter",
    mixins: [ Em ],
    setup(e) {
        const a = Da(e.metaData, "indexName");
        return () => f(V("CustomFormItem"), {
            class: "index-name-setter",
            setter: !0,
            label: "序号名称"
        }, {
            default: () => [ f(V("ssu-input-text"), {
                modelValue: a.value,
                "onUpdate:modelValue": e => a.value = e,
                maxLength: 10
            }, null) ]
        });
    }
}), {setterMixin: Fm} = z, Om = e({
    name: "OperationSetter",
    mixins: [ Fm ],
    setup(e) {
        const a = Da(e.metaData, "fields");
        Da(e.metaData, "allowExport");
        const t = Da(e.metaData, "allowFilter"), l = Da(e.metaData, "filterFields"), o = Da(e.metaData, "disabledEditOldRecord"), i = Da(e.metaData, "disabledDeleteOldRecord"), n = s((() => a.value.length ? a.value.filter((e => ![ K.RELATION_FORM_FIELD, K.QRCode ].includes(e.code))).filter((e => !l.value.includes(e.fieldName))) : []));
        return P((() => a.value), (e => {
            l.value = l.value.filter((a => e.some((e => e.fieldName === a))));
        }), {
            deep: !0
        }), () => f(V("CustomFormItem"), {
            class: "operation-setter",
            setter: !0,
            label: "子表操作"
        }, {
            default: () => [ f(V("el-space"), {
                direction: "vertical",
                size: 20,
                alignment: "start"
            }, {
                default: () => [ f("div", null, [ f(V("ssu-checkbox"), {
                    modelValue: t.value,
                    "onUpdate:modelValue": e => t.value = e
                }, {
                    default: () => [ D("支持筛选") ]
                }), t.value && f(V("ProSortableList"), {
                    style: {
                        paddingBottom: 0
                    },
                    modelValue: l.value,
                    "onUpdate:modelValue": e => l.value = e,
                    options: n.value,
                    "not-border": !1,
                    optionConfig: {
                        labelPath: [ "name", "rawValue" ],
                        valuePath: [ "fieldName" ]
                    },
                    createDataFn: e => e,
                    addConfig: {
                        text: "添加筛选字段",
                        props: {
                            disabled: 0 === a.value.length || a.value.length === l.value.length
                        }
                    }
                }, {
                    itemLeft: e => {
                        const t = a.value.find((a => a.fieldName === e));
                        return Ja.get(t, [ "name", "rawValue" ]);
                    }
                }) ]), f(V("ssu-checkbox"), {
                    modelValue: o.value,
                    "onUpdate:modelValue": e => o.value = e
                }, {
                    default: () => [ D("禁用编辑已有记录") ]
                }), f(V("ssu-checkbox"), {
                    modelValue: i.value,
                    "onUpdate:modelValue": e => i.value = e
                }, {
                    default: () => [ D("禁用删除已有记录") ]
                }) ]
            }) ]
        });
    }
}), {setterMixin: Nm} = z;

var Mm = e({
    name: "MergetTableColumnSetter",
    mixins: [ Nm ],
    setup(e) {
        const a = Da(e.metaData, "fields"), t = s((() => a.value.map((e => {
            var a, t;
            return {
                label: null !== (a = null === (t = e.name) || void 0 === t ? void 0 : t.rawValue) && void 0 !== a ? a : "",
                value: e.fieldName
            };
        })))), l = s({
            get: () => {
                var a;
                return null === (a = e.metaData.props.disabledSetMergeTableColumn) || void 0 === a ? void 0 : a.rawValue;
            },
            set: a => {
                e.metaData.props.disabledSetMergeTableColumn ? e.metaData.props.disabledSetMergeTableColumn.rawValue = a : e.metaData.props.disabledSetMergeTableColumn = {
                    rawValue: a,
                    key: "disabledSetMergeTableColumn",
                    type: "raw"
                };
            }
        }), i = s({
            get: () => {
                var a;
                return null === (a = e.metaData.props.allowMergeTableColumn) || void 0 === a ? void 0 : a.rawValue;
            },
            set: a => {
                e.metaData.props.allowMergeTableColumn ? e.metaData.props.allowMergeTableColumn.rawValue = a : e.metaData.props.allowMergeTableColumn = {
                    rawValue: a,
                    key: "allowMergeTableColumn",
                    type: "raw"
                };
            }
        }), n = s({
            get: () => {
                var a;
                return null === (a = e.metaData.props.mergeTableColumnFields) || void 0 === a ? void 0 : a.rawValue;
            },
            set: a => {
                e.metaData.props.mergeTableColumnFields ? e.metaData.props.mergeTableColumnFields.rawValue = a : e.metaData.props.mergeTableColumnFields = {
                    rawValue: a,
                    key: "mergeTableColumnFields",
                    type: "raw"
                };
            }
        }), r = () => {
            Ja.isEmpty(t.value) ? n.value = [] : n.value = n.value.filter((e => t.value.find((a => a.value === e))));
        }, u = () => {
            i.value && r();
        }, d = () => {
            var a, t, o;
            l.value || "list" !== (null === (a = e.metaData) || void 0 === a || null === (t = a.props) || void 0 === t || null === (o = t.displayType) || void 0 === o ? void 0 : o.rawValue) || (l.value = !0);
        };
        return P((() => t.value), (() => {
            r();
        }), {
            deep: !0
        }), w((() => {
            i.value && r(), document.addEventListener(la.SAVE, d);
        })), B((() => {
            document.removeEventListener(la.SAVE, d);
        })), () => {
            var a, r, s;
            return o(f("div", {
                class: "merge-table-column-setter"
            }, [ f("div", {
                class: "top"
            }, [ f(V("ssu-checkbox"), {
                modelValue: i.value,
                "onUpdate:modelValue": e => i.value = e,
                disabled: l.value,
                onChange: u
            }, {
                default: () => [ D("合并列") ]
            }), f(V("el-tooltip"), {
                content: "当子表显示方式为‘列表’时，支持将子表内某列进行整列单元格合并处理，同时支持多个列合并；合并后，合并列对应字段每一行数据取相同的值"
            }, {
                default: () => [ f("span", {
                    class: "tip"
                }, [ f(V("syc-icon"), {
                    type: "designer/bangzhuzhongxin"
                }, null) ]) ]
            }) ]), f("div", {
                class: "bottom"
            }, [ o(f(V("ssu-select"), {
                modelValue: n.value,
                "onUpdate:modelValue": e => n.value = e,
                disabled: l.value,
                optionData: t.value,
                placeholder: "请选择",
                multiple: !0
            }, null), [ [ g, i.value ] ]) ]) ]), [ [ g, "list" === (null === (a = e.metaData) || void 0 === a || null === (r = a.props) || void 0 === r || null === (s = r.displayType) || void 0 === s ? void 0 : s.rawValue) ] ]);
        };
    }
}), Rm = e({
    name: "table-column-row-config-setter",
    setup: (e, {attrs: a}) => () => {
        const t = {
            ...e,
            ...a
        };
        return f(V("CustomFormItem"), {
            class: "table-column-row-config-setter",
            label: "设置",
            setter: !0
        }, {
            default: () => [ f(mm, t, null), f(Mm, t, null) ]
        });
    }
});

const {propsFormMixin: xm} = z;

var Am = e({
    name: "OlSubFormPropsForm",
    mixins: [ xm ],
    emits: [ "changeHeadVisible" ],
    setup(e, {expose: a, emit: t}) {
        const {editable: l, visible: o, visibleWhenCreate: n} = e.metaData, r = s((() => e.metaData.validateRuleConfig)), u = be(), {data: d, formRef: p} = fa(e, a), {convertOptionListToObj: m, convertObjToOptionList: c} = H, v = s((() => i(c({
            editable: l,
            visible: o,
            visibleWhenCreate: n
        }, !0)).value)), g = i(null);
        P((() => g.value), (e => {
            t("changeHeadVisible", Ja.isNil(e));
        }));
        const b = e => {
            g.value = e.data;
        }, h = Da(e.metaData, "showIndex"), y = Da(e.metaData, "displayType");
        Da(e.metaData, "filterFields"), Da(e.metaData, "fields");
        const C = () => {
            const a = u.getWidgetMap(g.value.libInfoCode)[u.getMeta(g.value.libInfoCode, g.value.code).propsForm];
            return f("div", {
                class: "filed-props-form"
            }, [ f("div", {
                class: "filed-props-form__head"
            }, [ f(V("el-breadcrumb"), null, {
                default: () => [ f(V("el-breadcrumb-item"), null, {
                    default: () => [ f("span", {
                        class: "filed-props-form__head-back",
                        onClick: () => g.value = null
                    }, [ D("子表") ]) ]
                }), f(V("el-breadcrumb-item"), null, {
                    default: () => [ f("span", {
                        class: "filed-props-form__head-strong"
                    }, [ D("字段配置") ]) ]
                }) ]
            }) ]), f(a, E({
                modelValue: g.value.props,
                "onUpdate:modelValue": e => g.value.props = e
            }, e, {
                metaData: g.value,
                inSubForm: !0,
                mainFormData: e.formData,
                pageMetaData: {
                    widgets: e.metaData.props.fields,
                    systemAttributes: e.pageMetaData.systemAttributes,
                    mainMetaWidgets: e.pageMetaData.widgets
                }
            }), null) ]);
        };
        return () => f("div", {
            class: "ol-sub-form-props-form"
        }, [ g.value ? C() : f(V("el-form"), {
            ref: p,
            model: d,
            "label-position": "top",
            class: "ol-sub-form-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(Lm, {
                metaData: e.metaData
            }, null), h.value && y.value === Zp && f(Im, {
                metaData: e.metaData
            }, null), y.value === Zp && f(wm, {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                inSubForm: !0,
                pageData: e.pageData,
                appData: e.appData
            }, null), f(Vm, {
                platformId: e.platformId,
                onEdit: b,
                metaData: e.metaData,
                appData: e.appData,
                pageData: e.pageData
            }, null), f(Om, {
                metaData: e.metaData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: r.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("batch-select-add-setter"), {
                metaData: e.metaData
            }, null), f(V("MergeTableColumnSetter"), {
                metaData: e.metaData
            }, null), f(V("max-row-num-setter"), {
                metaData: e.metaData
            }, null), f(Rm, {
                metaData: e.metaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: v.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = m(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        }) ]);
    }
});

const {formEntityMixin: km, metaDataMixin: Pm, formComponentMixin: _m} = z, Um = e({
    name: "OlSubFormTableFormatter",
    props: {
        ...tm
    },
    mixins: [ km, Pm, _m ],
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t}) {
        const l = s({
            get: () => e.modelValue,
            set: e => {
                t("update:modelValue", e);
            }
        });
        return () => e.runtimeEnv === Q.TABLE ? f("div", null, [ D("点击详情查看") ]) : f(hm, E(e, a, {
            modelValue: l.value,
            "onUpdate:modelValue": e => l.value = e
        }), null);
    }
});

var Bm = {
    code: K.SUB_FORM,
    name: "子表",
    group: X.NORMAL,
    icon: "zibiao",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlSubForm",
    mobileWidget: "OlSubFormMobile",
    propsForm: "OlSubFormPropsForm",
    tableFormatter: "OlSubFormTableFormatter",
    props: {
        fields: [],
        allowExport: !1,
        allowFilter: !1,
        col: 1,
        displayType: Jp,
        appDisplayType: Jp,
        showIndex: !1,
        appShowIndex: !1,
        indexName: "项目",
        filterFields: [],
        allowBatchSelectAdd: !1,
        batchSelectAddField: "",
        disabledSetMergeTableColumn: !1,
        allowMergeTableColumn: !1,
        mergeTableColumnFields: [],
        maxRowNum: "",
        allowMaxRowNum: !1,
        disabledEditOldRecord: !1,
        disabledDeleteOldRecord: !1,
        modelCode: ""
    },
    renderConfig: {
        showLabel: !1
    },
    defaultValue: [],
    supportBindVariableProps: [],
    supportI18NProps: [],
    supportEvents: [],
    conditionConfig: {
        defaultOperator: ee.IS_NOT_NULL,
        supportWidgetCodeConfig: [],
        supportOperator: {
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL],
            [ee.IS_NULL]: ae[ee.IS_NULL]
        }
    }
};

hm.meta = Bm, hm.PropsForm = Am, hm.Mobile = Dm, hm.install = e => {
    e.component(hm.name, hm), e.component(Dm.name, Dm), e.component(Am.name, Am), e.component(Um.name, Um);
}, hm.widgetMap = {
    [hm.name]: hm,
    [Dm.name]: Dm,
    [Am.name]: Am,
    [Um.name]: Um
};

var Wm = (e, a) => {
    const t = le(), l = i({
        lng: 0,
        lat: 0
    }), o = i(3), n = i({
        lng: 116.403963,
        lat: 39.915119
    }), r = i(), u = i({}), d = i(!0), p = i(""), m = i(), c = i([]), v = i(!1), f = i(!1), g = s((() => e.metaData.name)), h = Ja.debounce((async e => {
        f.value = !0;
        const a = await (async e => {
            const a = await t.base.getSuggestion(e);
            if (!a.length) return Promise.resolve([]);
            const l = Ja.cloneDeep(a[0]);
            return l.name = "", l.address = e.address, Promise.resolve([ l, ...a ]);
        })({
            address: e
        });
        c.value = a, f.value = !1;
    }), 500), y = () => new Promise(((e, a) => {
        let t = new BMap.Geolocation;
        t.enableSDKLocation(), t.getCurrentPosition((t => {
            t ? e(t) : a("定位失败");
        }), {
            enableHighAccuracy: !1
        });
    })), D = () => {
        var a, t, i, r, s;
        (l.value.lng = (null === (a = e.oriPoint) || void 0 === a ? void 0 : a.lng) || 116.404, 
        l.value.lat = (null === (t = e.oriPoint) || void 0 === t ? void 0 : t.lat) || 39.915, 
        o.value = 15, u.value = Ja.cloneDeep(e.oriPoint), d.value) && (v.value = !0, null !== (i = e.oriPoint) && void 0 !== i && i.lng && null !== (r = e.oriPoint) && void 0 !== r && r.lat && null !== (s = e.oriPoint) && void 0 !== s && s.address ? w({
            lng: e.oriPoint.lng,
            lat: e.oriPoint.lat
        }).then((a => {
            c.value = (null == a ? void 0 : a.surroundingPois) || [], l.value = e.oriPoint, 
            n.value = e.oriPoint;
        })).finally((() => {
            v.value = !1;
        })) : y().then((e => {
            e.point && (l.value = e.point, n.value = e.point);
        })).finally((() => {
            v.value = !1;
        })));
        d.value = !1;
    }, C = () => {
        a.emit("closed");
    }, w = e => new Promise(((a, l) => {
        var o, i;
        t.form.getAddressByPoi({
            ...e,
            lat: null !== (o = e.lat) && void 0 !== o ? o : e.y,
            lng: null !== (i = e.lng) && void 0 !== i ? i : e.x
        }).then((async e => {
            var t;
            if (!e) return void l("获取位置失败");
            const o = e.result;
            o.surroundingPois = null !== (t = o.surroundingPois) && void 0 !== t ? t : o.pois || [], 
            o.surroundingPois = o.surroundingPois.map((e => {
                var a, t, l;
                return {
                    ...e,
                    address: null !== (a = null !== (t = e.address) && void 0 !== t ? t : e.addr) && void 0 !== a ? a : o.address,
                    title: null !== (l = e.title) && void 0 !== l ? l : e.name
                };
            })), null != o && o.location && (o.point = null == o ? void 0 : o.location), a(o);
        }));
    }));
    return {
        center: l,
        zoom: o,
        markPoint: n,
        map: r,
        initMap: D,
        handler: ({BMap: e, map: a}) => {
            a.value = a, D();
        },
        currentLocation: u,
        saveAddress: (e, a) => {
            e.point && (e.location = {
                lng: e.point.x,
                lat: e.point.y
            }), e.location ? (l.value = e.location, n.value = e.location, p.value = a, u.value = {
                ...e,
                ...e.location,
                title: e.name,
                addressComponents: Ja.cloneDeep(e)
            }) : C();
        },
        closeDialog: C,
        getLocation: w,
        getLocationPoint: (e, a) => new Promise(((a, t) => {
            e && (l.value = e, n.value = e, w(e).then((e => {
                c.value = e || [], m.value = "", e && a(e);
            })));
        })),
        clickMap: a => {
            e.disabled || (n.value = a.point, u.value.location = a.point, u.value.lng = a.point.lng, 
            u.value.lat = a.point.lat);
        },
        keyword: m,
        searchResult: c,
        goSearchAddress: h,
        goNavigation: e => {
            window.open(`http://api.map.baidu.com/marker?location=${e.lng},${e.lat}&title=${e.title}&output=html`, "_blank");
        },
        getCurrentLocation: y,
        currentLocationLoading: v,
        mapListLoading: f,
        activeSearch: p,
        handleSave: () => {
            u.value.lat && u.value.lng ? u.value.address ? a.emit("saved", u.value) : Ka({
                message: `${g.value}不能为空`,
                type: "warning"
            }) : Ka({
                message: "请先在地图中选择坐标点",
                type: "warning"
            });
        },
        handleLocate: () => {
            l.value = {
                lat: 210,
                lng: 110
            }, b((() => {
                n.value = e.oriPoint, l.value = e.oriPoint;
            }));
        }
    };
}, qm = e({
    name: "OlAddressMapWeb",
    components: {
        BaiduMap: bt,
        BmMarker: ht,
        BmLabel: yt
    },
    props: {
        oriPoint: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        metaData: {
            type: Object,
            default: () => ({})
        }
    },
    emits: [ "saved", "closed", "point" ],
    setup(e, a) {
        const {center: t, zoom: l, markPoint: o, currentLocation: i, handler: n, saveAddress: r, closeDialog: s, initMap: u, getLocationPoint: d, clickMap: p, keyword: m, searchResult: c, goSearchAddress: v, currentLocationLoading: f, mapListLoading: g, activeSearch: b, handleSave: h, handleLocate: y} = Wm(e, a);
        return {
            apiKey: oa,
            center: t,
            zoom: l,
            handler: n,
            getLocationPoint: d,
            clickMap: p,
            markPoint: o,
            keyword: m,
            goSearchAddress: v,
            searchResult: c,
            currentLocation: i,
            saveAddress: r,
            initMap: u,
            closeDialog: s,
            currentLocationLoading: f,
            mapListLoading: g,
            activeSearch: b,
            handleSave: h,
            handleLocate: y
        };
    }
});

const jm = {
    class: "ol-address-map-web"
}, $m = {
    class: "icon-close"
}, zm = {
    key: 0,
    class: "left"
}, Gm = {
    class: "address-wrap"
}, Hm = {
    class: "address-list"
}, Qm = [ "onClick" ], Ym = {
    class: "address-list-item-title"
}, Km = y("div", null, null, -1), Xm = {
    key: 0,
    class: "operateTips"
}, Jm = {
    key: 3,
    class: "address-dialog-footer"
}, Zm = {
    class: "inner-left"
}, ec = {
    class: "button-right"
};

qm.render = function(e, i, n, r, s, u) {
    const d = V("syc-icon"), p = V("ssu-input-text"), m = V("bm-marker"), c = V("bm-label"), g = V("el-form-item"), b = V("ssu-button"), h = V("baidu-map"), C = a("loading");
    return t(), l("div", jm, [ y("div", $m, [ y("i", {
        class: "iconfont icon-guanbi-hov",
        onClick: i[0] || (i[0] = (...a) => e.closeDialog && e.closeDialog(...a))
    }) ]), y("div", {
        class: "icon-init",
        onClick: i[1] || (i[1] = (...a) => e.handleLocate && e.handleLocate(...a))
    }, [ f(d, {
        type: "designer/location"
    }) ]), e.disabled ? F("", !0) : (t(), l("div", zm, [ f(p, {
        placeholder: "搜索地点",
        searchIcon: "icon-zhinengsousuo",
        modelValue: e.keyword,
        "onUpdate:modelValue": i[2] || (i[2] = a => e.keyword = a),
        search: "",
        onKeyEnter: e.goSearchAddress,
        onSearch: e.goSearchAddress,
        onInput: e.goSearchAddress
    }, null, 8, [ "modelValue", "onKeyEnter", "onSearch", "onInput" ]), y("div", Gm, [ o((t(), 
    l("div", Hm, [ (t(!0), l(R, null, x(e.searchResult, ((a, o) => (t(), l("div", {
        key: a.uid,
        onClick: t => e.saveAddress(a, o),
        class: A([ "address-list-item", {
            active: o === e.activeSearch
        } ])
    }, [ y("div", Ym, O(a?.name), 1), y("div", {
        class: A([ "address-list-item-address", {
            first: 0 === o
        } ])
    }, O(a?.address), 3) ], 10, Qm)))), 128)) ])), [ [ C, e.mapListLoading ] ]) ]) ])), Km, f(h, {
        ak: e.apiKey,
        v: "3.0",
        type: "API",
        class: "map-wrap",
        center: e.center,
        zoom: e.zoom,
        onReady: e.handler,
        onClick: e.clickMap,
        "scroll-wheel-zoom": !0,
        "map-click": !1
    }, {
        default: v((() => [ e.disabled ? F("", !0) : (t(), l("div", Xm, " 操作提示：在地图上单击鼠标左键或拖动定位图标可调整坐标点位置，滚动鼠标滚轮可缩放地图，按住鼠标左键可拖动地图 ")), e.oriPoint ? (t(), 
        L(m, {
            key: 1,
            position: e.oriPoint,
            icon: {
                url: "https://static.sycdev.com/ADMIN_SYC/5eb5c65b-0e65-42ea-9fc1-d20c7af20893.png",
                size: {
                    width: 32,
                    height: 32
                },
                opts: {
                    imageSize: {
                        width: 32,
                        height: 32
                    }
                },
                backgroundPosition: "center"
            }
        }, null, 8, [ "position", "icon" ])) : F("", !0), e.disabled ? F("", !0) : (t(), 
        L(m, {
            key: 2,
            position: e.markPoint,
            dragging: !0,
            icon: {
                url: "https://static.sycdev.com/ADMIN_SYC/c5db82e9-b1f2-4d6d-8cda-6c77a2b7c004.png",
                size: {
                    width: 36,
                    height: 72
                },
                opts: {
                    imageSize: {
                        width: 36,
                        height: 36
                    }
                }
            },
            onDragend: e.clickMap
        }, {
            default: v((() => [ f(c, {
                content: "可移动图标至您想要的位置",
                labelStyle: {
                    color: "#fff",
                    fontSize: "12px",
                    border: "none",
                    backgroundColor: "rgba(0,0,0,0.7)",
                    borderRadius: "6px",
                    padding: "5px 10px"
                },
                offset: {
                    width: -65,
                    height: -30
                }
            }, null, 8, [ "labelStyle" ]) ])),
            _: 1
        }, 8, [ "position", "icon", "onDragend" ])), e.disabled ? F("", !0) : (t(), l("div", Jm, [ y("div", Zm, [ f(g, {
            label: "地图选址",
            class: "inner-item address"
        }, {
            default: v((() => [ f(p, {
                modelValue: e.currentLocation.address,
                "onUpdate:modelValue": i[3] || (i[3] = a => e.currentLocation.address = a)
            }, null, 8, [ "modelValue" ]) ])),
            _: 1
        }), f(g, {
            label: "详细地址",
            class: "inner-item title"
        }, {
            default: v((() => [ f(p, {
                modelValue: e.currentLocation.title,
                "onUpdate:modelValue": i[4] || (i[4] = a => e.currentLocation.title = a)
            }, null, 8, [ "modelValue" ]) ])),
            _: 1
        }) ]), y("div", ec, [ f(b, {
            style: {
                "margin-right": "8px"
            },
            onClick: e.closeDialog
        }, {
            default: v((() => [ D("取消") ])),
            _: 1
        }, 8, [ "onClick" ]), f(b, {
            type: "primary",
            onClick: e.handleSave
        }, {
            default: v((() => [ D("确定") ])),
            _: 1
        }, 8, [ "onClick" ]) ]) ])) ])),
        _: 1
    }, 8, [ "ak", "center", "zoom", "onReady", "onClick" ]) ]);
};

var ac = e({
    name: "",
    components: {
        BaiduMap: bt
    },
    emits: [ "saved", "get-current-location-done" ],
    setup(e, a) {
        const {getCurrentLocation: t, getLocation: l, saveAddress: o, center: i, zoom: n} = Wm(e, a);
        return {
            setCurrentLocationMap: () => {
                t().then((e => {
                    e && l(e.point).then((e => {
                        e && o({
                            ...e
                        }), a.emit("get-current-location-done");
                    })).catch((() => {
                        a.emit("get-current-location-done");
                    }));
                })).catch((() => {
                    a.emit("get-current-location-done");
                }));
            },
            apiKey: oa,
            center: i,
            zoom: n
        };
    }
});

ac.render = function(e, a, l, o, i, n) {
    const r = V("baidu-map");
    return t(), L(r, {
        ak: e.apiKey,
        v: "3.0",
        type: "API",
        center: e.center,
        zoom: e.zoom,
        onReady: e.setCurrentLocationMap
    }, {
        default: v((() => [ W(e.$slots, "default") ])),
        _: 3
    }, 8, [ "ak", "center", "zoom", "onReady" ]);
};

var tc = e({
    name: "",
    components: {
        BaiduMap: bt,
        BmMarker: ht,
        BmLabel: yt
    },
    props: {
        oriPoint: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: !1
        }
    },
    emits: [ "saved", "closed" ],
    setup(e, a) {
        const t = i({
            uid: ""
        }), l = i(""), {center: o, zoom: n, markPoint: r, handler: s, saveAddress: u, closeDialog: d, initMap: p, getLocationPoint: m, clickMap: c, keyword: v, searchResult: f, goSearchAddress: g, goNavigation: b, handleSave: h} = Wm(e, a);
        return {
            apiKey: oa,
            center: o,
            zoom: n,
            handler: s,
            saveAddress: u,
            getLocationPoint: m,
            clickMap: c,
            markPoint: r,
            keyword: v,
            goSearchAddress: g,
            searchResult: f,
            handleSave: h,
            initMap: p,
            closeDialog: d,
            choosePoint: (e, a) => {
                t.value = e, u(e), l.value = e.uid + a;
            },
            checkedPoint: t,
            goNavigation: b,
            checkedPointIndex: l
        };
    }
});

const lc = {
    class: "ol-address-mobile-map-web"
}, oc = {
    class: "icon-operate"
}, ic = {
    key: 0,
    class: "address-popup"
}, nc = {
    class: "address-select"
}, rc = {
    class: "address-wrap"
}, sc = [ "onClick" ], uc = {
    class: "address-list-item"
}, dc = {
    class: "address-list-item-title"
}, pc = {
    class: "address-list-item-address"
}, mc = {
    key: 1
}, cc = {
    class: "address-detail-wrap address-list-list"
}, vc = {
    class: "address-list-item"
}, fc = {
    class: "address-list-item-title"
}, gc = {
    class: "address-list-item-address"
};

tc.render = function(e, a, o, i, n, r) {
    const s = V("ssu-button"), u = V("syc-icon"), d = V("ssu-input-text"), p = V("ssu-radio"), m = V("van-popup"), c = V("bm-marker"), g = V("baidu-map");
    return t(), l("div", lc, [ y("div", oc, [ f(s, {
        type: "text",
        onClick: e.closeDialog
    }, {
        default: v((() => [ D(O(e.disabled ? "返回" : "取消"), 1) ])),
        _: 1
    }, 8, [ "onClick" ]), e.disabled ? F("", !0) : (t(), L(s, {
        key: 0,
        type: "primary",
        onClick: e.handleSave
    }, {
        default: v((() => [ D("确定") ])),
        _: 1
    }, 8, [ "onClick" ])) ]), y("div", {
        class: "icon-init",
        onClick: a[0] || (a[0] = (...a) => e.initMap && e.initMap(...a))
    }, [ f(u, {
        type: "designer/location"
    }) ]), f(m, {
        show: e.markPoint,
        "onUpdate:show": a[4] || (a[4] = a => e.markPoint = a),
        position: "bottom",
        round: "",
        overlay: !1,
        style: {
            "max-height": "30%",
            background: "#F2F3F7"
        }
    }, {
        default: v((() => [ e.disabled ? (t(), l("div", mc, [ y("div", cc, [ y("div", vc, [ y("div", fc, O(e.oriPoint?.title), 1), y("div", gc, O(e.oriPoint?.address), 1) ]), f(s, {
            onClick: a[3] || (a[3] = a => e.goNavigation(e.oriPoint)),
            round: "",
            type: "primary"
        }, {
            default: v((() => [ f(u, {
                type: "designer/navigation"
            }), D("导航 ") ])),
            _: 1
        }) ]) ])) : (t(), l("div", ic, [ y("div", nc, [ f(d, {
            placeholder: "搜索地点",
            modelValue: e.keyword,
            "onUpdate:modelValue": a[1] || (a[1] = a => e.keyword = a),
            "css-suffix-icon": "icon-zhinengsousuo",
            onChange: e.goSearchAddress
        }, null, 8, [ "modelValue", "onChange" ]) ]), y("div", rc, [ (t(!0), l(R, null, x(e.searchResult, ((o, i) => (t(), 
        l("div", {
            onClick: a => e.choosePoint(o, i),
            class: "address-list-list",
            key: o.uid
        }, [ y("div", uc, [ y("div", dc, O(o?.title), 1), y("div", pc, O(o?.address), 1) ]), f(p, {
            label: o.uid + i,
            modelValue: e.checkedPointIndex,
            "onUpdate:modelValue": a[2] || (a[2] = a => e.checkedPointIndex = a)
        }, {
            default: v((() => [ D(O("")) ])),
            _: 2
        }, 1032, [ "label", "modelValue" ]) ], 8, sc)))), 128)) ]) ])) ])),
        _: 1
    }, 8, [ "show" ]), f(g, {
        ak: e.apiKey,
        v: "3.0",
        type: "API",
        class: "map-wrap",
        center: e.center,
        zoom: e.zoom,
        onReady: e.handler,
        onClick: e.clickMap,
        "scroll-wheel-zoom": !0,
        "map-click": !1
    }, {
        default: v((() => [ e.oriPoint ? (t(), L(c, {
            key: 0,
            position: e.oriPoint,
            icon: {
                url: "https://static.sycdev.com/ADMIN_SYC/5eb5c65b-0e65-42ea-9fc1-d20c7af20893.png",
                size: {
                    width: 16,
                    height: 16
                },
                opts: {
                    imageSize: {
                        width: 16,
                        height: 16
                    }
                },
                backgroundPosition: "center"
            }
        }, null, 8, [ "position", "icon" ])) : F("", !0), f(c, {
            position: e.markPoint,
            dragging: !e.disabled,
            icon: {
                url: "https://static.sycdev.com/ADMIN_SYC/85db9776-4fb4-4f30-941a-14db0b4bccd4.png",
                size: {
                    width: 24,
                    height: 74
                },
                opts: {
                    imageSize: {
                        width: 24,
                        height: 37
                    }
                }
            },
            onDragend: e.clickMap
        }, null, 8, [ "position", "dragging", "icon", "onDragend" ]) ])),
        _: 1
    }, 8, [ "ak", "center", "zoom", "onReady", "onClick" ]) ]);
};

const {formComponentMixin: bc} = z;

var hc = e({
    name: "OlAddress",
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: !1
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        inline: {
            type: Boolean,
            default: !1
        },
        detailedAddressLabel: {
            type: String,
            default: "详细地址"
        }
    },
    mixins: [ bc ],
    components: {
        baiduMap: qm,
        baiduMobileMap: tc,
        mapLocation: ac
    },
    emits: [ "update:modelValue" ],
    setup(e, {attrs: a, emit: t, expose: l}) {
        const o = Na(e), n = s((() => e.metaData.fieldName)), r = s((() => (e.currentPosition && !c.value?.length || d.value) && !e.disabled)), u = s((() => r.value ? "当前位置获取中…" : e.placeholder)), d = i(!1), p = a => {
            e.disabled || (d.value = a, a && (m.value = {
                address: null,
                showAddress: null
            }));
        }, m = i({
            address: null,
            showAddress: null
        }), c = s({
            get: () => o.value || e.metaData.defaultValue,
            set(e) {
                t("update:modelValue", e);
                const a = m.value, {country: l = null, province: o = null, city: i = null, district: r = null, street: s = null, streetNumber: u = null, town: d = null} = a?.addressComponents || {};
                a?.addressComponents && (t(`update:${n.value}_country`, "中国" === l ? "China" : l), 
                t(`update:${n.value}_province`, o), t(`update:${n.value}_city`, i), t(`update:${n.value}_district`, r), 
                t(`update:${n.value}_street`, s));
                const p = Ja.isNil(a?.address) ? i : a?.address, c = Ja.isNil(a?.title) ? s : a?.title, v = Ja.isNil(a?.lng) ? null : a?.lng, f = Ja.isNil(a?.lat) ? null : a?.lat;
                t(`update:${n.value}_address`, p), t(`update:${n.value}_desc`, c), t(`update:${n.value}_lng`, v), 
                t(`update:${n.value}_lat`, f);
            }
        }), v = s((() => e.formRules.find((e => "required" === e.key || e.required)))), f = s((() => ({
            address: [ {
                required: v.value,
                message: "此项为必填项"
            } ],
            title: [ {
                required: v.value,
                message: "此项为必填项"
            } ]
        }))), g = i(!1), b = e => {
            g.value = e;
        }, h = (e, a = !0) => {
            a && b(!1), m.value = {
                ...m.value,
                ...e
            }, c.value = [ m.value?.lng, m.value?.lat ];
        }, y = s((() => ({
            disabled: e.disabled,
            inline: e.inline
        }))), D = i();
        return P((() => c.value), (a => {
            m.value.lng = a[0], m.value.lat = a[1];
            const t = e.formData || {};
            m.value.address = t[e.metaData.fieldName + "_address"] || null, m.value.title = t[e.metaData.fieldName + "_desc"] || null;
        }), {
            immediate: !0
        }), P((() => (e.formData || {})[e.metaData.fieldName + "_desc"]), ((e, a) => {
            (e || a || !Ja.isEqual(e, a)) && (m.value.title = e);
        })), P((() => m.value.title), ((e, a) => {
            Ja.isEqual(e, a) || t(`update:${n.value}_desc`, e);
        })), {
            data: o,
            bindData: y,
            addressRef: D,
            selectedAddress: c,
            addressData: m,
            validate: () => D.value?.validate(),
            rules: f,
            showMap: g,
            switchMap: b,
            saveAddress: h,
            positionLoading: r,
            currentPlaceholder: u,
            setCurrentLocation: e => {
                h(e, !1), p(!1);
            },
            handleGetLocationDone: () => {
                const e = setTimeout((() => {
                    d.value = !1, m.value?.lng && m.value?.lat || Ka({
                        message: "当前位置自动获取失败,请手动输入",
                        type: "warning"
                    }), clearTimeout(e);
                }), 200);
            },
            currentLocationFlag: d,
            updateCurrentLocationFlag: p,
            resetFormData: () => {
                m.value = {
                    address: null,
                    showAddress: m.value.showAddress,
                    addressComponents: {}
                }, c.value = null;
            },
            openDetail: () => {
                e.disabled && m.value.address && b(!0);
            }
        };
    }
});

hc.render = function(e, a, i, n, r, s) {
    const u = V("el-button"), d = V("ssu-input-text"), p = V("el-form-item"), m = V("mapLocation"), c = V("baiduMobileMap"), b = V("van-popup"), h = V("baiduMap"), D = V("BaseDialog"), C = V("el-form");
    return t(), L(C, E({
        class: [ {
            "ol-address-inline": e.inline
        }, "ol-address" ],
        rules: e.rules,
        model: e.addressData,
        ref: "addressRef"
    }, e.bindData, {
        "validate-on-rule-change": !1
    }), {
        default: v((() => [ f(p, {
            prop: "address",
            label: e.label,
            class: "ol-address-inline-form-item"
        }, {
            default: v((() => [ y("div", {
                class: "address-wrap",
                onClick: a[1] || (a[1] = (...a) => e.openDetail && e.openDetail(...a))
            }, [ f(d, {
                placeholder: e.currentPlaceholder,
                modelValue: e.addressData.address,
                clearable: "",
                onClear: e.resetFormData,
                onClick: a[0] || (a[0] = a => e.switchMap(!0)),
                class: "real-address"
            }, $({
                _: 2
            }, [ e.positionLoading ? {
                name: "prefix",
                fn: v((() => [ f(u, {
                    loading: e.positionLoading,
                    type: "text",
                    class: "position-loading"
                }, null, 8, [ "loading" ]) ])),
                key: "0"
            } : void 0 ]), 1032, [ "placeholder", "modelValue", "onClear" ]) ]) ])),
            _: 1
        }, 8, [ "label" ]), f(p, {
            label: e.detailedAddressLabel,
            prop: "title",
            class: "ol-address-inline-form-item"
        }, {
            default: v((() => [ f(d, {
                loading: e.positionLoading,
                placeholder: e.currentPlaceholder,
                modelValue: e.addressData.title,
                "onUpdate:modelValue": a[2] || (a[2] = a => e.addressData.title = a),
                modelModifiers: {
                    trim: !0
                }
            }, $({
                _: 2
            }, [ e.positionLoading ? {
                name: "prefix",
                fn: v((() => [ f(u, {
                    loading: e.positionLoading,
                    type: "text",
                    class: "position-loading"
                }, null, 8, [ "loading" ]) ])),
                key: "0"
            } : void 0 ]), 1032, [ "loading", "placeholder", "modelValue" ]) ])),
            _: 1
        }, 8, [ "label" ]), e.currentLocationFlag ? o((t(), L(m, {
            key: 0,
            onSaved: e.setCurrentLocation,
            onGetCurrentLocationDone: e.handleGetLocationDone
        }, null, 8, [ "onSaved", "onGetCurrentLocationDone" ])), [ [ g, !1 ] ]) : F("", !0), e.showMap ? (t(), 
        l(R, {
            key: 1
        }, [ e.isMobile ? (t(), L(b, {
            key: 0,
            show: e.showMap,
            "onUpdate:show": a[4] || (a[4] = a => e.showMap = a),
            position: "top",
            style: {
                height: "100%"
            }
        }, {
            default: v((() => [ e.showMap ? (t(), L(c, {
                key: 0,
                onSaved: e.saveAddress,
                onClosed: a[3] || (a[3] = a => e.switchMap(!1)),
                oriPoint: e.addressData,
                disabled: e.disabled
            }, null, 8, [ "onSaved", "oriPoint", "disabled" ])) : F("", !0) ])),
            _: 1
        }, 8, [ "show" ])) : (t(), L(D, {
            key: 1,
            modelValue: e.showMap,
            "onUpdate:modelValue": a[6] || (a[6] = a => e.showMap = a),
            size: "large",
            showClose: !1,
            "custom-class": "ol-address-map-dialog"
        }, {
            default: v((() => [ f(h, {
                onSaved: e.saveAddress,
                onClosed: a[5] || (a[5] = a => e.switchMap(!1)),
                oriPoint: e.addressData,
                disabled: e.disabled,
                metaData: e.metaData
            }, null, 8, [ "onSaved", "oriPoint", "disabled", "metaData" ]) ])),
            _: 1
        }, 8, [ "modelValue" ])) ], 64)) : F("", !0) ])),
        _: 1
    }, 16, [ "class", "rules", "model" ]);
};

const {formComponentMixin: yc} = z;

var Dc = e({
    name: "OlAddressMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ yc ],
    setup: e => () => f("div", null, [ D("测试移动端") ])
});

const {propsFormMixin: Cc} = z;

var wc = e({
    name: "OlAddressPropsForm",
    mixins: [ Cc ],
    setup(e, {expose: a}) {
        const t = s((() => e.metaData.validateRuleConfig)), {data: l, formRef: o} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return Ja.has(e.metaData.props, "currentPosition") && (e.metaData.props.currentPosition.rawValue && (e.metaData.dynamicDefaultValue = {
            type: ia.FIXED,
            expression: "${current_position}"
        }), delete e.metaData.props.currentPosition), () => f(V("el-form"), {
            ref: o,
            model: l,
            "label-position": "top",
            class: "ol-address-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                appData: e.appData,
                pageData: e.pageData
            }, null), f(V("validate-rule-setter"), {
                metaData: e.metaData,
                validateRuleConfigList: t.value,
                "onUpdate:validateRuleConfigList": (e = []) => {}
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Sc, metaDataMixin: Lc} = z;

var Tc = e({
    name: "OlAddressTableFormatter",
    mixins: [ Sc, Lc ],
    props: {
        isMobile: {
            type: Boolean,
            default: !1
        },
        label: {
            type: String,
            default: ""
        },
        runtimeEnv: {
            type: String,
            default: ""
        }
    },
    setup(e, {attrs: a}) {
        const t = s((() => e.label)), l = s((() => {
            var a, t, l;
            return null !== (a = null !== (t = null === (l = e.metaData) || void 0 === l ? void 0 : l.metaData) && void 0 !== t ? t : e.metaData) && void 0 !== a ? a : {};
        })), n = s((() => {
            var e;
            return null === (e = a.formRules) || void 0 === e ? void 0 : e.find((e => "required" === e.key || e.required));
        })), r = s((() => null == e ? void 0 : e.runtimeEnv)), u = s((() => {
            var a, t;
            const o = e.formData, i = e.fieldValue;
            return {
                address: `${null !== (a = o[l.value.fieldName + "_address"]) && void 0 !== a ? a : ""}`,
                desc: `${null !== (t = o[l.value.fieldName + "_desc"]) && void 0 !== t ? t : ""}`,
                lng: null == i ? void 0 : i[0],
                lat: null == i ? void 0 : i[1]
            };
        })), d = i(!1), p = e => {
            d.value = e;
        };
        return () => {
            var a, l;
            return f("div", {
                class: "ol-address-table-formatter"
            }, [ o(f("div", null, [ f("label", {
                class: [ "el-form-item__label custom-form-item-label", n.value && "required-label" ]
            }, [ f("span", null, [ t.value ]) ]) ]), [ [ g, "table" !== r.value ] ]), null !== (a = u.value) && void 0 !== a && a.address ? f("div", {
                class: [ "el-form-item__content custom-form-item-content", "real-address", "table" === r.value && "table-address" ],
                onClick: e => e.stopPropagation()
            }, [ f("span", {
                onClick: () => {
                    p(!0);
                }
            }, [ f(V("syc-icon"), {
                class: "address-icon",
                type: "designer/address"
            }, null), null === (l = u.value) || void 0 === l ? void 0 : l.address, "table" === r.value && u.value.desc ]) ]) : Y, o(f("div", {
                class: "detail-address"
            }, [ f("div", null, [ f("label", {
                class: [ "el-form-item__label custom-form-item-label", n.value && "required-label" ]
            }, [ f("span", null, [ D("详细地址") ]) ]) ]), f("div", {
                class: "el-form-item__content custom-form-item-content"
            }, [ f("span", {
                onClick: () => {
                    p(!0);
                }
            }, [ u.value.desc || Y ]) ]) ]), [ [ g, "table" !== r.value ] ]), e.isMobile ? f(V("van-popup"), {
                show: d.value,
                "onUpdate:show": e => d.value = e,
                position: "top",
                style: {
                    height: "100%"
                }
            }, {
                default: () => [ f(tc, {
                    onClosed: () => p(!1),
                    oriPoint: u.value,
                    disabled: !0
                }, null) ]
            }) : f(V("BaseDialog"), {
                modelValue: d.value,
                "onUpdate:modelValue": e => d.value = e,
                size: "large",
                showClose: !1,
                "custom-class": "ol-address-map-dialog"
            }, {
                default: () => [ f(qm, {
                    disabled: !0,
                    onClosed: () => p(!1),
                    oriPoint: u.value
                }, null) ]
            }) ]);
        };
    }
}), Vc = {
    code: K.ADDRESS,
    name: "地图选址",
    group: X.HIGH_LEVEL,
    icon: "address",
    widgetType: J.FORM,
    dataType: Z.ARRAY,
    widget: "OlAddress",
    propsForm: "OlAddressPropsForm",
    tableFormatter: "OlAddressTableFormatter",
    props: {
        placeholder: "请输入"
    },
    defaultValue: [],
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    renderConfig: {
        showLabel: !1
    },
    extraValues: [ {
        fieldName: "_desc",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "地名描述"
        },
        fieldLevel: 1,
        defaultValue: ""
    }, {
        fieldName: "_address",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "地址"
        },
        fieldLevel: 1,
        defaultValue: ""
    }, {
        fieldName: "_lng",
        dataType: Z.NUMBER,
        name: {
            type: "raw",
            key: "name",
            rawValue: "经度"
        },
        fieldLevel: 1,
        props: {
            showDecimal: {
                type: "raw",
                key: "showDecimal",
                rawValue: !0
            },
            decimalCount: {
                type: "raw",
                key: "decimalCount",
                rawValue: 14
            }
        }
    }, {
        fieldName: "_lat",
        dataType: Z.NUMBER,
        name: {
            type: "raw",
            key: "name",
            rawValue: "纬度"
        },
        fieldLevel: 1,
        props: {
            showDecimal: {
                type: "raw",
                key: "showDecimal",
                rawValue: !0
            },
            decimalCount: {
                type: "raw",
                key: "decimalCount",
                rawValue: 14
            }
        }
    }, {
        fieldName: "_country",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "国家"
        },
        fieldLevel: 1
    }, {
        fieldName: "_province",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "省"
        },
        fieldLevel: 1
    }, {
        fieldName: "_city",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "市"
        },
        fieldLevel: 1
    }, {
        fieldName: "_district",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "区"
        },
        fieldLevel: 1
    }, {
        fieldName: "_street",
        dataType: Z.STRING,
        name: {
            type: "raw",
            key: "name",
            rawValue: "街道"
        },
        fieldLevel: 1
    } ],
    conditionConfig: {
        defaultOperator: ee.IS_NULL,
        supportWidgetCodeConfig: [ K.SINGLE_TEXT, K.MULTIPLE_TEXT, K.SEQUENCE, K.RADIO, K.SINGLE_SELECT ],
        supportOperator: {
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

hc.meta = Vc, hc.PropsForm = wc, hc.Mobile = Dc, hc.install = e => {
    e.component(hc.name, hc), e.component(wc.name, wc), e.component(Dc.name, Dc), e.component(Tc.name, Tc);
}, hc.widgetMap = {
    [Dc.name]: Dc,
    [hc.name]: hc,
    [wc.name]: wc,
    [Tc.name]: Tc
};

const {metaDataMixin: Ec} = z, Ic = e({
    name: "OlTab",
    props: {
        tabList: {
            type: Array,
            default: () => []
        },
        displayByDetailEnv: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Ec ],
    setup(e, {slots: a}) {
        var t, l;
        const o = s((() => e.runtimeEnv === Q.DESIGNER)), n = s((() => o.value ? e.tabList : e.tabList.filter(((e, a) => (e.index = a, 
        void 0 === e.displayEnv || !Array.isArray(e.displayEnv) || !!e.displayEnv.includes(na.WEB)))))), r = i(null !== (t = null === (l = Ja.head(n.value)) || void 0 === l ? void 0 : l.value) && void 0 !== t ? t : ""), u = i(), d = s((() => o.value ? {
            paddingBottom: "40px"
        } : null)), p = s((() => !!e.displayByDetailEnv && e.runtimeEnv !== Q.DETAIL)), m = i([ r.value ]), c = e => (null == e ? void 0 : e.widgetType) === J.CONTAINER ? e.children.find((e => e.find((e => c(e))))) : Je.of({
            ...e
        }).hasRequiredRule(), v = (a, t) => {
            var l;
            const o = (null === (l = e.metaData.children) || void 0 === l ? void 0 : l[t]).find((e => c(e)));
            return m.value.includes(a.value) || !!o;
        };
        return w((() => {
            o.value && P((() => n.value), (e => {
                if (!e.length) return;
                u.value = qa();
                e.some((e => e.value === r.value)) || (r.value = Ja.head(e).value);
            }), {
                deep: !0
            });
        })), P((() => r.value), (e => {
            m.value.includes(e) || m.value.push(e);
        })), () => {
            let e;
            if (!n.value.length) return null;
            const t = W(a, "default");
            var l, i;
            return !o.value && p.value ? f("div", {
                class: "ol-tab"
            }, [ null === (l = t.children[0]) || void 0 === l ? void 0 : l.children[0] ]) : f("div", {
                class: "ol-tab"
            }, [ f(V("ssu-tab"), {
                active: r.value,
                "onUpdate:active": e => r.value = e
            }, (i = e = n.value.map(((e, a) => {
                var l;
                const i = o.value ? a : e.index;
                return f(V("el-tab-pane"), {
                    name: e.value,
                    key: `${e.value}_${i}`,
                    label: e.label
                }, {
                    default: () => [ v(e, i) && f("div", {
                        style: d.value
                    }, [ null === (l = t.children[0]) || void 0 === l ? void 0 : l.children[i] ]) ]
                });
            })), "function" == typeof i || "[object Object]" === Object.prototype.toString.call(i) && !N(i) ? e : {
                default: () => [ e ]
            })) ]);
        };
    }
}), {metaDataMixin: Fc} = z, Oc = e({
    name: "OlTabChild",
    props: {
        parentMetaData: {
            type: Object,
            default: () => ({})
        },
        parentProps: {
            type: Object,
            default: () => ({})
        },
        isMobile: {
            type: Boolean,
            default: !1
        },
        index: {
            type: Number
        }
    },
    mixins: [ Fc ],
    setup: (e, {slots: a}) => () => f("div", {
        class: "ol-tab-child"
    }, [ W(a, "default") ])
}), Nc = e({
    name: "OptionSetting",
    props: {
        modelValue: {
            type: Array
        }
    },
    emits: [ "update:modelValue" ],
    setup(e, {emit: a}) {
        const t = i({}), l = s({
            get: () => void 0 === e.modelValue ? [ na.APP, na.WEB ] : e.modelValue,
            set(e) {
                a("update:modelValue", e);
            }
        });
        return () => f(V("el-popover"), {
            placement: "bottom",
            width: 206,
            trigger: "click"
        }, {
            default: () => [ f("div", {
                id: "tab-option-setting-body"
            }, [ f(V("el-form"), {
                model: t.value,
                "label-position": "top"
            }, {
                default: () => [ f(V("el-form-item"), {
                    label: "显示端"
                }, {
                    default: () => [ f(V("ssu-checkbox-group"), {
                        modelValue: l.value,
                        "onUpdate:modelValue": e => l.value = e,
                        class: "setting-checkbox-group"
                    }, {
                        default: () => [ f(V("ssu-checkbox"), {
                            label: na.WEB
                        }, {
                            default: () => [ D("Web端") ]
                        }), f(V("ssu-checkbox"), {
                            label: na.APP
                        }, {
                            default: () => [ D("App端") ]
                        }) ]
                    }) ]
                }) ]
            }) ]) ],
            reference: () => f("div", null, [ f(V("syc-icon"), {
                type: "setting4"
            }, null) ])
        });
    }
}), {propsFormMixin: Mc} = z, Rc = e({
    name: "OlTabPropsForm",
    mixins: [ Mc ],
    setup(e, {slots: a}) {
        const t = Da(e.metaData, "tabList"), l = Da(e.metaData, "displayByDetailEnv"), o = () => ({
            label: `选项卡${t.value.length + 1}`,
            value: qa(),
            displayEnv: [ na.APP, na.WEB ]
        }), n = i({}), r = () => t.value.reduce(((e, a, t) => (e[a.value] = t, e)), {}), s = () => {
            const e = r();
            n.value = e;
        };
        w((() => {
            s();
        }));
        const u = a => {
            b((() => {
                const a = r(), l = new Array(t.value.length);
                t.value.forEach((t => {
                    const o = n.value[t.value], i = a[t.value], r = e.metaData.children[o];
                    l[i] = r;
                })), e.metaData.children = l, s();
            }));
        };
        return () => f(V("el-form"), {
            "label-position": "top"
        }, {
            default: () => [ f(V("CustomFormItem"), {
                setter: !0,
                label: "选项卡配置"
            }, {
                default: () => [ f(V("ProSortableList"), {
                    modelValue: t.value,
                    "onUpdate:modelValue": e => t.value = e,
                    createDataFn: o,
                    dropdownMode: !1,
                    keepOne: !0,
                    "not-border": !0,
                    onRemove: ({index: a}) => {
                        e.metaData.children.splice(a, 1), s();
                    },
                    onEnd: u,
                    onAdd: () => {
                        e.metaData.children.push([]), s();
                    },
                    addConfig: {
                        text: "添加选项卡"
                    }
                }, {
                    itemLeft: e => f(V("ssu-input-text"), {
                        modelValue: e.label,
                        "onUpdate:modelValue": a => e.label = a
                    }, {
                        suffix: () => f(Nc, {
                            modelValue: e.displayEnv,
                            "onUpdate:modelValue": a => e.displayEnv = a
                        }, null)
                    })
                }) ]
            }), f(V("CustomFormItem"), {
                setter: !0,
                label: "设置"
            }, {
                default: () => [ f(V("ssu-checkbox"), {
                    modelValue: l.value,
                    "onUpdate:modelValue": e => l.value = e
                }, {
                    default: () => [ D("仅详情页展示") ]
                }) ]
            }) ]
        });
    }
});

var xc = {
    code: "Tab",
    name: "选项卡",
    icon: "xuanxiangka",
    group: X.HIGH_LEVEL,
    widgetType: J.CONTAINER,
    widget: "OlTab",
    child: "OlTabChild",
    mobileWidget: "OlTabMobile",
    propsForm: "OlTabPropsForm",
    children: [ [], [] ],
    props: {
        displayByDetailEnv: !1,
        tabList: [ {
            label: "选项卡1",
            value: "tab1",
            displayEnv: [ na.APP, na.WEB ]
        }, {
            label: "选项卡2",
            value: "tab2",
            displayEnv: [ na.APP, na.WEB ]
        } ]
    }
};

Ic.install = e => {
    e.component(Ic.name, Ic), e.component(Oc.name, Oc), e.component(Rc.name, Rc);
}, Ic.meta = xc, Ic.PropsForm = Rc, Ic.Child = Oc, Ic.widgetMap = {
    [Oc.name]: Oc,
    [Ic.name]: Ic,
    [Rc.name]: Rc
};

const {metaDataMixin: Ac, formEntityMixin: kc} = z, Pc = e({
    name: "OlWebView",
    props: {
        url: {
            type: String,
            default: ""
        },
        autoHeight: {
            type: Boolean,
            default: !1
        },
        allowParam: {
            type: Boolean,
            default: !1
        },
        params: {
            type: Array,
            default: () => []
        },
        height: {
            type: Number,
            default: 0
        },
        allowOpenNewWindow: {
            type: Boolean,
            default: !1
        }
    },
    mixins: [ Ac, kc ],
    setup(e, {slots: a}) {
        const t = s((() => e.variables)), l = ra(t), o = s((() => !(e => {
            const a = Ja.find(sa, {
                type: "link"
            });
            return new RegExp(a).test(e);
        })(e.url))), i = s((() => ja(e.url))), n = s((() => {
            const a = [ "ol-web-view" ];
            return e.autoHeight && a.push("is-auto-height"), a;
        })), r = s((() => e.height + "px")), u = s((() => i.value && !o.value && e.allowOpenNewWindow)), d = s((() => {
            if (e.allowParam && Ua(e.params)) {
                return _(l).resolveByKeyValueArray2QueryString(e.params);
            }
            return "";
        })), p = s((() => e.url.includes("?") ? `${e.url}&${d.value}` : [ e.url, d.value ].join("?"))), m = () => {
            window.open(p.value, "_blank");
        };
        return () => f("div", {
            class: n.value,
            style: {
                height: !e.autoHeight && r.value
            }
        }, [ i.value ? o.value ? f("div", {
            class: "ol-web-view-message"
        }, [ D("Iframe链接设置有误") ]) : f("iframe", {
            key: p.value,
            src: p.value
        }, null) : f("div", {
            class: "ol-web-view-message"
        }, [ D("请设置Iframe链接") ]), u.value && f("span", {
            class: "open-window-icon",
            onClick: m
        }, [ f(V("syc-icon"), {
            type: "icon-danchuangkou"
        }, null) ]) ]);
    }
}), {propsFormMixin: _c} = z, {convertObjToOptionList: Uc, convertOptionListToObj: Bc} = H, Wc = e({
    name: "OlWebViewPropsForm",
    mixins: [ _c ],
    setup(e, {slots: a}) {
        const t = Da(e.metaData, "url"), l = Da(e.metaData, "autoHeight"), o = Da(e.metaData, "height"), n = Da(e.metaData, "allowParam"), r = Da(e.metaData, "params"), u = Da(e.metaData, "urlToMobile", ""), d = Da(e.metaData, "heightToMobile", 400), p = Da(e.metaData, "allowParamToMobile", !1), m = Da(e.metaData, "paramsToMobile", []), c = Da(e.metaData, "allowOpenNewWindow", !0), v = s((() => e.metaData.visible)), g = s((() => e.metaData.visibleWhenCreate)), b = s((() => i(Uc({
            visible: v.value,
            visibleWhenCreate: g.value
        }, !0)).value)), h = () => {
            const e = o.value;
            o.value = e > 400 ? e < 1e3 ? Math.floor(e) : 1e3 : 400;
        }, y = () => {
            const e = d.value;
            d.value = e > 400 ? e < 1e3 ? Math.floor(e) : 1e3 : 400;
        };
        return () => f(V("el-form"), {
            "label-position": "top",
            class: "ol-web-view-props-form"
        }, {
            default: () => [ f("div", {
                class: "terminal-setter-wrapper"
            }, [ f("div", {
                class: "terminal-web"
            }, [ f("div", {
                class: "terminal-title"
            }, [ D("Web") ]), f(V("CustomFormItem"), {
                label: "iFrame地址",
                setter: !0
            }, {
                default: () => [ f(V("ssu-input-text"), {
                    placeholder: "请输入嵌入网页的URL",
                    type: "textarea",
                    modelValue: t.value,
                    "onUpdate:modelValue": e => t.value = e
                }, null) ]
            }), f("div", {
                class: "web-view-attributes-setter"
            }, [ f("div", {
                class: "web-view-attributes-setter-header"
            }, [ f("span", null, [ D("高度") ]), f("span", {
                style: {
                    color: "#616266",
                    display: "flex",
                    "align-items": "center"
                }
            }, [ f("span", {
                style: {
                    marginRight: "8px"
                }
            }, [ D("自适应") ]), f(V("el-switch"), {
                modelValue: l.value,
                "onUpdate:modelValue": e => l.value = e
            }, null) ]) ]), !l.value && f(V("ssu-input-text"), {
                placeholder: "请输入整数",
                modelValue: o.value,
                "onUpdate:modelValue": e => o.value = e,
                onBlur: h,
                maxlength: 4
            }, {
                suffix: () => f("div", {
                    style: {
                        color: "#303133"
                    }
                }, [ D("px") ])
            }) ]), f("div", {
                class: " web-view-attributes-setter"
            }, [ f("div", {
                class: "web-view-attributes-setter-header"
            }, [ f("span", null, [ D("对链接目标传参") ]), f(V("el-switch"), {
                modelValue: n.value,
                "onUpdate:modelValue": e => n.value = e
            }, null) ]), n.value && f(V("ParamsSetter"), {
                pageMetaData: e.pageMetaData,
                modelValue: r.value,
                "onUpdate:modelValue": e => r.value = e
            }, null) ]) ]), f("div", {
                class: "terminal-mobile"
            }, [ f("div", {
                class: "terminal-title"
            }, [ D("App") ]), f(V("CustomFormItem"), {
                label: "iFrame地址",
                setter: !0
            }, {
                default: () => [ f(V("ssu-input-text"), {
                    placeholder: "请输入嵌入网页的URL",
                    type: "textarea",
                    modelValue: u.value,
                    "onUpdate:modelValue": e => u.value = e
                }, null) ]
            }), f("div", {
                class: "web-view-attributes-setter"
            }, [ f("div", {
                class: "web-view-attributes-setter-header"
            }, [ f("span", null, [ D("高度") ]) ]), f(V("ssu-input-text"), {
                placeholder: "请输入整数",
                modelValue: d.value,
                "onUpdate:modelValue": e => d.value = e,
                onBlur: y,
                maxlength: 4
            }, {
                suffix: () => f("div", {
                    style: {
                        color: "#303133"
                    }
                }, [ D("px") ])
            }) ]), f("div", {
                class: " web-view-attributes-setter"
            }, [ f("div", {
                class: "web-view-attributes-setter-header"
            }, [ f("span", null, [ D("对链接目标传参") ]), f(V("el-switch"), {
                modelValue: p.value,
                "onUpdate:modelValue": e => p.value = e
            }, null) ]), p.value && f(V("ParamsSetter"), {
                pageMetaData: e.pageMetaData,
                modelValue: m.value,
                "onUpdate:modelValue": e => m.value = e
            }, null) ]) ]) ]), f(V("el-divider"), {
                "border-style": "dashed"
            }, null), f(V("CustomFormItem"), {
                label: "设置",
                setter: !0
            }, {
                default: () => [ f(V("el-checkbox"), {
                    modelValue: c.value,
                    "onUpdate:modelValue": e => c.value = e,
                    label: "允许新页面打开链接"
                }, null) ]
            }), f(V("field-status-setter"), {
                metaData: e.metaData,
                filterKey: "editable",
                statusConfigList: b.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = Bc(a, G.options.filter((e => "editable" !== e.key)), !0), {visible: l, visibleWhenCreate: o} = t;
                    e.metaData.visible = l, e.metaData.visibleWhenCreate = o;
                }
            }, null) ]
        });
    }
});

var qc = {
    code: K.WEB_VIEW,
    name: "Iframe",
    icon: "iframe",
    group: X.HIGH_LEVEL,
    widgetType: J.DISPLAY,
    widget: "OlWebView",
    mobileWidget: "OlWebViewMobile",
    propsForm: "OlWebViewPropsForm",
    props: {
        url: "",
        autoHeight: !0,
        allowParam: !1,
        params: [],
        height: 400,
        urlToMobile: "",
        allowParamToMobile: !1,
        paramsToMobile: [],
        heightToMobile: 400,
        allowOpenNewWindow: !0
    },
    renderConfig: {
        showLabel: !1
    }
};

Pc.install = e => {
    e.component(Pc.name, Pc), e.component(Wc.name, Wc);
}, Pc.meta = qc, Pc.PropsForm = Wc, Pc.widgetMap = {
    [Pc.name]: Pc,
    [Wc.name]: Wc
};

var jc = e({
    name: "QRCodePopover",
    props: {
        isShowThumbnailImg: {
            type: Boolean,
            default: !1
        },
        content: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        popoverConfig: {
            type: Object,
            default: () => ({})
        },
        qrConfig: {
            type: Object,
            default: () => ({})
        },
        contentWidth: {
            type: Number,
            default: 290
        },
        contentHeight: {
            type: Number,
            default: 250
        },
        referenceQrCodeSize: {
            type: Number,
            default: 100
        },
        contentQrCodeSize: {
            type: Number,
            default: 160
        },
        isShowDownloadButton: {
            type: Boolean,
            default: !0
        },
        isShowIdentifyButton: {
            type: Boolean,
            default: !0
        },
        isShowCopyButton: {
            type: Boolean,
            default: !0
        }
    },
    components: {
        QRCode: np
    },
    setup(e, a) {
        const t = i(!1), l = i(null), o = i(null), n = s((() => Ja.isEmpty(null == e ? void 0 : e.content) ? "暂无内容" : String(null == e ? void 0 : e.content))), r = () => {
            t.value = !0;
        }, u = () => {
            t.value = !1;
        }, d = () => {
            var a;
            const t = `${Ja.isEmpty(e.name) ? "二维码" : String(e.name)}.png`;
            null === (a = o.value) || void 0 === a || a.downloadImage(t, 600);
        }, p = () => {
            var e;
            e = n.value, new RegExp(/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/).test(e) ? window.open(n.value) : Dt.alert(n.value, "二维码内容", {
                customClass: "base-qr-code-message",
                confirmButtonText: "关闭"
            }).catch((() => Promise.reject()));
        }, m = () => {
            $a(n.value);
        }, c = e => {
            t.value = !0, e.stopPropagation();
        }, v = e => {
            t.value = !0, e.stopPropagation();
        }, g = e => {
            const a = e => {
                var a, o, i, n;
                const r = (null == e ? void 0 : e.path) || (null === (a = event) || void 0 === a ? void 0 : a.path) || (null === (o = event) || void 0 === o ? void 0 : o.composedPath) && (null === (i = event) || void 0 === i || null === (n = i.composedPath) || void 0 === n ? void 0 : n.call(i));
                Ja.isArray(r) && r.includes(l.value) || (t.value = !1);
            };
            "add" === e && (document.addEventListener("mousedown", a), window.addEventListener("resize", a)), 
            "remove" === e && (document.removeEventListener("mousedown", a), window.removeEventListener("resize", a));
        };
        w((() => {
            g("add");
        })), B((() => {
            g("remove");
        }));
        const b = a => ({
            ...e.qrConfig,
            width: a
        });
        return a.expose({
            open: r,
            close: u,
            download: d,
            identify: p
        }), () => {
            const a = {
                "--base-qr-code-content-height": `${e.contentHeight}px`,
                "--base-qr-code-reference-size": `${e.referenceQrCodeSize}px`,
                "--base-qr-code-content-size": `${e.contentQrCodeSize}px`
            };
            return f(V("el-popover"), E({
                visible: t.value,
                "onUpdate:visible": e => t.value = e,
                "popper-class": "base-qr-code-popover",
                placement: "right-start",
                trigger: "manual",
                width: String(e.width),
                style: a
            }, e.popoverConfig), {
                reference: () => f("div", {
                    class: [ "base-qr-code-content", e.isShowThumbnailImg && "is-show-thumbnail-img" ],
                    style: a,
                    onClick: v
                }, [ e.isShowThumbnailImg ? f(V("syc-icon"), {
                    type: "designer/qrcode",
                    width: 16,
                    height: 16,
                    color: "#1B7DFA"
                }, null) : f(np, {
                    class: "base-qr-code-popover-component",
                    text: n.value,
                    options: {
                        ...b(e.referenceQrCodeSize)
                    }
                }, null) ]),
                default: () => f("div", {
                    ref: l,
                    class: "base-qr-code-popover-content",
                    onClick: c,
                    style: a
                }, [ f("div", {
                    class: "base-qr-code-wrap-close"
                }, null), f("div", {
                    class: "base-qr-code-wrap-body"
                }, [ f(np, {
                    ref: o,
                    class: "base-qr-code-popover-component",
                    text: n.value,
                    options: {
                        ...b(e.contentQrCodeSize)
                    }
                }, null) ]), f("div", {
                    class: "base-qr-code-wrap-footer"
                }, [ f("slot", E({
                    name: "footer"
                }, {
                    download: d,
                    open: r,
                    close: u,
                    identify: p
                }), [ f("div", {
                    class: [ "base-qr-code-wrap-footer-wrap", e.isShowDownloadButton && e.isShowIdentifyButton && "show-line" ]
                }, [ e.isShowDownloadButton && f("div", {
                    class: [ "base-qr-code-wrap-footer-item", e.isShowDownloadButton && e.isShowIdentifyButton && "show-line" ],
                    onClick: d
                }, [ D("下载二维码") ]), e.isShowIdentifyButton && f("div", {
                    class: [ "base-qr-code-wrap-footer-item", e.isShowDownloadButton && e.isShowIdentifyButton && "show-line" ],
                    onClick: p
                }, [ D("识别二维码") ]), e.isShowCopyButton && f("div", {
                    class: "base-qr-code-wrap-footer-item",
                    onClick: m
                }, [ D("复制链接") ]) ]) ]) ]) ])
            });
        };
    }
});

const {formComponentMixin: $c} = z;

var zc = e({
    name: "OlQRCodeContainer",
    props: {
        isShowThumbnailImg: {
            type: Boolean,
            default: !1
        },
        content: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        QRCodePopover: jc
    },
    mixins: [ $c ],
    setup: e => () => f(jc, {
        isShowThumbnailImg: e.isShowThumbnailImg,
        content: e.content,
        name: e.name
    }, null)
});

const Gc = "暂无内容", {CURRENT_RECORD_ID: Hc} = ua, Qc = (e, a) => s((() => {
    const {variables: t} = e, {content: l} = a;
    return Ja.isEmpty(t) || Ja.isEmpty(l) || !t[Hc] ? Gc : String(l).replaceAll(`{{${Hc}}}`, t[Hc]);
})), {formComponentMixin: Yc} = z;

var Kc = e({
    name: "OlQRCode",
    components: {
        QRCodeContainer: zc
    },
    mixins: [ Yc ],
    setup(e, {attrs: a}) {
        const t = s((() => (null == e ? void 0 : e.runtimeEnv) === Q.DESIGNER)), l = s((() => (null == e ? void 0 : e.runtimeEnv) === Q.CREATE)), o = s((() => (null == e ? void 0 : e.runtimeEnv) === Q.EDIT)), i = s((() => o.value ? Qc(e, a).value : Ja.isEmpty(null == a ? void 0 : a.content) ? Gc : String(a.content))), n = s((() => ({
            ...e,
            ...a
        }))), r = s((() => ({
            "is-disabled": e.disabled,
            "is-edit": o.value,
            "is-create": l.value,
            "is-designer": t.value
        })));
        return () => f("div", {
            class: [ "ol-qr-code", {
                ...r.value
            } ]
        }, [ (t.value || l.value) && f(V("syc-icon"), {
            type: "designer/qrcode",
            width: 16,
            height: 16,
            color: "#1B7DFA"
        }, null), o.value && f(zc, E(n, {
            content: i.value
        }), null) ]);
    }
}), Xc = e({
    name: "OlQRCodeMobile",
    setup: () => () => f("div", {
        class: "ol-qr-code-mobile"
    }, [ f(V("syc-icon"), {
        type: "designer/qrcode",
        width: 16,
        height: 16,
        color: "#1B7DFA"
    }, null) ])
});

const {propsFormMixin: Jc} = z;

var Zc = e({
    name: "OlQRCodePropsForm",
    mixins: [ Jc ],
    setup(e, {expose: a}) {
        const {data: t, formRef: l} = fa(e, a), {convertOptionListToObj: o, convertObjToOptionList: n} = H, r = s((() => i(n({
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: l,
            model: t,
            "label-position": "top",
            class: "ol-qr-code-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("q-r-code-content-setter"), {
                metaData: e.metaData
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: r.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = o(a, G.options, !0), {visible: l, visibleWhenCreate: i} = t;
                    e.metaData.visible = l, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: ev, metaDataMixin: av} = z;

var tv = e({
    name: "OlQRCodeTableFormatter",
    components: {
        QRCodeContainer: zc
    },
    mixins: [ ev, av ],
    setup(e, {attrs: a}) {
        const t = s((() => ({
            ...e,
            ...a
        }))), l = s((() => (null == e ? void 0 : e.runtimeEnv) === Q.TABLE)), o = s((() => (null == e ? void 0 : e.runtimeEnv) === Q.DETAIL)), i = s((() => ({
            "is-table-row": l.value,
            "is-table-detail": o.value
        }))), n = s((() => l.value ? ((e, a) => s((() => {
            const {formData: t} = e, {content: l} = a;
            return Ja.isEmpty(t) || Ja.isEmpty(l) || !t.id ? Gc : String(l).replaceAll(`{{${Hc}}}`, t.id);
        })))(e, a).value : o.value ? Qc(e, a).value : Gc)), r = s((() => {
            var e;
            return null !== (e = null == a ? void 0 : a.label) && void 0 !== e ? e : "";
        }));
        return () => f("label", {
            class: [ "ol-qr-code-table-formatter", {
                ...i.value
            } ]
        }, [ f(zc, E(t, {
            "is-show-thumbnail-img": l.value,
            content: n.value,
            name: r.value
        }), null) ]);
    }
}), lv = {
    code: K.QRCode,
    name: "二维码",
    group: X.HIGH_LEVEL,
    icon: "qrcode",
    widgetType: J.FORM,
    dataType: Z.STRING,
    widget: "OlQRCode",
    propsForm: "OlQRCodePropsForm",
    tableFormatter: "OlQRCodeTableFormatter",
    props: {
        content: ""
    },
    defaultValue: "",
    requiredProps: [],
    supportBindVariableProps: [ "content" ],
    supportI18NProps: [ "content" ],
    supportEvents: [],
    conditionConfig: {}
};

Kc.meta = lv, Kc.PropsForm = Zc, Kc.Mobile = Xc, Kc.install = e => {
    e.component(Kc.name, Kc), e.component(Zc.name, Zc), e.component(Xc.name, Xc), e.component(tv.name, tv);
}, Kc.widgetMap = {
    [Xc.name]: Xc,
    [Kc.name]: Kc,
    [Zc.name]: Zc,
    [tv.name]: tv
};

const ov = "left", iv = "right", nv = [ {
    label: "显示在左侧",
    value: ov
}, {
    label: "显示在右侧",
    value: iv
} ];

var rv = {
    code: K.SWITCH,
    name: "开关",
    group: X.NORMAL,
    icon: "kaiguan",
    widgetType: J.FORM,
    dataType: Z.BOOLEAN,
    widget: "OlSwitch",
    propsForm: "OlSwitchPropsForm",
    tableFormatter: "OlSwitchTableFormatter",
    props: {
        trueLabel: "是",
        falseLabel: "否",
        truePlaceholder: "",
        falsePlaceholder: "",
        showPosition: iv,
        onlyTrueStatus: !1
    },
    defaultValue: !1,
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.EQUAL,
        supportWidgetCodeConfig: [ K.SWITCH ],
        supportOperator: {
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL]
        }
    }
};

const {formComponentMixin: sv} = z;

var uv = e({
    name: "OlSwitch",
    props: {
        modelValue: {
            type: Boolean,
            default: !1
        },
        truePlaceholder: {
            type: String,
            default: ""
        },
        falsePlaceholder: {
            type: String,
            default: ""
        },
        showPosition: {
            type: String,
            default: rv.props.showPosition
        }
    },
    mixins: [ sv ],
    emits: [ "update:modelValue" ],
    setup(e, {emit: a, attrs: t}) {
        const l = s({
            get: () => Ja.isNil(e.modelValue) ? e.metaData.defaultValue : e.modelValue,
            set: e => {
                a("update:modelValue", e);
            }
        }), i = s((() => ({
            ...e,
            ...t
        })));
        return () => {
            const {truePlaceholder: a, falsePlaceholder: t, showPosition: n} = e, r = n === ov, s = l.value && a || !l.value && t, u = f(V("el-switch"), E(i.value, {
                class: "ol-switch-component",
                modelValue: l.value,
                "onUpdate:modelValue": e => l.value = e
            }), null), d = o(f("div", {
                class: [ "ol-switch-tip", r && "is-left" ]
            }, [ l.value ? a : t ]), [ [ g, s ] ]);
            return f("div", {
                class: "ol-switch"
            }, [ f(R, null, r ? [ f(d, null, null), f(u, null, null) ] : [ f(u, null, null), f(d, null, null) ]) ]);
        };
    }
});

const {formComponentMixin: dv} = z, pv = e({
    name: "OlSwitchMobile",
    mixins: [ dv ],
    setup: () => () => f("div", null, [ D("开关移动端") ])
}), {setterMixin: mv} = z, cv = e({
    name: "PlaceholderSetter",
    mixins: [ mv ],
    setup(e) {
        const a = Da(e.metaData, "truePlaceholder"), t = Da(e.metaData, "falsePlaceholder"), l = Da(e.metaData, "showPosition");
        return () => f(V("CustomFormItem"), {
            class: "placeholder-setter",
            setter: !0,
            label: "提示文本"
        }, {
            default: () => [ f("div", {
                class: "setter-item"
            }, [ f("div", {
                class: "title"
            }, [ D("开启时") ]), f(V("ssu-input-text"), {
                modelValue: a.value,
                "onUpdate:modelValue": e => a.value = e,
                placeholder: "请输入"
            }, null) ]), f("div", {
                class: "setter-item"
            }, [ f("div", {
                class: "title"
            }, [ D("关闭时") ]), f(V("ssu-input-text"), {
                modelValue: t.value,
                "onUpdate:modelValue": e => t.value = e,
                placeholder: "请输入"
            }, null) ]), f("div", {
                class: "setter-item"
            }, [ f("div", {
                class: "title"
            }, [ D("显示位置") ]), f(V("ssu-select"), {
                modelValue: l.value,
                "onUpdate:modelValue": e => l.value = e,
                label: "请选择",
                optionData: nv
            }, null) ]) ]
        });
    }
}), {setterMixin: vv} = z, fv = e({
    name: "LabelSetter",
    mixins: [ vv ],
    setup(e) {
        const {error: a} = ca(), t = Da(e.metaData, "trueLabel"), l = Da(e.metaData, "falseLabel"), o = (e, o) => {
            e || (a(o ? "开启时的值不能为空" : "关闭时的值不能为空"), o ? t.value = rv.props.trueLabel : l.value = rv.props.falseLabel);
        };
        return () => f(V("CustomFormItem"), {
            class: "label-setter",
            setter: !0,
            label: "值设置"
        }, {
            default: () => [ f("div", {
                class: "setter-item"
            }, [ f("div", {
                class: "title"
            }, [ D("开启时的值") ]), f(V("ssu-input-text"), {
                modelValue: t.value,
                "onUpdate:modelValue": e => t.value = e,
                label: "请输入",
                onChange: e => o(e, !0)
            }, null) ]), f("div", {
                class: "setter-item"
            }, [ f("div", {
                class: "title"
            }, [ D("关闭时的值") ]), f(V("ssu-input-text"), {
                modelValue: l.value,
                "onUpdate:modelValue": e => l.value = e,
                label: "请输入",
                onChange: e => o(e, !1)
            }, null) ]) ]
        });
    }
}), {setterMixin: gv} = z, bv = e({
    name: "ValidateRuleSetter",
    mixins: [ gv ],
    setup(e) {
        const a = Da(e.metaData, "onlyTrueStatus");
        return () => f(V("CustomFormItem"), {
            class: "validate-rule-setter",
            setter: !0,
            label: "验证"
        }, {
            default: () => [ f(V("ssu-checkbox"), {
                modelValue: a.value,
                "onUpdate:modelValue": e => a.value = e
            }, {
                default: () => [ D("开启状态唯一") ]
            }), f(V("el-tooltip"), {
                content: '勾选后，表单仅允许一条数据设置为"开启"'
            }, {
                default: () => [ f("span", {
                    class: "tip"
                }, [ f(V("syc-icon"), {
                    type: "designer/bangzhuzhongxin"
                }, null) ]) ]
            }) ]
        });
    }
}), {propsFormMixin: hv} = z;

var yv = e({
    name: "OlSwitchPropsForm",
    mixins: [ hv ],
    setup(e, {expose: a}) {
        const {data: t, formRef: l} = fa(e, a), {convertOptionListToObj: n, convertObjToOptionList: r} = H, u = s((() => i(r({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: l,
            model: t,
            "label-position": "top",
            class: "ol-switch-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(cv, {
                metaData: e.metaData
            }, null), f(fv, {
                metaData: e.metaData
            }, null), f(V("default-value-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                pageData: e.pageData,
                appData: e.appData
            }, null), o(f(bv, {
                metaData: e.metaData
            }, null), [ [ g, !e.inSubForm ] ]), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: u.value,
                "onUpdate:statusConfigList": (a = []) => {
                    const t = n(a, G.options, !0), {editable: l, visible: o, visibleWhenCreate: i} = t;
                    e.metaData.editable = l, e.metaData.visible = o, e.metaData.visibleWhenCreate = i;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Dv, metaDataMixin: Cv} = z;

var wv = e({
    props: {
        fieldValue: {
            type: Boolean
        },
        trueLabel: {
            type: String,
            default: ""
        },
        falseLabel: {
            type: String,
            default: ""
        }
    },
    name: "OlSwitchTableFormatter",
    mixins: [ Dv, Cv ],
    setup(e, a) {
        const t = s((() => Ja.isBoolean(e.fieldValue) ? e.fieldValue ? e.trueLabel : e.falseLabel : Y));
        return () => f("div", {
            class: "ol-switch-table-formatter"
        }, [ f(V("el-switch"), {
            disabled: !0,
            class: "ol-switch-table-formatter-switch",
            "model-value": e.fieldValue
        }, null), f("label", {
            class: "ol-switch-table-formatter-label"
        }, [ t.value ]) ]);
    }
});

uv.meta = rv, uv.PropsForm = yv, uv.Mobile = pv, uv.install = e => {
    e.component(uv.name, uv), e.component(yv.name, yv), e.component(pv.name, pv), e.component(wv.name, wv);
}, uv.widgetMap = {
    [pv.name]: pv,
    [uv.name]: uv,
    [yv.name]: yv,
    [wv.name]: wv
};

const {formComponentMixin: Sv} = z, Lv = /subFormField\.\S[^\\.{}]+\./g, Tv = /relationFormField\.\S[^\\.{}]+\./g, Vv = /field\.\S/g;

var Ev = e({
    name: "OlFormula",
    mixins: [ Sv ],
    setup(e, {attrs: a, emit: t}) {
        const l = s((() => ({
            ...e,
            ...a,
            step: 1 / Math.pow(10, u.value)
        }))), o = s((() => a.expression)), n = s((() => a.returnType)), r = s((() => a.customUnit)), u = s((() => a.decimalCount || 0)), d = s((() => a.numberFormatType)), p = s((() => a.numberFormulaToZero)), m = s((() => a.priceUnit)), c = s((() => a.showThousands)), v = s((() => a.dateFormatType)), g = s((() => {
            var e;
            return (null === (e = o.value) || void 0 === e ? void 0 : e.match(Vv)) || [];
        })), b = s((() => {
            var e, a;
            return (null === (e = o.value) || void 0 === e || null === (a = e.match(Lv)) || void 0 === a ? void 0 : a.map((e => {
                var a;
                return null === (a = e.split(".")) || void 0 === a ? void 0 : a[1];
            }))) || [];
        })), h = s((() => {
            var e, a;
            return (null === (e = o.value) || void 0 === e || null === (a = e.match(Tv)) || void 0 === a ? void 0 : a.map((e => {
                var a;
                return null === (a = e.split(".")) || void 0 === a ? void 0 : a[1];
            }))) || [];
        })), y = s((() => {
            const a = {};
            return b.value.forEach((t => {
                a[t] = za(e.formData[t]);
            })), a;
        })), D = s((() => {
            const a = {};
            return h.value.forEach((t => {
                a[t] = za(e.formData[`${t}${je}`] || e.formData[t]);
            })), a;
        })), C = s((() => ({
            field: e.formData,
            subFormField: y.value,
            relationFormField: D.value
        }))), w = i(null), S = s((() => {
            var e, a, t;
            if (!(g.value.length || b.value.length | h.value.length || Ja.isNil(w.value))) return w.value;
            const l = da.evaluate(o.value, C.value, {
                evalMode: !1,
                escapeEmptyValue: !p.value
            });
            let i;
            switch (n.value) {
              case "number":
                i = Ja.isNumber(l) && !isNaN(l) ? l : void 0;
                break;

              case "date":
                i = isNaN(l) ? void 0 : null !== (e = (a = dt(l)).isValid) && void 0 !== e && e.call(a) ? dt(l).valueOf() : void 0;
                break;

              default:
                i = null == l || null === (t = l.toString) || void 0 === t ? void 0 : t.call(l);
            }
            return i;
        })), L = s((() => ({
            [we.PERCENT_UNIT.value]: "%",
            [we.PRICE_UNIT.value]: m.value,
            [we.CUSTOM_UNIT.value]: r.value
        }[d.value]))), T = s((() => ({
            [we.DEFAULT.value]: e => e,
            [we.PERCENT_UNIT.value]: e => e + "%",
            [we.PRICE_UNIT.value]: e => m.value + e,
            [we.CUSTOM_UNIT.value]: e => e + r.value
        }))), I = s((() => e.runtimeEnv === Q.EDIT || e.runtimeEnv === Q.CREATE || e.runtimeEnv === Q.PROCESS_AUDIT)), F = s((() => e.runtimeEnv === Q.TABLE)), O = s((() => {
            var a, t;
            let l = "";
            l = I.value ? S.value : e.fieldValue;
            let o = Y;
            switch (n.value) {
              case "number":
                o = N(l, !F.value);
                break;

              case "date":
                o = l && M(l) || l;
                break;

              default:
                o = null === (a = l) || void 0 === a || null === (t = a.toString) || void 0 === t ? void 0 : t.call(a);
            }
            return Ja.isNil(o) ? Y : o;
        })), N = (e, a) => {
            let t = "";
            if (!Ja.isNil(e)) {
                var l, o;
                if (t = Ja.floor(e, u.value).toLocaleString("zh-CN", {
                    useGrouping: c.value,
                    minimumFractionDigits: u.value
                }), !a) t = (null === (l = (o = T.value)[d.value]) || void 0 === l ? void 0 : l.call(o, t)) || t;
                return t;
            }
            return t;
        }, M = e => {
            var a, t;
            return null !== (a = dt(e)) && void 0 !== a && null !== (t = a.isValid) && void 0 !== t && t.call(a) ? dt(e).format(v.value) : void 0;
        };
        return e.runtimeEnv === Q.TABLE ? () => f("span", {
            class: [ "ol-formula" ]
        }, [ O.value ]) : (P((() => S.value), Ja.debounce(((e, a) => {
            I.value && e !== a && (w.value = e, t("update:modelValue", e));
        }), 100), {
            immediate: !0
        }), () => f("span", {
            class: "ol-formula"
        }, [ f(V("ssu-input-text"), E(l.value, {
            modelValue: O.value,
            "onUpdate:modelValue": e => {
                S.value = e;
            },
            class: [ "priceUnit" === d.value && "padding-unit-left", (r.value || "percentUnit" === d.value) && "padding-unit-right" ],
            disabled: !0
        }), null), f("span", {
            class: [ "ol-number-unit", "priceUnit" === d.value && "left-unit", (r.value || "percentUnit" === d.value) && "right-unit" ]
        }, [ L.value ]) ]));
    }
});

const {propsFormMixin: Iv, setterMixin: Fv} = z;

var Ov = e({
    name: "OlFormulaPropsForm",
    mixins: [ Iv, Fv ],
    setup(e, {expose: a}) {
        const {data: t, formRef: l} = fa(e, a), {convertOptionListToObj: o, convertObjToOptionList: n} = H, r = s((() => {
            var a;
            return (null === (a = e.metaData.props.returnType) || void 0 === a ? void 0 : a.rawValue) || "";
        })), u = s((() => "number" === r.value)), d = s({
            get() {
                var a;
                return null === (a = e.metaData.props.numberFormulaToZero) || void 0 === a ? void 0 : a.rawValue;
            },
            set(a) {
                e.metaData.props.numberFormulaToZero.rawValue = a;
            }
        }), p = s((() => "date" === r.value)), m = s((() => {
            var a, t;
            const l = i(n({
                showThousands: null === (a = e.metaData.props.showThousands) || void 0 === a ? void 0 : a.rawValue,
                showDecimal: null === (t = e.metaData.props.showDecimal) || void 0 === t ? void 0 : t.rawValue
            }));
            return i(l.value.map((a => {
                var t;
                return i({
                    ...a,
                    params: "showDecimal" === a.key && {
                        ...a.params,
                        decimalCount: null === (t = e.metaData.props.decimalCount) || void 0 === t ? void 0 : t.rawValue
                    } || void 0
                }).value;
            }))).value;
        })), c = s((() => i(Ce.options.map((a => {
            var t;
            return {
                ...a,
                params: {
                    ...a.params,
                    decimalCount: "showDecimal" === a.key && (null === (t = e.metaData.props.decimalCount) || void 0 === t ? void 0 : t.rawValue) || void 0
                }
            };
        }))).value)), v = s((() => i(n({
            editable: e.metaData.editable,
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return P((() => r.value), (a => {
            e.metaData.dataType = pa[a];
        }), {
            immediate: !0
        }), () => f(V("el-form"), {
            ref: l,
            model: t,
            "label-position": "top",
            class: "ol-formula-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("formula-expression-setter"), e, null), f(V("return-type-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                returnTypeOptions: ma,
                disabled: e.metaData.saved
            }, {
                default: () => u.value && f(V("el-checkbox"), {
                    modelValue: d.value,
                    "onUpdate:modelValue": e => d.value = e,
                    style: {
                        fontWeight: 400
                    }
                }, {
                    default: () => [ D("参与计算的字段为空时，默认为0") ]
                }) || void 0
            }), u.value && f(V("number-format-setter"), {
                metaData: e.metaData,
                formatTypeKey: "numberFormatType",
                numberFormatOptions: c.value,
                numberFormatConfigList: m.value,
                "onUpdate:numberFormatConfigList": (a = []) => {
                    const t = o(a), {showDecimal: l, showThousands: i} = t;
                    let n;
                    var r, s;
                    l && (n = null === (r = m.value.find((e => "showDecimal" === e.key))) || void 0 === r || null === (s = r.params) || void 0 === s ? void 0 : s.decimalCount);
                    e.metaData.props.showDecimal.rawValue = l, e.metaData.props.showThousands.rawValue = i, 
                    e.metaData.props.decimalCount.rawValue = n;
                }
            }, null), p.value && f(V("date-format-setter"), {
                metaData: e.metaData,
                formatTypeKey: "dateFormatType"
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: v.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const t = o(a, G.options.filter((e => "editable" !== e.key)), !0), {editable: l, visible: i, visibleWhenCreate: n} = t;
                    e.metaData.editable = l, e.metaData.visible = i, e.metaData.visibleWhenCreate = n;
                }
            }, null) ]
        });
    }
}), Nv = {
    code: K.FORMULA,
    name: "公式",
    group: X.HIGH_LEVEL,
    icon: "bianhao1",
    widgetType: J.FORM,
    widget: "OlFormula",
    propsForm: "OlFormulaPropsForm",
    props: {
        placeholder: "系统自动生成，无需填写",
        expression: "",
        returnType: "number",
        numberFormatType: we.DEFAULT.value,
        numberFormulaToZero: !0,
        priceUnit: "￥",
        customUnit: "",
        showThousands: !1,
        showDecimal: !1,
        decimalCount: void 0,
        dateFormatType: se.options[0].value
    },
    defaultValue: void 0,
    conditionConfig: ({widget: e, libraryManager: a}) => {
        const t = Ja.get(e, "props.returnType.rawValue", "number"), l = a.getMeta("official", K.NUMBER), o = a.getMeta("official", K.DATETIME), i = a.getMeta("official", K.SINGLE_TEXT);
        switch (t) {
          case "date":
            return o.conditionConfig;

          case "text":
            return i.conditionConfig;

          default:
            return l.conditionConfig;
        }
    }
};

Ev.meta = Nv, Ev.PropsForm = Ov, Ev.install = e => {
    e.component(Ev.name, Ev), e.component(Ov.name, Ov);
}, Ev.widgetMap = {
    [Ev.name]: Ev,
    [Ov.name]: Ov
};

const Mv = "container", Rv = "form", xv = "chart", Av = "other", kv = "display", Pv = "SingleText", _v = "Datetime", Uv = "Group", Bv = "SubForm", Wv = xa([ {
    value: Pv,
    label: "单行文本"
}, {
    value: "MultipleText",
    label: "多行文本"
}, {
    value: "Number",
    label: "数字"
}, {
    value: "SingleSelect",
    label: "下拉单选"
}, {
    value: "MultipleSelect",
    label: "下拉多选"
}, {
    value: "Radio",
    label: "单选"
}, {
    value: "Checkbox",
    label: "多选"
}, {
    value: "Rate",
    label: "评分"
}, {
    value: _v,
    label: "日期时间"
}, {
    value: "Grid",
    label: "布局容器"
}, {
    value: Uv,
    label: "分组"
}, {
    value: "Member",
    label: "成员"
}, {
    value: "Sequence",
    label: "自动编号"
}, {
    value: "Image",
    label: "图片"
}, {
    value: "Department",
    label: "部门"
}, {
    value: "Cascader",
    label: "级联选择"
}, {
    value: "Attachment",
    label: "附件"
}, {
    value: Bv,
    label: "子表"
}, {
    value: "Address",
    label: "地图选址"
}, {
    value: "RelationForm",
    label: "关联表单"
}, {
    value: "RelationFormField",
    label: "关联表字段"
}, {
    value: "RelationFormQuery",
    label: "关联查询"
}, {
    value: "Tab",
    label: "选项卡"
}, {
    value: "REMARK",
    label: "备注"
}, {
    value: "QRCode",
    label: "二维码"
}, {
    value: "Switch",
    label: "开关"
} ]);

xa([ {
    label: "常用组件",
    value: "normal"
}, {
    label: "高级字段",
    value: "highLevel"
}, {
    label: "业务组件",
    value: "business"
} ]), xa([ {
    label: "容器组件",
    value: Mv
}, {
    label: "表单组件",
    value: Rv
}, {
    label: "图标组件",
    value: xv
}, {
    label: "其他组件",
    value: Av
} ]);

xa([ {
    label: "普通表单",
    value: "form_page",
    name: "表单名称"
}, {
    label: "流程表单",
    value: "workflow",
    name: "表单名称"
}, {
    label: "仪表盘",
    value: "dashboard",
    name: "仪表盘名称"
}, {
    label: "自定义页面",
    value: "custom",
    name: "页面名称"
}, {
    label: "分组",
    value: "group",
    name: "分组名称"
} ]), xa([ {
    key: "editable",
    label: "只读"
}, {
    key: "visible",
    label: "隐藏"
}, {
    key: "visibleWhenCreate",
    label: "新增时隐藏"
} ]);

const qv = {
    label: "按百分比显示",
    value: "percentUnit"
}, jv = {
    label: "显示为金额",
    value: "priceUnit"
}, $v = {
    label: "显示单位",
    value: "customUnit"
}, zv = {
    label: "默认",
    value: "default"
};

xa([ {
    value: zv.value,
    label: zv.label
}, {
    value: qv.value,
    label: qv.label
}, {
    value: jv.value,
    label: jv.label
}, {
    value: $v.value,
    label: $v.label
} ]);

const Gv = {
    value: "￥",
    label: "￥人民币"
}, Hv = {
    value: "$",
    label: "$美元"
}, Qv = {
    value: "€",
    label: "€欧元"
}, Yv = {
    value: "£",
    label: "£英镑"
}, Kv = {
    value: "HK$",
    label: "HK$港元"
};

xa([ {
    value: Gv.value,
    label: Gv.label
}, {
    value: Hv.value,
    label: Hv.label
}, {
    value: Qv.value,
    label: Qv.label
}, {
    value: Yv.value,
    label: Yv.label
}, {
    value: Kv.value,
    label: Kv.label
} ]), xa([ {
    key: "showThousands",
    label: "显示千分位"
}, {
    key: "showDecimal",
    label: "显示小数",
    params: {
        decimalCount: void 0
    },
    component: (e, a) => (a.decimalCount = a.decimalCount || 1, f("div", {
        style: {
            "padding-left": "26px"
        }
    }, [ f("label", {
        style: {
            marginRight: "4px"
        }
    }, [ D("保留小数位") ]), f(V("ssu-input-number"), {
        modelValue: a.decimalCount,
        "onUpdate:modelValue": e => a.decimalCount = e,
        min: 1,
        max: 10,
        "max-length": 10
    }, null) ]))
} ]);

const Xv = {
    label: "最大值",
    value: "max"
}, Jv = {
    label: "最小值",
    value: "min"
}, Zv = {
    label: "求和",
    value: "sum"
}, ef = {
    label: "平均值",
    value: "avg"
};

xa([ {
    label: Xv.label,
    value: Xv.value
}, {
    label: Jv.label,
    value: Jv.value
}, {
    label: Zv.label,
    value: Zv.value
}, {
    label: ef.label,
    value: ef.value
} ]), xa([ {
    label: "横向排列",
    value: "horizontal"
}, {
    label: "纵向排列",
    value: "vertical"
} ]), xa([ {
    label: "5分",
    value: 5
}, {
    label: "10分",
    value: 10
} ]), xa([ {
    label: "星级",
    value: "default"
}, {
    label: "量表",
    value: "table"
} ]);

const af = "single", tf = "multiple";

xa([ {
    label: "单条",
    value: af
}, {
    label: "多条",
    value: tf
} ]), xa([ {
    label: "单个",
    value: af
}, {
    label: "多个",
    value: tf
} ]), xa([ {
    label: "不限",
    value: "free"
}, {
    label: "指定",
    value: "limit"
} ]), xa([ {
    label: "单选",
    value: "single"
}, {
    label: "多选",
    value: "multiple"
} ]), xa([ {
    label: "平铺",
    value: "tile"
}, {
    label: "进度",
    value: "step"
} ]), xa([ {
    label: "年",
    type: "year",
    value: "YYYY"
}, {
    label: "年-月",
    type: "month",
    value: "YYYY-MM"
}, {
    label: "年-月-日",
    type: "date",
    value: "YYYY-MM-DD"
}, {
    label: "年-月-日 时",
    type: "datetime",
    value: "YYYY-MM-DD HH"
}, {
    label: "年-月-日 时:分",
    type: "datetime",
    value: "YYYY-MM-DD HH:mm"
}, {
    label: "年-月-日 时:分:秒",
    type: "datetime",
    value: "YYYY-MM-DD HH:mm:ss"
} ]), xa([ {
    label: "年月日时分秒",
    value: "yyyyMMddHHmmss"
}, {
    label: "年月日时分",
    value: "yyyyMMddHHmm"
}, {
    label: "年月日时",
    value: "yyyyMMddHH"
}, {
    label: "年月日",
    value: "yyyyMMdd"
}, {
    label: "年月",
    value: "yyyyMM"
}, {
    label: "年",
    value: "yyyy"
} ]), xa([ {
    label: "不自动重置",
    type: "",
    value: "0"
}, {
    label: "每日重置",
    type: "day",
    value: "1"
}, {
    label: "每周重置",
    type: "week",
    value: "2"
}, {
    label: "每月重置",
    type: "month",
    value: "3"
}, {
    label: "每年重置",
    type: "year",
    value: "4"
} ]), xa([ {
    label: "默认格式",
    value: "0"
}, {
    label: "自定义格式",
    value: "1"
} ]);

const lf = {
    EQUAL: "equal",
    NOT_EQUAL: "not_equal",
    GREATER: "greater",
    GREATER_OR_EQUAL: "greater_or_equal",
    LESS: "less",
    LESS_OR_EQUAL: "less_or_equal",
    BETWEEN: "between",
    NOT_BETWEEN: "not_between",
    BEGINS_WITH: "begins_with",
    NOT_BEGINS_WITH: "not_begins_with",
    CONTAINS: "contains",
    NOT_CONTAINS: "not_contains",
    ENDS_WITH: "ends_with",
    NOT_ENDS_WITH: "not_ends_with",
    IS_NULL: "is_null",
    IS_NOT_NULL: "is_not_null",
    IS_EMPTY: "is_empty",
    IS_NOT_EMPTY: "is_not_empty",
    IN: "in",
    NOT_IN: "not_in"
};

xa([ {
    value: lf.EQUAL,
    label: "="
}, {
    value: lf.BETWEEN,
    label: "区间内"
}, {
    value: lf.NOT_BETWEEN,
    label: "区间外"
}, {
    value: lf.DYNAMIC,
    label: "动态"
}, {
    value: lf.NOT_EQUAL,
    label: "≠"
}, {
    value: lf.GREATER,
    label: ">"
}, {
    value: lf.GREATER_OR_EQUAL,
    label: "≥"
}, {
    value: lf.LESS,
    label: "＜"
}, {
    value: lf.LESS_OR_EQUAL,
    label: "≤"
}, {
    value: lf.BEGINS_WITH,
    label: "以...开头"
}, {
    value: lf.NOT_BEGINS_WITH,
    label: "不以...开头"
}, {
    value: lf.CONTAINS,
    label: "包含"
}, {
    value: lf.NOT_CONTAINS,
    label: "不包含"
}, {
    value: lf.ENDS_WITH,
    label: "以...结束"
}, {
    value: lf.NOT_ENDS_WITH,
    label: "不以...结束"
}, {
    value: lf.IS_NULL,
    label: "为空"
}, {
    value: lf.IS_NOT_NULL,
    label: "不为空"
}, {
    value: lf.IS_EMPTY,
    label: "为空"
}, {
    value: lf.IS_NOT_EMPTY,
    label: "不为空"
}, {
    value: lf.IN,
    label: "属于"
}, {
    value: lf.NOT_IN,
    label: "不属于"
} ]);

const of = "month_before", nf = "week_before", rf = "day_before", sf = "current_year", uf = "current_month", df = "current_week", pf = "current_day", mf = "month_after", cf = "week_after", vf = "day_after";

xa([ {
    label: "月前",
    value: of,
    showInput: !0
}, {
    label: "周前",
    value: nf,
    showInput: !0
}, {
    label: "天前",
    value: rf,
    showInput: !0
}, {
    label: "当年",
    value: sf,
    showInput: !1
}, {
    label: "当月",
    value: uf,
    showInput: !1
}, {
    label: "当周",
    value: df,
    showInput: !1
}, {
    label: "当天",
    value: pf,
    showInput: !1
}, {
    label: "月后",
    value: mf,
    showInput: !0
}, {
    label: "周后",
    value: cf,
    showInput: !0
}, {
    label: "天后",
    value: vf,
    showInput: !0
} ]);

const ff = "current_user";

xa([ {
    value: ff,
    label: "当前用户"
} ]);

const gf = "current_dept", bf = "current_dept_and_lower_dept";

xa([ {
    value: gf,
    label: "当前用户部门"
}, {
    value: bf,
    label: "当前用户部门及下级部门"
} ]);

const hf = "AND";

xa([ {
    value: hf,
    label: "并且"
}, {
    value: "OR",
    label: "或者"
} ]);

const yf = "fixed", Df = "field", Cf = "dynamic";

xa([ {
    value: yf,
    label: "固定值"
}, {
    value: Df,
    label: "字段值"
}, {
    value: Cf,
    label: "动态值"
}, {
    value: "expression",
    label: "表达式"
} ]), xa([ {
    label: "卡片",
    value: "card"
}, {
    label: "拖拽",
    value: "drag"
} ]), xa([ {
    label: "文档",
    value: "document",
    children: [ "txt", "pdf", "doc", "docx", "xls", "xlsx", "cvs", "xml", "ppt", "pptx", "wps", "htm", "html", "rtf", "hlp" ]
}, {
    label: "音频",
    value: "audio",
    children: [ "mp3", "flac", "ape", "wma", "wav", "aac", "m4a", "au", "ram", "mmf", "aif" ]
}, {
    label: "视频",
    value: "video",
    children: [ "mkv", "mp4", "avi", "swf", "wmv", "rmvb", "mov", " mpg" ]
}, {
    label: "压缩包",
    value: "package",
    children: [ "rar", "zip", "7z", "gz", "arj", "z" ]
}, {
    label: "自定义",
    value: "custom",
    children: []
} ]);

xa([ {
    value: "hidden",
    label: "隐藏",
    priority: 1
}, {
    value: "readonly",
    label: "只读",
    priority: 2
}, {
    value: "editable",
    label: "可编辑",
    priority: 3
}, {
    value: "required",
    label: "必填",
    priority: 4
} ]);

const wf = "list", Sf = "select", Lf = "tile";

xa([ {
    label: "列表",
    value: wf
}, {
    label: "下拉",
    value: Sf
} ]), xa([ {
    label: "列表",
    value: wf
}, {
    label: "下拉",
    value: Sf
}, {
    label: "平铺",
    value: Lf
} ]);

xa([ {
    value: "currentUserId",
    label: "当前用户ID"
}, {
    value: "currentTenantId",
    label: "当前租户ID"
}, {
    value: "currentRecordId",
    label: "当前记录ID"
} ]);

const Tf = "custom", Vf = "optionSet";

xa([ {
    label: "自定义选项",
    value: Tf
}, {
    label: "链接数据源",
    value: "remote"
}, {
    label: "通用选项集",
    value: Vf
} ]), xa([ {
    label: "自定义选项",
    value: Tf
}, {
    label: "通用选项集",
    value: Vf
} ]);

xa([ {
    value: "currentLocation",
    label: "当前位置"
}, {
    value: "fixed",
    label: "固定值"
}, {
    value: "field",
    label: "其他字段值"
}, {
    value: "query",
    label: "查询表单记录"
}, {
    value: "formula",
    label: "公式计算"
} ]);

const Ef = {
    createTime: "create_time",
    updateTime: "update_time",
    currentTime: "current_time",
    fixedTime: "fixed_time"
};

xa([ {
    label: "当前日期",
    value: Ef.currentTime
} ]), xa([ {
    label: "创建时间",
    value: Ef.createTime
}, {
    label: "更新时间",
    value: Ef.updateTime
} ]);

const If = /^[a-z][a-z\d_]*$/g, Ff = /[^a-zA-Z\d_]*/g, Of = e => Ja.isArray(e) && !Ja.isEmpty(e), Nf = (e = "", a) => {
    let t = "";
    return e && a ? (t = a(e, {
        toneType: "none",
        v: !0,
        nonZh: "consecutive"
    }).replace(Ff, ""), t.match(If) || (t = a(e, {
        toneType: "none",
        v: !0,
        nonZh: "removed"
    }).replace(Ff, "")), t.length > 30 && (t = t.slice(0, 30)), t) : "";
};

class Mf {
    constructor(e = [], a = []) {
        xi(this, "list", void 0), xi(this, "systemWidgets", []), this.list = e, this.systemWidgets = a;
    }
    each(e, a) {
        const t = a || this.list, l = (a, t, o) => {
            for (let i = 0; i < a.length; i++) {
                const n = a[i];
                if (_a(e, null, n, i, t, o), n.widgetType === Mv) {
                    const e = n.children;
                    Of(e) && e.forEach((e => {
                        Of(e) && l(e, n, o + 1);
                    }));
                }
            }
        };
        l(t, null, 0);
    }
    flat() {
        const e = [];
        return this.each((a => {
            e.push(a);
        })), e;
    }
    getStatisticalData() {
        const e = this.flat(), a = e.reduce(((e, a) => (e.widget++, a.widgetType === Rv ? e.form++ : a.widgetType === Mv ? e.container++ : a.widgetType === kv ? e.display++ : e.other++, 
        e)), {
            widget: 0,
            form: 0,
            container: 0,
            other: 0,
            display: 0
        }), t = Ja.groupBy(Ja.map(e, (e => Ja.pick(e, "id", "code", "name"))), "code");
        return {
            ...a,
            codeItems: Object.entries(t).map((([e, a]) => ({
                label: Wv.getLabel(e),
                count: a.length,
                list: a
            }))).sort(((e, a) => a.count - e.count))
        };
    }
    cloneEach(e) {
        const a = Ja.cloneDeep(this.list);
        return this.each(e, a), a;
    }
    getFormWidgets(e = {}) {
        const {withSystem: a} = e, t = [], l = a ? [ ...this.list, ...this.systemWidgets ] : this.list;
        return this.each((e => {
            e.widgetType === Rv && t.push(e);
        }), l), t.filter(Boolean);
    }
    getFormWidgetsAndGroupCode(e = {}) {
        const {withSystem: a} = e, t = [], l = a ? [ ...this.list, ...this.systemWidgets ] : this.list;
        return this.each((e => {
            e.widgetType !== Rv && e.code !== Uv || t.push(e);
        }), l), t.filter(Boolean);
    }
    getByFieldName(e, a = {}) {
        return this.getFormWidgets(a).find((a => a.fieldName === e));
    }
    toMap(e = "id") {
        const a = {};
        return this.cloneEach((t => {
            a[t[e]] = t;
        })), a;
    }
    getFormChildren(e = {}) {
        const {withSystem: a} = e, t = a ? [ ...Ja.cloneDeep(this.list), ...this.systemWidgets ] : Ja.cloneDeep(this.list);
        for (let e = 0; e < t.length; e++) {
            const a = t[e];
            var l, o;
            if (a.code == Bv) t.push(...null === (l = a.props) || void 0 === l || null === (o = l.fields) || void 0 === o ? void 0 : o.rawValue);
        }
        return t;
    }
    batchAddFieldName(e) {
        var a;
        const t = /\d+$/, l = Array.isArray(e) ? e : this.getFormWidgets();
        let o = null === (a = require("pinyin-pro")) || void 0 === a ? void 0 : a.pinyin;
        l.forEach((e => {
            var a, t;
            e.fieldName || (e.fieldName = Nf(e.name.rawValue, o)), null !== (a = e.props) && void 0 !== a && null !== (t = a.fields) && void 0 !== t && t.rawValue.length && e.props.fields.rawValue.forEach((e => {
                e.fieldName || (e.fieldName = Nf(e.name.rawValue, o));
            }));
        }));
        let i = {};
        const n = (e, a) => {
            const l = e.fieldName.replace(t, "");
            i[l] || (i[l] = []);
            let o = {};
            var n, r;
            e.code == Bv ? (null !== (n = e.props) && void 0 !== n && null !== (r = n.fields) && void 0 !== r && r.rawValue.length && e.props.fields.rawValue.forEach(((l, n) => {
                const r = l.fieldName.replace(t, ""), s = `sub_form_${e.fieldName}${r}`;
                i[s] || (i[s] = []), o = {
                    data: l,
                    idx: n,
                    parentIdx: a
                }, i[s].push(o);
            })), o = {
                data: e,
                idx: a,
                parentIdx: 0
            }, i[l].push(o)) : (o = {
                data: e,
                idx: a,
                parentIdx: 0
            }, i[l].push(o));
        }, r = l.filter((e => e.saved)), s = l.filter((e => !e.saved));
        r.forEach(((e, a) => {
            n(e, a);
        })), s.forEach(((e, a) => {
            n(e, a);
        }));
        const u = Object.values(i).filter((e => e.length > 1)), d = (e = [], a = "") => {
            let t = [];
            return e.forEach((e => {
                e.data.id !== a && t.push(e.data.fieldName);
            })), t;
        };
        for (const e in u) {
            var p, m, c;
            null === (p = u[e]) || void 0 === p || null === (m = (c = p).forEach) || void 0 === m || m.call(c, ((a, t) => {
                if (a.data.saved) return;
                if (0 === t) return;
                let l = d(u[e], a.data.id), o = `${a.data.fieldName}`;
                if (l.includes(o)) if (o = `${a.data.fieldName}${t}`, a.data.fieldName.length >= 30) {
                    const e = l.length + "";
                    o = `${a.data.fieldName.slice(0, -e.length)}${l.length ? l.length : ""}`;
                } else o = `${a.data.fieldName}${l.length ? l.length : ""}`; else if (a.data.fieldName.length >= 30) {
                    const e = t + "";
                    o = `${a.data.fieldName.slice(0, -e.length)}${t}`;
                }
                a.data.fieldName = o;
            }));
        }
        return o = null, l;
    }
    batchAddSavedFlag(e) {
        const a = Array.isArray(e) ? e : this.getFormWidgets();
        return this.each((e => {
            var a, t;
            e.code == Bv && null !== (a = e.props) && void 0 !== a && null !== (t = a.fields) && void 0 !== t && t.rawValue.length && e.props.fields.rawValue.forEach((e => {
                e.saved = !0;
            })), e.saved = !0;
        }), a), a;
    }
}

const Rf = require("dayjs/plugin/quarterOfYear");

dt.extend(Rf);

const xf = dt("9999-12-31 23:59:59"), Af = dt("1970-01-01 00:00:00"), kf = {
    [sf]: () => {
        const e = dt();
        return [ e.startOf("year").valueOf(), e.endOf("year").valueOf() ];
    },
    [of]: e => {
        const a = dt().subtract(e, "month");
        return [ Af.valueOf(), a.valueOf() ];
    },
    [uf]: () => {
        const e = dt();
        return [ e.startOf("month").valueOf(), e.endOf("month").valueOf() ];
    },
    [mf]: e => [ dt().add(e, "month").valueOf(), xf.valueOf() ],
    [nf]: e => {
        const a = dt().subtract(e, "week");
        return [ Af.valueOf(), a.valueOf() ];
    },
    [df]: () => {
        const e = dt();
        return [ e.startOf("week").valueOf(), e.endOf("week").valueOf() ];
    },
    [cf]: e => [ dt().add(e, "week").valueOf(), xf.valueOf() ],
    [rf]: e => {
        const a = dt().subtract(e, "day");
        return [ Af.valueOf(), a.valueOf() ];
    },
    [pf]: () => {
        const e = dt();
        return [ e.startOf("day").valueOf(), e.endOf("day").valueOf() ];
    },
    [vf]: e => [ dt().add(e, "day").valueOf(), xf.valueOf() ]
};

var Pf;

const _f = null !== (Pf = window.dynamicDataContext) && void 0 !== Pf ? Pf : new class {
    constructor() {
        this.data = {}, this.lastFetchTime = null;
    }
    get currentUser() {
        return this.data[ff];
    }
    get currentDept() {
        return this.data[gf];
    }
    get currentDeptAndLowerDept() {
        return this.data[bf];
    }
    setData(e, a) {
        this.data[e] = a;
    }
    setCurrentUser(e) {
        this.setData(ff, e);
    }
    getData(e) {
        return this.data[e];
    }
    async fetchDeptData(e) {
        (!this.lastFetchTime || Date.now() - this.lastFetchTime > 3e4) && (await Promise.all([ this.fetchUserDept(e), this.fetchUserDeptAndLowDept(e) ]), 
        this.lastFetchTime = Date.now());
    }
    async fetchUserDept(e) {
        var a, t;
        if (null === (a = this.currentUser) || void 0 === a || !a.id) return;
        const l = await e.admin.getDeptByUserId(null === (t = this.currentUser) || void 0 === t ? void 0 : t.id);
        this.setData(gf, l);
    }
    async fetchUserDeptAndLowDept(e) {
        var a;
        if (null === (a = this.currentUser) || void 0 === a || !a.id) return;
        const t = await e.admin.getAllDeptByUserId(this.currentUser.id);
        this.setData(bf, t);
    }
    removeData(e) {
        delete this.data[e];
    }
    emptyData() {
        this.data = {};
    }
};

window.dynamicDataContext = _f;

const Uf = window.jsonRuleExecutionRegistry || new class {
    constructor() {
        xi(this, "widgetCalculators", new Map);
    }
    registerWidgetCalculator(e, a) {
        this.widgetCalculators.set(e, a);
    }
    resolveCalculator(e, a) {
        const t = this.widgetCalculators.get(e);
        if (t) return new t(a);
    }
};

window.jsonRuleExecutionRegistry = Uf;

const Bf = {
    widgets: [],
    libraryManager: {}
};

class Wf {
    constructor({widgets: e, libraryManager: a} = Bf) {
        this.setWidgets(e), this.libraryManager = a;
    }
    get formWidgets() {
        return this.widgetTree.getFormWidgets();
    }
    get widgetMap() {
        return Ga("fieldName", this.formWidgets);
    }
    setWidgets(e) {
        Ua(e) && (this.widgets = e, this.widgetTree = new Mf(e, []));
    }
    getEachFn(e) {
        return e.condition === hf ? Ja.every : Ja.some;
    }
    resolveCalculator(e, a, t) {
        const l = this.widgetMap[e], o = {
            metaData: l,
            defineMetaData: this.libraryManager.getMeta("official", null == l ? void 0 : l.code),
            widgets: this.formWidgets,
            jsonRule: a,
            data: t
        };
        return {
            calculator: Uf.resolveCalculator((null == l ? void 0 : l.code) || Pv, o),
            ctx: o
        };
    }
    resolveValue(e, a, t, l) {
        var o, i;
        switch (a) {
          case Df:
            return t[e];

          case Cf:
            if ((null == l || null === (o = l.metaData) || void 0 === o ? void 0 : o.code) !== _v) switch (null == e ? void 0 : e.type) {
              case ff:
                return [ _f.currentUser.id ];

              case gf:
                return _f.currentDept.map((e => e.id));

              case bf:
                return _f.currentDeptAndLowerDept.map((e => e.id));
            }
            return null == kf || null === (i = kf[null == e ? void 0 : e.type]) || void 0 === i ? void 0 : i.call(kf, null == e ? void 0 : e.value);

          default:
            return e;
        }
    }
    execute({jsonRule: e, data: a}) {
        var t;
        if (!Ua(this.widgets)) return !0;
        if (Ja.isNil(e)) return !0;
        const l = null !== (t = null == e ? void 0 : e.rules) && void 0 !== t ? t : [];
        if (Ua(l)) {
            return this.getEachFn(e)(l, (e => this.execute({
                jsonRule: e,
                data: a
            })));
        }
        const {field: o, operator: i, value: n, valueType: r} = e, s = this.resolveCalculator(o, e, a);
        if (s) {
            const {calculator: e, ctx: t} = s, l = a[o], u = this.resolveValue(n, r, a, t);
            return e.callOperator(i, l, u);
        }
        return !1;
    }
    async batchExecute(e = []) {
        return e.map((e => this.execute(e)));
    }
}

const {formComponentMixin: qf} = z;

var jf = e({
    name: "OlSummary",
    props: {
        modelValue: {
            type: [ Number, String ]
        },
        formId: {
            type: String,
            default: ""
        }
    },
    emits: [ "update:modelValue" ],
    mixins: [ qf ],
    setup(e, {attrs: a, emit: t}) {
        const l = Na(e), o = s((() => ({
            ...e,
            ...a,
            step: 1 / Math.pow(10, d.value)
        }))), n = le(), r = i([]), u = s((() => a.showDecimal)), d = s((() => u.value ? a.decimalCount : 0)), p = be(), m = s((() => new Wf({
            widgets: r.value,
            libraryManager: p
        }))), c = s({
            get: () => Ja.isNil(l.value) ? e.metaData.defaultValue : l.value,
            set: e => {
                if (Ja.isNil(e) || "" === e) return void t("update:modelValue", null);
                if (String(e).length > 16) {
                    const a = String(e).split("");
                    a.length = 16, e = a.join("");
                }
                String(e).includes(".") ? (e = Math.floor(Ha(e * Math.pow(10, d.value))) / Math.pow(10, d.value), 
                t("update:modelValue", `${e}${!String(e).includes(".") && d.value > 0 ? "." : ""}`)) : t("update:modelValue", e);
            }
        }), v = s((() => De.options.reduce(((e, t) => {
            let l = "";
            switch (t.value) {
              case "percentUnit":
                l = "%";
                break;

              case "priceUnit":
                l = a.priceUnit;
                break;

              case "customUnit":
                l = a.customUnit;
            }
            return {
                ...e,
                [t.value]: l
            };
        }))[a.formatType])), f = i(), g = s((() => {
            let a;
            return new ge(e.pageMetaData).each((t => {
                t.fieldName === e.metaData.props.summaryFieldName && (a = t);
            })), Ae(a?.code) ? b(a.props.appId.rawValue, a.props.formId.rawValue) : r.value = a?.props?.fields, 
            f.value = a, e.formData[a?.fieldName];
        })), b = (e, a) => {
            e && a && n.metaData.getPageMetaData({
                appId: e,
                pageCode: a
            }).then((e => {
                const {widgets: a = [], systemAttributes: t = []} = e.content || {}, l = new ge(a.concat(t)), o = [];
                l.each(((e, a, t, l) => {
                    e.widgetType === J.FORM && o.push(e);
                })), r.value = o;
            }));
        };
        return P((() => g.value), Ja.debounce((a => {
            e.runtimeEnv !== Q.EDIT && e.runtimeEnv !== Q.CREATE && e.runtimeEnv !== Q.PROCESS_AUDIT || setTimeout((() => {
                ((a, t) => {
                    const {summaryFieldName: l, statisticalFields: o, statisticalWay: i, numberFormulaToZero: n, conditions: r} = e.metaData.props;
                    let s;
                    const u = a[l + je], d = Ja.isObject(u?.[0]) ? je : qe;
                    if (Ae(t)) {
                        if (s = a[l + d], !s || !s.length) return void (c.value = 0);
                    } else if (s = a[l], !s || !s.length) return void (c.value = 0);
                    let p = [];
                    if (r?.rules) {
                        const e = Ja.cloneDeep(r);
                        e.rules.forEach((e => {
                            e.field = e.field.split(".")[e.field.split(".").length - 1];
                        })), s.forEach(((a, t) => {
                            const l = m?.value.execute({
                                jsonRule: e,
                                data: a
                            });
                            l && p.push(a);
                        }));
                    }
                    const v = r?.rules ? p : s;
                    if ("id" === o) return void (c.value = v.length);
                    const f = [];
                    v.forEach((e => {
                        const a = e[o];
                        Ja.isNil(a) || "​" === a ? f.push("undefined") : f.push(isNaN(a) ? 1 : a);
                    }));
                    const g = da.evaluate(`\${${i?.toLocaleUpperCase()}(${f})}`, {}, {
                        evalMode: !1,
                        escapeEmptyValue: !n
                    });
                    c.value = g;
                })(e.formData, f.value?.code);
            }), 500);
        }), 400), {
            immediate: (!0).valueOf,
            deep: !0
        }), {
            inputValue: c,
            bindData: o,
            unit: v
        };
    }
});

const $f = {
    class: "ol-summary ol-summary-mobile"
};

jf.render = function(e, a, o, i, n, r) {
    const s = V("ssu-input-text");
    return t(), l("div", $f, [ f(s, E({
        modelValue: e.inputValue,
        "onUpdate:modelValue": a[0] || (a[0] = a => e.inputValue = a),
        modelModifiers: {
            number: !0
        }
    }, e.bindData, {
        class: [ "priceUnit" === e.bindData.formatType && "padding-unit-left", (e.bindData.customUnit || "percentUnit" === e.bindData.formatType) && "padding-unit-right" ],
        type: "number",
        disabled: ""
    }), null, 16, [ "modelValue", "class" ]), e.unit ? (t(), l("span", {
        key: 0,
        class: A([ "ol-summary-unit", "priceUnit" === e.bindData.formatType && "left-unit", (e.bindData.customUnit || "percentUnit" === e.bindData.formatType) && "right-unit" ])
    }, O(e.unit), 3)) : F("", !0) ]);
};

const {formComponentMixin: zf} = z;

var Gf = e({
    name: "OlSummaryMobile",
    props: {
        modelValue: {
            type: String,
            default: ""
        }
    },
    mixins: [ zf ],
    setup: e => () => f(et, null, null)
});

const {propsFormMixin: Hf, setterMixin: Qf} = z;

var Yf = e({
    name: "OlSummaryPropsForm",
    mixins: [ Hf, Qf ],
    setup(e, {expose: a}) {
        const {convertOptionListToObj: t, convertObjToOptionList: l} = H, o = s((() => {
            const a = i(l({
                showThousands: e.metaData.props.showThousands.rawValue,
                showDecimal: e.metaData.props.showDecimal.rawValue
            }));
            return i(a.value.map((a => i({
                ...a,
                params: "showDecimal" === a.key && {
                    ...a.params,
                    decimalCount: e.metaData.props.decimalCount.rawValue
                } || void 0
            }).value))).value;
        })), n = s((() => i(Ce.options.map((a => ({
            ...a,
            params: {
                ...a.params,
                decimalCount: "showDecimal" === a.key && e.metaData.props.decimalCount.rawValue || void 0
            }
        })))).value)), {data: r, formRef: u} = fa(e, a), d = s((() => i(l({
            visible: e.metaData.visible,
            visibleWhenCreate: e.metaData.visibleWhenCreate
        }, !0)).value));
        return () => f(V("el-form"), {
            ref: u,
            model: r,
            "label-position": "top",
            class: "ol-summary-props-form"
        }, {
            default: () => [ f(V("label-setter"), {
                metaData: e.metaData
            }, null), f(V("field-name-setter"), {
                metaData: e.metaData,
                pageMetaData: e.pageMetaData
            }, null), f(V("placeholder-setter"), {
                metaData: e.metaData
            }, null), f(V("summary-setter"), {
                pageData: e.pageData,
                metaData: e.metaData,
                pageMetaData: e.pageMetaData,
                inSubForm: e.inSubForm
            }, null), f(V("number-format-setter"), {
                metaData: e.metaData,
                numberFormatOptions: n.value,
                numberFormatConfigList: o.value,
                "onUpdate:numberFormatConfigList": (a = []) => {
                    const l = t(a), {showDecimal: i, showThousands: n} = l;
                    let r;
                    var s, u;
                    i && (r = null === (s = o.value.find((e => "showDecimal" === e.key))) || void 0 === s || null === (u = s.params) || void 0 === u ? void 0 : u.decimalCount);
                    e.metaData.props.showDecimal.rawValue = i, e.metaData.props.showThousands.rawValue = n, 
                    e.metaData.props.decimalCount.rawValue = r;
                }
            }, null), f(V("field-status-setter"), {
                metaData: e.metaData,
                statusConfigList: d.value,
                filterKey: "editable",
                "onUpdate:statusConfigList": (a = []) => {
                    const l = t(a, G.options.filter((e => "editable" !== e.key)), !0), {editable: o, visible: i, visibleWhenCreate: n} = l;
                    e.metaData.editable = o, e.metaData.visible = i, e.metaData.visibleWhenCreate = n;
                }
            }, null) ]
        });
    }
});

const {formEntityMixin: Kf, metaDataMixin: Xf} = z;

var Jf = e({
    name: "OlSummaryTableFormatter",
    mixins: [ Kf, Xf ],
    props: {
        formatType: {
            type: String,
            default: ""
        },
        priceUnit: {
            type: String,
            default: ""
        },
        customUnit: {
            type: String,
            default: ""
        },
        showThousands: {
            type: Boolean,
            default: !1
        },
        decimalCount: {
            type: Number,
            default: 0
        }
    },
    setup(e, {attrs: a}) {
        const t = s((() => ({
            [we.DEFAULT.value]: e => e,
            [we.PERCENT_UNIT.value]: e => e + "%",
            [we.PRICE_UNIT.value]: a => e.priceUnit + a,
            [we.CUSTOM_UNIT.value]: a => a + e.customUnit
        }))), l = s((() => {
            let a = "";
            var l, o;
            return Ja.isNil(e.fieldValue) ? (a = 0, a) : (a = e.fieldValue, a = Ja.floor(a, e.decimalCount).toLocaleString("zh-CN", {
                useGrouping: e.showThousands,
                minimumFractionDigits: e.decimalCount
            }), a = (null === (l = (o = t.value)[e.formatType]) || void 0 === l ? void 0 : l.call(o, a)) || a, 
            a);
        }));
        return () => f("label", {
            class: "ol-summary-table-formatter"
        }, [ l.value ]);
    }
}), Zf = {
    code: K.SUMMARY,
    name: "汇总",
    group: X.HIGH_LEVEL,
    icon: "summary",
    widgetType: J.FORM,
    dataType: Z.NUMBER,
    widget: "OlSummary",
    propsForm: "OlSummaryPropsForm",
    tableFormatter: "OlSummaryTableFormatter",
    props: {
        placeholder: "系统自动生成，无需填写",
        summaryFieldName: "",
        summaryFieldWidgetCode: "",
        summaryFieldModelCode: "",
        statisticalFields: "",
        statisticalFieldWidgetCode: "",
        statisticalFormula: "",
        statisticalWay: "",
        conditions: null,
        numberFormatType: "",
        numberFormulaToZero: !0,
        formatType: "",
        priceUnit: "",
        customUnit: "",
        showThousands: !1,
        showDecimal: !1,
        decimalCount: void 0
    },
    defaultValue: void 0,
    requiredProps: [],
    supportBindVariableProps: [ "placeholder" ],
    supportI18NProps: [ "placeholder" ],
    supportEvents: [ {
        key: "focus",
        name: "聚焦"
    }, {
        key: "blur",
        name: "失焦"
    } ],
    conditionConfig: {
        defaultOperator: ee.BETWEEN,
        supportWidgetCodeConfig: [ K.NUMBER, K.RATE, K.SUMMARY, vi ],
        supportOperator: {
            [ee.BETWEEN]: ae[ee.BETWEEN],
            [ee.NOT_BETWEEN]: ae[ee.NOT_BETWEEN],
            [ee.EQUAL]: ae[ee.EQUAL],
            [ee.NOT_EQUAL]: ae[ee.NOT_EQUAL],
            [ee.LESS]: ae[ee.LESS],
            [ee.LESS_OR_EQUAL]: ae[ee.LESS_OR_EQUAL],
            [ee.GREATER]: ae[ee.GREATER],
            [ee.GREATER_OR_EQUAL]: ae[ee.GREATER_OR_EQUAL],
            [ee.IS_NULL]: ae[ee.IS_NULL],
            [ee.IS_NOT_NULL]: ae[ee.IS_NOT_NULL]
        }
    }
};

jf.meta = Zf, jf.PropsForm = Yf, jf.Mobile = Gf, jf.install = e => {
    e.component(jf.name, jf), e.component(Yf.name, Yf), e.component(Gf.name, Gf), e.component(Jf.name, Jf);
}, jf.widgetMap = {
    [Gf.name]: Gf,
    [jf.name]: jf,
    [Yf.name]: Yf,
    [Jf.name]: Jf
};

const eg = [ Kc, Pc, Ic, jp, fs, Ts, rn, gn, qs, Zo, Op, ls, Gd, hi, rr, eu, Mo, Tn, Gi, $l, hm, Ju, hc, sp, Ad, Cd, Id, uv, Ev, jf ], ag = eg.map((e => e.meta)), tg = eg.reduce(((e, a) => {
    const t = {
        [a.meta.code]: a
    };
    return {
        ...e,
        ...a.widgetMap ? a.widgetMap : t
    };
}), {}), lg = {
    code: "official",
    name: "官方组件库",
    metas: ag,
    widgets: eg,
    widgetMap: tg,
    install(e) {
        eg.forEach((a => {
            a.install(e);
        }));
    }
};

export { lg as default };
