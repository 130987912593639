/*
 * @Author: zbq
 * @description: 文件描述
 * @Date: 2022-07-26 10:49:06
 * @LastEditors: zbq
 * @LastEditTime: 2022-07-27 20:12:08
 */
import { defineComponent, Fragment } from 'vue'
import styles from './PublicFormShare.module.scss'
import { usePublicFormShareProvider } from './store'
import FormDetailRender from './FormDetailRender'
// import { px2vw } from '@syc-paas/utils'
import _ from 'lodash'
import Failure from '../../assets/images/failure.png'

const PublicFormShare = defineComponent({
  name: 'PublicFormShare',

  setup(props, ctx) {
    const {
      formName,
      pageBgColor,
      pageBgImage,
      handleClickSubmit,
      isSubmitSuccess,
      loading,
      isFailure,
      appraiseOpenDetails,
      onClickPrintTemplate,
    } = usePublicFormShareProvider({ props, ctx })

    return () => {
      return (
        <div v-loading={loading.value} class={styles.container}>
          {isFailure.value && (
            <Fragment>
              <div class={[styles.formMain, styles.submitSuccess]}>
                <img class={styles.failureImg} src={Failure} />
                <div style="color: #90939A">抱歉，您访问的链接已失效</div>
              </div>
            </Fragment>
          )}
          {!isFailure.value && !isSubmitSuccess.value && (
            <div
              class={styles.formWrapper}
              style={{
                backgroundColor: pageBgColor.value,
              }}
            >
              <div class={styles.formBgImg}>
                <img src={pageBgImage.value} />
              </div>

              <div class={styles.formMain}>
                <div class={styles.formTitle}>{formName.value}</div>
                <div class={styles.formContent}>
                  {appraiseOpenDetails.value?.printTemplatePdfUrl && (
                    <div
                      class={styles.formPrintTitle}
                      onClick={onClickPrintTemplate}
                    >
                      <span
                        style={{
                          display: 'flex',
                          'align-items': 'center',
                        }}
                      >
                        <SvgIcon
                          class={styles.iconTemplate}
                          name={'icon-template'}
                          width={17}
                          height={20}
                        />
                        {appraiseOpenDetails.value.printTemplateTitle}
                      </span>
                      <span>
                        <SvgIcon
                          class={styles.iconXiala}
                          name={'xiala'}
                          width={12}
                          height={10}
                        />
                      </span>
                    </div>
                  )}
                  <FormDetailRender />
                </div>
                <div class={styles.formButton}>
                  <div class={styles.button}>
                    <ssu-button
                      type="primary"
                      onClick={_.debounce(handleClickSubmit, 200)}
                    >
                      提交
                    </ssu-button>
                  </div>
                </div>
              </div>

              {/* {isSubmitSuccess.value && (
              <Fragment>
                <div class={[styles.formMain, styles.submitSuccess]}>
                  <div>提交成功!</div>
                </div>
              </Fragment>
            )} */}
            </div>
          )}
          {isSubmitSuccess.value && (
            <Fragment>
              <div class={[styles.formMain, styles.submitSuccess]}>
                <i
                  class={['iconfont iconchenggong', styles.submitSuccessIcon]}
                ></i>
                <div>问卷提交成功，感谢您的参与!</div>
              </div>
            </Fragment>
          )}
        </div>
      )
    }
  },
})

export default PublicFormShare
