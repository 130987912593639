/*
 * @Author: pzf
 * @description: 配置文件
 * @Date: 2022-07-07 09:46:54
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-14 13:35:33
 */
const config = {
    defaultType: [
        'bmp',
        'jpg',
        'png',
        'jpeg',
        'webp',
        'tif',
        'gif',
        'psd',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'xls',
        'xlsx',
        'txt',
        'rar',
        'mp3',
        'wav',
        'ogg',
        'flac',
        'cda',
        'mp4',
        'wmv',
        'flv',
        'avi',
        'RM',
        'rm',
        'mpeg',
        '3gp',
        'asf',
        'rmvb',
        'mov',
        'pdf',
        'doc',
        'rtf',
        'docx',
        'ppt',
        'rar',
        'wps',
        'pptx',
        'pptm',
        'zip',
        '7z',
        'war',
        'xlsm',
        'xml',
        'xlsb'
    ],
    mixTypeObj: [
        {
            typeArr: ['mp4', 'wmv', 'flv', 'avi', 'RM', 'mpeg', '3gp', 'asf', 'rmvb', 'mov', 'rm', 'mkv','mp4','avi','swf','wmv','rmvb','mov', 'mpg'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_video.png'
        },
        {
            typeArr: ['pdf'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_pdf.png'
        },
        {
            typeArr: ['psd'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_psd.png'
        },
        {
            typeArr: ['txt'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_txt.png'
        },
        {
            typeArr: ['doc', 'docx', 'wps', 'rtf', 'hlp'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_word.png'
        },
        {
            typeArr: ['xls', 'xlsx', 'xlsm', 'xlsb', 'cvs'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_elsx.png'
        },
        {
            typeArr: ['ppt', 'pptx', 'pptm'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_ppt.png'
        },
        {
            typeArr: ['rar', 'zip', '7z', 'war', 'rar','zip','7z','gz','arj','z'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_zip.png'
        },
        {
            typeArr: ['xml'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_xml.png'
        },
        {
            typeArr: [ 'mpeg', 'wma', 'flac', 'aac', 'mp3','flac','ape','wma','wav','aac','m4a','au','ram','mmf','aif', 'cda', 'ogg'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_audio.png'
        },
        {
            typeArr: ['htm', 'html'],
            icon: 'https://js.shengyc.com/assets/SLM-plus/images/file-icon/icon_html.png'
        }
    ],
    imgArr: ['jpg', 'png', 'gif', 'svg', 'bmp', 'webp', 'apng', 'jpeg', 'heic']
}

export default config
