/*
 * @Author: 莫靓仔
 * @description: dom操作工具
 * @Date: 2020-12-03 16:15:29
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-06 09:26:34
 */

/**
 * @description: 是否有某个类名
 * @param {DOM} el 节点
 * @param {className} cls 类名
 * @return {*}
 */
export function hasClass(el, cls) {
    if (!el || !cls) return false
    if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.')
    if (el.classList) {
        return el.classList.contains(cls)
    } else {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
    }
}

/**
 * @description: 添加个类名
 * @param {DOM} el 节点
 * @param {className} cls 类名
 * @return {*}
 */
export function addClass(el, cls) {
    if (!el) return
    var curClass = el.className
    var classes = (cls || '').split(' ')

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i]
        if (!clsName) continue

        if (el.classList) {
            el.classList.add(clsName)
        } else if (!hasClass(el, clsName)) {
            curClass += ' ' + clsName
        }
    }
    if (!el.classList) {
        el.className = curClass
    }
}

/**
 * @description: 删除类名
 * @param {DOM} el 节点
 * @param {className} cls 类名
 * @return {*}
 */
export function removeClass(el, cls) {
    if (!el || !cls) return
    var classes = cls.split(' ')
    var curClass = ' ' + el.className + ' '

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i]
        if (!clsName) continue

        if (el.classList) {
            el.classList.remove(clsName)
        } else if (hasClass(el, clsName)) {
            curClass = curClass.replace(' ' + clsName + ' ', ' ')
        }
    }
    if (!el.classList) {
        try {
            el.className = curClass.trim()
        } catch (err) {
        }
    }
}

/**
 * @description: 获取样式属性
 * @param {DOM} element 节点
 * @param {String} styleName style名
 * @return {*}
 */
export const getStyle = function(element, styleName) {
    if (!element || !styleName) return null
    styleName = camelCase(styleName)
    if (styleName === 'float') {
        styleName = 'cssFloat'
    }
    try {
        var computed = document.defaultView.getComputedStyle(element, '') || ''
        return element.style[styleName] || computed ? computed[styleName] : null
    } catch (e) {
        return element.style[styleName]
    }
}

export const camelCase = function(name) {
    const SPECIAL_CHARS_REGEXP = /([:\-_]+(.))/g
    const MOZ_HACK_REGEXP = /^moz([A-Z])/
    return name
        .replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
            return offset ? letter.toUpperCase() : letter
        })
        .replace(MOZ_HACK_REGEXP, 'Moz$1')
}

// 获取类名为xxx的父元素
export const getParents = (element, className) => {
    if (hasClass(element, className)) {
        return element
    }

    let targetParent = element.parentNode
    
    while (!hasClass(targetParent, className) && targetParent !== null) {
        targetParent = targetParent.parentNode
    }
    return targetParent
}

/**
 * @description: 数据类型判断
 * @param {*} target
 * @param {String} type
 * @return {String/Boolean} type/targetType 传type返回Boolean，不传返回大写的类型 如：Array Object String
 */
 export function isType(target, type) {
    let targetType = Object.prototype.toString.call(target).replace(/^\[object (.*)\]$/, '$1')
    if (type) {
        return type === targetType
    } else {
        return targetType
    }
}

export default {
    hasClass,
    addClass,
    removeClass,
    getStyle,
    camelCase,
    getParents,
    isType
}
