import { PageType, createLiteStore } from '@syc-paas/core'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import _ from 'lodash'
import paasClient from '@/lib/paasClient'
import { recursionTree } from '@syc-paas/utils'
import { useAppLayoutStore } from '../AppLayout/AppLayoutStore'
import { useMutation } from 'vue-query'

const useRouteInfo = () => {
  const route = useRoute()
  const router = useRouter()
  const routeData = computed(() => {
    return route.params
  })
  const routePrefix = computed(() => {
    const { platformId, appId } = routeData.value
    return `/platform/${platformId}/app/${appId}/page`
  })
  const goPage = (pageCode) => {
    router.push(`${routePrefix.value}/${pageCode}`)
  }
  const noRedirect = computed(() => {
    return routePrefix.value === route.path
  })

  const toPageItem = (item = {}) => {
    if (!item.code) {
      return
    }

    goPage(item.code)
  }
  return {
    routePrefix,
    routeData,
    noRedirect,
    route,
    goPage,
    toPageItem,
  }
}

// const useData = ({ goPage, noRedirect }) => {
//   const { routeData } = useRouteInfo()
//   const curElement = ref({})
//   const { menuList, pageList, isLoading, refetch } = useAppLayoutStore()
//   const toPageItem = (item = {}) => {
//     if (!item.code) {
//       return
//     }

//     goPage(item.code)
//     curElement.value = item
//   }
//   watch(
//     () => {
//       return menuList.value
//     },
//     (value) => {
//       // 跳转页面
//       let firstPage = null
//       recursionTree(value, {
//         stop: (data) => data.pageType !== PageType.GROUP,
//         visit: (data) => {
//           firstPage = data
//         },
//       })

//       if (noRedirect.value) {
//         if (firstPage) {
//           toPageItem(firstPage)
//         }
//       } else {
//         const currentPage = value.find((item) => {
//           return item.code === routeData.value.pageCode
//         })

//         curElement.value = currentPage
//       }
//     },
//     { immediate: true }
//   )
//   const _sortList = (list) => {
//     return list.map((item, index) => {
//       if (!_.isNil(item.children)) {
//         item.children = _sortList(item.children)
//       }
//       return {
//         id: item.id,
//         name: item.name,
//         sort: index,
//         children: item.children,
//       }
//     })
//   }
//   const sort = useMutation(() => {
//     const body = _sortList(menuList.value)
//     return paasClient.admin.setPageList({ preview: true, body })
//   })

//   return {
//     pageList,
//     isLoading,
//     menuList,
//     refetch,
//     curElement,
//     sortMenu: sort.mutate,
//     toPageItem,
//   }
// }

// const useUiState = ({ sortMenu }) => {
//   const curDrag = ref()
//   const curHover = ref()

//   const onStart = (e, list) => {
//     if (!_.isNil(e.oldIndex)) {
//       const item = list[e.oldIndex]
//       curDrag.value = item
//     }
//   }
//   const onEnd = () => {
//     curDrag.value = null
//     sortMenu()
//   }
//   return {
//     curDrag,
//     curHover,
//     onStart,
//     onEnd,
//   }
// }

// const useDialog = ({ curElement }) => {
//   const visible = ref(false)
//   const deleteVisible = ref(false)
//   const dialogType = ref('')

//   const openDialog = (type, element = {}) => {
//     dialogType.value = type
//     curElement.value = element
//     visible.value = true
//   }

//   const openDeleteMenuDialog = (element = {}) => {
//     curElement.value = element
//     deleteVisible.value = true
//   }
//   return {
//     visible,
//     deleteVisible,
//     dialogType,
//     openDialog,
//     openDeleteMenuDialog,
//   }
// }

export const AppPageLayoutStore = createLiteStore(() => {
  const routeInfo = useRouteInfo()
  // const data = useData(routeInfo)
  // const uiState = useUiState(data)
  // const dialogData = useDialog({
  //   curElement: data.curElement,
  // })
  // const isDashBoardPage = computed(() => {
  //   return data.curElement.value?.pageType === PageType.DASHBOARD
  // })
  return {
    // ...uiState,
    // ...data,
    ...routeInfo,
    // ...dialogData,
    // isDashBoardPage,
  }
}, 'appPageLayout')

export const useAppPageLayoutStore = () => AppPageLayoutStore.useInject()
