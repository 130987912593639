import { computed, ref, watch } from 'vue'
import { createLiteStore, createStoreContext } from '@syc-paas/core'

import _ from 'lodash'
import paasClient from '@/lib/paasClient'
import { useQueries } from 'vue-query'
import { useRoute } from 'vue-router'

const useData = () => {
  const route = useRoute()
  const routeData = computed(() => {
    return route.params
  })
  const data = useQueries([
    {
      queryKey: ['page', routeData.value.appId, routeData.value.pageCode],
      queryFn: () => {
        return paasClient.metaData.getPageDetail({
          appId: routeData.value.appId,
          code: routeData.value.pageCode,
        })
      },
    },
    {
      queryKey: ['app', routeData.value.appId],
      queryFn: () => {
        return paasClient.admin.getAppDetail(routeData.value.appId)
      },
    },
  ])
  const isLoading = computed(() => data.some((item) => item.isLoading))
  const platformId = computed(() => route.params.platformId)
  const appId = computed(() => route.params.appId)

  const pageData = computed(() => data[0].data)
  const appData = computed(() => data[1].data)
  const pageCode = computed(() => pageData.value?.code)
  const appCode = computed(() => appData.value?.code)
  return {
    appId,
    platformId,
    isLoading,
    pageData,
    appData,
    pageCode,
    appCode,
  }
}

const useRenderButton = () => {
  const renderButton = ref(() => {})
  const route = useRoute()
  const setRenderButton = (fn = _.noop) => {
    renderButton.value = fn
  }
  watch(
    () => route.path,
    () => {
      setRenderButton(() => null)
    }
  )
  return { renderButton, setRenderButton }
}

export const AppDesignLayoutStore = createLiteStore(() => {
  return {
    ...useData(),
    ...useRenderButton(),
  }
}, 'appDesignLayout')

export const useAppDesignLayoutStore = () => AppDesignLayoutStore.useInject()
