import { VueSvgIcon } from '@yzfe/vue3-svgicon'
import { defineComponent } from 'vue'
export * from './icon'

const iconRequire = require.context('./assets/svgs', true, /\.svg/)
const iconMap = iconRequire.keys().reduce((acc, path) => {
  const icon = iconRequire(path)
  return {
    ...acc,
    [icon.name]: icon,
  }
}, {})

const SycIcon = defineComponent({
  name: 'SycIcon',
  props: {
    type: {
      type: String,
    },
  },
  setup(props, { attrs }) {
    return () => <VueSvgIcon class="syc-icon" data={iconMap[props.type]} {...attrs} />
  },
})
const Plugin = {
  install(app) {
    app.component(SycIcon.name, SycIcon)
  },
}
export { SycIcon, Plugin, iconMap }
