import { ADMIN_HOST } from './constants'

export const redirectLogin = () => {
  window.location.href = `${ADMIN_HOST}/#/login-index?redirectUrl=${window.location.protocol}//${window.location.host}/#/workbench`
}

export default {
  ENTER: 'SYC_PAAS:ENTER',
  ADD_PLATFORM: 'SYC_PAAS:ENTER:ADD_PLATFORM',
}
