const en = {
  logoName: 'fe tpl',
  language: 'Select the language',
  hello: 'hello world111',
  button: {
    cancel: 'cancel',
    confirm: 'confirm',
    create: 'create',
    save: 'save',
    remove: 'remove',
    add: 'add',
    modify: 'modify',
  },
  login: {
    welcome: 'Welcome',
    btnName: 'Sign In',
    placeholder: {
      account: 'Place enter your account',
      password: 'Place enter your password',
    },
    ruleMessages: {
      account: {
        required: 'account is required',
      },
      password: {
        required: 'password is required',
      },
    },
    fallback: {
      success: 'Login success',
    },
  },
  appManage: {
    name: 'my app',
    describe: 'create your app, make operate more simple',
    addName: 'create app',
    searchPlaceholder: 'please input keyword to search',
    notPlatformIdError: 'please select platform',
    dropdown: {
      setApp: 'set app',
      deleteApp: 'delete app',
    },
    addDialog: {
      title: 'create app',
      name: 'app name',
      namePlaceholder: 'plase name for app，for example，"SaaS App"',
      nameError: 'the name is required',
      icon: 'app icon',
      successMessage: 'crete success',
      errorMessage: 'create failure',
    },
    deleteDialog: {
      title: 'tip',
      describe:
        'When delete “{name}”, <i>all config and data in this app will be deleted forever without resume.<i/>so please confirm all your user dont need this app after you delete ',
      name: 'please input app name for confirm delete this app',
      nameError: 'cannot match app name',
      successMessage: 'delete success',
      errorMessage: 'delete failure',
    },
  },
  appSettingsIndex: {
    title: 'app setting',
    baseInfo: 'base info',
    functionSwitch: 'function switch',
    transferButton: '转让平台',
  },
  appSettingsBaseInfo: {
    name: 'app name',
    namePlaceholder: 'plase name for app，for example，"Sass Platform"',
    nameError: 'the name is required',
    icon: 'app icon',
    successMessage: 'save success',
    errorMessage: 'save failure',
    createByName: 'createByName',
    createTime: 'creat time',
  },
  workbench: {
    name: 'my platform',
    describe: 'create your platform, make operate more simple',
    addName: 'create platform',
    searchPlaceholder: 'please input keyword to search',
    dropdown: {
      setPlatform: 'set platform',
      deletePlatform: 'delete platform',
    },
    addDialog: {
      title: 'create platform',
      name: 'platform name',
      namePlaceholder: 'plase name for platform，for example，"Sass Platform"',
      nameError: 'the name is required',
      homePageUrl: 'platform address',
      homePageUrlPlaceholder: 'please input your platform index page',
      icon: 'platform icon',
      successMessage: 'crete success',
      errorMessage: 'create failure',
    },
    deleteDialog: {
      title: 'tip',
      describe:
        'When delete “{name}”, <i>all config and data in this platform will be deleted forever without resume.<i/>so please confirm all your user dont need this platform after you delete ',
      name: 'please input platform name for confirm delete this platform',
      nameError: 'cannot match platform name',
      successMessage: 'delete success',
      errorMessage: 'delete failure',
    },
  },
  panelContainer: {
    noDataText: 'no data',
  },
  platformConfigIndex: {
    title: 'platform config',
    baseInfo: 'base info',
    platformAdmin: 'platform admin',
  },
  platformConfigBaseInfo: {
    name: 'platform name',
    namePlaceholder: 'plase name for platform，for example，"Sass Platform"',
    nameError: 'the name is required',
    homePageUrl: 'platform address',
    homePageUrlPlaceholder: 'please input your platform index page',
    icon: 'platform icon',
    successMessage: 'save success',
    errorMessage: 'save failure',
    createName: 'createName',
    createTime: 'creat time',
  },
  platformConfigAdmin: {
    title: 'platform admin',
    describe:
      'Platform can be designed, applied and configured (only platform owners can manage platform administrators, transfer and delete platforms)',
    admin: 'admin',
    owner: 'owner',
  },
  pageManage: {
    pageType: {
      normalPage: 'normalPage',
    },
    addPage: {
      formCode: 'form code',
      enter: 'please enter',
      codeMsg:
        'The identification must start with a lowercase letter. You can enter lowercase letters, numbers and underscores',
        select: 'select',
        icon: 'icon'
    },
    deletePage: {
      deleteMsg: 'confirm delete?',
    },
  },
}

export default en
