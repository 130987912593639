import {
  PlatformLayoutStore,
  usePlatformLayoutStore,
} from './PlatformLayoutStore'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'

import BaseHeader from '../../components/BaseHeader/BaseHeader'
import BaseHeaderBack from '@/components/BaseHeaderBack/BaseHeaderBack'
import { ElMessage } from 'element-plus'
import { SLM_PREVIEW_PAGE } from '@/lib/constants'
import _ from 'lodash'
import paasClient from '@/lib/paasClient'
import styles from './PlatformLayout.module.scss'
import { useLayoutActiveRoute } from '../../hooks/useLayoutActiveRoute'

import { PLATFORM_TYPES } from '@/lib/enum'

const PlatformLayout = defineComponent({
  name: 'PlatformLayout',
  setup() {
    const platformLayoutStore = PlatformLayoutStore.useProvide()
    console.log(
      '%cPlatformLayout.js line:22 platformLayoutStore',
      'color: #007acc;',
      platformLayoutStore
    )
    const { platformName, getPlatformDetail, platformType, platformCode } = platformLayoutStore

    const menus = reactive([
      { label: '应用管理', name: 'app' },
      { label: '导航设置', name: 'navigation' },
      { label: '平台设置', name: 'config' },
      { label: '待办中心', name: 'agency' },
      { label: 'SIOT管理', name: 'siot' },
      { label: '组态管理', name: 'configuration' },
    ])
    const route = useRoute()
    const router = useRouter()

    // 租户
    const pageNum = ref(1)
    const pageSize = ref(20)
    // 当前租户
    const currentTenant = ref()
    // 加载更多租户loading
    const isMoreSelectLoading = ref(false)
    // 是否有更多租户
    const isMoreTenantList = ref(false)
    //  租户数据
    const optionData = ref([])

    const layoutPrefix = computed(() => {
      return `/platform/${route.params.platformId}`
    })

    const activeTab = useLayoutActiveRoute(layoutPrefix.value)

    const handelClickTab = (tab) => {
      const { platformId } = route.params 
      router.push(`/platform/${platformId}/${tab.props.name}`)
    }

    const click = () => {
      router.push(`/workbench`)
    }
    //  租户集合
    const getTenantList = async (keyword) => {
      let _platformId = route.params.platformId
      let tenantType = sessionStorage.getItem('tenantType')
      let _data = {
        params: {
          pageNum: pageNum.value,
          pageSize: pageSize.value,
          platformId: _platformId,
          // 状态，0-启用，1-停用
          status: 0,
          keyword,
          type: tenantType,
        },
      }
      if (!_platformId) {
        return false
      }
      try {
        isMoreSelectLoading.value = true
        let { result, more } = await paasClient.admin.getTenantList(_data)
        if (result.length) {
          let _data = result.map((v) => ({
            value: v.id,
            label: v.name,
          }))
          optionData.value = [...optionData.value, ..._data]
          if (pageNum.value == 1) {
            currentTenant.value = optionData.value[0].value
          }
        }
        isMoreTenantList.value = more
        isMoreSelectLoading.value = false
      } catch (error) {
        isMoreSelectLoading.value = false
      }
    }
    //  获取更多租户
    const getMoreTenantList = () => {
      if (isMoreTenantList.value) {
        pageNum.value += 1
        getTenantList()
      }
    }
    // 跳转slm预览首页
    const _linkSlmPage = async () => {
      let tenantId = currentTenant.value

      if (!tenantId) {
        ElMessage.warning('请选择租户！')
        return false
      }
      try {
        let data = await paasClient.admin.getTentantCredentials({
          tenantId,
        })
        if (SLM_PREVIEW_PAGE && tenantId && data) {
          window.open(
            `${SLM_PREVIEW_PAGE}?previewTenantId=${tenantId}&code=${data}`
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    // 远程搜索租户
    const remoteMethod = _.debounce((query) => {
        if (query) {
            pageNum.value = 1
            optionData.value = []
            getTenantList(query)
        } else {
            onClearTenant()
        }
    }, 1000)

    const onClearTenant = () => {
      optionData.value = []
      pageNum.value = 1
      getTenantList()
  }


    onMounted(() => {
      getPlatformDetail()
      getTenantList()
    })

    return () => {
      return (
        <div class={styles.container}>
          <BaseHeader
            vSlots={{
              left: () => {
                return (
                  <BaseHeaderBack name={platformName.value} onClick={click} />
                )
              },
              middle: () => {
                return (
                  <ssu-tab-list
                    v-model={activeTab.value}
                    plain
                    margin={{ bottom: 0 }}
                    onTabClick={handelClickTab}
                    list={menus.filter((item => {
                      return (item.name !== 'siot' && item.name !== 'configuration') || platformType.value === PLATFORM_TYPES.SIOT
                    }))}
                    disabledMore={true}
                  />
                )
              },
              right: () => {
                return (
                  <div>
                    <ssu-select
                      v-model={currentTenant.value}
                      filterable
                      remote
                      clearable
                      optionPanelLoading={isMoreSelectLoading.value}
                      optionData={optionData.value}
                      placeholder="请选择租户"
                      class={styles.tentant}
                      remote-method={remoteMethod}
                      onClear={onClearTenant}
                      vSlots={
                        isMoreTenantList.value
                          ? {
                              footoption: () => {
                                let _footerDom = (
                                  <div class="more-button-wrap">
                                    <ssu-button
                                      class="more-button"
                                      type="text"
                                      onClick={getMoreTenantList}
                                    >
                                      加载更多
                                    </ssu-button>
                                  </div>
                                )
                                return _footerDom
                              },
                            }
                          : ''
                      }
                    ></ssu-select>
                    <ssu-button class={styles.preview} onClick={_linkSlmPage}>
                      预览
                    </ssu-button>
                  </div>
                )
              },
            }}
          />
          <div class={styles.main}>
            <RouterView />
          </div>
        </div>
      )
    }
  },
})

export default PlatformLayout
