import { ref, watch } from 'vue'

import _ from 'lodash'
import { useRoute } from 'vue-router'

export const useLayoutActiveRoute = (prefix = '') => {
  const activeTab = ref()
  const route = useRoute()
  const getKey = () => {
    const pairs = route.path.replace(prefix, '')
    return _.head(pairs.split('/').filter(Boolean))
  }
  watch(
    () => route.path,
    () => {
      activeTab.value = getKey()
    },
    { immediate: true }
  )
  return activeTab
}
