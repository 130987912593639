import { RELATION_FORM_ORIGIN_FLAG as e, WidgetType as t, WidgetCode as n, WidgetTree as r } from "@syc-paas/core";

import { SsuMessage as a } from "@syc-saas/ui";

import l from "lodash";

import { customAlphabet as i } from "nanoid";

import c from "jszip";

import o from "axios";

const s = "abcdefghijklmnopqrstuvwsyz", u = i(`${s}`, 15), d = i(`0123456789${s}`, 15), p = (e = 20) => u(1) + d(e - 1), y = e => !l.isNil(e) && "" !== e, m = e => l.isArray(e) && !l.isEmpty(e), h = e => l.isArray(e) || l.isObject(e), f = (e, t = []) => t.reduce(((t, n) => ({
    ...t,
    [n[e]]: n
})), {});

function v(e, t) {
    v.canvas = v.canvas ? v.canvas : document.createElement("canvas");
    const n = v.canvas.getContext("2d");
    n.font = t;
    return n.measureText(e).width;
}

function b(e, t = null, ...n) {
    return l.isNil(e) ? t : e(...n);
}

function g(e) {
    return !(l.isNil(e) || !l.isObject(e)) && (l.isFunction(e.__proto__.then) && l.isFunction(e.__proto__.catch) && l.isFunction(e.__proto__.finally));
}

function A(e) {
    const t = t => e.find((e => e.value === t));
    return {
        getPartialOptions: (t = []) => e.filter((e => t.includes(e.value))),
        excludeByKey: (t, n) => e.filter((e => e[t] !== n)),
        filterByKey(t, n) {
            const r = Array.isArray(n);
            return e.filter((e => r ? n.includes(e[t]) : e[t] === n));
        },
        groupBy: t => e.reduce(((n, r) => {
            const a = r[t], l = e.filter((e => e[t] === a));
            return n[a] = l, n;
        }), {}),
        getOption: t,
        get(e, n) {
            const r = t(e);
            return r ? r[n] : "";
        },
        options: e,
        getLabel(e) {
            const n = t(e);
            return null == n ? void 0 : n.label;
        },
        getValue(t) {
            const n = e.find((e => e.label === t));
            return null == n ? void 0 : n.value;
        }
    };
}

const w = (e = {}, t) => n => {
    const r = e[n];
    return l.isNil(r) ? t : r;
}, E = (e = [], t = null) => (...n) => {
    for (let r = 0; r < e.length; r++) {
        const [a, l] = e[r];
        let i;
        if (i = Array.isArray(a) ? a.every((e => b(e, !1, ...n))) : b(a, !1, ...n), i) return b(l, t, ...n);
    }
}, O = (e = {}) => (t, n = [], r) => b(e[t], r, ...n), _ = (e = {}, t = {
    labelKey: "label",
    valueKey: "value"
}) => {
    const n = l.cloneDeep(e), {labelKey: r, valueKey: a} = t, i = Object.keys(n);
    return 0 === i.length ? [] : i.map((t => ({
        [r]: t,
        [a]: e[t]
    })));
}, N = (e = [], t = !0) => {
    var n;
    const r = l.cloneDeep(e), a = {};
    return null == r || null === (n = r.forEach) || void 0 === n || n.call(r, (e => {
        Object.keys(e).forEach((n => {
            t ? (a[n] || (a[n] = []), a[n].push(e[n])) : a[n] = e[n];
        }));
    })), a;
}, j = (e = [], t = {}) => {
    const {childrenKey: n, visit: r, stop: a} = Object.assign({
        childrenKey: "children",
        stop: () => !1,
        visit: () => {}
    }, t), l = (e, t) => {
        for (let i = 0; i < e.length; i++) {
            const c = e[i];
            if (a(c)) {
                r(c, t, i);
                break;
            }
            r(c, t, i);
            const o = c[n];
            o && o.length > 0 && l(o, c);
        }
    };
    l(e);
}, R = e => l.isNil(e) ? [] : Array.isArray(e) ? e : [ e ], $ = e => () => e, x = e => e / 750 * 100 + "vw", D = (t, n = "id", r = {}) => {
    const a = l.cloneDeep(t);
    return a ? (Object.keys(a).forEach((t => {
        var i;
        return null !== (i = a[t]) && void 0 !== i && i[n] ? (null != r && r.withoutOriginData || (a[`${t}${e}`] = l.cloneDeep(a[t])), 
        void (a[`${t}`] = a[`${t}`][n])) : l.isArray(a[t]) ? (null != r && r.withoutOriginData || (a[`${t}${e}`] = l.cloneDeep(a[t])), 
        void (a[t] = a[t].map((e => (null == e ? void 0 : e[n]) || e)))) : void 0;
    })), a) : a;
}, k = (e, t = 15) => parseFloat(Number(e).toPrecision(t)), C = (e = [], n = []) => e.map((e => {
    if (e.widgetType === t.CONTAINER) return e.children = e.children.map((e => C(e, n))), 
    e;
    return n.find((t => t.id === e.id)) ? {
        ...e
    } : e;
})), F = (e = [], a = [], l = {}) => {
    const i = [ n.RELATION_FORM_FIELD, n.RELATION_FORM_QUERY ], c = [];
    return new r(e).each((e => {
        e.widgetType !== t.CONTAINER && i.includes(e.code) || c.push(e);
    })), c.map((e => {
        if (!e) return null;
        return a.find((t => t === e.fieldName)) ? {
            ...e,
            visible: (null == l ? void 0 : l.visible) || e.visible,
            editable: (null == l ? void 0 : l.editable) || e.editable
        } : null;
    })).filter((e => e));
}, K = (e = []) => e.map((e => ({
    label: e,
    value: e
}))), T = e => l.isObject(e) && !l.isEmpty(e), I = e => l.isArray(e) && !l.isEmpty(e), L = e => l.isString(e) && !l.isEmpty(e), P = e => {
    const t = l.cloneDeep(e);
    return l.isObject(t) ? (Object.keys(t).forEach((e => {
        t[e] = `${String(t[e])} ${e}`;
    })), t) : t;
};

function U(e) {
    const t = require("js-base64").Base64;
    let n = encodeURIComponent(t.encode(e));
    return `${process.env.VUE_APP_ONLINE_PREVIEW}?url=${n}`;
}

const B = (e = {}, t) => {
    const n = null == e ? void 0 : e[t];
    return l.isObject(n) && l.has(n, "rawValue") ? n.rawValue : n;
}, V = (e, t = [], n = "id") => {
    e && t.forEach((t => {
        var r;
        null === (r = e.addCache) || void 0 === r || r.call(e, {
            cacheKey: t[n],
            ...t
        });
    }));
}, z = e => t => e + (t ? `-${t}` : ""), q = e => {
    let t = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"), n = new Array("", "十", "百", "仟", "萬", "億", "点", ""), r = ("" + e).replace(/(^0*)/g, "").split("."), a = 0, l = "";
    for (let e = r[0].length - 1; e >= 0; e--) {
        switch (a) {
          case 0:
            l = n[7] + l;
            break;

          case 4:
            new RegExp("0{4}//d{" + (r[0].length - e - 1) + "}$").test(r[0]) || (l = n[4] + l);
            break;

          case 8:
            l = n[5] + l, n[7] = n[5], a = 0;
        }
        a % 4 == 2 && 0 != r[0].charAt(e + 2) && 0 == r[0].charAt(e + 1) && (l = t[0] + l), 
        0 != r[0].charAt(e) && (l = t[r[0].charAt(e)] + n[a % 4] + l), a++;
    }
    if (r.length > 1) {
        l += n[6];
        for (let e = 0; e < r[1].length; e++) l += t[r[1].charAt(e)];
    }
    return "一十" == l && (l = "十"), l.match(/^一/) && 3 == l.length && (l = l.replace("一", "")), 
    l;
}, M = async (e, t, n = !1, r = !1, a = "application/vnd.ms-excel") => new Promise(((l, i) => {
    const c = e => {
        const n = new Blob([ e ], {
            type: a
        });
        if (r) {
            const e = new FileReader;
            e.onloadend = () => {
                l(e.result);
            }, e.readAsDataURL(n);
        } else {
            const e = URL.createObjectURL(n), r = document.createElement("a");
            r.download = t, r.href = e, document.body.appendChild(r), r.click(), document.body.removeChild(r), 
            l(n);
        }
    };
    try {
        n ? o({
            method: "get",
            url: e,
            responseType: "arraybuffer"
        }).then((({data: e}) => {
            c(e);
        })) : c(e);
    } catch (e) {
        i(e);
    }
})), S = (e, t, n) => new Promise(((r, a) => {
    try {
        const l = new c, i = l.folder(n);
        Array.from(e).map((e => {
            i.file(e.name, e);
        })), l.generateAsync({
            type: "blob"
        }).then((async e => {
            let n = new File([ e ], t, {
                type: "zip"
            });
            r(n);
        })).catch((e => {
            a(e);
        }));
    } catch (e) {
        a(e);
    }
})), Q = async e => {
    try {
        await navigator.clipboard.writeText(e), a({
            type: "success",
            message: "复制成功！"
        });
    } catch (t) {
        const n = document.createElement("textarea");
        n.value = e, document.body.appendChild(n), n.select(), document.execCommand("copy"), 
        n.remove(), a({
            type: "success",
            message: "复制成功！"
        });
    }
};

export { K as arrayToOptions, E as cond, Q as copyToClipboard, z as createGetClassName, M as download, D as formDataAdaptor, $ as funWrap, O as functionStrategy, U as getFilePreviewUrl, B as getRawValue, v as getTextWidth, m as hasContentArray, y as isAvailableData, h as isComplexData, I as isNotEmptyArray, T as isNotEmptyObject, L as isNotEmptyString, g as isPromise, f as listToMapWith, p as nanoid, k as numberAmend, q as numberToChinese, N as objArrayToObj, _ as objToArray, A as optionsWrap, S as parseToZip, x as px2vw, j as recursionTree, b as safeCallFunction, F as setButtonFormWidgetsAuth, V as setCacheListByKey, C as setProcessWidgetsAuth, R as toArray, w as valueStrategy, P as wrapStyles };
