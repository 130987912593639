/*
 * @Author: zbq
 * @description: 文件描述
 * @Date: 2022-05-27 10:05:48
 * @LastEditors: zbq
 * @LastEditTime: 2022-05-27 10:15:13
 */
import authHelper from '../lib/auth.helper'
import nProgress from 'nprogress'
import { redirectLogin } from '../lib/permission'
import useAuth from '../store/auth'

const enableAuthentication = true

export const getNoPermOption = () => {
  const { clearUserInfo } = useAuth()
  clearUserInfo()
  redirectLogin()
}

const authentication = async (to, from, next) => {
  let token = authHelper.getUserInfo().token
  const { getTokenFromCode, whiteList, getUserInfo } = useAuth()

  if (to.path === '/login' || whiteList.includes(to.path)) {
    next()
    return
  }

  if (!token) {
    if (to.query.code) {
      await getTokenFromCode(to.query.code)
      next({
        path: '/workbench',
        query: {},
      })
    } else {
      getNoPermOption()
    }
  } else {
    try {
      await getUserInfo()
    } catch (error) {
      console.log('%cpermission.js line:37 error', 'color: #007acc;', error)
    }
    next()
  }
}

const permission = (router) => {
  router.beforeEach(() => {
    nProgress.start()
  })
  if (enableAuthentication) {
    router.beforeEach(authentication)
  }

  router.afterEach(() => {
    nProgress.done()
  })
}

export default permission
