import { ref as e, onMounted as t, computed as a, onUnmounted as l, reactive as o, unref as r, watch as n } from "vue";

import { useVModel as i } from "@vueuse/core";

import u from "lodash";

import { safeCallFunction as s, isAvailableData as d, hasContentArray as v, toArray as c, nanoid as p } from "@syc-paas/utils";

import { useSdk as m, WidgetTree as g, WidgetType as h, OptionSetCode as f, useVariableResolver as y, useCacheGroup as S, SELECT_DATA_SOURCE_TYPE as C, RELATION_FORM_IN_SUB_FORM_MAIN_KEY as D, useCache as w, useLibraryManager as b, basicSortComponentsOptions as E, RELATION_FORM_CONDITION_KEY as F, RELATION_FORM_CONDITION_OTHER_KEY as N, BaseCache as T, PageEngine as O, DATETIME_SELECT_RANGE_FIELD_NAME_MAP as I, JsonRuleExecution as L, designerEventNameMap as A } from "@syc-paas/core";

import { SsuMessage as P } from "@syc-saas/ui";

import R from "mitt";

import { useRemote as G, useOptionSet as V } from "@syc-paas/hooks";

import M from "dayjs";

import W from "axios";

const k = (t, a) => {
    const l = i(t), o = e(), r = () => o.value.validate(), n = () => o.value.resetFields();
    return a({
        validate: r,
        reset: n
    }), {
        data: l,
        formRef: o,
        validate: r,
        reset: n
    };
}, U = e => e ? u.pick(e, "result", "total") : {
    result: [],
    total: 0
}, _ = (a, {onError: l = u.noop, onSuccess: o = u.noop, onCancel: r = u.noop, transform: n = (e => e), initialValue: i = {}, auto: d = !1, sdk: v = null}) => {
    const c = e(!1), p = v || m(), g = e(i), h = e(u.noop), f = (e = {}) => {
        const {cancelToken: t, cancel: r} = p.createCancelToken();
        h.value = r, c.value = !0;
        return (Array.isArray(a) ? Promise.all(a.map((a => s(a, Promise.resolve({}), {
            ...e,
            cancelToken: t
        })))) : s(a, Promise.resolve({}), {
            ...e,
            cancelToken: t
        })).then((e => {
            const t = s(n, e, e);
            return g.value = t, s(o, null, t), Promise.resolve(t);
        })).catch((e => (s(l, null, e), Promise.reject(e)))).finally((() => {
            c.value = !1;
        }));
    };
    return t((() => {
        !0 === d && f();
    })), {
        cancel: () => {
            s(r), h.value();
        },
        isLoading: c,
        data: g,
        start: f
    };
}, $ = (e = !0) => ({
    success: t => {
        const a = u.isString(t) ? {
            message: t
        } : t;
        P({
            ...a,
            showClose: e,
            type: "success"
        });
    },
    error: t => {
        const a = u.isString(t) ? {
            message: t
        } : t;
        P({
            ...a,
            showClose: e,
            type: "error"
        });
    },
    warning: t => {
        const a = u.isString(t) ? {
            message: t
        } : t;
        P({
            ...a,
            showClose: e,
            type: "warning"
        });
    },
    info: t => {
        const a = u.isString(t) ? {
            message: t
        } : t;
        P({
            ...a,
            showClose: e,
            type: "info"
        });
    }
}), x = (t = "pc") => {
    const l = e(t);
    return {
        isPc: a((() => "pc" === l.value)),
        isMobile: a((() => "mobile" === l.value)),
        activeDevice: l,
        toggleDevice: e => {
            l.value = e.value;
        }
    };
}, J = (e, t, l) => {
    const o = [ "props", t, "rawValue" ];
    return a({
        get: () => (e.props[t] || (e.props[t] = {
            rawValue: l
        }), u.get(e, o)),
        set(a) {
            e.props[t] || (e.props[t] = {
                rawValue: l
            }), e.props[t].rawValue = a;
        }
    });
}, z = (e, t, l, o = !1) => {
    const r = u.get(e, [ "props", t ]);
    return (u.isNil(l) && o || !u.isNil(l)) && !r && (e.props[t] = {
        rawValue: l,
        key: t,
        type: "raw"
    }), a({
        get() {
            var a;
            return null === (a = u.get(e, [ "props", t ])) || void 0 === a ? void 0 : a.rawValue;
        },
        set(a) {
            r ? e.props[t].rawValue = a : e.props[t] = {
                rawValue: a,
                key: t,
                type: "raw"
            };
        }
    });
}, B = () => {
    const e = R();
    return l((() => {
        e.all.clear();
    })), e;
}, H = (t, a = "dialog") => {
    t = t || B();
    const l = e(!1);
    return {
        open: e => {
            l.value = !0, t.emit(`${a}.open`, e);
        },
        close: e => {
            l.value = !1, t.emit(`${a}.false`, e);
        },
        visible: l,
        evt: t
    };
}, j = (e, l) => {
    let n = m();
    const i = o({
        systemWidgets: [],
        widgets: []
    });
    function s(t) {
        n = n || t;
        const a = (t, a) => n.metaData.getPageMetaData({
            appId: l,
            pageCode: e,
            cache: !1
        }).then((e => {
            if (e && e.content && e.content.widgets.length > 0) {
                const a = e.content.widgets.concat(e.content.systemAttributes) || [];
                t && t(a), i.widgets = e.content.widgets || [], i.systemWidgets = e.content.systemAttributes || [];
            } else a && a();
        }));
        if (t) return new Promise(((e, t) => {
            a(e, t);
        }));
        a();
    }
    t((() => {
        s();
    }));
    const d = a((() => new g(i.widgets, i.systemWidgets))), v = a((() => {
        const e = [];
        return r(d).each(((t, a, l, o) => {
            t.widgetType !== h.CONTAINER && e.push(t);
        })), f(e);
    })), c = a((() => {
        const e = r(d).getFormWidgets();
        return f(e, "fieldName");
    })), p = a((() => [ ...i.widgets, ...i.systemWidgets ])), f = (e = p.value, t = "id") => (null != e ? e : []).map((e => ({
        value: e[t],
        label: u.get(e, [ "name", "rawValue" ]),
        widget: e
    }))), y = a((() => i.widgets));
    return {
        contentData: c,
        widgets: p,
        widgetsWithoutSystem: y,
        actionSelectableWidgetList: v,
        widgetsToOptions: f,
        init: s
    };
}, K = (t, a = u.noop, l) => {
    const o = e(!1);
    n((() => t.value), ((e, t) => {
        !u.isEmpty(e) && d(e) && (o.value || (o.value = !0, a(e, t)));
    }), l);
}, Q = e => t => {
    const a = e.value;
    if (u.isNil(a) || !u.isObject(a)) return !0;
    const {enable: l, include: o, exclude: r} = a;
    return !!l && (v(o) ? !!o.includes(t) : !v(r) || !r.includes(t));
}, Y = () => ({
    getExtraValueUpdateWatcher: (e = {}, t) => {
        var a, l;
        const o = (null === (a = e.defineMetaData) || void 0 === a ? void 0 : a.extraValues) || [];
        if (!o.length) return {};
        const r = e.fieldName || (null === (l = e.metaData) || void 0 === l ? void 0 : l.fieldName) || e.prop;
        return o.reduce(((e, a) => ({
            ...e,
            [`onUpdate:${r}${a.fieldName}`]: e => {
                t && (t[`${r}${a.fieldName}`] = e || (null == a ? void 0 : a.defaultValue));
            }
        })), {});
    }
}), q = () => {
    const e = m();
    return {
        handleGetDataByRemoteUrl: (t, a, l = {}) => {
            if (!t) return Promise.reject("无 remoteUrl");
            let o = {
                url: t,
                method: "post",
                timeout: 5e3,
                ...l
            };
            return a && (o.body = a.value), e.request(o).then((e => {
                if (u.isArray(e)) return e;
                P({
                    type: "warning",
                    message: "数据返回结构错误"
                });
            }));
        },
        handleGetModelNodeNameList: t => e.process.getModelNodeEvent({
            modelCode: t
        }).then((e => u.uniqBy(e, (e => null == e ? void 0 : e.nodeName)).map((e => ({
            label: null == e ? void 0 : e.nodeName,
            value: null == e ? void 0 : e.nodeName
        }))))),
        handleGetModelStatus: t => e.process.getModelStatus({
            modelCode: t
        }).then((e => e.map((e => ({
            label: e,
            value: e
        })))))
    };
}, Z = (e, a) => {
    const o = () => {
        t((() => {})), l((() => {}));
    };
    (null == e ? void 0 : e.value) && o(), n((() => null == e ? void 0 : e.value), (() => {
        (null == e ? void 0 : e.value) && o();
    }));
}, X = () => {
    const e = m(), t = [ f.MODEL, f.PRODUCTION_LINE ];
    f.PRODUCTION_LINE, f.MODEL;
    return {
        getOptionSetFirstOptions: async (a, l, o = !1, r = null) => {
            if (!l) return;
            let n = o ? e.metaData.getOpenCascadeSetByCode : e.metaData.getCustomSetByCode;
            const i = await n(a || "0", l, r), {optionList: u, isEnableOptionCode: s} = i || {};
            return u ? u.map((e => {
                var t;
                const {optionCode: a, optionName: l, id: o} = e, r = l;
                return {
                    ...e,
                    label: r,
                    value: s ? a : l,
                    id: o,
                    code: null !== (t = null != a ? a : l) && void 0 !== t ? t : o
                };
            })).filter((({enable: e}) => !!t.includes(l) || e)) : [];
        }
    };
}, ee = l => {
    const {handleGetDataByRemoteUrl: o} = G(), {getOptionSetFirstOptions: i} = V(), u = a((() => l.variables)), s = y(u), d = e([]), c = e(!1), p = a((() => {
        if (l.allowParam && v(l.remoteUrlRequestParams)) {
            return r(s).resolveByKeyValueArray2Object(l.remoteUrlRequestParams);
        }
    })), m = S("dictionary"), g = a((() => m.value.toList() || [])), h = async () => {
        const {CUSTOM: e, OPTION_SET: t, REMOTE: a} = C;
        if (l.optionsType !== e && l.optionsType) {
            if (l.optionsType === a) try {
                c.value = !0, d.value = await o(l.remoteUrl, p);
            } finally {
                c.value = !1;
            }
            if (l.optionsType !== t) c.value = !1; else {
                if (!l.optionSetValue) return void (d.value = []);
                const e = g.value.find((e => e.dictCode === l.optionSetValue));
                var r;
                if (null != e && e.items) return void (d.value = (null == e || null === (r = e.items) || void 0 === r ? void 0 : r.map((e => {
                    var t, a, l;
                    return {
                        ...e,
                        label: null !== (t = null !== (a = e.label) && void 0 !== a ? a : e.optionName) && void 0 !== t ? t : e.name,
                        value: null !== (l = e.value) && void 0 !== l ? l : e.optionCode
                    };
                }))) || []);
                try {
                    c.value = !0, d.value = await i(l.appId, l.optionSetValue, l.isPublicRelease, l.publicReleaseShareCode), 
                    d.value = d.value.map((e => {
                        var t, a, l;
                        return {
                            ...e,
                            label: null !== (t = null !== (a = e.label) && void 0 !== a ? a : e.optionName) && void 0 !== t ? t : e.name,
                            value: null !== (l = e.value) && void 0 !== l ? l : e.optionCode
                        };
                    }));
                } finally {
                    c.value = !1;
                }
            }
        } else d.value = l.options || [];
    };
    return n((() => [ l.options, l.optionsType, l.optionSetValue ]), (() => {
        h();
    })), t((() => {
        h();
    })), {
        curOptions: d,
        loading: c
    };
}, te = (t = u.noop) => {
    const a = e(!1);
    return {
        loading: a,
        run: async (...e) => {
            a.value = !0;
            try {
                return await t(...e);
            } catch (e) {
                throw e;
            } finally {
                a.value = !1;
            }
        }
    };
}, ae = (t, l = {}) => {
    const {service: o, requestOptions: r, key: i, onSuccess: v} = u.merge({
        key: "data",
        service: () => Promise.resolve(null)
    }, l), c = e(null), p = a((() => t[i])), {start: m, isLoading: g} = _(o, {
        ...r,
        onSuccess(e) {
            c.value = e;
        }
    });
    return n((() => p.value), (async (e, t) => {
        d(e) ? c.value = u.cloneDeep(e) : await m(), s(v, null, c.value);
    }), {
        immediate: !0,
        deep: !0
    }), {
        isLoading: g,
        data: c
    };
}, le = (e, t = []) => (t = Array.isArray(t) ? t : [ t ]).reduce(((t, l) => ({
    ...t,
    [l]: a((() => e[l]))
})), {}), oe = () => {
    const e = (t, a = {}, l = {}) => (t = u.cloneDeep(t), u.isArray(t) && t.length ? (t.rules && (t.rules = e(t, a)), 
    t.forEach((e => {
        var t, o;
        if ("field" === e.valueType) if (e.valueField = e.valueField || e.value, null != l && l.inSubForm && null !== (t = e.value) && void 0 !== t && null !== (o = t.startsWith) && void 0 !== o && o.call(t, D)) {
            var r, n;
            const t = u.last(e.valueField.split(".").filter(Boolean));
            e.value = null != l && null !== (r = l.mainFormData) && void 0 !== r && r[t] ? null == l || null === (n = l.mainFormData) || void 0 === n ? void 0 : n[t] : void 0;
        } else e.value = a[e.valueField] ? a[e.valueField] : void 0;
    })), t) : null), t = (e = []) => {
        if (!u.isArray(e) || null == e || !e.length) return !1;
        let a = !1;
        return e.forEach((e => {
            e.rules && t(e.rules) && (a = !0), "field" === e.valueType && (a = !0);
        })), a;
    };
    return {
        conditionRulesAdaptor: e,
        checkConditionRulesFieldType: t
    };
}, re = e => {
    const t = w(), l = a((() => t.getOrAddGroup(`${e.value}MetaData`))), o = a((() => t.getOrAddGroup(`${e.value}Widgets`))), r = a((() => t.getOrAddGroup(`${e.value}SystemAttributes`)));
    return {
        metaDataCacheGroup: l,
        widgetsCacheGroup: o,
        titleFieldCacheGroup: a((() => t.getOrAddGroup(`${e.value}TitleField`))),
        systemAttributesCacheGroup: r,
        relationFormListCacheGroup: a((() => t.getOrAddGroup(`${e.value}RelationFormListData`))),
        clearWidgetsCacheGroup: () => {
            t.deleteGroup(`${e.value}MetaData`);
        },
        clearSystemAttributesCacheGroup: () => {
            t.deleteGroup(`${e.value}SystemAttributes`);
        },
        clearRelationFormListCacheGroup: () => {
            t.deleteGroup(`${e.value}RelationFormListData`);
        }
    };
}, ne = (l, o, r) => {
    const i = m(), s = b(), {widgetsCacheGroup: d, systemAttributesCacheGroup: v, relationFormListCacheGroup: p, titleFieldCacheGroup: g, metaDataCacheGroup: h} = re(o), f = e([]), y = e([]), S = e(null), C = e(!1), D = a((() => {
        var e, t;
        return null === (e = g.value) || void 0 === e || null === (t = e.getData("titleField")) || void 0 === t ? void 0 : t.titleField;
    })), w = a((() => {
        if (D.value) {
            var e;
            if (null !== (e = r.value) && void 0 !== e && e.length) {
                const e = r.value.findIndex((e => e === D.value));
                return e > 0 && (r.value.unshift(r.value[e]), r.value.splice(e + 1, 1)), r.value;
            }
            {
                var t, a;
                const e = ((null === (t = S.value) || void 0 === t ? void 0 : t.toColumnList()) || []).find((e => {
                    var t;
                    return (null == e || null === (t = e.metaData) || void 0 === t ? void 0 : t.fieldName) === D.value;
                }));
                return c(null === (a = e.metaData) || void 0 === a ? void 0 : a.fieldName);
            }
        }
        return r.value || [];
    })), F = a((() => {
        var e;
        const t = (null === (e = S.value) || void 0 === e ? void 0 : e.toColumnList()) || [];
        return w.value.map((e => t.find((t => {
            var a;
            return (null == t || null === (a = t.metaData) || void 0 === a ? void 0 : a.fieldName) === e;
        })))).filter((e => !!e)).map((e => {
            var t;
            let a = E.includes(null == e || null === (t = e.defineMetaData) || void 0 === t ? void 0 : t.code);
            return {
                ...e,
                sortable: a
            };
        }));
    })), N = (e, t) => {
        var a, l, o;
        if (e && t) {
            var r, n, c, p;
            if (null !== (a = d.value) && void 0 !== a && null !== (l = a.toList) && void 0 !== l && null !== (o = l.call(a)) && void 0 !== o && o.length) return f.value = (null === (r = d.value) || void 0 === r || null === (n = r.toList) || void 0 === n ? void 0 : n.call(r)) || [], 
            y.value = (null === (c = v.value) || void 0 === c || null === (p = c.toList) || void 0 === p ? void 0 : p.call(c)) || [], 
            void (S.value = new O({
                libManager: s,
                widgets: f.value,
                messages: [],
                systemWidgets: y.value,
                language: "zh-cn"
            }));
            C.value || (C.value = !0, ((e, t) => {
                const a = h.value.getData(`${t}MetaData`);
                if (a) {
                    var l;
                    const e = null === (l = a.getData("metaData")) || void 0 === l ? void 0 : l.metaData;
                    if (!u.isEmpty(e)) return Promise.resolve(e);
                }
                return i.metaData.getPageMetaData({
                    appId: e,
                    pageCode: t
                });
            })(e, t).then((e => {
                var a;
                const {widgets: l, systemAttributes: o, titleField: r} = (null == e ? void 0 : e.content) || {};
                h.value.addCache({
                    cacheKey: t,
                    metaData: e,
                    id: "metaData"
                }), y.value = o || [], f.value = l || [], f.value.forEach((e => {
                    var t, a;
                    null === (t = d.value) || void 0 === t || null === (a = t.addCache) || void 0 === a || a.call(t, {
                        ...e
                    });
                })), null === (a = g.value) || void 0 === a || a.addCache({
                    cacheKey: "titleField",
                    titleField: r,
                    id: "titleField"
                }), y.value.forEach((e => {
                    var t, a;
                    null === (t = v.value) || void 0 === t || null === (a = t.addCache) || void 0 === a || a.call(t, {
                        ...e
                    });
                })), S.value = new O({
                    libManager: s,
                    widgets: l || [],
                    messages: [],
                    systemWidgets: o || [],
                    language: "zh-cn"
                });
            })).finally((() => {
                C.value = !1;
            })));
        }
    };
    return n((() => [ l.value, o.value ]), (() => {
        N(l.value, o.value);
    })), t((() => {
        N(l.value, o.value);
    })), {
        libManager: s,
        relationFormFieldList: f,
        relationFormSystemFieldList: y,
        pageEngine: S,
        formFieldLoading: C,
        initFormFieldList: N,
        relationTableColumnList: F,
        relationFormListCacheGroup: p,
        titleField: D
    };
}, ie = (t, l, o, r, n) => {
    var i;
    const {relationFormListCacheGroup: s} = re(l), d = m(), {conditionRulesAdaptor: v, checkConditionRulesFieldType: c} = oe(), p = a((() => {
        var e;
        const t = JSON.parse(JSON.stringify(null !== (e = o.value) && void 0 !== e ? e : {}).replaceAll(`${F}.`, "").replaceAll(`${N}.`, ""));
        if (!c(null == t ? void 0 : t.rules)) return t;
        const a = v(null == t ? void 0 : t.rules, r.formData, {
            inSubForm: r.inSubForm,
            mainFormData: r.mainFormData
        });
        return {
            ...t,
            rules: a
        };
    })), g = e({}), h = e(!1), f = e([]), y = e((null === (i = s.value) || void 0 === i ? void 0 : i.toList()) || []), S = e(0), C = e({
        pageNum: 1,
        pageSize: 10
    }), D = e([]), w = new T("initRelationFormQueryData", 0), b = (e, a = "replace", o = {}) => (r => {
        if (!t.value || !l.value) return Promise.resolve({});
        let i;
        u.isEmpty(e) || (i = null != e && e.condition ? e : {
            condition: "AND",
            ...e
        }), h.value = !0;
        const v = {
            appId: t.value,
            modelCode: l.value,
            ...C.value,
            filter: i,
            ...o
        };
        if (w.has(v)) {
            const e = w.get(v);
            return h.value = !1, r && r(), Promise.resolve((() => e));
        }
        return d.form.getListWithoutScene(v).then((e => (w.set(v, e), ((e, t) => {
            const {result: a = [], total: l} = t, o = a || [];
            null == n || n.emit("dataSourceUpdate", o), "replace" === e && (y.value = o), "push" === e && y.value.push(...o), 
            "unshift" === e && y.value.unshift(...o), S.value = l, o.forEach((e => {
                s.value.addCache({
                    cheKey: e.id,
                    ...e
                });
            }));
        })(a, e), e))).finally((() => (h.value = !1, r && r())));
    })();
    return {
        relationFormListCacheGroup: s,
        relationFormCondition: p,
        relationFormDataLoading: h,
        relationFormQuery: C,
        relationFormQueryData: y,
        relationFormSelectedData: D,
        relationFormQueryDataTotal: S,
        filterRelationFormQueryData: f,
        initRelationFormQueryData: b,
        handlePageChange: e => {
            var t;
            C.value.pageNum = e;
            const a = {
                ...p.value,
                rules: ((null === (t = p.value) || void 0 === t ? void 0 : t.rules) || []).concat(g.value.rules || [])
            };
            b(a, void 0, {
                cache: !1
            });
        },
        handleSortChange: ({prop: e, order: t}) => {
            var a;
            C.value.sort = t ? `${e}${"descending" == t ? "-" : "+"}` : void 0;
            const l = {
                ...p.value,
                rules: ((null === (a = p.value) || void 0 === a ? void 0 : a.rules) || []).concat(g.value.rules || [])
            };
            b(l, void 0, {
                cache: !1
            });
        },
        handlePageSizeChange: e => {
            var t;
            C.value.pageSize = e, C.value.pageNum = 1;
            const a = {
                ...p.value,
                rules: ((null === (t = p.value) || void 0 === t ? void 0 : t.rules) || []).concat(g.value.rules || [])
            };
            b(a, void 0, {
                cache: !1
            });
        },
        handleKeyWordFilter: e => {
            var t;
            C.value.keyword = e;
            const a = {
                ...p.value,
                rules: ((null === (t = p.value) || void 0 === t ? void 0 : t.rules) || []).concat(g.value.rules || [])
            };
            b(a, void 0, {
                cache: !1
            });
        },
        handleFilter: (e = {}) => {
            var t;
            g.value = e;
            const a = {
                ...p.value,
                rules: ((null === (t = p.value) || void 0 === t ? void 0 : t.rules) || []).concat(g.value.rules || [])
            };
            b(a, void 0, {
                cache: !1
            });
        },
        handleRefresh: () => {
            var e;
            const t = {
                ...p.value,
                rules: ((null === (e = p.value) || void 0 === e ? void 0 : e.rules) || []).concat(g.value.rules || [])
            };
            b(t, void 0, {
                cache: !1
            });
        },
        handleGetSelectedList: (e = []) => {
            D.value = e;
        }
    };
}, ue = "sub_form_", se = e => e.indexOf(ue) >= 0, de = (e, t, a = {}, l = {}) => {
    const o = t => {
        const o = se(t);
        return t = (e => se(e) ? e.replace(ue, "") : e)(t), e ? o ? a[t] : l[t] : a[t];
    }, r = [ -1 / 0, 1 / 0 ], n = (e, a, l) => {
        if (null == t || !t[a]) return;
        const {createTime: n, currentTime: i, fixedTime: u, updateTime: s} = I, {isEnable: d, valueType: v, value: c} = t[a];
        if (!d || !v) return;
        let p = l;
        const m = [ [ i, Date.now() ], [ n, o(n) ], [ s, o(s) ], [ u, c ] ].find((([e]) => e === v));
        if (m) {
            const [, e] = m;
            p = e || l;
        } else p = o(v) || l;
        r[e] = ((e, t) => "startTime" === t ? e === -1 / 0 ? e : M(e).startOf("day") : "endTime" === t ? e === 1 / 0 ? e : M(e).endOf("day") : void 0)(p, a);
    };
    return n(0, "startTime", -1 / 0), n(1, "endTime", 1 / 0), r;
}, ve = (a = e(!1), l = e({}), o = e({}), i = e({})) => {
    const s = e([ -1 / 0, 1 / 0 ]), d = () => {
        const e = r(l), t = r(o), n = r(a), u = r(i);
        s.value = de(n, e, t, u);
    };
    return t((() => {
        d();
    })), n((() => [ null == a ? void 0 : a.value, null == o ? void 0 : o.value, null == l ? void 0 : l.value, null == i ? void 0 : i.value ]), u.debounce((() => {
        d();
    }), 100), {
        deep: !0
    }), {
        selectTimeList: s
    };
}, ce = (e, t, l) => {
    const o = b(), n = a((() => new L({
        widgets: r(t),
        libraryManager: o
    }))), i = a((() => {
        var t;
        const a = r(e), o = r(l);
        return (null == a || null === (t = a.map) || void 0 === t ? void 0 : t.call(a, (e => {
            var t, a, l;
            return {
                result: (null == e || null === (t = e.conditionMap) || void 0 === t || null === (a = t.rules) || void 0 === a ? void 0 : a.length) && (null == n || null === (l = n.value) || void 0 === l ? void 0 : l.execute({
                    jsonRule: null == e ? void 0 : e.conditionMap,
                    data: o
                })),
                color: e.color
            };
        }))) || [];
    })), u = a((() => {
        var e;
        return (null === (e = i.value.find((e => e.result))) || void 0 === e ? void 0 : e.color) || "";
    }));
    return {
        displayStyleRulesResultList: i,
        displayStyleRulesColorResult: u
    };
}, pe = () => {
    let e = [], t = new Set, a = [];
    const l = a => {
        t.has(a) || (t.add(a), e.push({
            id: a,
            type: "dept"
        }));
    };
    return {
        getRange: ({range: o, userData: r, formValue: n}) => null != o && o.length ? (e = [], 
        t = new Set, a = [], o.forEach((t => {
            switch (t.type) {
              case "modelField":
                ((t, o) => {
                    var r;
                    switch (o.id) {
                      case "{{currentUserDept}}":
                        null == t || null === (r = t.current_dept) || void 0 === r || r.forEach((e => {
                            l(e.id);
                        }));
                        break;

                      case "{{currentUser}}":
                        e.push({
                            type: "personal",
                            id: t.current_user.id
                        });
                        break;

                      default:
                        a.push(o);
                    }
                })(r, t);
                break;

              case "dept":
                l(t.id);
                break;

              default:
                e.push({
                    id: t.id,
                    type: t.type
                });
            }
        })), a.length ? (a.forEach((e => {
            var t;
            null !== (t = n[e.id]) && void 0 !== t && t.length ? n[e.id].forEach((e => {
                l(e);
            })) : l(null);
        })), e) : e) : []
    };
}, me = [ "QRCode" ], ge = () => ({
    createTabs: (e = []) => {
        let t = [];
        return v(e) && (t = e.map((e => ({
            ...e,
            key: p()
        })))), t;
    },
    formatLogResult: (e = []) => {
        let t = [];
        const a = {};
        return e.forEach((e => {
            const t = M.unix(e.operationTime).format("YYYY-MM-DD");
            u.isEmpty(a[t]) && (a[t] = {
                date: t,
                times: []
            });
            const l = {
                time: M.unix(e.operationTime).format("HH:mm:ss"),
                operator: e.operator,
                changeData: e.changeData,
                traceId: e.traceId
            };
            a[t].times.push(l);
        })), u.isEmpty(a) || Object.values(a).forEach((e => {
            t.push(e);
        })), t;
    },
    jsonToParse: e => JSON.parse(e),
    formatSubFormData: e => {
        let t = [], a = [];
        const l = {};
        return e.forEach((e => {
            if (v(e.changeData)) {
                let t = {};
                return e.changeData.forEach((e => {
                    l[e.attributeCode] || (l[e.attributeCode] = {}), l[e.attributeCode] = {
                        label: e.fieldDescription,
                        prop: e.attributeCode
                    }, t[e.attributeCode] = e;
                })), t.eventType = e.eventType, void a.push(t);
            }
        })), u.isEmpty(l) || Object.values(l).forEach((e => {
            t.push(e);
        })), {
            head: t,
            body: a
        };
    },
    formatTableHeadData: (e, t) => {
        let a = [], l = [];
        if (new g(e).each((e => {
            me.includes(e.code) || l.push(e);
        })), v(l)) {
            const e = l.find((e => e.fieldName === t)), o = u.result(e, "props.fields.rawValue");
            v(o) && (a = o.map((e => {
                if (!me.includes(e.code)) return {
                    prop: e.id,
                    label: e.name.rawValue
                };
            })).filter(Boolean));
        }
        return a;
    }
}), he = {}, fe = () => ({
    createDesignerEvent: () => {
        const {SAVE: e, PREVIEW: t} = A;
        he[e] = new Event(e), he[t] = new Event(t);
    },
    notifyDesignerEvent: e => {
        const t = he[e];
        t && document.dispatchEvent(t);
    }
}), ye = process.env.VUE_VIEW_COLUMN_WIDTH || "VIEW_COLUMN_WIDTH", Se = process.env.VUE_VIEW_ACTIONS_ELLIPSIS || "VIEW_ACTIONS_ELLIPSIS", Ce = process.env.VUE_VIEW_PAGE_SIZE || "VIEW_PAGE_SIZE", De = (e, t) => ({
    setColumnWidth: (a, l) => {
        try {
            const o = JSON.parse(localStorage.getItem(ye) || "{}");
            o[e] || (o[e] = {}), o[e][t] || (o[e][t] = {}), o[e][t][a] = l, localStorage.setItem(ye, JSON.stringify(o));
        } catch {
            return l;
        }
    },
    getColumnWidth: a => {
        try {
            var l, o;
            return null === (l = JSON.parse(localStorage.getItem(ye) || "{}")[e]) || void 0 === l || null === (o = l[t]) || void 0 === o ? void 0 : o[a];
        } catch {
            return;
        }
    }
}), we = (e, t = "list") => ({
    setActionsEllipsis: a => {
        try {
            const l = JSON.parse(localStorage.getItem(Se) || "{}");
            u.isNil(l[e]) && (l[e] = {}), l[e][t] = a, localStorage.setItem(Se, JSON.stringify(l));
        } catch {
            return a;
        }
    },
    getActionsEllipsis: () => {
        try {
            var a;
            return null === (a = JSON.parse(localStorage.getItem(Se) || "{}")[e]) || void 0 === a ? void 0 : a[t];
        } catch {
            return null;
        }
    }
}), be = (e, t = "list") => ({
    setPageSize: a => {
        try {
            const l = JSON.parse(localStorage.getItem(Ce) || "{}");
            u.isNil(l[e]) && (l[e] = {}), l[e][t] = a, localStorage.setItem(Ce, JSON.stringify(l));
        } catch {
            return a;
        }
    },
    getPageSize: () => {
        try {
            var a;
            return null === (a = JSON.parse(localStorage.getItem(Ce) || "{}")[e]) || void 0 === a ? void 0 : a[t];
        } catch {
            return null;
        }
    }
}), Ee = t => {
    const a = e(null);
    return [ a, e => {
        a.value = t ? e[t] : e;
    } ];
}, Fe = (e, t) => {
    if (e) return (a, l = !1, o = !1) => {
        if (a) return new Promise(((l, r) => {
            var n, i;
            let u = a.name.lastIndexOf(".");
            a.name.substring(u + 1);
            const s = o ? null === (i = e.upload) || void 0 === i ? void 0 : i.getUploadParamsUnlogin : null === (n = e.upload) || void 0 === n ? void 0 : n.getUploadParams;
            null == s || s({
                fileName: a.name,
                fileSize: a.size,
                businessCode: t
            }).then((e => {
                let t = e, o = new FormData, n = t.url;
                o.append("policy", t.policy), t.contentType && o.append("Content-Type", String(t.contentType)), 
                o.append("AccessKeyId", t.accessKeyId), o.append("signature", t.signature), o.append("key", t.key), 
                o.append("file", a), W.post(n, o, {
                    headers: {
                        Authorization: t.headerAuthorization
                    },
                    cancelToken: new W.CancelToken((function(e) {}))
                }).then((() => {
                    l(t.fileUrl, t);
                })).catch((e => {
                    r(e);
                }));
            })).catch((e => {
                r(e);
            }));
        }));
    };
}, Ne = e => {
    const t = m();
    return {
        uploadHWCloud: Fe(t, e)
    };
}, Te = (a = 14) => {
    const o = e(null);
    return t((() => {
        o.value = document.createElement("canvas");
    })), l((() => {
        o.value = null;
    })), {
        countStrWidth: (e, t = a) => {
            var l;
            const r = null === (l = o.value) || void 0 === l ? void 0 : l.getContext("2d");
            if (!r) return String(null != e ? e : "").length * t;
            r.font = `${t}px Microsoft YaHei,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif`;
            return r.measureText(e).width;
        }
    };
};

export { Fe as createUploadHwCloud, de as getDatetimeRangeSelectTimeList, U as pagerTransformer, Ee as useActive, te as useAsync, oe as useConditionData, ae as useDataOrRequest, ve as useDatetimeRangeSelect, fe as useDesignerEvent, x as useDevice, H as useDialog, ce as useDisplayStyleRules, k as useElForm, $ as useElMessage, Q as useFilterPriority, Y as useFormExtraValue, j as useFormSetting, B as useMitt, K as useOnceWatch, X as useOptionSet, ee as useOptions, pe as usePersonRange, le as usePropsComputed, re as useRelationFormCache, ne as useRelationFormField, ie as useRelationFormQueryData, q as useRemote, ge as useRuntimeTableProcessReminderUtils, _ as useSdkRequest, Te as useStringLength, we as useTableActions, De as useTableColumnWidth, be as useTablePageSize, Ne as useUpload, J as useWidgetProp, z as useWidgetPropCompat, Z as useWindowClick };
