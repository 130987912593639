import _ from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'
import { createLiteStore, useSdk } from '@syc-paas/core'

// import paasClient from '@/lib/paasClient'
// import { useQueries } from 'vue-query'
import { useRoute } from 'vue-router'
import { useSdkRequest } from '@syc-paas/hooks'

const useData = () => {
  const route = useRoute()
  const sdk = useSdk()
  const { automationId, appId } = route.params
  const {
    data: pageData,
    isLoading,
    start: fetchFlowDetail,
  } = useSdkRequest(
    () => {
      return sdk.metaData.getRoboterFlowDetails({
        appId,
        automationId: automationId,
      })
    },
    { auto: true }
  )

  // const isLoading = computed(() => data.some(item => item.isLoading))
  return {
    pageData,
    isLoading,
    fetchFlowDetail,
  }
}
// const useData = () => {
//   const route = useRoute()

//   const { automationId, appId } = route.params
//   const data = useQueries([
//     {
//       queryFn: () => {
//         return paasClient.metaData.getRoboterFlowDetails({
//           appId,
//           automationId: automationId,
//         })
//       },
//     },
//   ])
//   const pageData = ref({})
//   watch(
//     () => data,
//     () => {
//       if (_.isEmpty(pageData.value)) {
//         pageData.value = data[0].data
//       } else {
//         pageData.value.name = data[0].data?.name
//       }
//     },
//     { deep: true }
//   )

//   const isLoading = computed(() => data.some((item) => item.isLoading))
//   return {
//     pageData,
//     isLoading,
//   }
// }

const useRenderButton = () => {
  const renderButton = ref(() => {})
  const route = useRoute()
  const setRenderButton = (fn = _.noop) => {
    renderButton.value = fn
  }
  watch(
    () => route.path,
    () => {
      setRenderButton(() => null)
    }
  )
  return { renderButton, setRenderButton }
}

export const AutomationLayoutStore = createLiteStore(() => {
  const { pageData, isLoading, fetchFlowDetail } = useData()
  return {
    pageData,
    isLoading,
    fetchFlowDetail,
    ...useRenderButton(),
  }
}, 'automationLayout')

export const useAutomationLayoutStore = () => AutomationLayoutStore.useInject()
