/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-07-06 17:50:49
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:47:20
 */
 
export const commonPreviewItemProps = {
    url: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    draggable: {
        type: Boolean,
        default: false
    }
} 
