import { createI18n } from 'vue-i18n'
import en from './en'
import { optionsWrap } from '@syc-paas/utils'
import zhCn from './zh-cn'

export const Language = {
  ZH_CN: 'zh-cn',
  EN: 'en',
}

export const languageOptions = optionsWrap([
  { label: '简体中文', value: Language.ZH_CN },
  { label: 'English', value: Language.EN },
])

const i18n = createI18n({
  locale: Language.ZH_CN, // set locale
  legacy: false,
  messages: {
    [Language.ZH_CN]: zhCn,
    en,
  },
})

export default i18n
