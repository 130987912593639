const zhCn = {
  logoName: 'SYC PaaS',
  language: '语言选择',
  companyName: '广州盛原成科技有限公司',
  copyright: '@:companyName 版权所有',
  hello: '你好世界',
  button: {
    create: '创建',
    add: '新增',
    cancel: '取消',
    confirm: '确定',
    save: '保存',
    remove: '移除',
    delete: '删除',
    modify: '修改',
  },
  state: {
    success: '成功',
  },
  login: {
    welcome: '欢迎登录Paas',
    btnName: '登录',
    placeholder: {
      account: '请输入账号',
      password: '请输入密码',
    },
    ruleMessages: {
      account: {
        required: '账号不能为空',
      },
      password: {
        required: '密码不能为空',
      },
    },
    fallback: {
      success: '登录成功',
    },
  },
  workbench: {
    name: '我的平台',
    describe: '创建你的平台，让操作更便捷',
    addName: '创建平台',
    searchPlaceholder: '输入名称搜索',
    dropdown: {
      setPlatform: '平台设置',
      deletePlatform: '删除平台',
    },
    addDialog: {
      title: '创建平台',
      name: '平台名称',
      namePlaceholder: '给平台命名，如“SaaS平台”',
      nameError: '请输入平台名称',
      homePageUrl: '平台地址',
      homePageUrlPlaceholder: '请输入平台首页地址',
      icon: '平台图标',
      successMessage: '创建成功',
      errorMessage: '创建失败',
    },
    deleteDialog: {
      title: '提示',
      describe:
        '删除平台“{name}”, <i>平台下所有配置和数据将被永久删除，不可恢复。</i>请确认所有用户都不再需要此平台后，再执行此操作',
      name: '请输入平台名称，以确认删除此平台',
      nameError: '无法匹配平台名称',
      successMessage: '删除成功',
      errorMessage: '删除失败',
    },
  },
  panelContainer: {
    noDataText: '暂无数据',
  },
  platformConfigIndex: {
    title: '平台设置',
    baseInfo: '基础信息',
    platformAdmin: '平台管理员',
    platformRuntimeEnv: '环境管理',

  },
  appManage: {
    name: '我的应用',
    describe: '创建你的应用，让操作更便捷',
    addName: '创建应用',
    searchPlaceholder: '输入名称搜索',
    notPlatformIdError: '请选择对应的平台',
    dropdown: {
      setApp: '应用设置',
      deleteApp: '删除应用',
    },
    addDialog: {
      title: '创建应用',
      name: '应用名称',
      namePlaceholder: '给应用命名，如“SaaS应用”',
      nameError: '请输入应用名称',
      icon: '应用图标',
      code: '应用标识',
      codePlaceholder: '请输入应用标识',
      codeTip:
        '用作应用的唯一标识。至多10字符，小写字母开头、可输入小写字母、数字、下划线等内容。',
      codeError: '字段标识只能小写字母开头、可输入小写字母、数字、下划线等内容',
      successMessage: '创建成功',
      errorMessage: '创建失败',
    },
    deleteDialog: {
      title: '提示',
      describe:
        '删除应用“{name}”, <i>应用下所有配置和数据将被永久删除，不可恢复。</i>请确认所有用户都不再需要此应用后，再执行此操作',
      name: '请输入应用名称，以确认删除此应用',
      nameError: '无法匹配应用名称',
      successMessage: '删除成功',
      errorMessage: '删除失败',
    },
  },
  appSettingsIndex: {
    title: '应用设置',
    baseInfo: '基础信息',
    functionSwitch: '功能开关',
  },
  appSettingsBaseInfo: {
    name: '应用名称',
    namePlaceholder: '给应用命名，如“销售管理”',
    nameError: '请输入应用名称',
    icon: '应用图标',
    successMessage: '保存成功',
    errorMessage: '保存失败',
    createByName: '创建人',
    createTime: '创建时间',
  },
  platformConfigBaseInfo: {
    name: '平台名称',
    namePlaceholder: '给平台命名，如“Sass平台”',
    nameError: '请输入平台名称',
    code: '平台标识',
    codePlaceholder: '请输入平台标识',
    codeError: '标识必须以小写字母开头、可输入小写字母、数字、下划线',

    type: '平台类型',
    typeError: '请选择平台类型',

    homePageUrl: '平台地址',
    homePageUrlPlaceholder: '请输入平台首页地址',
    icon: '平台图标',
    successMessage: '保存成功',
    errorMessage: '保存失败',
    createName: '创建人',
    createTime: '创建时间',
  },
  platformConfigAdmin: {
    title: '平台管理员',
    describe:
      '可设计应用及配置平台（仅平台所有者可管理平台管理员以及转让、删除平台）',
    admin: '管理者',
    owner: '所有者',
    transferButton: '转让平台',
  },
  pageManage: {
    page: '页面',
    group: '分组',
    designForm: '编辑表单',
    designDashboard: '编辑仪表盘',
    editInfo: '修改基本信息',
    pageType: {
      normalPage: '普通表单',
    },
    addPage: {
      formCode: '表单标识',
      enter: '请输入',
      codeMsg: '标识必须以小写字母开头、可输入小写字母、数字、下划线',
      select: '请选择',
      icon: '表单图标'
    },
    deletePage: {
      deleteMsg: '确认删除吗？',
    }
  },
  tenantConfig: {
    name: '用户体系',
    namePlaceholder: '请选择用户体系',
    nameError: '请选择用户',
  },
}

export default zhCn
