import {
  COOKIE_DOMAIN,
  COOKIE_TOKEN_KEY,
  COOKIE_TENANT_ID_KEY,
} from './constants'

import cookies from 'js-cookie'
export const cookieHelper = {
  get domain() {
    if (process.env.NODE_ENV === 'development') {
      return ''
    }
    return COOKIE_DOMAIN
  },
  get(key) {
    if (this.domain) {
      return cookies.get(key, {
        domain: this.domain,
      })
    }
    return cookies.get(key)
  },
  set(key, value) {
    if (this.domain) {
      return cookies.set(key, value, {
        domain: this.domain,
      })
    }
    return cookies.set(key, value)
  },
  remove(key) {
    if (this.domain) {
      cookies.remove(key, {
        domain: this.domain,
      })
      return
    }
    cookies.remove(key)
  },
}

const tokenKey = 'token'
const usernameKey = 'username'
const userIdKey = 'userId'
const tenantId = 'tenantId'
const userInfoFields = [tokenKey, userIdKey, usernameKey, tenantId]

const keyMap = {
  token: COOKIE_TOKEN_KEY,
  tenantId: COOKIE_TENANT_ID_KEY,
}

const replaceKey = (key) => {
  return keyMap[key] || key
}
const authHelper = {
  setUserInfo(data = {}) {
    Object.entries(data).forEach(([key, value]) => {
      if (userInfoFields.includes(key)) {
        const k = replaceKey(key)
        if (value) {
          cookieHelper.set(k, value)
        }
      }
    })
  },
  getUserInfo() {
    return userInfoFields.reduce((acc, key) => {
      const k = replaceKey(key)

      return {
        ...acc,
        [key]: cookieHelper.get(k) || '',
      }
    }, {})
  },
  clearUserInfo() {
    userInfoFields.forEach((key) => {
      const k = replaceKey(key)
      cookieHelper.remove(k)
    })
  },
}
export default authHelper
