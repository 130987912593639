/*
 * @Author: pzf
 * @description: 打开附件预览
 * @Date: 2022-07-06 17:50:49
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-07 09:48:17
 */

import { createVNode, render, isVNode } from 'vue' 
import SlmPreviewBox from './index.vue' 

const openPreviewBox = function (opts) {
    let options = opts

    options = {
        ...options
    }

    const container = document.createElement('div')
    const vm = createVNode(SlmPreviewBox, options, isVNode(options) ? { default: () => options } : null)
    // destroy 销毁组件
    if (vm.props) {
        vm.props.onDestroy = () => {
            render(null, container)
        }
    }
    render(vm, container)

    if (container.firstElementChild) {
        document.body.appendChild(container.firstElementChild)
    }
}

export default openPreviewBox
