import {
  AppDesignLayoutStore,
  useAppDesignLayoutStore,
} from './AppDesignLayoutStore'
import { PageType, connect } from '@syc-paas/core'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, provide } from 'vue'

import BaseHeader from '../../components/BaseHeader/BaseHeader'
import BaseHeaderBack from '../../components/BaseHeaderBack/BaseHeaderBack'
import { safeCallFunction } from '@syc-paas/utils'
import styles from './AppDesignLayout.module.scss'
import { useLayoutActiveRoute } from '../../hooks/useLayoutActiveRoute'

const AppDesignLayout = defineComponent({
  name: 'AppDesignLayout',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { pageData, isLoading, renderButton } =
      AppDesignLayoutStore.useProvide()
    const isProcessPage = computed(() => {
      return pageData.value?.type === PageType.FLOW_FORM
    })
    const isDashBoardPage = computed(() => {
      return pageData.value?.type === PageType.DASHBOARD
    })

    const menus = computed(() => {
      if (isDashBoardPage.value) {
        return [{ label: '仪表盘设计', name: 'dashboard', key: 'dashboard' }]
      }

      return [
        { label: '表单设计', name: 'form', key: 'form' },
        { label: '表单设置', name: 'form-setting', key: 'form-setting' },
        isProcessPage.value && {
          label: '流程设计',
          name: 'process',
          key: 'process',
        },
      ].filter((item) => !!item)
    })

    const layoutPrefix = computed(() => {
      const { platformId, appId, pageCode } = route.params
      return `/platform/${platformId}/app/${appId}/design/${pageCode}`
    })
    const activeTab = useLayoutActiveRoute(layoutPrefix.value)
    provide('activeTab', activeTab)
    const handelClickTab = (tab) => {
      router.push(`${layoutPrefix.value}/${tab.props.name}`)
    }

    const back = () => {
      const { platformId, appId, pageCode } = route.params
      router.push(`/platform/${platformId}/app/${appId}/page/${pageCode}`)
    }

    return () => {
      return (
        <div class={styles.container} v-loading={isLoading.value}>
          <BaseHeader
            vSlots={{
              left: () => {
                return (
                  <BaseHeaderBack name={pageData.value?.name} onClick={back} />
                )
              },
              middle: () => {
                return (
                  <ssu-tab-list
                    v-model={activeTab.value}
                    plain
                    class={styles.tabs}
                    margin={{ bottom: 0 }}
                    onTabClick={(tab) => {
                      handelClickTab(tab)
                    }}
                    list={menus.value}
                    disabledMore={true}
                  ></ssu-tab-list>
                )
              },
              right: () => {
                return (
                  <el-space>
                    {/* <ssu-button>预览</ssu-button>
                    <ssu-button type="primary">保存</ssu-button> */}
                    {safeCallFunction(renderButton.value)}
                  </el-space>
                )
              },
            }}
          />
          <div class={styles.main}>{!isLoading.value && <RouterView />}</div>
        </div>
      )
    }
  },
})

export default AppDesignLayout
