import { RouterView, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'

import { AppLayoutStore } from './AppLayoutStore'
import BaseHeader from '../../components/BaseHeader/BaseHeader'
import BaseHeaderBack from '@/components/BaseHeaderBack/BaseHeaderBack'
import _ from 'lodash'
import styles from './AppLayout.module.scss'
import { useLayoutActiveRoute } from '../../hooks/useLayoutActiveRoute'

const AppLayout = defineComponent({
  name: 'AppLayout',
  setup() {
    const route = useRoute()
    const router = useRouter()

    const menus = reactive([
      { label: '页面管理', name: 'page' },
      { label: '自动化', name: 'automation' },
      { label: '应用设置', name: 'settings' },
      { label: '应用发布', name: 'publish' },
    ])

    const { appName, getAppDetail } = AppLayoutStore.useProvide()

    const layoutPrefix = computed(() => {
      const { platformId, appId } = route.params
      return `/platform/${platformId}/app/${appId}`
    })

    const activeTab = useLayoutActiveRoute(layoutPrefix.value)

    const handelClickTab = (tab) => {
      const tabName = tab.props.name
      const { appId, platformId } = route.params
      const currentPath = route.fullPath
      if (currentPath.indexOf(tab) !== -1) return
      const name = tabName === 'settings' ? `settings/baseInfo` : tabName
      router.push(`/platform/${platformId}/app/${appId}/${name}`)
    }

    const click = () => {
      const { platformId } = route.params
      router.push(`/platform/${platformId}/app`)
    }

    onMounted(() => {
      getAppDetail()
    })

    return () => {
      return (
        <div class={styles.container}>
          <BaseHeader
            vSlots={{
              left: () => {
                return <BaseHeaderBack name={appName.value} onClick={click} />
              },
              middle: () => {
                return (
                  <ssu-tab-list
                    class={styles.tabs}
                    v-model={activeTab.value}
                    plain
                    margin={{ bottom: 0 }}
                    onTabClick={handelClickTab}
                    list={menus}
                    disabledMore={true}
                  ></ssu-tab-list>
                )
              },
            }}
          />
          <div class={styles.main}>
            <RouterView />
          </div>
        </div>
      )
    }
  },
})

export default AppLayout
