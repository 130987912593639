/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-06-28 13:57:33
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-14 14:44:11
 */
import { computed, defineComponent, onUpdated, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import styles from './BaseHeaderBack.module.scss'

const BaseHeaderBack = defineComponent({
  name: 'BaseHeaderBack',
  props: {
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['click', 'update:modelValue'],
  setup(props, ctx) {
    ctx.expose({})
    const isShowEdit = ref(false)
    const refInput = ref(null)

    const data = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        ctx.emit('update:modelValue', val)
      },
    })

    const handleEdit = () => {
      isShowEdit.value = true
      nextTick(() => {
        refInput.value.focus()
      })
    }
    const handleBlur = () => {
      isShowEdit.value = false
    }

    onUpdated(() => {
      localStorage.setItem('appName', data.value ? data.value : props.name)
    })
    return () => {
      return (
        <div class={styles.container}>
          <ssu-icon iconName="iconfont iconshangyiye icon" onClick={() => ctx.emit('click')}></ssu-icon>
          {!isShowEdit.value ? (
            <div
              class={styles.name}
              title={data.value ? data.value : props.name}
            >
              <span onClick={() => ctx.emit('click')}>{data.value ? data.value : props.name}</span>
              {!props.name ? (
                <syc-icon
                  type="edit"
                  width="15"
                  height="15"
                  class={styles.iconEdit}
                  onClick={() => handleEdit()}
                ></syc-icon>
              ) : null}
            </div>
          ) : (
            <el-input
              ref={refInput}
              class={styles.input}
              v-model={data.value}
              placeholder="请输入自动化名称"
              onBlur={() => handleBlur()}
            />
          )}
        </div>
      )
    }
  },
})

export default BaseHeaderBack
