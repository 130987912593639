/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-03-09 17:18:04
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-02 17:03:47
 */
import displayImg from './components/displayImg.vue'
import displayDoc from './components/displayFile.vue'
import displayAudio from './components/displayAudio.vue'
import displayVideo from './components/displayVideo.vue'
import displayPdf from './components/displayPdf.vue'
import displayDefault from './components/displayDefault.vue'

export default {
  displayImg,
  displayDoc,
  displayAudio,
  displayVideo,
  displayPdf,
  displayDefault,
}
