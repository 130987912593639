let cacheInputIconsMap = null
let cacheTypeIconsMap = null
let cacheFunctionIconsMap = null
let platformIconsMap = null
let formIconsMap = null

const typeList = (val) => {
  let list = {
    inputIcons: require.context('./assets/svgs/inputIcons', true, /\.svg/),
    typeIcons: require.context('./assets/svgs/typeIcons', true, /\.svg/),
    functionIcons: require.context(
      './assets/svgs/functionIcons',
      true,
      /\.svg/
    ),
    platformIcons: require.context(
      './assets/svgs/platformIcons',
      true,
      /\.svg/
    ),
    formIcons: require.context('./assets/svgs/formIcons', true, /\.svg/),
  }
  return list[val] || []
}

const getIconMap = (type, cache) => {
  if (cache) {
    return cache
  }
  const icons = typeList(type)
  // type === 'inputIcons'
  //   ? require.context('@svg/inputIcons', true, /\.svg/)
  //   : require.context('@svg/typeIcons', true, /\.svg/)
  const svgIconMap = icons.keys().reduce((acc, key) => {
    const svgContent = icons(key)
    return {
      ...acc,
      [svgContent.name]: svgContent,
    }
  }, {})
  cache = svgIconMap
  return svgIconMap
}

export const INPUT_ICON_PREFIX = 'inputIcons/'
export const TYPE_ICON_PREFIX = 'typeIcons/'
export const FORM_ICON_PREFIX = 'formIcons/'
export const PLATFORM_ICON_PREFIX = 'platformIcons/'

export const INPUT_ICON_COLOR_MAP = {
  '#FD5B4C': 'linear-gradient(180deg, #FA877D 0%, #FD5B4C 100%)',
  '#F0953C': 'linear-gradient(360deg, #F0953C 0%, #FFB46A 100%)',
  '#FFA200': 'linear-gradient(360deg, #FFA200 0%, #FFC052 100%)',
  '#06CC5F': 'linear-gradient(180deg, #3FD984 0%, #06CC5F 100%)',
  '#0ACBA5': 'linear-gradient(180deg, #5DE3C9 0%, #0ACBA5 100%)',
  '#2E6CEA': 'linear-gradient(360deg, #2E6CEA 0%, #699AFF 100%)',
  '#7851F8': 'linear-gradient(180deg, #A88EFF 0%, #7851F8 100%)',
  '#9F5FFF': 'linear-gradient(360deg, #9F5FFF 0%, #C096FF 100%)',
  '#6785A3': 'linear-gradient(180deg, #859EB7 0%, #6785A3 100%)',
}

export const FORM_ICON_COLOR_MAP = {
  '#FD5849': '#FD5849',
  '#FF942B': '#FF942B',
  '#AF8BFF': '#AF8BFF',
  '#167DFA': '#167DFA',
  '#3999FF': '#3999FF',
  '#00B0FF': '#00B0FF',
  '#1BBEBE': '#1BBEBE',
}

export const INPUT_ICON_MAP = getIconMap('inputIcons', cacheInputIconsMap)

export const INPUT_ICON_COLOR_LIST = Object.keys(INPUT_ICON_COLOR_MAP) || []

export const FORM_ICON_COLOR_LIST = Object.keys(FORM_ICON_COLOR_MAP) || []

export const INPUT_ICON_LIST = Object.keys(INPUT_ICON_MAP) || []

export const TYPE_ICON_MAP = getIconMap('typeIcons', cacheTypeIconsMap)

export const TYPE_ICON_LIST = Object.keys(TYPE_ICON_MAP) || []

export const FUNCTION_ICON_MAP = getIconMap(
  'functionIcons',
  cacheFunctionIconsMap
)

export const FUNCTION_ICON_LIST = Object.keys(FUNCTION_ICON_MAP) || []

export const PLATFORM_ICON_MAP = getIconMap('platformIcons', platformIconsMap)

export const PLATFORM_ICON_LIST = Object.keys(PLATFORM_ICON_MAP) || []

export const FORM_ICON_MAP = getIconMap('formIcons', formIconsMap)

export const FORM_ICON_LIST = Object.keys(FORM_ICON_MAP) || []

const getAllIconMap = () => {
  const iconMap = {}
  INPUT_ICON_LIST.forEach((key) => {
    const newKey = key.split(INPUT_ICON_PREFIX)[1]
    newKey && (iconMap[newKey] = key)
  })
  PLATFORM_ICON_LIST.forEach((key) => {
    const newKey = key.split(PLATFORM_ICON_PREFIX)[1]
    newKey && (iconMap[newKey] = key)
  })

  FORM_ICON_LIST.forEach((key) => {
    const newKey = key.split(FORM_ICON_PREFIX)[1]
    newKey && (iconMap[newKey] = key)
  })

  return iconMap
}

export const getIconByMap = (val) => {
  let IconKey = ''
  Object.keys(BASE_ICON_ALL_MAP).forEach((key) => {
    if (val === BASE_ICON_ALL_MAP[key]) {
      IconKey = key
    }
  })
  return IconKey
}

export const BASE_ICON_ALL_MAP = getAllIconMap()
export const BASE_ICON_ALL_LIST =
  Object.keys(BASE_ICON_ALL_MAP).map((item) => {
    return BASE_ICON_ALL_MAP[item]
  }) || []
