/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-07-04 15:06:23
 * @LastEditors: zhengbolin
 * @LastEditTime: 2022-10-27 10:24:30
 */
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SycPaaSComponents from '@syc-paas/components'
import cookies from 'js-cookie'
const SignWrap = SycPaaSComponents.SignWrap

const SignMobile = defineComponent({
  name: 'SignMobile',
  components: { SignWrap },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // 此时的code，是使用tempH5SignCode，即从签名报告页面跳过来，还是使用普通的code，从正常的签名组件跳过来
    // taskId 任务id
    const {
      code,
      pageCode,
      isTempH5SignCode,
      isPublicRelease,
      pdfUrl,
      taskId,
      signType,
      isRuntime
    } = route.query
    // 若参数没有isPublicRelease， 则临时使用token判断是否在公开发布表单中，无token则是公开发布表单
    const isPublicReleaseValue = computed(() => {
      const token = cookies.get(process.env.VUE_APP_COOKIE_TOKEN_KEY)
      return isPublicRelease || !token
    })

    const handleSaved = () => {
      history.go(-1)
    }

    return () => {
      return (
        <SignWrap
          code={code}
          pageCode={pageCode}
          taskId={taskId}
          pdfUrl={pdfUrl}
          signType={signType}
          isRuntime={isRuntime}
          name="Sign"
          isPublicRelease={isPublicReleaseValue.value}
          isTempH5SignCode={!!isTempH5SignCode}
          onSaved={handleSaved}
        ></SignWrap>
      )
    }
  },
})

export default SignMobile
