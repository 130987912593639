import { iframeRole, useIframeState } from '@syc-paas/core'

import { defineComponent } from 'vue'
import styles from './AppFormDesign.module.scss'
import { useRuntimeVariables } from '../../../hooks/useRuntimeVariables'

const Preview = defineComponent(() => {
  const variables = useRuntimeVariables()

  const { state } = useIframeState({
    role: iframeRole.Child,
    initialState: {
      widgets: [],
      isMobile: false,
      appId: '',
      pageCode: '',
      platformId: '',
    },
  })

  return () => {
    if (!state.widgets.length) return null
    return (
      <PageRender
        variables={variables.value}
        class={styles.preview}
        isMobile={state.isMobile}
        widgets={state.widgets}
        appId={state.appId}
        pageCode={state.pageCode}
        platformId={state.platformId}
      />
    )
  }
})

export default Preview
