import styles from './style.module.scss'
import paasClient from '@/lib/paasClient'
// import cookies from 'js-cookie'
import { SsuMessageBox } from '@syc-saas/ui'
import { getFilePreviewUrl } from '@syc-paas/utils'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useWindowSize } from '@vueuse/core'
import { Dialog } from 'vant'
import { useElMessage } from '@syc-paas/hooks'
import { RuntimePlatformEnv } from '@syc-paas/core'
import { downloadReportPdf } from './download'
import { isMobile, isApp } from '../../utils/device'

const SignReport = defineComponent({
  name: 'SignReport',

  setup() {
    const { success, warning } = useElMessage()
    const { width: windowWidth } = useWindowSize()
    const isSmallScreen = computed(() => windowWidth.value <= 500)
    const isLoading = ref(false)
    const route = useRoute()
    const olSignRef = ref(null)
    const signImgUrl = ref('')
    const permissionInfo = ref({
      token: '',
      tenantId: '',
    })
    const permissionInfoHeaders = computed(() => {
      const { token, tenantId } = permissionInfo.value
      const { isRuntimeEnv } = routeQuery.value
      // 去除undefined
      return JSON.parse(
        JSON.stringify({
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            'TENANT-ID': tenantId || undefined,
            env: isRuntimeEnv
              ? RuntimePlatformEnv.RUNTIME
              : RuntimePlatformEnv.DESIGN,
          },
        })
      )
    })
    const pdfObj = ref({
      url: '',
      previewUrl: '',
      title: '签字报告',
    })
    const currentStatus = ref('pending') // 进行中状态，还有success和reject
    const routeQuery = computed(() => ({
      appId: route?.query?.appId ?? '',
      pageCode: route?.query?.pageCode ?? '',
      code: route?.query?.code ?? '',
      taskId: route?.query?.taskId ?? '',
      recordId: route?.query?.recordId ?? '',
      signatureTemplateType: route?.query?.signatureTemplateType ?? '',
      signatureTemplateCode: route?.query?.signatureTemplateCode ?? '',
      isRuntimeEnv: !!route?.query?.isRuntime,
    }))
    const downloadSign = () => {
      if (!pdfObj.value.url) {
        return
      }

      if (isApp()) {
        window.open(
          `slmspro://webDownloadPDF?url=${pdfObj.value.url}&fileName=${pdfObj.value.title}&fileType=pdf`,
          '_blank'
        )
        return
      }

      downloadReportPdf(
        pdfObj.value.url,
        pdfObj.value.previewUrl,
        pdfObj.value.title
      )
    }
    const rejectSign = async () => {
      if (isSmallScreen.value) {
        await Dialog.confirm({
          title: '提示',
          message: '确认拒绝签名？',
        })
      } else {
        await SsuMessageBox({
          description: `确认拒绝签名？`,
          isFlex: true,
          type: 'warning',
        })
      }
      isLoading.value = true
      const res = await paasClient.process
        .postSignRejectComplete(
          {
            taskId: routeQuery.value.taskId,
          },
          permissionInfoHeaders.value
        )
        .finally(() => (isLoading.value = false))
      if (res) {
        success({
          message: '操作成功',
        })
        currentStatus.value = 'reject'
      }
    }
    const jumpSignPage = () => {
      if (!pdfObj.value.url) {
        warning({
          message: '报告获取中，请稍等...',
        })
        return
      }
      const qrConfig = olSignRef.value?.getQrConfig()
      qrConfig?.value && window.open(qrConfig.value)
    }

    const initPostQrCodeValue = () => {
      return (url) => {
        const params = {
          isTempH5SignCode: 'true',
          isPublicRelease: 'true',
          taskId: routeQuery.value.taskId,
          pdfUrl: pdfObj.value.url,
          signType: routeQuery.value.signatureTemplateType,
          isRuntime: routeQuery.value.isRuntimeEnv,
        }
        const newStr = Object.keys(params).reduce((str, key) => {
          const value = params[key]
          value && (str = str + `&${key}=${value}`)
          return str
        }, '')

        return url + newStr
      }
    }
    const postQrCodeValue = ref(initPostQrCodeValue())

    const changeSignUrl = (value) => {
      signImgUrl.value = value
      signImgUrl.value && (currentStatus.value = 'success')
    }
    const init = () => {
      const { appId, pageCode, signatureTemplateCode, recordId } =
        routeQuery.value
      if (!appId || !pageCode || !signatureTemplateCode || !recordId) {
        return
      }
      isLoading.value = true
      const promise1 = paasClient.metaData
        .downloadPrintTemplatePdf(
          {
            appId,
            pageCode,
            code: signatureTemplateCode,
            recordId,
          },
          permissionInfoHeaders.value
        )
        .then((url) => {
          if (currentStatus.value !== 'success') {
            pdfObj.value.url = url
            pdfObj.value.previewUrl = getFilePreviewUrl(url)
            postQrCodeValue.value = initPostQrCodeValue()
          }
        })
      const promise2 = paasClient.metaData
        .getPrintTemplateDetailByCode(
          {
            appId,
            pageCode,
            code: signatureTemplateCode,
          },
          permissionInfoHeaders.value
        )
        .then((res) => {
          pdfObj.value.title = res?.name || '签字报告'
        })
      Promise.all([promise1, promise2]).finally(() => (isLoading.value = false))
    }

    // 轮询查询当前状态，是否已完成了签字
    const getCurrentStatus = async (timer) => {
      const res = await paasClient.process.getSignatureTokenByTempCode(
        routeQuery.value.code
      )
      permissionInfo.value = {
        token: res.token,
        tenantId: res.tenantId,
      }
      // cookies.set(process.env.VUE_APP_COOKIE_TOKEN_KEY, res.token)
      // cookies.set(process.env.VUE_APP_COOKIE_TENANT_ID_KEY, res.tenantId)
      // 如果设置了这个字段说明已经签名了，我们需要把原先的pdf相关链接都替换掉了
      if (res?.reportUrl) {
        pdfObj.value.url = res.reportUrl
        pdfObj.value.previewUrl = getFilePreviewUrl(res.reportUrl)
        currentStatus.value = 'success'
        clearInterval(timer)
      } else {
        // 如果没有设置reportUrl字段并且isReject为true，说明是拒绝签名，我们直接设为拒绝状态
        res?.isReject && (currentStatus.value = 'reject')
      }
    }

    const pollingInit = async (initCallback) => {
      let isFirstInit = true
      let timer = null

      await getCurrentStatus(timer)
      if (isFirstInit) {
        initCallback()
        isFirstInit = false
      }
      timer = setInterval(() => {
        getCurrentStatus(timer)
      }, 3000)
    }

    const setDocumentTile = () => {
      document.title = pdfObj.value.title
    }

    watch(
      () => pdfObj.value.title,
      () => {
        setDocumentTile()
      }
    )

    onMounted(() => {
      setDocumentTile()
      pollingInit(() => {
        init()
        // 延迟初始化ws，因为还未获取到临时的认证，后续优化成watch方式
        setTimeout(() => {
          isMobile() && olSignRef.value?.initWebsocket() // 小屏幕时初始化签字的websocket，因为我们是没有二维码弹框的
        }, 200)
      })
    })

    return () => {
      return (
        <div
          class={[
            styles['sign-report'],
            isSmallScreen.value && 'is-small-screen',
          ]}
        >
          <div
            class={[
              styles['sign-report-pdf'],
              isSmallScreen.value && styles['is-small-screen'],
            ]}
          >
            <iframe
              src={pdfObj.value.previewUrl}
              height="100%"
              width="100%"
            ></iframe>
          </div>
          <div
            class={[
              styles['sign-report-buttons'],
              `is-${currentStatus.value}`,
              isSmallScreen.value && styles['is-small-screen'],
            ]}
          >
            <div class={styles['sign-report-buttons-left']}>
              <ssu-button onClick={downloadSign}>下载PDF</ssu-button>
            </div>
            <div class={styles['sign-report-buttons-right']}>
              {['pending'].includes(currentStatus.value) && (
                <>
                  <ssu-button
                    onClick={rejectSign}
                    style={{
                      marginRight: '12px',
                    }}
                  >
                    拒绝签名
                  </ssu-button>
                  {/* 假设是小屏幕的，我们获取对应的qrcode的链接，然后直接跳转过去,千万别直接销毁ol-sign */}
                  <ssu-button
                    v-show={isSmallScreen.value}
                    type="primary"
                    onClick={jumpSignPage}
                  >
                    确认签名
                  </ssu-button>
                  <ol-sign
                    v-show={!isSmallScreen.value}
                    ref={olSignRef}
                    {...routeQuery.value}
                    modelValue={signImgUrl.value}
                    tempH5SignCode={routeQuery.value.code}
                    qrCodePopoverPlacement="left-start"
                    onUpdate:modelValue={changeSignUrl}
                    disabledEditClose={true}
                    postQrCodeValue={postQrCodeValue.value}
                    token={permissionInfo.value?.token}
                    tenantId={permissionInfo.value?.tenantId}
                    vSlots={{
                      reference: () => (
                        <ssu-button type="primary">确认签名</ssu-button>
                      ),
                    }}
                  ></ol-sign>
                </>
              )}
              {['reject'].includes(currentStatus.value) && (
                <div class={styles['sign-report-reject-text']}>已拒绝签字</div>
              )}
            </div>
          </div>
        </div>
      )
    }
  },
})
export default SignReport
