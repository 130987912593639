/*
 * @Author: pzf
 * @description: 文件描述
 * @Date: 2022-05-26 17:52:25
 * @LastEditors: pzf
 * @LastEditTime: 2022-07-19 10:19:23
 */
import { WidgetCode } from '@syc-paas/core'

export const AUTOMATION_STATUS_MAP = {
  OPEN: '1',
  CLOSE: '0',
}

export const AUTOMATION_STATUS_OPTION = [
  { value: AUTOMATION_STATUS_MAP.OPEN, label: '开启' },
  { value: AUTOMATION_STATUS_MAP.CLOSE, label: '关闭' },
]

export const TRIGGER_TYPES_OPTION_MAP = {
  FORM: { value: 'form', label: '表单事件触发' },
  TIMING: { value: 'timing', label: '定时触发' },
  DATE_FIELD: { value: 'date_field', label: '按日期字段触发' },
  WEBHOOK: { value: 'webhook', label: 'webhook触发' },
  BUTTON: { value: 'button', label: '自定义按钮触发' },
}

export const TRIGGER_TYPES_OPTION = [
  TRIGGER_TYPES_OPTION_MAP.FORM,
  TRIGGER_TYPES_OPTION_MAP.TIMING,
  TRIGGER_TYPES_OPTION_MAP.DATE_FIELD,
  TRIGGER_TYPES_OPTION_MAP.WEBHOOK,
  TRIGGER_TYPES_OPTION_MAP.BUTTON,
]

export const EXECUTE_FLOW_STATUS_OPTION = [
  { value: 'INIT', label: '进行中', type: 'primary' },
  { value: 'SUCCESS', label: '成功', type: 'success' },
  { value: 'FAIL', label: '失败', type: 'danger' },
  { value: 'NOT_NEED_RUN', label: '未执行', type: 'info' },
]

export const PUBLIC_RELEASE_WIDGETS_FILTER_LIST = [
  WidgetCode.MEMBER,
  WidgetCode.DEPARTMENT,
  WidgetCode.RELATION_FORM,
  WidgetCode.RELATION_FORM_FIELD,
  WidgetCode.RELATION_FORM_QUERY,
]

export const PLATFORM_TYPES = {
  SAAS: 'saas',
  SIOT: 'siot',
}
export const PLATFORM_TYPE_ARR = [
  { value: PLATFORM_TYPES.SAAS, label: 'SaaS平台' },
  { value: PLATFORM_TYPES.SIOT, label: '边缘IoT平台' },
]
