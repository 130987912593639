// 判断当前是不是ios端
export const isIos = () => {
  const u = navigator.userAgent
  const isIosEnv = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return isIosEnv
}

// 判断当前是不是安卓端
export const isAndroid = () => {
  return !isIos()
}

// 判断当前是不是微信端
export const isWeChat = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  return ua.indexOf('micromessenger') > -1
}

// 判断当前是不是pc端
export const isMobile = () => {
  let userAgentInfo = navigator.userAgent
  let Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  let getArr = Agents.filter((i) => userAgentInfo.includes(i))
  return getArr?.length
}

export const isApp = () => {
  let userAgentInfo = navigator.userAgent
  return ['SLMSPro/iOS', 'SLMSPro/Android'].some(e => userAgentInfo.includes(e))
}
