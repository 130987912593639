import { computed, defineComponent, defineProps } from 'vue'

const icons = require.context('@svg', true, /\.svg/)
const svgIconMap = icons.keys().reduce((acc, key) => {
  const svgContent = icons(key)
  return {
    ...acc,
    [svgContent.name]: svgContent,
  }
}, {})

const SvgIcon = defineComponent({
  name: 'SvgIcon',
  props: {
    name: String,
  },
  setup(props, { attrs }) {
    const data = computed(() => {
      return svgIconMap[props.name]
    })
    return () => (
      <yz-svg-icon
        class="svc-icon"
        data={data.value}
        {...{
          props: attrs,
          attrs,
        }}
      />
    )
  },
})

export default SvgIcon
