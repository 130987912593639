/*
 * @Author: pzf
 * @description: 配置文件
 * @Date: 2022-07-06 17:50:49
 * @LastEditors: pzf
 * @LastEditTime: 2022-08-03 17:21:59
 */


export function getFileFormat(url) {
  if (!url) {
    return ''
  }
  let _url = url.split('?')[0]
  let index = _url.lastIndexOf('.') // 获取文件最后一个点的下标
  let fileFormat = _url.substring(index + 1).toLowerCase() // 获取文件后缀并转换成小写 例如： jpg、mp3、mp4
  return fileFormat
}
export function getComponentName(url, name) {
  if (!url) {
    return
  }
  let urlViewMap = {
    displayImg: ['png', 'bmp', 'webp', 'gif', 'jpg', 'jpeg', 'heic'],
    displayAudio: ['mp3', 'wav', 'ogg', 'flac', 'cda'],
    displayVideo: ['mp4', 'mpeg', 'mov', '3gp', '3gpp'],
    // displayPdf: ['pdf'],
    displayDoc: [
      'pdf',
      'xlsx',
      'TXT',
      'XLS',
      'PDF',
      'DOC',
      'XLSX',
      'DOCX',
      'PPT',
      'pptx',
      'zip',
      'xml',
      'png',
      'gif',
      'jpg',
      'jpeg',
      'mp3',
      'wav',
      'mp4',
      'flv',
    ].map((item) => item.toLowerCase()),
  }
  let urlFileFormat = getFileFormat(url)?.toLowerCase()
  let nameFileFormat = getFileFormat(name)?.toLowerCase() 
  for (let key in urlViewMap) {
    if (
      urlViewMap[key].includes(urlFileFormat) ||
      urlViewMap[key].includes(nameFileFormat)
    ) {
      return key
    }
  }
  return 'displayDefault'
}

// 获取是否支持预览
export function whetherSupportPreview(url) {
  if (!url) {
    return false
  }
  let previewArr = [
    'TXT',
    'XLS',
    'PDF',
    'DOC',
    'XLSX',
    'DOCX',
    'PPT',
    'pptx',
    'zip',
    'xml',
    'png',
    'gif',
    'jpg',
    'jpeg',
    'mp3',
    'wav',
    'mp4',
    'flv',
  ].map(item => item.toLowerCase())
  let _url = url.split('?')[0]
  let index = _url.lastIndexOf('.') // 获取文件最后一个点的下标
  let lowerFileFormat = _url.substring(index + 1).toLowerCase() // 获取文件后缀小写
  return (
    previewArr.includes(lowerFileFormat)
  )
}

