import { defineComponent, ref, watch } from 'vue'

import { redirectLogin } from '../../lib/permission'
import styles from './NotPermission.module.scss'
import { useRoute } from 'vue-router'

const NotPermission = defineComponent({
  name: 'NotPermission',
  setup() {
    const { query } = useRoute()

    return () => {
      return (
        <div class={styles.container}>
          <div class={styles.content}>
            <img
              class={styles.img}
              src={require('@/assets/images/no-permission.png')}
            />
            <p class={styles.describe}>暂无权限，请联系管理员</p>
            <div>
              <ssu-button type="primary" onClick={() => redirectLogin()}>
                返回登录页面
              </ssu-button>
            </div>
          </div>
        </div>
      )
    }
  },
})

export default NotPermission
