import e from "lodash";

import t from "axios";

import s from "js-cookie";

import i from "qs";

import { safeCallFunction as o } from "@syc-paas/utils";

const a = (e, t) => e.replace(/{{(.*?)}}/g, ((e, s) => {
    const i = t[s];
    return i;
})), r = e => (t, s = {}) => {
    const {query: i = {}, headers: o = {}, body: r = {}, pathVariable: n = {}, cancelToken: d, responseType: l, cache: p} = s;
    return {
        method: e,
        url: a(t, n),
        params: i,
        body: r,
        headers: o,
        cancelToken: d,
        responseType: l,
        cache: p
    };
}, n = r("post"), d = r("get"), l = r("put"), p = r("delete"), c = "/admin", h = `${c}/login`, u = `${c}/app`, $ = `${c}/platform`, g = `${c}/platform/admin`, m = `${c}/permission`, y = `${c}/department`, f = `${c}/user`, b = `${c}/appversion`, I = `${c}/tenant`, S = "/workflow/workbench", T = `${c}/role`, k = `${c}/position`, v = `${c}/external-app`, w = {
    getTokenFromCode: e => d(`${h}/code-info/${e}`),
    getTentantCredentials: ({tenantId: e, params: t}) => d(`${h}/ticket/${e}`, {
        query: t
    }),
    getUserInfo: () => d(`${f}/personal`),
    getPermission: () => d(`${f}/personal-permission`),
    getBatchUser: ({userIds: e, cancelToken: t}) => n(`${f}/info-batch`, {
        body: e,
        cancelToken: t
    }),
    getBatchDept: ({deptIds: e, userIds: t, cancelToken: s}) => n(`${f}/multi-info-batch`, {
        body: {
            deptId: e,
            userId: t
        },
        cancelToken: s
    }),
    getDepartmentTree: () => d(`${y}/tree`),
    postMemberListV2: (e, t, s) => n(`/admin/user/selectable-page/v2?deptId=${e}`, {
        body: t,
        ...s
    }),
    getMemberList: e => d(`${f}/selectable-page`, {
        query: {
            status: 0,
            ...e
        }
    }),
    getUserList: e => d(`${f}/page`, {
        query: e
    }),
    getDepartmentMemberTree: (e = [], t) => n(`${y}/member-tree`, {
        body: e,
        ...t
    }),
    getDeptByUserId: e => d(`${y}/getDeptByUserId/${e}`),
    getAllDeptByUserId: e => d(`${y}/getAllDeptByUserId/${e}`),
    addApp: (e, t) => n(`${u}`, {
        body: e,
        ...t
    }),
    getAppDetail: (e, t) => d(`${u}/${e}`, {
        ...t
    }),
    getAppPage: (e, t) => n(`${u}/page`, {
        body: e,
        ...t
    }),
    editApp: (e, t, s) => l(`${u}/${e}`, {
        body: t,
        ...s
    }),
    deleteApp: (e, t, s) => p(`${u}/${e}`, {
        query: {
            name: t
        },
        ...s
    }),
    getPlatformSettingApp: (e, t) => d(`${u}/setting/${e}`, {
        ...t
    }),
    putPlatformSettingApp: (e, t, s) => l(`${u}/setting/${e}`, {
        body: t,
        ...s
    }),
    getNavAllApplication: (e, t) => d(`${u}/platform/${e}`, {
        ...t
    }),
    addPlatform: (e, t) => n(`${$}`, {
        body: e,
        ...t
    }),
    getPlatformDetail: (e, t) => d(`${$}/${e}`, {
        ...t
    }),
    getPlatformPage: (e, t) => n(`${$}/list`, {
        body: e,
        ...t
    }),
    editPlatform: (e, t) => l(`${$}/${e.id}`, {
        body: e,
        ...t
    }),
    deletePlatform: (e, t) => p(`${$}/${e}`, {
        ...t
    }),
    transferPlatform: (e, t) => n(`${g}/transfer`, {
        body: e,
        ...t
    }),
    addPlatformAdmin: (e, t) => n(`${g}`, {
        body: e,
        ...t
    }),
    getPlatformAdminList: (e, t) => d(`${g}/list/${e}`, {
        ...t
    }),
    deletePlatformAdmin: (e, t) => p(`${g}/${e}`, {
        ...t
    }),
    getPageList: ({appId: e, preview: t}) => d(`${m}/primary-group/${e}/${t}`),
    setPageList: ({preview: e, body: t}) => l(`${m}/primary-group/${e}`, {
        body: t
    }),
    getAppversionList: ({appId: e, params: t}) => d(`${b}/page/${e}`, {
        query: t
    }),
    getTenantList: ({params: e}) => d(`${I}/selectable-page`, {
        query: e
    }),
    getTenantStructure: e => d(`${I}/structure`, {
        ...e
    }),
    getTenantInfo: () => d(`${I}/info`),
    initiate: e => n(`${S}/start`, {
        body: e
    }),
    todo: e => n(`${S}/todo`, {
        body: e
    }),
    finished: e => n(`${S}/finished`, {
        body: e
    }),
    draft: e => n(`${S}/draft`, {
        body: e
    }),
    carbonCopy: e => n(`${S}/carbon-copy`, {
        body: e
    }),
    getCurrentUserPermissionTree: (e, t = {}) => d(`${m}/user`, {
        query: e,
        ...t
    }),
    getWechatShareSign: e => d(`${f}/wx/share/sign/nature`, {
        query: e
    }),
    getRoleList: e => d(`${T}/page`, {
        query: e
    }),
    getPositionList: e => d(`${k}/page`, {
        query: e
    }),
    getSelectRoleList: e => d(`${T}/selectable`, {
        query: e
    }),
    getExternalAppList: () => d(`${v}/list`),
    getAdminUser: () => d(`${f}/admin`),
    getMemberListV3: (e, t) => n(`${f}/selectable-page/v3`, {
        body: e,
        query: t
    }),
    getDeptList: e => n(`${c}/department/selectable-page`, {
        query: e
    })
}, C = "/form", L = {
    createWithoutScene: ({cancelToken: e, appId: t, modelCode: s, ...i}) => n(`${C}/app/${t}/model/${s}`, {
        body: i,
        cancelToken: e
    }),
    updateWithoutScene: ({cancelToken: e, appId: t, modelCode: s, id: i, ...o}) => l(`${C}/app/${t}/model/${s}/${i}`, {
        body: o,
        cancelToken: e
    }),
    batchUpdateWithoutScene: ({cancelToken: e, appId: t, modelCode: s, data: i}) => l(`${C}/app/${t}/model/${s}/batch`, {
        body: i,
        cancelToken: e
    }),
    deleteWithoutScene: ({cancelToken: e, appId: t, modelCode: s, data: i}) => p(`${C}/app/${t}/model/${s}`, {
        body: i,
        cancelToken: e
    }),
    getDetailWithoutScene: ({cancelToken: e, appId: t, modelCode: s, id: o, params: a}) => d(`${C}/app/${t}/model/${s}/${o}?${i.stringify(a)}`, {
        cancelToken: e
    }),
    getListWithoutScene: ({appId: e, modelCode: t, pageSize: s, pageNum: i, filter: o, sort: a, keyword: r, dataPermission: d, ...l}) => n(`${C}/app/${e}/model/${t}/query`, {
        body: {
            pageSize: s,
            pageNum: i,
            filter: o,
            sort: a,
            keyword: r,
            dataPermission: d
        },
        cache: !0,
        ...l
    }),
    getListV3: ({appId: e, sceneId: t, pageSize: s, pageNum: i, filter: o, sort: a, keyword: r, dataPermission: d, ...l}) => {
        const p = {
            pageSize: s,
            pageNum: i,
            filter: o,
            sort: a,
            keyword: r,
            dataPermission: Boolean(d)
        };
        return n(`/form/v3/app/${e}/scene/${t}/query`, {
            body: p,
            ...l
        });
    },
    getList: ({appId: e, sceneId: t, pageSize: s, pageNum: i, filter: o, sort: a, keyword: r, dataPermission: d, ...l}) => {
        const p = {
            pageSize: s,
            pageNum: i,
            filter: o,
            sort: a,
            keyword: r,
            dataPermission: Boolean(d)
        };
        return n(`${C}/app/${e}/scene/${t}/query`, {
            body: p,
            ...l
        });
    },
    getPageDataList: ({appId: e, sceneId: t, pageSize: s, pageNum: i, filter: o, sort: a, keyword: r, params: d, ...l}) => n(`${C}/noAuth/app/${e}/model/scene/${t}/query`, {
        body: {
            pageSize: s,
            pageNum: i,
            filter: o,
            sort: a,
            keyword: r
        },
        query: d,
        ...l
    }),
    create: ({cancelToken: e, appId: t, sceneId: s, ...i}) => n(`${C}/app/${t}/scene/${s}`, {
        body: i,
        cancelToken: e
    }),
    getDetail: ({id: e, appId: t, sceneId: s, cancelToken: i}) => d(`${C}/app/${t}/scene/${s}/${e}`, {
        cancelToken: i
    }),
    delete: ({cancelToken: e, appId: t, sceneId: s, items: i = []}) => p(`${C}/app/${t}/scene/${s}`, {
        body: i,
        cancelToken: e
    }),
    update: ({cancelToken: e, appId: t, sceneId: s, ...i}) => {
        const {id: o, ...a} = i;
        return l(`${C}/app/${t}/scene/${s}/${o}`, {
            body: a,
            cancelToken: e
        });
    },
    export: ({appId: e, sceneId: t, params: s, ...i}) => n(`${C}/app/${e}/scene/${t}/export`, {
        body: {
            ...s
        },
        ...i
    }),
    downloadTemplate: (e, t, s) => d(`/form/app/${e}/scene/${t}/v2/downloadTemplate`, {
        responseType: "blob",
        query: s
    }),
    getImportResult: (e, t, s) => d(`/form/app/${e}/scene/${t}/v2/result?requestId=${s}`),
    getImportProgress: (e, t, s) => d(`/form/app/${e}/scene/${t}/v2/status?requestId=${s}`),
    getImportException: (e, t, s) => d(`/form/app/${e}/scene/${t}/v2/result?requestId=${s}`),
    importData: ({cancelToken: e, type: t, appId: s, sceneId: i, data: o}) => n(`/form/app/${s}/scene/${i}/v2/${t}/import`, {
        body: o,
        cancelToken: e
    }),
    downloadExceptionData: (e, t, s) => d(`/form/app/${e}/scene/${t}/v2/downloadError?requestId=${s}`, {
        responseType: "blob"
    }),
    importNormalData: (e, t, s, i) => n(`/form/app/${t}/scene/${s}/v2/${e}/import/execute/${i}`),
    cacelImportData: (e, t, s) => l(`/form/app/${e}/scene/${t}/cacelImportData?requestId=${s}`),
    cancelImportData: (e, t) => l(`/form/app/${e}/scene/${t}/v2/cancel`),
    getSignatureTokenByCode: e => d(`${C}/widget/signature/getTokenByCode?code=${e}`),
    getSignatureQrCode: ({sessionId: e}, t) => d(`${C}/widget/signature/getQrCodeUrl?sessionId=${e}`, {
        ...t
    }),
    saveSignatureUrl: ({appId: e, code: t, url: s}, i) => n(`${C}/widget/signature/saveUrl`, {
        ...i,
        body: {
            code: t,
            url: s
        }
    }),
    submitPublicReleaseInfo: e => n(`${C}/open/api`, e),
    getFormDetailPdfByPrintTemplateCode: ({appId: e, modelCode: t, templateCode: s, recordId: i}) => d(`${C}/app/${e}/model/${t}/print-template/${s}/download?recordId=${i}`),
    getDataSummaryList: ({appId: e, sceneId: t, pageSize: s, pageNum: i, filter: o, sort: a, keyword: r, ...d}) => n(`${C}/app/${e}/scene/${t}/summary`, {
        body: {
            pageSize: s,
            pageNum: i,
            filter: o,
            sort: a,
            keyword: r
        },
        ...d
    }),
    getDataSummary: ({appId: e, modelCode: t, id: s}) => d(`${C}/app/${e}/model/${t}/permission/${s}`),
    getBaiduAddress: e => d(`${C}/address/baidu-api`, {
        query: e
    }),
    getAddressByPoi: e => n(`${C}/address/poi/baidu-api`, {
        body: e
    }),
    verifyDataPermissionById: ({appId: e, modelCode: t, formId: s}) => d(`${C}/app/${e}/model/${t}/permission/${s}`)
}, x = {
    login: (e, t) => n("/staff-iam-sso/oauth/token", {
        body: i.stringify(e),
        ...t
    }),
    getUserInfo: e => d("/staff-iam-admin/verify", {
        query: {
            a: 1
        },
        ...e
    })
}, z = "/metadata", A = "/metadata/app", F = {
    addPageMenu: ({appId: e, body: t}) => n(`${A}/${e}/menu`, {
        body: t
    }),
    editPageMenu: ({appId: e, code: t, body: s}) => l(`${A}/${e}/menu/${t}`, {
        body: s
    }),
    deletePageMenu: ({appId: e, code: t}) => p(`${A}/${e}/menu/${t}`),
    getPageDetail: ({appId: e, code: t, ...s}) => d(`${A}/${e}/page/${t}/simple`, {
        ...s
    }),
    getApplicationPageMetaData: ({appId: e, params: t}) => n(`${A}/${e}/page/list`, {
        body: t
    }),
    getPageSceneColumnSetting: ({id: e, appId: t, ...s}) => d(`${A}/${t}/form-scene/${e}/visible-config`, {
        ...s
    }),
    putPageSceneColumnSetting: ({body: e, appId: t, id: s}) => l(`${A}/${t}/form-scene/${s}/visible-config`, {
        body: e
    }),
    getPageScenes: ({appId: e, ...t}) => d(`${A}/${e}/form-scene`, {
        ...t
    }),
    getFormSceneDetail: ({id: e, appId: t}) => d(`${A}/${t}/form-scene/${e}`),
    getFormSceneVisibleConfig: e => d(`${A}/${e}/visible-config`),
    createPageScene: e => n(`${A}/${e.appId}/form-scene`, {
        body: e
    }),
    updatePageScene: ({appId: e, id: t, ...s}) => l(`${A}/${e}/form-scene/${t}`, {
        body: {
            ...s
        }
    }),
    sortPageScene: ({appId: e, id: t, sort: s}) => l(`${A}/${e}/form-scene/${t}/sort`, {
        body: {
            sort: s
        }
    }),
    allSortPageScene: ({appId: e, body: t}) => l(`${A}/${e}/form-scene/sort`, {
        body: t
    }),
    copyPageScene: ({id: e, appId: t}) => n(`${A}/${t}/form-scene/copy`, {
        body: {
            id: e
        }
    }),
    deletePageScene: ({id: e, appId: t}) => p(`${A}/${t}/form-scene/${e}`),
    getfilterConfig: ({appId: e, pageCode: t, cancelToken: s}) => d(`${A}/${e}/page/${t}/filterConfig`, {
        cancelToken: s
    }),
    postfilterConfig: ({appId: e, pageCode: t, cancelToken: s, ...i}) => n(`${A}/${e}/page/${t}/filterConfig`, {
        body: i,
        cancelToken: s
    }),
    getPageMetaData: ({appId: e, pageCode: t, ...s}) => d(`${A}/${e}/page/${t}`, {
        cache: !0,
        ...s
    }),
    getPageNoAuthMetaData: ({appId: e, pageCode: t, params: s, ...i}) => d(`${z}/open/api/app/${e}/page/${t}`, {
        query: s,
        ...i
    }),
    savePageMetaData: ({appId: e, pageCode: t, data: s}) => l(`${A}/${e}/page/${t}`, {
        body: {
            content: s
        }
    }),
    savePublishApplication: ({appId: e, body: t}) => n(`${A}/${e}/publish`, {
        body: t
    }),
    getRoboterFlow: ({appId: e, ...t}) => n(`${A}/${e}/roboter-flow/page`, {
        body: t
    }),
    addRoboterFlow: ({appId: e, ...t}) => n(`${A}/${e}/roboter-flow`, {
        body: t
    }),
    updateRoboterFlowStatus: ({appId: e, id: t, status: s}) => l(`${A}/${e}/roboter-flow/${t}/status/${s}`),
    deleteRoboterFlow: ({appId: e, id: t}) => p(`${A}/${e}/roboter-flow/${t}`),
    getRoboterFlowDetails: ({appId: e, automationId: t, ...s}) => d(`${A}/${e}/roboter-flow/${t}`, {
        query: s
    }),
    putRoboterFlowDetails: ({appId: e, automationId: t, data: s}) => l(`${A}/${e}/roboter-flow/${t}`, {
        body: s
    }),
    getByFlowCode: ({appId: e, flowCode: t}) => d(`${A}/${e}/page-button/getByFlowCode?flowCode=${t}`),
    getBusinessRules: (e, {params: t}) => d(`${A}/${e}/form-rule`, {
        query: t
    }),
    addBusinessRules: (e, t) => n(`${A}/${e}/form-rule`, {
        body: t
    }),
    editBusinessRules: (e, t, s) => l(`${A}/${e}/form-rule/${t}`, {
        body: s
    }),
    deleteBusinessRules: (e, t) => p(`${A}/${e}/form-rule/${t}`),
    detailBusinessRules: (e, t) => d(`${A}/${e}/form-rule/${t}`),
    enableBusinessRules: (e, t, s) => l(`${A}/${e}/form-rule/status/${t}`, {
        body: {
            status: s
        }
    }),
    sortBusinessRules: (e, t, s) => l(`${A}/${e}/form-rule/${t}/sort`, {
        body: {
            sort: s
        }
    }),
    getFunctionButton: ({params: e, ...t}) => d(`${A}/${e.appId}/page-button/v2`, {
        query: {
            pageCode: e.pageCode
        },
        ...t
    }),
    addFunctionButton: (e, t) => n(`${A}/${e}/page-button`, {
        body: t
    }),
    editFunctionButton: (e, t, s) => l(`${A}/${e}/page-button/${t}`, {
        body: s
    }),
    deleteFunctionButton: (e, t) => p(`${A}/${e}/page-button/${t}`),
    detailFunctionButton: (e, t) => d(`${A}/${e}/page-button/${t}`),
    enableFunctionButton: (e, t, s) => l(`${A}/${e}/page-button/${t}/status/${s}`),
    updateFunctionButtonShowCount: (e, t) => l(`${A}/${e}/page-button/updateShowCount`, {
        body: t
    }),
    sortFunctionButton: (e, t) => l(`${A}/${e}/page-button/sort/v2`, {
        body: t
    }),
    roboterFunctionButton: (e, t) => d(`${A}/${e}/roboter-flow/code`, {
        query: t
    }),
    deleteRoboterFunctionButton: (e, t) => p(`${A}/${e}/roboter-flow/code/${t}`),
    getNormalOptionSetList: (e = 0) => d(`${A}/${e || 0}/widget/data/cascade/custom`),
    addNormalOptionSet: (e, t) => n(`${A}/${e}/widget/data/cascade/custom`, {
        body: t
    }),
    editNormalOptionSet: (e = 0, t, s) => l(`${A}/${e || 0}/widget/data/cascade/custom/${s}`, {
        body: t
    }),
    deleteNormalOptionSet: (e = 0, t) => p(`${A}/${e || 0}/widget/data/cascade/custom/${t}`),
    getOptionSetByCode: (e = 0, t, s = "") => d(`${A}/${e || 0}/widget/data/cascade/${t}`, {
        query: {
            keyword: s
        }
    }),
    getOptionListByCode: (e = 0, t, s) => d(`${A}/${e || 0}/widget/data/cascade/page/${t}`, {
        query: s
    }),
    getCustomSetByCode: (e = 0, t, s = {}) => d(`${A}/${e || 0}/widget/data/cascade/custom/${t}`, {
        cache: !0,
        ...s
    }),
    getBatchCascade: (e, t, s) => n(`${A}/${s}/widget/data/cascade/map/${t}`, {
        body: {
            idList: e
        }
    }),
    getDictionaryByCode: (e, t) => d(`${z}/dictionary/${e}`, t),
    enableOptionSetOption: (e, t, s, i) => l(`/metadata/app/${e}/widget/data/dict/${t}/item/${s}/enable`, {
        body: i
    }),
    getDataTitleDetail: ({appId: e, ...t}) => d(`${A}/${e}/data-title`, {
        query: t
    }),
    saveDataTitleDetail: ({appId: e, ...t}) => n(`${A}/${e}/data-title`, {
        body: {
            ...t
        }
    }),
    getPublicReleaseStatus: ({appId: e, pageCode: t}) => d(`${A}/${e}/public-release/${t}/status`),
    getAllPagePublicRelease: ({appId: e}) => d(`${A}/${e}/public-release/list`),
    getQuestionnaireChartList: e => d(`/metadata/satisfy/template/enable?type=${e}`),
    getQuestionnaireChartCustomer: ({id: e}) => d(`/form/satisfy/template/${e}/stats/customer`),
    getQuestionnaireData: ({id: e, customerId: t}) => d(`/form/satisfy/template/${e}/stats`, {
        id: e,
        customerId: t
    }),
    getQuestionnaireCustomerFormNum: ({id: e}) => d(`/form/satisfy/template/${e}/questionnaire/count-unconfirmed-customer`, {
        id: e
    }),
    editPublicReleaseStatus: ({appId: e, pageCode: t, body: s}) => l(`${A}/${e}/public-release/${t}/status`, {
        body: s
    }),
    getPublicRelease: ({appId: e, pageCode: t}) => d(`${A}/${e}/public-release/${t}`),
    putPublicRelease: ({appId: e, pageCode: t, body: s}) => l(`${A}/${e}/public-release/${t}`, {
        body: s
    }),
    resetPublicReleaseUrl: ({appId: e, pageCode: t}) => d(`${A}/${e}/public-release/${t}/reset/url`),
    getOpenFormRuleByShareCode: e => n(`${z}/open/api/page/form-rule`, {
        body: e
    }),
    getOpenMetaDataByShareCode: e => n(`${z}/open/api/page/info`, e),
    getPublicReleaseInfoByShareCode: e => n(`${z}/open/api/public-release/info`, e),
    getQuestionnaireStatistical: e => n(`${z}/open/api/page/stats`, {
        body: e
    }),
    getOpenCascadeSetByCode: (e, t, s) => d(`${z}/open/api/cascade/custom/${t}`, {
        query: {
            shareCode: s
        }
    }),
    getPublicQuestionnaireStatistical: ({pageCode: e}) => d(`${A}/questionnaire-stats/${e}`),
    postQuestionnaireShare: ({pageCode: e, status: t, body: s}) => n(`${A}/questionnaire-stats/${e}/shareStatus/${t}`, {
        body: s
    }),
    getPrintTemplateList: ({appId: e, pageCode: t}) => d(`${A}/${e}/page/${t}/print-template`),
    addCustomPrintTemplate: ({appId: e, pageCode: t, body: s}) => n(`${A}/${e}/page/${t}/print-template`, {
        body: s
    }),
    updatePrintTemplateDetail: ({appId: e, pageCode: t, id: s, body: i}) => l(`${A}/${e}/page/${t}/print-template/${s}`, {
        body: i
    }),
    getPrintTemplateDetailByCode: ({appId: e, pageCode: t, code: s}, i) => d(`${A}/${e}/page/${t}/print-template/${s}`, i),
    downloadPrintTemplateExcel: ({appId: e, pageCode: t, code: s, recordId: i}) => d(`/form/app/${e}/model/${t}/print-template/${s}/download/excel?recordId=${i}`),
    downloadPrintTemplatePdf: ({appId: e, pageCode: t, code: s, recordId: i}, o) => d(`/form/app/${e}/model/${t}/print-template/${s}/download?recordId=${i}`, o),
    deletePrintTemplateDetailByCode: ({appId: e, pageCode: t, code: s}) => p(`${A}/${e}/page/${t}/print-template/${s}`),
    editPrintTemplateEnable: ({appId: e, pageCode: t, body: s, code: i}) => l(`${A}/${e}/page/${t}/print-template/${i}/enable`, {
        body: s
    }),
    getPageList: ({appId: e, preview: t}) => d(`/metadata/permission/primary-group/${e}/${t}`),
    getRuntimeEnvList: e => d(`${z}/platform/${e}/config`),
    saveRuntimeEnv: ({platformId: e, body: t}) => n(`${z}/platform/${e}/config`, {
        body: t
    }),
    editRuntimeEnv: ({platformId: e, body: t}) => l(`${z}/platform/${e}/config`, {
        body: t
    }),
    deleteRuntimeEnv: ({platformId: e, id: t}) => p(`${z}/platform/${e}/config/${t}`),
    publishRuntimeEnv: ({platformId: e, id: t}) => n(`${z}/platform/publish/${e}?targetId=${t}`),
    postPageMetadata: ({appId: e, body: t}) => n(`${A}/${e}/page/metadata`, {
        body: t
    }),
    getWebhookUrl: ({appId: e, automationId: t}) => d(`${A}/${e}/roboter-flow/webhook-url/${t}`),
    getDataLogDisplay: ({appId: e, pageCode: t}) => d(`${A}/${e}/page/${t}/data-log/display`),
    putDataLogDisplay: ({appId: e, pageCode: t, ...s}) => l(`${A}/${e}/page/${t}/data-log/display`, {
        body: {
            ...s
        }
    }),
    getTemplageStats: ({id: e, customerId: t}) => d(`/form/satisfy/template/${e}/stats`, {
        query: {
            customerId: t
        }
    }),
    postTemplageStatsShare: ({id: e}) => n("/metadata/satisfy/template/{id}/stats/share"),
    getQuestionnaireList: () => d("/metadata/satisfy/template"),
    getExportInfo: ({appId: e, sceneId: t}) => d(`/metadata/app/${e}/export-info/${t}`),
    updateExportInfo: ({appId: e, sceneId: t, params: s}) => l(`/metadata/app/${e}/export-info/${t}`, {
        body: {
            ...s
        }
    })
}, P = "/workflow", D = "/workflow/applyFlow/task", E = {
    getModelDetail: e => d(`${P}/model/json`, {
        query: e
    }),
    saveProcessModel: (e, t) => n(`${P}/model/save`, {
        body: e,
        ...t
    }),
    deployProcessModel: (e, t) => n(`${P}/model/deploy`, {
        body: e,
        ...t
    }),
    getNodeButtonList: e => d(`${P}/api/v1/button`, {
        query: e
    }),
    deleteProcInstance: ({cancelToken: e, appId: t, modelCode: s, items: i = []}) => p(`${P}/app/${t}/model/${s}`, {
        body: {
            ids: i
        },
        cancelToken: e
    }),
    checkProcInstance: e => d(`${P}/procInstance/checkProcInstance`, {
        ...e
    }),
    startProcInstanceByForm: (e, t) => n(`${P}/startProcess/startProcInstanceByForm`, {
        body: e,
        ...t
    }),
    getDeployedProcessList: e => d(`${P}/procInstance/listDeployedProcess`, {
        ...e
    }),
    getStatisticsData: e => d(`${P}/statistics/myWorkbench`, {
        ...e
    }),
    getMyInitiateProcessList: e => d(`${P}/startByMe/listProcInstance`, {
        ...e
    }),
    getWaitingApproveList: e => d(`${P}/todoTask/listUndoneTasks`, {
        ...e
    }),
    getFinishedProcessList: e => d(`${P}/completeByMe/listDoneTask`, {
        ...e
    }),
    getStartFormData: e => d(`${P}/app/${e.appId}/model/${e.modelCode}/startForm`, {
        query: e
    }),
    getCurrentTaskFormData: e => d(`${P}/form/getTaskFormData`, {
        ...e
    }),
    getTaskFormDataByProcInstanceId: e => d(`${P}/app/${e.appId}/model/${e.modelCode}/${e.formDataId}/detail/byLastNode`),
    getProcessInstanceLog: e => d(`${P}/procInstance/log`, {
        ...e
    }),
    postCancelProcess: (e, t) => n(`${P}/procInstance/cancel`, {
        body: e,
        ...t
    }),
    postRejectTask: (e, t) => n(`${P}/procTask/reject`, {
        body: e,
        ...t
    }),
    postCompleteTask: (e, t) => n(`${P}/procTask/complete`, {
        body: e,
        ...t
    }),
    postTurnoverTask: (e, t) => n(`${P}/procTask/changeTaskAssignee`, {
        body: e,
        ...t
    }),
    postUrgeTask: (e, t) => n(`${P}/proc-start/urge`, {
        body: e,
        ...t
    }),
    postWithdrawTask: (e, t) => n(`${P}/proc-start/withdraw`, {
        body: e,
        ...t
    }),
    postCountersignTask: (e, t) => n(`${P}/procTask/countersign`, {
        body: e,
        ...t
    }),
    postRollBackTask: (e, t) => n(`${P}/procTask/roll-back`, {
        body: e,
        ...t
    }),
    postDelegateTask: (e, t) => n(`${P}/procTask/delegate`, {
        body: e,
        ...t
    }),
    postStashTask: (e, t) => n(`${P}/procTask/stash`, {
        body: e,
        ...t
    }),
    postDispatchTask: (e, t) => n(`${P}/procTask/dispatch`, {
        body: e,
        ...t
    }),
    postRedispatchTask: (e, t) => n(`${P}/procTask/redispatch`, {
        body: e,
        ...t
    }),
    postAssistApplyTask: (e, t) => n(`${D}/assistApply`, {
        body: e,
        ...t
    }),
    postApplyCompleteTask: (e, t) => n(`${D}/complete`, {
        body: e,
        ...t
    }),
    postEndApplyTask: (e, t) => n(`${D}/endApply`, {
        body: e,
        ...t
    }),
    postModifyApplyTask: (e, t) => n(`${D}/modifyApply`, {
        body: e,
        ...t
    }),
    postCancelApplyTask: (e, t) => n(`${D}/cancelApply`, {
        body: e,
        ...t
    }),
    postApplyRejectTask: (e, t) => n(`${D}/reject`, {
        body: e,
        ...t
    }),
    postTurnOverApplyTask: (e, t) => n(`${D}/turnOverApply`, {
        body: e,
        ...t
    }),
    postSignConfirmComplete: (e, t) => n(`${P}/procTask/signConfirmComplete`, {
        body: e,
        ...t
    }),
    postSignRejectComplete: (e, t) => n(`${P}/procTask/signConfirmReject`, {
        body: e,
        ...t
    }),
    getSignatureTokenByTempCode: e => d(`${P}/procTask/signature/getTokenByTempCode?code=${e}`),
    getSignatureReportUrl: ({taskId: e, formDataId: t}, s) => d(`${P}/procTask/signature/report/getQrCodeUrl?taskId=${e}&formDataId=${t}`, {
        ...s
    }),
    getApplyTask: e => d(`/workflow/applyFlow/task/${e}`),
    postDraftSubmit: e => n(`${P}/proc-start/submit`, {
        body: e
    }),
    procTaskCustom: (e, t) => {
        const s = {};
        return t && (s.sessionId = t), n(`${P}/procTask/custom`, {
            body: e,
            headers: s
        });
    },
    getTaskStash: e => d(`${P}/procTask/getStashForm`, {
        ...e
    }),
    startProcess: ({appId: e, modelCode: t, formData: s, ...i}) => n(`${P}/app/${e}/model/${t}/start`, {
        body: {
            modelCode: t,
            formParam: s
        },
        ...i
    }),
    getProcessInstanceDetails: ({appId: e, modelCode: t, businessId: s, actId: i, taskInstanceId: o, sourceType: a}) => d(`${P}/app/${e}/model/${t}/${s}/detail/byTaskInstance`, {
        query: {
            actId: i,
            taskInstanceId: o,
            sourceType: a
        }
    }),
    getSubTableDetails: ({appId: e, modelCode: t, masterId: s, pageSize: i, pageNum: o, filter: a, sort: r, keyword: d, ...l}) => n(`${P}/app/${e}/model/${t}/${s}/childPage/byLastNode`, {
        body: {
            pageSize: i,
            pageNum: o,
            filter: a,
            sort: r,
            keyword: d
        },
        ...l
    }),
    getSubListDetails: ({appId: e, modelCode: t, masterId: s, pageSize: i, pageNum: o, actId: a, taskInstanceId: r, filter: d, sort: l, keyword: p, ...c}) => n(`${P}/app/${e}/model/${t}/${s}/childPage/byTaskInstance`, {
        body: {
            pageSize: i,
            pageNum: o,
            filter: d,
            sort: l,
            keyword: p
        },
        query: {
            actId: a,
            taskInstanceId: r
        },
        ...c
    }),
    getStartFormSubPermission: ({appId: e, modelCode: t}) => d(`${P}/app/${e}/model/${t}/startForm/childProperties`),
    getListSubPermission: ({appId: e, modelCode: t, businessId: s}) => n(`${P}/app/${e}/model/${t}/${s}/childProperties/byLastNode`),
    getBacklogSubPermission: ({appId: e, modelCode: t, businessId: s, actId: i, taskInstanceId: o}) => n(`${P}/app/${e}/model/${t}/${s}/childProperties/byTaskInstance`, {
        query: {
            actId: i,
            taskInstanceId: o
        }
    }),
    processInstanceList: ({appId: e, sceneId: t, pageNum: s, pageSize: i, cancelToken: o, filter: a, keyword: r, sort: d}) => n(`${P}/app/${e}/scene/${t}/query`, {
        body: {
            filter: a,
            keyword: r,
            sort: d,
            pageNum: s,
            pageSize: i
        },
        cancelToken: o
    }),
    listUndoneFormTasks: ({appId: e, pageNum: t, pageSize: s, modelCode: i, ...o}) => d(`${P}/todoTask/listUndoneFormTasks`, {
        query: {
            appId: e,
            pageNum: t,
            modelCode: i
        },
        ...o
    }),
    getModelNodeEvent: e => d(`${P}/model/nodeEvents`, {
        query: e
    }),
    getModelStatus: e => d(`${P}/model/info/status`, {
        query: e
    }),
    postDraftStash: e => n(`${P}/proc-start/draft-stash`, {
        body: e
    }),
    getModelSettingsByModelCode: e => d(`${P}/model/settings`, {
        query: e
    }),
    getAppraise: e => n(`${P}/appraise/details`, {
        body: e
    }),
    saveAppraise: e => n("/workflow/appraise", {
        body: e
    }),
    saveModelSettings: e => n(`${P}/model/settings`, {
        body: e
    }),
    getProcessLogFromList: e => n(`${P}/process-log/from/list`, {
        body: e
    }),
    getModelRemindSettings: e => n(`${P}/model-remind`, {
        body: e
    }),
    putModelRemindDStatus: e => l(`${P}/model-remind/status`, {
        body: e
    }),
    getAppraiseOpenDetails: (e, t) => n(`${P}/appraise/open-details`, {
        headers: null == t ? void 0 : t.headers,
        body: e
    }),
    postAppraise: e => n(`${P}/procTask/appraise`, e)
}, _ = {
    getUploadParams: e => d("/base/file/upload-params", {
        query: e
    }),
    getUploadParamsUnlogin: e => d("/base/file/unlogin/upload-params", {
        query: e
    })
}, O = "/roboter", B = {
    getExecuteFlowPage: e => n(`${O}/app/${e.appId}/execute-flow/page`, {
        body: e
    }),
    getExecuteNode: ({appId: e, flowInstanceId: t}) => d(`${O}/app/${e}/execute-node/${t}`),
    postPerformExecute: (e, t, s, i) => n(`${O}/app/${e}/execute/${t}`, {
        body: s,
        headers: {
            sessionId: i
        }
    }),
    expressionEvaluation: e => n(`${O}/expression/execute`, {
        body: e
    }),
    expressionEvaluationOpen: e => n(`${O}/open/expression/execute`, {
        body: e
    }),
    batchExpressionEvaluation: e => n(`${O}/expression/batchExecute`, {
        body: e
    }),
    batchExpressionEvaluationOpen: e => n(`${O}/open/expression/batchExecute`, {
        body: e
    }),
    batchExpressionsEvaluation: e => n(`${O}/expression/batchExecuteBiz`, {
        body: e
    }),
    batchExpressionsEvaluationOpen: e => n(`${O}/open/expression/batchExecuteBiz`, {
        body: e
    }),
    saveModelFile: (e, t) => n(`${O}/magic-api/${e}/file`, {
        body: t
    }),
    testExecutionTime: e => n(`${O}/expression/cron/executeTime`, {
        body: e
    })
}, R = "/msg", q = {
    postReminderConfig: e => n(`${R}/push/setting`, {
        body: e
    }),
    getReminderConfigById: e => d(`${R}/push/setting/code/${e}`),
    putReminderConfig: e => l(`${R}/push/setting`, {
        body: e
    }),
    deleteReminderConfigById: ({id: e}) => p(`${R}/push/setting/${e}`),
    getMessageGroup: () => n(`${R}/group/setting/list`),
    sendMsg: e => n(`${R}/send/sms`, {
        body: e
    })
}, M = {
    getRobotSettingList: e => d("/open-platform/robot-setting/list", {
        query: e
    })
}, U = "/base", N = `${U}/op-log`, W = {
    getOpLogModelById: ({modelCode: e, id: t}) => d(`${N}/model/${e}/${t}`),
    getOpLogSubModelById: ({modelCode: e, id: t, attributeCode: s, ...i}) => d(`${N}/model/${e}/${t}/${s}/single-link`, {
        query: {
            ...i
        }
    }),
    getSuggestion: e => d(`${U}/address/suggestion`, {
        query: e
    }),
    getAddress: e => d(`${U}/address`, {
        query: e
    })
}, j = "object" == typeof performance && performance && "function" == typeof performance.now ? performance : Date, G = "function" == typeof AbortController ? AbortController : class {
    constructor() {
        this.signal = new V;
    }
    abort(e = new Error("This operation was aborted")) {
        this.signal.reason = this.signal.reason || e, this.signal.aborted = !0, this.signal.dispatchEvent({
            type: "abort",
            target: this.signal
        });
    }
}, Q = "function" == typeof AbortSignal, H = "function" == typeof G.AbortSignal, V = Q ? AbortSignal : H ? G.AbortController : class {
    constructor() {
        this.reason = void 0, this.aborted = !1, this._listeners = [];
    }
    dispatchEvent(e) {
        "abort" === e.type && (this.aborted = !0, this.onabort(e), this._listeners.forEach((t => t(e)), this));
    }
    onabort() {}
    addEventListener(e, t) {
        "abort" === e && this._listeners.push(t);
    }
    removeEventListener(e, t) {
        "abort" === e && (this._listeners = this._listeners.filter((e => e !== t)));
    }
}, J = new Set, X = (e, t) => {
    const s = `LRU_CACHE_OPTION_${e}`;
    Z(s) && ee(s, `${e} option`, `options.${t}`, ae);
}, Y = (e, t) => {
    const s = `LRU_CACHE_METHOD_${e}`;
    if (Z(s)) {
        const {prototype: i} = ae, {get: o} = Object.getOwnPropertyDescriptor(i, e);
        ee(s, `${e} method`, `cache.${t}()`, o);
    }
}, K = (...e) => {
    "object" == typeof process && process && "function" == typeof process.emitWarning && process.emitWarning(...e);
}, Z = e => !J.has(e), ee = (e, t, s, i) => {
    J.add(e);
    K(`The ${t} is deprecated. Please use ${s} instead.`, "DeprecationWarning", e, i);
}, te = e => e && e === Math.floor(e) && e > 0 && isFinite(e), se = e => te(e) ? e <= Math.pow(2, 8) ? Uint8Array : e <= Math.pow(2, 16) ? Uint16Array : e <= Math.pow(2, 32) ? Uint32Array : e <= Number.MAX_SAFE_INTEGER ? ie : null : null;

class ie extends Array {
    constructor(e) {
        super(e), this.fill(0);
    }
}

class oe {
    constructor(e) {
        if (0 === e) return [];
        const t = se(e);
        this.heap = new t(e), this.length = 0;
    }
    push(e) {
        this.heap[this.length++] = e;
    }
    pop() {
        return this.heap[--this.length];
    }
}

class ae {
    constructor(e = {}) {
        const {max: t = 0, ttl: s, ttlResolution: i = 1, ttlAutopurge: o, updateAgeOnGet: a, updateAgeOnHas: r, allowStale: n, dispose: d, disposeAfter: l, noDisposeOnSet: p, noUpdateTTL: c, maxSize: h = 0, maxEntrySize: u = 0, sizeCalculation: $, fetchMethod: g, fetchContext: m, noDeleteOnFetchRejection: y, noDeleteOnStaleGet: f, allowStaleOnFetchRejection: b, allowStaleOnFetchAbort: I, ignoreFetchAbort: S} = e, {length: T, maxAge: k, stale: v} = e instanceof ae ? {} : e;
        if (0 !== t && !te(t)) throw new TypeError("max option must be a nonnegative integer");
        const w = t ? se(t) : Array;
        if (!w) throw new Error("invalid max value: " + t);
        if (this.max = t, this.maxSize = h, this.maxEntrySize = u || this.maxSize, this.sizeCalculation = $ || T, 
        this.sizeCalculation) {
            if (!this.maxSize && !this.maxEntrySize) throw new TypeError("cannot set sizeCalculation without setting maxSize or maxEntrySize");
            if ("function" != typeof this.sizeCalculation) throw new TypeError("sizeCalculation set to non-function");
        }
        if (this.fetchMethod = g || null, this.fetchMethod && "function" != typeof this.fetchMethod) throw new TypeError("fetchMethod must be a function if specified");
        if (this.fetchContext = m, !this.fetchMethod && void 0 !== m) throw new TypeError("cannot set fetchContext without fetchMethod");
        if (this.keyMap = new Map, this.keyList = new Array(t).fill(null), this.valList = new Array(t).fill(null), 
        this.next = new w(t), this.prev = new w(t), this.head = 0, this.tail = 0, this.free = new oe(t), 
        this.initialFill = 1, this.size = 0, "function" == typeof d && (this.dispose = d), 
        "function" == typeof l ? (this.disposeAfter = l, this.disposed = []) : (this.disposeAfter = null, 
        this.disposed = null), this.noDisposeOnSet = !!p, this.noUpdateTTL = !!c, this.noDeleteOnFetchRejection = !!y, 
        this.allowStaleOnFetchRejection = !!b, this.allowStaleOnFetchAbort = !!I, this.ignoreFetchAbort = !!S, 
        0 !== this.maxEntrySize) {
            if (0 !== this.maxSize && !te(this.maxSize)) throw new TypeError("maxSize must be a positive integer if specified");
            if (!te(this.maxEntrySize)) throw new TypeError("maxEntrySize must be a positive integer if specified");
            this.initializeSizeTracking();
        }
        if (this.allowStale = !!n || !!v, this.noDeleteOnStaleGet = !!f, this.updateAgeOnGet = !!a, 
        this.updateAgeOnHas = !!r, this.ttlResolution = te(i) || 0 === i ? i : 1, this.ttlAutopurge = !!o, 
        this.ttl = s || k || 0, this.ttl) {
            if (!te(this.ttl)) throw new TypeError("ttl must be a positive integer if specified");
            this.initializeTTLTracking();
        }
        if (0 === this.max && 0 === this.ttl && 0 === this.maxSize) throw new TypeError("At least one of max, maxSize, or ttl is required");
        if (!this.ttlAutopurge && !this.max && !this.maxSize) {
            const e = "LRU_CACHE_UNBOUNDED";
            if (Z(e)) {
                J.add(e);
                K("TTL caching without ttlAutopurge, max, or maxSize can result in unbounded memory consumption.", "UnboundedCacheWarning", e, ae);
            }
        }
        v && X("stale", "allowStale"), k && X("maxAge", "ttl"), T && X("length", "sizeCalculation");
    }
    getRemainingTTL(e) {
        return this.has(e, {
            updateAgeOnHas: !1
        }) ? 1 / 0 : 0;
    }
    initializeTTLTracking() {
        this.ttls = new ie(this.max), this.starts = new ie(this.max), this.setItemTTL = (e, t, s = j.now()) => {
            if (this.starts[e] = 0 !== t ? s : 0, this.ttls[e] = t, 0 !== t && this.ttlAutopurge) {
                const s = setTimeout((() => {
                    this.isStale(e) && this.delete(this.keyList[e]);
                }), t + 1);
                s.unref && s.unref();
            }
        }, this.updateItemAge = e => {
            this.starts[e] = 0 !== this.ttls[e] ? j.now() : 0;
        }, this.statusTTL = (s, i) => {
            s && (s.ttl = this.ttls[i], s.start = this.starts[i], s.now = e || t(), s.remainingTTL = s.now + s.ttl - s.start);
        };
        let e = 0;
        const t = () => {
            const t = j.now();
            if (this.ttlResolution > 0) {
                e = t;
                const s = setTimeout((() => e = 0), this.ttlResolution);
                s.unref && s.unref();
            }
            return t;
        };
        this.getRemainingTTL = s => {
            const i = this.keyMap.get(s);
            return void 0 === i ? 0 : 0 === this.ttls[i] || 0 === this.starts[i] ? 1 / 0 : this.starts[i] + this.ttls[i] - (e || t());
        }, this.isStale = s => 0 !== this.ttls[s] && 0 !== this.starts[s] && (e || t()) - this.starts[s] > this.ttls[s];
    }
    updateItemAge(e) {}
    statusTTL(e, t) {}
    setItemTTL(e, t, s) {}
    isStale(e) {
        return !1;
    }
    initializeSizeTracking() {
        this.calculatedSize = 0, this.sizes = new ie(this.max), this.removeItemSize = e => {
            this.calculatedSize -= this.sizes[e], this.sizes[e] = 0;
        }, this.requireSize = (e, t, s, i) => {
            if (this.isBackgroundFetch(t)) return 0;
            if (!te(s)) {
                if (!i) throw new TypeError("invalid size value (must be positive integer). When maxSize or maxEntrySize is used, sizeCalculation or size must be set.");
                if ("function" != typeof i) throw new TypeError("sizeCalculation must be a function");
                if (s = i(t, e), !te(s)) throw new TypeError("sizeCalculation return invalid (expect positive integer)");
            }
            return s;
        }, this.addItemSize = (e, t, s) => {
            if (this.sizes[e] = t, this.maxSize) {
                const t = this.maxSize - this.sizes[e];
                for (;this.calculatedSize > t; ) this.evict(!0);
            }
            this.calculatedSize += this.sizes[e], s && (s.entrySize = t, s.totalCalculatedSize = this.calculatedSize);
        };
    }
    removeItemSize(e) {}
    addItemSize(e, t) {}
    requireSize(e, t, s, i) {
        if (s || i) throw new TypeError("cannot set size without setting maxSize or maxEntrySize on cache");
    }
    * indexes({allowStale: e = this.allowStale} = {}) {
        if (this.size) for (let t = this.tail; this.isValidIndex(t) && (!e && this.isStale(t) || (yield t), 
        t !== this.head); ) t = this.prev[t];
    }
    * rindexes({allowStale: e = this.allowStale} = {}) {
        if (this.size) for (let t = this.head; this.isValidIndex(t) && (!e && this.isStale(t) || (yield t), 
        t !== this.tail); ) t = this.next[t];
    }
    isValidIndex(e) {
        return void 0 !== e && this.keyMap.get(this.keyList[e]) === e;
    }
    * entries() {
        for (const e of this.indexes()) void 0 === this.valList[e] || void 0 === this.keyList[e] || this.isBackgroundFetch(this.valList[e]) || (yield [ this.keyList[e], this.valList[e] ]);
    }
    * rentries() {
        for (const e of this.rindexes()) void 0 === this.valList[e] || void 0 === this.keyList[e] || this.isBackgroundFetch(this.valList[e]) || (yield [ this.keyList[e], this.valList[e] ]);
    }
    * keys() {
        for (const e of this.indexes()) void 0 === this.keyList[e] || this.isBackgroundFetch(this.valList[e]) || (yield this.keyList[e]);
    }
    * rkeys() {
        for (const e of this.rindexes()) void 0 === this.keyList[e] || this.isBackgroundFetch(this.valList[e]) || (yield this.keyList[e]);
    }
    * values() {
        for (const e of this.indexes()) void 0 === this.valList[e] || this.isBackgroundFetch(this.valList[e]) || (yield this.valList[e]);
    }
    * rvalues() {
        for (const e of this.rindexes()) void 0 === this.valList[e] || this.isBackgroundFetch(this.valList[e]) || (yield this.valList[e]);
    }
    [Symbol.iterator]() {
        return this.entries();
    }
    find(e, t) {
        for (const s of this.indexes()) {
            const i = this.valList[s], o = this.isBackgroundFetch(i) ? i.__staleWhileFetching : i;
            if (void 0 !== o && e(o, this.keyList[s], this)) return this.get(this.keyList[s], t);
        }
    }
    forEach(e, t = this) {
        for (const s of this.indexes()) {
            const i = this.valList[s], o = this.isBackgroundFetch(i) ? i.__staleWhileFetching : i;
            void 0 !== o && e.call(t, o, this.keyList[s], this);
        }
    }
    rforEach(e, t = this) {
        for (const s of this.rindexes()) {
            const i = this.valList[s], o = this.isBackgroundFetch(i) ? i.__staleWhileFetching : i;
            void 0 !== o && e.call(t, o, this.keyList[s], this);
        }
    }
    get prune() {
        return Y("prune", "purgeStale"), this.purgeStale;
    }
    purgeStale() {
        let e = !1;
        for (const t of this.rindexes({
            allowStale: !0
        })) this.isStale(t) && (this.delete(this.keyList[t]), e = !0);
        return e;
    }
    dump() {
        const e = [];
        for (const t of this.indexes({
            allowStale: !0
        })) {
            const s = this.keyList[t], i = this.valList[t], o = this.isBackgroundFetch(i) ? i.__staleWhileFetching : i;
            if (void 0 === o) continue;
            const a = {
                value: o
            };
            if (this.ttls) {
                a.ttl = this.ttls[t];
                const e = j.now() - this.starts[t];
                a.start = Math.floor(Date.now() - e);
            }
            this.sizes && (a.size = this.sizes[t]), e.unshift([ s, a ]);
        }
        return e;
    }
    load(e) {
        this.clear();
        for (const [t, s] of e) {
            if (s.start) {
                const e = Date.now() - s.start;
                s.start = j.now() - e;
            }
            this.set(t, s.value, s);
        }
    }
    dispose(e, t, s) {}
    set(e, t, {ttl: s = this.ttl, start: i, noDisposeOnSet: o = this.noDisposeOnSet, size: a = 0, sizeCalculation: r = this.sizeCalculation, noUpdateTTL: n = this.noUpdateTTL, status: d} = {}) {
        if (a = this.requireSize(e, t, a, r), this.maxEntrySize && a > this.maxEntrySize) return d && (d.set = "miss", 
        d.maxEntrySizeExceeded = !0), this.delete(e), this;
        let l = 0 === this.size ? void 0 : this.keyMap.get(e);
        if (void 0 === l) l = this.newIndex(), this.keyList[l] = e, this.valList[l] = t, 
        this.keyMap.set(e, l), this.next[this.tail] = l, this.prev[l] = this.tail, this.tail = l, 
        this.size++, this.addItemSize(l, a, d), d && (d.set = "add"), n = !1; else {
            this.moveToTail(l);
            const s = this.valList[l];
            if (t !== s) {
                if (this.isBackgroundFetch(s) ? s.__abortController.abort(new Error("replaced")) : o || (this.dispose(s, e, "set"), 
                this.disposeAfter && this.disposed.push([ s, e, "set" ])), this.removeItemSize(l), 
                this.valList[l] = t, this.addItemSize(l, a, d), d) {
                    d.set = "replace";
                    const e = s && this.isBackgroundFetch(s) ? s.__staleWhileFetching : s;
                    void 0 !== e && (d.oldValue = e);
                }
            } else d && (d.set = "update");
        }
        if (0 === s || 0 !== this.ttl || this.ttls || this.initializeTTLTracking(), n || this.setItemTTL(l, s, i), 
        this.statusTTL(d, l), this.disposeAfter) for (;this.disposed.length; ) this.disposeAfter(...this.disposed.shift());
        return this;
    }
    newIndex() {
        return 0 === this.size ? this.tail : this.size === this.max && 0 !== this.max ? this.evict(!1) : 0 !== this.free.length ? this.free.pop() : this.initialFill++;
    }
    pop() {
        if (this.size) {
            const e = this.valList[this.head];
            return this.evict(!0), e;
        }
    }
    evict(e) {
        const t = this.head, s = this.keyList[t], i = this.valList[t];
        return this.isBackgroundFetch(i) ? i.__abortController.abort(new Error("evicted")) : (this.dispose(i, s, "evict"), 
        this.disposeAfter && this.disposed.push([ i, s, "evict" ])), this.removeItemSize(t), 
        e && (this.keyList[t] = null, this.valList[t] = null, this.free.push(t)), this.head = this.next[t], 
        this.keyMap.delete(s), this.size--, t;
    }
    has(e, {updateAgeOnHas: t = this.updateAgeOnHas, status: s} = {}) {
        const i = this.keyMap.get(e);
        if (void 0 !== i) {
            if (!this.isStale(i)) return t && this.updateItemAge(i), s && (s.has = "hit"), this.statusTTL(s, i), 
            !0;
            s && (s.has = "stale", this.statusTTL(s, i));
        } else s && (s.has = "miss");
        return !1;
    }
    peek(e, {allowStale: t = this.allowStale} = {}) {
        const s = this.keyMap.get(e);
        if (void 0 !== s && (t || !this.isStale(s))) {
            const e = this.valList[s];
            return this.isBackgroundFetch(e) ? e.__staleWhileFetching : e;
        }
    }
    backgroundFetch(e, t, s, i) {
        const o = void 0 === t ? void 0 : this.valList[t];
        if (this.isBackgroundFetch(o)) return o;
        const a = new G;
        s.signal && s.signal.addEventListener("abort", (() => a.abort(s.signal.reason)));
        const r = {
            signal: a.signal,
            options: s,
            context: i
        }, n = (i, o = !1) => {
            const {aborted: n} = a.signal, p = s.ignoreFetchAbort && void 0 !== i;
            return s.status && (n && !o ? (s.status.fetchAborted = !0, s.status.fetchError = a.signal.reason, 
            p && (s.status.fetchAbortIgnored = !0)) : s.status.fetchResolved = !0), !n || p || o ? (this.valList[t] === l && (void 0 === i ? l.__staleWhileFetching ? this.valList[t] = l.__staleWhileFetching : this.delete(e) : (s.status && (s.status.fetchUpdated = !0), 
            this.set(e, i, r.options))), i) : d(a.signal.reason);
        }, d = i => {
            const {aborted: o} = a.signal, r = o && s.allowStaleOnFetchAbort, n = r || s.allowStaleOnFetchRejection, d = n || s.noDeleteOnFetchRejection;
            if (this.valList[t] === l) {
                !d || void 0 === l.__staleWhileFetching ? this.delete(e) : r || (this.valList[t] = l.__staleWhileFetching);
            }
            if (n) return s.status && void 0 !== l.__staleWhileFetching && (s.status.returnedStale = !0), 
            l.__staleWhileFetching;
            if (l.__returned === l) throw i;
        };
        s.status && (s.status.fetchDispatched = !0);
        const l = new Promise(((t, i) => {
            this.fetchMethod(e, o, r).then((e => t(e)), i), a.signal.addEventListener("abort", (() => {
                s.ignoreFetchAbort && !s.allowStaleOnFetchAbort || (t(), s.allowStaleOnFetchAbort && (t = e => n(e, !0)));
            }));
        })).then(n, (e => (s.status && (s.status.fetchRejected = !0, s.status.fetchError = e), 
        d(e))));
        return l.__abortController = a, l.__staleWhileFetching = o, l.__returned = null, 
        void 0 === t ? (this.set(e, l, {
            ...r.options,
            status: void 0
        }), t = this.keyMap.get(e)) : this.valList[t] = l, l;
    }
    isBackgroundFetch(e) {
        return e && "object" == typeof e && "function" == typeof e.then && Object.prototype.hasOwnProperty.call(e, "__staleWhileFetching") && Object.prototype.hasOwnProperty.call(e, "__returned") && (e.__returned === e || null === e.__returned);
    }
    async fetch(e, {allowStale: t = this.allowStale, updateAgeOnGet: s = this.updateAgeOnGet, noDeleteOnStaleGet: i = this.noDeleteOnStaleGet, ttl: o = this.ttl, noDisposeOnSet: a = this.noDisposeOnSet, size: r = 0, sizeCalculation: n = this.sizeCalculation, noUpdateTTL: d = this.noUpdateTTL, noDeleteOnFetchRejection: l = this.noDeleteOnFetchRejection, allowStaleOnFetchRejection: p = this.allowStaleOnFetchRejection, ignoreFetchAbort: c = this.ignoreFetchAbort, allowStaleOnFetchAbort: h = this.allowStaleOnFetchAbort, fetchContext: u = this.fetchContext, forceRefresh: $ = !1, status: g, signal: m} = {}) {
        if (!this.fetchMethod) return g && (g.fetch = "get"), this.get(e, {
            allowStale: t,
            updateAgeOnGet: s,
            noDeleteOnStaleGet: i,
            status: g
        });
        const y = {
            allowStale: t,
            updateAgeOnGet: s,
            noDeleteOnStaleGet: i,
            ttl: o,
            noDisposeOnSet: a,
            size: r,
            sizeCalculation: n,
            noUpdateTTL: d,
            noDeleteOnFetchRejection: l,
            allowStaleOnFetchRejection: p,
            allowStaleOnFetchAbort: h,
            ignoreFetchAbort: c,
            status: g,
            signal: m
        };
        let f = this.keyMap.get(e);
        if (void 0 === f) {
            g && (g.fetch = "miss");
            const t = this.backgroundFetch(e, f, y, u);
            return t.__returned = t;
        }
        {
            const i = this.valList[f];
            if (this.isBackgroundFetch(i)) {
                const e = t && void 0 !== i.__staleWhileFetching;
                return g && (g.fetch = "inflight", e && (g.returnedStale = !0)), e ? i.__staleWhileFetching : i.__returned = i;
            }
            const o = this.isStale(f);
            if (!$ && !o) return g && (g.fetch = "hit"), this.moveToTail(f), s && this.updateItemAge(f), 
            this.statusTTL(g, f), i;
            const a = this.backgroundFetch(e, f, y, u), r = void 0 !== a.__staleWhileFetching, n = r && t;
            return g && (g.fetch = r && o ? "stale" : "refresh", n && o && (g.returnedStale = !0)), 
            n ? a.__staleWhileFetching : a.__returned = a;
        }
    }
    get(e, {allowStale: t = this.allowStale, updateAgeOnGet: s = this.updateAgeOnGet, noDeleteOnStaleGet: i = this.noDeleteOnStaleGet, status: o} = {}) {
        const a = this.keyMap.get(e);
        if (void 0 !== a) {
            const r = this.valList[a], n = this.isBackgroundFetch(r);
            return this.statusTTL(o, a), this.isStale(a) ? (o && (o.get = "stale"), n ? (o && (o.returnedStale = t && void 0 !== r.__staleWhileFetching), 
            t ? r.__staleWhileFetching : void 0) : (i || this.delete(e), o && (o.returnedStale = t), 
            t ? r : void 0)) : (o && (o.get = "hit"), n ? r.__staleWhileFetching : (this.moveToTail(a), 
            s && this.updateItemAge(a), r));
        }
        o && (o.get = "miss");
    }
    connect(e, t) {
        this.prev[t] = e, this.next[e] = t;
    }
    moveToTail(e) {
        e !== this.tail && (e === this.head ? this.head = this.next[e] : this.connect(this.prev[e], this.next[e]), 
        this.connect(this.tail, e), this.tail = e);
    }
    get del() {
        return Y("del", "delete"), this.delete;
    }
    delete(e) {
        let t = !1;
        if (0 !== this.size) {
            const s = this.keyMap.get(e);
            if (void 0 !== s) if (t = !0, 1 === this.size) this.clear(); else {
                this.removeItemSize(s);
                const t = this.valList[s];
                this.isBackgroundFetch(t) ? t.__abortController.abort(new Error("deleted")) : (this.dispose(t, e, "delete"), 
                this.disposeAfter && this.disposed.push([ t, e, "delete" ])), this.keyMap.delete(e), 
                this.keyList[s] = null, this.valList[s] = null, s === this.tail ? this.tail = this.prev[s] : s === this.head ? this.head = this.next[s] : (this.next[this.prev[s]] = this.next[s], 
                this.prev[this.next[s]] = this.prev[s]), this.size--, this.free.push(s);
            }
        }
        if (this.disposed) for (;this.disposed.length; ) this.disposeAfter(...this.disposed.shift());
        return t;
    }
    clear() {
        for (const e of this.rindexes({
            allowStale: !0
        })) {
            const t = this.valList[e];
            if (this.isBackgroundFetch(t)) t.__abortController.abort(new Error("deleted")); else {
                const s = this.keyList[e];
                this.dispose(t, s, "delete"), this.disposeAfter && this.disposed.push([ t, s, "delete" ]);
            }
        }
        if (this.keyMap.clear(), this.valList.fill(null), this.keyList.fill(null), this.ttls && (this.ttls.fill(0), 
        this.starts.fill(0)), this.sizes && this.sizes.fill(0), this.head = 0, this.tail = 0, 
        this.initialFill = 1, this.free.length = 0, this.calculatedSize = 0, this.size = 0, 
        this.disposed) for (;this.disposed.length; ) this.disposeAfter(...this.disposed.shift());
    }
    get reset() {
        return Y("reset", "clear"), this.clear;
    }
    get length() {
        return ((e, t) => {
            const s = `LRU_CACHE_PROPERTY_${e}`;
            if (Z(s)) {
                const {prototype: i} = ae, {get: o} = Object.getOwnPropertyDescriptor(i, e);
                ee(s, `${e} property`, `cache.${t}`, o);
            }
        })("length", "size"), this.size;
    }
    static get AbortController() {
        return G;
    }
    static get AbortSignal() {
        return V;
    }
}

var re = ae;

const ne = {
    baseURL: "",
    getters: {
        token: () => s.get("token")
    },
    onRequest: e.noop,
    onResponse: e.noop,
    onError: e.noop
}, de = {
    iam: x,
    metaData: F,
    admin: w,
    process: E,
    form: L,
    upload: _,
    roboter: B,
    msg: q,
    openPlatform: M,
    base: W
}, le = new re({
    max: 10,
    ttl: 2e4
}), pe = s => {
    s = e.merge(ne, s);
    const i = t.create({
        baseURL: s.baseURL
    });
    i.interceptors.request.use((e => {
        var t, i, a;
        const r = o(null === (t = s) || void 0 === t || null === (i = t.getters) || void 0 === i ? void 0 : i.token);
        var n;
        r && (e.headers.Authorization = null !== (n = e.headers.Authorization) && void 0 !== n ? n : `Bearer ${r}`);
        return e.params = {
            ...e.params,
            _: Date.now()
        }, o(null === (a = s) || void 0 === a ? void 0 : a.onRequest, null, e), e;
    })), i.interceptors.response.use((e => {
        var t;
        const i = e.data, a = i.success || [ "0", "000000" ].includes(`${i.code}`), r = e.status >= 400;
        return "blob" === e.config.responseType ? i : a && !r ? null !== (n = i.data) && void 0 !== n ? n : i.payload : (o(null === (t = s) || void 0 === t ? void 0 : t.onError, null, e), 
        Promise.reject(e));
        var n;
    }), (e => {
        var t;
        return o(null === (t = s) || void 0 === t ? void 0 : t.onError, null, e.response), 
        Promise.reject(e);
    }));
    const a = (e = {}) => {
        const {url: t, params: s, body: o, headers: a, method: r, cancelToken: n, responseType: d, timeout: l, cache: p} = e, c = {
            url: t,
            params: s,
            headers: a,
            method: r,
            data: o
        };
        if (n && (c.cancelToken = n), d && (c.responseType = d), l && (c.timeout = l), p) {
            le.get(JSON.stringify({
                url: t,
                params: s,
                body: o
            })) || le.set(JSON.stringify({
                url: t,
                params: s,
                body: o
            }), i.request(c));
            return le.get(JSON.stringify({
                url: t,
                params: s,
                body: o
            }));
        }
        return i.request(c);
    }, r = {
        request: a,
        createCancelToken() {
            let e;
            const s = new t.CancelToken((t => {
                e = t;
            }));
            return {
                cancel: e,
                cancelToken: s
            };
        }
    };
    return Object.keys(de).forEach((e => {
        const t = de[e], s = {};
        Object.keys(t).forEach((e => {
            s[e] = (...s) => a(t[e](...s));
        })), r[e] = s;
    })), r;
};

export { pe as default };
